import React, { Component } from 'react';
import SelectField from '../fields/select';
import InputLabel from '@material-ui/core/InputLabel';
import Fab from '@material-ui/core/Fab';
import Add from '@material-ui/icons/Add';
import Minimize from '@material-ui/icons/Minimize';
import Radio from '@material-ui/core/Radio';
import RadioField from '../fields/radio';
import InputField from '../fields/inputs';
import DropdownPopover from '../../components/common/dropdown-popover';

//Field Value
import FieldValueComponent from '../common/field-value-component';

//Constants
import * as Constants from '../../utilities/constants';

class FieldValueChoice extends Component {
    state = {
        anchorElem: '',
        indexValueOfPopover: 0,
    }

    handleChange = index => event => {
        let checkData = this.props.checkData;
        checkData[index].checked = event.target.checked;
        this.props.optionOnChangeEvent(checkData);
    };

    handleOnChangeEvent(index, key, value) {
        if (key === "radioType") {
            let checkData = this.props.checkData;
            console.log("index", index, "key", key, "value", value);
            checkData[index].field_value = "";
            checkData[index].value_type = value;
            this.props.optionOnChangeEvent(checkData);
        }
        else {
            let checkData = this.props.checkData;
            checkData[index][key] = value;
            this.props.optionOnChangeEvent(checkData);
        }

    }

    handleOnChangeEventFunc = (index, fieldName) => (value, errors) => {
        this.handleOnChangeEvent(index, "value_type", "value");
        this.handleOnChangeEvent(index, "field_value", value);
    }

    addNewOption = index => event => {
        let checkData = this.props.checkData;
        let option = {};
        checkData.splice(index + 1, 0, option);
        this.props.optionOnChangeEvent(checkData);
    }

    deleteOption = index => event => {
        let checkData = this.props.checkData;
        checkData.splice(index, 1);

        this.props.optionOnChangeEvent(checkData);
    }

    handleFieldSelected(index, field) {
        let checkData = this.props.checkData;
        checkData[index]["field_value"] = field.field_key;
        switch (field.fieldType) {
            case Constants.DATA_TYPE.FORM_DATA: {
                checkData[index]["value_type"] = "field";
                break;
            }
            case Constants.DATA_TYPE.PROGRAM_DATA: {
                checkData[index]["value_type"] = "program";
                break;
            }
            case Constants.DATA_TYPE.SECTIONAL_DATA: {
                checkData[index]["value_type"] = "sectional";
                break;
            }
        }


        this.props.optionOnChangeEvent(checkData);
    }

    handleCloseOfFieldDropdown = () => () => {
        this.setState({ anchorElem: '' });
    }

    profileUserClick(event, index) {
        this.setState({ anchorElem: event.currentTarget, indexValueOfPopover: index });
    }
    profileUserClose() {
        this.setState({ anchorEl: !this.state.anchorEl });
    }
    handleInputPopover = () => () => {

    }

    render() {
        let { checkData, fieldList, label, fieldDataList, description, title, isDynamic, parentFieldList, workflowData, programDataLinkList, sectionFormData } = this.props;
        let { anchorElem, indexValueOfPopover } = this.state;
        //check if default or not
        let checkDataUpdated = JSON.parse(JSON.stringify(checkData));
        // if(isDefault){
        //     checkDataUpdated = checkDataUpdated.filter( data => data.disabled);
        // }else{
        //     checkDataUpdated = checkDataUpdated.filter( data => !data.disabled).length>0 ?checkDataUpdated.filter( data => !data.disabled): [{}];
        // }
        let dataList = {};
        //Adding differentiation for field type i.e. formField, programField and sectionalField
        parentFieldList.map(field => field.fieldType = Constants.DATA_TYPE.FORM_DATA);
        programDataLinkList && programDataLinkList.map(field => field.fieldType = Constants.DATA_TYPE.PROGRAM_DATA);
        sectionFormData && sectionFormData.map(field => field.fieldType = Constants.DATA_TYPE.SECTIONAL_DATA);

        dataList["Form Fields"] = parentFieldList;
        dataList["Program Data"] = programDataLinkList ? programDataLinkList : [];
        dataList["Sectional Form Fields"] = sectionFormData ? sectionFormData : [];

        return (
            <div className="form-row">
                <InputLabel
                    htmlFor={label}
                    className="label"
                >
                    {description ? description : ""}
                </InputLabel>

                {title && (
                    <div className="title border-bottom">
                        <InputLabel
                            htmlFor={label}
                            className="label m-0"
                        >
                            {title}
                        </InputLabel>
                    </div>

                )}
                <div className="choice-wrap">
                    {
                        checkDataUpdated.map((check, index) => {
                            return (
                                <div className="choice-row field-value-choice" key={index}>
                                    <SelectField
                                        label="Field Name"
                                        withLabel={true}
                                        optionsData={fieldList}
                                        selected_value={check.field_id}
                                        default_value={check.field_id}
                                        radioChangeHandle={(value, errors) => this.handleOnChangeEvent(index, "field_id", value)}
                                        disabled={check.disabled}
                                    />

                                    <div className="form-row">
                                        <div className="field-value-combine">
                                            <Radio
                                                onChange={(errors) => this.handleOnChangeEvent(index, "radioType", "value")}
                                                checked={check.value_type === "value" ? true : false}
                                            />
                                            {
                                                <FieldValueComponent
                                                    fieldLabel="Field Value"
                                                    fieldDataList={fieldDataList}
                                                    fieldOperation={check.value_type === "value" ? check : {}}
                                                    check={check}
                                                    index={index}
                                                    handleOperationUpdated={this.handleOnChangeEventFunc} />
                                            }
                                        </div>
                                        <div className="field-value-combine">
                                            <Radio
                                                onChange={(errors) => this.handleOnChangeEvent(index, "radioType", "field")}
                                                checked={check.value_type === "field" || check.value_type === "program" || check.value_type === "sectional" ? true : false}
                                            />
                                            {/* <SelectField
                                                    label="Field Value"
                                                    withLabel= {true}
                                                    disabled={check.value_type === "value" ? true :check.checkNull}
                                                    optionsData={parentFieldList}
                                                    selected_value={check.value_type === "field" ? check.field_value : []}
                                                    default_value={check.field_value}
                                                    radioChangeHandle={(value, errors) => {this.handleOnChangeEvent(index, "value_type", "field");this.handleOnChangeEvent(index, "field_value", value)}}
                                                /> */}

                                            <div style={{ width: "100%" }}>
                                                <InputField
                                                    label="Fields Popover"
                                                    placeholder="Search fields"
                                                    //default_value = {this.props.selectedIcon.text}
                                                    default_value={check.value_type === "field" || check.value_type === "program" || check.value_type === "sectional" ? check.field_value : ""}
                                                    selected_value={check.value_type === "field" || check.value_type === "program" || check.value_type === "sectional" ? check.field_value : ""}
                                                    handleOnClickEvent={(event) => this.profileUserClick(event, index)}
                                                    handleOnChangeValue={this.handleInputPopover.bind(this)}
                                                // fieldStyle="field-box bg-plane grey-color"
                                                />
                                                <DropdownPopover
                                                    fieldStyle="field-box bg-plane grey-color field-icon"
                                                    label="Select Icon Upload"
                                                    disabled={check.value_type === "value" ? true : check.checkNull}
                                                    handleFieldSelected={(field) => this.handleFieldSelected(indexValueOfPopover, field)}
                                                    anchorElem={this.state.anchorElem}
                                                    dataList={dataList}
                                                    selectedIcon={this.state.selectedIcon}
                                                    handleCloseOfFieldDropdown={this.handleCloseOfFieldDropdown().bind(this)}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    {isDynamic &&
                                        (
                                            <div className="choice-action">
                                                <Fab aria-label="add_circle_outline" className="fabs-icons add" onClick={this.addNewOption(index)}>
                                                    <Add />
                                                </Fab>
                                                {(checkData.length <= 1 && index === 0) ? "" :
                                                    (
                                                        <Fab aria-label="highlight_off" className="fabs-icons remove" onClick={this.deleteOption(index)}>
                                                            <Minimize />
                                                        </Fab>
                                                    )
                                                }
                                            </div>
                                        )
                                    }

                                </div>
                            )
                        })
                    }
                </div>
            </div>
        )
    }
}
FieldValueChoice.defaultProps = {
    isDynamic: true,
    isDefault: false,
    parentFieldList: [],
}
export default FieldValueChoice;