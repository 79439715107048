import React, { Component } from 'react';

//Custom component
import TableComponent from '../../components/common/table-component';
import Header from '../../components/header';
import WorkflowHeader from '../../components/workflow/workflow-header';
import ArrowBackGrey from '../../assets/images/icon/arrow-back-grey.svg';
import Fab from '@material-ui/core/Fab';
import DataGridTable from '../../components/dashboard/data-grid-table';

//API
import * as FormDataApi from '../../api/form-data-api';

//Constants
import * as FormConstant from '../../utilities/constants'

//Utilities
import * as RecordProcessor from '../../utilities/form-record-process';

class FormRecords extends Component {

    state = {
        gridRecordDetails: {
            rows: [],
            columns: [],
        },
    }

    componentDidMount() {
        this.getAllFormData();
    }

    getAllFormData() {

        const match = this.props.match;
        let formkey = match.params.formkey;
        FormDataApi.getFromData(formkey).then(formRecorData => {
            let recordData = RecordProcessor.generateProcessedRecords(formRecorData.form, formRecorData.records);
            console.log(recordData);
            this.setState({ gridRecordDetails: recordData });
        });
    }
    gridRecordDetailsChange = () => (columnState) => {
        let { gridRecordDetails } = this.state;
        gridRecordDetails.columns = columnState;
        this.setState({ gridRecordDetails: gridRecordDetails });
    }

    render() {
        let { gridRecordDetails } = this.state;
        const match = this.props.match;
        let programkey = match.params.programkey;
        let clientId = match.params.clientId;
        let formkey = match.params.formkey;
        console.log("formkey ", formkey)

        return (
            <div className="FormBuilderScene">
                <Header headerType="dashboard" positionType="fixed" programKey={programkey} clientId={clientId} />
                <WorkflowHeader workflowType="normal" tab="form" programKey={programkey} clientId={clientId} />
                <main>

                    <DataGridTable
                        gridRecordDetails={gridRecordDetails}
                        gridRecordDetailsChange={this.gridRecordDetailsChange().bind(this)}
                        programKey={programkey}
                        formKey={formkey}
                        clientId={clientId}
                    />

                </main>
            </div>
        )
    }
}

export default FormRecords;