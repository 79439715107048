import React, { Component } from 'react';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import InputLabel from '@material-ui/core/InputLabel';

class ToggleChoice extends Component {

    state = {
        dataOption: [
            { text: "Small", value: "S" },
            { text: "Medium", value: "M" },
            { text: "Large", value: "L" }
        ]
    }


    handleValueChange = (event, newAlignment) => {
        this.props.optionOnChangeEvent(newAlignment);
    };

    render() {

        let { label, fieldStyle, selected_value } = this.props;
        const defaultLabel = 'Toggle Button';
        let defaultFieldStyle = "field-box";


        return (
            <div className="form-row">
                <div className={fieldStyle ? fieldStyle : defaultFieldStyle}>
                    <InputLabel
                        htmlFor={label}
                        className="label"
                    >
                        {label ? label : defaultLabel}
                    </InputLabel>
                    <ToggleButtonGroup value={selected_value} exclusive onChange={this.handleValueChange.bind(this)} className="toggle-btn">
                        {
                            this.state.dataOption.map((data, i) => {
                                return (
                                    <ToggleButton value={data.value} key={i}>
                                        {/* <FormatAlignLeftIcon /> */}
                                        {data.text}
                                    </ToggleButton>
                                )
                            })
                        }
                    </ToggleButtonGroup>
                </div>
            </div>
        )
    }

}

export default ToggleChoice;