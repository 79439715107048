import React, { Component } from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Description from '../validators/descriptions';
import MandatoryValidator from '../validators/mandatory-validator';
import Checkbox from '@material-ui/core/Checkbox';
import Chip from '@material-ui/core/Chip';
import SearchField from '../../components/fields/search.js';
import CancelIcon from '@material-ui/icons/Cancel';
import { connect } from 'react-redux';

//Validation
import * as ErrorValidation from '../validators/error-validator';

//Custom 
import ErrorMessage from '../common/error-message';

class SelectField extends Component {
    state = {
        errorStates: [],
        optionsDataObj: {},
        optionsData: [],
    };

    componentWillMount() {
        if (this.props.optionsData) {
            this.generateOptionObject(this.props.optionsData);
        }
    }

    componentWillReceiveProps(props) {
        //Code to generate optionsDataObj when optionsData gets updated
        if (props.optionsData && this.props.optionsData && props.optionsData.join() !== this.props.optionsData.join()) {
            this.generateOptionObject(props.optionsData);
        }
    }

    handleDeleteOfChip = (value) => () => {
        let { optionsData } = this.props;
        let selectedValueList = [];

        optionsData.forEach(data => {
            if (data.field_option_value === value) {
                data.checked = false
            }
            data.checked && selectedValueList.push(data.field_option_value);
        })

        let errors = ErrorValidation.updateErrorMessage(selectedValueList.join(), this.props.field_validations, this.props.condition_script, this.props.error_message);
        this.props.radioChangeHandle(selectedValueList.join(), errors);
    }

    generateOptionObject(optionsData) {
        //Code to generate optionsDataObj when optionsData gets updated
        let optionsDataObj = this.state.optionsDataObj;
        optionsData.forEach(optionData => {
            optionsDataObj[optionData.field_option_value] = optionData.field_option_label
        })
        this.setState({ optionsDataObj: optionsDataObj })
    }

    handleSingleSelectChangeEvent = event => {
        //validating errors
        let userDataRecord = null;
        if (this.props.parent_id) {
            userDataRecord = this.props.userDataRecord;
        }

        let errors = ErrorValidation.updateErrorMessage(event.target.value, this.props.field_validations, this.props.condition_script, this.props.error_message, null, userDataRecord, this.props);
        this.setState({ errorStates: errors });
        this.props.radioChangeHandle(event.target.value, errors, this.props, this.props.name);
    }

    handleMultiSelectChange = event => {
        let { optionsData } = this.props;
        var multipleString = '';
        let multipleValues = event.target.value;

        multipleString = multipleValues.join();
        optionsData.forEach(options => {
            if (multipleValues.includes(options.field_option_value)) {
                options["checked"] = true;
            }
            else {
                options["checked"] = false;
            }
        })
        //validating errors
        let errors = ErrorValidation.updateErrorMessage(multipleString, this.props.field_validations, this.props.condition_script, this.props.error_message);
        this.setState({ errorStates: errors });
        this.props.radioChangeHandle(multipleString, errors);
    }

    render() {
        let {
            label,
            is_mandatory,
            instruction,
            optionsData,
            defaultLabel,
            fieldStyle,
            customStyle,
            disabled,
            isMultiple,
            withLabel,
            isChip,
            selected_value,
            default_value,
            is_search
        } = this.props;
        let { errorStates, optionsDataObj } = this.state;

        //This if statement is to assign the Pre-set values into selected_values
        if (selected_value && selected_value.length < 1) {
            let array = [];
            optionsData.forEach(options => {
                if (options.checked) {
                    array.push(options.field_option_value);
                }
            })
            selected_value = array.join();
        }

        selected_value = selected_value ? (Array.isArray(selected_value) ? selected_value : selected_value.toString().split(",")) : [];

        let defaultFieldStyle = "field-box";
        let isDisabled = this.props.is_disable === 'yes' ? true : false;

        let options = optionsData.map((data, i) => {
            if (is_search && i === optionsData.length - 1) {
                return (
                    <MenuItem value={data.field_option_value} key={i} className="search-select search-in-table">
                        <SearchField placeholder="Search" />
                    </MenuItem>
                )
            }
            return (
                <MenuItem value={data.field_option_value} key={i} >
                    {data.field_option_label}
                </MenuItem>
            )
        });

        console.log("optionsData222", this.props.optionsData);

        let optionMultiple = optionsData.map((data, i) => {
            return (
                <MenuItem value={data.field_option_value} key={i} >
                    <Checkbox
                        checked={data.checked ? data.checked : false}
                    />
                    {data.field_option_label}
                </MenuItem>
            )
        }
        );

        return (
            <div className={"form-row " + customStyle}>
                <div className={fieldStyle ? fieldStyle : defaultFieldStyle}>
                    {
                        withLabel ? (
                            <InputLabel
                                htmlFor={label}
                                className="label"
                            >
                                {label ? label : defaultLabel}
                                <MandatoryValidator value={is_mandatory} />
                            </InputLabel>
                        ) : null
                    }


                    {isMultiple ?
                        (
                            <Select multiple
                                value={selected_value ? selected_value : default_value}
                                onChange={this.handleMultiSelectChange}
                                disabled={isDisabled ? isDisabled : disabled}
                                className="field"
                                renderValue={selected => (
                                    isChip ?
                                        <div >
                                            {selected.map((value, index) => (
                                                <Chip key={value} label={optionsDataObj[value]} size="small" className="chips secondary-color" deleteIcon={<CancelIcon />}
                                                    onDelete={this.handleDeleteOfChip(value).bind(this)} />
                                            ))}
                                        </div> :
                                        <div >
                                            {selected.map((value, index) => (
                                                <Chip key={value} label={optionsDataObj[value]} size="small" className="chips secondary-color" deleteIcon={<CancelIcon />}
                                                    onDelete={this.handleDeleteOfChip(value).bind(this)} />
                                            ))}
                                        </div>
                                )}
                                inputProps={{
                                    id: { label },
                                }}>
                                {optionMultiple}
                            </Select>
                        ) :
                        (
                            <Select
                                value={selected_value ? selected_value : default_value}
                                onChange={this.handleSingleSelectChangeEvent}
                                disabled={isDisabled ? isDisabled : this.props.disabled}
                                className="field"
                                inputProps={{
                                    id: { label },
                                }}>
                                {options}
                            </Select>
                        )}
                    <ErrorMessage errorStates={errorStates} name={label} />
                </div>
                <Description value={instruction} />
            </div>
        )
    }
}

SelectField.defaultProps = {
    isMultiple: false,
    withLabel: true,
    isChip: false,
    default_value: [],
    selected_value: [],
    is_search: false
}

const mapStateToProps = state => ({
    userDataRecord: state.formData,
});

export default connect(mapStateToProps)(SelectField);
