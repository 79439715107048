import React from 'react';
import 'react-phone-input-2/lib/style.css'

import InputField from '../components/fields/inputs';
import TextAreaField from '../components/fields/textarea';
import RadioField from '../components/fields/radio';
import CheckBoxField from '../components/fields/checkbox';
import NameField from '../components/fields/name';
import SelectField from '../components/fields/select';
import PhoneField from '../components/fields/phone';
import ButtonGroupField from '../components/fields/button-group';
import AddressField from '../components/fields/address';
import DateKendo from "../components/kendo/date-picker";
import TimePickerKendo from "../components/kendo/time-picker";
import DateTimePickerKendo from "../components/kendo/date-time-picker";
import SectionFieldType from "../components/fields/section-field-type";
import UploadField from "../components/fields/upload";
import PolicyIdField from '../components/fields/policyIdField';
import AutoCompleteField from '../components/fields/auto-complete-field';

//Constants
import * as FormConstants from './constants';

export function fieldGenerate(field, props, handleEvent, handleButtonEvent, handleDeleteFile) {

    let options = props.field_option_list ? props.field_option_list : [];
    switch (field) {
        case FormConstants.FieldIds.F_SINGLE_TEXT.code:
            return (<InputField {...props} handleOnChangeValue={handleEvent} customStyle={generateClasses(props)} />);
        case FormConstants.FieldIds.F_MULTI_TEXT.code:
            return (<TextAreaField {...props} handleOnChangeValue={handleEvent} rowmax="2" customStyle={generateClasses(props)} />);
        case FormConstants.FieldIds.F_NAME.code:
            return (<NameField {...props} handleNameChange={handleEvent} childrenAttributes={generateChild(props.children, props, handleEvent)} customStyle={generateClasses(props)} />);
        case FormConstants.FieldIds.F_DATE.code:
            return (<DateKendo {...props} handleDateChange={handleEvent} name="form_date" customStyle={generateClasses(props)} />);
        case FormConstants.FieldIds.F_TIME.code:
            return (<TimePickerKendo {...props} handleDateChange={handleEvent} name="form_time" customStyle={generateClasses(props)} />);
        case FormConstants.FieldIds.F_DATE_TIME.code:
            return (<DateTimePickerKendo {...props} handleDateChange={handleEvent} name="date_and_time" customStyle={generateClasses(props)} />);
        case FormConstants.FieldIds.F_RADIO_GROUP.code:
            return (<RadioField {...props} radioChangeHandle={handleEvent} radioData={options} customStyle={generateClasses(props)} radioFieldStyle="field-inline" />);
        case FormConstants.FieldIds.F_CHECKBOX_GROUP.code:
            return (<CheckBoxField {...props} checkChangeHandle={handleEvent} checkData={options} customStyle={generateClasses(props)} />);
        case FormConstants.FieldIds.F_SINGLE_DROPDOWN_GROUP.code:
            return (<SelectField {...props} radioChangeHandle={handleEvent} optionsData={options} customStyle={generateClasses(props)} />);
        case FormConstants.FieldIds.F_MULTIPLE_DROPDOWN_GROUP.code:
            return (<SelectField {...props} radioChangeHandle={handleEvent} optionsData={options} isMultiple={true} customStyle={generateClasses(props)} />);
        // case FormConstants.FieldIds.F_PHONE_TEXT.code: //customStyle={generateClasses(props)}
        //     return (<InputField {...props} handleOnChangeValue={handleEvent} isPhoneNumber={true} customStyle={generateClasses(props)}/>);
        case FormConstants.FieldIds.F_PHONE_TEXT.code:
            return (<PhoneField {...props} handleOnChangeValue={handleEvent} customStyle={generateClasses(props)} />);
        case FormConstants.FieldIds.F_EMAIL_TEXT.code:
            return (<InputField {...props} handleOnChangeValue={handleEvent} isEmail={true} customStyle={generateClasses(props)} />);
        case FormConstants.FieldIds.F_ACTION_BUTTONS.code:
            return (<ButtonGroupField {...props} handleGroupClick={handleButtonEvent} buttonData={options} customStyle={generateClasses(props)} />);
        case FormConstants.FieldIds.F_CALCULATION.code:
            return (<InputField {...props} handleOnChangeValue={handleEvent} customStyle={generateClasses(props)} />);
        case FormConstants.FieldIds.F_NUMBER_TXT.code:
            return (<InputField {...props} type="number" handleOnChangeValue={handleEvent} isPhoneNumber={true} customStyle={generateClasses(props)} />);
        case FormConstants.FieldIds.F_ADDRESS.code:
            return (<AddressField {...props} handleAddressChange={handleEvent} childrenAttributes={generateChild(props.children, props, handleEvent)} customStyle={generateClasses(props)} />);
        case FormConstants.FieldIds.F_STATE.code:
            return (<SelectField {...props} radioChangeHandle={handleEvent} optionsData={options} customStyle={generateClasses(props)} />);
        case FormConstants.FieldIds.F_SUB_STATE.code:
            return (<SelectField {...props} radioChangeHandle={handleEvent} optionsData={options} customStyle={generateClasses(props)} />);
        case FormConstants.FieldIds.F_SECTION_FIELD_TYPE.code:
            return (<SectionFieldType {...props} />);
        case FormConstants.FieldIds.F_UPLOAD.code:
            return (<UploadField {...props} name={props.field_key} handleUploadCreation={handleEvent} handleDeleteFile={(field) => handleDeleteFile(field)} type="file" customStyle="upload-file" fieldStyle="field-box bg-plane grey-color field-upload" />); // //fieldStyle="field-box bg-plane grey-color field-upload" //
        case FormConstants.FieldIds.F_POLICY_ID.code:
            return (<PolicyIdField {...props} customStyle={generateClasses(props)} />);
        case FormConstants.FieldIds.F_MULTIPLE_DROPDOWN_SELECT_GROUP.code:
            return (<AutoCompleteField {...props} handleMultiSelectChange={handleEvent} optionsData={options} customStyle={generateClasses(props)} />);
        default:
            return;
    }

}

function generateChild(children, field, handleEvent) {
    let childrenComponent = [];
    children &&
        children.forEach(child => {
            childrenComponent.push(fieldGenerate(child.field_type, child, handleEvent))
        })
    return childrenComponent;
}

function generateClasses(props) {
    let sizeClass = "";
    switch (props.field_size) {
        case "S": sizeClass = "small-row"; break;
        case "M": sizeClass = "medium-row"; break;
        case "L": sizeClass = "large-row"; break;
        default: break;
    }
    if (props.is_disable) {
        sizeClass += " form-disabled";
    }
    return sizeClass;
}
