import React, { Component } from 'react';
import FormHelperText from '@material-ui/core/FormHelperText';


class ErrorMessage extends Component {
    render() {
        let { errorStates, name } = this.props;

        return (
            errorStates.map(errorState => {
                return (
                    errorState.isValid ? "" :
                        <FormHelperText
                            id={name}
                            style={{ color: errorState.isValid ? "green" : "red" }}
                        >
                            {errorState.message}
                        </FormHelperText>
                )
            })
        )
    }
}

export default ErrorMessage;