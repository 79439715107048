import * as AjaxHelper from "../utilities/ajax-helper"
import { ApiConstants } from "./common/api-constants"

let apiBaseUrl = () => (ApiConstants.DEV_MODE === 'true' ? ApiConstants.HOST : window.location.origin) + ApiConstants.API_VERSION;

export function getAllClientStates() {
    return AjaxHelper.get(apiBaseUrl() + ApiConstants.CLIENT, null, { "Content-Type": "application/json" });
}

export function createClient(clientData) {
    return AjaxHelper.post(apiBaseUrl() + ApiConstants.CLIENT, clientData, { "Content-Type": "application/json" });
}

export function getClientById(clientId) {
    return AjaxHelper.get(apiBaseUrl() + ApiConstants.CLIENT + "/" + clientId, null, { "Content-Type": "application/json" });
}

export function updateClient(clientId, clientData) {
    return AjaxHelper.put(apiBaseUrl() + ApiConstants.CLIENT + "/" + clientId, clientData, { "Content-Type": "application/json" });
}

export function getAllClientByType(type) {
    return AjaxHelper.get(apiBaseUrl() + ApiConstants.CLIENT + "/type/" + type, null, { "Content-Type": "application/json" });
}

export function getClientSharingGateCode(payload) {
    return AjaxHelper.post(apiBaseUrl() + ApiConstants.CLIENT + "/agent-one-time-auth-code", payload, { "Content-Type": "application/json" });
}

export function getAdminClientCode(clientData) {
    return AjaxHelper.post(apiBaseUrl() + ApiConstants.CLIENT + "/getAdminCode", clientData, { "Content-Type": "application/json" });
}

export function validateAdminClientCode(codeData) {
    return AjaxHelper.post(apiBaseUrl() + ApiConstants.CLIENT + "/validateAdminCode", codeData, { "Content-Type": "application/json" });
}

export async function testClientDBConnection(
    databaseName,
    databaseHost,
    databasePort,
    databaseUsername,
    databasePassword,
) {
    return AjaxHelper.post(
        apiBaseUrl() + ApiConstants.CLIENT + "/testConnection",
        {
            database_name: databaseName,
            database_host: databaseHost,
            database_port: databasePort,
            database_username: databaseUsername,
            database_password: databasePassword,
        },
        { "Content-Type": "application/json" },
    );
}

export function getProfileImage() {
    return AjaxHelper.get(apiBaseUrl() + ApiConstants.CLIENT + "/profile-image", null, { "Content-Type": "application/json" }, false, "blob");
}
