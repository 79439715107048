import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import Fab from '@material-ui/core/Fab';
import Slide from '@material-ui/core/Slide';
import closeIcon from '../../assets/images/icon/close.svg';
import WorkflowHeader from '../../components/workflow/workflow-header';
import Button from '@material-ui/core/Button';

import AddSectionIllus from '../../assets/images/illustration/add-section-1.svg'
import SectionalGreyIllus from '../../assets/images/illustration/sectional-grey.svg';
import EventBasedGreyIllus from '../../assets/images/illustration/event-grey.svg';
import MotorIllus from '../../assets/images/illustration/motor.svg';
import CloseIcon from '../../assets/images/icon/close.svg'

import ConfigBasicDetails from '../../components/configuration/config-basic-details';
import ConfigInsurers from '../../components/configuration/config-insurers';
import ConfigAgents from '../../components/configuration/config-agents';
import ConfigApiDetails from '../../components/configuration/config-api-details';

//Custom component
import Header from '../../components/header'
import Square2 from '../../assets/images/icon/square-2-blue.svg';
import { icons } from '../../utilities/constants';
import ConfigTimeline from '../../components/configuration/config-timeline';
//API
import * as Constants from '../../utilities/constants';
import * as ProgramDataApi from '../../api/program-api';
import * as ConfigApi from '../../api/configuration-api';


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


//  * @author milind on 28-01-2021
//  * @{project} assurekit
class ConfigDashboard extends Component {

    state = {
        isConfigData: [],
        open: false,
        deleteOpen: false,
        stateOpen: false,
        selectedTriggerType: 0,
        customStyleSearch: '',
        selectedIcon: { text: "Square2", icon: Square2 },
        dataReceived: false,
        isConfig: false,
        active: false,
        configSelection: Constants.CONFIG_PROCESS.BASIC_DETAILS,
        configData: {},
        timeline_count: 4,
        programData: {},
        isFinish: false,
        data: {},
        clientId: 0,
        motorConfigId: undefined,
    }

    constructor(props) {
        super(props);
        this.getAllFormData();
    }


    componentWillMount() {
        const match = this.props.match;
        let programkey = match.params.programkey;
        let configData = this.state.configData;
        console.log("this.state.configData: ", this.state.configData);
        configData["program_key"] = programkey;
        configData["timeline_count"] = this.state.timeline_count;
        this.setState({ configData: configData });
        this.getProgramDataAndConfigData(programkey);

    }

    getProgramDataAndConfigData(programkey) {
        ProgramDataApi.getProgramByProgramKey(programkey).then(data => {
            this.setState({ programData: data });

            ConfigApi.getConfig(data.client_id, programkey).then(res => {
                this.setState({ isConfig: !this.state.isConfig });
            })
        })

    }

    handleGetProgramData(programkey) {

        let { data } = this.state
        console.log("handleGetProgramData ", data);
        data.program_key = programkey;
        this.setState({ configData: data });
        if (data.is_finish) {
            this.setState({ isFinish: true });
        }
        if (data.timeline_count === 4) {
            this.handleGetProgramSelection(data.timeline_count);
            this.setState({ timeline_count: data.timeline_count });
        }
        else {
            this.handleGetProgramSelection(data.timeline_count + 1);
            this.setState({ timeline_count: data.timeline_count + 1 });
        }
    }

    handleGetProgramSelection(timeline_count) {
        switch (timeline_count) {
            case 1:
                this.setState({ configSelection: Constants.CONFIG_PROCESS.BASIC_DETAILS });
                break;
            case 2:
                this.setState({ configSelection: Constants.CONFIG_PROCESS.INSURERS });
                break;
            case 3:
                this.setState({ configSelection: Constants.CONFIG_PROCESS.AGENTS });
                break;
            case 4:
                this.setState({ configSelection: Constants.CONFIG_PROCESS.API_DETAILS });
                break;
            default:
                this.setState({ configSelection: Constants.CONFIG_PROCESS.BASIC_DETAILS });
                break;
        }
    }


    getAllFormData() {
        let tableData = [];
        const match = this.props.match;
        let programkey = match.params.programkey;
    }

    handleConfirmDeleteConfiguration = () => {
        const match = this.props.match
        let programKey = match.params.programkey
        let clientId = match.params.clientId
        let motorConfigId = this.state.motorConfigId

        ConfigApi.deleteMotorConfig(clientId, programKey, motorConfigId).then(res => {
            this.setState({ deleteOpen: false, isConfig: false });
        })
    }

    handleClose(key) {
        this.setState({ [key]: false });
    }

    handleOpen(key) {
        this.setState({ [key]: true });
    }

    handleFunction(id) {
    }
    selectTriggerHandle = (triggerType) => (event) => {

        if (triggerType == Constants.CONFIGURATION_PROGRAM.MOTOR.code) {
            this.handleClose("open");
            this.setState({ isConfig: !this.state.isConfig });
        }

    }
    toggleTimeline() {
        this.setState({ active: !this.state.active });
    }

    modifyProgramSelection(configType, programIndex, isFinished) {
        console.log("inside program selection " + configType + " " + programIndex);
        let { timeline_count, configData, isFinish } = this.state;
        if (timeline_count < programIndex) {
            timeline_count = programIndex;
            configData["timeline_count"] = timeline_count;
            console.log("timeline_count", timeline_count);
        }
        this.setState({ configSelection: configType, timeline_count: timeline_count, configData: configData });
    }

    addMotorConfigId(motorConfigId) {
        this.setState({ motorConfigId: motorConfigId });
    }

    selectionConfigType(configType) {
        const match = this.props.match;
        let clientId = match.params.clientId;
        let programkey = match.params.programkey;

        switch (configType) {
            case Constants.CONFIG_PROCESS.BASIC_DETAILS:
                return (
                    <ConfigBasicDetails
                        modifyProgramSelection={this.modifyProgramSelection.bind(this)}
                        addMotorConfigId={this.addMotorConfigId.bind(this)}
                        programData={this.state.programData}
                        // modifyProgramData={this.modifyProgramData.bind(this)}
                        // handleSaveProgramData={this.handleSaveProgramData.bind(this)}
                        programkey={programkey}
                        handleShowConfirmDeleteDialog={() => this.handleOpen("deleteOpen")}
                    // handleDownloadMasterPolicyFile={()=>this.handleDownloadMasterPolicyFile()}
                    />
                );

            case Constants.CONFIG_PROCESS.INSURERS:
                return (
                    <ConfigInsurers
                        modifyProgramSelection={this.modifyProgramSelection.bind(this)}
                        motorConfigId={this.state.motorConfigId}
                        // programData={this.state.programData}
                        // modifyProgramData={this.modifyProgramData.bind(this)}
                        // handleSaveProgramData={this.handleSaveProgramData.bind(this)}
                        clientId={clientId}
                        programkey={programkey}
                        handleShowConfirmDeleteDialog={() => this.handleOpen("deleteOpen")}
                    // handleDownloadMasterPolicyFile={()=>this.handleDownloadMasterPolicyFile()}
                    />
                );

            case Constants.CONFIG_PROCESS.AGENTS:
                return (
                    <ConfigAgents
                        modifyProgramSelection={this.modifyProgramSelection.bind(this)}
                        // programData={this.state.programData}
                        // modifyProgramData={this.modifyProgramData.bind(this)}
                        // handleSaveProgramData={this.handleSaveProgramData.bind(this);
                        clientId={clientId}
                        programkey={programkey}
                        handleShowConfirmDeleteDialog={() => this.handleOpen("deleteOpen")}
                    // handleDownloadMasterPolicyFile={()=>this.handleDownloadMasterPolicyFile()

                    />
                );

            case Constants.CONFIG_PROCESS.API_DETAILS:
                return (
                    <ConfigApiDetails
                        modifyProgramSelection={this.modifyProgramSelection.bind(this)}
                        // programData={this.state.programData}
                        // modifyProgramData={this.modifyProgramData.bind(this)}
                        // handleSaveProgramData={this.handleSaveProgramData.bind(this)}
                        clientId={clientId}
                        programkey={programkey}
                        motorConfigId={this.state.motorConfigId}
                        handleShowConfirmDeleteDialog={() => this.handleOpen("deleteOpen")}
                    // handleDownloadMasterPolicyFile={()=>this.handleDownloadMasterPolicyFile()}
                    />
                );

            default: break;
        }

    }

    render() {
        let { open, data, active, isConfig, configSelection, programData, isConfigData, clientId, deleteOpen } = this.state;
        let toggleTimeline = active ? "toggle-timeline" : "";
        const match = this.props.match;
        clientId = match.params.clientId;
        let programkey = match.params.programkey;

        return (
            <div className="FormBuilderScene">
                <Header headerType="dashboard" positionType="fixed" programKey={programkey} />
                <WorkflowHeader workflowType="normal" clientId={clientId} programKey={programkey} tab="config" handleTabChange={this.handleFunction.bind(this)} />
                <main>
                    {


                        <div className="workflow-fields-sec program-build-sec">
                            {(isConfig) && (

                                <div className="program-build-wrap">
                                    <div className={"program-card-wrap program-build-timeline " + toggleTimeline}>
                                        <div className="card-block">
                                            <div className="card-body">
                                                <ConfigTimeline
                                                    buttonClick={this.toggleTimeline.bind(this)}
                                                    buttonState={this.state.active}
                                                    modifyProgramSelection={this.modifyProgramSelection.bind(this)}
                                                    configSelection={this.state.configSelection}
                                                    timeline_count={this.state.timeline_count}
                                                    isFinish={this.state.isFinish}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className={"program-card-wrap program-build-form " + toggleTimeline}>
                                        <div className="card-block">
                                            <div className="card-body">
                                                {this.selectionConfigType(configSelection)}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            )}

                            {!isConfig && (
                                <section className="blank-page-sec">
                                    <div className="blank-page-wrap">
                                        <div className="blank-page-icon">
                                            <img src={AddSectionIllus} alt="AddSectionIllus" />
                                        </div>
                                        <div className="blank-page-info">
                                            <h5>No Configuration has been added yet!</h5>
                                            <h6>Add Configuration according to business logic</h6>
                                        </div>
                                        <Fab variant="extended" size="large" className="btn btn-large workflow-add-btn no-shadow primary-color" onClick={event => this.handleOpen("open")}>
                                            Add Configuration
                                        </Fab>
                                    </div>
                                </section>
                            )}

                            <Dialog open={open} onClose={event => this.handleClose("open")} TransitionComponent={Transition} maxWidth="xl">
                                <section className="workflow-dialog workflow-dialog-large">
                                    <div className="card-block">
                                        <div className="card-header border-bottom">
                                            <div className="sub-title">
                                                <h5>Configuration Builder</h5>
                                            </div>
                                            <div className="close-dialog">
                                                <img src={CloseIcon} alt="" onClick={event => this.handleClose("open")}></img>
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <Grid container>
                                                <Grid item md={3}>
                                                    <div className="sub-title">
                                                        <h2>What Kind of Configuration you want to build?</h2>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                            <div className="workflow-dialog-options workflow-dialog-options-3">
                                                <div className="worflow-dialog-wrap">
                                                    <div className="workflow-dialog-img">
                                                        <Button variant="contained" color="primary" onClick={this.selectTriggerHandle(Constants.CONFIGURATION_PROGRAM.MOTOR.code).bind(this)}>
                                                            <img src={MotorIllus} alt="MotorIllus"></img>
                                                        </Button>
                                                    </div>
                                                    <h5>Motor</h5>
                                                </div>
                                                <div className="workflow-dialog-wrap-disable">
                                                    <div className="workflow-dialog-img">
                                                        <Button disabled variant="contained" color="primary" >
                                                            <img src={SectionalGreyIllus} alt="SectionalGreyIllus"></img>
                                                        </Button>
                                                    </div>
                                                    <h5>Health</h5>
                                                </div>
                                                <div className="workflow-dialog-wrap-disable">
                                                    <div className="workflow-dialog-img">
                                                        <Button disabled variant="contained" color="primary" >
                                                            <img src={EventBasedGreyIllus} alt="EventBasedGreyIllus"></img>
                                                        </Button>
                                                    </div>
                                                    <h5>Other</h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </Dialog>
                            <Dialog open={deleteOpen} onClose={event => this.handleClose("deleteOpen")} TransitionComponent={Transition} maxWidth="sm">
                                <section className="workflow-dialog workflow-dialog-pri">
                                    <div className="card-block">
                                        <div className="card-header border-bottom p-b-25">
                                            <div className="sub-title">
                                                <h2 className="bold">Confirm Delete?</h2>
                                            </div>
                                            <div className="close-dialog">
                                                <img src={closeIcon} alt="" onClick={event => this.handleClose("deleteOpen")}></img>
                                            </div>
                                        </div>
                                        <div className="card-body p-t-25 p-b-45">
                                            <div className="sub-title">
                                                <h5 className="weight-300 bold p-b-25">Are you sure to about deleting?</h5>
                                                <h5 className="weight-300">This action cannot be undone. AssureGate for this particular program will get disrupted.</h5>
                                            </div>
                                        </div>
                                        <div className="card-footer border-top p-0 p-t-25">
                                            <div className="btn-wrap justify-space-between">
                                                <Fab variant="extended" size="large" className="btn btn-large btn-outline pink-color" onClick={this.handleConfirmDeleteConfiguration}>
                                                    Confirm Delete
                                                </Fab>
                                                <Fab variant="extended" size="large" className="btn btn-large btn-plane grey-color" onClick={event => this.handleClose("deleteOpen")}>
                                                    Cancel
                                                </Fab>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </Dialog>
                        </div>
                    }
                </main>
            </div>
        )
    }
}

export default ConfigDashboard;