import React, { Component } from 'react';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Fab from '@material-ui/core/Fab';
import MenuIcon from '../../assets/images/icon/menu.svg';
import DesignIcon from '../../assets/images/icon/build-blue.svg';
import WorkflowIcon from '../../assets/images/icon/workflow-blue.svg';
import SettingIcon from '../../assets/images/icon/setting-blue.svg';
import UsersIcon from '../../assets/images/icon/users-blue.svg';
import ProgramIcon from '../../assets/images/icon/program-grey.svg';
import ProgramDashIcon from '../../assets/images/icon/square-4-blue.svg';
import SectionDashIcon from '../../assets/images/icon/square-2-blue.svg';
import AddIcon from '@material-ui/icons/Add';
import HomeIcon from '../../assets/images/icon/home-blue.svg';
import PolicyIcon from '../../assets/images/icon/policy-blue.svg';
import TaskIcon from '../../assets/images/icon/task-blue.svg';
import ArrowBackGrey from '../../assets/images/icon/chevron-left.svg';
import MultipleTabActions from '../common/multiple-tab-actions';
import Dialog from '@material-ui/core/Dialog';
import closeIcon from '../../assets/images/icon/close.svg';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Sectionllus from '../../assets/images/illustration/section-circle-1.svg';
import AddFlowIllus from '../../assets/images/illustration/flow-circle-2.svg';
import AddWorkflow2Illus from '../../assets/images/illustration/form-circle-2.svg';
import UserIllus from '../../assets/images/illustration/user-circle.svg';
import Slide from '@material-ui/core/Slide';
import PrioritySelect from '../common/priority-select';
import { Redirect } from 'react-router-dom';
import ProfileOnboard from '../common/profile-onboard';
import Popover from '@material-ui/core/Popover';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import WorkflowclientDrawer from './workflow-client-drawer';
import ProgramShare from '../common/program-share';
import { ExpandMore } from '@material-ui/icons';
import { icons } from '../../utilities/constants';
import * as ProgramApi from '../../api/program-api'
import * as UserApi from '../../api/user-api';
import * as ClientApi from '../../api/client-api';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            <Box p={3}>{children}</Box>
        </Typography>
    );
}

function a11yProps(index) {
    // console.log("a11yProps", index)
    return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
}

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

class WorkflowClientHeader extends Component {

    state = {
        value: 0,
        detailsViewlink: null,
        clientListDrawer: false,
        programFilterDrawer: false,
        triggerDialogOpen: false,
        navigationData: {},
        programs: [],
        openSection: false,
        redirect: 0,
        isTop: true,
        userType: 0,
        clientData: []
    }

    toggleDrawer = (side, open) => event => {
        this.setState({ [side]: open });
    };

    handleDialogOpen(type) {
        this.setState({ [type]: true });
    }

    createNewWorkflow() {
        this.handleDialogOpen("triggerDialogOpen");
    }

    handleClose = (type) => (event) => {
        this.setState({ [type]: false });
    }

    handleChange(event, newValue) {
        this.setState({ value: newValue });
        this.props.handleTabChange(newValue);
    }

    redirectLink = (link) => () => {
        window.location = link;
    }

    redirectLinkOnAddClick = (link) => () => {
        let url = window.location.href;
        let urlSplitArray = url.split('/');
        let currentUrl = "/" + urlSplitArray[urlSplitArray.length - 2] + "/" + urlSplitArray[urlSplitArray.length - 1];

        if (link === currentUrl) {
            window.location = link;
        }
        this.setState({ redirect: link, openSection: true });
    }

    redirectLinkWithProps = () => {
        if (this.state.openSection) {
            this.setState({ openSection: false })
            return <Redirect to={{
                pathname: this.state.redirect,
                state: { id: true }
            }}
            />
        }
    }

    componentDidMount() {

        document.addEventListener('scroll', () => {
            const isTop = window.scrollY < 64;
            if (isTop !== this.state.isTop) {
                this.setState({ isTop })
            }
        });

        // ProgramApi.getAllProgramStates().then(result => {
        //     this.setState({ programs: result });
        // })
        // const match = this.props.match; 
        // let clientID = match.params.clientId;
        let { clientId } = this.props
        // clientId = 
        console.log("New Client Id-- ", clientId)
        if (this.props.workflowType === "normal" || this.props.workflowType === "dashboard") {
            ClientApi.getClientById(clientId).then(result => {
                this.setState({ navigationData: result });
            })
        }

        // if(this.props.workflowType === "details-view" || this.props.workflowType === "dashboard")
        // {
        //     UserApi.getLoggedInUser().then(data=>{
        //         this.setState({userType: data.user_type});
        //     })
        // }
        this.getAllClientData();
    }

    componentWillUnmount() {
        window.onscroll = null;
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.selectedSectionIndex !== this.props.selectedSectionIndex) {
            this.setState({ value: nextProps.selectedSectionIndex });
        }
    }

    // ProgramListClick(event) {
    //     this.setState({ anchorEl: event.currentTarget });
    // }

    // ProgramListClose() {
    //     this.setState({ anchorEl: !this.state.anchorEl });
    // }

    doneChange() {
        // let { programKey } = this.props;
        console.log("doneChange", window.location.pathname.split("/"));
        let urlSplit = window.location.pathname.split("/");
        let redirectLink;
        // switch (urlSplit[2]) {
        //     case "section": redirectLink = "/" + programKey + "/section-dashboard"; break;
        //     case "workflow": redirectLink = "/" + programKey + "/workflows-dashboard"; break;
        // }
        // window.location = redirectLink;
    }

    getAllClientData() {
        ClientApi.getAllClientStates().then(data => {
            this.setState({ clientData: data });
        })
    }

    render() {
        let { clientId, tab, workflowType, customStyle, sectionData, componentName, insideValidation, isFormEdit } = this.props;
        let { value, triggerDialogOpen, programs, anchorEl, userType, clientData } = this.state;

        let programLink = "/" + clientId + "/program-client-dashboard";
        let teamLink = "/" + clientId + "/team-members-dashboard";
        let profileLink = "/" + clientId + "/profile-dashboard";

        const open = Boolean(anchorEl);
        const id = open ? 'simple-popper' : undefined;
        console.log("isFormEdit", isFormEdit);
        return (
            <div className={"workflow-header-wrap " + customStyle} >
                {this.redirectLinkWithProps()}
                {

                    workflowType === 'normal' ? (
                        <section className="workflow-header">
                            <Toolbar>
                                <div className="workflow-nav">
                                    <div className="workflow-mega-wrap">
                                        <div className="divider">
                                            <IconButton onClick={this.toggleDrawer('clientListDrawer', true).bind(this)}>
                                                <img alt="" src={MenuIcon} />
                                            </IconButton>
                                            <Fab variant="extended" size="large" className="btn btn-plane workflow-menu-btn no-shadow">
                                                <h6><span className="app-name">{this.state.navigationData.name}</span> <span className="comp-name">{componentName}</span></h6>
                                                {/* <ExpandMore /> */}
                                            </Fab>
                                        </div>
                                        {/* <Fab variant="extended" size="small" className="btn btn-small btn-plane btn-fab workflow-add-btn no-shadow" onClick={this.createNewWorkflow.bind(this)}>
                                            <AddIcon />
                                        </Fab> */}
                                    </div>
                                </div>
                                {!insideValidation &&
                                    <div className="workflow-nav workflow-form-nav-x">
                                        <div className="workflow-mega-switch">

                                            <Tabs
                                                value={tab}
                                                onChange={this.handleChange.bind(this)}
                                                indicatorColor="primary"
                                                textColor="primary"
                                                variant="scrollable"
                                                scrollButtons="auto"
                                                aria-label="scrollable auto tabs"
                                            >
                                                <Tab value="program" label={
                                                    <Fab variant="extended" size="large" className={"btn workflow-switch-btn no-shadow " + (tab === "program" ? " active" : "")} onClick={this.redirectLink(programLink)}>
                                                        <img alt="ProgramDashIcon" class="icon" src={ProgramDashIcon} /> Program
                                                    </Fab>}
                                                    {...a11yProps(0)} />
                                                <Tab value="team" label={
                                                    <Fab variant="extended" size="large" className={"btn workflow-switch-btn no-shadow " + (tab === "team" ? " active" : "")} onClick={this.redirectLink(teamLink)}>
                                                        <img alt="SectionDashIcon" class="icon" src={SectionDashIcon} /> Team
                                                    </Fab>}
                                                    {...a11yProps(1)} />
                                                <Tab value="profile" label={
                                                    <Fab variant="extended" size="large" className={"btn workflow-switch-btn no-shadow" + (tab === "profile" ? " active" : "")} onClick={this.redirectLink(profileLink)}>
                                                        <img alt="DesignIcon" class="icon" src={DesignIcon} /> Profile
                                                    </Fab>}
                                                    {...a11yProps(2)} />
                                            </Tabs>
                                        </div>
                                    </div>
                                }
                                <div className="workflow-nav">
                                    <div className="workflow-mega-wrap">

                                        {!insideValidation &&
                                            <div>
                                                {/* <ProgramShare /> */}
                                            </div>
                                        }
                                        {/* <Fab variant="extended" size="small" className="btn btn-plane btn-small secondary-color no-shadow workflow-setting-btn">
                                            Preview
                                        </Fab> &nbsp; */}
                                        {/* {!insideValidation &&
                                            <Fab variant="extended" size="small" className="btn btn-plane btn-small no-shadow no-shadow workflow-setting-btn" >
                                                Access
                                        </Fab>
                                        } */}

                                        {insideValidation &&
                                            <div>
                                                <Fab variant="extended" size="small" className="btn btn-small btn-plane transparent-color no-shadow" onClick={this.props.settingChange}>
                                                    <img alt="SettingIcon" class="icon" src={SettingIcon} />
                                                    Setting
                                                </Fab> &nbsp;&nbsp;
                                                <Fab variant="extended" size="small" className="btn btn-small btn-plane primary-color no-shadow" onClick={this.doneChange.bind(this)}>
                                                    Done
                                                </Fab> &nbsp;&nbsp;
                                            </div>
                                        }
                                    </div>
                                </div>

                            </Toolbar>
                            <WorkflowclientDrawer toggleDrawer={this.toggleDrawer.bind(this)} dashboardBackKey={insideValidation ? "workspace" : "dashboard"} insideBlock={this.props.insideBlock} clientKey={this.props.clientId} clientListDrawer={this.state.clientListDrawer} clientData={this.state.clientData} />
                            {/* <WorkflowProgramDrawer toggleDrawer={this.toggleDrawer.bind(this)} programName = {this.props.clientName} programFilterDrawer={this.state.programFilterDrawer} navigationData={this.state.navigationData} /> */}
                        </section>
                    ) : ""
                }

                {/* Dailog for trigger type */}
                {/* <Dialog open={triggerDialogOpen} onClose={this.handleClose("triggerDialogOpen")} TransitionComponent={Transition} maxWidth="xl">
                    <section className="workflow-dialog workflow-dialog-large">
                        <div className="card-block">
                            <div className="card-header border-bottom">
                                <div className="sub-title">
                                    <h5>Component Creator</h5>
                                </div>
                                <div className="close-dialog">
                                    <img src={closeIcon} alt="" onClick={this.handleClose("triggerDialogOpen")}></img>
                                </div>
                            </div>
                            <div className="card-body">
                                <Grid container>
                                    <Grid item md={4}>
                                        <div className="sub-title">
                                            <h2>Do you want to create a new Component ?</h2>
                                        </div>
                                    </Grid>
                                </Grid>
                                <div className="workflow-dialog-options">
                                    <div className="worflow-dialog-wrap">
                                        <div className="workflow-dialog-img">
                                            <Button variant="contained" color="primary" onClick={this.redirectLinkOnAddClick(sectionLink)}>
                                                <img src={Sectionllus} alt="Sectionllus"></img>
                                            </Button>
                                        </div>
                                        <h5>Section</h5>
                                    </div>
                                    <div className="worflow-dialog-wrap">
                                        <div className="workflow-dialog-img">
                                            <Button variant="contained" color="primary" onClick={this.redirectLinkOnAddClick(formLink)}>
                                                <img src={AddWorkflow2Illus} alt="AddWorkflow2Illus"></img>
                                            </Button>
                                        </div>
                                        <h5>Forms</h5>
                                    </div>
                                    <div className="worflow-dialog-wrap">
                                        <div className="workflow-dialog-img">
                                            <Button variant="contained" color="primary" onClick={this.redirectLinkOnAddClick(workflowLink)}>
                                                <img src={AddFlowIllus} alt="AddFlowIllus"></img>
                                            </Button>
                                        </div>
                                        <h5>Flow</h5>
                                    </div>
                                    <div className="worflow-dialog-wrap">
                                        <div className="workflow-dialog-img">
                                            <Button variant="contained" color="primary" onClick={this.redirectLink(userLink)}>
                                                <img src={UserIllus} alt="UserIllus"></img>
                                            </Button>
                                        </div>
                                        <h5>User</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </Dialog> */}
            </div>
        )
    }
}

WorkflowClientHeader.defaultProps = {
    workflowType: 'normal',
    customStyle: '',
    sectionData: [],
    tabActions: ["message", "edit", "duplicate", "delete", "pagination"],
}

export default WorkflowClientHeader;