import React, { Component } from 'react';
import ButtonField from './button';

class ButtonGroupField extends Component {

    handleAllClick = (field) => (buttonId) => {
        this.props.handleGroupClick(field, buttonId);
    }

    render() {
        let {
            id,
            fieldStyle,
            customStyle,
            buttonData,
        } = this.props;

        let defaultFieldStyle = "field-box";
        // let isDisabled = (this.props.is_disable === 'yes');
        //hide all hidden field
        buttonData = buttonData.filter(button => button.is_hidden !== "yes");
        console.log("buttonData", buttonData);
        return (
            <div className={"form-row btn-grp-row" + customStyle}>
                <div className={fieldStyle ? fieldStyle : defaultFieldStyle}>
                    {
                        buttonData.map(button => {
                            return (
                                <ButtonField {...this.props} color={button.field_metadata} name={button.field_option_label} value={button.field_option_value} handleClick={this.handleAllClick(id)} />
                            )
                        })
                    }
                </div>
            </div>
        )
    }
}

export default ButtonGroupField;
