import React, { Component } from 'react';
import { Button, Dialog, DialogActions, DialogTitle, DialogContent, DialogContentText } from '@material-ui/core';

class DialogBox extends Component {

    render() {
        const {
            open,
            title,
            content,
            onClose
        } = this.props;

        return (
            <Dialog
                open={open}
                aria-labelledby="dialog-box-title"
                aria-describedby="dialog-box-description"
                maxWidth="sm"
            >
                <DialogTitle id="dialog-box-title">{title}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="dialog-box-description" >
                        {content}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button autoFocus onClick={onClose} color="primary">
                    Close
                </Button>
                </DialogActions>
            </Dialog>
        )
    }
}

DialogBox.defaultProps = {
    backdropClick: false
}

export default DialogBox;