import React, { Component } from 'react';
import AddFieldsComponent from '../common/add-fields';
import Fab from '@material-ui/core/Fab';
import EyeIcon from '../../assets/images/icon/eye-blue.svg';
import { Scrollbars } from 'react-custom-scrollbars';

//Constants
import * as Constants from '../../utilities/constants';

class ProgramStackHolder extends Component {

    state = {
        stakeholder: {},
    }

    componentWillMount() {
        let stakeholder = this.state.stakeholder;
        let { programData } = this.props;
        if (programData.hasOwnProperty("stake_holder") && programData.stake_holder != null) {
            stakeholder = programData.stake_holder;
        }
        else {
            stakeholder["insurance_company"] = [];
            stakeholder["insurance_broker"] = [];
            stakeholder["insurance_client"] = [];
            stakeholder["insurance_policy_for"] = [];
            stakeholder["insurance_vendors"] = [];
            stakeholder["program_key"] = this.props.programkey;
        }

        this.setState({ stakeholder: stakeholder });
    }

    handleNextClick() {
        let { stakeholder } = this.state;

        if (stakeholder.insurance_company.length > 0 || stakeholder.insurance_broker.length > 0 || stakeholder.insurance_client.length > 0 || stakeholder.insurance_policy_for.length > 0 || stakeholder.insurance_vendors.length > 0) {
            this.props.handleSaveProgramData();
            this.props.modifyProgramSelection(Constants.PROGRAM.MASTER_POLICY, 2);
        }
    }

    handleBackClick() {
        this.props.modifyProgramSelection(Constants.PROGRAM.STACK_HOLDER, 1);
    }

    handleStakeholderData(stakeData) {
        let { programData } = this.props;
        console.log("stakeData", stakeData, "programData", programData);
        this.setState({ stakeholder: stakeData });
        console.log("programData123", programData);
        programData["stake_holder"] = stakeData;
        this.props.modifyProgramData(programData);
    }

    render() {
        return (
            <div className="drawer-tabs workflow-tabs-wrap">
                <div className="drawer-tabs-header border-bottom">
                    <div className="workflow-title">
                        <h5>Stake Holder</h5>
                    </div>
                    <div className="workflow-tabs-search">
                        <Fab variant="extended" size="large" className="btn btn-large secondary-color workflow-add-btn no-shadow" style={{ minWidth: '180px' }} onClick={this.props.handleDownloadMasterPolicyFile}>
                            <img src={EyeIcon} alt="EyeIcon" className="MuiSvgIcon" />
                            Master Policy
                        </Fab>
                    </div>
                </div>
                <div className="tab-container">
                    <Scrollbars style={{ width: '100%', height: '100%' }}>
                        <AddFieldsComponent
                            addFieldType="add-single-field"
                            stakeholder={this.state.stakeholder}
                            programData={this.props.programData}
                            handleStakeholderData={this.handleStakeholderData.bind(this)}
                        />
                    </Scrollbars>
                </div>
                <div className="card-footer border-top p-l-0 p-r-0">
                    <div className="btn-wrap">
                        <Fab variant="extended" size="large" className="btn btn-large btn-plane primary-color" onClick={this.handleNextClick.bind(this)}>
                            Next
                        </Fab>
                        <Fab variant="extended" size="large" className="btn btn-large btn-plane grey-color" onClick={this.handleBackClick.bind(this)}>
                            Back
                        </Fab>
                    </div>
                </div>
            </div>
        )
    }
}

export default ProgramStackHolder;