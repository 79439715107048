export const ApiConstants = {
    HOST: `${process.env.REACT_APP_API_HOST_BASE_URL}`,
    API_VERSION: `${process.env.REACT_APP_API_HOST_VERSION}`,
    FORM_ELEMENT: "/form-element",
    FORM_ELEMENT_DATA: "/form-element/form",
    FORM_FIELD_METADATA: "/form-metadata",
    FORM: "/form",
    FORM_TEMPLATE: "/form-template",
    FIELD: "/field",
    DATA: "/data",
    WORKFLOW: "/workflow",
    WORKFLOW_ACTION: "/workflow-action",
    PROGRAM: "/program",
    PROGRAM_SECTION: "/program/section",
    STATE: "/program/state",
    PROGRAM_DATA: "/program-data",
    PROCESS_PROGRESS: "/process/progress",
    USER: "/user",
    PERMISSION: "/permission",
    PERMISSION_SET: "/permissionSet",
    AUDIT_TRAIL: "/audit-trail",
    AUTH_USER: "/authUser",
    NOTIFICATION: "/notification",
    DUPLICATE: "/duplicate",
    USER_PERMISSION_MAPPING: "/userPermissionMapping",
    PAYMENT: "/payment",
    EMAIL_VERIFICATION: "/verificationEmail",
    SEARCH: "/search",
    FILE_UPLOAD: "/file",
    CLIENT: "/clients",
    TEAM_MEMBER: "/team",
    CONFIGURATION: "/config",
    MOTOR_CONFIGURATION: "/motor",
    INSURER_KIT: "/insurer-kits",
    ADD_INSURER: "/add-insurer",
    INSURER_DETAILS: "/insurers",
    ASSUREGATE_HOST_URL: `${process.env.REACT_APP_ASSURE_GATE_BASE_URL}`,
    DATA_SOURCE_CONFIG: "/data-source-config",
    DEV_MODE: `${process.env.REACT_APP_DEV_MODE}`,
    INTEGRATIONS: "/integrations",
}
