import React, { Component } from 'react';

import GridTable from '../../components/kendo/grid';
import { Redirect } from 'react-router-dom';

class DataGridTable extends Component {

    state = {
        redirectValue: false,
        dataViewLink: '',
        redirectOnNewForm: false,
    }

    redirectLink() {
        this.setState({ redirectOnNewForm: true });
        //window.location = link;
    }

    redirectWithNewProps() {
        let { dataViewLink } = this.state;
        return <Redirect to={{
            pathname: dataViewLink,
            state: { id: this.props.selectedSectionIndex }
        }}
        />
    }

    redirectOnAddNewClick() {
        let { programKey, formKey, clientId } = this.props;
        let path = '/' + clientId + '/' + programKey + "/form/" + formKey + "/publish";
        if (this.state.redirectOnNewForm) {
            console.log("redirectOnAddNewClick: ", path);
            return <Redirect to={{
                pathname: '/' + clientId + '/' + programKey + "/form/" + formKey + "/publish",
                state: { addNewForm: true }
            }}
            />
        }
    }

    redirectToDetails(data) {
        let { programKey, clientId } = this.props;
        let dataViewLink = '/' + clientId + '/' + programKey + "/details-view/" + data.process_id;
        this.setState({ redirectValue: true, dataViewLink: dataViewLink });

        //this.redirectLink(dataViewLink);
        //this.redirectWithProps(dataViewLink);
    }

    render() {
        let { gridRecordDetails, programKey, formKey, clientId } = this.props;
        let formViewLink = '/' + clientId + '/' + programKey + "/form/" + formKey + "/publish";
        return (
            <div className="workflow-fields-sec">
                <div className="drawer-tabs workflow-tabs-wrap p-0">

                    <div className="entry-grid-wrap">
                        <div className="grid-table-wrap">
                            <GridTable gridRecordDetails={gridRecordDetails} gridRecordDetailsChange={this.props.gridRecordDetailsChange} getSectionDetails={() => this.props.getSectionDetails()} getFormData={(formKey) => this.props.getFormData(formKey)} onRowClickEvent={e => this.redirectToDetails(e.dataItem)} redirectLink={() => this.redirectLink()} />
                        </div>
                    </div>
                </div>
                {this.state.redirectValue && this.redirectWithNewProps()}
                {this.redirectOnAddNewClick()}
            </div>
        )
    }
}

export default DataGridTable;