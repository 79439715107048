import { getTenant } from './multitenant';

//Convenience method for post xhr requests
export function post(url, data, headers, params, isFormdata, callback) {
	params = params || "";
	//params = urlEncode(params);
	return new Promise((resolve, reject) => {
		doAjax(url, headers, "POST", true, resolve, reject, params, data, isFormdata, callback);
	});
}

//Convenience method for put xhr requests
export function put(url, data, headers, params) {
	params = params || "";
	//params = urlEncode(params);
	return new Promise((resolve, reject) => {
		doAjax(url, headers, "PUT", true, resolve, reject, params, data);
	});
}

//Convenience method get post xhr requests
export function get(url, params, headers, download, responseType) {
	params = params || "";
	//params = urlEncode(params);

	return new Promise((resolve, reject) => {
		doAjax(url, headers, "GET", true, resolve, reject, params, null, null, null, download, responseType);
	});
}

//Convenience method for post xhr requests
export function del(url, data, headers, params) {
	params = params || "";
	//params = urlEncode(params);
	return new Promise((resolve, reject) => {
		doAjax(url, headers, "DELETE", true, resolve, reject, params, data);
	});
}

// Common function to initialize XML Http Request object 
function getHttpRequestObject() {
	// Define and initialize as false
	var xhr = false;

	// Mozilla/Safari/Non-IE
	if (window.XMLHttpRequest) {
		xhr = new XMLHttpRequest();
	}
	return xhr;
}

function isJsonString(str) {
	try {
		JSON.parse(str);
	} catch (e) {
		return false;
	}
	return true;
}

function addAuthHeader(headers) {
	let token = sessionStorage.getItem("access_token");
	if (token)
		headers["Authorization"] = "Bearer " + token;
}

function addTenantHeader(headers) {
	let tenant = getTenant();
	if (tenant)
		headers["tenant"] = tenant;
}

function handleEvent(e) {
	console.log(`${e.type}: ${e.loaded} bytes transferred\n`);
}

function addListeners(xhr) {
	xhr.addEventListener('loadstart', handleEvent);
	xhr.addEventListener('load', handleEvent);
	xhr.addEventListener('loadend', handleEvent);
	xhr.addEventListener('progress', handleEvent);
	xhr.addEventListener('error', handleEvent);
	xhr.addEventListener('abort', handleEvent);
}

// Does the AJAX call to URL specific with rest of the parameters
//Returns response if status code is 200-299, otherwise rejects with status number and text
export function doAjax(url, headers, method, async, resolve, reject, params, data, isFormdata, callback, isDownload, responseType) {

	// Set the variables
	url = url || "";
	method = method || "GET";
	async = async || true;
	if (isFormdata != true) {
		data = JSON.stringify(data) || null;
	}
	params = params || null;

	if (params !== null) {
		console.log("Request Params");
		console.log(params);
	}
	if (data !== null) {
		console.log("Request Body");
	}

	if (url === "") {
		console.error("URL can not be null/blank");
		return false;
	}
	var xhr = getHttpRequestObject();
	// If AJAX supported
	if (xhr !== false) {

		if (params !== null) {
			var flattenedParams = "";
			var first = true;
			for (var key in params) {
				if (params.hasOwnProperty(key)) {
					if (first) {
						flattenedParams = key + "=" + params[key];
						first = false;
					} else {
						flattenedParams = flattenedParams + "&" + key + "=" + params[key];
					}
				}
			}
			url = url + "?" + encodeURI(flattenedParams);
		}
		if (isDownload) {
			xhr.responseType = 'blob';
		}
		if (responseType) {
			xhr.responseType = "blob";
		}
		// xhr.withCredentials = true;
		addListeners(xhr);
		xhr.upload.onprogress = function (e) {
			console.log("uploading", ((e.loaded * 100) / e.total), e);
			// const pro = new ProgressEvent(e);
			callback && callback(((e.loaded * 100) / e.total));
		};

		xhr.open(method, url, async);

		//Add auth header
		addAuthHeader(headers);
		//add tenant header
		addTenantHeader(headers);
		//Set headers passed in
		for (var header in headers) {
			// skip loop if the property is from prototype
			if (!headers.hasOwnProperty(header)) continue;
			xhr.setRequestHeader(header, headers[header])
			console.log("Auth", header, headers[header]);
		}
		// Set request header (optional if GET method is used)
		/*if(method == "POST")
		{
			xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
		}*/

		xhr.onload = function () {
			if (this.status >= 200 && this.status < 300) {
				console.log("Response", xhr);

				if (isDownload) {
					fileDownload(xhr);
				}
				if (xhr.response === null || !isJsonString(xhr.response)) {
					console.log(xhr.response);
					resolve(xhr.response);
				} else {
					console.log(JSON.parse(xhr.response));
					resolve(JSON.parse(xhr.response));
				}
			} else {
				if (this.status === 401) {
					//redirect login when 401
					sessionStorage.removeItem("access_token");
					window.location = "/";
				}
				/*reject({
					status: this.status,
					statusText: xhr.statusText
				});*/
				// console.log("Rejection");
				// console.log(xhr);
				reject(xhr);
			}
		};

		xhr.onerror = function () {
			/*reject({
				status: this.status,
				statusText: xhr.statusText
			});*/
			// console.log("Rejection");
			// console.log(xhr);
			reject(xhr);
		};
		// Send data
		xhr.send(data);
	}
	else {
		console.log("Please use browser with Ajax support.!");
	}
}

function fileDownload(xhr) {
	var disposition = xhr.getResponseHeader('Content-Disposition');
	console.log("fileDownload 1", (disposition && disposition.indexOf('attachment') !== -1));
	if (disposition && disposition.indexOf('attachment') !== -1) {
		console.log("fileDownload 2");
		var filename = "";
		if (disposition && disposition.indexOf('attachment') !== -1) {
			var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
			var matches = filenameRegex.exec(disposition);
			if (matches != null && matches[1]) filename = matches[1].replace(/['"]/g, '');
		}
		var type = xhr.getResponseHeader('Content-Type');

		var blob = new Blob([xhr.response], { type: type });
		if (typeof window.navigator.msSaveBlob !== 'undefined') {
			// IE workaround for "HTML7007: One or more blob URLs were revoked by closing the blob for which they were created. These URLs will no longer resolve as the data backing the URL has been freed."
			window.navigator.msSaveBlob(blob, filename);
		} else {
			var URL = window.URL || window.webkitURL;
			var downloadUrl = URL.createObjectURL(blob);
			if (filename) {
				// use HTML5 a[download] attribute to specify filename
				var a = document.createElement("a");
				// safari doesn't support this yet
				if (typeof a.download === 'undefined') {
					window.location = downloadUrl;
				} else {
					a.href = downloadUrl;
					a.download = filename;
					document.body.append(a);
					a.click();
					a.remove();
				}
			} else {
				window.location = downloadUrl;
			}
			console.log("downloadUrl", downloadUrl);
			setTimeout(function () { window.URL.revokeObjectURL(downloadUrl); }, 100); // cleanup
		}
	}
}