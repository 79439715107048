import React, { Component } from 'react';
import Header from '../header';
import WorkflowFields from '../workflow/workflow-fields';
import WorkflowHeader from '../../components/workflow/workflow-header';
import WorkflowSetups from '../workflow/workflow-setups';
import WorkflowSetting from '../workflow/workflow-setting';
import Drawer from '@material-ui/core/Drawer';
import { Scrollbars } from 'react-custom-scrollbars';
//dialog
import Dialog from '@material-ui/core/Dialog';
import CloseIcon from '../../assets/images/icon/close.svg';
import Fab from '@material-ui/core/Fab';
//Custom
import FieldOperation from './field-operations-component';

//API
import * as FormElementApi from '../../api/form-builder-api';

class WorkflowMaster extends Component {

  state = {
    settingWorkflow: false,
    right: false,
    conditionChoice: true,
    parentIndex: -1,
    childIndex: -1,
    openAction: false,
    openTrigger: false,
    selectedStepData: [],
    dataIndex: 0,
    actionTriggerId: 0,
    newOrEdit: "",
    editDialog: false,
    fieldListMaster: [],
    formFieldDetailsMaster: [],
    fieldOperationMaster: {},
    newParentIndex: -1,
    newChildIndex: -1,
    stepDataCopy: {},
    conditionExist: false,
  }

  componentWillMount() {
    let formElementPromise = FormElementApi.getFormFieldMetadataTypes();
    formElementPromise.then(data => {
      this.setState({ formFieldDetailsMaster: data });
    })
  }

  genFieldList(index) {
    let { formData, stepDataList } = this.props;
    let { stepDataCopy, formFieldDetailsMaster } = this.state;
    let allfieldConditions = [];

    let fieldListMaster = [];
    let fieldConditionObj = {};

    if (formData.form_field_list && formData.form_field_list.length > 0) {
      fieldListMaster = this.generateFieldListWithGroup(formData.form_field_list);
      this.setState({ fieldListMaster: fieldListMaster });
    }

    fieldListMaster.forEach(list => {
      formFieldDetailsMaster.forEach(detail => {
        if (list.field_type === detail.field_code_txt) {
          fieldConditionObj[list.field_option_value] = detail.field_conditions;
          return;
        }
      })
    })

    if (formFieldDetailsMaster.length > 0) {
      stepDataCopy = JSON.parse(JSON.stringify(stepDataList[index]));
      stepDataCopy.field_operations.forEach(field => {
        field["field_conditions"] = fieldConditionObj[field.field_id];
      })
      this.setState({ stepDataCopy: stepDataCopy });
    }
  }

  generateFieldListWithGroup(form_field_list) {
    let fieldList = [];
    form_field_list.forEach(formField => {
      if (formField.group_field) {
        if (formField.field_option_list) {
          formField.field_option_list.forEach(fieldOption => {
            let obj = {
              field_option_label: fieldOption.field_option_label,
              field_option_value: formField.id + "#" + fieldOption.field_option_value,
              field_type: formField.field_type
            }
            fieldList.push(obj);
          })
        }
      } else {
        let obj = {
          field_option_label: formField.label,
          field_option_value: formField.id,
          field_type: formField.field_type
        }
        fieldList.push(obj);
      }
    })
    return fieldList;
  }

  toggleDrawer = (side, open) => (event, newOrEdit, parentIndex, childIndex, conditionExist) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    this.setState({ [side]: open, newOrEdit: newOrEdit, parentIndex: parentIndex, childIndex: childIndex, conditionExist: conditionExist });
  };

  //delete an action
  handleClickOpen() {
    this.setState({ openAction: true });
  }

  handleClose() {
    this.setState({ openAction: false });
  }

  handleCloseEditDialog() {
    this.setState({ editDialog: false });
  }

  handleFieldOperationMaster = () => () => {
    this.setState({ fieldOperationMaster: {} });
  }

  handleDeleteAction = (event) => {

    if (this.state.selectedStepData.actions.length === 1) {
      this.props.deleteSelectedActionTrigger(this.state.selectedStepData.id);
    } else {
      this.state.selectedStepData.actions.splice(this.state.dataIndex, 1);
      this.props.handleNewAction(this.state.selectedStepData);
    }
    this.setState({ openAction: false });
  }

  deleteAction = () => (event, stepData, parentIndex, childIndex) => {
    this.setState({ dataIndex: childIndex });
    this.setState({ selectedStepData: stepData });
    this.handleClickOpen();
  };

  //delete an actionTrigger
  dialogActionTriggerOpen() {
    this.setState({ openTrigger: true });
  }

  dialogActionTriggerClose() {
    this.setState({ openTrigger: false });
  }

  handleDeleteActionTrigger = (event) => {
    this.props.deleteSelectedActionTrigger(this.state.actionTriggerId);
    this.setState({ openTrigger: false });
  }


  getActionTrigggerId = () => (event, triggerId) => {
    this.setState({ actionTriggerId: triggerId })
    this.dialogActionTriggerOpen()
  }

  renderDialogDeleteAction() {
    return (
      <Dialog open={this.state.openAction} onClose={this.handleClose.bind(this)} aria-labelledby="simple-dialog-title">
        <section className="workflow-dialog workflow-dialog-small workflow-dialog-pri">
          <div className="card-block">
            <div className="card-header border-bottom p-b-25">
              <div className="sub-title">
                <h2 className="bold">Delete</h2>
              </div>
              <div className="close-dialog">
                <img src={CloseIcon} alt="CloseIcon" onClick={this.handleClose.bind(this)} ></img>
              </div>
            </div>
            <div className="card-body p-t-25 p-b-45">
              <div className="sub-title">
                <h5 className="weight-300">Do you really want to delete the Action ?</h5>
              </div>
            </div>
            <div className="card-footer p-0 p-t-25">
              <div className="btn-wrap">
                <Fab variant="extended" size="large" className="btn btn-large btn-plane primary-color" onClick={this.handleDeleteAction.bind(this)}>
                  Delete
                </Fab>
                <Fab variant="extended" size="large" className="btn btn-large btn-plane grey-color" onClick={this.handleClose.bind(this)}>
                  Cancel
                </Fab>
              </div>
            </div>
          </div>
        </section>
      </Dialog>
    );
  }

  renderDialogDeleteActionTrigger() {
    return (
      <Dialog open={this.state.openTrigger} onClose={this.handleClose.bind(this)} aria-labelledby="simple-dialog-title">
        <section className="workflow-dialog workflow-dialog-small workflow-dialog-pri">
          <div className="card-block">
            <div className="card-header border-bottom p-b-25">
              <div className="sub-title">
                <h2 className="bold">Delete</h2>
              </div>
              <div className="close-dialog">
                <img src={CloseIcon} alt="CloseIcon" onClick={this.dialogActionTriggerClose.bind(this)} ></img>
              </div>
            </div>
            <div className="card-body p-t-25 p-b-45">
              <div className="sub-title">
                <h5 className="weight-300">Do you really want to delete the ActionTrigger ?</h5>
              </div>
              <div className="card-footer p-0 p-t-25">
                <div className="btn-wrap">
                  <Fab variant="extended" size="large" className="btn btn-large btn-plane primary-color" onClick={this.handleDeleteActionTrigger.bind(this)}>
                    Delete
                  </Fab>
                  <Fab variant="extended" size="large" className="btn btn-large btn-plane grey-color" onClick={this.dialogActionTriggerClose.bind(this)} >
                    Cancel
                  </Fab>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Dialog>
    );
  }

  handleStateUpdate(key, value) {
    this.setState({ [key]: value });
  }

  openCloseEditDialog = (index) => () => {
    this.genFieldList(index);
    this.setState({ editDialog: true, newParentIndex: index });
  }

  editSubWorkflowButton(parentIndex, childIndex) {
    let { stepDataCopy } = this.state;
    let { stepDataList } = this.props;
    stepDataCopy = JSON.parse(JSON.stringify(stepDataList[parentIndex]));
    this.setState({ stepDataCopy: stepDataCopy, newChildIndex: childIndex, newParentIndex: parentIndex });
  }


  handleSaveEditDialog() {
    let { stepDataList } = this.props;
    let { stepDataCopy, newParentIndex, fieldOperationMaster } = this.state;

    if (fieldOperationMaster.hasOwnProperty("field_id") && fieldOperationMaster.hasOwnProperty("operation")) {
      fieldOperationMaster["position"] = stepDataCopy.field_operations.length;
      stepDataCopy.field_operations.push(fieldOperationMaster);
    }
    else {
      let validationOfNextOperation = stepDataCopy.field_operations.length;
      stepDataCopy.field_operations.forEach((field, index) => {
        if (index === validationOfNextOperation - 1) {
          field.next_operation = null;
        }
      })
    }

    stepDataList[newParentIndex].field_operations = stepDataCopy.field_operations;


    this.props.handleStepDataListChange(stepDataList);
    this.setState({ stepDataCopy: stepDataCopy, editDialog: false, fieldOperationMaster: {} })
    this.props.handleNewAction(stepDataCopy);
  }

  handleCloseEditDialog() {
    let { stepDataCopy } = this.state;
    this.setState({ editDialog: false, fieldOperationMaster: {}, stepDataCopy: stepDataCopy });
  }

  renderEditDialog() {
    return (
      <Dialog open={this.state.editDialog} onClose={this.handleCloseEditDialog.bind(this)} aria-labelledby="simple-dialog-title" maxWidth="md">
        <section className="workflow-dialog workflow-dialog-small workflow-dialog-pri">
          <div className="card-block">
            <div className="card-header border-bottom p-b-25">
              <div className="sub-title">
                <h2 className="bold">Edit Workflow</h2>
              </div>
              <div className="close-dialog">
                <img src={CloseIcon} alt="" onClick={this.handleCloseEditDialog.bind(this)}></img>
              </div>
            </div>
            <div className="card-body p-t-30 p-b-30">
              <div className="workflow-dialog-options p-0">
                <div className="worflow-dialog-wrap tabs-container-scroll">
                  <Scrollbars style={{ width: '100%', height: '100%' }}>
                    <FieldOperation
                      fieldList={this.state.fieldListMaster}
                      formFieldDetails={this.state.formFieldDetailsMaster}
                      fieldDataList={this.props.formData.hasOwnProperty("form_field_list") ? this.props.formData.form_field_list : []}
                      stepData={this.state.stepDataCopy}
                      fieldOperation={this.state.fieldOperationMaster}
                      workflowData={this.props.workflowData}
                      sectionFormData={this.props.sectionFormData}
                      programDataLinkList={this.props.programDataLinkList}
                      handleFieldOperationUpdate={(value) => this.handleStateUpdate("fieldOperationMaster", value)}
                      handleStepDataUpdate={(value) => this.handleStateUpdate("stepDataCopy", value)}
                      handleFieldOperationMaster={this.handleFieldOperationMaster.bind(this)}
                    />
                  </Scrollbars>
                </div>
              </div>
            </div>
            <div className="card-footer border-top p-l-0 p-r-0">
              <div className="btn-wrap">
                <Fab variant="extended" size="large" className="btn btn-large btn-plane primary-color" onClick={this.handleSaveEditDialog.bind(this)}>Save Criteria</Fab>
                <Fab variant="extended" size="large" className="btn btn-large btn-plane grey-color" onClick={this.handleCloseEditDialog.bind(this)}>Cancel</Fab>
              </div>
            </div>
          </div>
        </section>
      </Dialog>
    );
  }

  settingWorkflowChange() {
    this.toggleDrawerWorkflowSettings("settingWorkflow", true)
  }

  toggleDrawerWorkflowSettings(side, open) {
    this.setState({ [side]: open })
  }

  render() {
    let { newParentIndex, newChildIndex, parentIndex, childIndex, stepDataCopy, conditionExist } = this.state;
    let { workflowData, formData, stepDataList, programKey, programState, programSubState, clientId } = this.props;

    const sideList = side => (
      <section className="workflow-setup-sec">
        <WorkflowSetups
          workflowData={workflowData}
          formData={formData}
          programDataLinkList={this.props.programDataLinkList}
          programData={this.props.programData}
          sectionFormData={this.props.sectionFormData}
          toggleDrawer={this.toggleDrawer(side, false)}
          handleNewAction={this.props.handleNewAction}
          newOrEdit={this.state.newOrEdit}
          parentIndex={parentIndex}
          childIndex={childIndex}
          stepDataList={stepDataList}
          programkey={programKey}
          programState={programState}
          programSubState={programSubState}
          stepDataCopy={stepDataCopy}
          newParentIndex={newParentIndex}
          newChildIndex={newChildIndex}
          conditionExist={conditionExist}
          permissionList={this.props.permissionList}
          paymentWorkflowList={this.props.paymentWorkflowList}
          clientId={clientId}
          ledgerWorkflowList={this.props.ledgerWorkflowList}
        />
      </section>
    );
    return (
      <div className="workflow-scene">
        <Header headerType="dashboard" positionType="fixed" programKey={programKey} />
        <WorkflowHeader workflowType="normal" tab="form" dashboardBackKey="workspace" insideValidation={true} insideBlock="workflow" settingChange={this.settingWorkflowChange.bind(this)} programKey={programKey} clientId={clientId} componentName={this.props.workflowName} />
        <main>

          {/* <Button onClick={this.toggleDrawer('right', true)}>menu</Button> */}
          <WorkflowFields
            toggleDrawer={this.toggleDrawer('right', true)}
            workflowData={workflowData}
            formData={formData}
            stepDataList={stepDataList}
            deleteAction={this.deleteAction().bind(this)}
            getActionTrigggerId={this.getActionTrigggerId()}
            openCloseEditDialog={this.openCloseEditDialog.bind(this)}
            editSubWorkflowButton={this.editSubWorkflowButton.bind(this)}
            programState={programState}
            programSubState={programSubState}
          />
          {this.renderDialogDeleteAction()}
          {this.renderDialogDeleteActionTrigger()}
          {this.renderEditDialog()}
        </main>
        <Drawer anchor="right" open={this.state.right} onClose={this.toggleDrawer('right', false)}>
          {sideList('right')}
        </Drawer>
        <Drawer anchor="right" open={this.state.settingWorkflow} onClose={(event) => { this.toggleDrawerWorkflowSettings('settingWorkflow', false) }}>
          <WorkflowSetting
            toggleDrawerWorkflowSettings={this.toggleDrawerWorkflowSettings.bind(this)}
            workflowData={workflowData}
            formData={formData}
            changeWorkflowDetails={(workflowData) => this.props.changeWorkflowDetails(workflowData)}
          />
        </Drawer>
      </div>
    )
  }
}

export default WorkflowMaster;