import React, { Component } from 'react';
/* import SettingFlowIllust from '../../assets/images/illustration/login-iso.svg'; */
import Header from '../../components/header';
import WorkflowClientHeader from '../../components/workflow/workflow-client-header';
import ProfileIcon from '../../assets/images/icon/single-profile.svg';
import ArrowIcon from '../../assets/images/icon/chevron-blue.svg';
/* import SelectField from '../../components/fields/select'; 
import TextAreaField from '../../components/fields/textarea';
import taskBlueIcon from '../../assets/images/icon/task-blue.svg';
import updateBlueIcon from '../../assets/images/icon/update-blue.svg'; */
import KeyBlueIcon from '../../assets/images/icon/key-blue.svg';
import SettingIcon from '../../assets/images/icon/setting-blue.svg';
/* import Grid from '@material-ui/core/Grid'; */
import Fab from '@material-ui/core/Fab';
import TextAreaField from '../../components/fields/textarea';
import Grid from '@material-ui/core/Grid';
import ArrowBackGrey from '@material-ui/icons/KeyboardArrowLeft';
import { Scrollbars } from 'react-custom-scrollbars';
import SelectField from '../../components/fields/select';
import PhoneField from '../../components/fields/phone';
import InputField from '../../components/fields/inputs';
import Button from '@material-ui/core/Button';
import * as Constants from '../../utilities/constants';
import Blank_Avatar from '../../assets/images/blank-avatar.png';
import Avatar from '@material-ui/core/Avatar';

import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import ToggleChoice from '../../components/properties/toggle-choice';
import SwitchButtonActions from '../../components/fields/switch-button-action';

import ClientProfile from '../../components/client/client-profile';
import DataSourceConfig from '../../components/configuration/data-source-config';

import SettingProgram from '../../components/settings/setting-program';
import SettingApi from '../../components/settings/setting-api';
import * as ClientApi from '../../api/client-api';
import ConfigApiDetails from '../../components/configuration/config-api-details';

class ProfileDashboard extends Component {

    state = {
        active: false,
        buttonState: false,
        selectedSidebar: Constants.CLIENT_SIDEBAR.CLIENT_PROFILE,
        clientDetails: {},
        open: false,
        clientDataCreate: {},
        intermediaryActive: false,
        isActive: false
    }

    componentWillMount() {
        const match = this.props.match;
        let clientID = match.params.clientId;
        let { clientId } = this.props
        clientId = clientID
        ClientApi.getClientById(clientId).then(data => {
            this.setState({ clientDetails: data });
            this.setState({ clientDataCreate: data });
            this.setState({ isActive: this.state.clientDataCreate.status })
            this.setState({ intermediaryActive: this.state.clientDataCreate.is_intermediary })
        })
    }

    toggleTimeline() {
        this.setState({ active: !this.state.active });
    }

    settingChange(sidebar) {
        this.setState({ selectedSidebar: sidebar });
    }

    handleClose() {
        this.setState({ open: false });
    }

    updateClientDetails(clientData) {
        this.setState({ clientDetails: clientData })
    }

    openSelection(selectedSidebar) {
        const match = this.props.match;
        let clientId = match.params.clientId;
        switch (selectedSidebar) {
            case Constants.CLIENT_SIDEBAR.CLIENT_PROFILE:
                return (
                    <ClientProfile
                        clientId={clientId}
                        clientDetails={this.state.clientDetails}
                        updateClientDetails={this.updateClientDetails.bind(this)}
                    />
                );
            case Constants.CLIENT_SIDEBAR.DATA_SOURCE_CONFIG:
                return (
                    <DataSourceConfig />
                );
            case Constants.CLIENT_SIDEBAR.API_ACCESS:
                return (
                    <ConfigApiDetails
                        clientId={clientId}
                        newAPIDetails={true}
                    />
                )
            default: break;
        }
    }

    render() {
        let { buttonState, active, selectedSidebar, clientDetails, intermediaryActive, isActive } = this.state;
        let toggleTimeline = active ? "toggle-timeline" : "";
        let ToggleBtnArrow = buttonState ? "toggle-arrow" : "";
        const match = this.props.match;
        let clientId = match.params.clientId;

        return (
            <div className="SettingBuilderScene">
                <Header headerType="dashboard" positionType="fixed" clientId={clientId} />
                <WorkflowClientHeader workflowType="normal" tab="profile" clientId={clientId} />
                <main>
                    <div className="workflow-fields-sec program-build-sec create-app-sec level-1-header">
                        <div className="program-build-wrap">
                            <div className={"program-card-wrap program-build-timeline profile-build-timeline " + toggleTimeline}>
                                <div className="card-block">
                                    <div className="card-body">
                                        <section className="create-app-timeline">
                                            <div className="cretae-timeline-wrap">
                                                <div className={"program-timeline-btn " + (selectedSidebar === Constants.CLIENT_SIDEBAR.CLIENT_PROFILE ? 'active' : '')}>
                                                    <Fab variant="extended" size="small" className="btn btn-small no-shadow btn-icon" onClick={() => this.settingChange(Constants.CLIENT_SIDEBAR.CLIENT_PROFILE)}>
                                                        <img src={ProfileIcon} alt="ProfileIcon" />
                                                        {
                                                            !toggleTimeline && (
                                                                <span>Profile</span>
                                                            )
                                                        }
                                                    </Fab>
                                                </div>
                                            </div>
                                            <div className="cretae-timeline-wrap">
                                                <div className={"program-timeline-btn " + (selectedSidebar === Constants.CLIENT_SIDEBAR.KYC ? 'active' : '')}>
                                                    <Fab variant="extended" size="small" className="btn btn-small no-shadow btn-icon" onClick={() => this.settingChange(Constants.CLIENT_SIDEBAR.KYC)}>
                                                        <img src={KeyBlueIcon} alt="KeyBlueIcon" />
                                                        {
                                                            !toggleTimeline && (
                                                                <span>KYC</span>
                                                            )
                                                        }
                                                    </Fab>
                                                </div>
                                            </div>
                                            <div className="cretae-timeline-wrap">
                                                <div className={"program-timeline-btn " + (selectedSidebar === Constants.CLIENT_SIDEBAR.DATA_SOURCE_CONFIG ? 'active' : '')}>
                                                    <Fab variant="extended" size="small" className="btn btn-small no-shadow btn-icon" onClick={() => this.settingChange(Constants.CLIENT_SIDEBAR.DATA_SOURCE_CONFIG)}>
                                                        <img src={SettingIcon} alt="SettingIcon" />
                                                        {
                                                            !toggleTimeline && (
                                                                <span>Database Config</span>
                                                            )
                                                        }
                                                    </Fab>
                                                </div>
                                            </div>
                                            <div className="cretae-timeline-wrap">
                                                <div className={"program-timeline-btn " + (selectedSidebar === Constants.CLIENT_SIDEBAR.API_ACCESS ? 'active' : '')}>
                                                    <Fab variant="extended" size="small" className="btn btn-small no-shadow btn-icon" onClick={() => this.settingChange(Constants.CLIENT_SIDEBAR.API_ACCESS)}>
                                                        <img src={KeyBlueIcon} alt="KeyBlueIcon" />
                                                        {
                                                            !toggleTimeline && (
                                                                <span>API Access</span>
                                                            )
                                                        }
                                                    </Fab>
                                                </div>
                                            </div>
                                            <div className={"program-timeline-btn program-timeline-toggle " + ToggleBtnArrow} onClick={this.toggleTimeline.bind(this)} >
                                                <Fab variant="extended" size="small" className="btn btn-small no-shadow btn-toggle">
                                                    <img src={ArrowIcon} alt="ArrowIcon" />
                                                </Fab>
                                            </div>
                                        </section>
                                    </div>
                                </div>
                            </div>
                            <div className={"program-card-wrap program-build-form profile-setting-sec client-profile-setting-sec " + toggleTimeline}>
                                <div className="card-block">
                                    <div className="card-body">
                                        {this.openSelection(selectedSidebar)}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
                {/* <main>
                    <div className="workflow-fields-sec program-build-sec create-app-sec level-2-header">
                        <div className="program-build-wrap">
                            <div className={"program-card-wrap program-build-form " + toggleTimeline}>
                                {
                                    (changeSetting === 'profile') && (
                                        <section className="workflow-dialog workflow-dialog-large">
                                            <div className="card-block">

                                                <div className="card-header border-bottom">
                                                    <div className="sub-title">
                                                        <h5>Client Profile</h5>
                                                    </div>
                                                    <div className="sub-title right">
                                                        <SwitchButtonActions
                                                            label="Yes"
                                                            name="active"
                                                            fieldStyle="field-box bg-plane grey-color"
                                                            isChecked={isActive}
                                                            headerLabel="Active?"
                                                            withLabel={true}
                                                            onChange={this.handleToggleChange("status").bind(this)}
                                                        />

                                                    </div>
                                                </div>
                                                <div className="card-body">
                                                    <Grid container>
                                                        <Grid item md={3}>
                                                        </Grid>
                                                        <Grid item md={9}>
                                                            <div className="workflow-dialog-forms form-builder-dashboard">
                                                                <div className="tabs-container tabs-container-scroll">
                                                                    <Scrollbars style={{ width: '100%', height: '100%' }}>
                                                                        <div className="tabs-container-scroll-wrap">
                                                                            <div className="workflow-form-field">
                                                                                <Grid container lg={12} md={12}>
                                                                                    <Grid item lg={5} md={8}>
                                                                                        <InputField label="*Display Name" type="text" name="name" selected_value={this.state.clientDataCreate.display_name} fieldStyle="field-box bg-plane grey-color" placeholder="Enter" handleOnChangeValue={this.handleClientChange("display_name").bind(this)} />
                                                                                    </Grid>
                                                                                    <Grid item lg={5} md={8}>

                                                                                        <InputField label="*Client Name" type="text" name="name" selected_value={this.state.clientDataCreate.name} fieldStyle="field-box bg-plane grey-color" placeholder="Enter" handleOnChangeValue={this.handleClientChange("name").bind(this)} />

                                                                                    </Grid>
                                                                                </Grid>
                                                                                <Grid container lg={10} md={16}>
                                                                                    <TextAreaField label="Address" name="address" rowmax="3" selected_value={this.state.clientDataCreate.address} fieldStyle="field-box bg-plane grey-color" placeholder="XYZ building, kamla mils" handleOnChangeValue={this.handleClientChange("address").bind(this)} />

                                                                                </Grid>
                                                                                <Grid container lg={12} md={12}>
                                                                                    <Grid item lg={5} md={8}>
                                                                                        <InputField
                                                                                            label="*Primary Email" type="email" name="primary email" fieldStyle="field-box bg-plane grey-color"
                                                                                            placeholder="design@assurekit.com"
                                                                                            selected_value={this.state.clientDataCreate.email}
                                                                                            handleOnChangeValue={this.handleClientChange("email").bind(this)}
                                                                                        />

                                                                                    </Grid>
                                                                                    <Grid item lg={5} md={8}>
                                                                                        <InputField
                                                                                            label="Alternate Email" type="email" name="alternate email" fieldStyle="field-box bg-plane grey-color"
                                                                                            placeholder="design@assurekit.com"
                                                                                            selected_value={this.state.clientDataCreate.alternate_email}
                                                                                            handleOnChangeValue={this.handleClientChange("alternate_email").bind(this)}
                                                                                        />
                                                                                    </Grid>
                                                                                </Grid>
                                                                                <Grid container lg={12} md={12}>
                                                                                    <Grid item lg={5} md={8}>
                                                                                        <PhoneField label="*Primary Phone" type="text" name="primary phone"
                                                                                            default_country_code="IN"
                                                                                            fieldStyle="field-box bg-plane grey-color" placeholder="7208337428"
                                                                                            selected_value={this.state.clientDataCreate.contact_no}
                                                                                            handleOnChangeValue={this.handleClientChange("contact_no").bind(this)}
                                                                                        />

                                                                                    </Grid>
                                                                                    <Grid item lg={5} md={8}>
                                                                                        <PhoneField label="Alternate Phone" type="text" name="client_phone"
                                                                                            default_country_code="IN"
                                                                                            fieldStyle="field-box bg-plane grey-color" placeholder="7208337428"
                                                                                            selected_value={this.state.clientDataCreate.alternate_no}
                                                                                            handleOnChangeValue={this.handleClientChange("alternate_no").bind(this)}
                                                                                        />
                                                                                    </Grid>
                                                                                </Grid>
                                                                            </div>
                                                                            <Grid container lg={12} md={12}>
                                                                                <Grid item lg={5} md={8}>
                                                                                    <SwitchButtonActions
                                                                                        label="Yes"
                                                                                        name="intermediary"
                                                                                        fieldStyle="field-box bg-plane grey-color"
                                                                                        isChecked={intermediaryActive}
                                                                                        headerLabel="Is Intermediary?"
                                                                                        withLabel={true}
                                                                                        onChange={this.handleToggleChange("is_intermediary").bind(this)}
                                                                                    />

                                                                                </Grid>
                                                                            </Grid>
                                                                            <div className="workflow-form-field border-bottom">
                                                                                <Grid container lg={12} md={12}>
                                                                                    <Grid item lg={5} md={8}>


                                                                                        <InputField
                                                                                            label="*Intermediary Name" type="text" name="intermediary name" fieldStyle="field-box bg-plane grey-color"
                                                                                            placeholder="Enter"
                                                                                            selected_value={this.state.clientDataCreate.intermediary_name}
                                                                                            handleOnChangeValue={this.handleClientChange("intermediary_name").bind(this)}
                                                                                        />

                                                                                    </Grid>
                                                                                    <Grid item lg={5} md={8}>

                                                                                        <InputField
                                                                                            label="*IRDAI Registration No." type="text" name="irdai registration no" fieldStyle="field-box bg-plane grey-color"
                                                                                            placeholder="Enter"
                                                                                            selected_value={this.state.clientDataCreate.irdai_registration_no}
                                                                                            handleOnChangeValue={this.handleClientChange("irdai_registration_no").bind(this)}
                                                                                        />

                                                                                    </Grid>
                                                                                </Grid>
                                                                                <Grid container lg={12} md={12}>
                                                                                    <Grid item lg={5} md={8}>


                                                                                        <InputField
                                                                                            label="*Valid Till Date" type="date" name="valid till" fieldStyle="field-box bg-plane grey-color"
                                                                                            placeholder="Enter"
                                                                                            selected_value={this.state.clientDataCreate.valid_till}
                                                                                            handleOnChangeValue={this.handleClientChange("valid_till").bind(this)}
                                                                                        />

                                                                                    </Grid>
                                                                                    <Grid item lg={5} md={8}>

                                                                                        <InputField
                                                                                            label="*CIN" type="text" name="cin" fieldStyle="field-box bg-plane grey-color"
                                                                                            placeholder="Enter"
                                                                                            selected_value={this.state.clientDataCreate.cin}
                                                                                            handleOnChangeValue={this.handleClientChange("cin").bind(this)}
                                                                                        />

                                                                                    </Grid>
                                                                                </Grid>
                                                                            </div>
                                                                        </div>
                                                                    </Scrollbars>
                                                                </div>
                                                                <div className="form-row workflow-dialog-footer">
                                                                    <div className="btn-wrap no-border">
                                                                        <Fab variant="extended" size="large" className="btn btn-large btn-plane primary-color" onClick={this.createClientData()}>
                                                                            Save
                                                        </Fab>
                                                                        <Fab variant="extended" size="large" className="btn btn-large btn-plane primary-color">
                                                                            Cancel
                                                        </Fab>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Grid>
                                                    </Grid>
                                                </div>
                                            </div>
                                        </section>
                                    )
                                }
                                {
                                    (changeSetting === 'kyc') && (
                                        <div></div>
                                    )
                                }
                            </div>
                        </div>
                    </div>

                </main> */}
            </div>
        )
    }
}

export default ProfileDashboard;