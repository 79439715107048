import React, { Component } from 'react';
import Radio from '@material-ui/core/Radio';
import Checkbox from '@material-ui/core/Checkbox';
import InputField from '../fields/inputs';
import Fab from '@material-ui/core/Fab';
import Add from '@material-ui/icons/Add';
import Minimize from '@material-ui/icons/Minimize';
import Chip from '@material-ui/core/Chip';
//Constants
import * as FormConstants from '../../utilities/constants';

class SingleChoice extends Component {

    constructor(props) {
        super(props)
        this.state = {
            countClicks: this.props.radioData.length > 2 ? this.props.radioData.length - 4 : this.props.radioData.length - 2, //2
            isClicked: false,
            chipValue: this.props.radioData.length > 4 ? true : false,
            otherChecked: false,
        }
    }

    handleChange = index => event => {
        let radioData = this.props.radioData;
        radioData.forEach(data => {
            if (data.field_option_value === event.target.value) {
                data.checked = true;
            }
            else {
                data.checked = false;
            }
        })

        let value = radioData[index].field_option_value;
        this.props.optionSelectedEvent(value);
    };

    handleOnClick = index => event => {
        this.removeSelectedIfPresent(index);
    }

    removeSelectedIfPresent(index) {
        let radioData = this.props.radioData;
        let clickedValue = radioData[index].field_option_value;
        if (clickedValue === this.props.default_value) {
            this.props.optionSelectedEvent(null);
        }
    }

    handleLabelOnChangeEvent = (index) => (value) => {
        let radioData = this.props.radioData;
        radioData[index].field_option_label = value;
        this.props.optionOnChangeEvent(radioData);
    }

    addNewOption = index => event => {
        let radioData = this.props.radioData;
        this.setState({ chipValue: false });
        let option = {
            field_option_label: "",
            field_option_value: new Date().getTime().toString(),
            option_type: 0
        };

        radioData.splice(index + 1, 0, option);

        if (radioData.length > 4) {
            this.setState({ countClicks: radioData.length - 3 });
        }
        else {
            this.setState({ countClicks: 0 });
        }

        this.props.optionOnChangeEvent(radioData);
    }

    otherChangeEvent = event => {
        if (event.target.checked) {
            this.addOtherOption();
        } else {
            this.deleteOtherOption();
        }
        this.setState({ otherChecked: event.target.checked });
    }

    addOtherOption() {
        let radioData = this.props.radioData;
        let option = {
            field_option_label: "",
            field_option_value: new Date().getTime(),
            option_type: 1
        };
        radioData.push(option);
        this.props.optionOnChangeEvent(radioData);
    }

    deleteOtherOption() {
        let updatedRadioData = this.getRadioData();
        this.props.optionOnChangeEvent(updatedRadioData);
    }

    getRadioData() {
        let radioData = this.props.radioData;
        let updatedRadioData = radioData.filter(radio => {
            return !radio.option_type || radio.option_type === 0;
        })

        let otherRadioArr = radioData.filter(radio => {
            return radio.option_type === 1;
        })
        if (!this.state.otherChecked && otherRadioArr.length > 0) {
            this.setState({ otherChecked: true })
        }

        if (this.state.chipValue) {
            if (this.state.isClicked) {
                return updatedRadioData
            }
            else {
                return updatedRadioData.splice(0, 4);
            }
        }
        else {
            return updatedRadioData
        }

    }

    clickToDisplay = event => {
        this.setState({ isClicked: true, countClicks: 0, chipValue: false })
    }

    deleteOption = index => event => {
        let radioData = this.props.radioData;
        this.removeSelectedIfPresent(index);
        radioData.splice(index, 1);

        if (radioData.length <= 4)      //this.state.countClicks
        {
            this.setState({ chipValue: false })
        }
        else {
            this.setState((prevState) => {
                return {
                    countClicks: prevState.countClicks - 1
                }
            })
        }

        this.props.optionOnChangeEvent(radioData);
    }

    render() {
        let { default_value, isRadio } = this.props;
        let updatedRadioData = this.getRadioData();
        let fieldType = this.props.fieldType;
        return (
            <div className="form-row">
                <div className="choice-wrap">
                    {
                        updatedRadioData.map((radio, index) => {
                            return (
                                <div className="choice-row" key={index}>
                                    <Radio
                                        value={radio.field_option_value}
                                        checked={radio.field_option_value === default_value}
                                        onChange={this.handleChange(index)}
                                        onClick={this.handleOnClick(index)}
                                    />
                                    <InputField selected_value={radio.field_option_label} handleOnChangeValue={this.handleLabelOnChangeEvent(index)} />
                                    <div className="choice-action">
                                        <Fab aria-label="add_circle_outline" className="fabs-icons add" onClick={this.addNewOption(index)}>

                                            <Add />
                                        </Fab>
                                        {(updatedRadioData.length <= 1 && index === 0) ? "" :
                                            (
                                                <Fab aria-label="highlight_off" className="fabs-icons remove" onClick={this.deleteOption(index)}>
                                                    <Minimize />
                                                </Fab>
                                            )}

                                    </div>
                                </div>
                            )
                        })
                    }
                </div>

                {!isRadio ?

                    fieldType !== FormConstants.FieldIds.F_SINGLE_DROPDOWN_GROUP.code ?
                        <div className="choice-wrap disabled-wrap">
                            <div className="choice-row">
                                <Checkbox
                                    checked={this.state.otherChecked}
                                    onChange={this.otherChangeEvent}
                                />
                                <InputField value="Other" disabled />
                            </div>
                        </div>
                        : ""
                    : ""
                }
                <div className="choice-wrap choice-count">
                    {this.state.chipValue &&
                        <Chip
                            variant="outlined"
                            label={"" + this.state.countClicks + "+ more"}
                            onClick={this.clickToDisplay}
                        />

                    }
                </div>

            </div>
        )
    }
}

export default SingleChoice;