import Square2 from '../assets/images/icon/square-2-blue.svg';
import Square4 from '../assets/images/icon/square-4-blue.svg';
import Edit from '../assets/images/icon/signature.svg';
import Warning from '../assets/images/icon/warning-blue.svg';
import Tick from '../assets/images/icon/tick-blue.svg';
import Notification from '../assets/images/icon/notification-fill-blue.svg';
import Mail from '../assets/images/icon/mail-blue.svg';
import Home from '../assets/images/icon/home-blue.svg';
import Company from '../assets/images/icon/company-blue.svg';
import Icons from '../components/common/icon-common';

export const FieldIds = {
    F_SINGLE_TEXT: { id: 1, code: "f-sin-txt" },
    F_MULTI_TEXT: { id: 2, code: "f-multi-txt" },
    F_NAME: { id: 3, code: "f-name" },
    F_DATE: { id: 4, code: "f-date" },
    F_TIME: { id: 5, code: "f-time" },
    F_DATE_TIME: { id: 6, code: "f-date-time" },
    F_RADIO_GROUP: { id: 7, code: "f-radio-grp" },
    F_CHECKBOX_GROUP: { id: 8, code: "f-checkbox-grp" },
    F_SINGLE_DROPDOWN_GROUP: { id: 9, code: "f-sin-dropdown-grp" },
    F_MULTIPLE_DROPDOWN_GROUP: { id: 10, code: "f-multi-dropdown-grp" },
    F_PHONE_TEXT: { id: 11, code: "f-phone-txt" },
    F_EMAIL_TEXT: { id: 12, code: "f-email-txt" },
    F_ACTION_BUTTONS: { id: 13, code: "f-action-but-group" },
    F_CALCULATION: { id: 14, code: "f-calc" },
    F_NUMBER_TXT: { id: 15, code: "f-number-txt" },
    F_ADDRESS: { id: 16, code: "f-address" },
    F_STATE: { id: 17, code: "f-state-txt" },
    F_SUB_STATE: { id: 18, code: "f-sub-state-txt" },
    F_SECTION_FIELD_TYPE: { id: 19, code: "f-section" },
    F_UPLOAD: { id: 20, code: "f-upload" },
    F_POLICY_ID: { id: 21, code: "f-policy-id" },
    F_MULTIPLE_DROPDOWN_SELECT_GROUP: { id: 22, code: "f-multi-dropdown-search-grp" }
}

export const PropertyIds = {
    P_FORM: "p-form",
    P_LABEL: "p-sin-lbl",
    P_PLACE_HOLDER: "p-place-hdr",
    P_INSTRUCTION: "p-instr-txt",
    P_MANDATORY: "p-madate-chk",
    P_VISIBILITY: "p-visibility-chk",
    P_STATE: "p-state-chk",
    P_SIZE: "p-fld-size",
    P_SIN_OPT: "p-sin-sel-opt",
    P_MUL_OPT: "p-multi-sel-opt",
    P_MUL_NAMES: "p-multi-names",
    P_INITIAL_VAL: "p-initial-value",
    P_CHAR_SIZE: "p-char-size",
    P_FIELD_SIZE: "p-field-link",
    P_ORDER: "p-order-chk",
    P_ALLOWED_DAYS: "p-allowed-days",
    P_ALLOWED_HOURS: "p-allowed-hours",
    P_FIELD_LAYOUT: "p-field-layout",
    P_VALIDATION_FIELD: "p-validation-field",
    P_DISPLAY_NAME: "p-display-name",
    P_PREFIX_NAME: "p-prefix-name",
    P_DISPLAY_ADDRESS: "p-display-address",
    P_COUNTRY_CODE_OPTIONS: "p-country-code-options",
    P_DEFAULT_COUNTRY_CODE: "p-default-country-code",
    P_CALCULATION_FORMULA: "p-calc-formula",
    P_FIELD_LINK: "p-field-link",
    P_ID_FIELD: "p-id-field",
    P_REMAINING_CHAR: "p-remaining-char",
}

export const FieldMetadataValues = {
    M_FIELD_NAME: "field_name",
    M_FIELD_CODE: "field_code_txt",
    M_FIELD_ID: "field_type_id",
    M_FIELD_PROPERTY_IDS: "field_properties_ids",
    M_IMAGE_CODE: "file_img_code",
}

export const ACTIONS_TYPE = {
    NOTIFICATION: "notification",
    FIELDS_ACTION: "field-actions",
    DATA_ACCESS: "data-access",
    INTEGRATION: "integration",
    SCRIPT: "script",
    PAYMENT: "payment-actions",
    PAYMENT_STATUS: "payment",
}

export const ACTIONS_TYPE_NAME = {
    [ACTIONS_TYPE.NOTIFICATION]: "Notification",
    [ACTIONS_TYPE.FIELDS_ACTION]: "Field Actions",
    [ACTIONS_TYPE.DATA_ACCESS]: "Data Access",
    [ACTIONS_TYPE.INTEGRATION]: "Integration",
    [ACTIONS_TYPE.SCRIPT]: "Deluge Script",
    [ACTIONS_TYPE.PAYMENT]: "Payment",
}

export const FIELD_ACTIONS = {
    HIDE: "hide-fields",
    SHOW: "show-fields",
    ENABLE: "enable-fields",
    DISABLE: "disable-fields",
    SET: "set-field-value",
    PAYMENT_INIT: "payment-init",
    OPEN_ASSUREGATE: "open-assure-gate",
    BUY_POLICY: "buy-policy",
    PDF_DOWNLOAD: "pdf-download",
    CANCEL_POLICY: "cancel-policy",
    STATE: "change-state",
    POLICY_GENERATOR: "policy-generator",
    REDIRECT: "redirect",
    SEND_EMAIL: "send-email",
    SEND_SMS: "send-sms",
    MOBILE_NOTIFY: "mobile-notify",
    WARNING_MESSAGE: "warning-message",
    SUCCESS_MESSAGE: "success-message",
    SEND_INVITATION: "send-invitation",
    ADD_RECORD: "add-record",
    UPDATE_RECORD: "update-record",
    UPDATE_LEDGER: "update-ledger",
    DELETE_RECORD: "delete-record",
    PAYMENT_SUCCESS: "payment-success",
    PAYMENT_FAIL: "payment-fail",
    LEDGER_SUCCESS: "ledger-success",
    LEDGER_FAIL: "ledger-fail",
}

export const FIELD_ACTIONS_NAME = {
    [FIELD_ACTIONS.HIDE]: "Hide Fields",
    [FIELD_ACTIONS.SHOW]: "Show Fields",
    [FIELD_ACTIONS.ENABLE]: "Enable Fields",
    [FIELD_ACTIONS.DISABLE]: "Disable Fields",
    [FIELD_ACTIONS.SET]: "Set Fields Value",
    [FIELD_ACTIONS.PAYMENT_INIT]: "Payment Initialization",
    [FIELD_ACTIONS.OPEN_ASSUREGATE]: "Open AssureGate",
    [FIELD_ACTIONS.BUY_POLICY]: "Buy Policy",
    [FIELD_ACTIONS.PDF_DOWNLOAD]: "Download PDF",
    [FIELD_ACTIONS.CANCEL_POLICY]: "Cancel Policy",
    [FIELD_ACTIONS.STATE]: "Change State",
    [FIELD_ACTIONS.POLICY_GENERATOR]: "Policy Generator",
    [FIELD_ACTIONS.REDIRECT]: "Redirect to URL",
    [FIELD_ACTIONS.ADD_RECORD]: "Add Record",
    [FIELD_ACTIONS.UPDATE_LEDGER]: "Update Ledger",
    [FIELD_ACTIONS.UPDATE_RECORD]: "Update Record",
    [FIELD_ACTIONS.DELETE_RECORD]: "Delete Record",
    [FIELD_ACTIONS.SEND_EMAIL]: "Send Email",
    [FIELD_ACTIONS.SEND_SMS]: "Send SMS",
    [FIELD_ACTIONS.MOBILE_NOTIFY]: "Send Mobile Notification",
    [FIELD_ACTIONS.SUCCESS_MESSAGE]: "Send Success Message",
    [FIELD_ACTIONS.SEND_INVITATION]: "Send Invitation",
    [FIELD_ACTIONS.WARNING_MESSAGE]: "Add Message",
    [FIELD_ACTIONS.PAYMENT_SUCCESS]: "Set Payment Successful Details",
    [FIELD_ACTIONS.PAYMENT_FAIL]: "Set Payment Failure Details",
    [FIELD_ACTIONS.LEDGER_SUCCESS]: "Set Ledger Suceessful Details",
    [FIELD_ACTIONS.LEDGER_FAIL]: "Set Ledger Failure Details",
}

export const FIELD_OPERATION = {
    EQUALS: "equals",
    NOT_EQUALS: "notEqual",
    IS_NULL: "isNull",
    IS_NOT_NULL: "isNotNull",
    STARTS_WITH: "startsWith",
    ENDS_WITH: "endsWith",
    CONTAINS: "contains",
    DOES_NOT_CONTAIN: "doesNotContain",
    GREATER_THAN: "greaterThan",
    LESS_THAN: "lessThan",
    CLICKED: "clicked"
}

export const FORM_TYPE = {
    SECTIONAL: { code: 'sectional', fieldExcept: [FieldIds.F_ACTION_BUTTONS.code] },
    ONCLICK: { code: 'onclick', fieldExcept: [] },
    ACTION: { code: 'action', fieldExcept: [] },
    PARAMETER: { code: 'parameter', fieldExcept: [FieldIds.F_ACTION_BUTTONS.code, FieldIds.F_STATE.code, FieldIds.F_SUB_STATE.code, FieldIds.F_CALCULATION.code] },
}

export const RECORD_TYPE = {
    FORM: { code: 1 },
    PROGRAM: { code: 2 },
    CONFIGURATION: { code: 3 }
}

export const PROGRAM = {
    STACK_HOLDER: "stack_holder",
    MASTER_POLICY: "master_policy",
    POLICY_PARAMETER: "policy_parameters",
    COVERAGE_LIMITS: "coverage_limits",
    PAYMENT_METHODS: "payment_methods",
    TERMS_CONDITIONS: "terms_conditions"
}

export const ACTIVITY_TYPE = {
    ADD_RECORD: "ADD_RECORD",
    UPDATE_RECORD: "UPDATE_RECORD",
    CHANGE_STATE: "CHANGE_STATE",
}

// export const icons = [
//     {id:'i_square2',text: "Square2", icon: Square2},
//     {id:'i_edit',text: "Edit", icon: Edit},
//     {id:'i_square4',text: "Square4", icon: Square4},
//     {id:'i_warning',text: "Warning", icon: Warning},
//     {id:'i_tick',text: "Tick", icon: Tick},
//     {id:'i_notification',text: "Notification", icon: Notification},
//     {id:'i_mail',text: "Mail", icon: Mail},
//     {id:'i_home',text: "Home", icon: Home},
//     {id:'i_company',text: "Company", icon: Company},
// ];

export const icons = {
    'i_square2': { text: "Square2", icon: Square2 },
    'i_edit': { text: "Edit", icon: Edit },
    'i_square4': { text: "Square4", icon: Square4 },
    'i_warning': { text: "Warning", icon: Warning },
    'i_tick': { text: "Tick", icon: Tick },
    'i_notification': { text: "Notification", icon: Notification },
    'i_mail': { text: "Mail", icon: Mail },
    'i_home': { text: "Home", icon: Home },
    'i_company': { text: "Company", icon: Company },
    'i_deleteBlueIcon': { text: "Delete", icon: Icons.deleteBlueIcon },
    'i_actionIcon': { text: "Actions", icon: Icons.actionIcon },
    'i_addBlueIcon': { text: "Add", icon: Icons.addBlueIcon },
    'i_buildBlueIcon': { text: "Build", icon: Icons.buildBlueIcon },
    'i_claimsBlueIcon': { text: "Claims", icon: Icons.claimsBlueIcon },
    'i_companyBlueIcon': { text: "Company", icon: Icons.companyBlueIcon },
    'i_designBlueIcon': { text: "Design", icon: Icons.designBlueIcon },
    'i_downloadBlueIcon': { text: "Download", icon: Icons.downloadBlueIcon },
    'i_duplicateBlueIcon': { text: "Duplicate", icon: Icons.duplicateBlueIcon },
    'i_editBlueIcon': { text: "Edit", icon: Icons.editBlueIcon },
    'i_eyeBlueIcon': { text: "Eye", icon: Icons.eyeBlueIcon },
    'i_filterBlueIcon': { text: "Filter", icon: Icons.filterBlueIcon },
    'i_hideBlueIcon': { text: "Hide", icon: Icons.hideBlueIcon },
    'i_homeBlueIcon': { text: "Home", icon: Icons.homeBlueIcon },
    'i_inputBlueIcon': { text: "Input", icon: Icons.inputBlueIcon },
    'i_keyBlueIcon': { text: "Key", icon: Icons.keyBlueIcon },
    'i_mailBlueIcon': { text: "Mail", icon: Icons.mailBlueIcon },
    'i_mobPhoneBlueIcon': { text: "Mobile Phone", icon: Icons.mobPhoneBlueIcon },
    'i_notificationFillBlueIcon': { text: "Notofication", icon: Icons.notificationFillBlueIcon },
    'i_ownerBlueIcon': { text: "Owner", icon: Icons.ownerBlueIcon },
    'i_policyBlueIcon': { text: "Policy", icon: Icons.policyBlueIcon },
    // 'i_profileBlueIcon' : { text: "Profile" , icon: Icons.profileBlueIcon },
    'i_reminderBlueIcon': { text: "Reminder", icon: Icons.reminderBlueIcon },
    'i_reportBlueIcon': { text: "Report", icon: Icons.reportBlueIcon },
    'i_sectionsBlueIcon': { text: "Section", icon: Icons.sectionsBlueIcon },
    'i_settingBlueIcon': { text: "Setting", icon: Icons.settingBlueIcon },
    'i_shareBlueIcon': { text: "Share", icon: Icons.shareBlueIcon },
    'i_square2BlueIcon': { text: "Square2", icon: Icons.square2BlueIcon },
    'i_square4BlueIcon': { text: "Square4", icon: Icons.square4BlueIcon },
    'i_stateBlueIcon': { text: "State", icon: Icons.stateBlueIcon },
    'i_taskBlueIcon': { text: "Task", icon: Icons.taskBlueIcon },
    'i_tickBlueIcon': { text: "Tick", icon: Icons.tickBlueIcon },
    'i_updateBlueIcon': { text: "Update", icon: Icons.updateBlueIcon },
    'i_usersBlueIcon': { text: "Users", icon: Icons.usersBlueIcon },
    'i_warningBlueIcon': { text: "Warning", icon: Icons.warningBlueIcon },
    'i_uploadIcon': { text: "Upload", icon: Icons.uploadIcon },
};

export const DATA_TYPE = {
    FORM_DATA: "form-data",
    PROGRAM_DATA: "program-data",
    SECTIONAL_DATA: "sectional-data",
}

export const POLICY_GENERATION_MODE = {
    PROGRAM: "program",
    CLIENT: "client",
}

//REDUX ACTION TYPES
export const REDUX_ACTION = {
    FETCH_PROFILE_IMAGE_USER: 'FETCH_PROFILE_IMAGE_USER',
    FETCH_PROFILE_IMAGE_CLIENT: "FETCH_PROFILE_IMAGE_CLIENT",
    CHANGE_PROFILE_IMAGE_USER: "CHANGE_PROFILE_IMAGE_USER",
    CHANGE_PROFILE_IMAGE_CLIENT: "CHANGE_PROFILE_IMAGE_CLIENT",
    DELETE_PROFILE_IMAGE: "DELETE_PROFILE_IMAGE",
    SET_FORM_DATA: "SET_FORM_DATA",
    SET_USER_DATA: "SET_USER_DATA",
}

// LOGO CONFIGURATION
export const CONFIG = {
    LOGO: require('../assets/images/asureKit.png')
}
// End

export const RECORD_STATUS = {
    ACTIVE: "ACTIVE",
    INACTIVE: "INACTIVE"
}

export const CLIENT_SIDEBAR = {
    CLIENT_PROFILE: "client_profile",
    KYC: "kyc",
    DATA_SOURCE_CONFIG: "data_source_config",
    API_ACCESS: "api_access"
}

export const CONFIGURATION_PROGRAM = {
    MOTOR: { code: 'motor' },
    HEALTH: { code: 'health' },
    OTHER: { code: 'other' }
}

export const CONFIG_PROCESS = {
    BASIC_DETAILS: "basic_Details",
    INSURERS: "insureres",
    AGENTS: "agents",
    API_DETAILS: "api_details"
}

export const REDIRECT_TYPE = {
    FORM: "form",
    URL: "url"
}


export const QUOTES_CONTENT = {
    CONTENT: "Could not find any insurance quotes for the vehicle you have selected. Please try again later or with another vehicle"
}

export const ASSUREGATE_FIELD_DATA = [
    // Id for tracking
    {
        field_option_label: "Inspection Id",
        field_option_value: "inspectionId",
    },
    {
        field_option_label: "Lead Id",
        field_option_value: "leadId",
    },
    //Registration details
    {
        field_option_label: "Mobile Number",
        field_option_value: "mobileNo",
    },
    {
        field_option_label: "Registration Number",
        field_option_value: "registrationNo",
    },
    {
        field_option_label: "Owner Type",
        field_option_value: "ownerType",
    },

    //Vehicle details
    {
        field_option_label: "Vehicle Brand",
        field_option_value: "brand",
    },
    {
        field_option_label: "Vehicle Model",
        field_option_value: "model",
    },
    {
        field_option_label: "Vehicle Fuel Type",
        field_option_value: "fuelType",
    },
    {
        field_option_label: "Vehicle Variant",
        field_option_value: "variant",
    },
    {
        field_option_label: "Current RTO",
        field_option_value: "currentRto",
    },
    {
        field_option_label: "Registration Year",
        field_option_value: "registrationYear",
    },
    {
        field_option_label: "Registration Date",
        field_option_value: "registrationDate",
    },

    //Past policy details
    {
        field_option_label: "Policy is Expired",
        field_option_value: "policyIsExpired",
    },
    {
        field_option_label: "Is Policy Previously Claimed",
        field_option_value: "previousClaimFlag",
    },
    {
        field_option_label: "NCB",
        field_option_value: "ncb",
    },

    //Information accuracy confirmation
    {
        field_option_label: "Manufacture Year",
        field_option_value: "manufactureYear",
    },
    {
        field_option_label: "Manufacture Month",
        field_option_value: "manufactureMonth",
    },
    {
        field_option_label: "Previous Policy Expiry Date",
        field_option_value: "policyExpiryDate",
    },
    {
        field_option_label: "Third Party Policy Expiry Date",
        field_option_value: "policyTPExpiryDate",
    },

    //Owner/company details
    {
        field_option_label: "First Name",
        field_option_value: "firstName",
    },
    {
        field_option_label: "Last Name",
        field_option_value: "lastName",
    },
    {
        field_option_label: "Gender",
        field_option_value: "gender",
    },
    {
        field_option_label: "Marital Status",
        field_option_value: "maritalStatus",
    },
    {
        field_option_label: "Email Id",
        field_option_value: "emailId",
    },
    {
        field_option_label: "Owner DOB",
        field_option_value: "dob",
    },
    {
        field_option_label: "Occupation",
        field_option_value: "occupation",
    },
    {
        field_option_label: "PAN Number",
        field_option_value: "panNo",
    },
    {
        field_option_label: "GST Id",
        field_option_value: "ownerGstIdNo",
    },
    {
        field_option_label: "Company Name",
        field_option_value: "companyName",
    },
    {
        field_option_label: "Contact Person First Name",
        field_option_value: "contactPersonFName",
    },
    {
        field_option_label: "Contact Person Last Name",
        field_option_value: "contactPersonLName",
    },
    {
        field_option_label: "Company GST",
        field_option_value: "companyGST",
    },

    //Nominee details
    {
        field_option_label: "Nominee First Name",
        field_option_value: "nomineeFirstName",
    },
    {
        field_option_label: "Nominee Last Name",
        field_option_value: "nomineeLastName",
    },
    {
        field_option_label: "Nominee Age",
        field_option_value: "nomineeAge",
    },
    {
        field_option_label: "Nominee Relationship With Owner",
        field_option_value: "relationshipWithOwner",
    },

    //Communication details
    {
        field_option_label: "Address",
        field_option_value: "address",
    },
    {
        field_option_label: "Pincode",
        field_option_value: "pincode",
    },

    // Inspection Details
    {
        field_option_label: "Inspection Contact Number",
        field_option_value: "contactPersonNumber",
    },
    {
        field_option_label: "Inspection Address",
        field_option_value: "vehiclePresentAddress",
    },

    //Vehicle details
    {
        field_option_label: "Previous Policy Number",
        field_option_value: "previousPolicyNumber",
    },
    {
        field_option_label: "Is Vehicle On Loan",
        field_option_value: "onLoanFlag",
    },
    {
        field_option_label: "Financier Name",
        field_option_value: "financierName",
    },
    {
        field_option_label: "Engine Number",
        field_option_value: "engineNo",
    },
    {
        field_option_label: "Chasis Number",
        field_option_value: "chasisNo",
    },
    {
        field_option_label: "Third Party Policy Number",
        field_option_value: "tpPolicyNumber",
    },

    // Custom Parameter
    {
        field_option_label: "Custom parameter 1",
        field_option_value: "param1",
    },
    {
        field_option_label: "Custom parameter 2",
        field_option_value: "param2",
    },
    {
        field_option_label: "Custom parameter 3",
        field_option_value: "param3",
    },
    {
        field_option_label: "Custom parameter 4",
        field_option_value: "param4",
    },
    {
        field_option_label: "Custom parameter 5",
        field_option_value: "param5",
    }
]

export const SOURCE = {
    // requests coming from assure core (admin, agents, etc)
    CORE: "core",
    // requests coming from clients (end users)
    GATE: "gate",
    // request sent by agents to client directly for payment
    GATE_PAYMENT: "gate-payment"
}

export const CODE_TYPE = {
    ONE_TIME: "ONE_TIME",
    TIME_BOUND: "TIME_BOUND"
}

export const ADMIN_SUBDOMAIN = "app";

export const REQUIRED_ERROR_MESSAGE = "Required"

export const DEFAULT_ADVANCED_CONFIG = {
    "minimum_idle": 10,
    "maximum_pool_size": 10,
    "idle_timeout": 6000000,
    "max_lifetime": 1800000,
    "connection_timeout": 30000,
    "keepalive_time": 0,
    "verify_server_certificate": "false",
    "use_ssl": "false",
    "require_ssl": "false",
    "trust_certificate_key_store_url": "",
    "trust_certificate_key_store_password": ""
};

export const TRUE_OR_FALSE_OPTION = [
    {
        field_option_label: "TRUE",
        field_option_value: "true",
    },
    {
        field_option_label: "FALSE",
        field_option_value: "false"
    }
]

export const SHOW_ADVANCED_CONFIG_OPTION = [
    {
        field_option_label: "Use Default Config",
        field_option_value: "default_config",
    },
    {
        field_option_label: "Use Custom Config",
        field_option_value: "custom_config"
    }
]

export const COLOR_SET = {
    a: "#836eff",
    b: "#ff5f88",
    c: "#ffa66e",
    d: "#ff5f88",
    e: "#f7b500",
    f: "#ea5455",
    g: "#e02020",
    h: "#44d7b6",
    i: "#fa6400",
    j: "#a0a9ba",
    k: "#836eff",
    l: "#ff5f88",
    m: "#ffa66e",
    n: "#ff5f88",
    o: "#f7b500",
    p: "#ea5455",
    q: "#e02020",
    r: "#44d7b6",
    s: "#fa6400",
    t: "#a0a9ba",
    u: "#f7b500",
    v: "#ea5455",
    w: "#e02020",
    x: "#44d7b6",
    y: "#fa6400",
    z: "#a0a9ba",
};
