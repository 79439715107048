import React, { Component } from 'react';
import Fab from '@material-ui/core/Fab';
import EyeIcon from '../../assets/images/icon/eye-blue.svg';
import AddIcon from '../../assets/images/icon/add-blue.svg';
import SearchField from '../fields/search';
import { Scrollbars } from 'react-custom-scrollbars';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import CloseIcon from '../../assets/images/icon/close.svg';
import ArrowBackGrey from '../../assets/images/icon/chevron-left.svg';
import InputField from '../fields/inputs';
import BuildIllust from '../../assets/images/illustration/build-iso.svg';
import TextAreaField from '../fields/textarea';
import ScratchIllus from '../../assets/images/illustration/scratch.svg';
import PolicyParamsIllus from '../../assets/images/illustration/policy-param-circle.svg';
import Slide from '@material-ui/core/Slide';

//Custom component
import TableComponent from '../common/table-component';
import UrlTabActions from '../../components/common/url-tab-actions';
import SwitchTabActions from '../../components/common/switch-tab-actions';
import MultipleTabActions from '../../components/common/multiple-tab-actions';

//Constants
import * as Constants from '../../utilities/constants';

//API
import * as FormApi from '../../api/form-api';

const tableRows = [
    { id: 'form_name', numeric: false, disablePadding: false, label: 'Name' },
    { id: 'noOfParameter', numeric: false, disablePadding: false, label: 'Number of Parameters' },
    { id: 'entries', numeric: true, disablePadding: false, label: 'Entries' },
    { id: 'created_on', numeric: true, disablePadding: false, label: 'Created on' },
    //{ id: 'actions', numeric: true, disablePadding: false, label: 'Actions' },
];
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function createData(index, form, editFormRedirect, recordsFormRedirect, previewFormRedirect, handleStatusChange) {

    let formNameView = (<UrlTabActions formName={form.form_name} handleNameClick={editFormRedirect(form.form_key)} />);
    let parameterCount = <div className="entries-shift"><UrlTabActions formName={form.field_count} withImg={false} handleNameClick={recordsFormRedirect(form.form_key)} /></div>;
    let recordCount = (<div className="entries-shift"><UrlTabActions formName={form.record_count} withImg={false} handleNameClick={recordsFormRedirect(form.form_key)} /></div>);
    //let actionView = (<MultipleTabActions type="icons" handleEditClick={editFormRedirect(form.form_key)} iconList={["edit","duplicate","delete"]}/>)
    let created_on = form.created_on;
    return { formNameView, parameterCount, recordCount, created_on };
}
class ProgramPolicyParam extends Component {

    state = {
        paramterTableData: [],
        form: {},
    }

    componentDidMount() {
        FormApi.getParameterFormData(this.props.programkey).then((formData, index) => {
            let paramterTableData = [];
            formData.forEach(form => {
                paramterTableData.push(createData(index, form, this.editFormRedirect, this.recordsFormRedirect, this.previewFormRedirect, this.handleStatusChange));
            })
            this.setState({ paramterTableData: paramterTableData });
        })
    }


    editFormRedirect = key => event => {
        event.stopPropagation();
        this.editForm(key);
    }

    editForm = key => {
        let programkey = this.props.programkey;
        window.location = '/' + this.props.clientId + "/" + programkey + "/form/" + key + "/builder";
    }

    previewFormRedirect = key => event => {
        event.stopPropagation();
        let programkey = this.props.programkey;
        window.location = '/' + this.props.clientId + "/" + programkey + "/form/" + key + "/preview";
    }

    recordsFormRedirect = key => event => {
        event.stopPropagation();
        let programkey = this.props.programkey;
        window.location = '/' + this.props.clientId + "/" + programkey + "/form/" + key + "/records";
    }

    handleStatusChange = (formId, status) => event => {
        // event.stopPropagation();
        // let {formList} = this.state;
        status = (status === "active") ? "inactive" : "active";
        this.generateTable(formId, status);
        // formList[index].form_status = (formList[index].form_status === "Active") ? 1 : 0;
        // this.setState({formList:formList}, () => this.generateTable());

    }

    handleNextClick() {
        this.props.handleSaveProgramData();
        this.props.modifyProgramSelection(Constants.PROGRAM.COVERAGE_LIMITS, 4);
    }

    handleBackClick() {
        this.props.modifyProgramSelection(Constants.PROGRAM.MASTER_POLICY, 2);
    }

    handleClose = (type) => (event) => {
        this.setState({ [type]: false });
    }

    handleDialogOpen(type) {
        this.setState({ [type]: true });
    }

    selectTriggerHandle = (triggerType) => (event) => {
        let { form } = this.state;
        form["form_type"] = triggerType;
        this.setState({ form: form });
        this.handleDialogOpen("formDialogOpen");
    }

    handleOnChangeValue = (fieldType) => (value) => {
        let { form } = this.state;
        form[fieldType] = value;
        this.setState({ form: form });
    }

    handleSubmitEvent = (event) => {
        let programkey = this.props.programkey;
        let formData = this.state.form;
        formData["program_key"] = programkey;
        FormApi.postFormData(this.state.form).then(res => {
            // this.getAllFormData();
            this.editForm(res.form_key);
            this.setState({ formDialogOpen: false })
        });
    }

    editForm = key => {
        let programkey = this.props.programkey;
        let clientId = this.props.clientId;
        window.location = "/" + clientId + "/" + programkey + "/form/" + key + "/builder";
    }

    renderCreateFormDialog() {
        let { formDialogOpen, form } = this.state;
        return (
            <Dialog open={formDialogOpen} onClose={this.handleClose("formDialogOpen")} TransitionComponent={Transition} maxWidth="xl">
                <section className="workflow-dialog workflow-dialog-large">
                    <div className="card-block">
                        <div className="card-header border-bottom">
                            <div className="sub-title">
                                <div className="close-dialog">
                                    <Button color="inherit" onClick={this.handleClose("formDialogOpen")}>
                                        <img src={ArrowBackGrey} alt=""></img> Go Back
                                    </Button>
                                </div>
                            </div>
                            <div className="close-dialog">
                                <img src={CloseIcon} alt="" onClick={this.handleClose("formDialogOpen")}></img>
                            </div>
                        </div>
                        <div className="card-body">
                            <Grid container>
                                <Grid item md={4}>
                                    <div className="workflow-illust-wrap">
                                        <div className="sub-title">
                                            <h2>How would you like to create your form?</h2>
                                        </div>
                                        <div className="workflow-illust">
                                            <img src={BuildIllust} alt="BuildIllust" />
                                        </div>
                                    </div>
                                </Grid>
                                <Grid item md={8}>
                                    <div className="workflow-dialog-forms form-builder-dashboard">
                                        <div className="tabs-container tabs-container-scroll">
                                            <Scrollbars style={{ width: '100%', height: '100%' }}>
                                                <div className="tabs-container-scroll-wrap">
                                                    <Grid item md={12}>
                                                        <div className="workflow-form-scene form-row">
                                                            <div className="workflow-form-select active">
                                                                <Button variant="contained" color="primary" className="btn">
                                                                    <img src={ScratchIllus} alt="ScratchIllus"></img>
                                                                </Button>
                                                                <h5>Scratch</h5>
                                                            </div>
                                                            {/* <div className="workflow-form-select">
                                                                <Button variant="contained" color="primary" className="btn">
                                                                    <img src={TemplateIllus} alt="TemplateIllus"></img>
                                                                </Button>
                                                                <h5>Template</h5>
                                                            </div> */}
                                                            <div className="workflow-form-info">
                                                                <h6 className="sub-title semi-bold">Create a form from scratch?</h6>
                                                                <h6 className="grey-color weight-300">You can create your program from scratch by creating and adding sections, forms,flows and users</h6>
                                                            </div>
                                                        </div>
                                                    </Grid>
                                                    <div className="workflow-form-field">
                                                        <Grid item md={5}>
                                                            <InputField label="Name of the Form" handleOnChangeValue={this.handleOnChangeValue("form_name").bind(this)} selected_value={form.form_name} is_mandatory="yes" field_validations={["v_required"]} type="text" name="form_name" fieldStyle="field-box bg-plane grey-color" placeholder="Name" />
                                                        </Grid>
                                                        <Grid item md={5}>
                                                            <TextAreaField label="Description" handleOnChangeValue={this.handleOnChangeValue("form_desc").bind(this)} selected_value={form.form_desc} name="form_description" rowmax="3" fieldStyle="field-box bg-plane grey-color" placeholder="Description" />
                                                        </Grid>
                                                    </div>
                                                </div>
                                            </Scrollbars>
                                        </div>
                                        <div className="form-row workflow-dialog-footer">
                                            <div className="btn-wrap no-border">
                                                <Fab variant="extended" size="large" className="btn btn-large btn-plane primary-color" onClick={this.handleSubmitEvent}>
                                                    Create Form
                                                </Fab>
                                            </div>
                                        </div>
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                </section>
            </Dialog>
        )
    }

    render() {
        let { paramterTableData } = this.state;
        return (
            <div className="drawer-tabs workflow-tabs-wrap">
                {this.renderCreateFormDialog()}
                <div className="drawer-tabs-header border-bottom">
                    <div className="workflow-title">
                        <h5>Policy Parameters</h5>
                    </div>
                    <div className="workflow-tabs-search">
                        <Fab variant="extended" size="large" className="btn btn-large secondary-color workflow-add-btn no-shadow" onClick={this.props.handleDownloadMasterPolicyFile} style={{ minWidth: '180px' }}>
                            <img src={EyeIcon} alt="EyeIcon" className="MuiSvgIcon" />
                            Master Policy
                        </Fab>
                    </div>
                </div>
                <div className="drawer-tabs-header">
                    <div className="workflow-title">
                        <h5></h5>
                    </div>
                    <div className="workflow-tabs-search">
                        <SearchField placeholder="Search here" />
                        <Fab variant="extended" size="large" className="btn btn-large secondary-color workflow-add-btn no-shadow" onClick={this.selectTriggerHandle(Constants.FORM_TYPE.PARAMETER.code).bind(this)}>
                            <img src={AddIcon} alt="AddIcon" /> &nbsp;
                            New Form
                        </Fab>
                    </div>
                </div>
                <div className="tab-container program-tab-scroll">
                    <Scrollbars style={{ width: '100%', height: '100%' }}>
                        {paramterTableData.length > 0 && (
                            <div className="tab-container">
                                <div className="table-wrapper">
                                    <TableComponent
                                        tableLabel={false}
                                        rows={tableRows}
                                        isPagginated={true}
                                        isSearchable={false}
                                        data={paramterTableData}
                                        searchLabel="Search for Forms"
                                        isCheckBoxEnabled={false}
                                        isToolboxPresent={false}
                                    />
                                </div>
                            </div>
                        )}
                        {paramterTableData.length <= 0 && (
                            <section className="blank-page-sec">
                                <div className="blank-page-wrap">
                                    <div className="blank-page-icon">
                                        <img src={PolicyParamsIllus} alt="PolicyParamsIllus" />
                                    </div>
                                    <div className="blank-page-info">
                                        <h5>You haven't added any Policy Parameters yet!</h5>
                                    </div>
                                    <Fab variant="extended" size="large" className="btn btn-large workflow-add-btn no-shadow primary-color" onClick={this.selectTriggerHandle(Constants.FORM_TYPE.PARAMETER.code).bind(this)}>
                                        New Parameters
                                    </Fab>
                                </div>
                            </section>
                        )}
                    </Scrollbars>
                </div>
                <div className="card-footer border-top p-l-0 p-r-0">
                    <div className="btn-wrap">
                        <Fab variant="extended" size="large" className="btn btn-large btn-plane primary-color" onClick={this.handleNextClick.bind(this)}>
                            Next
                        </Fab>
                        <Fab variant="extended" size="large" className="btn btn-large btn-plane grey-color" onClick={this.handleBackClick.bind(this)}>
                            Back
                        </Fab>
                    </div>
                </div>
            </div>
        )
    }
}

export default ProgramPolicyParam;