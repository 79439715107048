import React, { Component } from 'react';
import { Editor, EditorTools, EditorUtils, ProseMirror } from '@progress/kendo-react-editor';
import InputLabel from '@material-ui/core/InputLabel';
import Description from '../validators/descriptions';
import MandatoryValidator from '../validators/mandatory-validator';

class RichTextKendo extends Component {

    editor = null;

    handleKendoChange(value) {
        this.props.handleOnChangeValue(value);
    }

    render() {
        const { name, label, instruction, is_mandatory, defaultLabel, disabled, fieldStyle, customStyle, selected_value, default_value, rowmax } = this.props;
        const { Bold, Italic, Underline,
            AlignLeft, AlignRight, AlignCenter,
            Indent, Outdent,
            OrderedList, UnorderedList,
            Undo, Redo, Link, Unlink } = EditorTools;
        let defaultFieldStyle = "field-box";
        let isDisabled = this.props.is_disable === 'yes' ? true : false;
        const content = ``;

        if (this.editor && this.editor.view !== undefined) {
            if (default_value !== undefined) {
                EditorUtils.setHtml(this.editor.view, default_value);
            }
        }

        return (
            <div className={"form-row " + customStyle}>
                <div className={fieldStyle ? fieldStyle : defaultFieldStyle}>
                    <InputLabel
                        htmlFor={name}
                        className="label">
                        {label ? label : defaultLabel}
                        <MandatoryValidator value={is_mandatory} />
                    </InputLabel>
                    <Editor
                        tools={[
                            [Bold, Italic, Underline],
                            [Undo, Redo],
                            [Link, Unlink],
                            [AlignLeft, AlignCenter, AlignRight],
                            [OrderedList, UnorderedList, Indent, Outdent]
                        ]}
                        className="field"
                        ref={editor => this.editor = editor}
                        contentStyle={{ height: rowmax }}
                        defaultContent={default_value ? default_value : ""}
                        onExecute={({ state, transaction }) => {
                            if (state.doc.eq(transaction.doc)) {
                                return;
                            }
                            const nextState = ProseMirror.EditorState.create({
                                doc: transaction.doc,
                                selection: transaction.selection
                            });

                            const editorValue = EditorUtils.getHtml(nextState);
                            this.handleKendoChange(editorValue);
                        }}
                    />
                    <br />
                </div>
                <Description value={instruction} />
            </div>
        )
    }
}

RichTextKendo.defaultProps = {
    rowmax: 320,
    customStyle: ""
}

export default RichTextKendo;