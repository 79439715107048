import * as AjaxHelper from "../utilities/ajax-helper"
import { ApiConstants } from "./common/api-constants"

let apiBaseUrl = () => (ApiConstants.DEV_MODE === 'true' ? ApiConstants.HOST : window.location.origin) + ApiConstants.API_VERSION;

export function getAllUsers(flag) {
    if (flag === undefined) {
        return AjaxHelper.get(apiBaseUrl() + ApiConstants.USER, null, { "Content-Type": "application/json" });
    }
    else {
        return AjaxHelper.get(apiBaseUrl() + ApiConstants.USER + "?user=" + flag, null, { "Content-Type": "application/json" });
    }
}

export function getAdminClientUser(flag) {
    return AjaxHelper.get(apiBaseUrl() + ApiConstants.USER + "?clientId=" + flag, null, { "Content-Type": "application/json" });
}

export function getAllOrganization() {
    return AjaxHelper.get(apiBaseUrl() + ApiConstants.USER + "/company", null, { "Content-Type": "application/json" });
}

export function addUserRole(uesrRole) {
    return AjaxHelper.post(apiBaseUrl() + ApiConstants.USER + "/role", uesrRole, { "Content-Type": "application/json" });
}

export function postPermission(permissionSet) {
    return AjaxHelper.post(apiBaseUrl() + ApiConstants.PERMISSION_SET, permissionSet, { "Content-Type": "application/json" });
}

export function getAllPermission(programKey) {
    return AjaxHelper.get(apiBaseUrl() + ApiConstants.PERMISSION_SET + "/" + programKey + "/program", null, { "Content-Type": "application/json" });
}

export function addUserPermissionMapping(UserPermissionData) {
    return AjaxHelper.post(apiBaseUrl() + ApiConstants.USER_PERMISSION_MAPPING, UserPermissionData, { "Content-Type": "application/json" });
}

export function getUserPermissionMapping(programkey) {
    return AjaxHelper.get(apiBaseUrl() + ApiConstants.USER_PERMISSION_MAPPING + "/" + programkey, null, { "Content-Type": "application/json" });
}

export function getLoggedInUser() {
    return AjaxHelper.get(apiBaseUrl() + ApiConstants.USER + "/loggedUser", null, { "Content-Type": "application/json" });
}

export function getUserDetailsFromToken(token) {
    return AjaxHelper.get(apiBaseUrl() + ApiConstants.AUTH_USER + "/token/" + token, null, { "Content-Type": "application/json" });
}

export function postUpdatedPasswordToUserAuth(userData) {
    return AjaxHelper.post(apiBaseUrl() + ApiConstants.AUTH_USER + "/passwordUpdation", userData, { "Content-Type": "application/json" });
}

export function getUserDetailsFromUserProcessMapping() {
    return AjaxHelper.get(apiBaseUrl() + ApiConstants.USER + "/processMapping", null, { "Content-Type": "application/json" });
}

export function getUserDetailsFromUserName(userName) {
    return AjaxHelper.get(apiBaseUrl() + ApiConstants.USER + "/userDetails/" + userName, null, { "Content-Type": "application/json" });
}

export function updateUserDetails(userId, userDetails) {
    return AjaxHelper.put(apiBaseUrl() + ApiConstants.USER + "/" + userId, userDetails, { "Content-Type": "application/json" });

}

export function getPermissionSet(permissionSetId) {
    return AjaxHelper.get(apiBaseUrl() + ApiConstants.PERMISSION_SET + "/" + permissionSetId, null, { "Content-Type": "application/json" });
}

export function deleteUserPermission(permissionSetId) {
    return AjaxHelper.del(apiBaseUrl() + ApiConstants.PERMISSION_SET + "/" + permissionSetId, null, { "Content-Type": "application/json" });
}

export function deleteUserPermissionMappingById(userPermissionMappingId) {
    return AjaxHelper.del(apiBaseUrl() + ApiConstants.USER_PERMISSION_MAPPING + "/" + userPermissionMappingId, null, { "Content-Type": "application/json" });
}

export function logoutApplication() {
    return AjaxHelper.post(apiBaseUrl() + ApiConstants.AUTH_USER + "/logout", null, { "Content-Type": "application/json" });
}

export function confirmPassword(userDetails) {
    return AjaxHelper.post(apiBaseUrl() + ApiConstants.AUTH_USER + "/confirmPasscode", userDetails, { "Content-Type": "application/json" });
}

export function getUserPermissionMappingById(userPermissionMappingId) {
    return AjaxHelper.get(apiBaseUrl() + ApiConstants.USER_PERMISSION_MAPPING + "/id/" + userPermissionMappingId, null, { "Content-Type": "application/json" });
}

export function getProfileImage() {
    return AjaxHelper.get(apiBaseUrl() + ApiConstants.USER + "/profile-image", null, { "Content-Type": "application/json" }, false, "blob");
}
