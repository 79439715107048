import React, { Component } from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import Description from '../validators/descriptions';
import MandatoryValidator from '../validators/mandatory-validator';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';

class UserSetPermission extends Component {

    onCheckChange(event, index, type) {
        console.log("type", type, "event", event.target.checked, "index", index);
        let data = this.props.permissionData;

        data[index][type] = event.target.checked
        this.props.updatePermissionData(data);
    }

    render() {
        const { instruction, fieldStyle, customStyle, is_mandatory, defaultLabel, label, name, permissionData } = this.props;
        let defaultFieldStyle = "field-box";
        console.log("set_permission_77", permissionData);

        return (
            <section className="user-permission-sec">
                <div className={"form-row " + customStyle}>
                    <div className={fieldStyle ? fieldStyle : defaultFieldStyle}>
                        <InputLabel
                            shrink
                            htmlFor={name}
                            className="label">
                            {label ? label : defaultLabel}
                            <MandatoryValidator value={is_mandatory} />
                        </InputLabel>
                        <Description value={instruction} />
                        <div className="table-wrapper table-strip-line">
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Module</TableCell>
                                        <TableCell className="text-center">Access</TableCell>
                                        <TableCell className="text-center">View</TableCell>
                                        <TableCell className="text-center">Create</TableCell>
                                        <TableCell className="text-center">Edit</TableCell>
                                        <TableCell className="text-center">Delete</TableCell>
                                        {/* <TableCell className="text-center">More</TableCell> */}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {permissionData.map((permission, index) => (
                                        <TableRow>
                                            <TableCell>
                                                <h6>{permission.module_name}</h6>
                                            </TableCell>
                                            <TableCell className="text-center">
                                                <Checkbox checked={permission.is_access} onChange={(event) => this.onCheckChange(event, index, "is_access")} />
                                            </TableCell>
                                            <TableCell className="text-center">
                                                <Checkbox checked={permission.is_read} onChange={(event) => this.onCheckChange(event, index, "is_read")} />
                                            </TableCell>
                                            <TableCell className="text-center">
                                                <Checkbox checked={permission.is_create} onChange={(event) => this.onCheckChange(event, index, "is_create")} />
                                            </TableCell>
                                            <TableCell className="text-center">
                                                <Checkbox checked={permission.is_write} onChange={(event) => this.onCheckChange(event, index, "is_write")} />
                                            </TableCell>
                                            <TableCell className="text-center">
                                                <Checkbox checked={permission.is_delete} onChange={(event) => this.onCheckChange(event, index, "is_delete")} />
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default UserSetPermission;