import React, { Component } from 'react';
/* import SettingFlowIllust from '../../assets/images/illustration/login-iso.svg'; */
import { Scrollbars } from 'react-custom-scrollbars';
import Fab from '@material-ui/core/Fab';
import GenerateKeyCode from '../../components/common/generate-key';

class SettingApi extends Component {
    render() {

        return (
            <div className="drawer-tabs workflow-tabs-wrap">
                <div className="drawer-tabs-header border-bottom">
                    <div className="workflow-title">
                        <h5>Your API Key</h5>
                    </div>
                    <div className="workflow-tabs-search"></div>
                </div>
                <div className="tab-container">
                    <Scrollbars style={{ width: '100%', height: '100%' }}>
                        <section className="add-fields-wrap">
                            <div className="add-field-row">
                                <div className="added-field-grp">
                                    <div className="added-field">
                                        <GenerateKeyCode fieldStyle="field-box bg-plane field-dotted" label="API Key" disabled={true} />
                                    </div>
                                </div>
                            </div>
                        </section>
                    </Scrollbars>
                </div>
                <div className="card-footer border-top p-l-0 p-r-0">
                    <div className="btn-wrap">
                        <Fab variant="extended" size="large" className="btn btn-large btn-plane primary-color">
                            Save
                        </Fab>
                        <Fab variant="extended" size="large" className="btn btn-large btn-plane grey-color">
                            Cancel
                        </Fab>
                    </div>
                </div>
            </div>
        )
    }
}

export default SettingApi;