import React, { Component } from 'react';
import WorkflowHeader from '../../components/workflow/workflow-header';
import SectionFields from './section-fields';
import SectionSetup from './section-setup';
import SectionSetting from './section-setting';
import Drawer from '@material-ui/core/Drawer';

class SectionMaster extends Component {

    state = {
        settingSection: false,
        displayStateComponents: true,
        indexOfState: -1, //indexOfState is used while adding a sub-state into a specific state.
        editStatus: false,
        parentIndex: -1,
        childIndex: -1,
        newStateOrEdit: "",
    }

    displayStateComponentsChange(value, index) {
        this.setState({ displayStateComponents: value, indexOfState: index });
    }

    editStatusChange(value, parentIndex, childIndex) {
        this.setState({ editStatus: value, parentIndex: parentIndex, childIndex: childIndex });
    }

    newStateOrEditChange(value) {
        this.setState({ newStateOrEdit: value })
    }

    settingSectionChange() {
        console.log("settingChange");
        this.toggleDrawerSectionSettings("settingSection", true)
    }

    toggleDrawerSectionSettings(side, open) {
        this.setState({ [side]: open })
    }

    render() {


        const sideList = side => (
            <SectionSetup
                toggleDrawer={this.props.toggleDrawer}
                addState={this.props.addState}
                stateData={this.props.stateData}
                settingState={this.props.settingState}
                displayStateComponents={this.state.displayStateComponents}
                editStatus={this.state.editStatus}
                indexOfState={this.state.indexOfState}
                handleSaveAction={this.props.handleSaveAction.bind(this)}
                parentIndex={this.state.parentIndex}
                childIndex={this.state.childIndex}
                newStateOrEdit={this.state.newStateOrEdit}
                handleDeleteOfAllSubStatesById={this.props.handleDeleteOfAllSubStatesById.bind(this)}
                userOptionList={this.props.userOptionList}
                permissionList={this.props.permissionList}
                handleDeleteOfSubState={this.props.handleDeleteOfSubState.bind(this)}
            />
        );
        return (
            <div>
                <WorkflowHeader workflowType="normal" dashboardBackKey="workspace" insideValidation={true} insideBlock="section" tab="section" programKey={this.props.programkey} clientId={this.props.clientId} settingChange={this.settingSectionChange.bind(this)} componentName={this.props.sectionName} />
                <SectionFields
                    toggleDrawer={this.props.toggleDrawer}
                    stateData={this.props.stateData}
                    sectionName={this.props.sectionName}
                    sectionDetails={this.props.sectionDetails}
                    settingState={this.props.settingState}
                    displayStateComponentsChange={this.displayStateComponentsChange.bind(this)}
                    editStatusChange={this.editStatusChange.bind(this)}
                    handleDeleteOfState={this.props.handleDeleteOfState.bind(this)}
                    handleDeleteOfSubState={this.props.handleDeleteOfSubState.bind(this)}
                    newStateOrEditChange={this.newStateOrEditChange.bind(this)}
                />

                <Drawer anchor="right" open={this.props.addState} onClose={(event) => { this.props.toggleDrawer('right', false) }}>
                    {sideList('right')}
                </Drawer>

                <Drawer anchor="right" open={this.state.settingSection} onClose={(event) => { this.toggleDrawerSectionSettings('settingSection', false) }}>
                    <SectionSetting
                        toggleDrawerSectionSettings={this.toggleDrawerSectionSettings.bind(this)}
                        sectionDetails={this.props.sectionDetails}
                        updateSectionDetails={(sectionInfo) => this.props.updateSectionDetails(sectionInfo)}
                    />
                </Drawer>

            </div>
        );
    }
}
export default SectionMaster;