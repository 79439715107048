import React, { Component } from 'react';
import InputField from '../fields/inputs';

import TextAreaField from '../fields/textarea';
import SingleChoice from '../properties/single-choice';
import MultipleChoice from '../properties/multiple-choice';
import AdvanceChoice from '../properties/advance-choice';
import ToggleChoice from '../properties/toggle-choice';
import SelectField from '../fields/select';
import DateKendo from '../kendo/date-picker';
import TimePickerKendo from '../kendo/time-picker'
import Fab from '@material-ui/core/Fab';
import InputLabel from '@material-ui/core/InputLabel';
import deleteIcon from '../../assets/images/icon/delete-red.svg';
import copyBlueIcon from '../../assets/images/icon/task-blue.svg';
import IconButton from '@material-ui/core/IconButton';

import { CopyToClipboard } from 'react-copy-to-clipboard';
import Snackbar from '@material-ui/core/Snackbar'

import DateTimePickerKendo from '../kendo/date-time-picker';
import 'react-phone-input-2/lib/style.css';

//Constants
import * as FormConstants from '../../utilities/constants';
import * as CountryData from '../../utilities/country-data';
import CheckBoxField from '../fields/checkbox';
import FormulaComponent from '../properties/formula-component';

class FormProperties extends Component {

    generateFormProperty(propertyId, props) {
        let {
            checkValidateChangeHandle,
            checkVisibleChangeHandle,
            checkStateChangeHandle,
            selectedFieldProperty,
            selectedField,
            fieldSizeOnChange,
            labelOnChange,
            fieldLinkOnChange,
            instructionOnChange,
            placeholderOnChange,
            checkOrderChangeHandle,
            charSizeOnChange,
            allowedDaysOnChange,
            allowedHoursOnChange,
            fieldLayoutOnChange,
            optionOnChange,
            fieldOperationChange,
            namesOnChange,
            prefixOnChange,
            addressOnChange,
            countryCodeOnChange,
            defaultCountryCodeOnChange,
            optionSelectedChange,
            formData,
            fields,
            formDataUpdate,
            formFieldProperties,
            formFieldTypes,
            fieldOperation,
            errorMessageOnChange,
            openFormulaDialog,
            formulaDialogOpen,
            formulaTextChangeEvent,
            sectionOption,
            idFieldChange,
            remainingCharOnChange,
            remainingCharLimitOnChange,
            handleCopyToClip,
            snackbarOpen,
            deleteAllFormField,
            clearAndResetFrom
        } = props;
        let fieldOperationsValue = selectedFieldProperty.field_operations ? JSON.parse(JSON.stringify(selectedFieldProperty.field_operations)) : [];
        let propertyMetadata = formFieldProperties.filter(formFieldProperty => formFieldProperty.property_code === propertyId)[0]
        let fieldConditions = formFieldTypes.filter(formField => formField.field_code_txt === selectedFieldProperty.field_type)[0];
        let fieldType = selectedFieldProperty.field_type;
        let labelValue = selectedFieldProperty.label ? selectedFieldProperty.label : "";
        let instValue = selectedFieldProperty.instruction ? selectedFieldProperty.instruction : "";
        let validateValue = selectedFieldProperty.is_mandatory ? selectedFieldProperty.is_mandatory : "no";
        let visibleValue = selectedFieldProperty.is_hidden ? selectedFieldProperty.is_hidden : "no";
        let stateValue = selectedFieldProperty.is_disable ? selectedFieldProperty.is_disable : "no";

        let remainingCharValue = selectedFieldProperty.remaining_char ? selectedFieldProperty.remaining_char : "no";
        let placeholderValue = selectedFieldProperty.placeholder ? selectedFieldProperty.placeholder : "";
        let charSizeValue = selectedFieldProperty.char_size ? selectedFieldProperty.char_size : "";
        let remainingCharLimitValue = selectedFieldProperty.remaining_char_limit ? selectedFieldProperty.remaining_char_limit : null;
        let options = selectedFieldProperty.field_option_list;
        let sizeValue = selectedFieldProperty.field_size;
        let default_value = selectedFieldProperty.default_value ? selectedFieldProperty.default_value : "";
        let orderValue = selectedFieldProperty.is_alphabetical_order ? selectedFieldProperty.is_alphabetical_order : "no";
        let showFormData = formData.show_form ? formData.show_form : "no";
        let hoursArray = [];
        let prefixChecker = false;
        let filteredData = [];
        let countryCodeOptions = selectedFieldProperty.country_code_options ? codeUpdate(selectedFieldProperty.country_code_options) : JSON.parse(JSON.stringify(CountryData.COUNTRY_CODES)); //Used as Main Country Code selector                        
        let countryCodes = JSON.parse(JSON.stringify(CountryData.COUNTRY_CODES)); //Used to create FilteredData
        let defaultCountryCodes = []; //Used as DefaultCountryCode
        let formula_text = selectedFieldProperty.formula_text ? selectedFieldProperty.formula_text : "";
        let optionsName = [];
        let prefixName = [];
        let idField = selectedFieldProperty.id_field ? selectedFieldProperty.id_field : "no";
        let linkValue = selectedFieldProperty.field_key ? selectedFieldProperty.field_key : "";


        let validatePrefix = "";
        let validateFirstName = "";
        let validateLastName = "";
        let validateSuffix = "";
        let validateAddressLine1 = "";
        let validateAddressLine2 = "";
        let validateCityDistrict = "";
        let validateState = "";
        let validatePostalCode = "";
        let validateCountry = "";

        if (selectedFieldProperty.field_type === "f-name") {
            selectedFieldProperty.children.forEach(child => {
                if (child.is_mandatory === "yes" && child.field_alias === "Prefix") {
                    validatePrefix = "yes";
                }
                if (child.is_mandatory === "yes" && child.field_alias === "FirstName") {
                    validateFirstName = "yes";
                }
                if (child.is_mandatory === "yes" && child.field_alias === "LastName") {
                    validateLastName = "yes";
                }
                if (child.is_mandatory === "yes" && child.field_alias === "Suffix") {
                    validateSuffix = "yes";
                }

            })
        }
        if (selectedFieldProperty.field_type === "f-address") {
            selectedFieldProperty.children.forEach(child => {
                if (child.is_mandatory === "yes" && child.field_alias === "AddressLine1") {
                    validateAddressLine1 = "yes";
                }
                if (child.is_mandatory === "yes" && child.field_alias === "AddressLine2") {
                    validateAddressLine2 = "yes";
                }
                if (child.is_mandatory === "yes" && child.field_alias === "CityDistrict") {
                    validateCityDistrict = "yes";
                }
                if (child.is_mandatory === "yes" && child.field_alias === "State") {
                    validateState = "yes";
                }
                if (child.is_mandatory === "yes" && child.field_alias === "PostalCode") {
                    validatePostalCode = "yes";
                }
                if (child.is_mandatory === "yes" && child.field_alias === "Country") {
                    validateCountry = "yes";
                }

            })
        }

        if (propertyId === FormConstants.PropertyIds.P_DISPLAY_NAME || propertyId === FormConstants.PropertyIds.P_DISPLAY_ADDRESS) {
            let propertyData = JSON.parse(JSON.stringify(propertyMetadata.field_options));
            optionsName = selectedFieldProperty.display_name ? selectedFieldProperty.display_name : propertyData;
            // Validation for child-fields
            // optionsName.forEach(optionField => {
            //     if(optionField.checked){
            //         optionField["is_mandatory"] = selectedFieldProperty.is_mandatory;
            //     }
            // })
            // let childAddress =  selectedFieldProperty.children;
            // childAddress.forEach(childfield =>{
            //     childfield["is_mandatory"] = selectedFieldProperty.is_mandatory;
            // })

        }

        if (!fieldOperation.operation && fieldOperationsValue.length > 0) {
            //Only pop when next operation in not present
            if (!fieldOperationsValue[fieldOperationsValue.length - 1].next_operation) {
                fieldOperation = fieldOperationsValue.pop();
            }
        }

        //Assigning true to Checked Countries (Multi-Select)
        function codeUpdate(value) {
            let array = value.split(',');
            let countryData = JSON.parse(JSON.stringify(CountryData.COUNTRY_CODES));
            countryData.forEach(data => {
                data.checked = (array.includes(data.field_option_value))
            })
            return countryData;
        }

        //Assigning true to Checked Countries (Single-Select)
        function defaultUpdate(value, countryInfo) {
            let countryData = countryInfo;
            countryData.forEach(data => {
                data.checked = (data.field_option_value === value)
            })
            return countryData;
        }

        if (propertyId === FormConstants.PropertyIds.P_PREFIX_NAME) {
            selectedFieldProperty.display_name &&
                selectedFieldProperty.display_name.forEach(field => {
                    if (field.field_option_value === 'prefix') {
                        prefixChecker = field.checked;
                    }
                })
            let propertyData = JSON.parse(JSON.stringify(propertyMetadata.field_options));
            prefixName = selectedFieldProperty.prefix_name ? selectedFieldProperty.prefix_name : propertyData;

        }

        if (propertyId === FormConstants.PropertyIds.P_ALLOWED_DAYS) {
            let str = selectedFieldProperty.allowed_days.split(",");
            propertyMetadata.field_options.forEach(options => {
                options.checked = str.includes(options.field_option_value);
            })
        }

        if (propertyId === FormConstants.PropertyIds.P_ALLOWED_HOURS) {
            hoursArray = selectedFieldProperty.allowed_hours.split(",");
        }

        if (propertyId === FormConstants.PropertyIds.P_FIELD_LAYOUT) {
            let str = selectedFieldProperty.field_layout;
            propertyMetadata.field_options.forEach(options => {
                if (str === options.field_option_value) {
                    options.checked = true;
                }
                else {
                    options.checked = false;
                }
            })
        }

        //Creating FilteredData
        let array = [];
        fields.forEach(field => {
            if (field.field_type === FormConstants.FieldIds.F_PHONE_TEXT.code && field.position === selectedField) {
                if (field.country_code_options) {
                    array = field.country_code_options.split(",");
                    array.forEach(element => {
                        countryCodes.forEach(code => {
                            if (element === code.field_option_value) {
                                filteredData.push(code);
                            }
                        })
                    })

                }
            }
        })

        if (filteredData.length > 0) {
            filteredData.forEach(data => {
                data.checked = false
            })
        }

        if (filteredData.length > 0) {
            defaultCountryCodes = selectedFieldProperty.default_country_code ? defaultUpdate(selectedFieldProperty.default_country_code, filteredData) : filteredData;
        }
        else {
            defaultCountryCodes = selectedFieldProperty.default_country_code ? defaultUpdate(selectedFieldProperty.default_country_code, countryCodes) : countryCodes;
        }

        let validate = [
            {
                field_option_label: "Mandatory",
                field_option_value: "yes",
                checked: (validateValue === "yes")
            }
        ]
        let validateNameFields = [
            {
                field_option_label: "Mandatory Prefix",
                field_option_value: "prefix",

                checked: (validatePrefix === "yes" && selectedFieldProperty.display_name && selectedFieldProperty.display_name[0].checked)
            },
            {
                field_option_label: "Mandatory First Name",
                field_option_value: "firstName",
                checked: (validateFirstName === "yes" && selectedFieldProperty.display_name && selectedFieldProperty.display_name[1].checked)
            },
            {
                field_option_label: "Mandatory Last Name",
                field_option_value: "lastName",
                checked: (validateLastName === "yes" && selectedFieldProperty.display_name && selectedFieldProperty.display_name[2].checked)
            },
            {
                field_option_label: "Mandatory Suffix",
                field_option_value: "suffix",
                checked: (validateSuffix === "yes" && selectedFieldProperty.display_name && selectedFieldProperty.display_name[3].checked)
            }
        ]
        let validateAddressFields = [
            {
                field_option_label: "Mandatory Address Line 1",
                field_option_value: "address1",
                checked: (validateAddressLine1 === "yes" && selectedFieldProperty.display_name && selectedFieldProperty.display_name[0].checked)
            },
            {
                field_option_label: "Mandatory Address Line 2",
                field_option_value: "address2",
                checked: (validateAddressLine2 === "yes" && selectedFieldProperty.display_name && selectedFieldProperty.display_name[1].checked)
            },
            {
                field_option_label: "Mandatory City/District",
                field_option_value: "city-district",
                checked: (validateCityDistrict === "yes" && selectedFieldProperty.display_name && selectedFieldProperty.display_name[2].checked)
            },
            {
                field_option_label: "Mandatory State/Province",
                field_option_value: "state-province",
                checked: (validateState === "yes" && selectedFieldProperty.display_name && selectedFieldProperty.display_name[3].checked)
            },
            {
                field_option_label: "Mandatory Postal Code",
                field_option_value: "postal-code",
                checked: (validatePostalCode === "yes" && selectedFieldProperty.display_name && selectedFieldProperty.display_name[4].checked)
            },
            {
                field_option_label: "Mandatory Country",
                field_option_value: "country",
                checked: (validateCountry === "yes" && selectedFieldProperty.display_name && selectedFieldProperty.display_name[5].checked)
            }

        ]


        let visible = [
            {
                field_option_label: "Hidden",
                field_option_value: "yes",
                checked: (visibleValue === "yes")
            }
        ]

        let state = [
            {
                field_option_label: "Disable",
                field_option_value: "yes",
                checked: (stateValue === "yes")
            }
        ]

        let order = [
            {
                field_option_label: "Alphabetical",
                field_option_value: "yes",
                checked: (orderValue === "yes")
            }
        ]

        let operations = [
            {
                field_option_label: "And",
                field_option_value: "and",
            },
            {
                field_option_label: "Or",
                field_option_value: "or"
            }
        ];

        let hideFormId = [
            {
                field_option_label: "Yes",
                field_option_value: "yes",
                checked: (idField === "yes")
            },
        ]

        let showForm = [
            {
                field_option_label: "Show Form",
                field_option_value: "yes",
                checked: (showFormData === "yes")
            },
        ]

        let remainingChar = [
            {
                field_option_label: "Set Remaining Char Limit",
                field_option_value: "yes",
                checked: (remainingCharValue === "yes")
            }
        ]

        if (validateValue === "yes") {
            stateValue = "no";
            visibleValue = "no";
        }

        let childFieldData = [];

        if (selectedFieldProperty.group_field) {
            selectedFieldProperty.children.forEach((child, index) => {
                if (selectedFieldProperty.display_name[index].checked) {
                    let obj = {
                        checked: false,
                        field_option_label: child.label,
                        field_option_value: child.id,
                    }
                    childFieldData.push(obj);
                }

            })
        }

        function renderFieldValue(fieldOperation, checkHandle, index) {
            let fieldDataList = fields;
            let field = {};
            if (selectedFieldProperty.group_field) {
                selectedFieldProperty.children.forEach(child => {
                    if (child.id === fieldOperation.child_field) {
                        field = child;
                    }
                })
            } else {
                field = fieldDataList.filter(fieldData => (fieldData.id === selectedFieldProperty.id))[0];
            }

            let nullChecker = fieldOperation.hasOwnProperty('checkNull') ? fieldOperation.checkNull : false;
            let operationChecker = fieldOperation.operation ? false : true;
            let valueField = (
                <InputField
                    withLabel={false}
                    selected_value={fieldOperation.field_value}
                    //disabled= {fieldOperation?true:false}
                    disabled={true}
                    placeholder="Enter Value"
                    handleOnChangeValue={checkHandle ? (value, error) => fieldOperationChange(index, "field_value", value) : (value, error) => fieldOperationChange(fieldOperationsValue.length, "field_value", value)}
                />
            );
            if (field) {
                switch (field.field_type) {
                    case FormConstants.FieldIds.F_SINGLE_DROPDOWN_GROUP.code:
                    case FormConstants.FieldIds.F_MULTIPLE_DROPDOWN_GROUP.code:
                    case FormConstants.FieldIds.F_CHECKBOX_GROUP.code:
                    case FormConstants.FieldIds.F_RADIO_GROUP.code:
                        valueField = (
                            <SelectField
                                withLabel={false}
                                optionsData={field.field_option_list}
                                selected_value={fieldOperation.field_value}
                                disabled={operationChecker || nullChecker}
                                radioChangeHandle={checkHandle ? (value, error) => fieldOperationChange(index, "field_value", value) : (value, error) => fieldOperationChange(fieldOperationsValue.length, "field_value", value)} />
                        );
                        break;
                    case FormConstants.FieldIds.F_DATE.code:
                        valueField = (
                            <DateKendo
                                handleDateChange={checkHandle ? (value, error) => fieldOperationChange(index, "field_value", value) : (value, error) => fieldOperationChange(fieldOperationsValue.length, "field_value", value)}
                                selected_value={fieldOperation.field_value}
                                disabled={operationChecker || nullChecker}
                                name="form_date" />
                        );
                        break;
                    case FormConstants.FieldIds.F_TIME.code:
                        valueField = (
                            <TimePickerKendo
                                handleDateChange={checkHandle ? (value, error) => fieldOperationChange(index, "field_value", value) : (value, error) => fieldOperationChange(fieldOperationsValue.length, "field_value", value)}
                                selected_value={fieldOperation.field_value}
                                disabled={operationChecker || nullChecker}
                                name="form_date" />
                        );
                        break;
                    case FormConstants.FieldIds.F_DATE_TIME.code:
                        valueField = (
                            <DateTimePickerKendo
                                handleDateChange={checkHandle ? (value, error) => fieldOperationChange(index, "field_value", value) : (value, error) => fieldOperationChange(fieldOperationsValue.length, "field_value", value)}
                                selected_value={fieldOperation.field_value}
                                disabled={operationChecker || nullChecker}
                                name="form_date" />
                        );
                        break;
                    case FormConstants.FieldIds.F_NUMBER_TXT.code:
                        valueField = (
                            <InputField
                                withLabel={false}
                                type="number"
                                selected_value={fieldOperation.field_value}
                                disabled={operationChecker || nullChecker}
                                handleOnChangeValue={checkHandle ? (value, error) => fieldOperationChange(index, "field_value", value) : (value, error) => fieldOperationChange(fieldOperationsValue.length, "field_value", value)} />
                        );
                        break;
                    default:
                        valueField = (
                            <InputField
                                withLabel={false}
                                selected_value={fieldOperation.field_value}
                                disabled={operationChecker || nullChecker}
                                handleOnChangeValue={checkHandle ? (value, error) => fieldOperationChange(index, "field_value", value) : (value, error) => fieldOperationChange(fieldOperationsValue.length, "field_value", value)} />
                        );
                        break;
                }
            }

            return valueField;
        }
        console.log(" my property Id ", propertyId)
        switch (propertyId) {
            case FormConstants.PropertyIds.P_FORM:
                return (
                    <div>
                        <InputField label="Form Name" type="text" name="form_name" selected_value={formData.form_name} handleOnChangeValue={formDataUpdate("form_name")} />
                        <TextAreaField label="Form Description" name="form_description" selected_value={formData.form_desc} handleOnChangeValue={formDataUpdate("form_desc")} rowmax="3" />

                        {formData.form_type === FormConstants.FORM_TYPE.SECTIONAL.code && (
                            <div>
                                <InputField label="Share Link" type="text" name="form_name"
                                    disabled={true}
                                    selected_value={window.location.href.substring(0, window.location.href.lastIndexOf('/')) + "/publish"}
                                    handleOnChangeValue={formDataUpdate("form_name")}
                                    adornment="end"
                                    fieldStyle="field-box field-adornment bg-plane grey-color field-dotted"
                                    adornmentContent={
                                        <div>
                                            <CopyToClipboard
                                                text={window.location.href.substring(0, window.location.href.lastIndexOf('/')) + "/publish"}>
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={() => this.props.handleCopyToClip(true)}>
                                                    <img src={copyBlueIcon} alt="copyBlueIcon" className="MuiSvgIcon"></img>
                                                </IconButton>
                                            </CopyToClipboard>
                                            <Snackbar
                                                anchorOrigin={{
                                                    vertical: 'bottom',
                                                    horizontal: 'right',
                                                }}
                                                open={this.props.snackbarOpen}
                                                autoHideDuration={800}
                                                onClose={() => this.props.handleCopyToClip(false)}
                                                message={<span id="message-id">Copied</span>}
                                                ContentProps={{
                                                    'aria-describedby': 'message-id',
                                                }}
                                            >
                                            </Snackbar>
                                        </div>
                                    }
                                />
                                <SelectField label="Select Section" optionsData={sectionOption} is_search={false} selected_value={formData.section_id} radioChangeHandle={formDataUpdate("section_id")} />
                            </div>
                        )}

                        <CheckBoxField
                            label="Assign form to Section Detail View"
                            checkChangeHandle={formDataUpdate("show_form")}
                            selected_value={formData.show_form}
                            checkData={showForm}
                        />

                        <div className="btn-wrap">
                            <Fab variant="extended" size="small" className="btn btn-small btn-outline pink-color" onClick={deleteAllFormField}>
                                Clear All
                            </Fab>
                            {
                                formData.template_used && <Fab variant="extended" size="small" className="btn btn-small btn-outline pink-color" onClick={clearAndResetFrom}>
                                    Clear and Reset
                                </Fab>
                            }
                        </div>
                    </div>
                );
            case FormConstants.PropertyIds.P_LABEL:
                return (
                    <InputField key={propertyId} label="Field Label" type="text" name="field_label" selected_value={labelValue} handleOnChangeValue={labelOnChange} />
                );
            case FormConstants.PropertyIds.P_FIELD_LINK:
                return (
                    <InputField key={propertyId} label="Field Link" type="text" name="field_link" selected_value={linkValue} handleOnChangeValue={fieldLinkOnChange} />
                );
            case FormConstants.PropertyIds.P_PLACE_HOLDER:
                return (
                    <InputField key={propertyId} label="Placeholder" type="text" name="field_place" selected_value={placeholderValue} handleOnChangeValue={placeholderOnChange} />
                );
            case FormConstants.PropertyIds.P_INSTRUCTION:
                return (
                    <TextAreaField key={propertyId} label="Instructions" name="instructions" rowmax="3" selected_value={instValue} handleOnChangeValue={instructionOnChange} />
                );
            case FormConstants.PropertyIds.P_MANDATORY:
                if (stateValue !== "yes" && visibleValue !== "yes") {
                    if (selectedFieldProperty.field_type === FormConstants.FieldIds.F_NAME.code) {
                        return (
                            <div>
                                <CheckBoxField
                                    key={propertyId}
                                    checkData={validateNameFields}
                                    checkChangeHandle={checkValidateChangeHandle}
                                    label="Validation"


                                />


                            </div>
                        );
                    }
                    else if (selectedFieldProperty.field_type === FormConstants.FieldIds.F_ADDRESS.code) {
                        return (
                            <div>
                                <CheckBoxField
                                    key={propertyId}
                                    checkData={validateAddressFields}
                                    checkChangeHandle={checkValidateChangeHandle}
                                    label="Validation"
                                />
                            </div>
                        )
                    }
                    else {
                        return (
                            <CheckBoxField
                                key={propertyId}
                                checkData={validate}
                                checkChangeHandle={checkValidateChangeHandle}
                                label="Validations"
                                selectedField={selectedFieldProperty}
                                selectedFieldKey={"default"}
                            />
                        )
                    }
                }

            case FormConstants.PropertyIds.P_VISIBILITY:
                return (
                    validateValue !== "yes" &&
                    <CheckBoxField
                        key={propertyId}
                        checkData={visible}
                        checkChangeHandle={checkVisibleChangeHandle}
                        label="Visibility"
                    />

                );

            case FormConstants.PropertyIds.P_STATE:
                return (
                    validateValue !== "yes" &&
                    <CheckBoxField
                        key={propertyId}
                        checkData={state}
                        checkChangeHandle={checkStateChangeHandle}
                        label="State"
                    />
                );
            case FormConstants.PropertyIds.P_SIZE:
                return (
                    <ToggleChoice label="Field Size" optionOnChangeEvent={fieldSizeOnChange} selected_value={sizeValue} />
                );
            case FormConstants.PropertyIds.P_SIN_OPT:
                return (
                    <div key={propertyId}>
                        <div className="form-row">
                            <div className="sub-title">
                                <h6 className="font-med">Choice</h6>
                                <AdvanceChoice radioData={options} optionOnChangeEvent={optionOnChange} />
                            </div>
                        </div>
                        <SingleChoice fieldType={fieldType} radioData={options} default_value={default_value} optionOnChangeEvent={optionOnChange} optionSelectedEvent={optionSelectedChange} />
                    </div>
                );
            case FormConstants.PropertyIds.P_MUL_OPT:
                return (
                    <div key={propertyId}>
                        <div className="form-row">
                            <div className="sub-title">
                                <h6 className="font-med">Choice</h6>
                                <AdvanceChoice radioData={options} optionOnChangeEvent={optionOnChange} />
                            </div>
                        </div>
                        <MultipleChoice checkData={options} optionOnChangeEvent={optionOnChange} />
                    </div>
                );
            case FormConstants.PropertyIds.P_MUL_NAMES:
                return (
                    <div key={propertyId}>
                        <div className="form-row">
                            <div className="sub-title">
                                <h6 className="font-med">Names</h6>
                                <AdvanceChoice />
                            </div>
                        </div>
                        <MultipleChoice showColors={true} checkData={options} isCheckboxEnabled={false} optionOnChangeEvent={optionOnChange} />
                    </div>
                );
            case FormConstants.PropertyIds.P_INITIAL_VAL:
                {
                    switch (fieldType) {
                        case FormConstants.FieldIds.F_SINGLE_TEXT.code:
                        case FormConstants.FieldIds.F_MULTI_TEXT.code: return (
                            <InputField key={propertyId} label="Initial Value" type="text" name="field_initial" selected_value={default_value} handleOnChangeValue={optionSelectedChange} />
                        );
                        case FormConstants.FieldIds.F_DATE.code: return (
                            <DateKendo key={propertyId} label="Initial Value" name="field_initial" selected_value={default_value} allowed_days={selectedFieldProperty.allowed_days} handleDateChange={optionSelectedChange} />
                        )
                        case FormConstants.FieldIds.F_TIME.code:
                            return (
                                <TimePickerKendo key={propertyId} label="Initial Value" name="field_initial" selected_value={default_value} allowed_hours={selectedFieldProperty.allowed_hours} handleDateChange={optionSelectedChange} />
                            )
                        case FormConstants.FieldIds.F_DATE_TIME.code:
                            return (
                                <DateTimePickerKendo key={propertyId} label="Initial Value" name="field_initial" selected_value={default_value} allowed_days={selectedFieldProperty.allowed_days} allowed_hours={selectedFieldProperty.allowed_hours} handleDateChange={optionSelectedChange} />
                            )
                        default: return (
                            <InputField key={propertyId} label="Initial Value" type="text" name="field_initial" selected_value={default_value} handleOnChangeValue={optionSelectedChange} />
                        )
                    }
                }
            case FormConstants.PropertyIds.P_ORDER:
                return (
                    <CheckBoxField
                        key={propertyId}
                        checkData={order}
                        checkChangeHandle={checkOrderChangeHandle}
                        label="Order"
                    />
                );
            case FormConstants.PropertyIds.P_CHAR_SIZE:
                return (
                    <InputField key={propertyId} label="Char Maximum" type="number" name="field_char" default_value={propertyMetadata ? propertyMetadata.default_value : 255} selected_value={charSizeValue} handleOnChangeValue={charSizeOnChange} />
                );
            case FormConstants.PropertyIds.P_ALLOWED_DAYS:
                return (
                    <SelectField key={propertyId} label='Allowed Days' name="field_days" isMultiple='yes' optionsData={propertyMetadata.field_options} radioChangeHandle={allowedDaysOnChange} />
                );
            case FormConstants.PropertyIds.P_ALLOWED_HOURS:
                return (
                    <div>
                        <SelectField key={propertyId} label='Allowed Hours' name="field_hours_from" selected_value={hoursArray[0]} optionsData={propertyMetadata.field_options} radioChangeHandle={allowedHoursOnChange} />
                        <SelectField key={propertyId} name="field_hours_to" selected_value={hoursArray[1]} optionsData={propertyMetadata.field_options} radioChangeHandle={allowedHoursOnChange} />
                    </div>
                );
            case FormConstants.PropertyIds.P_FIELD_LAYOUT:
                return (
                    <SelectField key={propertyId} label='Field Layout' name="field_layout" default_value={propertyMetadata.default_value} optionsData={propertyMetadata.field_options} radioChangeHandle={fieldLayoutOnChange} />
                );
            case FormConstants.PropertyIds.P_DISPLAY_ADDRESS:
                return (
                    <div key={propertyId}>
                        <div className="form-row">
                            <div className="sub-title">
                                <label className="label">Display Address Fields</label>
                                <AdvanceChoice />
                            </div>
                        </div>
                        <MultipleChoice checkData={optionsName} isRigid={true} optionOnChangeEvent={addressOnChange} />
                    </div>
                );
            case FormConstants.PropertyIds.P_VALIDATION_FIELD:

                let filteredFieldConditions = fieldConditions.field_conditions.filter((field) => {
                    return field.field_option_value !== FormConstants.FIELD_OPERATION.IS_NULL && field.field_option_value !== FormConstants.FIELD_OPERATION.IS_NOT_NULL
                })

                return (
                    <div className="form-row">
                        <div className="properties-conditional">
                            <div className="field-box">
                                <InputLabel className="label">Conditional Validation</InputLabel>
                            </div>
                            <div className="actions-prop-wrap">
                                {
                                    fieldOperationsValue.map((field_operation, index) => {
                                        return (
                                            <div className="actions-prop-row flex-wrap" style={{ position: 'relative' }}>
                                                <div className="actions-prop-row">
                                                    <div className="form-row">
                                                        {
                                                            childFieldData.length > 0 &&
                                                            <SelectField
                                                                withLabel={false}
                                                                selected_value={field_operation.child_field}
                                                                optionsData={childFieldData}
                                                                radioChangeHandle={(value, error) => fieldOperationChange(index, "child_field", value)}
                                                            />
                                                        }
                                                        <SelectField withLabel={false}
                                                            optionsData={validateValue === "yes" ? filteredFieldConditions : fieldConditions.field_conditions}
                                                            selected_value={field_operation.operation}
                                                            radioChangeHandle={(value, error) => fieldOperationChange(index, "operation", value)}
                                                        />
                                                        {renderFieldValue(field_operation, true, index)}
                                                    </div>

                                                    <div className="form-row btn-actions">
                                                        <Fab aria-label="delete" variant="extended" size="small" className="btn btn-small btn-fab btn-plane btn-curve" onClick={(value, error) => fieldOperationChange(index, "delete")}>
                                                            <img alt="delete" src={deleteIcon}></img>
                                                        </Fab>
                                                    </div>
                                                </div>

                                                <div className="actions-prop-row-full">
                                                    <div className="form-row select-outline select-action sort-action">
                                                        <SelectField withLabel={false} optionsData={operations}
                                                            selected_value={field_operation.next_operation}
                                                            radioChangeHandle={(value, error) => fieldOperationChange(index, "next_operation", value)} />
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }

                                <div className="actions-prop-row flex-wrap" style={{ position: 'relative', zIndex: 99 }}>
                                    <div className="actions-prop-row">
                                        <div className="form-row">
                                            {
                                                childFieldData.length > 0 &&
                                                <SelectField
                                                    withLabel={false}
                                                    selected_value={fieldOperation.child_field}
                                                    optionsData={childFieldData}
                                                    radioChangeHandle={(value, error) => fieldOperationChange(fieldOperationsValue.length, "child_field", value)}
                                                />
                                            }
                                            <SelectField
                                                withLabel={false}
                                                selected_value={fieldOperation.operation}
                                                optionsData={validateValue === "yes" ? filteredFieldConditions : fieldConditions.field_conditions}
                                                radioChangeHandle={(value, error) => fieldOperationChange(fieldOperationsValue.length, "operation", value)}
                                            />
                                            {renderFieldValue(fieldOperation, false)}
                                        </div>
                                        <div className="form-row btn-actions">
                                            <Fab aria-label="delete" variant="extended" size="small" className="btn btn-small btn-fab btn-plane btn-curve" onClick={(value, error) => fieldOperationChange(null, "delete")}>
                                                <img alt="delete" src={deleteIcon}></img>
                                            </Fab>
                                        </div>
                                    </div>
                                </div>
                                <div className="actions-prop-row">
                                    <div className="form-row">
                                        <div className="btn-wrap">
                                            <Fab variant="extended" size="small" className="btn btn-small btn-fab btn-plane btn-curve" onClick={(event) => fieldOperationChange(fieldOperationsValue.length, "next_operation", "and")}> AND </Fab>
                                            <Fab variant="extended" size="small" className="btn btn-small btn-fab btn-plane btn-curve" onClick={(event) => fieldOperationChange(fieldOperationsValue.length, "next_operation", "or")}> Or </Fab>
                                        </div>
                                        <div className="p-t-15">
                                            <TextAreaField label="Error Message" placeholder="Description" rowmax="3" selected_value={selectedFieldProperty.error_message} handleOnChangeValue={errorMessageOnChange} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            case FormConstants.PropertyIds.P_DISPLAY_NAME:
                return (
                    <div key={propertyId}>
                        <div className="form-row">
                            <div className="sub-title">
                                <h6 className="font-med">Display Name</h6>
                                <AdvanceChoice />
                            </div>
                        </div>
                        <MultipleChoice checkData={optionsName} isRigid={true} optionOnChangeEvent={namesOnChange} />
                    </div>
                );
            case FormConstants.PropertyIds.P_PREFIX_NAME:
                return (

                    <div key={propertyId}>
                        {prefixChecker &&
                            <div>
                                <div className="form-row">
                                    <div className="sub-title">
                                        <h6 className="font-med">Prefix Choice</h6>
                                        <AdvanceChoice />
                                    </div>
                                </div>
                                <SingleChoice radioData={prefixName} isRadio={true} default_value={default_value} optionSelectedEvent={optionSelectedChange} optionOnChangeEvent={prefixOnChange} />
                            </div>
                        }
                    </div>
                );
            case FormConstants.PropertyIds.P_COUNTRY_CODE_OPTIONS:
                return (
                    <SelectField key={propertyId} label='Country Code' name="country_codes" isMultiple='yes' selected_value={selectedFieldProperty.country_code_options} optionsData={countryCodeOptions} radioChangeHandle={countryCodeOnChange} />
                );
            case FormConstants.PropertyIds.P_DEFAULT_COUNTRY_CODE:
                return (
                    <SelectField key={propertyId} label='Default Country Code' name="def_country_codes" optionsData={defaultCountryCodes} selected_value={selectedFieldProperty.default_country_code} radioChangeHandle={defaultCountryCodeOnChange} />
                );
            case FormConstants.PropertyIds.P_CALCULATION_FORMULA:
                return (
                    <div>
                        <TextAreaField key={propertyId} label="Formula" name="formula" rowmax="3" selected_value={formula_text} handleClickEvent={(event) => formulaDialogOpen(true)} is_disable={"yes"} />
                        <FormulaComponent formula_text={formula_text} openFormulaDialog={openFormulaDialog} handleDialogEvent={formulaDialogOpen} fields={fields} formulaTextChangeEvent={formulaTextChangeEvent} />
                    </div>
                );
            case FormConstants.PropertyIds.P_ID_FIELD:
                return (
                    <CheckBoxField
                        label="ID Field"
                        checkChangeHandle={idFieldChange}
                        selected_value={idField}
                        default_value={idField}
                        checkData={hideFormId}
                    />

                );
            case FormConstants.PropertyIds.P_REMAINING_CHAR:
                return (
                    <div>
                        <CheckBoxField
                            key={propertyId}
                            checkData={remainingChar}
                            checkChangeHandle={remainingCharOnChange}
                            label="Limit"
                        />
                        {remainingCharValue === "yes" ?
                            <InputField key={propertyId} type="number" selected_value={remainingCharLimitValue} handleOnChangeValue={remainingCharLimitOnChange} />
                            : selectedFieldProperty.remaining_char_limit = null
                        }

                    </div>
                );

            default: break;
        }
    }

    componentWillReceiveProps(nextProps) {

        if (nextProps.selectedField && this.props.selectedField && (nextProps.selectedField === this.props.selectedField)) {
            if (nextProps.selectedFieldProperty.field_key !== this.props.selectedFieldProperty.field_key) {
                //updating the unique form field key when key name changes
                // this.props.generateUniqueLink();
            }
        }
        // if(nextProps.selectedField && this.props.selectedField && (nextProps.selectedField !== this.props.selectedField)){
        //     this.props.saveFieldOperation(this.props.selectedField);
        // }
    }

    render() {

        let { selectedFieldPropertyTypes } = this.props;
        return (
            <div className="properties-block">

                <div className="card-body">
                    <div className="properties-form">
                        {
                            selectedFieldPropertyTypes.map(propertyTypes => {
                                return this.generateFormProperty(propertyTypes, this.props)
                            })
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default FormProperties;