
import React, { Component } from 'react';
import SelectField from '../fields/select';
import InputLabel from '@material-ui/core/InputLabel';
import Fab from '@material-ui/core/Fab';
import Add from '@material-ui/icons/Add';
import Minimize from '@material-ui/icons/Minimize';
import Radio from '@material-ui/core/Radio';


import AddIcon from '../../assets/images/icon/add-blue.svg';

import RadioField from '../fields/radio';
import InputField from '../fields/inputs';



class UpdateLedger extends Component {

    state = {
        cancelTypeSelec: true
    }

    componentWillMount() {
        console.log("Update Ledger", this.props);

    }
    handleChange = (index) => (value) => {
        console.log(index);
        let checkData = this.props.checkData;
        checkData[index]["criteria"] = value;
        this.props.optionOnChangeEvent(checkData);
        console.log(checkData);
        // this.setState({cancelTypeSelec : (value === "include-date")}); 
    }

    addNewOption = index => event => {
        let checkData = this.props.checkData;
        let option = {};
        checkData.splice(index + 1, 0, option);
        this.props.optionOnChangeEvent(checkData);
    }

    deleteOption = index => event => {
        let checkData = this.props.checkData;
        checkData.splice(index, 1);

        this.props.optionOnChangeEvent(checkData);
    }

    handleClickData(event, index) {

        console.log(event, index);
    }
    handleChangeData = (index, opt) => (value) => {

        console.log(value, index, opt);

        let checkData = this.props.checkData;
        checkData[index][opt] = value;
        this.props.optionOnChangeEvent(checkData);
        console.log(checkData);
    }
    render() {
        let { checkData } = this.props;
        let checkDataUpdated = JSON.parse(JSON.stringify(checkData));
        let cancelType = [
            {
                field_option_label: "Include Dates",
                field_option_value: "include-date",
            },
            {
                field_option_label: "Exclude Dates",
                field_option_value: "exclude-dates"
            }
        ]
        return (

            <section className="add-fields-wrap">
                <div className="add-fields-wrap-sec">
                    <div className="form-row">
                        <div className="title border-bottom">
                            <InputLabel
                                htmlFor={"OTHER fIELD"}
                                className="label m-0"
                            >
                                Other Fields
                            </InputLabel>
                        </div>
                    </div>
                </div>
                {
                    checkDataUpdated.map((check, index) => {
                        return (
                            <div className="add-multiple-wrap-sec">
                                <div className="add-field-row">
                                    <div className="added-field-grp">
                                        <div className="added-field">
                                            <InputField
                                                label="No Of Days"
                                                placeholder="Specify Days"
                                                //default_value = {this.props.selectedIcon.text}
                                                default_value={checkData[index]["no_of_days"] != undefined ? checkData[index]["no_of_days"] : ""}
                                                selected_value={checkData[index]["no_of_days"] != undefined ? checkData[index]["no_of_days"] : ""}
                                                // handleOnClickEvent={(event)=>this.handleClickData(event,index)}
                                                handleOnChangeValue={this.handleChangeData(index, "no_of_days").bind(this)}
                                            // handleOnChangeValue={}
                                            // fieldStyle="field-box bg-plane grey-color"
                                            />

                                            <InputField
                                                label="Percentage"
                                                placeholder="Enter Percentage"
                                                //default_value = {this.props.selectedIcon.text}
                                                default_value={checkData[index]["percentage"] != undefined ? checkData[index]["percentage"] : ""}
                                                selected_value={checkData[index]["percentage"] != undefined ? checkData[index]["percentage"] : ""}
                                                // handleOnClickEvent={(event)=>this.handleClickData(event,index)}
                                                handleOnChangeValue={this.handleChangeData(index, "percentage").bind(this)}
                                            // fieldStyle="field-box bg-plane grey-color"
                                            />
                                        </div>
                                        <div className="added-field">
                                            <RadioField
                                                radioGroupLabel="Slab Selection Type"
                                                radioData={cancelType}
                                                label="Slab Selection Type"
                                                radioFieldStyle="field-inline"
                                                customStyle="m-0"
                                                default_value={checkData[index]["criteria"] != undefined ? checkData[index]["criteria"] : ""}
                                                radioChangeHandle={this.handleChange(index).bind(this)}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="add-field-row add-field-btn">
                                    <div className="added-field-grp">
                                        <Fab variant="extended" size="small" className="btn btn-small btn-plane secondary-color btn-fab no-shadow " onClick={this.addNewOption(index)}>
                                            <img src={AddIcon} alt="AddIcon" />
                                        </Fab>
                                        {(checkData.length <= 1 && index === 0) ? "" :
                                            (
                                                <Fab variant="extended" size="small" className="btn btn-small btn-plane pink-color btn-fab no-shadow btn-delete" onClick={this.deleteOption(index)}>
                                                    <Minimize />
                                                </Fab>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                        )
                    }
                    )
                }
            </section>
        )
    }
}

export default UpdateLedger;