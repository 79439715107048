import React, { Component } from 'react';
import {
    DragSource,
    DropTarget,
} from 'react-dnd'
import { findDOMNode } from 'react-dom';
import flow from 'lodash.flow';
import Fab from '@material-ui/core/Fab';
// import DeleteIcon from '@material-ui/icons/Delete';
// import FileCopy from '@material-ui/icons/FileCopy';
import CircularProgress from '@material-ui/core/CircularProgress';
import deleteWhiteIcon from '../../assets/images/icon/delete-white.svg';
import deleteRedIcon from '../../assets/images/icon/delete-red.svg';
import duplicateWhiteIcon from '../../assets/images/icon/duplicate-white.svg';
import duplicateBlueIcon from '../../assets/images/icon/duplicate-blue.svg';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';


/** Detect the source to sort */
const cardSource = {
    beginDrag(props) {
        return {
            id: props.id,
            index: props.index
        }
    }
}
const cardTarget = {
    hover(props, monitor, component) {
        const dragIndex = monitor.getItem().index
        const hoverIndex = props.index

        // Don't replace item with themselves
        if (dragIndex === hoverIndex) {
            return
        }

        // Determine rectange on scree
        const hoverBoundingRect = (findDOMNode(
            component,
        )).getBoundingClientRect()

        // Get Vertical Middle
        const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2

        // Determine mouse position
        const clientOffset = monitor.getClientOffset()

        // Get pixels to the top
        const hoverClientY = (clientOffset).y - hoverBoundingRect.top;

        // Only perfomr the move when the mouse has crossed half of the items heights
        // When dragging downwards, only move when the cursor is below 50%
        // When dragging upwards, Only move when the cursor is above 50%
        // Dragging downwards
        if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
            return
        }

        //Dragging upwards
        if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
            return
        }

        // Time to actually perform the action
        props.moveFieldCard(dragIndex, hoverIndex)

        // Note: we're mutating the monitor itme here!
        // Generally it's better to avoid mutations,
        // but it's good here for the sake of performance
        // to avoid expensive index searches.
        monitor.getItem().index = hoverIndex

    }
}



class FormFields extends Component {

    state = {
        cardActive: false,
        actionState: ""
    }

    handleClick = e => {
        this.props.fieldSelected(e);
        const currentState = this.state.cardActive;
        this.setState({ cardActive: !currentState });
        this.setState({ actionState: "" });
    }

    actionEnter(status) {
        // console.log(status);
        this.setState({ actionState: status });
    }
    actionLeave(status) {
        this.setState({ actionState: status });
    }

    render() {
        const {
            connectDragSource,
            connectDropTarget,
            isOverCurrent,
            index,
            isDragging,
            fieldComponent,
            selectedField,
            fieldDelete,
            fieldDuplicate,
            deleteLoader,
            deleteSuccess,
            isDefault
        } = this.props;

        const { actionState } = this.state;

        const LightTooltip = withStyles(theme => ({
            tooltip: {
                backgroundColor: theme.palette.common.white,
                color: 'rgba(0, 0, 0, 0.87)',
                position: 'absolute',
                left: '50%',
                transform: 'translateX(-50%)',
                boxShadow: theme.shadows[1],
                fontSize: 11,
            },
        }))(Tooltip);

        const opacity = isDragging ? 0.5 : 1;
        const background = isDragging ? '#eeeeee' : '#ffffff';
        const border = isOverCurrent ? '1px dotted red' : '1px dotted transparent';

        const actions = (
            <div className={"actions interactive-actions " + actionState}>
                <div className="btn-wrap">
                    <LightTooltip title="Delete">
                        <Fab aria-label="delete" className={"fabs-icons delete-icon " + (actionState === "delete-icon" ? "hover" : "dismiss")} onClick={fieldDelete} onMouseEnter={() => this.actionEnter("delete-icon")} onMouseLeave={() => this.actionLeave(" ")}>
                            <img alt="deleteRedIcon" src={deleteRedIcon} className="delete-red"></img>
                            <img alt="deleteWhiteIcon" src={deleteWhiteIcon} className="delete-white"></img>
                        </Fab>
                    </LightTooltip>
                    <LightTooltip title="Duplicate">
                        <Fab aria-label="file_copy" className={"fabs-icons copy-icon " + (actionState === "copy-icon" ? "hover" : "dismiss")} onClick={fieldDuplicate} onMouseEnter={() => this.actionEnter("copy-icon")} onMouseLeave={() => this.actionLeave(" ")}>
                            <img alt="duplicateBlueIcon" src={duplicateBlueIcon} className="copy-blue"></img>
                            <img alt="duplicateWhiteIcon" src={duplicateWhiteIcon} className="copy-white"></img>
                        </Fab>
                    </LightTooltip>
                </div>
            </div>
        )

        return (
            connectDragSource &&
            connectDropTarget &&
            connectDragSource(
                connectDropTarget(
                    <div className="card-generate">
                        <div style={{ opacity, background, border }} className={"field-generate " + (index === selectedField ? 'active' : '')} onClick={this.handleClick}>
                            {fieldComponent}

                            {index === selectedField ? (deleteLoader ? (deleteSuccess ? (<div className="delete-status">Deleted!!</div>) : (<div className="delete-status"><CircularProgress /></div>)) : "") : ""}
                        </div>
                        {(index === selectedField && !isDefault) ? actions : ''}
                    </div>
                )
            )
        )
    }
}




export default flow(
    DragSource('card', cardSource, (connect, monitor) => ({
        connectDragSource: connect.dragSource(),
        isDragging: monitor.isDragging(),
        connectDragPreview: connect.dragPreview(),
    }),
    ),
    DropTarget('card', cardTarget, (connect => ({
        connectDropTarget: connect.dropTarget()
    }))
    )
)(FormFields);