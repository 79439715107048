import React, { Component } from 'react';
/* import SettingFlowIllust from '../../assets/images/illustration/login-iso.svg'; */
import { Scrollbars } from 'react-custom-scrollbars';
import InputField from '../../components/fields/inputs';
import Fab from '@material-ui/core/Fab';

class SettingPassword extends Component {
    render() {

        return (
            <div className="drawer-tabs workflow-tabs-wrap">
                <div className="tab-container m-0">
                    <Scrollbars style={{ width: '100%', height: '100%' }}>
                        <div className="profile-sec-wrap">
                            <div className="profile-sec-body-x width-100">
                                <div className="drawer-tabs-header border-bottom">
                                    <div className="workflow-title">
                                        <h5>Change your Password</h5>
                                    </div>
                                    <div className="workflow-tabs-search"></div>
                                </div>
                                <section className="add-fields-wrap">
                                    <div className="add-field-row">
                                        <div className="added-field-grp">
                                            <div className="added-field">
                                                <InputField label="Current Password" type="text" name="profile_passwrod" fieldStyle="field-box bg-plane" placeholder="Enter" />                                            </div>
                                        </div>
                                    </div>
                                    <div className="add-field-row">
                                        <div className="added-field-grp">
                                            <div className="added-field">
                                                <InputField label="New Password" type="text" name="profile_newPassword" fieldStyle="field-box bg-plane" placeholder="Enter" />
                                                <InputField label="Confirm New Password" type="text" name="profile_confiPassword" fieldStyle="field-box bg-plane" placeholder="Enter" />
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </Scrollbars>
                </div>
                <div className="card-footer border-top p-l-0 p-r-0">
                    <div className="btn-wrap">
                        <Fab variant="extended" size="large" className="btn btn-large btn-plane primary-color">
                            Save
                        </Fab>
                        <Fab variant="extended" size="large" className="btn btn-large btn-plane grey-color">
                            Cancel
                        </Fab>
                    </div>
                </div>
            </div>
        )
    }
}

export default SettingPassword;