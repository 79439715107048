import React, { Component } from 'react';
import Fab from '@material-ui/core/Fab';
import closeIcon from '../../assets/images/icon/close.svg';
import updateGreyIcon from '../../assets/images/icon/update-grey.svg';
import addGreyIcon from '../../assets/images/icon/add-grey.svg';
import changeGreyIcon from '../../assets/images/icon/change-priority-grey.svg';
// import StarGreyIcon from '../../assets/images/icon/star-grey.svg';
import { Scrollbars } from 'react-custom-scrollbars';
import Avatar from '@material-ui/core/Avatar';
import SearchField from '../../components/fields/search';
// import ArrowForwardGrey from '@material-ui/icons/KeyboardArrowRight';
// import * as NotificationApi from '../../api/notification-api';
import Drawer from '@material-ui/core/Drawer';

//API
import * as ProcessProgress from '../../api/process-api';
import * as SearchApi from '../../api/search-api';

class SearchNotification extends Component {

    state = {
        searchedRecords: [],
        processData: {},
    }

    redirectToDetailsScene = (programKey, process_id) => () => {
        let { clientId } = this.props;
        window.location = "/" + clientId + "/" + programKey + "/details-view/" + process_id;
    }

    handleSearchedRecordsChange(records) {
        this.setState({ searchedRecords: records });
    }

    handleOnChangeEvent(event) {
        let { programKey } = this.props;
        if (event.target.value && event.target.value.length >= 3 && event.target.value.length > 0) {
            SearchApi.getSearchedRecordByUniquekey(event.target.value, programKey).then(res => {
                this.handleSearchedRecordsChange(res);
            })
        }
    }

    render() {
        let { notification, programKey } = this.props;
        let { searchedRecords, processData } = this.state;
        console.log("searchedRecords", searchedRecords);
        return (
            <div>
                <Drawer anchor="right" open={this.props.searchDrawer} onClose={this.props.toggleDrawer('searchDrawer', false)}>

                    <section className="workflow-setup-sec notification-setup-sec">
                        <section className="setup-drawer-sec">
                            <div className="card-block">
                                <div className="card-header">
                                    <div className="card-subheader">
                                        <div className="card-title">
                                        </div>
                                        <div className="card-left-title">
                                            <div className="actions setup-close">
                                                <div className="btn-wrap">
                                                    <Fab variant="extended" size="small" className="btn btn-small btn-plane btn-fab white-color no-shadow" onClick={this.props.toggleDrawer('searchDrawer', false)}>
                                                        Close <img alt="edit" src={closeIcon}></img>
                                                    </Fab>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-header border-bottom" style={{ padding: '8px 0px 25px' }}>
                                    <div className="card-title">
                                        <h2>Search</h2>
                                    </div>
                                    <div></div>
                                </div>
                                <div className="card-body">
                                    <div role="presentation" className="drawer-sec">
                                        <div className="drawer-form-sec">
                                            <div className="workflow-tabs-wrap p-0">
                                                <div className="workflow-tabs-search m-0 p-b-10">
                                                    <SearchField
                                                        placeholder="Search here"
                                                        searchType="normal"
                                                        programKey={programKey}
                                                        searchedRecords={searchedRecords}
                                                        handleOnChangeValue={(event) => this.handleOnChangeEvent(event)}
                                                        customStyle="white-color" />
                                                </div>
                                            </div>
                                            {/* <h6 className="p-b-10">Recent issues</h6> */}
                                            <div className="tab-container tab-container-scroll" style={{ height: 'calc(100vh - 240px)' }}>
                                                <Scrollbars style={{ width: '100%', height: '100%' }}>
                                                    <div className="workflow-program-list notify-program-list">
                                                        {
                                                            searchedRecords &&
                                                            searchedRecords.map((records, index) => {
                                                                return (
                                                                    <div className="notify-program-row" onClick={this.redirectToDetailsScene(programKey, records.record ? records.record.process_id : null).bind(this)}>
                                                                        <Avatar alt="profile" className="avatar-img" src={addGreyIcon} />
                                                                        <ul>
                                                                            <li className="notify-title" >
                                                                                <h6>{records.record[records.id_field]}</h6>
                                                                            </li>
                                                                            {/* <li className="notify-desp">
                                                                    <h6 className="font-med">{records.data_attr2}</h6>
                                                                </li> */}

                                                                        </ul>
                                                                    </div>
                                                                );
                                                            })
                                                        }

                                                    </div>
                                                </Scrollbars>
                                            </div>
                                            {/* <div className="card-footer border-top">
                                            <div className="btn-wrap">
                                                <Fab onClick={this.props.changeNotifyStatusAll} variant="extended" size="large" className="btn btn-large btn-plane primary-color">View All</Fab>
                                                <Fab variant="extended" size="large" className="btn btn-large btn-plane grey-color">Cancel</Fab>
                                            </div>
                                        </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </section>
                </Drawer>
            </div>
        );
    }
}
export default SearchNotification;