import * as AjaxHelper from "../utilities/ajax-helper"
import { ApiConstants } from "./common/api-constants"

let apiBaseUrl = () => (ApiConstants.DEV_MODE === 'true' ? ApiConstants.HOST : window.location.origin) + ApiConstants.API_VERSION;

export function saveFormData(formData) {
    return AjaxHelper.post(apiBaseUrl() + ApiConstants.DATA, formData, { "Content-Type": "application/json" });
}

export function saveBulkFormData(recordData) {
    return AjaxHelper.post(apiBaseUrl() + ApiConstants.DATA + "/bulkUpload", recordData, { "Content-Type": "application/json" });
}

export function bulkUploadStatusCheck() {
    return AjaxHelper.get(apiBaseUrl() + ApiConstants.DATA + "/bulkUpload/status", null, { "Content-Type": "application/json" });
}
// export function saveRecordData(recordData){
//     return AjaxHelper.post(apiBaseUrl()+ApiConstants.DATA+"/recordData", recordData, {"Content-Type":"application/json"});    
// }

export function getFromData(formkey) {
    return AjaxHelper.get(apiBaseUrl() + ApiConstants.DATA + "/" + formkey + "/form", null, { "Content-Type": "application/json" });
}

export function getFromDataById(formId) {
    return AjaxHelper.get(apiBaseUrl() + ApiConstants.DATA + "/" + formId + "/formid", null, { "Content-Type": "application/json" });
}

export function deleteRecordById(common_record_id) {
    return AjaxHelper.del(apiBaseUrl() + ApiConstants.DATA + "/" + common_record_id, null, { "Content-Type": "application/json" })
}