import React, { Component } from 'react';
import { DateTimePicker } from '@progress/kendo-react-dateinputs';
import InputLabel from '@material-ui/core/InputLabel';
import Description from '../validators/descriptions';
import MandatoryValidator from '../validators/mandatory-validator';
import { Popup } from "@progress/kendo-react-popup";

//Validation
import * as ErrorValidation from '../validators/error-validator';
//Custom 
import ErrorMessage from '../common/error-message';

class DateTimePickerKendo extends Component {

    constructor(props) {
        super(props);
        this.state = {
            value: null,
            errorStates: [],
        };
    }
    _el;

    handleChange = (event) => {
        let dateTimeValue = event.target.value;
        dateTimeValue.setSeconds(0, 0);
        let errors = ErrorValidation.updateErrorMessage(dateTimeValue.toISOString(), this.props.field_validations, this.props.condition_script, this.props.error_message, this.props.allowed_hours); //event.target.value.toISOString() //.toUTCString()

        this.setState({ value: dateTimeValue, errorStates: errors });

        this.props.handleDateChange(dateTimeValue, errors);
    }
    render() {
        const { name, label, instruction, is_mandatory, defaultLabel, disabled, fieldStyle, selected_value, customStyle, default_value } = this.props;
        let { value, errorStates } = this.state;
        let defaultFieldStyle = "field-box";
        let isDisabled = this.props.is_disable === 'yes' ? true : false;
        return (
            <div className={"form-row " + customStyle}>
                <div className={fieldStyle ? fieldStyle : defaultFieldStyle} ref={el => (this._el = el)}>
                    <InputLabel
                        htmlFor={name}
                        className="label">
                        {label ? label : defaultLabel}
                        <MandatoryValidator value={is_mandatory} />
                    </InputLabel>
                    <DateTimePicker
                        id={name}
                        margin="normal"
                        onChange={this.handleChange.bind(this)}
                        popup={props => <Popup {...props} appendTo={this._el} />}
                        className={"field"}
                        value={selected_value ? new Date(selected_value) : (default_value && new Date(default_value))}
                        disabled={isDisabled ? isDisabled : disabled}
                    />
                </div>
                <Description value={instruction} />
                <ErrorMessage errorStates={errorStates} name={name} />
            </div>
        )
    }
}

DateTimePickerKendo.defaultProps = {
    default_value: null,
}

export default DateTimePickerKendo