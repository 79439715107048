import * as AjaxHelper from "../utilities/ajax-helper"
import { ApiConstants } from "./common/api-constants"

let apiBaseUrl = () => (ApiConstants.DEV_MODE === 'true' ? ApiConstants.HOST : window.location.origin) + ApiConstants.API_VERSION;

export function getAllStatesBySectionKey(sectionKey) {
    return AjaxHelper.get(apiBaseUrl() + ApiConstants.STATE + "/" + sectionKey, null, { "Content-Type": "application/json" });
}

export function getAllStatesByProgramKey(programKey) {
    return AjaxHelper.get(apiBaseUrl() + ApiConstants.STATE + "/" + programKey + "/program", null, { "Content-Type": "application/json" });
}

export function createState(programStateData) {
    return AjaxHelper.post(apiBaseUrl() + ApiConstants.STATE, programStateData, { "Content-Type": "application/json" });
}

export function createSubState(stateData, stateId) {
    return AjaxHelper.post(apiBaseUrl() + ApiConstants.STATE + "/" + stateId + "/substate", stateData, { "Content-Type": "application/json" });
}

export function deleteState(programStateId) {
    return AjaxHelper.del(apiBaseUrl() + ApiConstants.STATE + "/" + programStateId, null, { "Content-Type": "application/json" });
}

export function deleteSubState(stateId) {
    return AjaxHelper.del(apiBaseUrl() + ApiConstants.STATE + "/" + stateId + "/substate", null, { "Content-Type": "application/json" });
}

export function deleteAllSubStatesById(stateId) {
    return AjaxHelper.del(apiBaseUrl() + ApiConstants.STATE + "/substate", stateId, { "Content-Type": "application/json" });

}



