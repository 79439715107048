import * as Constants from '../utilities/constants';


const initialState = {
    profileImageUser: null,
    profileImageClient: null
}

export default function (state = initialState, action) {
    switch (action.type) {
        case Constants.REDUX_ACTION.FETCH_PROFILE_IMAGE_USER:
            return {
                ...state,
                profileImageUser: action.payload,
            }
        case Constants.REDUX_ACTION.FETCH_PROFILE_IMAGE_CLIENT:
            return {
                ...state,
                profileImageClient: action.payload,
            }
        case Constants.REDUX_ACTION.CHANGE_PROFILE_IMAGE_USER:
            return {
                ...state,
                profileImageUser: action.payload,
            }
        case Constants.REDUX_ACTION.CHANGE_PROFILE_IMAGE_CLIENT:
            return {
                ...state,
                profileImageClient: action.payload,
            }
        case Constants.REDUX_ACTION.DELETE_PROFILE_IMAGE:
            return {
                ...state,
                profileImageUser: action.payload,
            }
        default: return state;
    }
}