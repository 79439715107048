import * as AjaxHelper from "../utilities/ajax-helper"
import { ApiConstants } from "./common/api-constants"

let apiBaseUrl = () => (ApiConstants.DEV_MODE === 'true' ? ApiConstants.HOST : window.location.origin) + ApiConstants.API_VERSION;

/**
 * Checks if the "Download PDF" button can be displayed.
 *
 * @param {number} processId The process ID of the record.
 *
 * @returns Status 200 if the button is to be displayed.
 */
export function checkIfPdfIsDownloadable(processId) {
  return AjaxHelper.get(
    apiBaseUrl() + ApiConstants.INTEGRATIONS + `/download-pdf/check/${processId}`,
    null,
    { "Content-Type": "application/json" },
  )
}

/**
 * Gets the policy PDF from the Motor API.
 *
 * @param {number} processId The process ID of the record.
 *
 * @returns The policy PDF from Motor API or an error response.
 */
export function downloadPolicyPdf(processId) {
  return AjaxHelper.get(
    apiBaseUrl() + ApiConstants.INTEGRATIONS + `/download-pdf/${processId}`,
    null,
    { "Content-Type": "application/json" },
    true,
    "application/json"
  );
}
