import React, { Component } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import Tooltip from '@material-ui/core/Tooltip';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { lighten } from '@material-ui/core/styles/colorManipulator';

import MultipleTabActions from './multiple-tab-actions';

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

function searchData(array, searchQuery) {
  return (searchQuery
    ?
    array.filter(x => {
      var dataArray = Object.keys(x);

      let found = false;
      dataArray.map(dataName => {

        if (x[dataName]) {
          if (x[dataName].toString().toLowerCase().includes(searchQuery.toString().toLowerCase())) {
            found = true;
            return found;
          }
        }
        return "";
      })
      return found;
    })
    :
    array);
}

class TableComponentHead extends Component {
  createSortHandler = property => event => {
    this.props.onRequestSort(event, property);
  };

  render() {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, rows, isCheckBoxEnabled, counterEnabled } = this.props;

    return (
      <TableHead>
        <TableRow>
          {isCheckBoxEnabled ?
            <TableCell padding="checkbox">
              <Checkbox
                indeterminate={numSelected > 0 && numSelected < rowCount}
                checked={numSelected === rowCount}
                onChange={onSelectAllClick}
              />
            </TableCell>
            : ""}
          {counterEnabled ?
            <TableCell className="text-center" numeric>
              No
            </TableCell>
            : ""}
          {rows.map(row => {
            return (
              <TableCell
                key={row.id}
                numeric={row.numeric}
                padding={row.disablePadding ? 'none' : 'default'}
                sortDirection={orderBy === row.id ? order : false}
              >
                <Tooltip
                  title="Sort"
                  placement={row.numeric ? 'bottom-end' : 'bottom-start'}
                  enterDelay={300}
                >
                  <TableSortLabel
                    active={orderBy === row.id}
                    direction={order}
                    onClick={this.createSortHandler(row.id)}
                  >
                    {row.label}
                  </TableSortLabel>
                </Tooltip>
              </TableCell>
            );
          }, this)}
        </TableRow>
      </TableHead>
    );
  }
}

TableComponentHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const toolbarStyles = theme => ({
  root: {
    paddingRight: theme.spacing.unit,
  },
  highlight:
    theme.palette.type === 'light'
      ? {
        color: theme.palette.secondary.main,
        backgroundColor: lighten(theme.palette.secondary.light, 0.85),
      }
      : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.secondary.dark,
      },
  spacer: {
    flex: '1 1 50%',
  },
  actions: {
    color: theme.palette.text.secondary,
  },
  title: {
    flex: '0 0 auto',
  },
});

let TableComponentToolbar = props => {
  const { numSelected, classes, titleContent } = props;

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <div className={classes.title}>
        {titleContent}
      </div>
      <div className={classes.spacer} />
      <div className={classes.actions}>
        {numSelected > 0 ? (
          <MultipleTabActions type="entries" entryCount={numSelected} />
        ) : (
          <MultipleTabActions type="icons" iconList={["download", "delete", "search", "extra"]} />
        )}
      </div>
    </Toolbar>
  );
};

TableComponentToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
};

TableComponentToolbar = withStyles(toolbarStyles)(TableComponentToolbar);

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
  },
  table: {
    minWidth: 1020,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
});

class TableComponent extends Component {
  state = {
    order: 'asc',
    orderBy: 'calories',
    selected: [],
    page: 0,
    rowsPerPage: 10,
    searchQuery: "",
  };

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }

    this.setState({ order, orderBy });
  };

  handleSelectAllClick = event => {
    const { data } = this.props;
    if (event.target.checked) {
      this.setState(state => ({ selected: data.map(n => n.id) }));
      return;
    }
    this.setState({ selected: [] });
  };

  handleClick = (event, id) => {
    if (!this.props.isCheckBoxEnabled)
      return;

    const { selected } = this.state;
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    this.setState({ selected: newSelected });
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };

  isSelected = id => this.state.selected.indexOf(id) !== -1;

  handleDeleteSelected = event => {
    const { data } = this.props;
    const { selected } = this.state;
    selected.map(index => {

      data.map((d, i) => {

        if (d.id === index) {
          data.splice(i, 1);
        }
        return "";
      })
      return data;
    });
    this.setState({ selected: [] });
  }

  handleSearch = event => {
    this.setState({ searchQuery: event.target.value });
  }

  render() {
    const { classes, tableLabel, rows, isPagginated, isSearchable, data, isCheckBoxEnabled, searchLabel, isToolboxPresent, counterEnabled, titleContent } = this.props;
    const { order, orderBy, selected, rowsPerPage, page, searchQuery } = this.state;

    // const emptyRows = rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);

    let searchedData = searchData(data, searchQuery);
    console.log("rowsPerPage", rowsPerPage);

    return (
      <Paper className={classes.root}>
        {isSearchable ?
          (<Grid container spacing={24}>
            <Grid item xs={12} sm={7} />
            <Grid item xs={12} sm={4}>
              <TextField
                id="standard-full-width"
                label={searchLabel}
                style={{ margin: 8 }}
                placeholder={searchLabel}
                fullWidth
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
                value={this.state.searchQuery}
                onChange={this.handleSearch.bind(this)}
              />
              <Grid item xs={12} sm={1} />
            </Grid>
          </Grid>)
          :
          (<div />)
        }
        {isToolboxPresent ? (
          <TableComponentToolbar deleteClick={this.handleDeleteSelected} numSelected={selected.length} tableLabel={tableLabel} titleContent={titleContent} />
        ) : ""}
        <div className={classes.tableWrapper}>
          <Table className={classes.table} aria-labelledby="tableTitle">
            {
              console.log("jav d", rows, selected.length)
            }

            <TableComponentHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={this.handleSelectAllClick}
              onRequestSort={this.handleRequestSort}
              rowCount={searchedData.length}
              rows={rows}
              isCheckBoxEnabled={isCheckBoxEnabled}
              counterEnabled={counterEnabled}
            />

            {
              searchedData.length == 0 ?
                <TableBody>
                  <TableRow>
                    <TableCell colspan={rows.length} className="text-center">
                      <h5 >No Data Available</h5>
                    </TableCell>
                  </TableRow>
                </TableBody>
                :
                <TableBody>

                  {stableSort(searchedData, getSorting(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((n, index) => {
                      const isSelected = this.isSelected(n.id);
                      var dataArray = Object.keys(n);
                      // console.log("jav d",n, index, order, page, rowsPerPage, selected.length, )
                      return (
                        <TableRow
                          hover
                          onClick={event => this.handleClick(event, n.id)}
                          role="checkbox"
                          aria-checked={isSelected}
                          tabIndex={-1}
                          key={n.id}
                          selected={isSelected}
                        >
                          {isCheckBoxEnabled ?
                            <TableCell padding="checkbox">
                              <Checkbox checked={isSelected} />
                            </TableCell>
                            : ""}
                          {counterEnabled ?
                            <TableCell className="text-center bg-head-color" numeric>
                              <h6 className="bold black-color">{(page * rowsPerPage) + index + 1}</h6>
                            </TableCell>
                            : ""}
                          {dataArray.map((data, i) => {
                            if (data === 'id') {
                              return "";
                            }
                            if (i === 1) {
                              return <TableCell component="th" scope="row" ><h6>{n[data]}</h6></TableCell>;
                            }
                            else {
                              return <TableCell numeric><h6>{n[data]}</h6></TableCell>;
                            }
                          })}

                          {/* <TableCell component="th" scope="row" padding="none">
                        {n.name}
                      </TableCell>
                      <TableCell numeric>{n[newArr[2]]}</TableCell>
                      <TableCell numeric>{n.fat}</TableCell>
                      <TableCell numeric>{n.carbs}</TableCell>
                      <TableCell numeric>{n.protein}</TableCell> */}
                        </TableRow>


                      );
                    })}
                  {/* {emptyRows > 0 && (
                <TableRow style={{ height: 49 * emptyRows }}>
                  <TableCell colSpan={rows.length+5} />
                </TableRow>
              )} */}
                  {/* <TableRow>
                  <TableCell numeric>
                    <h5 className="weight-500 text-primary-color">ABC1235</h5>
                  </TableCell>
                  <TableCell numeric>
                    <h6>Policy Form</h6>
                  </TableCell>
                  <TableCell numeric>
                    <h6>State Name </h6>
                    <h6>Substate Name</h6>
                  </TableCell>
                  <TableCell numeric>
                    <h6>20</h6>
                  </TableCell>
                  <TableCell numeric>
                    <h6>15-Jun-2019</h6>
                  </TableCell>
                  <TableCell numeric>
                    <span className="state-tag musturd-color">Lowest</span> &nbsp;
                    <span className="state-tag pink-color">High</span> &nbsp;
                    <span className="state-tag cyan-color">Normal</span> &nbsp;
                    <span className="state-tag dark-maroon-color">Hightest</span> &nbsp;
                    <span className="state-tag orange-color">Low</span> &nbsp;
                  </TableCell>
                  <TableCell>
                    <ActionsViewSetup/>
                  </TableCell>
                </TableRow> */}
                  {/* <TableRow>
                  <TableCell numeric>
                    <UrlTabActions/>
                  </TableCell>
                  <TableCell numeric>
                    <div className="auto-select">
                      <SelectField
                        optionsData={defalutConditions}
                        is_search={true}
                      />
                    </div>
                  </TableCell>
                  <TableCell numeric>
                    <h6>20</h6>
                  </TableCell>
                  <TableCell numeric>
                    <SwitchTabActions/>
                  </TableCell>
                  <TableCell numeric>
                    <h6>15-Jun-2019</h6>
                  </TableCell>
                  <TableCell numeric>
                    <MultipleTabActions/>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell numeric>
                    <UrlTabActions/>
                  </TableCell>
                  <TableCell numeric>
                    <div className="auto-select">
                      <SelectField
                        optionsData={defalutConditions}
                      />
                    </div>
                  </TableCell>
                  <TableCell numeric>
                    <h6>20</h6>
                  </TableCell>
                  <TableCell numeric>
                    <SwitchTabActions/>
                  </TableCell>
                  <TableCell numeric>
                    <h6>15-Jun-2019</h6>
                  </TableCell>
                  <TableCell numeric>
                    <MultipleTabActions/>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell numeric>
                    <UrlTabActions/>
                  </TableCell>
                  <TableCell numeric>
                    <div className="auto-select">
                      <SelectField
                        optionsData={defalutConditions}
                      />
                    </div>
                  </TableCell>
                  <TableCell numeric>
                    <h6>20</h6>
                  </TableCell>
                  <TableCell numeric>
                    <SwitchTabActions/>
                  </TableCell>
                  <TableCell numeric>
                    <h6>15-Jun-2019</h6>
                  </TableCell>
                  <TableCell numeric>
                    <MultipleTabActions/>
                  </TableCell>
                </TableRow> */}
                </TableBody>
            }
          </Table>
        </div>
        {isPagginated ? (
          <TablePagination
            component="div"
            count={searchedData.length}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[5, 10, 25, 50, 100]}
            page={page}
            backIconButtonProps={{
              'aria-label': 'Previous Page',
            }}
            nextIconButtonProps={{
              'aria-label': 'Next Page',
            }}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        ) : (<div />)}
      </Paper>
    );
  }
}

TableComponent.propTypes = {
  classes: PropTypes.object.isRequired,
};

TableComponent.defaultProps = {
  isCheckBoxEnabled: true,
};

export default withStyles(styles)(TableComponent);
