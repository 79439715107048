import React, { Component } from 'react';
import Header from '../../components/header';
import WorkflowHeader from '../../components/workflow/workflow-header';

//API
import * as FormApi from '../../api/form-api';
import * as FormDataApi from '../../api/form-data-api.jsx';
import * as StorageFileApi from '../../api/file-storage-api';

//Custom
import LoadingComponent from '../../components/common/loading-component';
import LinearProgress from '@material-ui/core/LinearProgress';
import CircularProgress from '@material-ui/core/CircularProgress';

//dialog
import Dialog from '@material-ui/core/Dialog';
import CloseIcon from '../../assets/images/icon/close.svg';

//Constants
import * as FormCommon from '../../utilities/form-common'
import * as FormConstant from '../../utilities/constants'

//Utils
import * as ScriptEvaluator from '../../utilities/script-evaluator'

//Constants
import * as Constants from '../../utilities/constants';

//Lodash
import _ from 'lodash';

//Redux Action
import { setFormData, setUserData } from '../../actions/formDataAction';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

class FormBuilderView extends Component {

    state = {
        formData: { form_field_list: [] },
        loading: true,
        formUserDatas: [],
        validationStatus: {},
        isFormValid: false,
        workflowAction: [],
        rulesScript: {},
        count: 0,
        redirect: {},
        processData: {},
        fileUploadDialog: false,
        fileCount: 0,
        uploadedFileCount: 0,
        redirectKey: null,
        redirectBackToDashboard: false,
        workflowRules: [],
    }

    componentDidMount() {
        const match = this.props.match;
        // let programkey = match.params.programkey;
        let formkey = match.params.formkey;
        if (this.props.location && this.props.location.state) {
            console.log("this.props.location", this.props.location);
            if (this.props.location.state.processData) {
                this.setState({ processData: this.props.location.state.processData });
            }
            if (this.props.location.state.addNewForm) {
                this.setState({ redirectBackToDashboard: this.props.location.state.addNewForm });
            }
        }
        this.getFormData(formkey);
    }

    getFormData(formkey) {
        this.setState({ loading: true });
        let formPromise = FormApi.getFormDataDetails(formkey, null);
        formPromise.then(formData => {
            formData.form_field_list.sort(function (a, b) {
                return a.position - b.position
            })
            this.sortOptionsAlpha(formData);
            let validationStatus = this.setValidationStatusArray(formData);
            let updatedValidationStatus = this.setFormPreloadDataCheck(formData, validationStatus);
            this.generateFormValidationStatus(updatedValidationStatus);
            this.initialFormData(formData);

            //Redux Action
            this.props.setFormData(formData);

            this.setState({ formData: formData, loading: false }, () => { this.insertRecordDataToFields(); this.processRules(); this.updateChildConditionScript(formData) });
        });
    }

    updateChildConditionScript(formData) {
        console.log("formData7778", formData);
        formData.form_field_list.forEach(field => {
            if (field.group_field && field.condition_script && field.children && field.children.length > 0) {
                field.children.forEach(child => {
                    child.condition_script = field.condition_script;
                    child.error_message = field.error_message;
                })
            }
        })

    }

    initialFormData(formData) {
        let { formUserDatas } = this.state;
        formData.form_field_list && formData.form_field_list.map(fieldDetails => {
            let formUserData = formUserDatas[fieldDetails.data_pagination_id - 1];
            if (!formUserData) {
                formUserData = {};
                formUserData["form_pagination_id"] = fieldDetails.data_pagination_id;
                formUserData["object_id"] = formData.id;
                formUserData["process_id"] = this.props.process_id;
                switch (formData.form_type) {
                    case Constants.FORM_TYPE.PARAMETER.code: {
                        formUserData["object_type"] = Constants.RECORD_TYPE.PROGRAM.code;
                        break;
                    }
                    default: {
                        formUserData["object_type"] = Constants.RECORD_TYPE.FORM.code;
                    }
                }
                formUserDatas[fieldDetails.data_pagination_id - 1] = formUserData;
            }
        })
        for (var i = 0; i < formUserDatas.length; i++) {
            let formUserData = formUserDatas[i];
            if (!formUserData) {
                formUserData = {};
                formUserData["form_pagination_id"] = i + 1;
                formUserData["object_id"] = formData.id;
                formUserData["process_id"] = this.props.process_id;
                switch (formData.form_type) {
                    case Constants.FORM_TYPE.PARAMETER.code: {
                        formUserData["object_type"] = Constants.RECORD_TYPE.PROGRAM.code;
                        break;
                    }
                    default: {
                        formUserData["object_type"] = Constants.RECORD_TYPE.FORM.code;
                    }
                }

                formUserDatas[i] = formUserData;
            }
        }
        this.setState({ formUserDatas: formUserDatas });
    }

    insertRecordDataToFields() {
        let { formData, processData, formUserDatas } = this.state;
        if (processData.hasOwnProperty("sectional_form_record")) {
            formData.form_field_list.forEach((field, index) => {
                if (field.field_type !== Constants.FieldIds.F_UPLOAD.code) {
                    if (field.group_field) {
                        field.children.forEach(child => {
                            let dataRefId = "data_attr" + child.data_ref_id;
                            let paginationId = field.data_pagination_id;
                            let recordValue = this.searchReferenceIdRecord(dataRefId, paginationId);

                            if (child.field_option_list) {
                                recordValue = this.mapRecordLabelToValue(child.field_option_list, recordValue, child.field_type);
                                child.selected_value = recordValue;
                            }
                            else {
                                child.selected_value = recordValue;
                            }
                            formUserDatas[paginationId - 1][dataRefId] = recordValue
                        })
                    }
                    else {
                        let dataRefId = "data_attr" + field.data_ref_id;
                        let paginationId = field.data_pagination_id;
                        let recordValue = this.searchReferenceIdRecord(dataRefId, paginationId);
                        if (field.field_option_list && field.field_option_list.length > 0) {
                            recordValue = this.mapRecordLabelToValue(field.field_option_list, recordValue, field.field_type);
                            field.selected_value = recordValue;
                        }
                        else {
                            if (field.field_type === "f-date" || field.field_type === "f-date-time") {
                                if (recordValue) {
                                    field.selected_value = new Date(recordValue);
                                }
                            }
                            else {
                                field.selected_value = recordValue;
                            }
                        }
                        formUserDatas[paginationId - 1][dataRefId] = recordValue
                    }
                }
            })
            // formUserDatas[0]["common_record_id"] = processData.sectional_form_record.records[0].common_record_id;
            // formUserDatas[0]["process_id"] = processData.sectional_form_record.records[0].process_id;
            // formUserDatas[0]["record_id"] = processData.sectional_form_record.records[0].record_id;

            formUserDatas.forEach((data, index) => {
                if (processData.sectional_form_record.records[index]) {
                    data["common_record_id"] = processData.sectional_form_record.records[index].common_record_id;
                    data["process_id"] = processData.sectional_form_record.records[index].process_id;
                    data["record_id"] = processData.sectional_form_record.records[index].record_id;
                }
            })

            let updatedValidationStatus = this.setFormPreloadDataCheck(formData, this.state.validationStatus);
            this.generateFormValidationStatus(updatedValidationStatus);
            this.setState({ formData: formData, formUserDatas: formUserDatas });
        }
    }

    searchReferenceIdRecord(dataRefId, paginationId) {
        let { processData } = this.state;
        let recordData = processData.sectional_form_record.records[paginationId - 1];
        for (let key in recordData) {
            if (key === dataRefId) {
                return recordData[key];
            }
        }
        return "";
    }

    mapRecordLabelToValue(optionList, recordValue, fieldType) {
        switch (fieldType) {
            case "f-radio-grp":
            case "f-sin-dropdown-grp":
                optionList.forEach(list => {
                    if (list.field_option_label === recordValue) {
                        recordValue = list.field_option_value;
                        list.checked = true;
                    }
                })
                break;

            case "f-multi-dropdown-grp":
            case "f-checkbox-grp":
                let recordValueArray = recordValue.split(',');
                optionList.forEach(list => {
                    list.checked = (recordValueArray.includes(list.field_option_label));
                })

                let newRecordValue = [];
                recordValueArray.forEach(record => {
                    optionList.forEach(list => {
                        if (list.field_option_label === record) {
                            newRecordValue.push(list.field_option_value);
                        }
                    })
                })
                recordValue = newRecordValue.join(',');
                break;

            default:
                break;
        }
        return recordValue;
    }

    processRules() {
        let { formData } = this.state;
        console.log("formData333: ", formData);
        let rulesScrits = JSON.parse(formData.rules_script);
        let workflowRules = JSON.parse(JSON.stringify(rulesScrits.rules));
        console.log("workflowRules555", workflowRules);
        workflowRules.forEach(workflowRule => {
            workflowRule.forEach(rule => {
                if (rule.condition) {
                    rule.org_condition = rule.condition;
                    rule.condition = ScriptEvaluator.evaluatecondition(rule.condition, formData.form_field_list, this.state.formUserDatas);
                } else {
                    rule.condition = true;
                }
            })
            //Only execute on change
            let executableWorkflowRule = workflowRule.filter(rule => rule.executeOn === 1);
            this.executeScript(executableWorkflowRule);
        })

    }

    formFieldUsedReset(formData) {
        formData.form_field_list.forEach(field => {
            field.used = false;
        })
    }

    performActionConfig(task, formData, executableWorkflowRule) {
        if (task.actionConfig) {
            let fieldIds = task.actionConfig.fields;
            fieldIds && fieldIds.forEach(fieldId => {
                let fieldList = formData.form_field_list;
                console.log("fieldList444", fieldList, fieldId);
                //let selectedField = fieldList.filter(field => (field.id === fieldId))[0];
                let selectedField = null;
                fieldList.forEach(field => {
                    if (!selectedField) {
                        if (field.group_field) {
                            if (field.id === fieldId) {
                                selectedField = field;
                            }
                            else {
                                field.children.forEach(child => {
                                    if (child.id === fieldId) {
                                        selectedField = child;
                                    }
                                })
                            }


                            if (field.field_type === "f-action-but-group") {
                                let splitIdValue = fieldId.split("#");
                                let parentField = fieldList.filter(field => field.id === splitIdValue[0])[0];
                                let childField = parentField.field_option_list.filter(child => child.field_option_value === splitIdValue[1])[0]
                                selectedField = childField;

                            }
                        }
                        else if (field.id === fieldId) {
                            selectedField = field;
                        }
                    }
                })

                if (selectedField) {
                    ScriptEvaluator.actionPerformer(task.actionSpec, selectedField, task.actionConfig.values[fieldId]);
                }


                //if we don't do the null check, record attribute fields will get undefined values.
                if (selectedField && selectedField.selected_value) {
                    this.saveFormElementData(selectedField, selectedField.selected_value, formData);
                    this.updateConditionsOfRules(executableWorkflowRule, formData, selectedField);
                }

            })
        }
    }

    updateConditionsOfRules(executableWorkflowRule, formData, selectedField) {
        executableWorkflowRule.forEach(workRule => {
            if (workRule.org_condition && workRule.org_condition !== true) {
                console.log("workRule666", workRule, "formData", formData, "this.state.formUserDatas", this.state.formUserDatas);
                workRule.condition = ScriptEvaluator.evaluatecondition(workRule.org_condition, formData.form_field_list, this.state.formUserDatas, selectedField.selected_value);
            }
        })
    }

    executeScript(executableWorkflowRule, executeRedirect) {
        console.log("executableWorkflowRule", executableWorkflowRule, "executeRedirect", executeRedirect);
        let { formData, workflowRules } = this.state;
        console.log("formData555", formData);
        //Adding reset to handle field level workflow execution and give preference to action performer over resolver
        this.formFieldUsedReset(formData);

        executableWorkflowRule.forEach((rule, index) => {
            if (eval(rule.condition)) {
                console.log("HHH: ", rule.condition, "weq", rule.condition === true);
                rule.tasks.forEach(task => {

                    switch (task.actionSpec) {
                        case Constants.FIELD_ACTIONS.HIDE:
                        case Constants.FIELD_ACTIONS.SHOW:
                        case Constants.FIELD_ACTIONS.ENABLE:
                        case Constants.FIELD_ACTIONS.DISABLE:
                            this.performActionConfig(task, formData, executableWorkflowRule);
                            break;
                        case Constants.FIELD_ACTIONS.SET: {
                            //SET-FIELD-VALUE Should only execute once  
                            if (!workflowRules[index]) {
                                this.performActionConfig(task, formData, executableWorkflowRule);
                            }
                            break;
                        }

                        case Constants.FIELD_ACTIONS.REDIRECT:
                            this.setState({ redirect: task.actionConfig }, () => { (executeRedirect) && this.redirectBuilder() });
                            break;
                        default: break;
                    }

                })
                workflowRules[index] = true;

            }
            else {
                if (workflowRules[index]) {
                    let tasks = JSON.parse(JSON.stringify(rule.tasks));
                    tasks.reverse();
                    tasks.forEach(task => {
                        console.log(task);
                        if (task.actionConfig) {
                            let fieldIds = task.actionConfig.fields;
                            fieldIds && fieldIds.forEach(fieldId => {
                                let fieldList = formData.form_field_list;
                                let selectedField = fieldList.filter(field => (field.id === fieldId))[0];
                                if (selectedField) {
                                    ScriptEvaluator.actionResolver(task.actionSpec, selectedField);
                                }
                            })
                        }
                    })
                }
                workflowRules[index] = false;
            }

        })
        this.setState({ formData: formData, workflowRules: workflowRules }, () => this.processSpecificFields);
    }

    sortOptionsAlpha(formData) {
        formData.form_field_list.forEach(field => {
            if (field.is_alphabetical_order === "yes") {
                field.field_option_list.sort(function (a, b) {
                    if (a.field_option_label < b.field_option_label) { return -1; }
                    if (a.field_option_label > b.field_option_label) { return 1; }
                    return 0;
                })
            }
        })
    }

    generateFormValidationStatus(validationStatus) {
        //If user keep all the fields non-mandatory, 
        //then the form is be elgible to Save after the page is just loaded.
        let isVal = true;
        for (var key in validationStatus) {
            if (validationStatus[key] === false) {
                isVal = false
            }
        }

        if (isVal) {
            this.setState({ isFormValid: true });
        }
    }

    setFormPreloadDataCheck(formData, validationStatus) {
        //preselect data setting
        formData.form_field_list.forEach(field => {

            switch (field.field_type) {
                case FormConstant.FieldIds.F_SINGLE_DROPDOWN_GROUP.code:
                case FormConstant.FieldIds.F_MULTIPLE_DROPDOWN_GROUP.code:
                case FormConstant.FieldIds.F_CHECKBOX_GROUP.code:
                    let selectedOptArr = [];
                    let selectedOptStr = "";
                    let checkValid = false;
                    field.field_option_list &&
                        field.field_option_list.forEach(option => {
                            if (option.checked) {
                                selectedOptArr.push(option.field_option_value);
                                checkValid = true;
                            }
                        });

                    //Set the field validation status with current vallid status
                    //validationStatus[field.id] = checkValid;
                    if (checkValid) {
                        validationStatus[field.id] = checkValid;
                    }

                    if (selectedOptArr.length > 0) {
                        selectedOptStr = selectedOptArr.join();
                    }
                    this.setState({ validationStatus: validationStatus });
                    this.saveFormElementData(field, selectedOptStr, formData);
                    break;

                default:

                    let selectedOptStrValue = "";
                    let checkValidFlag = false;
                    if (field.default_value || field.selected_value) {
                        selectedOptStrValue = field.default_value ? field.default_value : (field.selected_value ? field.selected_value : "");
                        checkValidFlag = true
                    }
                    if (checkValidFlag) {
                        validationStatus[field.id] = checkValidFlag;
                    }

                    if (field.group_field) {
                        field.children && field.children.forEach(child => {
                            if ((child.default_value && child.default_value !== "") || (child.selected_value && child.selected_value !== "")) {
                                validationStatus[child.id] = true;
                            }
                        })
                    }

                    this.setState({ validationStatus: validationStatus });
                    this.saveFormElementData(field, selectedOptStrValue, formData);
                    break;

            }
        })
        return validationStatus;
    }

    setValidationStatusArray(formData) {
        let validStatus = this.state.validationStatus;

        formData.form_field_list.forEach(field => {
            if (field.field_validations.length > 0 && field.field_validations.includes("v_required")) {
                if (field.group_field) {
                    validStatus[field.id] = true;
                }
                else {
                    if (field.parent_id != null) {
                        let parentfield = formData.form_field_list.filter(parentField => { return (parentField.id === field.parent_id) })[0];
                        let displayfield = null;
                        displayfield = parentfield.display_name.filter(displayfield => { return (displayfield.field_option_label === field.label) })[0];
                        validStatus[field.id] = !displayfield.checked;
                    } else {
                        validStatus[field.id] = false;
                    }
                }
            }
            else {
                validStatus[field.id] = true;
            }
        })
        this.setState({ validationStatus: validStatus });
        return validStatus;
    }

    handleGenOnChangeValue = (fieldDetails) => (value, errors, childField) => {
        let { formData } = this.state;
        console.log("handleGen_value ", value, "fieldDetails", fieldDetails, "childField", childField, "formData", formData);
        if (fieldDetails.group_field) {
            //This funtion is used to update the fieldOptionList of all the Child Dropdowns.
            this.checkChildDropdown(childField, value);
            this.updateChildFieldsInFieldDetails(fieldDetails, childField, value);
            let childProperties = JSON.parse(JSON.stringify(childField));
            this.checkValidation(childProperties, errors, value);
            this.saveFormElementData(childProperties, value, formData, this.dataSaveCallback);
            childProperties.selected_value = value;
            //childField.selected_value = value;
            //this.saveFormElementData(childProperties, value, formData);
        }
        else if (fieldDetails.field_type === Constants.FieldIds.F_UPLOAD.code) {
            if (value) {
                formData.form_field_list.forEach(field => {
                    if (field.id === fieldDetails.id) {
                        field["uploadFile"] = value;
                    }
                })
                this.setState({ formData: formData });
            }
            this.checkValidation(fieldDetails, errors, value);
            this.saveFormElementData(fieldDetails, value, formData, this.dataSaveCallback);

        }
        else if (fieldDetails.field_type === "f-radio-grp" && fieldDetails.selected_value === "other") {
            console.log("Hit em up");
            let radioCheck = false;
            fieldDetails.field_option_list.forEach(data => {
                if (value === data.field_option_value) {
                    radioCheck = true;
                }
            })

            if (radioCheck) {
                this.checkValidation(fieldDetails, errors, value);
                this.saveFormElementData(fieldDetails, value, formData, this.dataSaveCallback);
                fieldDetails.selected_value = value;
            }
            else {
                fieldDetails.field_option_list.forEach(list => {
                    if (list.option_type === 1) {
                        list.field_option_label = value;
                    }
                })
                console.log("value222", value, "errors", errors);

                this.checkValidation(fieldDetails, errors, value);
                this.saveFormElementData(fieldDetails, value, formData, this.dataSaveCallback);

            }

        }
        else {
            //Validating field details
            this.checkValidation(fieldDetails, errors, value);
            this.saveFormElementData(fieldDetails, value, formData, this.dataSaveCallback);
            fieldDetails.selected_value = value;
        }
    }

    updateChildFieldsInFieldDetails(fieldDetails, childField, value) {
        fieldDetails.children &&
            fieldDetails.children.forEach(child => {
                if (child.id === childField.id) {
                    child.selected_value = value;
                }
            })
    }

    checkChildDropdown(childField, value) {
        if (childField.field_type === FormConstant.FieldIds.F_SINGLE_DROPDOWN_GROUP.code) {
            childField.field_option_list.forEach(field => {
                if (field.field_option_value === value) {
                    field.checked = true;
                }
                else {
                    field.checked = false;
                }
            })
        }
    }

    dataSaveCallback() {
        this.processRules();
        this.processSpecificFields();
    }

    processSpecificFields() {
        let { formData } = this.state;
        let fieldList = formData.form_field_list;
        fieldList.forEach(field => {
            switch (field.field_type) {
                case Constants.FieldIds.F_CALCULATION.code: {
                    let script = ScriptEvaluator.evaluateFieldCondition(field.formula_text, fieldList, this.state.formUserDatas);
                    field.selected_value = eval(script);
                    this.saveFormElementData(field, eval(script), formData, this.processRules);
                    break;
                }
                default: break;
            }
        })
        this.setState({ formData: formData });
    }

    handleButtonEvents = (fieldId, buttonId) => {
        let { formData } = this.state;
        let rulesScrits = JSON.parse(formData.rules_script);
        let workflowRules = JSON.parse(JSON.stringify(rulesScrits.rules));
        workflowRules.forEach(workflowRule => {
            let buttonWorkflowRule = workflowRule.filter(workflow => (workflow.executeOn === 2 && workflow.conditionFields.join().includes(fieldId + "#" + buttonId)));
            buttonWorkflowRule.forEach(rule => {
                if (rule.condition) {
                    rule.condition = ScriptEvaluator.evaluatecondition(rule.condition, formData.form_field_list, this.state.formUserDatas);
                } else {
                    rule.condition = true;
                }
            })
            this.executeScript(buttonWorkflowRule, true);
        })
    }

    handleDeleteFile(fieldDetails, errors, value) {
        let { formData } = this.state;
        formData.form_field_list.forEach(field => {
            if (field.id === fieldDetails.id) {
                field["uploadFile"] = null;
            }
        })
        this.setState({ formData: formData });
        this.checkValidation(fieldDetails, errors, null);
        this.saveFormElementData(fieldDetails, "", formData, this.dataSaveCallback);

    }

    saveFormElementData(fieldDetails, value, formData, callback) {
        let { formUserDatas } = this.state;
        let formUserData = formUserDatas[fieldDetails.data_pagination_id - 1];
        if (!formUserData) {
            formUserData = {};
            formUserData["form_pagination_id"] = fieldDetails.data_pagination_id;
            formUserData["object_id"] = formData.id;
            switch (formData.form_type) {
                case Constants.FORM_TYPE.PARAMETER.code: {
                    formUserData["object_type"] = Constants.RECORD_TYPE.PROGRAM.code;
                    break;
                }
                default: {
                    formUserData["object_type"] = Constants.RECORD_TYPE.FORM.code;
                }
            }
            formUserDatas[fieldDetails.data_pagination_id - 1] = formUserData;
        }

        formUserData["data_attr" + fieldDetails.data_ref_id] = value;

        //Redux Action
        this.props.setUserData(formUserDatas);

        this.setState({ formUserDatas: formUserDatas }, callback);
    }

    checkValidation(fieldDetails, errors, value) {
        console.log("fieldDetails222", fieldDetails, "errors", errors, "value", value);
        let validStatus = this.state.validationStatus;
        var valid = true;
        if (fieldDetails.field_validations.length > 0 || errors.length > 0) {
            //Field which are not mandatory and value is empty
            if (!fieldDetails.field_validations.includes("v_required") && !value && fieldDetails.field_type !== Constants.FieldIds.F_RADIO_GROUP.code) {
                if (!(fieldDetails.condition_script && fieldDetails.condition_script.includes("isNull"))) {
                    errors.forEach(err => {
                        err.isValid = true
                    })
                }
            }

            if (fieldDetails.field_type === Constants.FieldIds.F_UPLOAD.code && fieldDetails.field_validations.includes("v_required") && !value) {
                errors.forEach(err => {
                    err.isValid = false
                })
            }

            //Check for all validation status
            errors.forEach(err => {
                if (!(err.isValid)) {
                    valid = false
                }
            })

            validStatus[fieldDetails.id] = valid;
        } else {
            for (var key in validStatus) {
                if (key === fieldDetails.id) {
                    validStatus[key] = true;
                }

            }
        }

        var isVal = true;
        for (var key in validStatus) {
            if (validStatus[key] === false) {
                isVal = false;
            }
        }

        this.setState({ isFormValid: isVal, validationStatus: validStatus });
    }

    saveFormData = (key) => () => {
        if (this.state.isFormValid) {
            this.setState({ loading: true });

            let uploadCheck = this.checkForFileUploadFields();

            if (!uploadCheck) {
                FormDataApi.saveFormData(this.state.formUserDatas).then(res => {
                    this.setState({ formUserDatas: res });
                    this.redirectBuilder(key, res[0].process_id);
                    this.setState({ loading: false });
                });
            }
            else {

                this.state.formData.form_field_list.forEach(field => {
                    (field.field_type === Constants.FieldIds.F_UPLOAD.code && field.uploadFile) &&
                        this.storageFunctionApiCall(field.uploadFile, field)
                })
            }
            this.setState({ redirectKey: key });

        } else {
            this.globalValidationCheck();
        }
    }

    saveAfterFileUploaded() {
        FormDataApi.saveFormData(this.state.formUserDatas).then(res => {
            this.setState({ formUserDatas: res });
            this.setState({ loading: false });
            this.redirectBuilder(this.state.redirectKey, res[0].process_id);
        });
    }

    checkForFileUploadFields() {
        let { formData } = this.state;
        let check = false;
        let count = 0;
        formData.form_field_list.forEach(field => {
            if (field.field_type === Constants.FieldIds.F_UPLOAD.code && field.uploadFile) {
                field["fileProgressCount"] = 0;
                count += 1;
                check = true;
            }
        })
        this.setState({ formData: formData, fileCount: count }, () => {
            count > 0 && this.setState({ fileUploadDialog: true }) //, loading: false
        });
        return check;
    }

    dialogFileUploadClose() {
        this.setState({ fileUploadDialog: false });
    }

    renderFileUploadTrigger() {
        let { formData, fileUploadDialog, fileProgressCount } = this.state;

        return (
            <Dialog open={this.state.fileUploadDialog} onClose={this.dialogFileUploadClose.bind(this)} aria-labelledby="simple-dialog-title">
                <section className="workflow-dialog workflow-dialog-small workflow-dialog-pri">
                    <div className="card-block">
                        <div className="card-header border-bottom p-b-25">
                            <div className="sub-title">
                                <h2 className="bold">File Upload</h2>
                            </div>
                            <div className="close-dialog">
                                <img src={CloseIcon} alt="CloseIcon" onClick={this.dialogFileUploadClose.bind(this)} ></img>
                            </div>
                        </div>
                    </div>
                    <div>
                        {
                            fileUploadDialog &&
                            formData.form_field_list.map(field => {
                                return (
                                    <div>
                                        {
                                            (field.field_type === Constants.FieldIds.F_UPLOAD.code && field.uploadFile) ?
                                                <div>
                                                    <h6 >{field.uploadFile.name}</h6>
                                                    <div>
                                                        <LinearProgress variant="determinate" value={field.fileProgressCount ? field.fileProgressCount : 0} />
                                                    </div>
                                                </div>
                                                : ""
                                        }
                                    </div>
                                );
                            })
                        }
                    </div>
                </section>
            </Dialog>
        );
    }

    callbackUploadProgress(progressValue, field) {
        let { formData } = this.state;

        formData.form_field_list.forEach(list => {
            if (list.id === field.id) {
                list["fileProgressCount"] = progressValue;
            }
        })

        this.setState({ formData: formData })
    }

    storageFunctionApiCall(file, field) {
        let { formData } = this.state;
        const formDataValue = new FormData();
        formDataValue.append("file", file, file.name);

        StorageFileApi.postFileUpload(formDataValue, (fileProgressCount) => { this.callbackUploadProgress(fileProgressCount, field) })
            .then(response => {
                this.saveFormElementData(field, response.file_id, formData, this.dataSaveCallback);


                this.setState({ uploadedFileCount: this.state.uploadedFileCount + 1 }, () => {
                    (this.state.uploadedFileCount === this.state.fileCount) &&
                        this.setState({ fileUploadDialog: false }, () => { this.saveAfterFileUploaded() });
                })
                // this.setState({fileUploadDialog: false }, () => { this.saveAfterFileUploaded() });


            })
    }

    cancelEvent = (event) => {
        this.redirectToEditor();
    }

    cancelEventAfterEditing = (event) => {
        this.redirectToDetailsViewDashboard();
    }

    redirectToDetailsViewDashboard() {
        let { processData } = this.state;
        const match = this.props.match;
        let programkey = match.params.programkey;
        let clientId = match.params.clientId;
        // let formkey = match.params.formkey;
        let processId = null;
        if (processData.sectional_form_record) {
            processId = processData.sectional_form_record.records[0].process_id;
        }
        window.location = "/" + clientId + "/" + programkey + "/details-view/" + processId;
    }

    handleRedirectBackToDashboard() {
        const match = this.props.match;
        let programkey = match.params.programkey;
        let clientId = match.params.clientId;
        window.location = "/" + clientId + "/" + programkey + "/dashboard";
    }

    redirectBuilder(key, processId) {
        // if(Function.isEmpty(redirect)){
        if (key === "save") {
            this.redirectToDetails(processId);
        }
        else if (key === "save-new") {
            this.redirectToPublish();
        }

        // }else{
        //     if(redirect.type === "form"){
        //         const match = this.props.match; 
        //         let programkey = match.params.programkey;
        //         // let formkey = match.params.formkey;
        //         window.location = "/"+programkey+ redirect.redirectUrl; //Used to redirect to page
        //     }else{
        //         window.location =  redirect.redirectUrl ;
        //     }

        // }
    }

    redirectToEditor() {
        const match = this.props.match;
        let programkey = match.params.programkey;
        let formkey = match.params.formkey;
        let clientId = match.params.clientId;
        window.location = "/" + clientId + "/" + programkey + "/form/" + formkey + "/builder";
    }

    redirectToDetails(processId) {
        const match = this.props.match;
        let programkey = match.params.programkey;
        let clientId = match.params.clientId;
        window.location = "/" + clientId + "/" + programkey + "/details-view/" + processId;
    }

    redirectToPublish() {
        const match = this.props.match;
        let programkey = match.params.programkey;
        let formkey = match.params.formkey;
        let clientId = match.params.clientId;
        window.location = '/' + clientId + "/" + programkey + "/form/" + formkey + "/publish";
    }

    globalValidationCheck() {
        this.state.formData.form_field_list.forEach(field => {
            field.triggerValidCheck = !field.triggerValidCheck;
        });
        this.setState({ formData: this.state.formData });
    }

    createChildFields(formData) {
        if (formData.form_field_list.length > 0) {
            formData.form_field_list.forEach(field => {
                if (field.group_field) {
                    if (!field.hasOwnProperty('children')) {
                        field["children"] = formData.form_field_list.filter(child => child.parent_id === field.id)
                    }
                }
            })

            //Deleting the child fields using Lodash.
            _.remove(formData.form_field_list, function (field) {
                return field.parent_id !== null;
            });
        }
    }

    render() {
        let { loading, formData, processData, redirectBackToDashboard } = this.state;
        console.log("processData", processData);
        this.createChildFields(formData);
        const match = this.props.match;
        let programkey = match.params.programkey;
        console.log("validationStatus", this.state.validationStatus, "isFormValid", this.state.isFormValid);

        console.log("formUserDatas123", this.state.formUserDatas);
        let isFormEdit = processData.hasOwnProperty("sectional_form_record") ? true : false;
        console.log("workflowRules333", this.state.workflowRules);
        return (
            <div className="FormBuilderView">
                <Header headerType="dashboard" programKey={programkey} />
                <WorkflowHeader
                    workflowType="form-build-up"
                    customStyle="workflow-header-sticky"
                    tab="form"
                    isFormEdit={isFormEdit}
                    redirectBackToDashboard={redirectBackToDashboard}
                    formName={formData.form_name}
                    onClickChange={this.cancelEvent.bind(this)}
                    onCancelAfterEdit={this.cancelEventAfterEditing.bind(this)}
                    handleRedirectBackToDashboard={() => { this.handleRedirectBackToDashboard() }}
                    disabled={!this.state.isFormValid}
                    onClickSave={this.saveFormData("save").bind(this)}
                    onClickSaveNew={this.saveFormData("save-new").bind(this)} />
                <main>
                    {/* <div className="workflow-fields-sec"></div> */}
                    <div className="workflow-fields-sec form-builder-view">
                        <div className="card-block">
                            {/* <div className="card-body"> 
                                <div className="form-sections-wrap">
                                    <div className="form-sections-title">
                                        <h5>Lead Image</h5>
                                    </div>
                                    <div className="form-sections-fields">
                                        <div className="form-sections-images">
                                            <Avatar alt="profile" className="avatar-img" src={Avatar_img} />
                                        </div>
                                        <div className="form-sections-images">
                                            <Avatar alt="profile" className="avatar-img" src={Avatar_img} />
                                        </div>
                                        <div className="form-sections-images">
                                            <Avatar alt="profile" className="avatar-img" src={Avatar_img} />
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                            <div className="card-body">
                                <div className="form-sections-wrap">
                                    {/* <div className="form-sections-title">
                                        <h5>Lead Information</h5>
                                    </div> */}
                                    <div className="form-sections-fields">
                                        {
                                            formData.form_field_list.map(field => {
                                                if (field.is_hidden !== 'yes') {
                                                    return FormCommon.fieldGenerate(field.field_type, field, this.handleGenOnChangeValue(field).bind(this), this.handleButtonEvents, (errors, value) => this.handleDeleteFile(field, errors, value))
                                                }
                                                else {
                                                    return null;
                                                }
                                            })
                                        }

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {this.renderFileUploadTrigger()}
                    <LoadingComponent loading={loading} />
                </main>
            </div>
        )
    }
}

FormBuilderView.propTypes = {
    setFormData: PropTypes.func.isRequired,
    setUserData: PropTypes.func.isRequired,
}


export default connect(null, { setFormData, setUserData })(FormBuilderView);

