
import React, { Component } from 'react';

import { withStyles } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import InputField from '../fields/inputs';
import IconButton from '@material-ui/core/IconButton';
import Fab from '@material-ui/core/Fab';
import SearchField from '../fields/search';
import { Scrollbars } from 'react-custom-scrollbars';
import Tooltip from '@material-ui/core/Tooltip';
// import icons from './icons';
// import './styles.css';
import { icons } from '../../utilities/constants';
let { Square2 } = icons['i_square2'].icon;




const CustomListItem = (item, props) => (
  <item.type {...item.props}>
    <span className={`k-icon k-i-${props.dataItem.icon}`} style={{ margin: 'auto' }} />
  </item.type>
)

const CustomValue = (element, props) => (
  <element.type {...element.props}>
    {props && <span className={`k-icon k-i-${props.icon}`} />}
    {props && props.text}
  </element.type>
)


class IconFilterPopover extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filter: [],
      anchorEl: false,
      selectedIcon: { text: "Square2", icon: Square2 },
      search: "",
    }
  }

  handleFilterChange = ({ filter }) => {
    this.setState({ filter })
  }
  ProfileUserClick(event) {
    this.setState({ anchorEl: event.currentTarget });
  }
  ProfileUserClose() {
    this.setState({ anchorEl: !this.state.anchorEl });
  }

  searchValue(value) {
    // console.log("My Search",value);
    this.setState({ search: value })
    // console.log(this.state.search,"Hello")
  }

  render() {
    const { name, label, instruction, is_mandatory, defaultLabel, disabled, fieldStyle, customStyle, selected_value, default_value, Iconslist } = this.props;
    const { anchorEl, newlistIcon, search } = this.state;
    // console.log(Iconslist,"heyyyyy")
    let defaultFieldStyle = "field-box";
    let isDisabled = this.props.is_disable === 'yes' ? true : false;
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popper' : undefined;

    let filteredIcon = Object.keys(Iconslist);
    if (search) {
      var myKeys = Object.keys(Iconslist)
      // console.log("Obbject to Array",myKeys)
      filteredIcon = myKeys.filter(function (key) {
        return Iconslist[key].text.toLowerCase().includes(search.toLowerCase())
      }
      )
      // console.log(filteredIcon,"filter")
    }
    const LightTooltip = withStyles(theme => ({
      tooltip: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        position: 'static',
        left: '50%',
        transform: 'translateX(-50%)',
        boxShadow: theme.shadows[1],
        fontSize: 11,
      },
    }))(Tooltip);

    return (
      <div className={"icon-select-wrap " + customStyle}>
        <div className={fieldStyle ? fieldStyle : defaultFieldStyle}>
          <InputField
            label={label ? label : defaultLabel}
            default_value={this.props.selectedIcon.text}
            adornment="start"
            handleOnClickEvent={this.ProfileUserClick.bind(this)}
            adornmentContent={
              <IconButton aria-label="toggle password visibility">
                <img src={this.props.selectedIcon.icon} />
              </IconButton>
            }

            fieldStyle="field-box field-adornment bg-plane grey-color"
          />
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={this.ProfileUserClose.bind(this)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            <div className="select-filer-wrap">
              <div className="search-icons">
                <SearchField placeholder="Search here" handleOnChangeValue={(event) => this.searchValue(event)} />
              </div>
              <div className="select-icons">

                <Scrollbars style={{ width: '100%', height: '100%' }}>
                  {
                    filteredIcon.map((field, index) => {
                      return (
                        <LightTooltip title={Iconslist[field].text}>
                          <Fab variant="extended" size="smal" className="btn btn-small btn-fab btn-plane" onClick={this.ProfileUserClose.bind(this)}>
                            <img src={Iconslist[field].icon} onClick={() => this.props.newSelectedicon(field, Iconslist[field].text, Iconslist[field].icon)}></img>
                          </Fab>
                        </LightTooltip>
                      )
                    })
                  }
                </Scrollbars>
              </div>
            </div>
          </Popover>
        </div>
      </div>
    );
  }
}


IconFilterPopover.defaultProps = {
  default_value: null,
}

export default IconFilterPopover;
/*
ReactDOM.render(
  <AppComponent />,
  document.querySelector('my-app')
); */

