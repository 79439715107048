import React, { Component } from 'react';
class TextCounter extends Component {
    render() {
        let { dataLength, remaining_char_limit } = this.props;
        let remainingCharacters = remaining_char_limit - dataLength;
        return (
            <section className="counter-sec">
                Remaining: {(remainingCharacters) > 0 ? remainingCharacters : 0}
            </section>
        )
    }
}

TextCounter.defaultProps = {
    default_value: null,
}

export default TextCounter;