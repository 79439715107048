import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import Description from '../validators/descriptions';
import MandatoryValidator from '../validators/mandatory-validator';
import { connect } from 'react-redux';

//Validation
import * as ErrorValidation from '../validators/error-validator';

//Custom 
import ErrorMessage from '../common/error-message';

//Constants
import * as Constants from '../../utilities/constants';
import { InputAdornment } from '@material-ui/core';

class InputField extends Component {

    state = {
        errorStates: [],
        validNumber: ''
    }

    componentWillMount() {
        console.log("XOXOXOXO: ", this.props.selected_value, "sdas", this.props.default_value);
        let { default_value, selected_value } = this.props;

        if ((selected_value === "" || selected_value === null || selected_value === undefined) && default_value) {
            let errors = ErrorValidation.updateErrorMessage(default_value, this.props.field_validations, this.props.condition_script, this.props.error_message, this.props.char_size, null, this.props);
            this.props.handleOnChangeValue && this.props.handleOnChangeValue(default_value, errors, this.props);
        }
    }

    handleOnChangeEvent = (event) => {
        let userDataRecord = null;
        if (this.props.parent_id) {
            userDataRecord = this.props.userDataRecord;
        }

        let errors = ErrorValidation.updateErrorMessage(event.target.value, this.props.field_validations, this.props.condition_script, this.props.error_message, this.props.char_size, userDataRecord, this.props);
        this.setState({ errorStates: errors });
        this.props.handleOnChangeValue(event.target.value, errors, this.props);
    }

    handleNumberChange = (event) => {
        let errors = ErrorValidation.updateErrorMessage(event.target.value, this.props.field_validations, this.props.condition_script, this.props.error_message, null, null, this.props);
        let validNumber = this.state.validNumber;
        if (event.target.value >= 0 && event.target.value <= 255) {
            validNumber = event.target.value;
        }

        if (event.target.value !== "") {
            this.setState({ errorStates: errors });
            this.setState({ validNumber: validNumber });
            //this.props.handleOnChangeValue(validNumber, errors);
            this.props.handleOnChangeValue(event.target.value, errors);
        } else {
            // Milan Shah 3/10/21 Display required error if input is empty
            let requiredError = errors.filter((error) => error.message === Constants.REQUIRED_ERROR_MESSAGE);
            this.setState({ errorStates: requiredError });
            if (this.state.validNumber <= 9) {
                this.props.handleOnChangeValue(event.target.value, errors);
            }
        }

    }

    componentWillReceiveProps(nextProps) {

        //Global validation check
        if (nextProps.triggerValidCheck !== this.props.triggerValidCheck) {

            let errors = ErrorValidation.updateErrorMessage(nextProps.value, nextProps.field_validations, this.props.condition_script, this.props.error_message);
            this.setState({ errorStates: errors });
            //this.props.handleOnChangeValue(nextProps.value, errors);
        }
    }

    handleEnterKeyPress = (event) => {
        let { actionPerformAfterEnterPress } = this.props;
        if (event.key === 'Enter') {
            actionPerformAfterEnterPress && actionPerformAfterEnterPress();
        }
    }

    render() {
        /** 
         * name: for label and id for input
         * type: defined type for input
         * label: label text
         * instruction: instruction
         * placeValue: placeholder
         * fieldStyle: field-underline
         * <InputField label="Form Name" type="text" name="form_name"/>
         * */
        const {
            name,
            type,
            label,
            instruction,
            selected_value,
            is_mandatory,
            placeholder,
            defaultLabel,
            disabled,
            fieldStyle,
            customStyle,
            // isPhoneNumber, 
            // isEmail,
            default_value,
            withLabel,
            adornment,
            adornmentContent,
            errorObj
        } = this.props;
        let { errorStates } = this.state;
        let defaultPlaceholder = "";
        let defaultFieldStyle = "field-box";
        let isDisabled = (this.props.is_disable === 'yes');
        return (
            <div className={"form-row " + customStyle}>
                <div className={fieldStyle ? fieldStyle : defaultFieldStyle}>
                    {
                        withLabel ? (
                            <InputLabel
                                shrink
                                htmlFor={name}
                                className="label">
                                {label ? label : defaultLabel}
                                <MandatoryValidator value={is_mandatory} />
                            </InputLabel>
                        ) : null
                    }

                    <TextField
                        id={name}
                        className="field"
                        type={type}
                        onChange={this.props.type === "number" ? this.handleNumberChange : this.handleOnChangeEvent}
                        value={selected_value === 0 || selected_value ? selected_value : (default_value ? default_value : "")}
                        //defaultValue={default_value}
                        placeholder={placeholder ? placeholder : defaultPlaceholder}
                        disabled={isDisabled ? isDisabled : disabled}
                        onClick={this.props.handleOnClickEvent}
                        onKeyPress={this.handleEnterKeyPress}
                        InputProps={adornment === 'start' ? {
                            startAdornment: (
                                <InputAdornment position="start">
                                    {adornmentContent}
                                </InputAdornment>
                            )
                        } : adornment === 'end' ? {
                            endAdornment: (
                                <InputAdornment position="end">
                                    {adornmentContent}
                                </InputAdornment>
                            )
                        } : {}}
                    >
                    </TextField>

                    <ErrorMessage errorStates={errorObj ? [errorObj, ...errorStates] : errorStates} name={name} />

                </div>
                <Description value={instruction} />
            </div>
        )
    }
}

InputField.defaultProps = {
    isPhoneNumber: false,
    isEmail: false,
    withLabel: true,
    default_value: "",
    selected_value: "",
    adornment: "",
}

const mapStateToProps = state => ({
    userDataRecord: state.formData,
});
export default connect(mapStateToProps)(InputField);
