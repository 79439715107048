import React, { Component } from 'react';
import './form-builder-scene.css';
import Header from '../../components/header';
import HTML5Backend from 'react-dnd-html5-backend'
import { DragDropContext } from 'react-dnd'

//custom component
import FormBuilderMaster from '../../components/form-builder/formBuildMaster';
import LoadingComponent from '../../components/common/loading-component';

import { createMuiTheme } from '@material-ui/core/styles';

//API
import * as FormElementApi from '../../api/form-builder-api';
import * as FormApi from '../../api/form-api';
import * as ProgramApi from '../../api/program-api';

//Constants
import * as Constants from '../../utilities/constants';

const theme = createMuiTheme(
)

class FormBuilderScene extends Component {

  state = {
    formFieldTypes: [],
    formFieldProperties: [],
    formData: {},
    programKey: null,
    formKey: null,
    loading: true,
    sectionOption: [],
  }

  componentWillMount() {
    const match = this.props.match;
    let programKey = match.params.programkey;
    let formKey = match.params.formkey;

    this.setState({ formKey: formKey, programKey: programKey });

    let formElementPromise = FormElementApi.getFormFieldMetadataTypes();

    let formElementPropertiesPromise = FormElementApi.getFormFieldMetadataProperties();
    formElementPropertiesPromise.then(formElementProperties => {
      this.setState({ formFieldProperties: formElementProperties });
      formElementPromise.then(formFieldTypes => {

        let formDataPromise = FormApi.getFormDataByKey(formKey);
        formDataPromise.then(formData => {
          //Manage formField Types of the basis of formType
          this.reduceFieldTypes(formFieldTypes, formData.form_type)
          this.setState({ formData: formData, loading: false }, () => this.getAvailSectionData(null));
        })
      })
    })

  }

  getAvailSectionData(callback) {
    let { formData } = this.state;
    const match = this.props.match;
    let programkey = match.params.programkey;
    ProgramApi.getAvalibaleSection(programkey).then(sections => {
      let sectionOption = [];
      console.log("sections", sections);

      sections.forEach(section => {
        let obj = {
          field_option_label: section.section_name,
          field_option_value: section.id
        }
        sectionOption.push(obj);
      })
      let selectedSection = {
        field_option_label: formData.section_name,
        field_option_value: formData.section_id,
      };

      sectionOption.unshift(selectedSection);
      this.setState({ sectionOption: sectionOption }, callback);
    })
  }

  reduceFieldTypes(formFieldTypes, formType) {
    let exceptList = "";
    for (let key in Constants.FORM_TYPE) {
      if (Constants.FORM_TYPE[key].code === formType) {
        exceptList = Constants.FORM_TYPE[key].fieldExcept.join();
      }
    }
    let updatedFormFieldTypes = formFieldTypes.filter(formFieldType => !exceptList.includes(formFieldType.field_code_txt))
    this.setState({ formFieldTypes: updatedFormFieldTypes });
  }

  saveFormData = (formData, callback) => {
    let formPromise = FormApi.postFormData(formData);
    formPromise.then(res => {
      callback(res);
    })
  }

  updateFormData = (formData, callback) => {
    let childList = [];
    formData.form_field_list.forEach(field => {
      if (field.children) {
        childList = field.children;
        formData.form_field_list = formData.form_field_list.concat(childList);
      }
    })

    let formPromise = FormApi.putFormData(formData.id, formData);
    formPromise.then(res => {
      callback(res, formData);
    })
  }

  handleLoading = (value) => {
    this.setState({ loading: value });
  }

  render() {
    let { formFieldTypes, formFieldProperties, formData, formKey, programKey, loading, sectionOption } = this.state;
    const match = this.props.match;
    let clientId = match.params.clientId;

    return (
      <div className="FormBuilderScene" theme={theme}>
        <Header headerType="dashboard" positionType="fixed" programKey={programKey} />
        <main>
          {
            <div>
              <FormBuilderMaster
                formFieldTypes={formFieldTypes}
                formFieldProperties={formFieldProperties}
                saveForm={this.saveFormData}
                formData={formData}
                updateForm={this.updateFormData}
                formKey={formKey}
                clientId={clientId}
                programKey={programKey}
                sectionOption={sectionOption}
                handleLoading={(value) => this.handleLoading(value)}
              />
            </div>
          }
        </main>
        <LoadingComponent loading={loading} />
      </div>
    );
  }
}

export default DragDropContext(HTML5Backend)(FormBuilderScene);
