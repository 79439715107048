import React, { Component } from 'react';

import { Scrollbars } from 'react-custom-scrollbars';

import AddIcon from '@material-ui/icons/Add';
import Fab from '@material-ui/core/Fab';
import Slide from '@material-ui/core/Slide';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';

import Header from '../../components/header';
import RadioField from '../../components/fields/radio';
import UploadField from '../../components/fields/upload';
import TextAreaField from '../../components/fields/textarea';
import InputField from '../../components/fields/inputs';
import * as InputFieldValidation from '../../components/validators/field-validators/input-field-validation';

//API
import * as ProgramApi from '../../api/program-api';
import * as UserApi from '../../api/user-api';
import * as StorageFileApi from '../../api/file-storage-api';
import * as ClientApi from '../../api/client-api';

import SelectField from '../../components/fields/select';
import PhoneField from '../../components/fields/phone';
import { RECORD_STATUS, DEFAULT_ADVANCED_CONFIG, TRUE_OR_FALSE_OPTION, SHOW_ADVANCED_CONFIG_OPTION, COLOR_SET } from '../../utilities/constants';
import { validAdvancedConfigDetails } from '../../utilities/data-source-config-validator';
import { isAdminSubdomain } from '../../utilities/multitenant';

import ProgramIcon from '../../assets/images/icon/square-4-blue.svg';
import CompanyIcon from '../../assets/images/icon/company-blue.svg';
import ArrowIcon from '../../assets/images/icon/chevron-blue.svg';
import SectionalIllus from '../../assets/images/illustration/section-circle.svg';
import CloseIcon from '../../assets/images/icon/close.svg';
import WorkflowIllust from '../../assets/images/illustration/program-iso.svg';
import ScratchIllus from '../../assets/images/illustration/scratch.svg';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

class CreateApplication extends Component {
    state = {
        active: false,
        tabAction: 0,
        programData: [],
        openProgram: false,
        openClient: false,
        programDataCreate: {},
        buttonState: false,
        uploadFile: null,
        userType: -1,
        customStyleSearch: "",
        searchedProgramData: [],
        openDivProgram: isAdminSubdomain() ? false : true,
        openDivClient: isAdminSubdomain() ? true : false,
        clientData: [],
        clientDataCreate: {
            advancedConfig: DEFAULT_ADVANCED_CONFIG
        },
        searchedClientData: [],
        clientName: "",
        clientType: "",
        programActive: isAdminSubdomain() ? '' : 'active',
        clientActive: isAdminSubdomain() ? 'active' : '',
        inValidError: '',
        openInvalidTypeDialog: false,
        // State for storing if the user has tested the connection to the client database.
        hasVerifiedConnection: false,
        showAdvancedConfig: "default_config "
    }

    componentDidMount() {
        let { programDataCreate, clientDataCreate } = this.state;
        programDataCreate["program_type"] = "individual";
        clientDataCreate.databaseOption = "assurekit";
        this.setState({ programDataCreate, clientDataCreate });
    }

    handleUploadCreation = () => (file) => {
        let allowedExtensions = /(\.docx|\.doc|\.pdf)$/i;

        if (!allowedExtensions.exec(file.name)) {
            this.setState({ openInvalidTypeDialog: true });
        } else {
            this.setState({ uploadFile: file, })
        }
    }

    handleClose(key) {
        this.setState({ [key]: false });
    }

    handleDeleteFile() {
        this.setState({ uploadFile: null });
    }

    handleSearchOnChangeValue(event) {
        let { programData } = this.state;

        if (event.target.value === "") {
            this.setState({ searchedProgramData: programData });
        } else {
            let searchedData = [];
            searchedData = programData.filter(program => program.program_name.toLowerCase().includes(event.target.value.toLowerCase()) || (program.program_desc && program.program_desc.toLowerCase().includes(event.target.value.toLowerCase())));
            this.setState({ searchedProgramData: searchedData });
        }
    }

    createProgramData() {
        let { uploadFile, programDataCreate } = this.state;
        if (programDataCreate.program_name && programDataCreate.program_type && uploadFile && programDataCreate.client_id) {
            const formData = new FormData();
            formData.append("file", uploadFile, uploadFile.name);
            StorageFileApi.postFileUpload(formData).then(response => {
                programDataCreate["master_policy_file_upload"] = response.file_id;
                ProgramApi.createProgram(programDataCreate).then(res => {
                    this.editProgram(res.program_key, res.client_id);
                })
            })

        }
    }

    editProgram(programKey, clientId) {
        window.location = "/" + clientId + "/" + programKey + "/program-dashboard";
    }

    editClient(key, pathname) {
        let { clientId } = this.props
        clientId = key;
        window.location = "/" + key + "/" + pathname;
    }

    handleProgramChange = (type) => (value) => {
        let { programDataCreate, uploadFile } = this.state;
        if (type === 'program_type' && programDataCreate.type !== value) {
            this.setState({ uploadFile: null })
        }
        programDataCreate[type] = value;
        this.setState({ programDataCreate: programDataCreate });
    }

    handleClientChange = (type) => (value) => {
        let { clientDataCreate } = this.state;
        if (Object.prototype.hasOwnProperty.call(clientDataCreate.advancedConfig, type)) {
            clientDataCreate.advancedConfig[type] = value;
        } else {
            clientDataCreate[type] = value;
        }

        this.setState({ clientDataCreate: clientDataCreate });
    }

    createNewWorkflow() {
        this.handleDialogOpen("formTypeDialogOpen");
    }

    componentWillMount() {
        this.getAllProgramData();
        this.getAllClientData();
        UserApi.getLoggedInUser().then(data => {
            console.log("data800", data);
            this.setState({ userType: data.user_type })
        })
        if (window.innerWidth <= 767) {
            this.setState({ active: !this.state.active });
        }

    }

    getAllProgramData() {
        ProgramApi.getAllProgramStates().then(data => {
            this.setState({ programData: data, searchedProgramData: data });
            console.log("Get Program data:-----", this.state.programData);
        })
    }

    getAllClientData() {
        ClientApi.getAllClientStates().then(data => {
            this.handleOnProgramSelectField(data[0].id, "client_id", null, 'program')
            this.setState({ clientData: data, searchedClientData: data });
        })
    }

    toggleTimeline() {
        this.setState({ active: !this.state.active });
    }

    handleActionChange(event, newValue) {
        this.setState({ tabAction: newValue });
    }

    handleCreateNewProgram = (key) => () => {
        this.setState({ openProgram: key });
    }

    redirectToProgram = (client_id, program_key) => () => {
        window.location = "/" + client_id + "/" + program_key + "/program-dashboard";
    }

    redirectToDashboard = (clientId, program_key) => () => {
        window.location = "/" + clientId + "/" + program_key + "/dashboard";
    }


    loginToClientTenant = (clientId, newTab) => {
        let clientData = { "clientId": clientId }
        ClientApi.getAdminClientCode(clientData).then(res => {
            if (newTab) {
                window.open(res.url, '_blank');
            } else {
                window.location.href = res.url;
            }
        });
    }

    redirectToClient = (key) => () => {
        // let { clientId } = this.props
        // clientId = key;
        if (isAdminSubdomain()) {
            this.loginToClientTenant(key, true);
        } else {
            window.location = "/" + key + "/program-client-dashboard";
        }
    }

    handleAddNewClient = (key) => () => {
        this.setState({ openClient: key });
    }

    handleClientCall = (key) => () => {
        this.setState({ openDivClient: key });
        this.setState({ openDivProgram: false });
        this.setState({ clientActive: 'active' });
        this.setState({ programActive: '' });
    }

    handleProgramCall = (key) => () => {
        this.setState({ openDivProgram: key });
        this.setState({ openDivClient: false });
        this.setState({ clientActive: '' });
        this.setState({ programActive: 'active' });
    }

    handleOnProgramSelectField(key, type, value, check) {
        if (check == 'program') {
            let { programDataCreate } = this.state;
            this.state.clientName = key;
            programDataCreate[type] = key;
            this.setState({ programDataCreate: programDataCreate });
        } else if (check == 'client') {
            let { clientDataCreate } = this.state;
            this.state.clientType = key;
            clientDataCreate[type] = key;
            this.setState({ clientDataCreate: clientDataCreate });
        }
    }

    createClientData() {
        let { clientDataCreate } = this.state;

        let emailValidation = InputFieldValidation.emailValidation(clientDataCreate.email);
        let phoneNumberValidation = InputFieldValidation.phoneNumberValidation(
            clientDataCreate.contact_no,
        );

        if (clientDataCreate.name && clientDataCreate.type && clientDataCreate.contact_no
            && phoneNumberValidation.isValid && clientDataCreate.email && emailValidation.isValid
            && validAdvancedConfigDetails(clientDataCreate.advancedConfig)) {

            const createClientPayload = {
                contact_no: clientDataCreate.contact_no,
                email: clientDataCreate.email,
                name: clientDataCreate.name,
                type: clientDataCreate.type,
                tenant_name: clientDataCreate.tenantName,
                database_option: clientDataCreate.databaseOption,
                database_name: clientDataCreate.databaseName,
                database_host: clientDataCreate.databaseHost,
                database_port: clientDataCreate.databasePort,
                database_username: clientDataCreate.databaseUsername,
                database_password: clientDataCreate.databasePassword,
                advanced_config: clientDataCreate.advancedConfig
            };

            ClientApi.createClient(createClientPayload)
                .then(res => {
                    if (res.status === RECORD_STATUS.INACTIVE) {
                        this.handleAddNewClient(false);
                        this.setState({ clientDataCreate: { advancedConfig: DEFAULT_ADVANCED_CONFIG } });
                        this.loginToClientTenant(res.id, false);
                        //this.editClient(res.id, "profile-dashboard");
                    }
                });
        }
    }

    // editClient = client_id => {
    //     window.location = "/" + client_id + "/client-dashboard";
    // }

    /**
     * Tests the connection to the client's database given that the form fields are filled in
     * correctly.
     *
     * @author Ryan Noronha
     */
    testDatabaseConnection() {

        const { databaseName, databaseHost, databasePort, databaseUsername,
            databasePassword } = this.state.clientDataCreate;

        // Make sure that all the connection fields are filled in.
        if (!databaseName || !databaseHost || !databasePort || !databaseUsername || !databasePassword) {
            console.error("All or some database connection parameters are missing.");
            return;
        }

        // Send a request to the backend for testing the connection to the client's database.
        ClientApi.testClientDBConnection(databaseName, databaseHost, databasePort,
            databaseUsername, databasePassword
        ).then((response) => {
            alert("Connection established succefully")
        }).catch((error) => {
            alert("Unable to connect client's database");
            console.error("Error while testing connection: ", error);
        });
    }

    sortClientDataWithStatus(searchedClientData) {
        let sortedClientDataWithStatusAndName = searchedClientData.sort((a, b) => {
            return a.status.localeCompare(b.status) || a.name.localeCompare(b.name);
        });
        return sortedClientDataWithStatusAndName.map((fields) => {
            return (
                <div className="create-app-block" onClick={this.state.userType === 0 ? fields.status == "INACTIVE" && !isAdminSubdomain() ? this.editClient(fields.id, "profile-dashboard") : this.redirectToClient(fields.id).bind(this) : this.redirectToDashboard(fields.id).bind(this)}>
                    {fields.status === "INACTIVE" && (<h5 className="create-draft">Deactivated</h5>)}
                    <div className="create-app-wrap">
                        <Fab variant="extended" size="large" className="btn btn-large btn-fab btn-plane no-shadow grey-color no-shadow"
                            style={{
                                background: COLOR_SET[fields.name.trim().split(" ")[0].slice(0, 1).toLowerCase()],
                            }}
                        >
                            {fields.name.trim().split(" ").length === 1 ? fields.name.trim().split(" ")[0].slice(0, 1) : fields.name.trim().split(" ")[0].slice(0, 1) + fields.name.trim().split(" ")[1].slice(0, 1)}
                        </Fab>
                        <h5 className="create-info">{fields.name}</h5>
                        <h6 className="font-med create-desc">{fields.program_count}</h6>
                    </div>
                </div>
            );
        });
    }

    render() {
        let { active, tabAction, programData, openProgram, openClient, userType, buttonState, searchedProgramData, openDivProgram, openDivClient, clientDataCreate, clientName, searchedClientData, programActive, clientActive, clientType, inValidError } = this.state;
        let toggleTimeline = active ? "toggle-timeline" : "";
        let ToggleBtnArrow = buttonState ? "toggle-arrow" : "";
        console.log("programData223", programData, "searchedProgramData", searchedProgramData);
        let programType = [
            {
                field_option_label: "Individual",
                field_option_value: "individual",
            },
            {
                field_option_label: "Group",
                field_option_value: "group"
            }
        ]
        const databaseOptions = [
            {
                field_option_label: "Use Assurekit DB",
                field_option_value: "assurekit",
            },
            {
                field_option_label: "Use Client DB",
                field_option_value: "client",
            },
        ];

        const match = this.props.match;
        console.log("match.params: ", match.params);
        let programkey = match.params.programkey;
        let clientTypeList = [
            {
                field_option_label: "Distributor",
                field_option_value: "distributor",
            },
            {
                field_option_label: "Insurance Broker",
                field_option_value: "insurance_broker"
            },
            {
                field_option_label: "Corporate Agent",
                field_option_value: "corporate_agent"
            },
            {
                field_option_label: "Insurance Company",
                field_option_value: "insurance_company"
            },
            {
                field_option_label: "Individual Agent",
                field_option_value: "individual_agent"
            },
            {
                field_option_label: "Vendor",
                field_option_value: "vendor"
            }
        ]

        return (
            <div className="CreateApplication">
                <Header headerType="dashboard" positionType="fixed" searchType="normalClick" programKey={programkey} customStyleSearch={this.state.customStyleSearch} handleSearchOnChangeValue={(event) => this.handleSearchOnChangeValue(event)} />
                <main>
                    <div className="workflow-fields-sec program-build-sec create-app-sec">
                        <div className="program-build-wrap">
                            <div className={"program-card-wrap program-build-timeline " + toggleTimeline}>
                                <div className="card-block">
                                    <div className="card-body">
                                        <section className="create-app-timeline">
                                            {!isAdminSubdomain() && <div className="cretae-timeline-wrap">
                                                <div className={"program-timeline-btn " + programActive} onClick={this.handleProgramCall(true).bind(this)} >
                                                    <Fab variant="extended" size="small" className="btn btn-small no-shadow btn-icon">
                                                        <img src={ProgramIcon} alt="ProgramIcon" />
                                                        {
                                                            !toggleTimeline && (
                                                                <span>Program</span>
                                                            )
                                                        }
                                                    </Fab>
                                                </div>
                                            </div>
                                            }
                                            {userType === 0 && <div className="cretae-timeline-wrap">
                                                <div className={"program-timeline-btn " + clientActive} onClick={this.handleClientCall(true).bind(this)}>
                                                    <Fab variant="extended" size="small" className="btn btn-small no-shadow btn-icon">
                                                        <img src={CompanyIcon} alt="CompanyIcon" />
                                                        {
                                                            !toggleTimeline && (
                                                                <span>Client</span>
                                                            )
                                                        }
                                                    </Fab>
                                                </div>
                                            </div>}
                                            {/* <div className="cretae-timeline-wrap">
                                                <div className= {"program-timeline-btn "}>
                                                    <Fab variant="extended" size="small" className="btn btn-small no-shadow btn-icon">
                                                        <img src={CompanyIcon} alt="CompanyIcon"/>
                                                        {
                                                            !toggleTimeline && (
                                                                <span>Company</span>
                                                            )
                                                        }
                                                    </Fab>
                                                </div>
                                            </div>
                                            <div className="cretae-timeline-wrap">
                                                <div className= {"program-timeline-btn "}>
                                                    <Fab variant="extended" size="small" className="btn btn-small no-shadow btn-icon">
                                                        <img src={UserIcon} alt="UserIcon"/>
                                                        {
                                                            !toggleTimeline && (
                                                                <span>User</span>
                                                            )
                                                        }
                                                    </Fab>
                                                </div>
                                            </div> */}
                                            <div className={"program-timeline-btn program-timeline-toggle " + ToggleBtnArrow} onClick={this.toggleTimeline.bind(this)} >
                                                <Fab variant="extended" size="small" className="btn btn-small no-shadow btn-toggle">
                                                    <img src={ArrowIcon} alt="ArrowIcon" />
                                                    {/* <ArrowBackGrey className="icon"/> */}
                                                </Fab>
                                            </div>
                                        </section>
                                    </div>
                                </div>
                            </div>
                            {openDivClient &&
                                <div className={"program-card-wrap program-build-form " + toggleTimeline}>
                                    <div className="card-block">
                                        <div className="card-body">
                                            {!programData ?
                                                <section className="blank-page-sec">
                                                    <div className="blank-page-wrap">
                                                        <div className="blank-page-icon">
                                                            <img src={SectionalIllus} alt="SectionalIllus" />
                                                        </div>
                                                        <div className="blank-page-info m-t-b-15">
                                                            <h6>You can access only the new applications.</h6>
                                                        </div>
                                                        <Fab variant="extended" size="large" className="btn btn-large workflow-add-btn no-shadow primary-color" onClick={this.handleAddNewClient(true).bind(this)}>
                                                            Add New Client
                                                        </Fab>
                                                    </div>
                                                </section>
                                                :
                                                <div className="drawer-tabs drawer-tabs-round no-drawer-indicator workflow-tabs-wrap">
                                                    <div className="drawer-tabs-header">
                                                        <div className="tabs-section">
                                                            {/* <Tabs
                                                        value={tabAction}
                                                        onChange={this.handleActionChange.bind(this)}
                                                        variant="scrollable"
                                                        scrollButtons="auto"
                                                        aria-label="scrollable auto tabs">
                                                            <Tab label="Sectional" />
                                                            <Tab label="On Click" />
                                                            <Tab label="Actions" />
                                                    </Tabs> */}
                                                        </div>
                                                        {/* <div className="workflow-tabs-search">
                                                    <SearchField placeholder="Search here" searchType="normal" customStyle="white-color"/>
                                                </div> */}
                                                    </div>
                                                    {tabAction === 0 &&
                                                        <div className="tab-container">
                                                            <Scrollbars style={{ width: '100%', height: '100%' }}>
                                                                <div className="table-wrapper">
                                                                    {userType === 0 && isAdminSubdomain() &&
                                                                        <div className="create-app-block create-app-new" onClick={this.handleAddNewClient(true).bind(this)}>
                                                                            <div className="create-app-wrap">
                                                                                <Fab variant="extended" size="large" className="btn btn-large btn-fab no-shadow primary-color ">
                                                                                    <AddIcon />
                                                                                </Fab>
                                                                                <h5 className="create-info">Add new Client</h5>
                                                                                <h6 className="font-med create-desc">Click To Add</h6>
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                    {userType === 0 && this.sortClientDataWithStatus(searchedClientData)}
                                                                </div>
                                                            </Scrollbars>
                                                        </div>
                                                    }
                                                    {tabAction === 1 &&
                                                        <div className="tab-container">
                                                            <Scrollbars style={{ width: '100%', height: '100%' }}>
                                                                <div className="table-wrapper">
                                                                    Section 1
                                                                </div>
                                                            </Scrollbars>
                                                        </div>}
                                                    {tabAction === 2 &&
                                                        <div className="tab-container">
                                                            <Scrollbars style={{ width: '100%', height: '100%' }}>
                                                                <div className="table-wrapper">
                                                                    Section 2
                                                                </div>
                                                            </Scrollbars>
                                                        </div>}
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>}

                            {openDivProgram &&
                                <div className={"program-card-wrap program-build-form " + toggleTimeline}>
                                    <div className="card-block">
                                        <div className="card-body">
                                            {!programData ?
                                                <section className="blank-page-sec">
                                                    <div className="blank-page-wrap">
                                                        <div className="blank-page-icon">
                                                            <img src={SectionalIllus} alt="SectionalIllus" />
                                                        </div>
                                                        <div className="blank-page-info m-t-b-15">
                                                            <h6>You can access only the new applications.</h6>
                                                        </div>
                                                        <Fab variant="extended" size="large" className="btn btn-large workflow-add-btn no-shadow primary-color" onClick={this.handleCreateNewProgram(true).bind(this)}>
                                                            Create Application
                                                        </Fab>
                                                    </div>
                                                </section>
                                                :
                                                <div className="drawer-tabs drawer-tabs-round no-drawer-indicator workflow-tabs-wrap">
                                                    <div className="drawer-tabs-header">
                                                        <div className="tabs-section">
                                                            {/* <Tabs
                                                        value={tabAction}
                                                        onChange={this.handleActionChange.bind(this)}
                                                        variant="scrollable"
                                                        scrollButtons="auto"
                                                        aria-label="scrollable auto tabs">
                                                            <Tab label="Sectional" />
                                                            <Tab label="On Click" />
                                                            <Tab label="Actions" />
                                                    </Tabs> */}
                                                        </div>
                                                        {/* <div className="workflow-tabs-search">
                                                    <SearchField placeholder="Search here" searchType="normal" customStyle="white-color"/>
                                                </div> */}
                                                    </div>
                                                    {tabAction === 0 &&
                                                        <div className="tab-container">
                                                            <Scrollbars style={{ width: '100%', height: '100%' }}>
                                                                <div className="table-wrapper">
                                                                    {userType === 0 &&
                                                                        <div className="create-app-block create-app-new" onClick={this.handleCreateNewProgram(true).bind(this)}>
                                                                            <div className="create-app-wrap">
                                                                                <Fab variant="extended" size="large" className="btn btn-large btn-fab no-shadow primary-color ">
                                                                                    <AddIcon />
                                                                                </Fab>
                                                                                <h5 className="create-info">Fully Customised</h5>
                                                                                <h6 className="font-med create-desc">Create From Scratch</h6>
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                    {

                                                                        searchedProgramData.map(fields => {
                                                                            return (
                                                                                <div className="create-app-block" onClick={userType === 0 ? this.redirectToProgram(fields.client_id, fields.program_key).bind(this) : this.redirectToDashboard(fields.client_id, fields.program_key).bind(this)}>
                                                                                    {!fields.program_published && <h5 className="create-draft">Draft Mode</h5>}
                                                                                    <div className="create-app-wrap">
                                                                                        <Fab variant="extended" size="large"
                                                                                            className="btn btn-large btn-fab btn-plane no-shadow grey-color no-shadow"
                                                                                            style={{ background: (COLOR_SET[fields.program_name.trim().split(" ")[0].slice(0, 1).toLowerCase()]) }}
                                                                                        >
                                                                                            {
                                                                                                fields.program_name.trim().split(" ").length === 1 ?
                                                                                                    fields.program_name.trim().split(" ")[0].slice(0, 1) :
                                                                                                    fields.program_name.trim().split(" ")[0].slice(0, 1) + fields.program_name.trim().split(" ")[1].slice(0, 1)

                                                                                            }
                                                                                        </Fab>

                                                                                        <h5 className="create-info">{fields.program_name}</h5>
                                                                                        {
                                                                                            searchedClientData.map(client => {
                                                                                                return (
                                                                                                    fields.client_id === client.id ?
                                                                                                        <h6 className="font-med create-desc">{client.name}</h6> : ''
                                                                                                )
                                                                                            })
                                                                                        }
                                                                                    </div>
                                                                                    <div className="app-notify">
                                                                                        {/* <MultipleTabActions type="icons" iconList={["edit","delete","share"]}/> */}
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                        })
                                                                    }
                                                                </div>
                                                            </Scrollbars>
                                                        </div>}
                                                    {tabAction === 1 &&
                                                        <div className="tab-container">
                                                            <Scrollbars style={{ width: '100%', height: '100%' }}>
                                                                <div className="table-wrapper">
                                                                    Section 1
                                                                </div>
                                                            </Scrollbars>
                                                        </div>}
                                                    {tabAction === 2 &&
                                                        <div className="tab-container">
                                                            <Scrollbars style={{ width: '100%', height: '100%' }}>
                                                                <div className="table-wrapper">
                                                                    Section 2
                                                                </div>
                                                            </Scrollbars>
                                                        </div>}
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>}
                        </div>
                    </div>

                    <Dialog open={openProgram} TransitionComponent={Transition} maxWidth="xl">
                        <section className="workflow-dialog workflow-dialog-large">
                            <div className="card-block">
                                <div className="card-header border-bottom">
                                    <div className="sub-title">
                                        <h5>Program</h5>
                                    </div>
                                    <div className="close-dialog">
                                        <img src={CloseIcon} alt="CloseIcon" onClick={this.handleCreateNewProgram(false).bind(this)}></img>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <Grid container>
                                        <Grid item md={4}>
                                            <div className="workflow-illust-wrap">
                                                <div className="sub-title">
                                                    <h2>Create a New Program</h2>
                                                </div>
                                                <div className="workflow-illust">
                                                    <img src={WorkflowIllust} alt="workflowIllust" />
                                                </div>
                                            </div>
                                        </Grid>
                                        <Grid item md={8}>
                                            <div className="workflow-dialog-forms form-builder-dashboard">
                                                <div className="tabs-container tabs-container-scroll">
                                                    <Scrollbars style={{ width: '100%', height: '100%' }}>
                                                        <div className="tabs-container-scroll-wrap">
                                                            <Grid item md={12}>
                                                                <div className="workflow-form-scene form-row">
                                                                    <div className="workflow-form-select active">
                                                                        <Button variant="contained" color="primary" className="btn">
                                                                            <img src={ScratchIllus} alt="ScratchIllus" ></img>
                                                                        </Button>
                                                                        <h5>Scratch</h5>
                                                                    </div>
                                                                    {/* <div className="workflow-form-select">
                                                                        <Button variant="contained" color="primary" className="btn">
                                                                            <img src={TemplateIllus} alt="TemplateIllus"></img>
                                                                        </Button>
                                                                        <h5>Template</h5>
                                                                    </div> */}
                                                                    <div className="workflow-form-info">
                                                                        <h6 className="sub-title semi-bold">Create a form from scratch?</h6>
                                                                        <h6 className="grey-color weight-300">You can create your program from scratch by creating and adding sections, forms, flows and users</h6>
                                                                    </div>
                                                                </div>
                                                            </Grid>
                                                            <div className="workflow-form-field">
                                                                <Grid container lg={12} md={12}>
                                                                    <Grid item lg={5} md={8}>
                                                                        <InputField label="Name of the Program"
                                                                            type="text"
                                                                            name="form_name"
                                                                            selected_value={this.state.programDataCreate.program_name}
                                                                            fieldStyle="field-box bg-plane grey-color"
                                                                            placeholder="Name"
                                                                            is_mandatory="yes"
                                                                            handleOnChangeValue={this.handleProgramChange("program_name").bind(this)}
                                                                        />
                                                                    </Grid>
                                                                    <Grid item lg={5} md={8}>
                                                                        <RadioField
                                                                            radioGroupLabel="Program_Type"
                                                                            radioData={programType}
                                                                            label="Program Type"
                                                                            radioFieldStyle="field-inline"
                                                                            default_value="individual"
                                                                            selected_value={this.state.programDataCreate.program_type}
                                                                            radioChangeHandle={this.handleProgramChange("program_type").bind(this)}
                                                                        />
                                                                    </Grid>
                                                                </Grid>
                                                                <Grid container lg={12} md={12}>
                                                                    <Grid item lg={5} md={8}>
                                                                        <UploadField
                                                                            label="Master Policy Upload"
                                                                            type="file"
                                                                            name="upload"
                                                                            customStyle="upload-file"
                                                                            fieldStyle="field-box bg-plane grey-color field-upload"
                                                                            uploadFile={this.state.uploadFile}
                                                                            handleUploadCreation={this.handleUploadCreation().bind(this)}
                                                                            is_mandatory="yes"
                                                                            handleDeleteFile={() => this.handleDeleteFile()}
                                                                        />

                                                                    </Grid>
                                                                </Grid>
                                                                <Grid container lg={12} md={12}>
                                                                    <Grid item lg={5} md={8}>
                                                                        <TextAreaField label="Description" name="form_description" rowmax="3" selected_value={this.state.programDataCreate.program_desc} fieldStyle="field-box bg-plane grey-color" placeholder="Description" handleOnChangeValue={this.handleProgramChange("program_desc").bind(this)} />
                                                                    </Grid>
                                                                </Grid>
                                                            </div>
                                                        </div>
                                                    </Scrollbars>
                                                </div>
                                                <div className="form-row workflow-dialog-footer">
                                                    <div className="btn-wrap no-border">
                                                        <Fab variant="extended" size="large" className="btn btn-large btn-plane primary-color" onClick={this.createProgramData.bind(this)}>
                                                            Create Program
                                                        </Fab>
                                                    </div>
                                                </div>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </div>
                            </div>
                        </section>
                    </Dialog>

                    {userType === 0 &&
                        (<Dialog open={openClient} TransitionComponent={Transition} maxWidth="xl">
                            <section className="workflow-dialog workflow-dialog-large">
                                <div className="card-block">
                                    <div className="card-header border-bottom">
                                        <div className="sub-title">
                                            <h2>Basic Client Details</h2>
                                        </div>
                                        <div className="close-dialog">
                                            <img src={CloseIcon} alt="CloseIcon" onClick={this.handleAddNewClient(false).bind(this)}></img>
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        <Grid container>
                                            <Grid item md={4}>
                                                <div className="workflow-illust-wrap">
                                                    <div className="sub-title">
                                                        <h2>Create a New Client</h2>
                                                    </div>
                                                    <div className="workflow-illust">
                                                        <img src={WorkflowIllust} alt="workflowIllust" />
                                                    </div>
                                                </div>
                                            </Grid>
                                            <Grid item md={8}>
                                                <div className="workflow-dialog-forms form-builder-dashboard">
                                                    <div className="tabs-container tabs-container-scroll">
                                                        <Scrollbars style={{ width: '100%', height: '100%' }}>
                                                            <div className="tabs-container-scroll-wrap">
                                                                <Grid item md={12}>
                                                                    {/* <div className="workflow-form-scene form-row">
                                                                    <div className="workflow-form-select active">
                                                                        <Button variant="contained" color="primary" className="btn">
                                                                            <img src={ScratchIllus} alt="ScratchIllus" ></img>
                                                                        </Button>
                                                                        <h5>Scratch</h5>
                                                                    </div> */}
                                                                    {/* <div className="workflow-form-select">
                                                                        <Button variant="contained" color="primary" className="btn">
                                                                            <img src={TemplateIllus} alt="TemplateIllus"></img>
                                                                        </Button>
                                                                        <h5>Template</h5>
                                                                    </div> */}
                                                                    {/* <div className="workflow-form-info">
                                                                        <h6 className="sub-title semi-bold">Create a form scratch?</h6>
                                                                        <h6 className="grey-color weight-300">You can create your client from scratch by adding basic client details</h6>
                                                                    </div>
                                                                </div> */}
                                                                </Grid>
                                                                <div className="workflow-form-field">
                                                                    <Grid container lg={12} md={12}>

                                                                        <Grid container lg={12} md={12}>
                                                                            <Grid item lg={5} md={8}>
                                                                                <SelectField
                                                                                    label="Client Type"
                                                                                    withLabel={true}
                                                                                    optionsData={clientTypeList}
                                                                                    selected_value={this.state.clientType}
                                                                                    default_value={this.state.clientType}
                                                                                    fieldStyle="field-box bg-plane grey-color"
                                                                                    radioChangeHandle={(key, value, errors) => this.handleOnProgramSelectField(key, "type", value, 'client')}
                                                                                    is_mandatory="yes"
                                                                                    field_validations={["v_required"]}
                                                                                />
                                                                            </Grid>
                                                                            <Grid item lg={5} md={8}>
                                                                                <InputField label="Client Name" type="text" name="name"
                                                                                    selected_value={this.state.clientDataCreate.name} fieldStyle="field-box bg-plane grey-color"
                                                                                    placeholder="Enter Name" handleOnChangeValue={this.handleClientChange("name").bind(this)}
                                                                                    is_mandatory="yes"
                                                                                    field_validations={["v_required"]}
                                                                                />
                                                                            </Grid>
                                                                        </Grid>
                                                                        <Grid container lg={12} md={12}>
                                                                            <Grid item lg={5} md={8}>

                                                                                <InputField
                                                                                    label="Email" type="email" name="email" fieldStyle="field-box bg-plane grey-color"
                                                                                    placeholder="design@assurekit.com"
                                                                                    selected_value={this.state.clientDataCreate.email}
                                                                                    handleOnChangeValue={this.handleClientChange("email").bind(this)}
                                                                                    is_mandatory="yes"
                                                                                    field_validations={["v_email"]}
                                                                                />
                                                                            </Grid>
                                                                            <Grid item lg={5} md={8}>
                                                                                <PhoneField label="Phone" type="text" name="client_phone"
                                                                                    default_country_code="IN"
                                                                                    fieldStyle="field-box bg-plane grey-color" placeholder="+91-XXXXXXXXXX"
                                                                                    selected_value={this.state.clientDataCreate.contact_no}
                                                                                    handleOnChangeValue={this.handleClientChange("contact_no").bind(this)}
                                                                                    is_mandatory="yes"
                                                                                    field_validations={["v_valid_phone_number"]}
                                                                                />
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid>

                                                                    {/* Multitenant specific fields */}
                                                                    {/* Tenant name field */}
                                                                    <Grid container lg={12} md={12}>
                                                                        <Grid item lg={5} md={8}>
                                                                            <InputField
                                                                                label="Tenant name"
                                                                                type="text"
                                                                                name="tenantName"
                                                                                fieldStyle="field-box bg-plane grey-color"
                                                                                placeholder="Tenant"
                                                                                selected_value={this.state.clientDataCreate.tenantName}
                                                                                handleOnChangeValue={this.handleClientChange("tenantName").bind(this)}
                                                                                is_mandatory="yes"
                                                                                field_validations={["v_required"]}
                                                                            />
                                                                        </Grid>
                                                                    </Grid>

                                                                    {/* Database options radio input */}
                                                                    <Grid container lg={12} md={12}>
                                                                        <Grid item lg={5} md={8}>
                                                                            <RadioField
                                                                                radioGroupLabel="Database_Option"
                                                                                radioData={databaseOptions}
                                                                                label="Database Option"
                                                                                radioFieldStyle="field-inline"
                                                                                default_value="individual"
                                                                                selected_value={this.state.clientDataCreate.databaseOption}
                                                                                radioChangeHandle={this.handleClientChange("databaseOption").bind(this)}
                                                                            />
                                                                        </Grid>
                                                                    </Grid>

                                                                    {/* Mulitenant configuration information that is displayed only if "Use Tenant DB option is selected" */}
                                                                    {this.state.clientDataCreate.databaseOption === "client" &&
                                                                        <>
                                                                            <Grid container lg={12} md={12}>
                                                                                <Grid item lg={5} md={8}>
                                                                                    <InputField
                                                                                        label="Database Name"
                                                                                        type="text"
                                                                                        name="databaseName"
                                                                                        fieldStyle="field-box bg-plane grey-color"
                                                                                        placeholder="assurekit_tenant"
                                                                                        selected_value={this.state.clientDataCreate.databaseName}
                                                                                        handleOnChangeValue={this.handleClientChange("databaseName").bind(this)}
                                                                                    />
                                                                                </Grid>
                                                                            </Grid>
                                                                            {/* Tenant database host and port fields. */}
                                                                            <Grid container lg={12} md={12}>
                                                                                <Grid item lg={5} md={8}>
                                                                                    <InputField
                                                                                        label="Database Host"
                                                                                        type="text"
                                                                                        name="databaseHost"
                                                                                        fieldStyle="field-box bg-plane grey-color"
                                                                                        placeholder="yourhost.com"
                                                                                        selected_value={this.state.clientDataCreate.databaseHost}
                                                                                        handleOnChangeValue={this.handleClientChange("databaseHost").bind(this)}
                                                                                    // is_mandatory="yes"
                                                                                    // field_validations={["v_required"]}
                                                                                    />
                                                                                </Grid>

                                                                                <Grid item lg={5} md={8}>
                                                                                    <InputField
                                                                                        label="Database Port"
                                                                                        type="number"
                                                                                        name="databasePort"
                                                                                        fieldStyle="field-box bg-plane grey-color"
                                                                                        placeholder="3306"
                                                                                        selected_value={this.state.clientDataCreate.databasePort}
                                                                                        handleOnChangeValue={this.handleClientChange("databasePort").bind(this)}
                                                                                    />
                                                                                </Grid>
                                                                            </Grid>

                                                                            {/* Database username and password fields. */}
                                                                            <Grid container lg={12} md={12}>
                                                                                <Grid item lg={5} md={8}>
                                                                                    <InputField
                                                                                        label="Database Username"
                                                                                        type="text"
                                                                                        name="databaseUsername"
                                                                                        fieldStyle="field-box bg-plane grey-color"
                                                                                        placeholder="mysql_user"
                                                                                        selected_value={this.state.clientDataCreate.databaseUsername}
                                                                                        handleOnChangeValue={this.handleClientChange("databaseUsername").bind(this)}
                                                                                    />
                                                                                </Grid>

                                                                                <Grid item lg={5} md={8}>
                                                                                    <InputField
                                                                                        label="Database Password"
                                                                                        type="password"
                                                                                        name="databasePassword"
                                                                                        fieldStyle="field-box bg-plane grey-color"
                                                                                        placeholder="$trongPAss12"
                                                                                        selected_value={this.state.clientDataCreate.databasePassword}
                                                                                        handleOnChangeValue={this.handleClientChange("databasePassword").bind(this)}
                                                                                    />
                                                                                </Grid>
                                                                            </Grid>

                                                                            {/* Test connection button */}
                                                                            <Grid container lg={12} md={12}>
                                                                                <Grid item lg={5} md={8}>
                                                                                    <div className="form-row">
                                                                                        <Fab
                                                                                            variant="extended"
                                                                                            size="large"
                                                                                            className="btn btn-large btn-plane primary-color"
                                                                                            onClick={this.testDatabaseConnection.bind(this)}
                                                                                        >
                                                                                            Test Connection
                                                                                        </Fab>
                                                                                    </div>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </>
                                                                    }

                                                                    <Grid container lg={12} md={12}>
                                                                        <Grid item lg={5} md={8}>
                                                                            <RadioField
                                                                                radioGroupLabel="Advanced_Database_Option"
                                                                                radioData={SHOW_ADVANCED_CONFIG_OPTION}
                                                                                label="Advanced Database Option"
                                                                                radioFieldStyle="field-inline"
                                                                                default_value="default_config"
                                                                                selected_value={this.state.clientDataCreate.advancedDatabaseConfig}
                                                                                radioChangeHandle={this.handleClientChange("advancedDatabaseConfig").bind(this)}
                                                                            />
                                                                        </Grid>
                                                                    </Grid>

                                                                    {this.state.clientDataCreate.advancedDatabaseConfig === "custom_config" &&
                                                                        <>

                                                                            <Grid container lg={12} md={12}>
                                                                                <Grid item lg={5} md={8}>
                                                                                    <InputField
                                                                                        label="Minimum Idle Connection"
                                                                                        type="number"
                                                                                        name="minimum_idle"
                                                                                        selected_value={this.state.clientDataCreate.advancedConfig.minimum_idle}
                                                                                        fieldStyle="field-box bg-plane grey-color"
                                                                                        placeholder="Enter"
                                                                                        handleOnChangeValue={this.handleClientChange("minimum_idle").bind(this)}
                                                                                        is_mandatory="yes"
                                                                                        field_validations={["v_required", "v_number_only"]}
                                                                                    />
                                                                                </Grid>
                                                                            </Grid>
                                                                            <Grid container lg={12} md={12}>
                                                                                <Grid item lg={5} md={8}>
                                                                                    <InputField
                                                                                        label="Maximum Pool Size"
                                                                                        type="number"
                                                                                        name="maximum_pool_size"
                                                                                        selected_value={this.state.clientDataCreate.advancedConfig.maximum_pool_size}
                                                                                        fieldStyle="field-box bg-plane grey-color"
                                                                                        placeholder="Enter"
                                                                                        handleOnChangeValue={this.handleClientChange("maximum_pool_size").bind(this)}
                                                                                        is_mandatory="yes"
                                                                                        field_validations={["v_required", "v_number_only"]}
                                                                                    />
                                                                                </Grid>

                                                                                <Grid item lg={5} md={8}>
                                                                                    <InputField
                                                                                        label="Idle Timeout"
                                                                                        type="number"
                                                                                        name="idle_timeout"
                                                                                        selected_value={this.state.clientDataCreate.advancedConfig.idle_timeout}
                                                                                        fieldStyle="field-box bg-plane grey-color"
                                                                                        placeholder="Enter"
                                                                                        handleOnChangeValue={this.handleClientChange("idle_timeout").bind(this)}
                                                                                        is_mandatory="yes"
                                                                                        field_validations={["v_required", "v_number_only"]}
                                                                                    />
                                                                                </Grid>
                                                                            </Grid>
                                                                            <Grid container lg={12} md={12}>
                                                                                <Grid item lg={5} md={8}>
                                                                                    <InputField
                                                                                        label="Connection Max Lifetime"
                                                                                        type="number"
                                                                                        name="max_lifetime"
                                                                                        selected_value={this.state.clientDataCreate.advancedConfig.max_lifetime}
                                                                                        fieldStyle="field-box bg-plane grey-color"
                                                                                        placeholder="Enter"
                                                                                        handleOnChangeValue={this.handleClientChange("max_lifetime").bind(this)}
                                                                                        is_mandatory="yes"
                                                                                        field_validations={["v_required", "v_number_only"]}
                                                                                    />
                                                                                </Grid>

                                                                                <Grid item lg={5} md={8}>
                                                                                    <InputField
                                                                                        label="Connection Timeout"
                                                                                        type="number"
                                                                                        name="connection_timeout"
                                                                                        selected_value={this.state.clientDataCreate.advancedConfig.connection_timeout}
                                                                                        fieldStyle="field-box bg-plane grey-color"
                                                                                        placeholder="Enter"
                                                                                        handleOnChangeValue={this.handleClientChange("connection_timeout").bind(this)}
                                                                                        is_mandatory="yes"
                                                                                        field_validations={["v_required", "v_number_only"]}
                                                                                    />
                                                                                </Grid>
                                                                            </Grid>

                                                                            <Grid container lg={12} md={12}>
                                                                                <Grid item lg={5} md={8}>
                                                                                    <InputField
                                                                                        label="Keep Alive Time"
                                                                                        type="number"
                                                                                        name="keepalive_time"
                                                                                        selected_value={this.state.clientDataCreate.advancedConfig.keepalive_time}
                                                                                        fieldStyle="field-box bg-plane grey-color"
                                                                                        placeholder="Enter"
                                                                                        handleOnChangeValue={this.handleClientChange("keepalive_time").bind(this)}
                                                                                        is_mandatory="yes"
                                                                                        field_validations={["v_required", "v_number_only"]}
                                                                                    />
                                                                                </Grid>

                                                                                <Grid item lg={5} md={8}>
                                                                                    <RadioField
                                                                                        radioGroupLabel="Verify Server Certificate"
                                                                                        radioData={TRUE_OR_FALSE_OPTION}
                                                                                        label="Verify Server Certificate"
                                                                                        radioFieldStyle="field-inline"
                                                                                        default_value={this.state.clientDataCreate.advancedConfig.verify_server_certificate}
                                                                                        selected_value={this.state.clientDataCreate.advancedConfig.verify_server_certificate}
                                                                                        radioChangeHandle={this.handleClientChange("verify_server_certificate").bind(this)}
                                                                                    />
                                                                                </Grid>
                                                                            </Grid>
                                                                            <Grid container lg={12} md={12}>
                                                                                <Grid item lg={5} md={8}>
                                                                                    <RadioField
                                                                                        radioGroupLabel="Use SSL"
                                                                                        radioData={TRUE_OR_FALSE_OPTION}
                                                                                        label="Use SSL"
                                                                                        radioFieldStyle="field-inline"
                                                                                        default_value={this.state.clientDataCreate.advancedConfig.use_ssl}
                                                                                        selected_value={this.state.clientDataCreate.advancedConfig.use_ssl}
                                                                                        radioChangeHandle={this.handleClientChange("use_ssl").bind(this)}
                                                                                    />
                                                                                </Grid>

                                                                                <Grid item lg={5} md={8}>
                                                                                    <RadioField
                                                                                        radioGroupLabel="Require SSL"
                                                                                        radioData={TRUE_OR_FALSE_OPTION}
                                                                                        label="Require SSL"
                                                                                        radioFieldStyle="field-inline"
                                                                                        default_value={this.state.clientDataCreate.advancedConfig.require_ssl}
                                                                                        selected_value={this.state.clientDataCreate.advancedConfig.require_ssl}
                                                                                        radioChangeHandle={this.handleClientChange("require_ssl").bind(this)}
                                                                                    />
                                                                                </Grid>
                                                                            </Grid>
                                                                            <Grid container lg={12} md={12}>
                                                                                <Grid item lg={5} md={8}>
                                                                                    <InputField
                                                                                        label="Trust certificate key store url"
                                                                                        type="text"
                                                                                        name="trust_certificate_key_store_url"
                                                                                        selected_value={this.state.clientDataCreate.advancedConfig.trust_certificate_key_store_url}
                                                                                        fieldStyle="field-box bg-plane grey-color"
                                                                                        placeholder="Enter"
                                                                                        handleOnChangeValue={this.handleClientChange("trust_certificate_key_store_url").bind(this)}
                                                                                    />
                                                                                </Grid>

                                                                                <Grid item lg={5} md={8}>
                                                                                    <InputField
                                                                                        label="Trust certificate key store password"
                                                                                        type="password"
                                                                                        name="trust_certificate_key_store_password"
                                                                                        selected_value={this.state.clientDataCreate.advancedConfig.trust_certificate_key_store_password}
                                                                                        fieldStyle="field-box bg-plane grey-color"
                                                                                        placeholder="Enter"
                                                                                        handleOnChangeValue={this.handleClientChange("trust_certificate_key_store_password").bind(this)}
                                                                                    />
                                                                                </Grid>

                                                                            </Grid>
                                                                        </>
                                                                    }

                                                                </div>
                                                            </div>
                                                        </Scrollbars>
                                                    </div>
                                                    <div className="form-row workflow-dialog-footer">
                                                        <div className="btn-wrap no-border">
                                                            <Fab variant="extended" size="large" className="btn btn-large btn-plane primary-color" onClick={this.createClientData.bind(this)}>
                                                                Create Client
                                                            </Fab>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </div>
                                </div>
                            </section>
                        </Dialog>)}

                    <Dialog open={this.state.openInvalidTypeDialog} onClose={event => this.handleClose("openInvalidTypeDialog")} TransitionComponent={Transition} >
                        <div className="card-block">
                            <div className="card-header border-bottom" style={{ color: 'white', backgroundColor: 'orange', paddingLeft: '5px', paddingBottom: '5px', paddingRight: '0px' }}>
                                <div className="sub-title">
                                    <h2>Alert!</h2>
                                </div>
                            </div>
                            <div className="card-body" style={{ paddingTop: '20px' }}>
                                <Grid container>
                                    <Grid item md={12}>
                                        <p style={{ whiteSpace: 'break-spaces' }}><br /> Invalid file format, please upload a file with one of the following formats
                                            (.pdf .doc .docx)</p>
                                        <div className="workflow-dialog-forms form-builder-dashboard">
                                            <div className="form-row workflow-dialog-footer">
                                                <div className="btn-wrap no-border">

                                                    <Fab variant="extended" className="btn btn-large" style={{ height: '35px', paddingLeft: '0px', paddingRight: '0px', border: '1px solid orange', color: 'orange', backgroundColor: 'white', float: 'right' }} onClick={event => this.handleClose("openInvalidTypeDialog")}>
                                                        Done
                                                    </Fab>
                                                </div>
                                            </div>
                                        </div>
                                    </Grid>
                                    <Grid item md={3}>
                                    </Grid>
                                </Grid>
                            </div>
                        </div>
                    </Dialog>

                </main>
            </div>
        )
    }
}

export default CreateApplication;
