
import * as Constants from './constants';

function separateOperation(script, formFieldData, userData, value, userDataRecord, props) {
    console.log("script123", script, "formFieldData", formFieldData, "userData", userData, "value", value, "userDataRecord", userDataRecord, props);
    if (script.includes("contains")) {
        script = script.replace(/contains/g, "includes");
    }
    let criteriaList = [];
    let andCriteriaList = script.split("&&");
    andCriteriaList.forEach((andCriteria, i) => {
        let orCriteria = andCriteria.split("||");
        if (orCriteria.length > 0) {
            criteriaList = criteriaList.concat(orOperation(andCriteria, (i === andCriteriaList.length - 1), formFieldData, userData, value, userDataRecord, props));
        } else {
            if (i !== andCriteriaList.length - 1) {
                criteriaList.push(evaluateCriteria(andCriteria, formFieldData, userData, value) + " && ");
            } else {
                criteriaList.push(evaluateCriteria(andCriteria, formFieldData, userData, value));
            }
        }
    })
    console.log("criteriaList123", criteriaList.join(""));
    return criteriaList.join("");
}

function orOperation(criteria, isLast, formFieldData, userData, value, userDataRecord, props) {
    let resOrCritera = [];
    let orCriteriaList = criteria.split("||");
    orCriteriaList.forEach((orCriteria, i) => {
        if (i !== orCriteriaList.length - 1) {
            resOrCritera.push(evaluateCriteria(orCriteria, formFieldData, userData, value, userDataRecord, props) + " || ");
        }
        else if (!isLast) {
            resOrCritera.push(evaluateCriteria(orCriteria, formFieldData, userData, value, userDataRecord, props) + " && ");
        } else {
            resOrCritera.push(evaluateCriteria(orCriteria, formFieldData, userData, value, userDataRecord, props));
        }
    })
    return resOrCritera;
}

function getFieldValue(id, formFieldData, userData) {
    console.log("getfieldvalue", id, "formFieldData", formFieldData, "userData", userData);
    //let field = formFieldData.filter( fieldData => (fieldData.id === id))[0];
    let field = null;
    formFieldData.forEach(fieldData => {
        if (!field) {
            if (fieldData.group_field) {
                console.log("Inside Group");
                if (fieldData.children && fieldData.children.length > 0) {
                    field = fieldData.children.filter(field => (field.id === id))[0];
                    return;
                }
            }
            else {
                if (fieldData.id === id) {
                    console.log("Inside id");
                    field = fieldData;
                    return;
                }
            }
        }
    })
    let formUserData = userData[field.data_pagination_id - 1];

    //This code is used to fetch Coded value
    switch (field.field_type) {
        case Constants.FieldIds.F_RADIO_GROUP.code:
        case Constants.FieldIds.F_SINGLE_DROPDOWN_GROUP.code:
            if (formUserData["data_attr" + field.data_ref_id] === "") {
                return "''";
            }
            let fieldOptionCoded;
            field.field_option_list.forEach(list => {
                if (list.field_option_value === `${formUserData["data_attr" + field.data_ref_id]}`) {
                    if (list.field_option_coded) {
                        if (isNaN(list.field_option_coded)) {
                            fieldOptionCoded = `"${list.field_option_coded}"`;
                        }
                        else {
                            fieldOptionCoded = list.field_option_coded;
                        }
                    } else {
                        fieldOptionCoded = `"${formUserData["data_attr" + field.data_ref_id]}"`;
                    }
                }
            })
            return fieldOptionCoded;
        case Constants.FieldIds.F_DATE.code:
            if (formUserData["data_attr" + field.data_ref_id] === "") {
                return "''";
            }
            return `"${new Date(formUserData["data_attr" + field.data_ref_id]).toISOString()}"`;


        case Constants.FieldIds.F_TIME.code:
            if (formUserData["data_attr" + field.data_ref_id] === "") {
                return "''";
            }

            let value = new Date(formUserData["data_attr" + field.data_ref_id]);
            value.setDate(1);
            value.setMonth(1);
            value.setFullYear(1970);
            value.setSeconds(0, 0);

            return `"${value.toISOString()}"`;

        case Constants.FieldIds.F_DATE_TIME.code:
            if (formUserData["data_attr" + field.data_ref_id] === "") {
                return "''";
            }
            let valueDate = new Date(formUserData["data_attr" + field.data_ref_id]);
            valueDate.setSeconds(0, 0);

            return `"${valueDate.toISOString()}"`;

        case Constants.FieldIds.F_NUMBER_TXT.code:
            if (formUserData["data_attr" + field.data_ref_id] === "") {
                return 0;
            }
            return formUserData["data_attr" + field.data_ref_id];
        default:
            // if(formUserData["data_attr"+field.data_ref_id] === ""){
            //     return null;
            // }
            return `"${formUserData["data_attr" + field.data_ref_id]}"`;

    }


}

function getNumericFieldValue(id, formFieldData, userData) {
    let field = formFieldData.filter(fieldData => (fieldData.id === id))[0];
    let formUserData = userData[field.data_pagination_id - 1];
    let value;
    if (formUserData["data_attr" + field.data_ref_id]) {
        if (isNaN(formUserData["data_attr" + field.data_ref_id])) {
            value = 0;
        } else {
            value = formUserData["data_attr" + field.data_ref_id];
        }
    } else {
        value = 0;
    }
    return value;
}

export function evaluateFieldCondition(script, formFieldData, userData) {
    formFieldData.forEach(formField => {
        if (script && script.includes(formField.field_key)) {
            let fieldUserData;
            switch (formField.field_type) {
                case Constants.FieldIds.F_NUMBER_TXT.code: fieldUserData = getNumericFieldValue(formField.id, formFieldData, userData); break;
                default: fieldUserData = getFieldValue(formField.id, formFieldData, userData); break;
            }
            script = script.split(formField.field_key).join(`${fieldUserData}`)
        }
    })
    return script;
}

export function evaluatecondition(script, formFieldData, userData, value, userDataRecord, props) {
    return separateOperation(script, formFieldData, userData, value, userDataRecord, props);
}

function evaluateCriteria(criteria, formFieldData, userData, value, userDataRecord, props) {
    console.log("criteria2334", criteria, "formFieldData", formFieldData, "userData", userData, "value", value, "userDataRecord", userDataRecord, "props", props);
    let spiltOperents = criteria.replace(".", "Æ").split("Æ");
    console.log("spiltOperents888", spiltOperents);
    let C = spiltOperents[1];
    let operationValue = getOperationAndValue(C);
    let E = "";
    if (userDataRecord && userDataRecord !== undefined) {
        if (formFieldData === null) {
            E = '"' + getProperValueChildFields(spiltOperents[0], userDataRecord, value, props) + '"';
            //E = '"'+value+'"';
        }

    } else {
        if (formFieldData === null) {
            if (props.field_type === "f-number-txt") {
                // C = C.replace('("', '(');
                // C = C.replace('")',')');
                if (value === "") {
                    E = '"' + value + '"';
                } else {
                    E = value;
                }

            } else {
                //For direct Value
                E = '"' + value + '"';
            }



        } else if (operationValue[0] !== Constants.FIELD_OPERATION.CLICKED) {
            E = getFieldValue(spiltOperents[0].trim(), formFieldData, userData);
        } else {
            //For click event
            E = spiltOperents[0].trim();
        }
    }
    return generateFormula(E, operationValue[0], operationValue[1]);

}

function getProperValueChildFields(spiltOperents, userDataRecord, value, props) {
    let { formData, userFormData } = userDataRecord;
    let dataPag = null;
    let dataRef = null;

    if (spiltOperents.trimLeft() === props.id) {
        return value;

    } else {
        formData.form_field_list.forEach(field => {
            if (field.group_field) {
                field.children.forEach(child => {
                    if (child.id == spiltOperents.trimLeft()) {
                        dataPag = child.data_pagination_id;
                        dataRef = child.data_ref_id;
                    }
                })
            }
        })

        let userDataValue = userFormData[dataPag - 1];
        return userDataValue["data_attr" + dataRef];
    }

}

function getOperationAndValue(operationSet) {
    let operationSplit = operationSet.replace("(", "Æ").split("Æ");
    let operation = operationSplit[0];
    let value = "(" + operationSplit[1];
    return [operation, value];
}

function generateFormula(K, O, V) {
    console.log("K", K, 'O', O, "V", V);
    switch (O) {
        case Constants.FIELD_OPERATION.EQUALS: return (K + "===" + V);
        case Constants.FIELD_OPERATION.NOT_EQUALS: return (K + "!==" + V);
        case Constants.FIELD_OPERATION.CONTAIN: return (K + ".includes" + V);
        case Constants.FIELD_OPERATION.DOES_NOT_CONTAIN: return ("!" + K + ".includes" + V);
        case Constants.FIELD_OPERATION.IS_NULL: return ("(" + K + "==='' || " + K + "=== null" + ")");
        //case Constants.FIELD_OPERATION.IS_NOT_NULL: return ( "(" + K + "!=='' || " + K + "!== null" + ")");
        case Constants.FIELD_OPERATION.IS_NOT_NULL: return ("(" + K + "!== ''" + ")");
        case Constants.FIELD_OPERATION.GREATER_THAN: return (K + ">" + V);
        case Constants.FIELD_OPERATION.LESS_THAN: return ("(" + K + "!== '' && " + K + "!== null &&" + K + "<" + V + ")");
        case Constants.FIELD_OPERATION.CLICKED: return (true);
        default: return (K + "." + O + V);
    }
}

function checkForChildFields(field, type, value) {
    if (field.group_field) {
        field.children.forEach(child => {
            child[type] = value;
        })
    }
}


export function actionPerformer(action, field, value) {
    if (field) {
        field.used = true;
    }

    switch (action) {
        case Constants.FIELD_ACTIONS.HIDE: field.is_hidden = "yes"; checkForChildFields(field, "is_hidden", "yes"); break;
        case Constants.FIELD_ACTIONS.SHOW: field.is_hidden = "no"; checkForChildFields(field, "is_hidden", "no"); break;
        case Constants.FIELD_ACTIONS.ENABLE: field.is_disable = "no"; checkForChildFields(field, "is_disable", "no"); break;
        case Constants.FIELD_ACTIONS.DISABLE: field.is_disable = "yes"; checkForChildFields(field, "is_disable", "yes"); break;
        case Constants.FIELD_ACTIONS.SET:
            field.selected_value = value;
            switch (field.field_type) {
                case Constants.FieldIds.F_RADIO_GROUP.code:
                case Constants.FieldIds.F_SINGLE_DROPDOWN_GROUP.code:
                    field.field_option_list.forEach(option => {
                        if (option.field_option_label === value) {
                            option.checked = true;
                            field.selected_value = option.field_option_value;
                        }
                        else {
                            option.checked = false;
                        }
                    })
                    break;
                case Constants.FieldIds.F_CHECKBOX_GROUP.code:
                case Constants.FieldIds.F_MULTIPLE_DROPDOWN_GROUP.code: break;
            }
            break;
        default: break;
    }
}

export function actionResolver(action, field) {
    if (field && field.used) {
        return;
    }
    switch (action) {
        case Constants.FIELD_ACTIONS.HIDE: field.is_hidden = "no"; checkForChildFields(field, "is_hidden", "no"); break;
        case Constants.FIELD_ACTIONS.SHOW: field.is_hidden = "yes"; checkForChildFields(field, "is_hidden", "yes"); break;
        case Constants.FIELD_ACTIONS.ENABLE: field.is_disable = "yes"; checkForChildFields(field, "is_disable", "yes"); break;
        case Constants.FIELD_ACTIONS.DISABLE: field.is_disable = "no"; checkForChildFields(field, "is_disable", "no"); break;
        case Constants.FIELD_ACTIONS.SET: //field.selected_value = null; break;//removing this for error
        default: break;
    }
}