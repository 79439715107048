import React, { Component } from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputLabel from '@material-ui/core/InputLabel';
import Description from '../validators/descriptions';
import MandatoryValidator from '../validators/mandatory-validator';

//Validation
import * as ErrorValidation from '../validators/error-validator';

//Custom 
import ErrorMessage from '../common/error-message';

class CheckBoxField extends Component {

    /**
     * <div className="checkbox-group">
            <CheckBoxField
                checkData={checkDataList}
                checkChangeHandle={this.handleCustomCheckChangeEvent}
            /> 
        </div> 
     */
    state = {
        errorStates: [],
    }

    handleChange = i => event => {
        let checkDataList = this.props.checkData;
        let dataList = [];
        let dataStr = '';
        checkDataList[i].checked = event.target.checked;
        this.setState({ checkData: checkDataList });

        checkDataList.forEach(list => {
            if (list.checked) {
                dataList.push(list.field_option_value)
            }
            else {
                if (dataList.includes(list.field_option_value)) {
                    var index = dataList.indexOf(list.field_option_value);
                    dataList.splice(index, 1);
                }
            }
        })

        dataStr = dataList.join();

        let errors = ErrorValidation.updateErrorMessage(dataStr, this.props.field_validations, this.props.condition_script, this.props.error_message);
        this.setState({ errorStates: errors, checkData: checkDataList });

        this.props.checkChangeHandle(dataStr, errors);
    }

    render() {

        const { label, instruction, is_mandatory, defaultLabel, fieldStyle, checkboxFieldStyle, customStyle, checkData } = this.props;
        let { errorStates } = this.state;
        let defaultFieldStyle = "field-box";
        let defCheckboxFieldStyle = ""
        let isDisabled = this.props.is_disable === 'yes' ? true : false;
        // const defaultLabel = "Group Checkbox";
        console.log("checkData123", checkData);
        let checkContent = checkData.map((data, i) => {
            return (
                <FormControlLabel
                    key={i}
                    control={
                        <Checkbox
                            key={i}
                            value={data.field_option_value}
                            disabled={isDisabled}
                            checked={data.checked ? data.checked : false}
                            onChange={this.handleChange(i)}
                        />
                    }
                    label={data.field_option_label}
                />
            )
        });

        return (
            <div className={"form-row field-inline " + customStyle}>
                <div className={fieldStyle ? fieldStyle : defaultFieldStyle}>
                    <InputLabel
                        htmlFor="radio"
                        className="label">
                        {label ? label : defaultLabel}
                        <MandatoryValidator value={is_mandatory} />
                    </InputLabel>
                    <FormGroup row className={checkboxFieldStyle ? checkboxFieldStyle : defCheckboxFieldStyle}>
                        {checkContent}
                    </FormGroup>
                </div>
                <Description value={instruction} />
                <ErrorMessage errorStates={errorStates} name={label} />
            </div>
        )
    }
}
CheckBoxField.defaultProps = {
    customStyle: ""
}

export default CheckBoxField;