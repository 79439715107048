export function validDataSourceConfigDetails(dataSourceConfigDetails) {

    //validate all field(All fields are mandatory) 
    let invalidData = Object.keys(dataSourceConfigDetails).some((key) => {
        if (dataSourceConfigDetails[key] || dataSourceConfigDetails[key] === 0) {
            return false;
        }
        return true;
    })

    //return false if invalid data exist
    if (invalidData) {
        return false;
    }

    return validAdvancedConfigDetails(dataSourceConfigDetails.advanced_config)

}


export function validAdvancedConfigDetails(advanced_config) {

    //ssl field mandatory if use ssl is true
    let sslMandatoryField = ["trust_certificate_key_store_password", "trust_certificate_key_store_url"];

    //check advanced config
    let invalidData = Object.keys(advanced_config).some((key) => {
        //check ssl field mandatory field if use ssl is true
        if (sslMandatoryField.includes(key)) {
            if ((JSON.parse(advanced_config.verify_server_certificate)
                && advanced_config[key]) || !JSON.parse(advanced_config.verify_server_certificate)) {
                return false;
            }
        }

        if (advanced_config[key] || advanced_config[key] === 0) {
            return false;
        }

        return true;
    })

    //return false if invalid data exist
    return !invalidData;

}