//Utils
import * as ScriptEvaluator from '../../../utilities/script-evaluator'
import { REQUIRED_ERROR_MESSAGE } from '../../../utilities/constants'

export function requiredValidation(value) {
    let validState = { isValid: true };

    if (value === null || value === '' || value === undefined) {
        validState.isValid = false;
        validState.message = REQUIRED_ERROR_MESSAGE;
    }

    return validState;
}

export function conditionValidation(value, condition, errorMessage, userDataRecord, props) {
    let validState = {};
    console.log("value0009", value, "condition", condition, "errorMessage", errorMessage, "userDataRecord", userDataRecord, props);
    console.log("Waha", ScriptEvaluator.evaluatecondition(condition, null, null, value, userDataRecord, props));
    validState.isValid = !eval(ScriptEvaluator.evaluatecondition(condition, null, null, value, userDataRecord, props));

    validState.message = errorMessage;
    console.log("valley", validState);

    return validState;
}