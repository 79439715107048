import React, { Component } from 'react';

//Routes
import { withCookies } from 'react-cookie'
import { BrowserRouter as Router, Route, Redirect } from "react-router-dom";

//Scene
import FormBuilderScene from './scenes/form-builder-scene/form-builder-scene';
import FormPreviewScene from './scenes/form-preview-scene/form-preview-scene';
import Login from './scenes/login-scene/login';
import SetPassword from './scenes/set-password-scene/set-password';
import Components from './components/common/component';
import WorkflowComponent from './scenes/workflow-builder-scene/workflow-builder-scene';
import FormDashboard from './scenes/form-dashboard-scene/form-dashboard-scene';
import FormRecords from './scenes/form-records-scene/form-records-scene';
import WorkflowDashboard from './scenes/workflow-dashboard-scene/workflow-dashboard-scene';
import DashboardMaster from './scenes/dashboard-scene/dashboard-master-scene';
import FormBuilderView from './scenes/form-builder-scene/form-builder-view';
import ProgramDashboard from './scenes/program-dashboard-scene/pragram-dashboard';
import ClientDashboard from './scenes/client-dashboard-scene/client-dashboard';
import SectionDashboard from './scenes/section-dashboard-scene/section-dashboard';
import SectionBuilder from './scenes/section-builder-scene/section-builder-scene';
import UsersDashboard from './scenes/users-dashboard-scene/user-dashboard-scene';
import ProgramCreate from './scenes/program-dashboard-scene/program-create';
import DetailsView from './scenes/details-view-scene/details-view-scene';
import CreateApplication from './scenes/create-application-scene/create-application-scene';
import PolicyList from './scenes/policy-list-scene/policy-list';
import SettingDashboardScene from './scenes/setting-dashboard-scene/setting-dashboard-scene';
import SettingProfileScene from './scenes/setting-dashboard-scene/setting-profile-scene';
import PageError from './components/common/page-error';
import ProfileDashboard from './scenes/profile-dashboard-scene/profile-dashboard';
import ProgramClientDashboard from './scenes/program-client-dashboard-scene/program-client-dashboard';
import TeamMembersDashboard from './scenes/team-members-dashboard-scene/team-members-dashboard';
import ConfigDashboard from './scenes/config-dashboard-scene/config-dashboard';
import * as ClientApi from './api/client-api';

//Theme
import { MuiThemeProvider } from '@material-ui/core/styles';
import { createMuiTheme } from '@material-ui/core/styles';
import setPassword from './scenes/set-password-scene/set-password';

const theme = createMuiTheme({
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: '#0079fa',
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      light: '#e3f0fe',
      main: '#0044ff',
      // dark: will be calculated from palette.secondary.main,
      contrastText: '#0079fa',
    },
    // error: will use the default color
  },
});

let userData = {
  isAuthenticated: false,
  type: 0,
}

class App extends Component {

  state = {
    isAuthenticated: false,
    type: 0,
  }

  loginByCode = (code) => {
    if (code) {
      const codeData = { code: code };
      ClientApi.validateAdminClientCode(codeData).then(res => {
        sessionStorage.setItem("access_token", res.access_token);
        userData.isAuthenticated = true;
        let tokenPayload = parseJwt(res.access_token);
        userData.type = tokenPayload.type;
        this.setState({ isAuthenticated: true, type: tokenPayload.type });
      });
    }
  }

  componentWillMount() {
    let access_token = sessionStorage.getItem("access_token");
    //let type = sessionStorage.getItem("type");
    if (access_token != null) {
      userData.isAuthenticated = true;
      let tokenPayload = parseJwt(access_token);
      userData.type = tokenPayload.type;
      console.log("Inside APP", tokenPayload);
      this.setState({ isAuthenticated: true, type: tokenPayload.type });
    }

    const params = new URLSearchParams(window.location.search);
    if (!access_token && params.get("code")) {
      this.loginByCode(params.get("code"));
    }

  }

  handleLogin(access_token, type) {
    if (access_token != null) {
      sessionStorage.setItem("access_token", access_token);
      // sessionStorage.setItem("type", type);
      userData.isAuthenticated = true;
      //userData.type = type;
      this.setState({ isAuthenticated: true });
    }
  }

  render() {
    console.log("isAuthenticated123", this.state.isAuthenticated, "type", this.state.type);
    return (
      <Router>
        <MuiThemeProvider theme={theme}>
          <Route path="/" exact render={props => userData.isAuthenticated && userData.type === 0 ? <Redirect to={{ pathname: "/create-app", }} /> :
            new URLSearchParams(window.location.search).get("code") != null ? "" : <Login {...props} authEvent={this.handleLogin.bind(this)} />} />
          <Route path="/reset-token" render={props => <SetPassword {...props} />} />
          <ProtectedRoute path="/:clientId/:programkey/form/:formkey/builder" component={FormBuilderScene} isAuthenticated={userData.isAuthenticated} type={userData.type} />
          <ProtectedRoute path="/:clientId/:programkey/form/:formkey/preview" render={props => <FormBuilderView {...props} type="preview" />} isAuthenticated={userData.isAuthenticated} type={userData.type} />
          <PrivateRoute path="/:clientId/:programkey/form/:formkey/publish" component={FormBuilderView} isAuthenticated={userData.isAuthenticated} />
          <ProtectedRoute path="/:clientId/:programkey/form/:formkey/records" component={FormRecords} isAuthenticated={userData.isAuthenticated} type={userData.type} />
          <ProtectedRoute path="/:clientId/:programkey/forms-dashboard" component={FormDashboard} isAuthenticated={userData.isAuthenticated} type={userData.type} />
          <ProtectedRoute path="/components" component={Components} isAuthenticated={userData.isAuthenticated} type={userData.type} />
          <ProtectedRoute path="/:clientId/:programkey/workflow/:workfowkey/builder" component={WorkflowComponent} isAuthenticated={userData.isAuthenticated} type={userData.type} />
          <ProtectedRoute path="/:clientId/:programkey/workflows-dashboard" component={WorkflowDashboard} isAuthenticated={userData.isAuthenticated} type={userData.type} />
          <ProtectedRoute path="/:clientId/:programkey/section-dashboard" component={SectionDashboard} isAuthenticated={userData.isAuthenticated} type={userData.type} />
          <ProtectedRoute path="/:clientId/:programkey/users-dashboard" component={UsersDashboard} isAuthenticated={userData.isAuthenticated} type={userData.type} />
          <ProtectedRoute path="/:clientId/:programkey/section/:sectionkey/builder" component={SectionBuilder} isAuthenticated={userData.isAuthenticated} type={userData.type} />
          <ProtectedRoute path="/:clientId/:programkey/program-dashboard" component={ProgramDashboard} isAuthenticated={userData.isAuthenticated} type={userData.type} />
          <ProtectedRoute path="/:clientId/client-dashboard" component={ClientDashboard} isAuthenticated={userData.isAuthenticated} type={userData.type} />
          <ProtectedRoute path="/:clientId/team-members-dashboard" component={TeamMembersDashboard} isAuthenticated={userData.isAuthenticated} type={userData.type} />
          <ProtectedRoute path="/:clientId/profile-dashboard" component={ProfileDashboard} isAuthenticated={userData.isAuthenticated} type={userData.type} />
          <ProtectedRoute path="/:clientId/program-client-dashboard" component={ProgramClientDashboard} isAuthenticated={userData.isAuthenticated} type={userData.type} />
          <ProtectedRoute path="/:clientId/:programkey/config-dashboard" component={ConfigDashboard} isAuthenticated={userData.isAuthenticated} type={userData.type} />
          <PrivateRoute path="/:clientId/:programkey/dashboard" component={DashboardMaster} isAuthenticated={userData.isAuthenticated} />
          <PrivateRoute path="/form-build-view" component={FormPreviewScene} isAuthenticated={userData.isAuthenticated} />
          <PrivateRoute path="/program-create" component={ProgramCreate} isAuthenticated={userData.isAuthenticated} />
          <PrivateRoute path="/:clientId/:programkey/details-view/:processkey" component={DetailsView} isAuthenticated={userData.isAuthenticated} />
          <PrivateRoute path="/create-app" component={CreateApplication} isAuthenticated={userData.isAuthenticated} />
          <PrivateRoute path="/policy-list" component={PolicyList} isAuthenticated={userData.isAuthenticated} />
          <PrivateRoute path="/:clientId/:programkey/setting" component={SettingDashboardScene} isAuthenticated={userData.isAuthenticated} />
          <PrivateRoute path="/profile" component={SettingProfileScene} isAuthenticated={userData.isAuthenticated} />
          <PrivateRoute path="/error" component={PageError} isAuthenticated={userData.isAuthenticated} />
        </MuiThemeProvider>
      </Router >
    );
  }
}

export default withCookies(App);

function parseJwt(token) {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
};


function PrivateRoute({ component: Component, isAuthenticated, ...rest }) {
  return (
    <Route
      {...rest}
      render={props =>
        isAuthenticated && (
          <Component {...props} />
        )
        // : (
        //   <Redirect
        //     to={{
        //       //pathname: "/",
        //       state: { from: props.location }
        //     }}
        //   />
        // )
      }
    />
  );
}

function ProtectedRoute({ component: Component, isAuthenticated, type, ...rest }) {
  return (
    <Route
      {...rest}
      render={props =>
        (isAuthenticated && type == 0) ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/error",
              state: {
                from: props.location,
                userType: type
              }
            }}
          />
        )
      }
    />
  );
}
