import React, { Component } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import InputField from '../../components/fields/inputs';
import TextAreaField from '../../components/fields/textarea';
import Fab from '@material-ui/core/Fab';
import PhoneField from '../../components/fields/phone';
import Blank_Avatar from '../../assets/images/blank-avatar.png';
import BlankLogo from '../../assets/images/blank-logo.png';
import Avatar from '@material-ui/core/Avatar';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import SwitchButtonActions from '../../components/fields/switch-button-action';
import LoadingComponent from '../../components/common/loading-component';
import * as Constants from '../../utilities/constants';
import { connect } from 'react-redux';
import { changeClientProfileImage } from '../../actions/profileImageAction';
import { deleteProfileImage } from '../../actions/profileImageAction';
import PropTypes from 'prop-types';
import { fetchProfileImageClient } from '../../actions/profileImageAction';
import * as InputFieldValidation from '../../components/validators/field-validators/input-field-validation';

//Api
import * as ClientApi from '../../api/client-api';
import Slide from '@material-ui/core/Slide';
import CloseIcon from '../../assets/images/icon/close.svg'
import Dialog from '@material-ui/core/Dialog';
import Grid from '@material-ui/core/Grid';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

class ClientProfile extends Component {

    state = {
        active: false,
        buttonState: false,
        changeSetting: 'profile',
        clientDetails: {},
        open: false,
        clientDataCreate: {},
        intermediaryActive: false,
        isActive: false,
        loading: false,
        profileImageClient: true,
        openDialog: false

    }

    constructor(props) {
        super(props);

    }

    componentWillMount() {
        let clientId = this.props.clientId;
        ClientApi.getClientById(clientId).then(data => {

            this.getProfileImage();
            this.setState({ clientDetails: data });
            this.setState({ clientDataCreate: data });
            this.setState({ isActive: this.state.clientDataCreate.status == Constants.RECORD_STATUS.ACTIVE ? true : false })
            // this.setState({ intermediaryActive: this.state.clientDataCreate.intermediary })
        })
    }

    getProfileImage() {
        if (!this.props.profileImageClient) {
            this.props.fetchProfileImageClient();
        }
    }

    loaderChange() {
        this.setState({ loading: false });
    }

    handleToggleChange = (key) => () => {
        let { clientDataCreate } = this.state;
        if (key === "status") {
            this.setState({ isActive: !this.state.isActive });
            clientDataCreate[key] = !this.state.isActive ? "ACTIVE" : "INACTIVE";
        }
        // if (key === "intermediary") {
        //     this.setState({ intermediaryActive: this.state.intermediaryActive ? false : true });
        //     clientDataCreate[key] = !this.state.intermediaryActive;
        // }
    }

    handleProfilePictureChange(event) {
        let clientDetails = this.props.clientDetails;
        let allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i;

        if (!allowedExtensions.exec(event.target.files[0].name)) {
            this.setState({ openDialog: true });
        } else {
            let uploadPic = window.URL.createObjectURL(event.target.files[0]);
            const formDataValue = new FormData();
            formDataValue.append("file", event.target.files[0], event.target.files[0].name);
            this.setState({ loading: true }, () => this.props.changeClientProfileImage(formDataValue, clientDetails, uploadPic, this.loaderChange.bind(this)));
            this.setState({ profileImageClient: true });
        }
        event.target.value = null;
    }

    handleClientChange = (type) => (value) => {
        let { clientDataCreate } = this.state;
        clientDataCreate[type] = value;
        this.setState({ clientDataCreate: clientDataCreate });
    }

    handleChangeClientDataCreate(type, value) {
        let { clientDataCreate } = this.state;
        clientDataCreate[type] = value;
        this.setState({ clientDataCreate: clientDataCreate });
    }

    addImageId() {
        let clientId = this.props.clientId;
        ClientApi.getClientById(clientId).then(data => {

            this.handleChangeClientDataCreate("profile_image_id", data.profile_image_id)
            this.handleSaveClientDetails("update");
        })
    }

    handleSaveClientDetails(check) {
        let { clientDataCreate } = this.state;
        let clientId = this.props.clientId;
        let insuranceBrokerFieldIsValid = clientDataCreate.type === "insurance_broker" ? (clientDataCreate.intermediary_name && clientDataCreate.irdai_registration_no && clientDataCreate.valid_till && clientDataCreate.cin) : true;
        let emailValidation = InputFieldValidation.emailValidation(clientDataCreate.email)
        let phoneNumberValidation = InputFieldValidation.phoneNumberValidation(clientDataCreate.contact_no)

        if (clientDataCreate.display_name && clientDataCreate.name && clientDataCreate.email && emailValidation.isValid && clientDataCreate.contact_no && phoneNumberValidation.isValid && insuranceBrokerFieldIsValid) {
            ClientApi.updateClient(clientId, this.state.clientDataCreate).then(res => {
                if (check !== "delete") {
                    window.location = "/" + res.id + "/program-client-dashboard";
                }
            })
        }
    }

    deleteImage() {
        let { clientDataCreate } = this.state;
        clientDataCreate["profile_image_id"] = null;
        this.setState({ clientDataCreate: clientDataCreate });
        this.handleSaveClientDetails("delete");
        this.setState({ profileImageClient: false });
    }

    redirectToProgram() {
        let cancel = window.confirm(" Are you sure you want to cancel?");

        if (cancel) {
            window.location.reload();
        }
    }
    handleClose(key) {
        this.setState({ [key]: false });
    }


    render() {
        let { buttonState, active, selectedSidebar, clientDetails, intermediaryActive, isActive, profileImageClient } = this.state;
        return (
            <div className="drawer-tabs workflow-tabs-wrap">
                <div className="drawer-tabs-header border-bottom">
                    <div className="workflow-title">
                        <h5>Client Profile</h5>
                    </div>
                    <div className="workflow-tabs-search">
                        <SwitchButtonActions
                            label="Yes"
                            name="active"
                            fieldStyle="field-box bg-plane grey-color"
                            isChecked={isActive}
                            headerLabel="Active?"
                            withLabel={true}
                            onChange={this.handleToggleChange("status").bind(this)}
                            customStyle="m-0"
                        />
                    </div>
                </div>
                <div className="tab-container m-0">
                    <Scrollbars style={{ width: '100%', height: '100%' }}>
                        <div className="profile-sec-wrap">
                            <div className="profile-sec-body">
                                <section className="add-fields-wrap">
                                    <div className="profile-client-update">
                                        <div className="form-row add-field-row m-0 p-0">
                                            <label className="label">Logo</label>
                                            <div class="form-row-instruction"><h6 class="font-med">Tip: Upload high quality image</h6></div>
                                        </div>
                                        <div className="profile-sec-avtr">
                                            <Avatar alt="profile" className="avatar-img" src={this.props.profileImageClient && profileImageClient ? this.props.profileImageClient : BlankLogo} />
                                            <div className="extra-field">
                                                <div className="btn-wrap">
                                                    <Fab variant="extended" size="small" className="btn btn-small btn-plane white-color no-shadow" >
                                                        <InputLabel htmlFor="imageUpload"> Change </InputLabel>
                                                    </Fab>
                                                    <TextField
                                                        id="imageUpload"
                                                        className="field"
                                                        type="file"
                                                        placeholder="Photo"
                                                        onChange={(event) => this.handleProfilePictureChange(event)}
                                                    />
                                                    <Fab variant="extended" size="small" className="btn btn-small btn-plane white-color no-shadow" onClick={() => this.deleteImage()}>
                                                        Delete
                                                    </Fab>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="add-field-row">
                                        <div className="added-field-grp">
                                            <div className="added-field">
                                                <InputField
                                                    label="Display Name"
                                                    type="text"
                                                    name="name"
                                                    selected_value={this.state.clientDataCreate.display_name}
                                                    fieldStyle="field-box bg-plane grey-color"
                                                    placeholder="Enter"
                                                    handleOnChangeValue={this.handleClientChange("display_name").bind(this)}
                                                    is_mandatory="yes"
                                                    field_validations={["v_required"]}
                                                />
                                                <InputField
                                                    label="Client Name"
                                                    type="text"
                                                    name="name"
                                                    selected_value={this.state.clientDataCreate.name}
                                                    fieldStyle="field-box bg-plane grey-color"
                                                    placeholder="Enter"
                                                    handleOnChangeValue={this.handleClientChange("name").bind(this)}
                                                    is_mandatory="yes"
                                                    field_validations={["v_required"]}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="add-field-row p-0">
                                        <div className="added-field-grp added-single-grp">
                                            <div className="added-field">
                                                <TextAreaField
                                                    label="Address"
                                                    name="address"
                                                    rowmax="3"
                                                    selected_value={this.state.clientDataCreate.address}
                                                    fieldStyle="field-box bg-plane grey-color"
                                                    placeholder="XYZ building, kamla mils"
                                                    handleOnChangeValue={this.handleClientChange("address").bind(this)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="add-field-row">
                                        <div className="added-field-grp">
                                            <div className="added-field">
                                                <InputField
                                                    label="Primary Email"
                                                    type="email"
                                                    name="primary email"
                                                    fieldStyle="field-box bg-plane grey-color"
                                                    placeholder="design@assurekit.com"
                                                    selected_value={this.state.clientDataCreate.email}
                                                    handleOnChangeValue={this.handleClientChange("email").bind(this)}
                                                    is_mandatory="yes"
                                                    field_validations={["v_email"]}
                                                />
                                                <InputField
                                                    label="Alternate Email"
                                                    type="email"
                                                    name="alternate email"
                                                    fieldStyle="field-box bg-plane grey-color"
                                                    placeholder="design@assurekit.com"
                                                    selected_value={this.state.clientDataCreate.alternate_email}
                                                    handleOnChangeValue={this.handleClientChange("alternate_email").bind(this)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="add-field-row">
                                        <div className="added-field-grp">
                                            <div className="added-field">
                                                <PhoneField
                                                    label="Primary Phone"
                                                    type="text"
                                                    name="primary phone"
                                                    default_country_code="IN"
                                                    fieldStyle="field-box bg-plane grey-color"
                                                    placeholder="7208337428"
                                                    selected_value={this.state.clientDataCreate.contact_no}
                                                    handleOnChangeValue={this.handleClientChange("contact_no").bind(this)}
                                                    is_mandatory="yes"
                                                    field_validations={["v_valid_phone_number"]}
                                                />
                                                <PhoneField
                                                    label="Alternate Phone"
                                                    type="text"
                                                    name="client_phone"
                                                    default_country_code="IN"
                                                    fieldStyle="field-box bg-plane grey-color"
                                                    placeholder="7208337428"
                                                    selected_value={this.state.clientDataCreate.alternate_contact_no}
                                                    handleOnChangeValue={this.handleClientChange("alternate_contact_no").bind(this)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="add-field-row p-0">
                                        <div className="added-field-grp added-single-grp">
                                            <div className="added-field">
                                                <SwitchButtonActions
                                                    label="Yes"
                                                    name="intermediary"
                                                    fieldStyle="field-box bg-plane grey-color"
                                                    isChecked={intermediaryActive}
                                                    headerLabel="Is Intermediary?"
                                                    withLabel={true}
                                                    onChange={this.handleToggleChange("intermediary").bind(this)}
                                                />
                                            </div>
                                        </div>
                                    </div> */}
                                    {this.state.clientDataCreate.type === "insurance_broker" && <>
                                        <div className="add-field-row">
                                            <div className="added-field-grp">
                                                <div className="added-field">
                                                    <InputField
                                                        label="Intermediary Name"
                                                        type="text"
                                                        name="intermediary name"
                                                        fieldStyle="field-box bg-plane grey-color"
                                                        placeholder="Enter"
                                                        selected_value={this.state.clientDataCreate.intermediary_name}
                                                        handleOnChangeValue={this.handleClientChange("intermediary_name").bind(this)}
                                                        is_mandatory="yes"
                                                        field_validations={["v_required"]}
                                                    />
                                                    <InputField
                                                        label="IRDAI Registration No."
                                                        type="text"
                                                        name="irdai registration no"
                                                        fieldStyle="field-box bg-plane grey-color"
                                                        placeholder="Enter"
                                                        selected_value={this.state.clientDataCreate.irdai_registration_no}
                                                        handleOnChangeValue={this.handleClientChange("irdai_registration_no").bind(this)}
                                                        is_mandatory="yes"
                                                        field_validations={["v_required"]}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="add-field-row ">
                                            <div className="added-field-grp">
                                                <div className="added-field">
                                                    <InputField
                                                        label="Valid Till Date"
                                                        type="date"
                                                        name="valid till"
                                                        fieldStyle="field-box bg-plane grey-color"
                                                        placeholder="Enter"
                                                        selected_value={this.state.clientDataCreate.valid_till}
                                                        handleOnChangeValue={this.handleClientChange("valid_till").bind(this)}
                                                        is_mandatory="yes"
                                                        field_validations={["v_required"]}
                                                    />
                                                    <InputField
                                                        label="CIN"
                                                        type="text"
                                                        name="cin"
                                                        fieldStyle="field-box bg-plane grey-color"
                                                        placeholder="Enter"
                                                        selected_value={this.state.clientDataCreate.cin}
                                                        handleOnChangeValue={this.handleClientChange("cin").bind(this)}
                                                        is_mandatory="yes"
                                                        field_validations={["v_required"]}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </>}
                                </section>
                            </div>
                        </div>
                    </Scrollbars>
                </div>
                <div className="card-footer border-top p-l-0 p-r-0">
                    <div className="btn-wrap">
                        <Fab variant="extended" size="large" className="btn btn-large btn-plane primary-color" onClick={() => this.addImageId()}>
                            Save
                        </Fab>
                        <Fab variant="extended" size="large" className="btn btn-large btn-plane grey-color" onClick={() => this.redirectToProgram()}>
                            Cancel
                        </Fab>
                    </div>
                </div>
                <LoadingComponent loading={this.state.loading} />
                <Dialog open={this.state.openDialog} onClose={event => this.handleClose("openDialog")} TransitionComponent={Transition} >
                    <div className="card-block">
                        <div className="card-header border-bottom" style={{ color: 'white', backgroundColor: 'orange', paddingLeft: '5px', paddingBottom: '5px', paddingRight: '0px' }}>
                            <div className="sub-title">
                                <h2>Alert!</h2>
                            </div>
                        </div>
                        <div className="card-body" style={{ paddingTop: '20px' }}>
                            <Grid container>
                                <Grid item md={12}>
                                    <p style={{ whiteSpace: 'break-spaces' }}><br /> Invalid file format, please upload a file with one of the following formats
                                        (.jpg .jpeg .png .gif)</p>
                                    <div className="workflow-dialog-forms form-builder-dashboard">
                                        <div className="form-row workflow-dialog-footer">
                                            <div className="btn-wrap no-border">

                                                <Fab variant="extended" className="btn btn-large" style={{ height: '35px', paddingLeft: '0px', paddingRight: '0px', border: '1px solid orange', color: 'orange', backgroundColor: 'white', float: 'right' }} onClick={event => this.handleClose("openDialog")}>
                                                    Done
                                                </Fab>
                                            </div>
                                        </div>
                                    </div>
                                </Grid>
                                <Grid item md={3}>
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                </Dialog>
            </div>
        )
    }

}

ClientProfile.propTypes = {
    changeClientProfileImage: PropTypes.func.isRequired,
    deleteProfileImage: PropTypes.func.isRequired,
    fetchProfileImageClient: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
    profileImageClient: state.profileImage.profileImageClient,
});
export default connect(mapStateToProps, { changeClientProfileImage, deleteProfileImage, fetchProfileImageClient })(ClientProfile);
// export default ClientProfile;