import React, { Component } from 'react';
import SectionIcon from '../../assets/images/icon/sections-blue.svg';

class UrlTabActions extends Component {
    render() {
        let { formName, handleNameClick, withImg } = this.props;
        return (
            <section className="url-tab-sec">
                <div className="url-tab-wrap">
                    <div className="url-block">
                        {withImg ? (<img src={SectionIcon} alt="SectionIcon" className="icon" />) : ''} <a href="#" onClick={handleNameClick}>{formName}</a>
                    </div>
                </div>
            </section>
        );
    }
}

UrlTabActions.defaultProps = {
    withImg: true
}

export default UrlTabActions;

