import React, { Component } from 'react';

//dialog
import Dialog from '@material-ui/core/Dialog';
import CloseIcon from '../../assets/images/icon/close.svg';

//Custom component
import FormViewComponent from '../../components/form-view/form-view';
import LoadingComponent from '../../components/common/loading-component';
import { Scrollbars } from 'react-custom-scrollbars';

//API
import * as FormApi from '../../api/form-api';

class ActionFormMasterComponent extends Component {

    state = {
        openModal: false,
        loading: false,
        modalFormData: { form_field_list: [] },
    }

    handleClickOpen() {
        this.setState({ openModal: true });
    }

    handleClose() {
        this.setState({ openModal: false });
        this.setState({ modalFormData: { form_field_list: [] } });
    }

    afterDialogEventSave() {
        this.handleClose();
        this.setState({ modalFormData: { form_field_list: [] } });
        this.props.afterDialogEventSave();
    }

    getModalFormData(formkey) {
        let { processData } = this.props;
        const match = this.props.match;
        let processkey = match ? match.params.processkey : processData.process_id;

        this.handleClickOpen();
        this.setState({ loading: true });
        let formPromise = FormApi.getFormDataDetails(formkey, processkey);
        formPromise.then(formData => {
            this.setState({ modalFormData: formData, loading: false });
        });
    }

    handleDataChangeEvent(key, value, callback) {
        console.log("handleDataChangeEvent", key, value);
        if (key.split(".").length > 1) {
            let splitKeys = key.split(".");
            key = splitKeys[0];
            let valueObj = this.state[key];
            if (!valueObj) {
                valueObj = {};
            }
            valueObj[splitKeys[1]] = value;
            value = valueObj;
        }
        console.log(key, value);
        this.setState({ [key]: value }, callback);
    }

    renderModalForm() {
        let { processData } = this.props;
        let { loading, modalFormData } = this.state;
        return (
            <Dialog open={this.state.openModal} onClose={this.handleClose.bind(this)} aria-labelledby="simple-dialog-title" maxWidth="md">
                <section className="workflow-dialog workflow-dialog-large workflow-form-dialog">
                    <div className="card-block">
                        <div className="card-header border-bottom p-b-25">
                            <div className="sub-title">
                                <h2 className="bold">{modalFormData.form_name}</h2>
                            </div>
                            <div className="close-dialog">
                                <img src={CloseIcon} alt="CloseIcon" onClick={this.handleClose.bind(this)} ></img>
                            </div>
                        </div>
                        <LoadingComponent loading={loading} />
                    </div>
                    <div className="card-body p-t-25 p-b-45">
                        <div className="tabs-container tabs-container-scroll form-preview-dialog">
                            <Scrollbars style={{ width: '100%', height: '100%' }}>
                                <div className="tabs-container-scroll-wrap">
                                    {this.state.openModal &&
                                        <FormViewComponent
                                            formData={modalFormData}
                                            process_id={processData.process_id.toString()}
                                            afterSaveEvent={this.afterDialogEventSave.bind(this)}
                                            current_state={processData.current_state}
                                            current_sub_state={processData.current_sub_state}
                                            updateData={(value, callback) => this.handleDataChangeEvent("modalFormData", value, callback)}
                                        />
                                    }
                                </div>
                            </Scrollbars>
                        </div>
                    </div>
                </section>
            </Dialog>
        );
    }

    render() {
        let { processData } = this.props;
        return (
            <div>
                <FormViewComponent
                    formData={processData.current_form}
                    getModalFormData={this.getModalFormData.bind(this)}
                    process_id={processData.process_id.toString()}
                    afterSaveEvent={this.afterDialogEventSave.bind(this)}
                    current_state={processData.current_state}
                    current_sub_state={processData.current_sub_state}
                    updateData={(value, callback) => this.handleDataChangeEvent("processData.current_form", value, callback)}
                />
                {this.renderModalForm()}
            </div>
        )
    }
}

export default ActionFormMasterComponent;