import { ADMIN_SUBDOMAIN } from './constants';
import { ApiConstants } from "../api/common/api-constants";

export function getTenant() {
    return window.location.host.split(".")?.[0];
}

export function getTenantAssureGateUrl() {
    return window.location.protocol + '//' + getTenant() + '.' + ApiConstants.ASSUREGATE_HOST_URL;
}

export function isAdminSubdomain() {
    return getTenant() === ADMIN_SUBDOMAIN ? true : false;
}
