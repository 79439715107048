import React, { Component } from 'react';
/* import SettingFlowIllust from '../../assets/images/illustration/login-iso.svg'; */
import { Scrollbars } from 'react-custom-scrollbars';
import InputField from '../../components/fields/inputs';
import SelectField from '../../components/fields/select';
import TextAreaField from '../../components/fields/textarea';
import Fab from '@material-ui/core/Fab';
import PhoneField from '../../components/fields/phone';
import Avatar_img from '../../assets/images/avatar.jpg';
import Blank_Avatar from '../../assets/images/blank-avatar.png';
import Avatar from '@material-ui/core/Avatar';
import FacebookIcon from '../../assets/images/icon/facebook.svg';
import LinkedinIcon from '../../assets/images/icon/linkedin.svg';
import TwitterIcon from '../../assets/images/icon/twitter.svg';
import InputFields from '../fields/inputs';
import InputLabel from '@material-ui/core/InputLabel';
import * as UserApi from '../../api/user-api.jsx';
import TextField from '@material-ui/core/TextField';
import { InputAdornment } from '@material-ui/core';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Paper from '@material-ui/core/Paper';
import Draggable from 'react-draggable';
import { connect } from 'react-redux';
import { changeProfileImage } from '../../actions/profileImageAction';
import { deleteProfileImage } from '../../actions/profileImageAction';
import PropTypes from 'prop-types';
import LoadingComponent from '../../components/common/loading-component';


function PaperComponent(props) {
    return (
        <Draggable cancel={'[class*="MuiDialogContent-root"]'}>
            <Paper {...props} />
        </Draggable>
    );
}

class SettingProfile extends Component {

    state = {
        open: false,
        loading: false,
        openPasswordDialog: false,
        password: "",
        message: "The user's settings have been updated successfully.",
    }

    handleProfilePictureChange(event) {
        let { userDetails } = this.props;
        let uploadPic = window.URL.createObjectURL(event.target.files[0]);
        const formDataValue = new FormData();
        formDataValue.append("file", event.target.files[0], event.target.files[0].name);

        this.setState({ loading: true }, () => this.props.changeProfileImage(formDataValue, userDetails, uploadPic, this.loaderChange.bind(this)));


    }

    loaderChange() {
        this.setState({ loading: false });
    }


    handleDeleteClick() {
        let { userDetails } = this.props;
        this.props.deleteProfileImage(userDetails);
    }

    handleUserDetailsChange(key, value, errors) {
        let { userDetails } = this.props;
        userDetails[key] = value;
        this.props.updateUserDetails(userDetails);
    }

    handleSaveUserDetails() {
        let { userDetails } = this.props;
        console.log("userDetails", userDetails);
        // UserApi.updateUserDetails(userDetails.user_id, userDetails).then(res=>{
        //     this.setState({open : true});
        // })
        this.setState({ openPasswordDialog: true });
    }

    handleClose() {
        this.setState({ open: false });
    }

    renderUpdateSuccessfullDialog() {

        return (
            <Dialog
                open={this.state.open}
                onClose={() => this.handleClose()}
                PaperComponent={PaperComponent}
                aria-labelledby="draggable-dialog-title"
            >
                <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                    {/* Updated Successfully */}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {this.state.message}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={() => this.handleClose()} color="primary">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    renderPasswordConfirmationDialog() {

        return (
            <Dialog
                open={this.state.openPasswordDialog}
                onClose={() => this.handleClose()}
                PaperComponent={PaperComponent}
                aria-labelledby="draggable-dialog-title"
            >
                <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                    Save the Changes
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {/* The user's settings have been updated successfully. */}
                        <InputField
                            label="Confirm Password"
                            type={'password'}
                            placeholder="Enter"
                            name="login-password"
                            adornment="end"
                            fieldStyle="field-box field-adornment bg-plane grey-color"
                            // adornmentContent={
                            //     <IconButton
                            //         aria-label="toggle password visibility"
                            //         onClick={()=>this.handleClickShowPassword("showPassword")}
                            //     >
                            //         {showPassword ? <Visibility /> : <VisibilityOff />}
                            //     </IconButton>
                            // }
                            selected_value={this.state.password}
                            handleOnChangeValue={(value) => this.handlePasswordChange(value)}
                        />
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={() => this.handlePasswordSubmit()} color="primary">
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    handlePasswordSubmit() {
        let { password } = this.state;
        let { userDetails } = this.props;
        console.log("XOLSSL: ", password, "asdasd: ", userDetails);
        userDetails["check_password"] = password;
        UserApi.confirmPassword(userDetails).then(data => {
            console.log("data123: ", data);
            this.setState({ openPasswordDialog: false, message: "The user's settings have been updated successfully.", open: true, password: "" });
            // if(data){
            //     UserApi.updateUserDetails(userDetails.user_id, userDetails).then(res=>{
            //         this.setState({message: "The user's settings have been updated successfully.", open : true, password: ""});
            //     })
            // }else{
            //     this.setState({message: "Password is incorrect.", open:true, password: ""});
            // }
        })
            .catch(error => {
                let errorResponse = JSON.parse(error.response);
                console.log("qweqwe", errorResponse);
                this.setState({ openPasswordDialog: false, message: "Password is incorrect.", open: true, password: "" });
            })
    }

    handlePasswordChange(value) {
        console.log("value222", value);
        this.setState({ password: value });
    }

    render() {
        let { userDetails } = this.props;
        // let programType = [
        //     {
        //         field_option_label: "Individual",
        //         field_option_value: "individual",
        //     },
        //     {
        //         field_option_label: "Group",
        //         field_option_value: "group"
        //     }
        // ]
        let namePrefix = [
            {
                field_option_label: "Miss",
                field_option_value: "miss",
            },
            {
                field_option_label: "Mrs.",
                field_option_value: "mrs"
            },
            {
                field_option_label: "Mr.",
                field_option_value: "mr"
            },
        ]
        return (
            <div className="drawer-tabs workflow-tabs-wrap">
                <div className="tab-container m-0">
                    <Scrollbars style={{ width: '100%', height: '100%' }}>
                        <div className="profile-sec-wrap">
                            <div className="profile-sec-avtr">
                                <Avatar alt="profile" className="avatar-img" src={this.props.profileImage ? this.props.profileImage : Blank_Avatar} />
                                <div className="extra-field">
                                    <div className="btn-wrap">

                                        <Fab variant="extended" size="small" className="btn btn-small btn-plane white-color no-shadow" >
                                            <InputLabel htmlFor="imageUpload"> Change </InputLabel>
                                        </Fab>
                                        <TextField
                                            id="imageUpload"
                                            className="field"
                                            type="file"
                                            onChange={(event) => this.handleProfilePictureChange(event)}
                                            placeholder="Photo"
                                        >
                                        </TextField>

                                        <Fab variant="extended" size="small" className="btn btn-small btn-plane white-color no-shadow" onClick={() => this.handleDeleteClick()}>
                                            Delete
                                        </Fab>
                                    </div>
                                </div>
                            </div>
                            <div className="profile-sec-body">
                                <div className="drawer-tabs-header border-bottom">
                                    <div className="workflow-title">
                                        <h5>Tell us about yourself</h5>
                                    </div>
                                    <div className="workflow-tabs-search"></div>
                                </div>
                                <section className="add-fields-wrap">
                                    <div className="add-field-row">
                                        <div className="added-field-grp">
                                            <div className="added-field">
                                                <InputField
                                                    label="First Name"
                                                    type="text"
                                                    name="profile_name"
                                                    adornment="start"
                                                    adornmentContent={<SelectField optionsData={namePrefix} selected_value={userDetails.salutation && userDetails.salutation} radioChangeHandle={(value, errors) => this.handleUserDetailsChange("salutation", value, errors)} />}
                                                    selected_value={userDetails.first_name && userDetails.first_name}
                                                    handleOnChangeValue={(value, errors) => this.handleUserDetailsChange("first_name", value, errors)}
                                                    fieldStyle="field-box field-adornment bg-plane"
                                                    placeholder="Enter"
                                                />
                                                <InputField
                                                    label="Last Name"
                                                    type="text"
                                                    name="profile_lastname"
                                                    fieldStyle="field-box bg-plane"
                                                    selected_value={userDetails.last_name && userDetails.last_name}
                                                    handleOnChangeValue={(value, errors) => this.handleUserDetailsChange("last_name", value, errors)}
                                                    placeholder="Enter"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="add-field-row">
                                        <div className="added-field-grp">
                                            <div className="added-field">
                                                <InputField
                                                    label="Email" type="text" name="profile_email" fieldStyle="field-box bg-plane" placeholder="Enter"
                                                    selected_value={userDetails.email_id && userDetails.email_id}
                                                    handleOnChangeValue={(value, errors) => this.handleUserDetailsChange("email_id", value, errors)}
                                                />
                                                <PhoneField label="Phone" type="text" name="profile_phone" fieldStyle="field-box bg-plane" placeholder="Enter"
                                                    selected_value={userDetails.contact_number && userDetails.contact_number}
                                                    handleOnChangeValue={(value, errors) => this.handleUserDetailsChange("contact_number", value, errors)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="add-field-row">
                                        <div className="added-field-grp">
                                            <div className="added-field">
                                                <InputField label="Company" type="text" name="profile_company" fieldStyle="field-box bg-plane" placeholder="Enter" selected_value={userDetails.company && userDetails.company} handleOnChangeValue={(value, errors) => this.handleUserDetailsChange("company", value, errors)} />
                                                <InputField label="Designation" type="text" name="profile_designation" fieldStyle="field-box bg-plane" placeholder="Enter" selected_value={userDetails.designation && userDetails.designation} handleOnChangeValue={(value, errors) => this.handleUserDetailsChange("designation", value, errors)} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="add-field-row p-0">
                                        <div className="added-field-grp added-single-grp">
                                            <div className="added-field">
                                                <TextAreaField label="A few words about yourself" type="textarea" name="policy_description" fieldStyle="field-box bg-plane" placeholder="Description" rowmax="5" selected_value={userDetails.description && userDetails.description} handleOnChangeValue={(value, errors) => this.handleUserDetailsChange("description", value, errors)} />
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                        <div className="profile-sec-wrap">
                            <div className="profile-sec-avtr"></div>
                            <div className="profile-sec-body">
                                <div className="drawer-tabs-header">
                                    <div className="workflow-title">
                                        <h5 className='p-b-10'>Add your social account</h5>
                                        <h6 className="grey-color weight-300 p-b-10">Tip: Add your social handles to sound credible and sound like a human</h6>
                                    </div>
                                    <div className="workflow-tabs-search"></div>
                                </div>
                                <section className="add-fields-wrap">
                                    <div className="add-field-row ">
                                        <div className="added-field-grp added-single-grp">
                                            <div className="added-field">
                                                <InputFields
                                                    withLabel={false}
                                                    adornment="start"
                                                    adornmentContent={<div className="social-icons"><img src={FacebookIcon} alt="FacebookIcon" className="MuiSvgIcon" /> facebook.com</div>}
                                                    fieldStyle="field-box field-adornment bg-plane"
                                                    placeholder="Enter"
                                                    selected_value={userDetails.facebook_link && userDetails.facebook_link}
                                                    handleOnChangeValue={(value, errors) => this.handleUserDetailsChange("facebook_link", value, errors)}
                                                    customStyle="social-field"
                                                />
                                                <InputFields
                                                    withLabel={false}
                                                    adornment="start"
                                                    adornmentContent={<div className="social-icons"><img src={LinkedinIcon} alt="LinkedinIcon" className="MuiSvgIcon" /> twitter.com</div>}
                                                    fieldStyle="field-box field-adornment bg-plane"
                                                    placeholder="Enter"
                                                    selected_value={userDetails.linkedin_link && userDetails.linkedin_link}
                                                    handleOnChangeValue={(value, errors) => this.handleUserDetailsChange("linkedin_link", value, errors)}
                                                    customStyle="social-field"
                                                />
                                                <InputFields
                                                    withLabel={false}
                                                    adornment="start"
                                                    adornmentContent={<div className="social-icons"><img src={TwitterIcon} alt="TwitterIcon" className="MuiSvgIcon" /> linkedin.com</div>}
                                                    fieldStyle="field-box field-adornment bg-plane"
                                                    placeholder="Enter"
                                                    selected_value={userDetails.twitter_link && userDetails.twitter_link}
                                                    handleOnChangeValue={(value, errors) => this.handleUserDetailsChange("twitter_link", value, errors)}
                                                    customStyle="social-field"
                                                />
                                                {this.renderUpdateSuccessfullDialog()}
                                                {this.renderPasswordConfirmationDialog()}
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </Scrollbars>
                </div>
                <div className="card-footer border-top p-l-0 p-r-0">
                    <div className="btn-wrap">
                        <Fab variant="extended" size="large" className="btn btn-large btn-plane primary-color" onClick={() => this.handleSaveUserDetails()}>
                            Save
                        </Fab>
                        <Fab variant="extended" size="large" className="btn btn-large btn-plane grey-color">
                            Cancel
                        </Fab>
                    </div>
                </div>
                <LoadingComponent loading={this.state.loading} />
            </div>
        )
    }
}

SettingProfile.propTypes = {
    changeProfileImage: PropTypes.func.isRequired,
    deleteProfileImage: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
    profileImage: state.profileImage.profileImageUser,
});

export default connect(mapStateToProps, { changeProfileImage, deleteProfileImage })(SettingProfile);