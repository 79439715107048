import React, { Component } from 'react';
import { Fab } from '@material-ui/core';

class ButtonField extends Component {

  handleClick = (value) => (event) => {
    this.props.handleClick(value);
  }

  render() {
    const {
      name,
      color,
      value
    } = this.props;

    // let defaultFieldStyle = "field-box";
    // let isDisabled = (this.props.is_disable === 'yes');
    return (
      <Fab variant="extended" size="small" className={"btn btn-small btn-plane " + color} onClick={this.handleClick(value)}>
        {name}
      </Fab>
    )
  }
}

export default ButtonField;