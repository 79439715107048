import React, { Component } from 'react';
import Dialog from '@material-ui/core/Dialog';
import Fab from '@material-ui/core/Fab';
import Grid from '@material-ui/core/Grid';
import Slide from '@material-ui/core/Slide';
import AddIcon from '@material-ui/icons/Add';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';

import closeIcon from '../../assets/images/icon/close.svg';
import MultipleTabActions from '../../components/common/multiple-tab-actions';
import AddUserIllus from '../../assets/images/illustration/add-user.svg';
import PdfRedIcon from '../../assets/images/icon/pdf-red.svg';
import ExcelGreenIcon from '../../assets/images/icon/xls-green.svg';
import ExcelBlueIcon from '../../assets/images/icon/xls-blue.svg';
import UploadBlueIcon from '../../assets/images/icon/upload.svg';

import { Grid as DataGrid, GridColumn as Column } from '@progress/kendo-react-grid';
import { GridPDFExport } from '@progress/kendo-react-pdf';
import { ExcelExport, ExcelExportColumn } from '@progress/kendo-react-excel-export';

import XLSX from 'xlsx';
import Draggable from 'react-draggable';
import { Scrollbars } from 'react-custom-scrollbars';

//Custom component
import TableComponent from '../../components/common/table-component';
import InputField from '../../components/fields/inputs';
import SearchField from '../../components/fields/search';
import LoadingComponent from '../../components/common/loading-component';

import Header from '../../components/header';
import PhoneField from '../../components/fields/phone';
import SwitchButtonActions from '../../components/fields/switch-button-action';
import WorkflowClientHeader from '../../components/workflow/workflow-client-header';
import * as InputFieldValidation from '../../components/validators/field-validators/input-field-validation';
import * as ErrorValidation from '../../components/validators/error-validator';

//API
import * as TeamMemberApi from '../../api/team-member-api';

import { RECORD_STATUS } from '../../utilities/constants';

const tableUserRows = [
    { id: 'name', numeric: false, disablePadding: false, label: 'Name', show: true, field_validations: ["v_required"], apiParam: "name", hideOnImport: false },
    { id: 'designation', numeric: false, disablePadding: false, label: 'Designation', show: true, apiParam: "designation", hideOnImport: false },
    { id: 'emailId', numeric: false, disablePadding: false, label: 'Email', show: true, field_validations: ["v_email"], apiParam: "email_id", hideOnImport: false },
    { id: 'mobile', numeric: false, disablePadding: false, label: 'Mobile', show: true, field_validations: ["v_valid_phone_number"], apiParam: "mobile", hideOnImport: false },
    { id: 'programAssociated', numeric: false, disablePadding: false, label: 'Program Associated', show: true, hideOnImport: true },
    { id: 'isAgent', numeric: false, disablePadding: false, label: 'Agent', show: true, apiParam: "agent", hideOnImport: false, validField: ["true", "false"] },
    { id: 'isAllowAPIAccess', numeric: false, disablePadding: false, label: 'API Access', show: true, apiParam: "allow_api_access", hideOnImport: false, validField: ["true", "false"] },
    { id: 'createdOn', numeric: false, disablePadding: false, label: 'Created on', show: true, hideOnImport: true },
    { id: 'status', numeric: false, disablePadding: false, label: 'Status', show: true, apiParam: "status", hideOnImport: false, validField: ["ACTIVE", "INACTIVE"] },
    { id: 'actions', numeric: false, disablePadding: false, label: 'Actions', show: false, hideOnImport: true },
];

function createTeamMembersData(name, id, designation, emailId, mobile, isAgent, isAllowAPIAccess, createdOn, status, handleEditChange) {
    let programAssociated = "";
    let actionView = (<MultipleTabActions type="icons" handleEditClick={handleEditChange(id)} iconList={["edit"]} />)
    return { name, designation, emailId, mobile, programAssociated, isAgent, isAllowAPIAccess, createdOn, status, actionView };
}

const SheetJSFT = [
    "xlsx", "xlsb", "xlsm", "xls", "xml", "csv", "txt", "ods", "fods", "uos", "sylk", "dif", "dbf", "prn", "qpw", "123", "wb*", "wq*", "html", "htm"
].map(function (x) { return "." + x; }).join(",");

const make_cols = refstr => {
    let o = [], C = XLSX.utils.decode_range(refstr).e.c + 1;
    for (var i = 0; i < C; ++i) o[i] = { name: XLSX.utils.encode_col(i), key: i }
    return o;
};

function PaperComponent(props) {
    return (
        <Draggable cancel={'[class*="MuiDialogContent-root"]'}>
            <Paper {...props} />
        </Draggable>
    );
}

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

class TeamMembersDashboard extends Component {

    state = {
        customStyleSearch: 'only-small-search',
        addMembersDialogOpen: false,
        membersTableData: [],
        userData: {},
        active: false,
        isAgent: false,
        isAllowAPIAccess: false,
        disabled: false,
        exportExcel: '',
        open: false,
        loading: true
    }

    constructor(props) {
        super(props);
        const match = this.props.match;
        let clientId = match.params.clientId;
        this.handleGetAllUserData(clientId);
    }

    handleGetAllUserData(clientId) {
        TeamMemberApi.getTeamMembersData(clientId).then(data => {
            this.setState({ membersTableData: data, loading: false })
        })
    }

    searchBtnClick() {
        this.setState({ customStyleSearch: "" });
    }

    createUserflow(type) {
        this.setState({ [type]: true });
    }

    handleClose = (type) => (event) => {
        this.setState({ [type]: false, userData: {}, active: false, isAgent: false, isAllowAPIAccess: false });
    }

    handleUserChange(fieldName, value) {
        console.log("received : " + fieldName + " " + value);
        let userData = this.state.userData;
        userData[fieldName] = value;
        this.setState({ userData: userData });
        console.log("type ", fieldName, value, this.state.userData)
    }

    saveUserData() {
        const match = this.props.match;
        let clientId = match.params.clientId;
        let { userData } = this.state;
        this.setState({ disabled: true });
        let emailValidation = InputFieldValidation.emailValidation(userData.email_id)
        let phoneNumberValidation = InputFieldValidation.phoneNumberValidation(userData.mobile)
        userData["status"] = this.state.active ? RECORD_STATUS.ACTIVE : RECORD_STATUS.INACTIVE;
        userData["agent"] = this.state.isAgent;
        userData["allow_api_access"] = this.state.isAllowAPIAccess;
        userData["client_id"] = clientId;
        if (!userData.id && userData.name && userData.mobile && phoneNumberValidation.isValid && userData.email_id && emailValidation.isValid) {
            TeamMemberApi.addTeamMember(userData).then(res => {
                this.setState({ addMembersDialogOpen: false, userData: {}, active: false, isAgent: false, disabled: false, isAllowAPIAccess: false }, () => this.handleGetAllUserData(clientId));
            }).catch(error => {
                console.log("error", error);
                this.setState({ disabled: false });
            })
        } else {
            console.log("userData", userData);
            TeamMemberApi.updateTeamMember(userData.id, userData).then(res => {
                this.setState({ addMembersDialogOpen: false, userData: {}, active: false, isAgent: false, disabled: false, isAllowAPIAccess: false }, () => this.handleGetAllUserData(clientId));
            }).catch(error => {
                console.log("error", error);
                this.setState({ disabled: false });
            })
        }
    }

    handleToggleChange = (key) => (value) => {
        if (key === "active") {
            this.setState({ active: !this.state.active });
        }
        if (key === "isAgent") {
            this.setState({ isAgent: !this.state.isAgent });
        }
        if (key === "isAllowAPIAccess") {
            this.setState({ isAllowAPIAccess: !this.state.isAllowAPIAccess });
        }

    }

    handleEditChange = (id) => value => {
        TeamMemberApi.getTeamMemberDetailsById(id).then((data) => {
            this.setState({ userData: data, addMembersDialogOpen: true, active: (data.status === RECORD_STATUS.ACTIVE) ? true : false, isAgent: data.agent, isAllowAPIAccess: data.allow_api_access });
        });
    }

    gridPDFExport;
    exportPDF = () => {
        this.gridPDFExport.save();
    };

    gridExcelExport;
    exportExcel = () => {
        this.setState({ exportExcel: 'entire-table' }, () => this.gridExcelExport.save());
    }

    exportTemplateExcel = () => {
        this.setState({ exportExcel: 'table-template' }, () => this.gridExcelExport.save());
    }

    renderPdfColumns(columns, type) {
        return columns.map(column =>
            column.show && <Column field={column.id}
                width="200px"
                title={column.label}
            />
        )
    }

    renderExportColumns(columns) {
        if (this.state.exportExcel === 'table-template') {
            return columns.map(column =>
                column.show && <ExcelExportColumn field={column.id}
                    width="200px"
                    title={column.label}
                    hidden={column.hideOnImport}
                    cellOptions={{
                        format: '@'
                    }}
                />
            )
        }
        return columns.map(column =>
            column.show && <ExcelExportColumn field={column.id}
                width="200px"
                title={column.label}
            />
        )
    }

    fileHandler = (event) => {
        let fileObj = event.target.files[0];
        if (fileObj) {
            const reader = new FileReader();
            const rABS = !!reader.readAsBinaryString;
            let data = null;
            reader.onload = (e) => {
                const bstr = e.target.result;
                const wb = XLSX.read(bstr, { type: rABS ? 'binary' : 'array', bookVBA: true });
                const wsname = wb.SheetNames[0];
                const ws = wb.Sheets[wsname];
                data = XLSX.utils.sheet_to_json(ws, { defval: "" });
                if (data.length > 0) {
                    this.setState({ excelData: data, cols: make_cols(ws['!ref']) }, () => {
                        this.saveExcelData(data);
                    });
                } else {
                    this.setState({ open: true, errorMessage: "No entries found" });
                }
            };
            if (rABS) {
                reader.readAsBinaryString(fileObj);
            } else {
                reader.readAsArrayBuffer(fileObj);
            }
            this.fileInput.value = "";
        }
    }

    saveExcelData(excelDataList) {
        let recordData = [];
        let breakCondition = false;
        let errorText = ''
        this.setState({ loading: true })
        excelDataList.forEach((excelData, index) => {
            let formUserData = {};
            for (let key in excelData) {
                let filterField = tableUserRows.filter(field => field.label === key)[0];
                if (filterField != null) {
                    if (!ErrorValidation.checkForBulkUploadData(excelData[key], filterField.field_validations, key, filterField.validField)) {
                        breakCondition = true;
                        errorText = this.createHtmlErrorMessage(key, excelData[key], index, errorText);
                    } else {
                        formUserData[filterField.apiParam] = excelData[key];
                        switch (key) {
                            case "Agent":
                                formUserData[filterField.apiParam] = excelData[key].toLowerCase() === "true" ? true : false
                                break;
                            case "API Access":
                                formUserData[filterField.apiParam] = excelData[key].toLowerCase() === "true" ? true : false
                                break;
                            case "Status":
                                formUserData[filterField.apiParam] = excelData[key].toLowerCase() === "active" ? "ACTIVE" : "INACTIVE"
                                break;
                            default:
                        }
                    }
                }
            }
            formUserData["client_id"] = this.props.match.params.clientId;
            recordData[index] = formUserData;
        })
        this.setState({ errorMessage: errorText });
        if (!breakCondition) {
            TeamMemberApi.bulkAddTeamMember(recordData).then(response => {
                this.handleGetAllUserData(this.props.match.params.clientId);
            }).catch(error => {
                let errorResponse = JSON.parse(error.response);
                this.setState({ open: true, errorMessage: errorResponse.message, loading: false });
            })
        } else {
            this.setState({ open: true, loading: false });
        }
    }

    createHtmlErrorMessage(key, value, index, errorText) {
        let keyStyle = '<em>' + key + '</em>';
        let valueStyle = '<i>' + value + '</i>';
        let rowValue = index + 1;
        let message = '' + keyStyle + ' contains invalid data ' + valueStyle + ' at row ' + rowValue;
        message = '<h6>' + message + '</h6><br>';
        return errorText + message;
    }

    uploadButtonClick() {
        document.getElementById("imageUpload").click();
    }

    renderUpdateFailureDialog() {
        return (
            <Dialog
                open={this.state.open}
                onClose={this.handleClose("open")}
                PaperComponent={PaperComponent}
                aria-labelledby="draggable-dialog-title"
            >
                <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                    Upload Failed
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <div dangerouslySetInnerHTML={{ __html: this.state.errorMessage }}></div>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={this.handleClose("open")} color="primary">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    render() {
        let { customStyleSearch, membersTableData, active, isAgent, addMembersDialogOpen, exportExcel, isAllowAPIAccess } = this.state;
        const match = this.props.match;
        let clientId = match.params.clientId;
        let requiredMembersTableData = [];

        const LightTooltip = withStyles(theme => ({
            tooltip: {
                backgroundColor: theme.palette.common.white,
                color: 'rgba(0, 0, 0, 0.87)',
                position: 'absolute',
                left: '50%',
                transform: 'translateX(-50%)',
                boxShadow: theme.shadows[1],
                fontSize: 11,
            },
        }))(Tooltip);

        if (membersTableData != null) {
            membersTableData.forEach(data => {
                requiredMembersTableData.push(createTeamMembersData(data.name, data.id, data.designation, data.email_id, data.mobile, data.agent.toString(), data.allow_api_access?.toString() ?? "false", data.created_on, data.status, this.handleEditChange));
            });
        }

        return (
            <div className="FormBuilderScene">
                <Header headerType="dashboard" positionType="fixed" clientId={clientId} />
                <WorkflowClientHeader workflowType="normal" tab="team" clientId={clientId} />
                <main>
                    {
                        <div className="workflow-fields-sec workflow-fields-dashboard">
                            <div className="drawer-tabs drawer-tabs-round no-drawer-indicator workflow-tabs-wrap">
                                <div className="drawer-tabs-header">
                                    <div></div>
                                    <div className="workflow-tabs-search">
                                        <SearchField placeholder="Search here" searchType="normal" customStyle={customStyleSearch} searchBtnClick={this.searchBtnClick.bind(this)} />
                                        <LightTooltip title="Export Excel">
                                            <Fab onClick={this.exportExcel} variant="extended" size="small" className="btn btn-small btn-plane btn-fab transparent-color no-shadow grey-color">
                                                <img src={ExcelGreenIcon} alt="ExcelGreenIcon" />
                                            </Fab>
                                        </LightTooltip>
                                        <LightTooltip title="Export Table Template">
                                            <Fab onClick={this.exportTemplateExcel} variant="extended" size="small" className="btn btn-small btn-plane btn-fab transparent-color no-shadow grey-color">
                                                <img src={ExcelBlueIcon} alt="ExcelBlueIcon" />
                                            </Fab>
                                        </LightTooltip>
                                        <LightTooltip title="Export PDF">
                                            <Fab onClick={this.exportPDF} variant="extended" size="small" className="btn btn-small btn-plane btn-fab transparent-color no-shadow grey-color">
                                                <img src={PdfRedIcon} alt="PdfRedIcon" />
                                            </Fab>
                                        </LightTooltip>
                                        <LightTooltip title="Upload Bulk Data">
                                            <Fab onClick={this.uploadButtonClick} variant="extended" size="small" className="btn btn-small btn-plane btn-fab transparent-color no-shadow grey-color">
                                                <img src={UploadBlueIcon} alt="DuplicatePinkIcon" />
                                            </Fab>
                                        </LightTooltip>
                                        <input
                                            id="imageUpload" hidden
                                            ref={fileInput => (this.fileInput = fileInput)}
                                            type="file"
                                            onChange={this.fileHandler.bind(this)}
                                            accept={SheetJSFT}
                                            style={{ "padding": "10px" }}
                                        />
                                        <Fab variant="extended" size="large" className="btn btn-large workflow-add-btn no-shadow" onClick={(event) => this.createUserflow("addMembersDialogOpen")}>
                                            <AddIcon />
                                            Add Users
                                        </Fab>
                                    </div>
                                </div>
                                <div className="tab-container">
                                    <GridPDFExport ref={pdfExport => this.gridPDFExport = pdfExport}>
                                        <DataGrid data={requiredMembersTableData}>
                                            {this.renderPdfColumns(tableUserRows)}
                                        </DataGrid>
                                    </GridPDFExport>
                                    <ExcelExport
                                        data={exportExcel === 'table-template' ? tableUserRows : requiredMembersTableData}
                                        ref={(exporter) => { this.gridExcelExport = exporter; }}
                                    >

                                        {membersTableData.length > 0 && (
                                            <div className="table-wrapper vertical-bottom">
                                                <TableComponent
                                                    tableLabel=""
                                                    rows={tableUserRows}
                                                    isPagginated={true}
                                                    isSearchable={false}
                                                    data={requiredMembersTableData}
                                                    searchLabel=""
                                                    isCheckBoxEnabled={false}
                                                />
                                            </div>
                                        )
                                        }
                                        {membersTableData.length <= 0 && (
                                            <section className="blank-page-sec">
                                                <div className="blank-page-wrap">
                                                    <div className="blank-page-icon">
                                                        <img src={AddUserIllus} alt="AddUserIllus" />
                                                    </div>
                                                    <div className="blank-page-info">
                                                        <h5>You haven't added any users yet!</h5>
                                                        <h6>Add team members and assign them agent role according to your business requirement</h6>
                                                    </div>
                                                    <Fab variant="extended" size="large" className="btn btn-large workflow-add-btn no-shadow primary-color" onClick={() => this.createUserflow("addMembersDialogOpen")}>
                                                        Add Team Members
                                                    </Fab>
                                                </div>
                                            </section>
                                        )}
                                        {this.renderExportColumns(tableUserRows)}
                                    </ExcelExport>
                                </div>
                            </div>
                            <LoadingComponent loading={this.state.loading} />
                            {this.renderUpdateFailureDialog()}
                            {/* Dialog for add new user */}
                            <Dialog open={addMembersDialogOpen} onClose={this.handleClose("addMembersDialogOpen")} TransitionComponent={Transition} maxWidth="sm">
                                <section className="workflow-dialog workflow-dialog-large">
                                    <div className="card-block">
                                        <div className="card-header border-bottom">
                                            <div className="sub-title">
                                                <h2>Team Member Details</h2>
                                            </div>
                                            <div className="close-dialog">
                                                <img src={closeIcon} alt="" onClick={this.handleClose("addMembersDialogOpen")}></img>
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <Grid container>
                                                <Grid item sm={12}>
                                                    <div className="workflow-dialog-forms">
                                                        <div className="tabs-container tabs-container-scroll">
                                                            <Scrollbars style={{ width: '100%', height: '100%' }}>
                                                                <div className="tabs-container-scroll-wrap">
                                                                    <Grid item md={12}>
                                                                        <InputField
                                                                            label="Name"
                                                                            name="name"
                                                                            type="text"
                                                                            placeholder="Name"
                                                                            fieldStyle="field-box bg-plane grey-color"
                                                                            selected_value={this.state.userData.name}
                                                                            handleOnChangeValue={(value) => this.handleUserChange("name", value)}
                                                                            is_mandatory="yes"
                                                                            disabled={this.state.userData.id ? true : false}
                                                                            field_validations={["v_required"]}
                                                                        />
                                                                    </Grid>
                                                                    <Grid item md={12}>
                                                                        <InputField
                                                                            label="Designation"
                                                                            name="designation"
                                                                            placeholder="Designation"
                                                                            fieldStyle="field-box bg-plane grey-color"
                                                                            selected_value={this.state.userData.designation}
                                                                            handleOnChangeValue={(value) => this.handleUserChange("designation", value)}
                                                                        />
                                                                    </Grid>
                                                                    <Grid item md={12}>
                                                                        <PhoneField
                                                                            label="Mobile no"
                                                                            name="mobile"
                                                                            default_country_code="in"
                                                                            fieldStyle="field-box bg-plane grey-color"
                                                                            placeholder="+91 XXXXX-XXXXX"
                                                                            selected_value={this.state.userData.mobile}
                                                                            handleOnChangeValue={(value) => this.handleUserChange("mobile", value)}
                                                                            is_mandatory="yes"
                                                                            field_validations={["v_valid_phone_number"]}
                                                                        />
                                                                    </Grid>
                                                                    <Grid item md={12}>
                                                                        <InputField
                                                                            label="Email"
                                                                            name="email_id"
                                                                            isEmail={true}
                                                                            placeholder="eg: abc@gmail.com"
                                                                            fieldStyle="field-box bg-plane grey-color"
                                                                            selected_value={this.state.userData.email_id}
                                                                            handleOnChangeValue={(value) => this.handleUserChange("email_id", value)}
                                                                            is_mandatory="yes"
                                                                            disabled={this.state.userData.id ? true : false}
                                                                            field_validations={["v_email"]}
                                                                        />
                                                                    </Grid>
                                                                    <Grid container md={12}>
                                                                        <Grid item md={5}>
                                                                            <SwitchButtonActions
                                                                                label="Yes"
                                                                                name="active"
                                                                                fieldStyle="field-box bg-plane grey-color"
                                                                                isChecked={active}
                                                                                headerLabel="Active?"
                                                                                withLabel={true}
                                                                                onChange={this.handleToggleChange("active").bind(this)}
                                                                            />
                                                                        </Grid>
                                                                        <Grid item md={5}>
                                                                            <SwitchButtonActions
                                                                                label="Yes"
                                                                                name="is_agent"
                                                                                fieldStyle="field-box bg-plane grey-color"
                                                                                isChecked={isAgent}
                                                                                headerLabel="Agent?"
                                                                                withLabel={true}
                                                                                onChange={this.handleToggleChange("isAgent").bind(this)}
                                                                            />
                                                                        </Grid>
                                                                        <Grid item md={5}>
                                                                            <SwitchButtonActions
                                                                                label="Yes"
                                                                                name="is_allow_api_access"
                                                                                fieldStyle="field-box bg-plane grey-color"
                                                                                isChecked={isAllowAPIAccess}
                                                                                headerLabel="Allow API access?"
                                                                                withLabel={true}
                                                                                onChange={this.handleToggleChange("isAllowAPIAccess").bind(this)}
                                                                            />
                                                                        </Grid>
                                                                    </Grid>
                                                                </div>
                                                            </Scrollbars>
                                                        </div>
                                                        <div className="form-row workflow-dialog-footer">
                                                            <div className="btn-wrap no-border">
                                                                <Fab variant="extended" size="large" className="btn btn-large btn-plane primary-color" onClick={this.saveUserData.bind(this)} disabled={this.state.disabled}>
                                                                    Save and Associate
                                                                </Fab>
                                                                <Fab variant="extended" size="large" className="btn btn-large btn-plane grey-color" onClick={this.handleClose("addMembersDialogOpen")}>
                                                                    Cancel
                                                                </Fab>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </div>
                                    </div>
                                </section>
                            </Dialog>
                        </div>
                    }
                </main>
            </div>
        )
    }
}

export default TeamMembersDashboard;