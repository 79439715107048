import React, { Component } from 'react';
/* import SettingFlowIllust from '../../assets/images/illustration/login-iso.svg'; */
import Header from '../../components/header';
import WorkflowHeader from '../../components/workflow/workflow-header';
import ProgramIcon from '../../assets/images/icon/square-4-blue.svg';
import ArrowIcon from '../../assets/images/icon/chevron-blue.svg';
/* import SelectField from '../../components/fields/select'; 
import TextAreaField from '../../components/fields/textarea';
import taskBlueIcon from '../../assets/images/icon/task-blue.svg';
import updateBlueIcon from '../../assets/images/icon/update-blue.svg'; */
/* import Grid from '@material-ui/core/Grid'; */
import Fab from '@material-ui/core/Fab';

import SettingProgram from '../../components/settings/setting-program';
import SettingApi from '../../components/settings/setting-api';
import * as ProgramApi from '../../api/program-api';

class SettingDashboardScene extends Component {
    state = {
        active: false,
        buttonState: false,
        changeSetting: 'program',
        programDetails: {},
        open: false,
    }

    componentWillMount() {
        const match = this.props.match;
        let programKey = match.params.programkey;
        ProgramApi.getProgramByProgramKey(programKey).then(data => {
            this.setState({ programDetails: data });
        })
    }


    toggleTimeline() {
        this.setState({ active: !this.state.active });
    }
    settingChange(val) {
        this.setState({ changeSetting: val });
        console.log(this.state.changeSetting);
    }

    handleProgramDetailsChange(programDetails) {
        console.log("handleProgramDetailsChange", programDetails);
        this.setState({ programDetails: programDetails })

    }

    updateProgramData() {
        ProgramApi.updateProgram(this.state.programDetails).then(data => {
            console.log("data555", data)
            this.setState({ programDetails: data, open: true });
        })
    }

    handleClose() {
        this.setState({ open: false });
    }

    render() {
        let { buttonState, active, changeSetting, programDetails } = this.state;
        let toggleTimeline = active ? "toggle-timeline" : "";
        let ToggleBtnArrow = buttonState ? "toggle-arrow" : "";
        const match = this.props.match;
        let clientId = match.params.clientId;
        let programkey = match.params.programkey;
        /* let defalutSections = [
            {
                field_option_label: "\u20B9",
                field_option_value: "rs",
            },
            {
                field_option_label: "\u0024",
                field_option_value: "usd"
            },
            {
                field_option_label: "\u00A5",
                field_option_value: "yen"
            },
        ]
        const settingTrigger = [
            {
                field_option_label: "Individual",
                field_option_value: "individual",
            },
            {
                field_option_label: "Group",
                field_option_value: "group",
            },
        ] */

        return (
            <div className="SettingBuilderScene">
                <Header headerType="dashboard" positionType="fixed" programKey={programkey} />
                <WorkflowHeader workflowType="normal" tab="setting" clientId={clientId} programKey={programkey} programName={programDetails.program_name} />
                <main>
                    <div className="workflow-fields-sec program-build-sec create-app-sec level-2-header">
                        <div className="program-build-wrap">
                            <div className={"program-card-wrap program-build-timeline " + toggleTimeline}>
                                <div className="card-block">
                                    <div className="card-body">
                                        <section className="create-app-timeline">
                                            <div className="cretae-timeline-wrap">
                                                <div className={"program-timeline-btn " + (changeSetting === 'program' ? 'active' : '')}>
                                                    <Fab variant="extended" size="small" className="btn btn-small no-shadow btn-icon" onClick={() => this.settingChange('program')}>
                                                        <img src={ProgramIcon} alt="ProgramIcon" />
                                                        {
                                                            !toggleTimeline && (
                                                                <span>Program</span>
                                                            )
                                                        }
                                                    </Fab>
                                                </div>
                                            </div>
                                            {/* <div className="cretae-timeline-wrap">
                                                <div className= {"program-timeline-btn " + (changeSetting === 'apiKey' ? 'active' : '') }>
                                                    <Fab variant="extended" size="small" className="btn btn-small no-shadow btn-icon" onClick={()=> this.settingChange('apiKey')}>
                                                        <img src={KeyBlueIcon} alt="KeyBlueIcon"/> 
                                                        {
                                                            !toggleTimeline && (
                                                                <span>Api Key</span>
                                                            )
                                                        }
                                                    </Fab>
                                                </div>
                                            </div> */}
                                            <div className={"program-timeline-btn program-timeline-toggle " + ToggleBtnArrow} onClick={this.toggleTimeline.bind(this)} >
                                                <Fab variant="extended" size="small" className="btn btn-small no-shadow btn-toggle">
                                                    <img src={ArrowIcon} alt="ArrowIcon" />
                                                </Fab>
                                            </div>
                                        </section>
                                    </div>
                                </div>
                            </div>
                            <div className={"program-card-wrap program-build-form " + toggleTimeline}>
                                <div className="card-block">
                                    <div className="card-body">
                                        {
                                            (changeSetting === 'program') && (
                                                <SettingProgram
                                                    open={this.state.open}
                                                    programDetails={programDetails}
                                                    handleClose={() => this.handleClose()}
                                                    handleProgramDetailsChange={(programDetails) => this.handleProgramDetailsChange(programDetails)}
                                                    updateProgramData={() => this.updateProgramData()}
                                                />
                                            )
                                        }
                                        {
                                            (changeSetting === 'apiKey') && (
                                                <SettingApi />
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <section className="workflow-dialog workflow-dialog-large workflow-fields-sec workflow-fields-dashboard setting-dashbaord-scene">
                        <div className="card-block">
                            <div className="card-header border-bottom">
                                <div className="sub-title">
                                    <h4>Setting</h4>
                                </div>
                                <div className="close-dialog">
                                </div>
                            </div>
                            <div className="card-body">
                                <Grid container>
                                    <Grid item md={4}>
                                        <div className="workflow-illust-wrap">
                                            <div className="sub-title">
                                                <h2>Create a New Program</h2>
                                            </div>
                                            <div className="workflow-illust">
                                                <img src={SettingFlowIllust} alt="SettingFlowIllust"/>
                                            </div>
                                        </div>
                                    </Grid>
                                    <Grid item md={8}>
                                        <div className="workflow-dialog-forms">
                                            <div className="tabs-container tabs-container-scroll">
                                                <Scrollbars style={{ width: '100%', height: '100%' }}>
                                                    <div className="tabs-container-scroll-wrap">
                                                        <Grid item md={8}>
                                                            <SelectField 
                                                                label="Master Policy Upload"
                                                                optionsData={defalutSections}
                                                                isMultiple={true}
                                                                fieldStyle="field-box bg-plane grey-color"/>
                                                        </Grid>
                                                        <Grid item md={8}>
                                                            <InputField 
                                                                label="Name of the Program"
                                                                placeholder="Enter"
                                                                fieldStyle="field-box bg-plane grey-color"/>
                                                        </Grid>
                                                        <Grid item md={8}>
                                                            <TextAreaField 
                                                                label="Description" 
                                                                name="user_descp" 
                                                                rowmax="6" 
                                                                placeholder="Description"
                                                                fieldStyle="field-box bg-plane grey-color"/>
                                                        </Grid>
                                                        <Grid item md={8}>
                                                            <RadioField 
                                                                radioData={settingTrigger}
                                                                label="Program Type"     
                                                                radioFieldStyle="field-inline"      
                                                            />
                                                        </Grid>
                                                        <Grid item md={8}>
                                                            <GenerateKeyCode fieldStyle="field-box bg-plane field-dotted" label="API Key" disabled={true}/>
                                                        </Grid>
                                                    </div>
                                                </Scrollbars>
                                            </div>
                                            <div className="form-row workflow-dialog-footer">
                                                <div className="btn-wrap no-border">
                                                    <Fab variant="extended" size="large" className="btn btn-large btn-plane primary-color">
                                                        Share
                                                    </Fab>
                                                    <Fab variant="extended" size="large" className="btn btn-large btn-plane grey-color">
                                                        Cancel
                                                    </Fab>
                                                </div>
                                            </div> 
                                        </div>
                                    </Grid>
                                </Grid>
                            </div> 
                        </div>
                    </section> */}
                </main>
            </div>
        )
    }
}

export default SettingDashboardScene;