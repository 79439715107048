import React, { Component } from 'react';
//import logo_img from '../../assets/images/clients/axis-bank.png';
// import logo_img from '../../assets/images/asureKit.png';
import Popover from '@material-ui/core/Popover';
import settingIcon from '../../assets/images/icon/setting-blue.svg';
import Avatar_img from '../../assets/images/avatar.jpg';
import Blank_Avatar from '../../assets/images/blank-avatar.png';
import Avatar from '@material-ui/core/Avatar';
import Fab from '@material-ui/core/Fab';
import { Redirect } from "react-router-dom";
import PropTypes from 'prop-types';
import profileIcon from '../../assets/images/icon/profile-blue.svg';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { fetchProfileImageUser } from '../../actions/profileImageAction';

//API
import * as ProgramApi from '../../api/program-api';
import * as UserApi from '../../api/user-api.jsx';

/* Date: 11-01-2021
 Name: Milind Sewak
 Desc: Logo Image Configuration*/
import * as Constants from '../../utilities/constants';
// End

const Logo = (
    <div className="logo-wrap">
        <img src={Constants.CONFIG.LOGO} alt="Logo" />
    </div>
)

class ProfileOnboard extends Component {
    state = {
        profilelink: null,
        redirect: false,
        loggedUser: {},
        anchorEl: false,
    }
    componentDidMount() {
        this.getCurrentUser();

        this.setState({ anchorEl: this.props.showProfile });

        //this.props.programKey &&
        // ProgramApi.getNavigationData(this.props.programKey).then(result => {
        //     this.setState({navigationData : result});
        // })
    }

    getCurrentUser() {
        UserApi.getLoggedInUser().then(user => {
            this.getProfileImage();
            this.setState({ loggedUser: user });
        });
    }

    getProfileImage() {
        if (!this.props.profileImage) {
            this.props.fetchProfileImageUser();
        }
    }

    ProfileUserClick(event) {
        this.setState({ anchorEl: event.currentTarget });
    }

    ProfileUserClose() {
        this.setState({ anchorEl: !this.state.anchorEl });
    }

    redirectLink = (link) => () => {
        window.location = link;
    }

    deleteSession() {
        let tokenValue = sessionStorage.getItem("access_token");
        console.log("tokenValue", tokenValue);
        UserApi.logoutApplication().then(data => {
            console.log("data123", data);
            sessionStorage.removeItem("access_token");
        })

        this.setState({ redirect: true });
    }
    render() {
        const { programKey, loginSet } = this.props;
        const { anchorEl, redirect, loggedUser } = this.state;
        const open = Boolean(anchorEl);
        const id = open ? 'simple-popper' : undefined;
        let profileLink = "/profile";
        const LightTooltip = withStyles(theme => ({
            tooltip: {
                backgroundColor: theme.palette.common.white,
                color: 'rgba(0, 0, 0, 0.87)',
                //   position: 'absolute',
                left: '40%',
                transform: 'translateX(-50%)',
                boxShadow: theme.shadows[1],
                fontSize: 11,
            },
        }))(Tooltip);
        return (
            <div className="notify-wrap assure-profile">
                {redirect && (<Redirect
                    to={{
                        pathname: "/"
                    }}
                />)}
                {
                    loginSet == 'simple' ?
                        <IconButton aria-describedby={id}
                            variant="contained"
                            onClick={this.ProfileUserClick.bind(this)}>
                            <img alt="" src={this.props.profileImage ? this.props.profileImage : Blank_Avatar} className="mob-profile" />
                        </IconButton>
                        : <Fab size="large" className="btn btn-large btn-plane white-color profile-btn"
                            aria-describedby={id}
                            variant="contained"
                            onClick={this.ProfileUserClick.bind(this)}>
                            {Logo}
                            <Avatar alt="profile" className="avatar-img" src={this.props.profileImage ? this.props.profileImage : Blank_Avatar} />
                        </Fab>
                }

                <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={this.ProfileUserClose.bind(this)}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                >
                    <div className="profile-user-wrap">
                        <div className="profile-user-info">
                            <Avatar alt="profile" className="avatar-img" src={this.props.profileImage ? this.props.profileImage : Blank_Avatar} />
                            <h5>{(loggedUser.first_name ? loggedUser.first_name : "First Name") + " " + (loggedUser.last_name ? loggedUser.last_name : "Last Name")}</h5>
                            <h6 className="font-small">{loggedUser.email_id}</h6>
                        </div>
                        {/* <div className="btn-wrap">
                                <Fab variant="extended" size="small" className="btn btn-large btn-plane white-color profile-btn no-shadow">
                                    <img alt="settingIcon" src={settingIcon} />
                                    Setting
                                </Fab>
                                <Fab variant="extended" size="small" className="btn btn-large btn-plane primary-color profile-btn no-shadow" onClick={(event) => this.deleteSession()}>
                                    Signout
                                </Fab>
                            </div> */}
                        <div className="btn-wrap">
                            <Fab variant="extended" size="small" className="btn btn-large btn-plane white-color profile-btn no-shadow" onClick={this.redirectLink(profileLink)}>
                                <img alt="settingIcon" src={settingIcon} />
                                Setting
                            </Fab>
                            <Fab variant="extended" size="small" className="btn btn-large btn-plane primary-color profile-btn no-shadow" onClick={(event) => this.deleteSession()}>
                                Sign out
                            </Fab>
                        </div>
                    </div>
                </Popover>
            </div>
        )
    }
}

ProfileOnboard.propTypes = {
    fetchProfileImageUser: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
    profileImage: state.profileImage.profileImageUser,
});
export default connect(mapStateToProps, { fetchProfileImageUser })(ProfileOnboard);