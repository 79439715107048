import React, { Component } from 'react';
import PageErrorIllust from "../../assets/images/illustration/error-page.svg";
import Logo from '../../assets/images/assureKit-white.png';
import Fab from '@material-ui/core/Fab';

class PageError extends Component {

    redirectToDashboard() {

        if (this.props.location && this.props.location.state && this.props.location.state.userType) {
            if (this.props.location.state.userType == 2) {
                window.location = "/policy-list";
            } else {
                window.location = "/create-app";
            }
        } else {
            window.location = "/";
        }
    }

    render() {
        return (
            <div className="page-error-wrap">
                <header className="header fixed-header">
                    <div className="logo">
                        <img src={Logo} alt="assureKit" />
                    </div>
                </header>
                <div className="error-img-wrap">
                    <img src={PageErrorIllust} alt="404 error" />
                </div>
                <div className="error-info-wrap">
                    <div className="error-descp">
                        <p className="error-back">403</p>
                        <div className="error-info">
                            <h3>403</h3>
                            <p>We’ve noticed you lost your way, not to worry though,  We can help you find your next opportunity</p>
                            <Fab variant="extended" size="large" className="btn btn-large btn-plane no-shadow primary-color" onClick={() => this.redirectToDashboard()}>
                                Go Home
                            </Fab>
                        </div>
                    </div>
                </div>
                <footer className="footer fixed-footer">
                    <p>&copy; 2019 Assurekit Technology and Services Pvt Ltd</p>
                </footer>
            </div>
        )
    }
}

export default PageError;