import React, { Component } from 'react';
import { DateRangePicker } from '@progress/kendo-react-dateinputs';
import InputLabel from '@material-ui/core/InputLabel';
import Description from '../validators/descriptions';
import MandatoryValidator from '../validators/mandatory-validator';


class DateRangePickerKendo extends Component {

    state = { value: new Date() };

    handleChange = (event) => {
        console.log("Date_Range_Picker", event.target.value);

        // let errors = ErrorValidation.updateErrorMessage( event.target.value, this.props.field_validations, this.props.condition_script, this.props.error_message );
        this.props.handleDateChange(event.target.value, []);
    }

    render() {

        let { name, label, instruction, is_mandatory, defaultLabel, disabled, fieldStyle, customStyle, default_value, value } = this.props;
        let defaultFieldStyle = "field-box";
        let isDisabled = this.props.is_disable === 'yes' ? true : false;

        if (value.start && value.end) {
            if (value.start.toString() === "Invalid Date" || value.end.toString() === "Invalid Date") {
                value = null;
            }
        }

        return (
            <div className={"form-row " + customStyle}>
                <div className={fieldStyle ? fieldStyle : defaultFieldStyle}>
                    <InputLabel
                        htmlFor={name}
                        className="label">
                        {label ? label : defaultLabel}
                        <MandatoryValidator value={is_mandatory} />
                    </InputLabel>
                    <DateRangePicker
                        id={name}
                        margin="normal"
                        className="field"
                        format="dd/MM/yyyy"
                        value={value}
                        defaultValue={null}
                        disabled={isDisabled ? isDisabled : disabled}
                        onChange={this.handleChange.bind(this)}
                    />
                </div>
                <Description value={instruction} />
            </div>
        )
    }
}

DateRangePickerKendo.defaultProps = {
    default_value: null,
}

export default DateRangePickerKendo