import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import Fab from '@material-ui/core/Fab';
import Slide from '@material-ui/core/Slide';
import closeIcon from '../../assets/images/icon/close.svg';
import ScratchIllus from '../../assets/images/illustration/scratch.svg';
import WorkflowIllust from '../../assets/images/illustration/program-iso.svg';
// import WorkflowHeader from '../../components/workflow/workflow-header';
import WorkflowClientHeader from '../../components/workflow/workflow-client-header';

import RadioField from '../../components/fields/radio';
import UploadField from '../../components/fields/upload';
import SearchField from '../../components/fields/search';
import SelectField from '../../components/fields/select';
import AddIcon from '@material-ui/icons/Add';
import AddProgramIllus from '../../assets/images/illustration/add-section-1.svg'
import Button from '@material-ui/core/Button';

//Custom component
import TableComponent from '../../components/common/table-component';
import TextAreaField from '../../components/fields/textarea';
import Header from '../../components/header'
import InputField from '../../components/fields/inputs';
import UrlTabActions from '../../components/common/url-tab-actions';
import MultipleTabActions from '../../components/common/multiple-tab-actions';
import { Scrollbars } from 'react-custom-scrollbars';

//API
import * as ProgramApi from '../../api/program-api';
import * as StorageFileApi from '../../api/file-storage-api';
import * as ClientApi from '../../api/client-api';

const tableRows = [
    { id: 'slNo', numeric: false, disablePadding: false, label: 'Sl no' },
    { id: 'name', numeric: true, disablePadding: false, label: 'Name' },
    { id: 'description', numeric: true, disablePadding: false, label: 'Description' },
    { id: 'createdOn', numeric: true, disablePadding: false, label: 'Created on' },
    { id: 'updatedOn', numeric: true, disablePadding: false, label: 'Updated on' },
    { id: 'actions', numeric: true, disablePadding: false, label: 'Actions' },
];

function createData(slNo, programName, programKey, programDesc, createdOn, updatedOn, clientId, redirectProgram, handleEditProgramChange) {
    let programNameView = (<div className="table-name"><UrlTabActions formName={programName} withImg={false} handleNameClick={redirectProgram(programKey, clientId)} /></div>);
    let actionView = (<MultipleTabActions type="icons" iconList={["edit"]} handleEditClick={handleEditProgramChange(programKey)} />)
    return { slNo, programNameView, programDesc, createdOn, updatedOn, actionView };
}

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

class ProgramClientDashboard extends Component {

    state = {
        tableData: [],
        openProgram: false,
        programData: [],
        programDataCreate: {},
        uploadFile: null,
        clientName: "",
        optionsData: [],
        clientData: [],
        searchedClientData: [],
        openInvalidTypeDialog: false
    }

    componentWillMount() {
        this.getAllProgramData();
        this.getAllClientData();
    }

    componentDidMount() {
        let { programDataCreate } = this.state;
        programDataCreate["program_type"] = "individual";
        this.setState({ programDataCreate: programDataCreate });
    }

    getAllProgramData() {
        let tableData = [];
        const match = this.props.match;
        let clientId = match.params.clientId;
        ProgramApi.getAllProgramByClientId(clientId).then(programData => {
            programData.forEach((data, index) => {
                tableData.push(createData(++index, data.program_name, data.program_key, data.program_desc, data.created_on, data.modified_on, data.client_id, this.redirectProgram, this.handleEditProgramChange));
            })
            this.setState({ tableData: tableData, programData: programData });
        })
    }

    handleEditProgramChange = (programKey) => value => {
        ProgramApi.getProgramByProgramKey(programKey).then((data) => {
            this.setState({ programDataCreate: data, openProgram: true });
        });
    }

    getAllClientData() {
        let optionsData = []
        ClientApi.getAllClientStates().then(data => {
            data.forEach(optionData => {
                optionsData.push({
                    field_option_label: optionData.name,
                    field_option_value: optionData.id + ''
                })
            })
            this.setState({ optionsData: optionsData });
        })
    }

    handleClose(key) {
        this.setState({ [key]: false, programDataCreate: {} });
    }

    handleOpen(key) {
        this.setState({ [key]: true });
    }

    handleProgramChange = (type) => (value) => {
        let { programDataCreate, uploadFile } = this.state;
        if (type === 'program_type' && programDataCreate.type !== value) {
            this.setState({ uploadFile: null })
        }
        programDataCreate[type] = value;
        this.setState({ programDataCreate: programDataCreate });
    }

    handleUploadCreation = () => (file) => {
        let allowedExtensions = /(\.docx|\.doc|\.pdf)$/i;

        if (!allowedExtensions.exec(file.name)) {
            this.setState({ openInvalidTypeDialog: true });
        } else {
            this.setState({ uploadFile: file, })
        }
    }

    handleClose(key) {
        this.setState({ [key]: false });
    }

    handleDeleteFile() {
        this.setState({ uploadFile: null });
    }

    handleOnProgramSelectField(key, type, value, check) {
        let { programDataCreate } = this.state;
        this.setState({ clientName: key });
        programDataCreate[type] = key;
        this.setState({ programDataCreate: programDataCreate });
    }

    createProgramData() {
        let { uploadFile, programDataCreate } = this.state;
        const match = this.props.match;
        let clientId = match.params.clientId;
        programDataCreate["client_id"] = clientId;
        if (programDataCreate.program_name && programDataCreate.program_type && uploadFile) {
            const formData = new FormData();
            formData.append("file", uploadFile, uploadFile.name);
            StorageFileApi.postFileUpload(formData).then(response => {
                programDataCreate["master_policy_file_upload"] = response.file_id;
                ProgramApi.createProgram(programDataCreate).then(res => {
                    console.log("update program", res.program_key + " " + res.client_id);
                    this.redirectProgram(res.program_key, res.client_id).apply();
                })
            })
        }
    }

    redirectProgram = (programKey, clientId) => event => {
        window.location = "/" + clientId + "/" + programKey + "/program-dashboard";
    }

    searchBtnClick() {
        let { programData, customStyleSearch } = this.state;
        let searchedProgramData = [];
        let tableData = [];
        searchedProgramData = programData.filter(data => (data.program_name.toLowerCase().includes(customStyleSearch.toLowerCase())));

        if (searchedProgramData && searchedProgramData.length > 0) {
            searchedProgramData.forEach((data, index) => {
                tableData.push(createData(++index, data.program_name, data.program_key, data.program_desc, data.created_on, data.modified_on, data.client_id, this.redirectProgramOnClick, this.handleEditProgramChange));
            })
            this.setState({ tableData: tableData });
        }
    }

    handleOnChangeValue(event) {
        let { programData } = this.state;
        if (event.target.value === "") {
            if (programData && programData.length > 0) {
                let tableData = [];
                programData.forEach((data, index) => {
                    tableData.push(createData(++index, data.program_name, data.program_key, data.program_desc, data.created_on, data.modified_on, data.client_id, this.redirectProgramOnClick, this.handleEditProgramChange));
                })
                this.setState({ tableData: tableData });
            }
        }
        this.setState({ customStyleSearch: event.target.value });
    }

    render() {
        let { openProgram, customStyleSearch, optionsData, clientName, uploadFile, programDataCreate, tableData } = this.state;
        const match = this.props.match;
        let clientId = match.params.clientId;
        let programType = [
            {
                field_option_label: "Individual",
                field_option_value: "individual",
            },
            {
                field_option_label: "Group",
                field_option_value: "group"
            }
        ]
        let defaultSectionsList = optionsData

        return (
            <div className="FormBuilderScene">
                <Header headerType="dashboard" positionType="fixed" clientId={clientId} />
                <WorkflowClientHeader workflowType="normal" tab="program" clientId={clientId} />
                <main>
                    {
                        <div className="workflow-fields-sec workflow-fields-dashboard">
                            <div className="drawer-tabs drawer-tabs-round no-drawer-indicator workflow-tabs-wrap">
                                <div className="drawer-tabs-header">
                                    <div></div>
                                    <div className="workflow-tabs-search">
                                        <SearchField placeholder="Search here" searchType="normal" customStyle={customStyleSearch} handleOnChangeValue={this.handleOnChangeValue.bind(this)} searchBtnClick={this.searchBtnClick.bind(this)} />
                                        <Fab variant="extended" size="large" className="btn btn-large workflow-add-btn no-shadow" onClick={event => this.handleOpen("openProgram")} style={{ minWidth: '175px' }}>
                                            <AddIcon /> New Program
                                        </Fab>
                                    </div>
                                </div>
                                <div className="tab-container">
                                    {tableData.length > 0 && (
                                        <div className="table-wrapper vertical-bottom">
                                            <TableComponent
                                                tableLabel=""
                                                rows={tableRows}
                                                isPagginated={true}
                                                isSearchable={false}
                                                data={tableData}
                                                searchLabel=""
                                                isCheckBoxEnabled={false}
                                            />
                                        </div>
                                    )}
                                    {tableData.length <= 0 && (
                                        <section className="blank-page-sec">
                                            <div className="blank-page-wrap">
                                                <div className="blank-page-icon">
                                                    <img src={AddProgramIllus} alt="AddProgramIllus" />
                                                </div>
                                                <div className="blank-page-info">
                                                    <h5>You haven't added any Program yet!</h5>
                                                    <h6>Create Program and attach forms and flows to it according to your business requirement</h6>
                                                </div>
                                                <Fab variant="extended" size="large" className="btn btn-large workflow-add-btn no-shadow primary-color" onClick={event => this.handleOpen("openProgram")}>
                                                    Add Program
                                                </Fab>
                                            </div>
                                        </section>
                                    )}
                                </div>
                            </div>

                            {/* Start Dialog for Create program form */}
                            <Dialog open={openProgram} TransitionComponent={Transition} maxWidth="xl">
                                <section className="workflow-dialog workflow-dialog-large">
                                    <div className="card-block">
                                        <div className="card-header border-bottom">
                                            <div className="sub-title">
                                                <h5>Program</h5>
                                            </div>
                                            <div className="close-dialog">
                                                <img src={closeIcon} alt="CloseIcon" onClick={() => this.handleClose("openProgram")}></img>
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <Grid container>
                                                <Grid item md={4}>
                                                    <div className="workflow-illust-wrap">
                                                        <div className="sub-title">
                                                            <h2>Create a New Program</h2>
                                                        </div>
                                                        <div className="workflow-illust">
                                                            <img src={WorkflowIllust} alt="workflowIllust" />
                                                        </div>
                                                    </div>
                                                </Grid>
                                                <Grid item md={8}>
                                                    <div className="workflow-dialog-forms form-builder-dashboard">
                                                        <div className="tabs-container tabs-container-scroll">
                                                            <Scrollbars style={{ width: '100%', height: '100%' }}>
                                                                <div className="tabs-container-scroll-wrap">
                                                                    <Grid item md={12}>
                                                                        <div className="workflow-form-scene form-row">
                                                                            <div className="workflow-form-select active">
                                                                                <Button variant="contained" color="primary" className="btn">
                                                                                    <img src={ScratchIllus} alt="WorkflowIllust" ></img>
                                                                                </Button>
                                                                                <h5>Scratch</h5>
                                                                            </div>
                                                                            <div className="workflow-form-info">
                                                                                <h6 className="sub-title semi-bold">Create a form from scratch?</h6>
                                                                                <h6 className="grey-color weight-300">You can create your program from scratch by creating and adding sections, forms, flows and users</h6>
                                                                            </div>
                                                                        </div>
                                                                    </Grid>
                                                                    <div className="workflow-form-field">
                                                                        <Grid container lg={12} md={12}>
                                                                            <Grid item lg={5} md={8}>
                                                                                <InputField label="Name of the Program" type="text" name="form_name" selected_value={programDataCreate.program_name} fieldStyle="field-box bg-plane grey-color" placeholder="Name" is_mandatory="yes" handleOnChangeValue={this.handleProgramChange("program_name").bind(this)} />
                                                                            </Grid>
                                                                            <Grid item lg={5} md={8}>
                                                                                <RadioField
                                                                                    radioGroupLabel="Program_Type"
                                                                                    radioData={programType}
                                                                                    label="Program Type"
                                                                                    radioFieldStyle="field-inline"
                                                                                    default_value="individual"
                                                                                    selected_value={programDataCreate.program_type}
                                                                                    radioChangeHandle={this.handleProgramChange("program_type").bind(this)}
                                                                                />
                                                                            </Grid>
                                                                        </Grid>
                                                                        <Grid container lg={12} md={12}>
                                                                            <Grid item lg={5} md={8}>
                                                                                <TextAreaField label="Description" name="form_description" rowmax="3" selected_value={programDataCreate.program_desc} fieldStyle="field-box bg-plane grey-color" placeholder="Description" handleOnChangeValue={this.handleProgramChange("program_desc").bind(this)} />
                                                                            </Grid>
                                                                            <Grid item lg={5} md={8}>

                                                                                <UploadField
                                                                                    label="Master Policy Upload"
                                                                                    type="file"
                                                                                    name="upload"
                                                                                    customStyle="upload-file"
                                                                                    fieldStyle="field-box bg-plane grey-color field-upload"
                                                                                    uploadFile={uploadFile}
                                                                                    handleUploadCreation={this.handleUploadCreation().bind(this)}
                                                                                    is_mandatory="yes"
                                                                                    handleDeleteFile={() => this.handleDeleteFile()}
                                                                                />
                                                                            </Grid>
                                                                        </Grid>
                                                                        {/* <Grid container lg={12} md={12}>
                                                                        <Grid item lg={5} md={8}>
                                                                            <SelectField
                                                                                label="Select Client"
                                                                                withLabel={true}
                                                                                optionsData={defaultSectionsList}
                                                                                selected_value={clientName}
                                                                                default_value={clientName}
                                                                                fieldStyle="field-box bg-plane grey-color"
                                                                                radioChangeHandle={(key, value, errors) => this.handleOnProgramSelectField(key, "client_id", value, 'program')}
                                                                            />
                                                                        </Grid>
                                                                    </Grid> */}
                                                                    </div>
                                                                </div>
                                                            </Scrollbars>
                                                        </div>
                                                        <div className="form-row workflow-dialog-footer">
                                                            <div className="btn-wrap no-border">
                                                                <Fab variant="extended" size="large" className="btn btn-large btn-plane primary-color" onClick={this.createProgramData.bind(this)}>
                                                                    Create Program
                                                                </Fab>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </div>
                                    </div>
                                </section>
                            </Dialog>
                            {/* End Dialog for Create program form */}
                            <Dialog open={this.state.openInvalidTypeDialog} onClose={event => this.handleClose("openInvalidTypeDialog")} TransitionComponent={Transition} >
                                <div className="card-block">
                                    <div className="card-header border-bottom" style={{ color: 'white', backgroundColor: 'orange', paddingLeft: '5px', paddingBottom: '5px', paddingRight: '0px' }}>
                                        <div className="sub-title">
                                            <h2>Alert!</h2>
                                        </div>
                                    </div>
                                    <div className="card-body" style={{ paddingTop: '20px' }}>
                                        <Grid container>
                                            <Grid item md={12}>
                                                <p style={{ whiteSpace: 'break-spaces' }}><br /> Invalid file format, please upload a file with one of the following formats
                                                    (.pdf .doc .docx)</p>
                                                <div className="workflow-dialog-forms form-builder-dashboard">
                                                    <div className="form-row workflow-dialog-footer">
                                                        <div className="btn-wrap no-border">

                                                            <Fab variant="extended" className="btn btn-large" style={{ height: '35px', paddingLeft: '0px', paddingRight: '0px', border: '1px solid orange', color: 'orange', backgroundColor: 'white', float: 'right' }} onClick={event => this.handleClose("openInvalidTypeDialog")}>
                                                                Done
                                                            </Fab>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Grid>
                                            <Grid item md={3}>
                                            </Grid>
                                        </Grid>
                                    </div>
                                </div>
                            </Dialog>

                        </div>
                    }
                </main>
            </div>
        )
    }
}

export default ProgramClientDashboard;