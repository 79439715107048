import React, { Component } from 'react';
import Fab from '@material-ui/core/Fab';
import editIcon from '../../assets/images/icon/edit-grey.svg';
import verDots from '../../assets/images/icon/more-vert.svg';
import Add from '@material-ui/icons/Add';
import Button from '@material-ui/core/Button';
import Icons from '../../components/common/icon-common';

class SectionFields extends Component {

    handleStateDelete = (id) => () => {
        this.props.handleDeleteOfState(id);
    }

    handleSubStateDelete = (id) => () => {
        this.props.handleDeleteOfSubState(id);
    }

    render() {
        let { stateData, sectionDetails } = this.props;
        return (
            <main>
                {
                    <section className="workflow-fields-sec">
                        <div className="card-block">
                            <div className="sub-title">
                                <h5>Add a state that will be present in the section <span><strong>{sectionDetails.section_name}</strong></span>, while a record is being <span><strong>worked on</strong></span></h5>
                            </div>
                            <div className="workflow-fields-wrap">
                                <div className="fields-wrap">
                                    {
                                        stateData.map((data, index) => {
                                            return (
                                                <div className="fields-list">
                                                    {
                                                        !data.sub_state &&
                                                        <div className="card-block">
                                                            <div className={data.sub_states_list.length === 0 ? "card-header " : "card-header border-bottom"}>
                                                                <div className="card-title">
                                                                    <span className="card-holde-left"></span>
                                                                    <h5>{data.state_name} &nbsp;{data.state_type && (<span className="state-tag musturd-color">{data.state_type}</span>)}</h5>
                                                                </div>
                                                                <div className="actions">
                                                                    <div className="btn-wrap">
                                                                        <Fab aria-label="edit" className="fabs-icons" onClick={(event) => { this.props.toggleDrawer('addState', true); this.props.editStatusChange(true, index); this.props.displayStateComponentsChange(true); this.props.newStateOrEditChange("edit") }}>
                                                                            <img alt="edit" src={editIcon}></img>
                                                                        </Fab>
                                                                        <Fab aria-label="delete" className="fabs-icons" onClick={this.handleStateDelete(data.id).bind(this)}>
                                                                            <img alt="delete" src={Icons.deleteIcon}></img>
                                                                        </Fab>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className={data.sub_states_list.length === 0 ? "" : "card-body"}>
                                                                <div className="w-fields-prop-wrap">
                                                                    {
                                                                        data.sub_states_list.map((list, i) => {
                                                                            return (
                                                                                <div className="w-fields-list">
                                                                                    <div className="w-fields-grp">
                                                                                        <span className="w-fields-sort"><img src={verDots} alt="verDots" /><img src={verDots} alt="verDots" /></span>
                                                                                        <div className="card-title">
                                                                                            <h6><span style={{ cursor: 'pointer' }}>{list.state_name}</span> {list.state_description} </h6>
                                                                                        </div>
                                                                                        <div className="actions">
                                                                                            <div className="btn-wrap">
                                                                                                <Fab aria-label="edit" className="fabs-icons" onClick={(event) => { this.props.toggleDrawer('addState', true); this.props.editStatusChange(false, index, i); this.props.displayStateComponentsChange(false); this.props.newStateOrEditChange("edit") }}>
                                                                                                    <img alt="edit" src={editIcon}></img>
                                                                                                </Fab>
                                                                                                <Fab aria-label="delete" className="fabs-icons" onClick={this.handleSubStateDelete(list.id).bind(this)}>
                                                                                                    <img alt="delete" src={Icons.deleteIcon}></img>
                                                                                                </Fab>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="actions">
                                                                                        <div className="btn-wrap">
                                                                                            <Fab aria-label="edit" className="fabs-icons add-icon" onClick={(event) => { this.props.toggleDrawer('addState', true); this.props.displayStateComponentsChange(false, index); this.props.newStateOrEditChange("new") }}>
                                                                                                <Add />
                                                                                            </Fab>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            );
                                                                        })

                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                                <div className="fields-wrap add-fields-wrap">
                                    <div className="fields-list">
                                        <div className="card-block">
                                            <div className="card-header">
                                                <div className="card-title">
                                                    <span className="card-holde-left"><Add /></span>
                                                    <Button onClick={(event) => { this.props.toggleDrawer('addState', true); this.props.displayStateComponentsChange(true); this.props.newStateOrEditChange("new") }}>Add New State</Button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                }
            </main>
        );
    }
}
export default SectionFields;