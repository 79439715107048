import * as AjaxHelper from "../utilities/ajax-helper"
import { ApiConstants } from "./common/api-constants"

let apiBaseUrl = () => (ApiConstants.DEV_MODE === 'true' ? ApiConstants.HOST : window.location.origin) + ApiConstants.API_VERSION;

export function saveWorkflowAction(workflowAction) {
    return AjaxHelper.post(apiBaseUrl() + ApiConstants.WORKFLOW_ACTION, workflowAction, { "Content-Type": "application/json" });
}

export function getWorkflowActions(workflowId) {
    return AjaxHelper.get(apiBaseUrl() + ApiConstants.WORKFLOW_ACTION + "/" + workflowId + "/workflow", null, { "Content-Type": "application/json" });
}

export function deleteWorkflowActionTrigger(triggerId) {
    return AjaxHelper.del(apiBaseUrl() + ApiConstants.WORKFLOW_ACTION + "/" + triggerId, null, { "Content-Type": "application/json" });
}

export function executeWorkflowActionTrigger(triggerId, processId) {
    return AjaxHelper.put(apiBaseUrl() + ApiConstants.WORKFLOW_ACTION + "/" + triggerId + "/process/" + processId, null, { "Content-Type": "application/json" });
}