import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Fab from '@material-ui/core/Fab';
import Grid from '@material-ui/core/Grid';
import Slide from '@material-ui/core/Slide';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import AddIcon from '@material-ui/icons/Add';
import React, { Component } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import ArrowBackGrey from '../../assets/images/icon/chevron-left.svg';
import closeIcon from '../../assets/images/icon/close.svg';
import AddPermissionIllus from '../../assets/images/illustration/add-permission.svg';
import AddRoleCircleIllus from '../../assets/images/illustration/add-role-circle.svg';
import AddRoleIllus from '../../assets/images/illustration/add-role.svg';
import AddUserCircleIllus from '../../assets/images/illustration/add-user-circle.svg';
import AddPermissionCircleIllus from '../../assets/images/illustration/add-permission-circle.svg';
import UserSetPermission from '../../components/common/user-set-permissions';
import CheckBoxField from '../../components/fields/checkbox';
import CloseIcon from '../../assets/images/icon/close.svg';

import AddUserIllus from '../../assets/images/illustration/add-user.svg';
import UsersFlowIllust from '../../assets/images/illustration/users-iso.svg';
import MultipleTabActions from '../../components/common/multiple-tab-actions';
import SwitchTabActions from '../../components/common/switch-tab-actions';
//Custom component
import TableComponent from '../../components/common/table-component';
import InputField from '../../components/fields/inputs';
import SearchField from '../../components/fields/search';
import SelectField from '../../components/fields/select';
import TextAreaField from '../../components/fields/textarea';
import Header from '../../components/header';
import WorkflowHeader from '../../components/workflow/workflow-header';

//API
import * as UserApi from '../../api/user-api';
import * as FormApi from '../../api/form-api';
import { CodeSharp } from '@material-ui/icons';

const tableRows = [
    { id: 'workflow_name', numeric: false, disablePadding: false, label: 'Name' },
    { id: 'details', numeric: true, disablePadding: false, label: 'Details' },
    { id: 'when', numeric: true, disablePadding: false, label: 'When' },
    //{ id: 'status', numeric: true, disablePadding: false, label: 'Status' },
    { id: 'createdOn', numeric: true, disablePadding: false, label: 'Created on' },
    { id: 'UpdatedOn', numeric: true, disablePadding: false, label: 'Updated on' },
    { id: 'actions', numeric: true, disablePadding: false, label: 'Actions' },
];

const tableUserRows = [
    { id: 'name', numeric: false, disablePadding: false, label: 'Name' },
    { id: 'company', numeric: true, disablePadding: false, label: 'Company' },
    { id: 'role', numeric: true, disablePadding: false, label: 'Role' },
    { id: 'email', numeric: true, disablePadding: false, label: 'Email' },
    { id: 'permission', numeric: true, disablePadding: false, label: 'Permission' },
    //{ id: 'status', numeric: true, disablePadding: false, label: 'Status' },
    { id: 'createdOn', numeric: true, disablePadding: false, label: 'Created on' },
    { id: 'UpdatedOn', numeric: true, disablePadding: false, label: 'Updated on' },
    { id: 'actions', numeric: true, disablePadding: false, label: 'Actions' },
];

const tablePermissionRows = [
    { id: 'name', numeric: false, disablePadding: false, label: 'Name' },
    { id: 'description', numeric: true, disablePadding: false, label: 'Description' },
    //{ id: 'status', numeric: true, disablePadding: false, label: 'Status' },
    { id: 'createdOn', numeric: true, disablePadding: false, label: 'Created on' },
    { id: 'UpdatedOn', numeric: true, disablePadding: false, label: 'Updated on' },
    { id: 'actions', numeric: true, disablePadding: false, label: 'Actions' },
];


function createUserData(index, user, userName, userPermission, handleEditChange, handleDeleteClick) {
    let userNameView = userName;
    let companyView = "company";
    let roleView = "role";
    let email = "email";
    let permission = userPermission;
    let createdOn = user.created_on;
    let updatedOn = user.modified_on;
    let actionView = (<MultipleTabActions type="icons" handleEditClick={handleEditChange} handleDeleteClick={handleDeleteClick} iconList={["edit", "delete"]} />)
    return { userNameView, companyView, roleView, email, permission, createdOn, updatedOn, actionView };
}

function createPermissionData(index, permission, handleStatusChange, handleEditChange, handleDeleteClick) {
    let permissionNameUIView = permission.permission_name;
    let description = permission.permission_desc;
    //let statusView = (<SwitchTabActions status={(permission.status === "active")? "enabled" : "disabled"}/>);
    let createdOn = permission.created_on;
    let updatedOn = permission.modified_on;
    let actionView = (<MultipleTabActions type="icons" handleEditClick={handleEditChange} handleDeleteClick={handleDeleteClick} iconList={["edit", "delete"]} />)
    return { permissionNameUIView, description, createdOn, updatedOn, actionView };
}

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


class UsersDashboard extends Component {

    state = {
        tableData: [],
        triggerDialogOpen: false,
        userPermDialogOpen: false,
        userRoleDialogOpen: false,
        userPermissionDialogOpen: false,
        openDeleteDialog: false,
        selectedTriggerType: 0,
        formList: [],
        permissionSet: {
            permission_list: [],
        },
        permissionSetNew: {
            permission_list: [],
        },
        workflowFormData: {
            workflow_trigger: {}
        },
        userTableData: [],
        permissionTableData: [],
        calculationTableData: [],
        paymentTableData: [],
        tabAction: 0,
        workflowData: [],
        customStyleSearch: 'only-small-search',
        userOptionList: [],
        adminUserList: [],
        permissionOptionList: [],
        comapanyList: [],
        roleData: {},
        userData: {},
        deleteRecordId: null,
        formData: [],
    }

    constructor(props) {
        super(props);
        this.getAllUserData(() => this.getPermissionData(() => this.getAllUserPermissionVO()));
        this.getAllOrganization();
        this.getFormData();
        this.getClientAdminUser();
    }

    getFormData() {
        const match = this.props.match;
        let programkey = match.params.programkey;
        FormApi.getAllFormData(programkey).then(formData => {
            let permission_list = [];
            formData.forEach(form => {
                let obj = {
                    module_name: form.form_name,
                    module_id: form.id,
                }
                permission_list.push(obj);
            })
            let permissionSet = this.state.permissionSet;
            permissionSet.permission_list = permission_list;
            let permissionSetNew = JSON.parse(JSON.stringify(permissionSet));
            this.setState({ permissionSet: permissionSet, permissionSetNew: permissionSetNew, formData: formData });
        });
    }

    getPermissionData(callback) {
        const match = this.props.match;
        let programkey = match.params.programkey;
        let permissionOptionList = [];
        UserApi.getAllPermission(programkey).then(permissionDataList => {
            let permissionTableData = [];
            permissionDataList.forEach((permission, index) => {
                let obj = {
                    field_option_label: permission.permission_name,
                    field_option_value: permission.permission_set_id.toString(),
                };
                permissionOptionList.push(obj);
                permissionTableData.push(createPermissionData(index, permission, null, () => this.handleEditChange(permission.permission_set_id), () => this.handleDeleteClick(permission.permission_set_id)));
            })
            this.setState({ permissionTableData: permissionTableData, permissionOptionList: permissionOptionList }, callback);
        }).catch(error => {
            this.setState({ permissionTableData: [] });
        });
    }

    handleEditChange(permission_set_id) {
        let { permissionSet, formData } = this.state;
        UserApi.getPermissionSet(permission_set_id).then(set => {
            permissionSet["permission_set_id"] = set.permission_set_id;
            permissionSet["permission_name"] = set.permission_name;
            permissionSet["permission_desc"] = set.permission_desc;

            if (set.permission_list) {
                permissionSet.permission_list = set.permission_list;

                //This is used to insert the newly added forms into the existing permission sets.
                formData.forEach(form => {
                    let permissionExist = set.permission_list.filter(list => list.module_id === form.id)[0];
                    if (!permissionExist || permissionExist === undefined) {
                        let formObj = {
                            module_name: form.form_name,
                            module_id: form.id,
                            permission_set_id: permissionSet.permission_set_id,
                        }

                        permissionSet.permission_list.push(formObj);
                    }
                })
            }


            this.setState({ permissionSet: permissionSet, userPermissionDialogOpen: true });
        })
    }

    handleDeleteClick(id) {
        this.setState({ openDeleteDialog: true, deleteRecordId: id });
    }

    //new method to add userType and client id...
    getClientAdminUser() {
        console.log("getClientAdminUser method");
        const match = this.props.match;
        let clientId = match.params.clientId
        let adminUserOptionList = [];
        let obj = {};
        UserApi.getAdminClientUser(clientId).then(users => {
            users.forEach((user, index) => {

                if (user.user_type === 0) {
                    obj = {
                        field_option_label: user.user_name,
                        field_option_value: user.user_id.toString(),
                    }

                } else {
                    obj = {
                        field_option_label: user.name,
                        field_option_value: user.user_id,
                    }

                }

                adminUserOptionList.push(obj);

            })
            this.setState({ adminUserList: adminUserOptionList });
        });


    }

    getAllUserData(callback) {
        let userOptionList = [];
        UserApi.getAllUsers().then(users => {
            users.forEach((user, index) => {
                if (user.user_type === 0 || user.user_type === 1) {
                    let obj = {
                        field_option_label: user.user_name,
                        field_option_value: user.user_id.toString(),
                    };
                    userOptionList.push(obj);
                }

            })
            this.setState({ userOptionList: userOptionList }, callback);
        });

    }

    getAllUserPermissionVO() {
        const match = this.props.match;
        let programkey = match.params.programkey;
        console.log("users666666666", programkey);

        UserApi.getUserPermissionMapping(programkey).then(users => {
            let userTableData = []
            users.forEach((user, index) => {
                console.log("useroption", this.state.userOptionList, "this.state.permissionOptionList", this.state.permissionOptionList)
                let userName = this.state.userOptionList.filter(userlist => (userlist.field_option_value === user.user_id.toString()))[0];
                userName = userName && userName.field_option_label
                let userPermission = this.state.permissionOptionList.filter(permission => (permission.field_option_value === user.permission_set_id.toString()))[0];
                userPermission = userPermission && userPermission.field_option_label;
                if (userName && userPermission) {
                    userTableData.push(createUserData(index, user, userName, userPermission, () => this.handleUserEditChange(user.user_permission_mapping_id), () => this.handleDeleteClick(user.user_permission_mapping_id)));
                }
            })
            this.setState({ userTableData: userTableData })
        })
            .catch(error => {
                this.setState({ userTableData: [] })
            });
    }

    handleUserEditChange(userPermissionMappingId) {
        let { userOptionList, permissionOptionList } = this.state;
        UserApi.getUserPermissionMappingById(userPermissionMappingId).then(userData => {
            userData.user_id = userOptionList.filter(userList => (userList.field_option_value === userData.user_id.toString()))[0].field_option_value;
            userData.permission_set_id = permissionOptionList.filter(permission => (permission.field_option_value === userData.permission_set_id.toString()))[0].field_option_value;
            userData.allow_draft_view = userData.allow_draft_view ? "allow_draft_view" : "";
            userData.permission_desc = userData.permission_desc;
            this.setState({ userData: userData, userPermDialogOpen: true })
        })
    }

    getAllOrganization() {
        let comapanyList = [];
        UserApi.getAllOrganization().then(res => {
            res.forEach(organization => {
                let obj = {
                    field_option_label: organization.company_name,
                    field_option_value: organization.company_name,
                };
                comapanyList.push(obj);
            });
            this.setState({ comapanyList: comapanyList });
            console.log("organiztion ", this.state.comapanyList);
        });
    }

    saveUserRole() {
        UserApi.addUserRole(this.state.roleData).then(res => {
            console.log("role made ", res);
        });
    }
    saveUserData() {
        const match = this.props.match;
        let programkey = match.params.programkey;
        let { userData, userOptionList } = this.state;
        let userDetails = userOptionList.filter(userOption => userOption.field_option_value === userData.user_id.toString())[0];
        userData["allow_draft_view"] = userData.allow_draft_view === "allow_draft_view";
        userData["user_name"] = userDetails.field_option_label;
        userData["program_key"] = programkey;
        UserApi.addUserPermissionMapping(userData).then(res => {
            console.log("userData ", res);
            this.setState({ userPermDialogOpen: false, userData: {} }, () => this.getAllUserPermissionVO());
        });
    }

    handleRoleChange(type, value) {
        let roleData = this.state.roleData;
        roleData[type] = value;
        this.setState({ roleData: roleData });
        console.log("type ", type, value, this.state.roleData)
    }

    handleStatusChange = (index, formId, workflowStatus) => event => {
        workflowStatus = (workflowStatus === "active") ? "inactive" : "active";
    }

    editWorkflowRedirect = key => event => {
        event.stopPropagation();
        this.editWorkflow(key);
    }

    handleDialogOpen(type) {
        this.setState({ [type]: true });
    }

    handleClose = (type) => (event) => {
        this.setState({ [type]: false, userData: {} });
    }

    createUserflow(triggerOpen) {
        if (triggerOpen === "userPermissionDialogOpen") {
            this.setState({ permissionSet: this.state.permissionSetNew }, () => this.handleDialogOpen(triggerOpen))
        } else {
            this.handleDialogOpen(triggerOpen);
        }
    }

    selectTriggerHandle = (triggerType) => (event) => {
        this.setState({ selectedTriggerType: triggerType });
        this.handleClose("triggerDialogOpen");
        this.handleDialogOpen("userPermDialogOpen");
        this.setWorkflowFormData("workflow_type", triggerType);
    }

    handleUserChange(fieldName, value) {
        let userData = this.state.userData;
        userData[fieldName] = value;
        this.setState({ userData: userData });
        console.log("type ", fieldName, value, this.state.userData)
    }

    setWorkflowFormData(fieldName, value) {
        let { workflowFormData } = this.state;
        if (fieldName.split(".").length > 1) {
            let fieldSplit = fieldName.split(".");
            fieldName = fieldSplit[0];
            if (!workflowFormData[fieldName]) {
                workflowFormData[fieldName] = {};
            }
            workflowFormData[fieldName][fieldSplit[1]] = value;
        } else {
            workflowFormData[fieldName] = value;
        }
        this.setState({ workflowFormData: workflowFormData });
    }


    handleActionChange(event, newValue) {
        this.setState({ tabAction: newValue });
    }

    searchBtnClick() {
        this.setState({ customStyleSearch: "" });
    }

    updatePermissionData(permissionData) {
        let { permissionSet } = this.state;
        permissionSet.permission_list = permissionData;
        this.setState({ permissionSet: permissionSet });
    }

    onChangeEvent(value, error, key) {
        let { permissionSet } = this.state;
        permissionSet[key] = value;
        this.setState({ permissionSet: permissionSet });
    }

    savePermissionData() {
        const match = this.props.match;
        let programkey = match.params.programkey;
        let updatePermissionData = this.state.permissionSet;
        //updatePermissionData.permission_list = this.filterEmptyPermission(updatePermissionData.permission_list);
        updatePermissionData["program_key"] = programkey;
        console.log("updatePermissionData_777", updatePermissionData);
        UserApi.postPermission(updatePermissionData).then(permisionRes => {
            this.setState({ userPermissionDialogOpen: false }, () => this.getAllUserData(() => this.getPermissionData(() => this.getAllUserPermissionVO())))
            this.setState({
                permissionSet: {
                    permission_list: [],
                }
            }, () => this.getFormData());
        })
    }

    filterEmptyPermission(permisionList) {
        return permisionList.filter(permission => permission.is_access || permission.is_delete || permission.is_read || permission.is_write || permission.is_create);
    }

    handleDeleteClose() {
        this.setState({ openDeleteDialog: false });
    }

    handleDeletePermission() {
        let { deleteRecordId } = this.state;
        UserApi.deleteUserPermission(deleteRecordId).then(data => {
            this.setState({ openDeleteDialog: false, deleteRecordId: null }, () => this.getPermissionData(() => this.getAllUserPermissionVO()));
        })
    }

    handleDeleteUserPermissionMapping() {
        let { deleteRecordId } = this.state;
        UserApi.deleteUserPermissionMappingById(deleteRecordId).then(data => {
            this.setState({ openDeleteDialog: false, deleteRecordId: null }, () => this.getAllUserPermissionVO());
        })
    }

    //Delete Dialog for User and Permission Tab
    renderDialogDeleteActionTrigger() {
        let { tabAction } = this.state;
        return (
            <Dialog open={this.state.openDeleteDialog} onClose={this.handleDeleteClose.bind(this)} aria-labelledby="simple-dialog-title">
                <section className="workflow-dialog workflow-dialog-small workflow-dialog-pri">
                    <div className="card-block">
                        <div className="card-header border-bottom p-b-25">
                            <div className="sub-title">
                                <h2 className="bold">Delete</h2>
                            </div>
                            <div className="close-dialog">
                                <img src={CloseIcon} alt="CloseIcon" onClick={this.handleDeleteClose.bind(this)} ></img>
                            </div>
                        </div>
                        <div className="card-body p-t-25 p-b-45">
                            <div className="sub-title">
                                <h5 className="weight-300">Do you really want to delete the
                                    {tabAction == 0 ? " User " : " Permission "} ?
                                </h5>
                            </div>
                            <div className="card-footer p-0 p-t-25">
                                <div className="btn-wrap">
                                    <Fab variant="extended" size="large" className="btn btn-large btn-plane primary-color" onClick={tabAction == 0 ? this.handleDeleteUserPermissionMapping.bind(this) : this.handleDeletePermission.bind(this)}>
                                        Delete
                                    </Fab>
                                    <Fab variant="extended" size="large" className="btn btn-large btn-plane grey-color" onClick={this.handleDeleteClose.bind(this)} >
                                        Cancel
                                    </Fab>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </Dialog>
        );
    }

    render() {
        let { triggerDialogOpen, userPermDialogOpen, userRoleDialogOpen, userPermissionDialogOpen, tabAction, customStyleSearch, userTableData, permissionTableData, calculationTableData, userOptionList, adminUserList, permissionSet, comapanyList, roleData, userData, permissionOptionList } = this.state;
        console.log("permissionSet_900", permissionSet);
        const match = this.props.match;
        let clientId = match.params.clientId;
        let programkey = match.params.programkey;
        let allowDraftView = [
            {
                field_option_label: "Allow draft view",
                field_option_value: "allow_draft_view",
                checked: userData.allow_draft_view === "allow_draft_view",
            }
        ]
        let shareDataPeer = [
            {
                field_option_label: "Share data with peers",
                field_option_value: true,
                checked: roleData.share_peer,
            }
        ]

        return (
            <div className="FormBuilderScene">
                <Header headerType="dashboard" positionType="fixed" programKey={programkey} />
                <WorkflowHeader workflowType="normal" tab="users" clientId={clientId} programKey={programkey} />
                {this.renderDialogDeleteActionTrigger()}
                <main>
                    {
                        <div className="workflow-fields-sec workflow-fields-dashboard">
                            <div className="drawer-tabs drawer-tabs-round no-drawer-indicator workflow-tabs-wrap">
                                <div className="drawer-tabs-header">
                                    <Tabs value={tabAction} onChange={this.handleActionChange.bind(this)}>
                                        <Tab label="User" />
                                        <Tab label="Permission" />
                                        {/* <Tab label="Role" /> */}
                                    </Tabs>
                                    <div className="workflow-tabs-search">
                                        <SearchField placeholder="Search here" searchType="advance" customStyle={customStyleSearch} searchBtnClick={this.searchBtnClick.bind(this)} />

                                        {tabAction === 0 &&
                                            (<Fab variant="extended" size="large" className="btn btn-large workflow-add-btn no-shadow" onClick={(event) => this.createUserflow("userPermDialogOpen")}>
                                                <AddIcon />
                                                Add Users
                                            </Fab>)
                                        }
                                        {tabAction === 1 &&
                                            (<Fab variant="extended" size="large" className="btn btn-large workflow-add-btn no-shadow" onClick={(event) => this.createUserflow("userPermissionDialogOpen")}>
                                                <AddIcon />
                                                Add Permission
                                            </Fab>)
                                        }
                                        {tabAction === 2 &&
                                            (<Fab variant="extended" size="large" className="btn btn-large workflow-add-btn no-shadow" onClick={(event) => this.createUserflow("userRoleDialogOpen")}>
                                                <AddIcon />
                                                Add Role
                                            </Fab>)
                                        }
                                    </div>
                                </div>

                                {tabAction === 0 &&
                                    <div className="tab-container">
                                        {userTableData.length > 0 && (
                                            <div className="table-wrapper vertical-bottom">
                                                <TableComponent
                                                    tableLabel=""
                                                    rows={tableUserRows}
                                                    isPagginated={true}
                                                    isSearchable={false}
                                                    data={userTableData}
                                                    searchLabel=""
                                                    isCheckBoxEnabled={false}
                                                />
                                            </div>
                                        )
                                        }
                                        {userTableData.length <= 0 && (
                                            <section className="blank-page-sec">
                                                <div className="blank-page-wrap">
                                                    <div className="blank-page-icon">
                                                        <img src={AddUserIllus} alt="AddUserIllus" />
                                                    </div>
                                                    <div className="blank-page-info">
                                                        <h5>You haven't added any users yet!</h5>
                                                        <h6>Add users and assign permissions and roles to them</h6>
                                                    </div>
                                                    <Fab variant="extended" size="large" className="btn btn-large workflow-add-btn no-shadow primary-color" onClick={() => this.createUserflow("userPermDialogOpen")}>
                                                        Add User
                                                    </Fab>
                                                </div>
                                            </section>
                                        )}
                                    </div>}
                                {tabAction === 1 &&
                                    <div className="tab-container">
                                        {permissionTableData.length > 0 && (
                                            <div className="table-wrapper vertical-bottom">
                                                <TableComponent
                                                    tableLabel=""
                                                    rows={tablePermissionRows}
                                                    isPagginated={true}
                                                    isSearchable={false}
                                                    data={permissionTableData}
                                                    searchLabel=""
                                                    isCheckBoxEnabled={false}
                                                />
                                            </div>
                                        )}
                                        {permissionTableData.length <= 0 && (
                                            <section className="blank-page-sec">
                                                <div className="blank-page-wrap">
                                                    <div className="blank-page-icon">
                                                        <img src={AddPermissionIllus} alt="AddPermissionIllus" />
                                                    </div>
                                                    <div className="blank-page-info">
                                                        <h5>You haven't added any permission yet!</h5>
                                                        <h6>Create permissions and assign it to your users</h6>
                                                    </div>
                                                    <Fab variant="extended" size="large" className="btn btn-large workflow-add-btn no-shadow primary-color"
                                                        onClick={(event) => this.createUserflow("userPermissionDialogOpen")}>
                                                        Add Permission
                                                    </Fab>
                                                </div>
                                            </section>
                                        )}
                                    </div>}
                                {tabAction === 2 &&
                                    <div className="tab-container">
                                        {calculationTableData.length > 0 && (
                                            <div className="table-wrapper vertical-bottom">
                                                <TableComponent
                                                    tableLabel=""
                                                    rows={tableRows}
                                                    isPagginated={true}
                                                    isSearchable={false}
                                                    data={calculationTableData}
                                                    searchLabel=""
                                                    isCheckBoxEnabled={false}
                                                />
                                            </div>
                                        )}
                                        {calculationTableData.length <= 0 && (
                                            <section className="blank-page-sec">
                                                <div className="blank-page-wrap">
                                                    <div className="blank-page-icon">
                                                        <img src={AddRoleIllus} alt="AddRoleIllus" />
                                                    </div>
                                                    <div className="blank-page-info">
                                                        <h5>You haven't added any roles yet!</h5>
                                                        <h6>Create roles within companies and add users to it</h6>
                                                    </div>
                                                    <Fab variant="extended" size="large"
                                                        className="btn btn-large workflow-add-btn no-shadow primary-color"
                                                        onClick={(event) => this.createUserflow("userRoleDialogOpen")}>
                                                        Add Role
                                                    </Fab>
                                                </div>
                                            </section>
                                        )}
                                    </div>}
                            </div>

                            {/* Dailog for trigger type */}
                            {/* onClick={()=>this.createUserflow("triggerDialogOpen")} */}
                            <Dialog open={triggerDialogOpen} onClose={this.handleClose("triggerDialogOpen")} TransitionComponent={Transition} maxWidth="xl">
                                <section className="workflow-dialog workflow-dialog-large">
                                    <div className="card-block">
                                        <div className="card-header border-bottom">
                                            <div className="sub-title">
                                                <h5>Users</h5>
                                            </div>
                                            <div className="close-dialog">
                                                <img src={closeIcon} alt="" onClick={this.handleClose("triggerDialogOpen")}></img>
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <Grid container>
                                                <Grid item md={4}>
                                                    <div className="sub-title">
                                                        <h2>What type of Users do you want to Add?</h2>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                            <div className="workflow-dialog-options workflow-dialog-options-3">
                                                <div className="worflow-dialog-wrap">
                                                    <div className="workflow-dialog-img">
                                                        <Button variant="contained" color="primary" onClick={(event) => this.createUserflow("userPermDialogOpen")}>
                                                            <img src={AddUserCircleIllus} alt="AddUserCircleIllus"></img>
                                                        </Button>
                                                    </div>
                                                    <h5>User</h5>
                                                </div>
                                                <div className="worflow-dialog-wrap">
                                                    <div className="workflow-dialog-img">
                                                        <Button variant="contained" color="primary" onClick={this.selectTriggerHandle(2).bind(this)}>
                                                            <img src={AddPermissionCircleIllus} alt="AddPermissionCircleIllus"></img>
                                                        </Button>
                                                    </div>
                                                    <h5>Permission</h5>
                                                </div>
                                                <div className="worflow-dialog-wrap">
                                                    <div className="workflow-dialog-img">
                                                        <Button variant="contained" color="primary" onClick={(event) => this.createUserflow("userRoleDialogOpen")}>
                                                            <img src={AddRoleCircleIllus} alt="AddRoleCircleIllus"></img>
                                                        </Button>
                                                    </div>
                                                    <h5>Role</h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </Dialog>

                            {/* Dialog for workflow create new user */}
                            <Dialog open={userPermDialogOpen} onClose={this.handleClose("userPermDialogOpen")} TransitionComponent={Transition} maxWidth="xl">
                                <section className="workflow-dialog workflow-dialog-large">
                                    <div className="card-block">
                                        <div className="card-header border-bottom">
                                            <div className="sub-title">
                                                <div className="close-dialog">
                                                    <Button color="inherit" onClick={this.handleClose("userPermDialogOpen")}>
                                                        <img src={ArrowBackGrey} alt=""></img> Go Back
                                                    </Button>
                                                </div>
                                            </div>
                                            <div className="close-dialog">
                                                <img src={closeIcon} alt="" onClick={this.handleClose("userPermDialogOpen")}></img>
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <Grid container>
                                                <Grid item md={4}>
                                                    <div className="workflow-illust-wrap">
                                                        <div className="sub-title">
                                                            <h2>Create a New User</h2>
                                                        </div>
                                                        <div className="workflow-illust">
                                                            <img src={UsersFlowIllust} alt="UsersFlowIllust" />
                                                        </div>
                                                    </div>
                                                </Grid>
                                                <Grid item md={8}>
                                                    <div className="workflow-dialog-forms">
                                                        <div className="tabs-container tabs-container-scroll">
                                                            <Scrollbars style={{ width: '100%', height: '100%' }}>
                                                                <div className="tabs-container-scroll-wrap">
                                                                    <Grid item md={8}>
                                                                        <SelectField
                                                                            label="Add Users"
                                                                            optionsData={adminUserList}
                                                                            isMultiple={false}
                                                                            radioChangeHandle={(value) => this.handleUserChange("user_id", value)}
                                                                            selected_value={userData.user_id}
                                                                            fieldStyle="field-box bg-plane grey-color" />
                                                                    </Grid>
                                                                    <Grid item md={8}>
                                                                        <SelectField
                                                                            label="Choose a Permission"
                                                                            instruction="This profile only have add and view permission."
                                                                            optionsData={permissionOptionList}
                                                                            isMultiple={false}
                                                                            radioChangeHandle={(value) => this.handleUserChange("permission_set_id", value)}
                                                                            selected_value={userData.permission_set_id}
                                                                            fieldStyle="field-box bg-plane grey-color" />
                                                                    </Grid>
                                                                    <Grid item md={8}>
                                                                        <CheckBoxField
                                                                            selected_value={userData.allow_draft_view}
                                                                            checkData={allowDraftView}
                                                                            customStyle="field-box state-field-check"
                                                                            checkChangeHandle={(value) => this.handleUserChange("allow_draft_view", value)}
                                                                        />
                                                                    </Grid>
                                                                    <Grid item md={8}>
                                                                        <TextAreaField
                                                                            label="Description"
                                                                            name="user_descp"
                                                                            rowmax="6"
                                                                            fieldStyle="field-box bg-plane grey-color"
                                                                            selected_value={userData.permission_desc}
                                                                            handleOnChangeValue={(value) => this.handleUserChange("permission_desc", value)}
                                                                        />
                                                                    </Grid>
                                                                </div>
                                                            </Scrollbars>
                                                        </div>
                                                        <div className="form-row workflow-dialog-footer">
                                                            <div className="btn-wrap no-border">
                                                                <Fab variant="extended" size="large" className="btn btn-large btn-plane primary-color" onClick={this.saveUserData.bind(this)}>
                                                                    Save and Associate
                                                                </Fab>
                                                                <Fab variant="extended" size="large" className="btn btn-large btn-plane grey-color" onClick={this.handleClose("userPermDialogOpen")}>
                                                                    Cancel
                                                                </Fab>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </div>
                                    </div>
                                </section>
                            </Dialog>

                            {/* Dialog for workflow create new permission */}
                            <Dialog open={userPermissionDialogOpen} onClose={this.handleClose("userPermissionDialogOpen")} TransitionComponent={Transition} maxWidth="xl">
                                <section className="workflow-dialog workflow-dialog-large">
                                    <div className="card-block">
                                        <div className="card-header border-bottom">
                                            <div className="sub-title">
                                                <div className="close-dialog">
                                                    <Button color="inherit" onClick={this.handleClose("userPermissionDialogOpen")}>
                                                        <img src={ArrowBackGrey} alt=""></img> Go Back
                                                    </Button>
                                                </div>
                                            </div>
                                            <div className="close-dialog">
                                                <img src={closeIcon} alt="" onClick={this.handleClose("userPermissionDialogOpen")}></img>
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <Grid container>
                                                <Grid item md={4}>
                                                    <div className="workflow-illust-wrap">
                                                        <div className="sub-title">
                                                            <h2>Create a New Permission</h2>
                                                        </div>
                                                        <div className="workflow-illust">
                                                            <img src={UsersFlowIllust} alt="UsersFlowIllust" />
                                                        </div>
                                                    </div>
                                                </Grid>
                                                <Grid item md={8}>
                                                    <div className="workflow-dialog-forms">
                                                        <div className="tabs-container tabs-container-scroll">
                                                            <Scrollbars style={{ width: '100%', height: '100%' }}>
                                                                <div className="tabs-container-scroll-wrap">
                                                                    <Grid item md={8}>
                                                                        <InputField
                                                                            label="Name"
                                                                            name="name"
                                                                            fieldStyle="field-box bg-plane grey-color"
                                                                            selected_value={permissionSet.permission_name}
                                                                            handleOnChangeValue={(value, error) => this.onChangeEvent(value, error, "permission_name")}
                                                                        />
                                                                    </Grid>
                                                                    <Grid item md={8}>
                                                                        <TextAreaField
                                                                            label="Description"
                                                                            name="user_descp"
                                                                            rowmax="6"
                                                                            fieldStyle="field-box bg-plane grey-color"
                                                                            selected_value={permissionSet.permission_desc}
                                                                            handleOnChangeValue={(value, error) => this.onChangeEvent(value, error, "permission_desc")}
                                                                        />
                                                                    </Grid>
                                                                    <Grid item md={8}>
                                                                        <UserSetPermission
                                                                            label="Set Permissions"
                                                                            instruction="Define the permission for records associated with the forms"
                                                                            name="Set Permissions"
                                                                            permissionData={permissionSet.permission_list}
                                                                            updatePermissionData={this.updatePermissionData.bind(this)}
                                                                        />
                                                                    </Grid>
                                                                </div>
                                                            </Scrollbars>
                                                        </div>
                                                        <div className="form-row workflow-dialog-footer">
                                                            <div className="btn-wrap no-border">
                                                                <Fab variant="extended" size="large" className="btn btn-large btn-plane primary-color" onClick={this.savePermissionData.bind(this)}>
                                                                    Save and Associate
                                                                </Fab>
                                                                <Fab variant="extended" size="large" className="btn btn-large btn-plane grey-color" onClick={this.handleClose("userPermissionDialogOpen")}>
                                                                    Cancel
                                                                </Fab>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </div>
                                    </div>
                                </section>
                            </Dialog>

                            {/* Dialog for workflow create new role */}
                            <Dialog open={userRoleDialogOpen} onClose={this.handleClose("userRoleDialogOpen")} TransitionComponent={Transition} maxWidth="xl">
                                <section className="workflow-dialog workflow-dialog-large">
                                    <div className="card-block">
                                        <div className="card-header border-bottom">
                                            <div className="sub-title">
                                                <div className="close-dialog">
                                                    <Button color="inherit" onClick={this.handleClose("userRoleDialogOpen")}>
                                                        <img src={ArrowBackGrey} alt=""></img> Go Back
                                                    </Button>
                                                </div>
                                            </div>
                                            <div className="close-dialog">
                                                <img src={closeIcon} alt="" onClick={this.handleClose("userRoleDialogOpen")}></img>
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <Grid container>
                                                <Grid item md={4}>
                                                    <div className="workflow-illust-wrap">
                                                        <div className="sub-title">
                                                            <h2>Create a New Role</h2>
                                                        </div>
                                                        <div className="workflow-illust">
                                                            <img src={UsersFlowIllust} alt="UsersFlowIllust" />
                                                        </div>
                                                    </div>
                                                </Grid>
                                                <Grid item md={8}>
                                                    <div className="workflow-dialog-forms">
                                                        <div className="tabs-container tabs-container-scroll">
                                                            <Scrollbars style={{ width: '100%', height: '100%' }}>
                                                                <div className="tabs-container-scroll-wrap">
                                                                    <Grid item md={8}>
                                                                        <InputField
                                                                            label="Role Name"
                                                                            type="text"
                                                                            name="name"
                                                                            selected_value={roleData.role_name}
                                                                            fieldStyle="field-box bg-plane grey-color"
                                                                            handleOnChangeValue={(value) => this.handleRoleChange("role_name", value)}
                                                                            placeholder="Name" />
                                                                    </Grid>
                                                                    <Grid item md={8}>
                                                                        <SelectField
                                                                            label="Choose a Company"
                                                                            instruction="Description text"
                                                                            optionsData={comapanyList}
                                                                            isMultiple={false}
                                                                            radioChangeHandle={(value) => this.handleRoleChange("company_name", value)}
                                                                            selected_value={roleData.company_name}
                                                                            fieldStyle="field-box bg-plane grey-color" />
                                                                    </Grid>
                                                                    <Grid item md={8}>
                                                                        <SelectField
                                                                            label="Report to"
                                                                            instruction="Description text"
                                                                            optionsData={userOptionList}
                                                                            isMultiple={false}
                                                                            radioChangeHandle={(value) => this.handleRoleChange("report_to", value)}
                                                                            selected_value={roleData.report_to}
                                                                            fieldStyle="field-box bg-plane grey-color" />
                                                                    </Grid>
                                                                    <Grid item md={8}>
                                                                        <TextAreaField
                                                                            label="Description"
                                                                            name="role_descp"
                                                                            rowmax="6"
                                                                            selected_value={roleData.role_desc}
                                                                            placeholder="Description"
                                                                            handleOnChangeValue={(value) => this.handleRoleChange("role_desc", value)}
                                                                            fieldStyle="field-box bg-plane grey-color" />
                                                                    </Grid>
                                                                    <Grid item md={8}>
                                                                        <CheckBoxField
                                                                            selected_value={roleData.share_peer}
                                                                            checkData={shareDataPeer}
                                                                            customStyle="field-box state-field-check"
                                                                            checkChangeHandle={(value) => this.handleRoleChange("share_peer", value)}
                                                                        />
                                                                        {/* <Checkbox
                                                                        value={roleData.share_peer}
                                                                        name="Share peers"
                                                                        onChange={(event) => this.handleRoleChange("share_peer", event.target.checked)}
                                                                    />Share data with peers */}
                                                                    </Grid>
                                                                </div>
                                                            </Scrollbars>
                                                        </div>
                                                        <div className="form-row workflow-dialog-footer">
                                                            <div className="btn-wrap no-border">
                                                                <Fab variant="extended" size="large" className="btn btn-large btn-plane primary-color" onClick={this.saveUserRole.bind(this)}>
                                                                    Save
                                                                </Fab>
                                                                <Fab variant="extended" size="large" className="btn btn-large btn-plane grey-color" onClick={this.handleClose("userRoleDialogOpen")}>
                                                                    Cancel
                                                                </Fab>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </div>
                                    </div>
                                </section>
                            </Dialog>
                        </div>
                    }
                </main>
            </div>
        )
    }
}

export default UsersDashboard;