import React, { Component } from 'react';
import SelectField from '../fields/select';
import InputField from '../fields/inputs';

import DateKendo from '../kendo/date-picker';
import TimePickerKendo from '../kendo/time-picker';
import DateTimePickerKendo from '../kendo/date-time-picker';

//Constants
import * as FormConstant from '../../utilities/constants'

class FieldValueComponent extends Component {

    render() {
        let { fieldDataList, fieldOperation, check, index, customStyle, fieldLabel } = this.props;
        let field = fieldDataList && fieldDataList.filter(fieldData => (fieldData.id === fieldOperation.field_id))[0];
        let nullChecker = fieldOperation.hasOwnProperty('checkNull')
        let valueField = (
            <InputField
                label={fieldLabel}
                selected_value={fieldOperation.field_value}
                disabled={fieldOperation.hasOwnProperty("value_type") ? false : true}
                handleOnChangeValue={check ? this.props.handleOperationUpdated(index, "field_value") : this.props.handleCondFieldChange("field_value")} customStyle={customStyle} />
        );
        if (field) {
            switch (field.field_type) {

                case FormConstant.FieldIds.F_SINGLE_DROPDOWN_GROUP.code:
                case FormConstant.FieldIds.F_MULTIPLE_DROPDOWN_GROUP.code:
                case FormConstant.FieldIds.F_CHECKBOX_GROUP.code:
                case FormConstant.FieldIds.F_RADIO_GROUP.code:
                    valueField = (
                        <SelectField
                            label={fieldLabel}
                            optionsData={field.field_option_list}
                            selected_value={fieldOperation.field_value}
                            disabled={nullChecker ? fieldOperation.checkNull : false}
                            radioChangeHandle={check ? this.props.handleOperationUpdated(index, "field_value") : this.props.handleCondFieldChange("field_value")} customStyle={customStyle} />
                    );
                    break;
                case FormConstant.FieldIds.F_STATE.code:
                    fieldOperation["metadata"] = "state";
                    valueField = (
                        <SelectField
                            label={fieldLabel}
                            optionsData={field.field_option_list}
                            selected_value={fieldOperation.field_value}
                            disabled={nullChecker ? fieldOperation.checkNull : false}
                            radioChangeHandle={check ? this.props.handleOperationUpdated(index, "field_value") : this.props.handleCondFieldChange("field_value")} customStyle={customStyle} />
                    );
                    break;
                case FormConstant.FieldIds.F_SUB_STATE.code:
                    fieldOperation["metadata"] = "substate";
                    valueField = (
                        <SelectField
                            label={fieldLabel}
                            optionsData={field.field_option_list}
                            selected_value={fieldOperation.field_value}
                            disabled={nullChecker ? fieldOperation.checkNull : false}
                            radioChangeHandle={check ? this.props.handleOperationUpdated(index, "field_value") : this.props.handleCondFieldChange("field_value")} customStyle={customStyle} />
                    );
                    break;
                case FormConstant.FieldIds.F_DATE.code:
                    valueField = (
                        <DateKendo
                            label={fieldLabel}
                            handleDateChange={check ? this.props.handleOperationUpdated(index, "field_value") : this.props.handleCondFieldChange("field_value")}
                            selected_value={fieldOperation.field_value}
                            disabled={nullChecker ? fieldOperation.checkNull : false}
                            name="form_date" customStyle={customStyle} />
                    );
                    break;
                case FormConstant.FieldIds.F_TIME.code:
                    valueField = (
                        <TimePickerKendo
                            label={fieldLabel}
                            handleDateChange={check ? this.props.handleOperationUpdated(index, "field_value") : this.props.handleCondFieldChange("field_value")}
                            selected_value={fieldOperation.field_value}
                            disabled={nullChecker ? fieldOperation.checkNull : false}
                            name="form_date" customStyle={customStyle} />
                    );
                    break;
                case FormConstant.FieldIds.F_DATE_TIME.code:
                    valueField = (
                        <DateTimePickerKendo
                            label={fieldLabel}
                            handleDateChange={check ? this.props.handleOperationUpdated(index, "field_value") : this.props.handleCondFieldChange("field_value")}
                            selected_value={fieldOperation.field_value}
                            disabled={nullChecker ? fieldOperation.checkNull : false}
                            name="form_date" customStyle={customStyle} />
                    );
                    break;
                case FormConstant.FieldIds.F_NUMBER_TXT.code:
                    valueField = (
                        <InputField
                            label={fieldLabel}
                            type="number"
                            selected_value={fieldOperation.field_value}
                            disabled={nullChecker ? fieldOperation.checkNull : false}
                            handleOnChangeValue={check ? this.props.handleOperationUpdated(index, "field_value") : this.props.handleCondFieldChange("field_value")} customStyle={customStyle} />
                    );
                    break;
                default:
                    valueField = (
                        <InputField
                            label={fieldLabel}
                            selected_value={fieldOperation.field_value}
                            disabled={nullChecker ? fieldOperation.checkNull : false}
                            handleOnChangeValue={check ? this.props.handleOperationUpdated(index, "field_value") : this.props.handleCondFieldChange("field_value")} customStyle={customStyle} />
                    );
                    break;
            }
        }

        return valueField;
    }
}

export default FieldValueComponent;