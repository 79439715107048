import React, { Component } from 'react';
/* import SettingFlowIllust from '../../assets/images/illustration/login-iso.svg'; */
import { Scrollbars } from 'react-custom-scrollbars';
import InputField from '../../components/fields/inputs';
import TextAreaField from '../../components/fields/textarea';
import RadioField from '../../components/fields/radio';
import UploadField from '../../components/fields/upload';
import Fab from '@material-ui/core/Fab';
import SwitchButtonActions from '../../components/fields/switch-button-action';

import Slide from '@material-ui/core/Slide';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Paper from '@material-ui/core/Paper';
import Draggable from 'react-draggable';
import closeIcon from '../../assets/images/icon/close.svg';

import * as StorageFileApi from '../../api/file-storage-api';

function PaperComponent(props) {
    return (
        <Draggable cancel={'[class*="MuiDialogContent-root"]'}>
            <Paper {...props} />
        </Draggable>
    );
}

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

class SettingProgram extends Component {

    state = {
        uploadFile: null,
        deleteOpen: false,
    }

    handleChangeProgram(value, type) {
        let { programDetails } = this.props;
        programDetails[type] = value;
        this.props.handleProgramDetailsChange(programDetails);
    }

    handlePublishProgram = (key) => {
        let { programDetails } = this.props;
        programDetails[key] = !programDetails.program_published;
        this.props.handleProgramDetailsChange(programDetails);
        this.setState({ deleteOpen: false });
    };

    handleSaveClick() {
        let { uploadFile } = this.state;
        let { programDetails } = this.props;
        console.log("uploadFile555", uploadFile, "programDetails", programDetails);

        if (uploadFile) {
            const formData = new FormData();
            formData.append("file", uploadFile, uploadFile.name);
            StorageFileApi.postFileUpload(formData).then(response => {
                programDetails["master_policy_file_upload"] = response.file_id;
                this.props.handleProgramDetailsChange(programDetails);
                this.props.updateProgramData();
            })
        } else {
            this.props.updateProgramData();
        }

    }

    handleUploadCreation = () => (file) => {
        this.setState({ uploadFile: file, })
    }

    handleDeleteFile() {
        this.setState({ uploadFile: null });
    }

    renderUpdateSuccessfullDialog() {
        return (
            <Dialog
                open={this.props.open}
                onClose={() => this.props.handleClose()}
                PaperComponent={PaperComponent}
                aria-labelledby="draggable-dialog-title"
            >
                <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                    Updated Successfully
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        The Program settings have been updated successfully.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={() => this.props.handleClose()} color="primary">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    handleClose(key) {
        this.setState({ [key]: false });
    }

    handleOpen(key) {
        this.setState({ [key]: true });
    }

    render() {
        let { programDetails } = this.props;
        let { deleteOpen } = this.state;
        console.log("programDetails455", programDetails);
        let programType = [
            {
                field_option_label: "Individual",
                field_option_value: "individual",
            },
            {
                field_option_label: "Group",
                field_option_value: "group"
            }
        ]

        return (

            <div className="drawer-tabs workflow-tabs-wrap">
                {this.renderUpdateSuccessfullDialog()}
                <div className="drawer-tabs-header border-bottom">
                    <div className="workflow-title">
                        <h5>Update Program Details</h5>
                    </div>
                    <div className="workflow-tabs-search"></div>
                </div>
                <div className="tab-container">
                    <Scrollbars style={{ width: '100%', height: '100%' }}>
                        <section className="add-fields-wrap">
                            <div className="add-field-row">
                                <div className="added-field-grp">
                                    <div className="added-field">
                                        <InputField
                                            label="Name of the Program"
                                            type="text"
                                            name="name_program"
                                            fieldStyle="field-box bg-plane grey-color"
                                            placeholder="Enter"
                                            selected_value={programDetails ? programDetails.program_name : ""}
                                            handleOnChangeValue={(value) => this.handleChangeProgram(value, "program_name")}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="add-field-row">
                                <div className="added-field-grp">
                                    <div className="added-field">
                                        <TextAreaField
                                            label="Description"
                                            type="textarea"
                                            name="policy_interest"
                                            fieldStyle="field-box bg-plane grey-color"
                                            placeholder="Description"
                                            rowmax="5"
                                            selected_value={programDetails ? programDetails.program_desc : ""}
                                            handleOnChangeValue={(value) => this.handleChangeProgram(value, "program_desc")}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="add-field-row">
                                <div className="added-field-grp added-field-selection-grp">
                                    <div className="added-field">
                                        <RadioField
                                            radioGroupLabel="Program Type"
                                            radioData={programType}
                                            label="Program Type"
                                            radioFieldStyle="field-inline"
                                            selected_value={programDetails ? programDetails.program_type : ""}
                                            radioChangeHandle={(value) => this.handleChangeProgram(value, "program_type")}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="add-field-row">
                                <div className="added-field-grp">
                                    <div className="added-field">
                                        <UploadField
                                            label="Master Policy Upload"
                                            type="file"
                                            name="upload"
                                            customStyle="upload-file"
                                            fieldStyle="field-box bg-plane grey-color field-upload"
                                            uploadFile={this.state.uploadFile}
                                            handleUploadCreation={this.handleUploadCreation().bind(this)}
                                            handleDeleteFile={() => this.handleDeleteFile()}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="add-field-row">
                                <div className="added-field-grp added-field-selection-grp">
                                    <div className="added-field">
                                        <SwitchButtonActions
                                            headerLabel={"Publish Program"}
                                            label="Published"
                                            fieldStyle="field-box bg-plane grey-color"
                                            isChecked={programDetails ? Boolean(programDetails.program_published) : ""}
                                            withLabel={true}
                                            // onChange={(event)=>this.handlePublishProgram(event.target.checked, "program_published")}
                                            onChange={() => this.handleOpen("deleteOpen")}
                                        />
                                    </div>
                                </div>
                            </div>
                        </section>
                    </Scrollbars>
                </div>
                <div className="card-footer border-top p-l-0 p-r-0">
                    <div className="btn-wrap">
                        <Fab variant="extended" size="large" className="btn btn-large btn-plane primary-color" onClick={() => this.handleSaveClick()}>
                            Save
                        </Fab>
                        <Fab variant="extended" size="large" className="btn btn-large btn-plane grey-color">
                            Cancel
                        </Fab>
                    </div>
                </div>
                <Dialog open={deleteOpen} onClose={event => this.handleClose("deleteOpen")} TransitionComponent={Transition} maxWidth="sm">
                    <section className="workflow-dialog workflow-dialog-pri">
                        <div className="card-block">
                            <div className="card-header border-bottom p-b-25">
                                <div className="sub-title">
                                    <h2 className="bold">Confirm Action?</h2>
                                </div>
                                <div className="close-dialog">
                                    <img src={closeIcon} alt="" onClick={event => this.handleClose("deleteOpen")}></img>
                                </div>
                            </div>
                            <div className="card-body p-t-25 p-b-45">
                                <div className="sub-title">
                                    <h5 className="weight-300 bold p-b-25">Are you sure ?</h5>
                                    <h5 className="weight-300">This action cannot be undone.
                                        {programDetails.program_published ? <span> Published entries would not be available in draft mode. </span>
                                            : <span> This action cannot be undone. Draft mode entries will be permanently deleted. </span>}
                                    </h5>
                                </div>
                            </div>
                            <div className="card-footer border-top p-0 p-t-25">
                                <div className="btn-wrap justify-space-between">
                                    <Fab variant="extended" size="large" className="btn btn-large btn-outline pink-color" onClick={() => this.handlePublishProgram("program_published")}>
                                        Confirm Action
                                    </Fab>
                                    <Fab variant="extended" size="large" className="btn btn-large btn-plane grey-color" onClick={event => this.handleClose("deleteOpen")}>
                                        Cancel
                                    </Fab>
                                </div>
                            </div>
                        </div>
                    </section>
                </Dialog>
            </div>
        )
    }
}

export default SettingProgram;