import React, { Component } from 'react';
import Header from '../../components/header';

//Custom
import SectionMaster from '../../components/section/section-master';

//API
import * as ProgramStateApi from '../../api/program-state-api';
import * as UserApi from '../../api/user-api';
import * as ProgramApi from '../../api/program-api';

class SectionBuilder extends Component {
    state = {
        addState: false,
        settingState: false,
        stateData: [],
        sectionName: "",
        userOptionList: [],
        sectionDetails: {},
        permissionList: [],
    }

    componentWillMount() {
        this.getAllPermissionData(() => this.getAllStateData());
    }

    getAllUserData(callback) {
        let userOptionList = [];
        UserApi.getAllUsers().then(users => {
            console.log("users ", users)
            users.forEach((user, index) => {
                let obj = {
                    field_option_label: user.user_name,
                    field_option_value: user.user_id.toString(),
                };
                userOptionList.push(obj);
            })
            this.setState({ userOptionList: userOptionList }, callback);
        });

    }

    getAllPermissionData(callback) {
        const match = this.props.match;
        let programkey = match.params.programkey;

        let permissionList = [];
        UserApi.getAllPermission(programkey).then(permissions => {
            console.log("Permission556", permissions);
            permissions.forEach(permission => {
                let permissionObj = {
                    field_option_label: permission.permission_name,
                    field_option_value: permission.permission_set_id.toString(),
                };
                permissionList.push(permissionObj);
            })

            this.setState({ permissionList: permissionList }, callback);
        })
    }

    getAllStateData() {
        const match = this.props.match;
        let sectionkey = match.params.sectionkey;

        ProgramStateApi.getAllStatesBySectionKey(sectionkey).then(res => {
            console.log("res1245: ", res);
            this.setState({ stateData: res.program_state_list })
            this.setState({ sectionName: res.section_name })
            ProgramApi.getSectionDetails(res.section_key).then(data => {
                this.setState({ sectionDetails: data });
            })
        })
    }

    handleDeleteOfState(id) {
        ProgramStateApi.deleteState(id).then(data => {
            this.getAllStateData();
        });
    }

    handleDeleteOfSubState(id) {
        ProgramStateApi.deleteSubState(id).then(data => {
            this.getAllStateData();
        });
    }

    handleDeleteOfAllSubStatesById(stateId) {
        ProgramStateApi.deleteAllSubStatesById(stateId).then(data => {
            this.getAllStateData();
        });
    }

    handleSaveAction(newStateData) {
        console.log("newStateData in Scene", newStateData);
        const match = this.props.match;
        let sectionkey = match.params.sectionkey;
        let programkey = match.params.programkey;

        newStateData["section_key"] = sectionkey;
        newStateData["program_key"] = programkey;
        newStateData["permission_ids"] = newStateData["permission_ids"] ? (Array.isArray(newStateData["permission_ids"]) ? newStateData["permission_ids"] : newStateData["permission_ids"].split(",")) : []

        newStateData.sub_states_list.forEach(list => {
            list["section_key"] = sectionkey;
            list["program_key"] = programkey;
            list["permission_ids"] = list["permission_ids"] ? (Array.isArray(list["permission_ids"]) ? list["permission_ids"] : list["permission_ids"].split(",")) : []
        })

        ProgramStateApi.createState(newStateData).then(res => {
            this.getAllStateData();
        })
    }

    toggleDrawer(side, open) {
        this.setState({ [side]: open });
    };

    updateSectionDetails(sectionInfo) {
        ProgramApi.updateSection(sectionInfo).then(data => {
            this.setState({ sectionDetails: data });
            this.getAllStateData();
        })
    }

    render() {
        const match = this.props.match;
        let programkey = match.params.programkey;
        let clientId = match.params.clientId;
        console.log("stateData222", this.state.stateData);
        return (
            <div className="FormBuilderScene">
                <Header headerType="dashboard" positionType="fixed" programKey={programkey} />
                <SectionMaster
                    toggleDrawer={this.toggleDrawer.bind(this)}
                    addState={this.state.addState}
                    programkey={programkey}
                    clientId={clientId}
                    stateData={this.state.stateData}
                    sectionName={this.state.sectionName}
                    userOptionList={this.state.userOptionList}
                    permissionList={this.state.permissionList}
                    sectionDetails={this.state.sectionDetails}
                    handleSaveAction={this.handleSaveAction.bind(this)}
                    handleDeleteOfState={this.handleDeleteOfState.bind(this)}
                    handleDeleteOfSubState={this.handleDeleteOfSubState.bind(this)}
                    updateSectionDetails={(sectionInfo) => this.updateSectionDetails(sectionInfo)}
                    handleDeleteOfAllSubStatesById={this.handleDeleteOfAllSubStatesById.bind(this)}
                />
            </div>

        );
    }
}

export default SectionBuilder;