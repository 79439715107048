import React, { Component } from 'react';

import InputField from '../fields/inputs';

import { Scrollbars } from 'react-custom-scrollbars';

import * as ConfigApi from '../../api/configuration-api';
import { RECORD_STATUS } from '../../utilities/constants';
import RadioField from '../fields/radio';
import Fab from '@material-ui/core/Fab';

import { CopyToClipboard } from "react-copy-to-clipboard";

class ConfigApiDetails extends Component {

    state = {
        apiDetail: {},
        isCopied: false
    }

    componentWillMount() {
        if (!this.state.apiDetail.hasOwnProperty("client_id")) {
            if (this.props.newAPIDetails) {
                ConfigApi.getNewApiDetails(this.props.clientId).then(response => {
                    this.setState({ apiDetail: response });
                })
            } else {
                ConfigApi.getApiDetails(this.props.clientId, this.props.programkey, this.props.motorConfigId).then(response => {
                    this.setState({ apiDetail: response });
                })
            }
        }
    }

    handleAPIChange = (type) => (value) => {
        let { apiDetail } = this.state;
        apiDetail[type] = value;
        this.setState({ apiDetail: apiDetail });
    }

    saveAPIChange = () => {
        if (this.props.newAPIDetails) {
            ConfigApi.saveNewApiDetails(this.props.clientId, this.state.apiDetail).then(response => {
                this.setState({ apiDetail: response });
            })
        } else {
            ConfigApi.saveApiDetails(this.props.clientId, this.props.programkey, this.props.motorConfigId, this.state.apiDetail).then(response => {
                this.setState({ apiDetail: response });
            })
        }
    }

    onCopyText = () => {
        this.setState({ isCopied: true });
        setTimeout(() => {
            this.setState({ isCopied: false });
        }, 2000);
    };

    render() {

        let { apiDetail, isCopied } = this.state;
        let status = [
            {
                field_option_label: RECORD_STATUS.ACTIVE,
                field_option_value: RECORD_STATUS.ACTIVE,
            },
            {
                field_option_label: RECORD_STATUS.INACTIVE,
                field_option_value: RECORD_STATUS.INACTIVE,
            }
        ]

        let codeSnippet = '"client_id"\t: "' + apiDetail.client_id + '"\n"client_secret"\t: "' + apiDetail.client_secret + '"\n"whitelisted_ip": ' + apiDetail.whitelisted_ip + '\n"status"\t: ' + (this.state.apiDetail.status == RECORD_STATUS.ACTIVE ? RECORD_STATUS.ACTIVE : RECORD_STATUS.INACTIVE);
        return (

            <div className="drawer-tabs workflow-tabs-wrap">
                <div className="drawer-tabs-header border-bottom">
                    <div className="workflow-title">
                        <h5>API Details</h5>
                    </div>
                </div>
                <div className="tab-container">
                    <Scrollbars style={{ width: '100%', height: '100%' }}>
                        <div className="drawer-tabs-header" style={{ paddingBottom: '15px' }}>
                            <div className="workflow-title">
                                <h5 className='p-b-10'>Client id and secrets</h5>
                                <h6 className="grey-color weight-300 p-b-10">Client configuration for authentication</h6>
                            </div>
                        </div>
                        <section className="add-fields-wrap">
                            <div className="add-field-row">
                                <div className="added-field">
                                    <RadioField
                                        radioGroupLabel="Status"
                                        radioData={status}
                                        label="Status"
                                        default_value={this.state.apiDetail.status == RECORD_STATUS.ACTIVE ? RECORD_STATUS.ACTIVE : RECORD_STATUS.INACTIVE}
                                        radioFieldStyle="field-inline"
                                        radioChangeHandle={this.handleAPIChange("status").bind(this)}
                                    />
                                </div>
                            </div>
                            {this.state.apiDetail.status == RECORD_STATUS.ACTIVE && (<><div className="add-field-row">
                                <div className="added-field-grp">
                                    <div className="added-field">
                                        <InputField
                                            label="Client ID"
                                            type="text"
                                            name="client_id"
                                            selected_value={apiDetail.client_id}
                                            handleOnChangeValue={this.handleAPIChange("client_id").bind(this)}
                                            fieldStyle="field-box grey-color bg-plane"
                                            disabled={true}
                                        />
                                        <InputField
                                            label="Client Secret"
                                            type="text"
                                            name="client_secret"
                                            selected_value={apiDetail.client_secret}
                                            handleOnChangeValue={this.handleAPIChange("client_secret").bind(this)}
                                            fieldStyle="field-box grey-color bg-plane"
                                            disabled={true}
                                        />
                                    </div>
                                </div>
                            </div>
                                <div className="add-field-row">
                                    <div className="added-field-grp">
                                        <div className="added-field">
                                            <InputField
                                                label="Whitelisted IP"
                                                type="text"
                                                name="whitelisted_ip"
                                                selected_value={apiDetail.whitelisted_ip}
                                                handleOnChangeValue={this.handleAPIChange("whitelisted_ip").bind(this)}
                                                fieldStyle="field-box grey-color bg-plane"
                                            />
                                        </div>
                                    </div>
                                </div></>)}
                        </section>
                    </Scrollbars>
                </div>
                <div className="card-footer border-top p-l-0 p-r-0">
                    <div className="drawer-tabs-header">
                        <div className="workflow-title">
                            {(!this.props.newAPIDetails) && <Fab variant="extended" size="large" className="btn btn-large btn-outline pink-color"
                                onClick={this.props.handleShowConfirmDeleteDialog}>
                                Delete
                            </Fab>}
                        </div>
                        <div className="workflow-tabs-search btn-wrap">

                            <CopyToClipboard text={codeSnippet} onCopy={this.onCopyText}>
                                <div className="copy-area">
                                    <Fab variant="extended" size="large" className="btn btn-large btn-plane primary-color">

                                        {!isCopied ?
                                            <span>
                                                Copy to Clipboard
                                            </span>
                                            :
                                            <span>
                                                Copied!
                                            </span>
                                        }
                                    </Fab>
                                </div>
                            </CopyToClipboard>
                            <Fab variant="extended" size="large" className="btn btn-large btn-plane primary-color"
                                onClick={this.saveAPIChange.bind(this)}>
                                Save
                            </Fab>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ConfigApiDetails;