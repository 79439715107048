import React, { Component } from 'react';

import Fab from '@material-ui/core/Fab';

//API
import * as ActionApi from '../../api/action-api';
import * as FormDataApi from '../../api/form-data-api.jsx';
import * as PaymentApi from '../../api/payment-api';
import * as StorageFileApi from '../../api/file-storage-api';

import LinearProgress from '@material-ui/core/LinearProgress';

//dialog
import Dialog from '@material-ui/core/Dialog';
import CloseIcon from '../../assets/images/icon/close.svg';
import CircularProgress from '@material-ui/core/CircularProgress';

//Constants
import * as FormCommon from '../../utilities/form-common'
import * as FormConstant from '../../utilities/constants'

//Utils
import * as ScriptEvaluator from '../../utilities/script-evaluator'

//Constants
import * as Constants from '../../utilities/constants';
import * as Function from '../../utilities/common-utils';

//Lodash
import _ from 'lodash';
import { openAssurgateInNewTab } from '../../services/assuregate-service';
import { withRouter } from 'react-router';
import AlertBox from '../common/alert-box';

class FormViewComponent extends Component {

    state = {
        loading: true,
        saveLoader: false,
        formUserDatas: [],
        validationStatus: {},
        isFormValid: false,
        workflowAction: [],
        rulesScript: {},
        count: 0,
        redirect: {},
        openModal: false,
        responseOfPayment: null,
        paymentInitializationObj: {
            prefill: {},
            notes: {},
        },
        assureGatePrefilledPayloadData: {},
        actionSpecType: null,
        redirectPaymentWorkflow: null,
        fileUploadDialog: false,
        fileCount: 0,
        uploadedFileCount: 0,
        workflowRules: [],
        error: false,
        errorMessage: ""
    }

    componentDidMount() {
        if (this.props.formData && this.props.formData.form_field_list) {
            this.getFormData(this.props.formData, this.props.current_state, this.props.current_sub_state);
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.formData && this.props.formData && (JSON.stringify(this.props.formData) !== JSON.stringify(nextProps.formData))) {
            this.getFormData(nextProps.formData, nextProps.current_state, nextProps.current_sub_state);
        } else if (nextProps.current_state !== this.props.current_state || nextProps.current_sub_state !== this.props.current_sub_state) {
            this.getFormData(nextProps.formData, nextProps.current_state, nextProps.current_sub_state);
        }

    }

    getFormData(formData, current_state, current_sub_state) {
        console.log("PPPP", formData, current_state, current_sub_state);
        if (formData) {
            formData.form_field_list && formData.form_field_list.sort(function (a, b) {
                return a.position - b.position
            })
            this.sortOptionsAlpha(formData);
            let validationStatus = this.setValidationStatusArray(formData);
            let updatedValidationStatus = this.setFormPreloadDataCheck(formData, validationStatus, current_state, current_sub_state);
            this.generateFormValidationStatus(updatedValidationStatus);
            this.initialFormData(formData);

            this.props.updateData(formData, () => this.processRules());
        }
    }

    initialFormData(formData) {
        let { formUserDatas } = this.state;
        formData.form_field_list && formData.form_field_list.map(fieldDetails => {
            console.log("initialFormData", fieldDetails);
            let formUserData = formUserDatas[fieldDetails.data_pagination_id - 1];
            if (!formUserData) {
                formUserData = {};
                formUserData["form_pagination_id"] = fieldDetails.data_pagination_id;
                formUserData["object_id"] = formData.id;
                formUserData["process_id"] = this.props.process_id;
                switch (formData.form_type) {
                    case Constants.FORM_TYPE.PARAMETER.code: {
                        formUserData["object_type"] = Constants.RECORD_TYPE.PROGRAM.code;
                        break;
                    }
                    default: {
                        formUserData["object_type"] = Constants.RECORD_TYPE.FORM.code;
                    }
                }
                formUserDatas[fieldDetails.data_pagination_id - 1] = formUserData;
            }
        })
        for (var i = 0; i < formUserDatas.length; i++) {
            let formUserData = formUserDatas[i];
            if (!formUserData) {
                formUserData = {};
                formUserData["form_pagination_id"] = i + 1;
                formUserData["object_id"] = formData.id;
                formUserData["process_id"] = this.props.process_id;
                switch (formData.form_type) {
                    case Constants.FORM_TYPE.PARAMETER.code: {
                        formUserData["object_type"] = Constants.RECORD_TYPE.PROGRAM.code;
                        break;
                    }
                    default: {
                        formUserData["object_type"] = Constants.RECORD_TYPE.FORM.code;
                    }
                }

                formUserDatas[i] = formUserData;
            }
        }
        this.setState({ formUserDatas: formUserDatas });
    }

    processRules() {
        let { formData } = this.props;
        //break if no data
        if (!formData.rules_script) {
            return
        }
        let rulesScrits = JSON.parse(formData.rules_script);
        let workflowRules = JSON.parse(JSON.stringify(rulesScrits.rules));
        console.log("workflowRules", workflowRules);
        workflowRules.forEach(workflowRule => {
            workflowRule.forEach(rule => {
                if (rule.condition) {
                    rule.org_condition = rule.condition;
                    rule.condition = ScriptEvaluator.evaluatecondition(rule.condition, formData.form_field_list, this.state.formUserDatas);
                } else {
                    rule.condition = true;
                }
            })
            //Only execute on change
            let executableWorkflowRule = workflowRule.filter(rule => rule.executeOn === 1);
            this.executeScript(executableWorkflowRule);
        })
    }

    formFieldUsedReset(formData) {
        formData.form_field_list.forEach(field => {
            field.used = false;
        })
    }

    performActionConfig(task, formData, executableWorkflowRule) {
        if (task.actionConfig) {
            let fieldIds = task.actionConfig.fields;
            fieldIds && fieldIds.forEach(fieldId => {
                let fieldList = formData.form_field_list;
                let selectedField = null;
                //check for action button and groups
                if (fieldId.includes("#")) {
                    selectedField = this.getChildFieldFromId(fieldList, fieldId);
                } else {
                    fieldList.forEach(field => {
                        if (field.group_field) {
                            // selectedField = field.children && field.children.filter(child => (child.id === fieldId))[0];
                            if (field.id === fieldId) {
                                selectedField = field;
                            }
                            else {
                                field.children.forEach(child => {
                                    if (child.id === fieldId) {
                                        selectedField = child;
                                    }
                                })
                            }

                        }
                        else if (field.id === fieldId) {
                            selectedField = field;
                        }
                    })
                }
                ScriptEvaluator.actionPerformer(task.actionSpec, selectedField, task.actionConfig.values[fieldId]);
                if (selectedField && selectedField.selected_value) {
                    this.saveFormElementData(selectedField, selectedField.selected_value, formData);
                    this.updateConditionsOfRules(executableWorkflowRule, formData, selectedField)
                }

            })
        }
    }

    updateConditionsOfRules(executableWorkflowRule, formData, selectedField) {
        executableWorkflowRule.forEach(workRule => {
            if (workRule.org_condition && workRule.org_condition !== true) {
                workRule.condition = ScriptEvaluator.evaluatecondition(workRule.org_condition, formData.form_field_list, this.state.formUserDatas, selectedField.selected_value);
            }
        })
    }

    executeScript(executableWorkflowRule, executeRedirect) {
        console.log("executableWorkflowRule_555", executableWorkflowRule);
        let formData = this.props.formData;
        let { workflowRules } = this.state;
        let defaultExecuted = false;
        this.formFieldUsedReset(formData);

        executableWorkflowRule.forEach((rule, index) => {
            if (eval(rule.condition)) {
                rule.tasks.forEach(task => {
                    switch (task.actionSpec) {
                        case Constants.FIELD_ACTIONS.HIDE:
                        case Constants.FIELD_ACTIONS.SHOW:
                        case Constants.FIELD_ACTIONS.ENABLE:
                        case Constants.FIELD_ACTIONS.DISABLE:
                            this.performActionConfig(task, formData, executableWorkflowRule);
                            break;

                        case Constants.FIELD_ACTIONS.SET: {

                            //SET-FIELD-VALUE Should only execute once  
                            if (!workflowRules[index]) {
                                this.performActionConfig(task, formData, executableWorkflowRule);
                            }

                            break;
                        }
                        case Constants.FIELD_ACTIONS.BUY_POLICY: {
                            this.setState({ actionSpecType: Constants.FIELD_ACTIONS.BUY_POLICY });
                            break;
                        }
                        case Constants.FIELD_ACTIONS.CANCEL_POLICY: {
                            this.setState({ actionSpecType: Constants.FIELD_ACTIONS.CANCEL_POLICY });
                            break;
                        }
                        case Constants.FIELD_ACTIONS.OPEN_ASSUREGATE: {
                            this.setState({ actionSpecType: Constants.FIELD_ACTIONS.OPEN_ASSUREGATE, assureGatePrefilledPayloadData: task?.actionConfig?.values });
                            break;
                        }
                        case Constants.FIELD_ACTIONS.PAYMENT_INIT: {
                            let { paymentInitializationObj } = this.state;
                            let fieldIds = null;
                            if (task.actionConfig) {
                                fieldIds = task.actionConfig.fields;
                            }

                            paymentInitializationObj["key"] = "rzp_test_ynSXD4i9GipcoW";
                            paymentInitializationObj["handler"] = (response) => { response && this.savingFormDataAfterResponse(response.razorpay_payment_id, response.razorpay_order_id, response) };
                            paymentInitializationObj["theme"] = { "color": "#1E90FF" };
                            console.log("fieldIds", fieldIds);
                            fieldIds && fieldIds.forEach(fieldId => {
                                let fieldList = formData.form_field_list;
                                let fieldValueId = task.actionConfig.values[fieldId];
                                let selectedField = fieldList.filter(field => (field.field_key === fieldValueId))[0];
                                console.log("selectedField555", selectedField);

                                if (fieldId === "merchant_name") {
                                    if (selectedField) {
                                        paymentInitializationObj["name"] = selectedField.hasOwnProperty("selected_value") ? selectedField.selected_value : null;
                                    } else {
                                        paymentInitializationObj["name"] = fieldValueId;
                                    }
                                }
                                else if (fieldId === "customer_name") {
                                    if (selectedField) {
                                        paymentInitializationObj.prefill["name"] = selectedField.hasOwnProperty("selected_value") ? selectedField.selected_value : null;
                                    } else {
                                        paymentInitializationObj.prefill["name"] = fieldValueId;
                                    }
                                }
                                else if (fieldId === "email") {
                                    if (selectedField) {
                                        paymentInitializationObj.prefill["email"] = selectedField.hasOwnProperty("selected_value") ? selectedField.selected_value : null;
                                    } else {
                                        paymentInitializationObj.prefill["email"] = fieldValueId;
                                    }
                                }
                                else if (fieldId === "address") {
                                    if (selectedField) {
                                        paymentInitializationObj.notes["address"] = selectedField.hasOwnProperty("selected_value") ? selectedField.selected_value : null;
                                    } else {
                                        paymentInitializationObj.notes["address"] = fieldValueId;
                                    }
                                }
                                else {
                                    if (selectedField) {
                                        paymentInitializationObj[fieldId] = selectedField.hasOwnProperty("selected_value") ? parseInt(selectedField.selected_value) * 100 : null;
                                    } else {
                                        paymentInitializationObj[fieldId] = fieldValueId;
                                    }
                                }

                            })

                            console.log("paymentInitializationObj", paymentInitializationObj);
                            this.setState({ paymentInitializationObj: paymentInitializationObj, actionSpecType: Constants.FIELD_ACTIONS.PAYMENT_INIT, redirectPaymentWorkflow: task.paymentWorkflow });

                            break;
                        }
                        case Constants.FIELD_ACTIONS.REDIRECT:
                            this.setState({ redirect: task.actionConfig }, () => { (executeRedirect) && this.redirectBuilder() });
                            break;
                        default:
                            defaultExecuted = true;
                            break;
                    }
                })
                workflowRules[index] = true;
                //for click events and other action types
                if (defaultExecuted && rule.executeOn === 2) {
                    ActionApi.executeWorkflowActionTrigger(rule.triggerId, this.props.process_id).then(res => {
                        this.props.afterSaveEvent();
                    })
                }
            }
            else {
                if (workflowRules[index]) {
                    let tasks = JSON.parse(JSON.stringify(rule.tasks));
                    tasks.reverse();
                    tasks.forEach(task => {
                        switch (task.actionSpec) {
                            case Constants.FIELD_ACTIONS.HIDE:
                            case Constants.FIELD_ACTIONS.SHOW:
                            case Constants.FIELD_ACTIONS.ENABLE:
                            case Constants.FIELD_ACTIONS.DISABLE:
                            case Constants.FIELD_ACTIONS.SET: {
                                if (task.actionConfig) {
                                    let fieldIds = task.actionConfig.fields;
                                    fieldIds && fieldIds.forEach(fieldId => {
                                        let fieldList = formData.form_field_list;
                                        //check for action button and groups
                                        let selectedField = ""
                                        if (fieldId.includes("#")) {
                                            selectedField = this.getChildFieldFromId(fieldList, fieldId);
                                        } else {
                                            selectedField = fieldList.filter(field => (field.id === fieldId))[0];
                                        }
                                        if (selectedField) {
                                            ScriptEvaluator.actionResolver(task.actionSpec, selectedField);
                                        }

                                    })
                                }
                            }
                            default: break;
                        }
                    })
                }
                workflowRules[index] = false;
            }
        })
        this.setState({ workflowRules: workflowRules });
        this.props.updateData(formData, () => this.processSpecificFields);
    }

    getChildFieldFromId(fieldList, id) {
        let splitId = id.split("#");
        let parentField = fieldList.filter(field => field.id === splitId[0])[0];
        let childField = parentField.field_option_list.filter(child => child.field_option_value === splitId[1])[0]
        return childField;
    }

    sortOptionsAlpha(formData) {
        formData.form_field_list.forEach(field => {
            if (field.is_alphabetical === "yes") {
                field.field_option_list.sort(function (a, b) {
                    if (a.field_option_label < b.field_option_label) { return -1; }
                    if (a.field_option_label > b.field_option_label) { return 1; }
                    return 0;
                })
            }
        })
    }

    generateFormValidationStatus(validationStatus) {
        //If user keep all the fields non-mandatory, 
        //then the form is be elgible to Save after the page is just loaded.
        let isVal = true;
        for (var key in validationStatus) {
            if (validationStatus[key] === false) {
                isVal = false
            }
        }
        if (isVal) {
            this.setState({ isFormValid: true });
        }
        else {
            this.setState({ isFormValid: false });
        }
    }

    setFormPreloadDataCheck(formData, validationStatus, current_state, current_sub_state) {
        //preselect data setting
        formData.form_field_list.forEach(field => {

            switch (field.field_type) {
                case FormConstant.FieldIds.F_SINGLE_DROPDOWN_GROUP.code:
                case FormConstant.FieldIds.F_MULTIPLE_DROPDOWN_GROUP.code:
                case FormConstant.FieldIds.F_CHECKBOX_GROUP.code:
                    let selectedOptArr = [];
                    let selectedOptStr = "";
                    let checkValid = false;
                    field.field_option_list.forEach(option => {
                        if (option.checked) {
                            selectedOptArr.push(option.id);
                            checkValid = true;
                        }
                    });

                    //Set the field validation status with current vallid status
                    //validationStatus[field.id] = checkValid;
                    if (checkValid) {
                        validationStatus[field.id] = checkValid;
                    }

                    if (selectedOptArr.length > 0) {
                        selectedOptStr = selectedOptArr.join();
                    }
                    this.setState({ validationStatus: validationStatus });
                    this.saveFormElementData(field, selectedOptStr, formData);
                    break;
                case FormConstant.FieldIds.F_STATE.code:
                    this.saveFormElementData(field, current_state, formData);
                    break;
                case FormConstant.FieldIds.F_SUB_STATE.code:
                    this.saveFormElementData(field, current_sub_state, formData);
                    break;
                default:

                    let selectedOptStrValue = "";
                    let checkValidFlag = false;
                    if (field.default_value) {
                        selectedOptStrValue = field.default_value;
                        checkValidFlag = true
                    }
                    if (checkValidFlag) {
                        validationStatus[field.id] = checkValidFlag;
                    }
                    this.setState({ validationStatus: validationStatus });
                    this.saveFormElementData(field, selectedOptStrValue, formData);
                    break;

            }
        })
        return validationStatus;
    }

    setValidationStatusArray(formData) {
        let validStatus = this.state.validationStatus;

        formData.form_field_list.forEach(field => {
            // if (field.field_validations.length > 0 && field.field_validations.includes("v_required")) {
            //     validStatus[field.id] = false;
            // }
            // else {
            //     validStatus[field.id] = true;
            // }

            if (field.field_validations.length > 0 && field.field_validations.includes("v_required")) {
                if (field.group_field) {
                    validStatus[field.id] = true;
                }
                else {
                    if (field.parent_id != null) {
                        let parentfield = formData.form_field_list.filter(parentField => { return (parentField.id === field.parent_id) })[0];
                        let displayfield = null;
                        displayfield = parentfield.display_name.filter(displayfield => { return (displayfield.field_option_label === field.label) })[0];
                        validStatus[field.id] = !displayfield.checked;
                    } else {
                        validStatus[field.id] = false;
                    }
                }
            }
            else {
                validStatus[field.id] = true;
            }
        })
        this.setState({ validationStatus: validStatus });
        return validStatus;
    }

    handleGenOnChangeValue = (fieldDetails) => (value, errors, childField) => {
        console.log("handleGenOnChangeValue", fieldDetails, "value", value, "errors", errors, "childField", childField);
        let { formData } = this.props;
        if (fieldDetails.group_field) {
            //This funtion is used to update the fieldOptionList of all the Child Dropdowns.
            this.checkChildDropdown(childField, value);
            this.updateChildFieldsInFieldDetails(fieldDetails, childField, value);
            let childProperties = JSON.parse(JSON.stringify(childField));
            this.checkValidation(childProperties, errors, value);
            this.saveFormElementData(childProperties, value, formData, this.dataSaveCallback);
            childProperties.selected_value = value;
            this.saveFormElementData(childProperties, value, formData);
        }
        else if (fieldDetails.field_type === Constants.FieldIds.F_UPLOAD.code) {
            if (value) {
                //Validating field details
                this.checkValidation(fieldDetails, errors, value);
                formData.form_field_list.forEach(field => {
                    if (field.id === fieldDetails.id) {
                        field["uploadFile"] = value;
                    }
                })
                this.setState({ formData: formData });
            }
        }
        else {
            //Validating field details
            this.checkValidation(fieldDetails, errors, value);
            this.saveFormElementData(fieldDetails, value, formData, this.dataSaveCallback);
            fieldDetails.selected_value = value;
        }
    }

    updateChildFieldsInFieldDetails(fieldDetails, childField, value) {
        fieldDetails.children &&
            fieldDetails.children.forEach(child => {
                if (child.id === childField.id) {
                    child.selected_value = value;
                }
            })
    }

    checkChildDropdown(childField, value) {
        if (childField.field_type === FormConstant.FieldIds.F_SINGLE_DROPDOWN_GROUP.code) {
            childField.field_option_list.forEach(field => {
                if (field.field_option_value === value) {
                    field.checked = true;
                }
                else {
                    field.checked = false;
                }
            })
        }
    }

    dataSaveCallback() {
        this.processRules();
        this.processSpecificFields();
    }

    processSpecificFields() {
        let { formData } = this.props;
        let fieldList = formData.form_field_list;
        fieldList.forEach(field => {
            switch (field.field_type) {
                case Constants.FieldIds.F_CALCULATION.code: {
                    let script = ScriptEvaluator.evaluateFieldCondition(field.formula_text, fieldList, this.state.formUserDatas);
                    field.selected_value = eval(script);
                    this.saveFormElementData(field, eval(script), formData, this.processRules);
                    break;
                }
                default: break;
            }
        })
        this.props.updateData(formData, null);
    }

    handleButtonEvents = (fieldId, buttonId) => {
        let { formData } = this.props;
        let rulesScrits = JSON.parse(formData.rules_script);
        let workflowRules = JSON.parse(JSON.stringify(rulesScrits.rules));
        workflowRules.forEach(workflowRule => {
            let buttonWorkflowRule = workflowRule.filter(workflow => (workflow.executeOn === 2 && workflow.conditionFields.join().includes(fieldId + "#" + buttonId)));
            buttonWorkflowRule.forEach(rule => {
                if (rule.condition) {
                    rule.condition = ScriptEvaluator.evaluatecondition(rule.condition, formData.form_field_list, this.state.formUserDatas);
                } else {
                    rule.condition = true;
                }
            })

            this.executeScript(buttonWorkflowRule, true);
        })
    }

    handleDeleteFile(fieldDetails) {
        let { formData } = this.state;
        formData.form_field_list.forEach(field => {
            if (field.id === fieldDetails.id) {
                field["uploadFile"] = null;
            }
        })
        this.setState({ formData: formData });
    }

    saveFormElementData(fieldDetails, value, formData, callback) {
        let { formUserDatas } = this.state;
        let formUserData = formUserDatas[fieldDetails.data_pagination_id - 1];
        if (!formUserData) {
            formUserData = {};
            formUserData["form_pagination_id"] = fieldDetails.data_pagination_id;
            formUserData["object_id"] = formData.id;
            formUserData["process_id"] = this.props.process_id;
            switch (formData.form_type) {
                case Constants.FORM_TYPE.PARAMETER.code: {
                    formUserData["object_type"] = Constants.RECORD_TYPE.PROGRAM.code;
                    break;
                }
                default: {
                    formUserData["object_type"] = Constants.RECORD_TYPE.FORM.code;
                }
            }
            formUserDatas[fieldDetails.data_pagination_id - 1] = formUserData;
        }

        formUserData["data_attr" + fieldDetails.data_ref_id] = value;

        this.setState({ formUserDatas: formUserDatas }, callback);
    }

    checkValidation(fieldDetails, errors, value) {

        let validStatus = this.state.validationStatus;
        var valid = true;
        if (fieldDetails.field_validations.length > 0 || errors.length > 0) {
            //Field which are not mandatory and value is empty
            if (!fieldDetails.field_validations.includes("v_required") && !value && fieldDetails.field_type !== "f-radio-grp") {
                errors.forEach(err => {
                    err.isValid = true
                })
            }

            //Check for all validation status
            errors.forEach(err => {
                if (err.isValid === false) {
                    valid = false
                }
            })

            if (valid) {
                validStatus[fieldDetails.id] = true;
            }
            else {
                validStatus[fieldDetails.id] = false;
            }
        }

        var isVal = true;
        for (var key in validStatus) {
            if (validStatus[key] === false) {
                isVal = false
            }
        }

        if (isVal) {
            this.setState({ isFormValid: true });
        }
        else {
            this.setState({ isFormValid: false });
        }

        this.setState({ validationStatus: validStatus });
    }

    saveFormData() {
        // console.log("Save clicked", this.state.paymentInitializationObj, "formdata", this.props.formData, "formUserDatas", this.state.actionSpecType);
        let { actionSpecType, paymentInitializationObj } = this.state;
        let { formData } = this.props;
        if (this.state.isFormValid) {
            if (actionSpecType === Constants.FIELD_ACTIONS.PAYMENT_INIT) {
                this.setState({ actionSpecType: null }); //paymentInitializationObj.amount, 
                PaymentApi.createOrderId(formData.id).then(data => {
                    // console.log("data233", data);
                    paymentInitializationObj["order_id"] = data.order_id;
                    const rzp = new window.Razorpay(this.state.paymentInitializationObj);
                    rzp.open();
                })

            } else if (actionSpecType === Constants.FIELD_ACTIONS.OPEN_ASSUREGATE) {
                this.setState({ saveLoader: true });
                const programKey = this.props?.match?.params?.programkey;
                openAssurgateInNewTab(this.state.assureGatePrefilledPayloadData, programKey, (success) => {
                    if (success)
                        this.setState({ error: false, errorMessage: "", actionSpecType: null, saveLoader: false }, () => this.saveFormData());
                    else
                        this.setState({ saveLoader: false, actionSpecType: Constants.FIELD_ACTIONS.OPEN_ASSUREGATE, error: true, errorMessage: "Error while opening the gate. Please try again later" })
                });
            } else {
                this.setState({ loading: true, saveLoader: true });
                let uploadCheck = this.checkForFileUploadFields();

                if (!uploadCheck) {
                    FormDataApi.saveFormData(this.state.formUserDatas).then(res => {
                        this.setState({ formUserDatas: res });
                        this.setState({ loading: false, saveLoader: false }, () => this.props.afterSaveEvent());
                    });
                }
                else {

                    this.state.formData.form_field_list.forEach(field => {
                        (field.field_type === Constants.FieldIds.F_UPLOAD.code && field.uploadFile) &&
                            this.storageFunctionApiCall(field.uploadFile, field)
                    })
                }
            }

        } else {
            this.globalValidationCheck();
        }
    }

    // FormDataApi.saveFormData(this.state.formUserDatas).then( res => {                
    //     this.setState({formUserDatas: res});
    //     // this.redirectBuilder(key);
    //     this.setState({loading: false}, () => this.props.afterSaveEvent());
    // });

    savingFormDataAfterResponse(paymentId, orderId, response) {
        console.log("SSOO", this.state.paymentInitializationObj, "response", response);
        if (this.state.isFormValid) {
            let formUserDatas = this.state.formUserDatas;
            if (paymentId && orderId) {
                formUserDatas.forEach(formUserData => {
                    formUserData.metadata = paymentId;
                    formUserData.order_id = orderId;
                })
            }
            this.setState({ loading: true, saveLoader: true });
            FormDataApi.saveFormData(this.state.formUserDatas).then(res => {
                // PaymentApi.receivePayment(this.state.redirectPaymentWorkflow, paymentId, res[0].common_record_id).then(data=>{
                // });
                this.setState({ formUserDatas: res });
                // this.redirectBuilder(key);
                this.setState({ loading: false, saveLoader: false }, () => this.props.afterSaveEvent());
            });
        } else {
            this.globalValidationCheck();
        }
    }


    saveAfterFileUploaded() {
        FormDataApi.saveFormData(this.state.formUserDatas).then(res => {
            this.setState({ formUserDatas: res, loading: false, saveLoader: false }, () => this.props.afterSaveEvent());
        });
    }

    checkForFileUploadFields() {
        let { formData } = this.props;
        let check = false;
        let count = 0;
        formData.form_field_list.forEach(field => {
            if (field.field_type === Constants.FieldIds.F_UPLOAD.code && field.uploadFile) {
                field["fileProgressCount"] = 0;
                count += 1;
                check = true;
            }
        })
        this.setState({ formData: formData, fileCount: count }, () => {
            count > 0 && this.setState({ fileUploadDialog: true }) //, loading: false
        });
        return check;
    }

    dialogFileUploadClose() {
        this.setState({ fileUploadDialog: false });
    }

    renderFileUploadTrigger() {
        let { formData, fileUploadDialog, fileProgressCount } = this.state;

        return (
            <Dialog open={this.state.fileUploadDialog} onClose={this.dialogFileUploadClose.bind(this)} aria-labelledby="simple-dialog-title">
                <section className="workflow-dialog workflow-dialog-small workflow-dialog-pri">
                    <div className="card-block">
                        <div className="card-header border-bottom p-b-25">
                            <div className="sub-title">
                                <h2 className="bold">File Upload</h2>
                            </div>
                            <div className="close-dialog">
                                <img src={CloseIcon} alt="CloseIcon" onClick={this.dialogFileUploadClose.bind(this)} ></img>
                            </div>
                        </div>
                    </div>
                    <div>
                        {
                            fileUploadDialog &&
                            formData.form_field_list.map(field => {
                                return (
                                    <div>
                                        {
                                            (field.field_type === Constants.FieldIds.F_UPLOAD.code && field.uploadFile) ?
                                                <div>
                                                    <h6 >{field.uploadFile.name}</h6>
                                                    <div>
                                                        <LinearProgress variant="determinate" value={field.fileProgressCount ? field.fileProgressCount : 0} />
                                                    </div>
                                                </div>
                                                : ""
                                        }
                                    </div>
                                );
                            })
                        }
                    </div>
                </section>
            </Dialog>
        );
    }

    callbackUploadProgress(progressValue, field) {
        let { formData } = this.state;

        formData.form_field_list.forEach(list => {
            if (list.id === field.id) {
                list["fileProgressCount"] = progressValue;
            }
        })

        this.setState({ formData: formData })
    }

    storageFunctionApiCall(file, field) {
        let { formData } = this.state;
        const formDataValue = new FormData();
        formDataValue.append("file", file, file.name);

        StorageFileApi.postFileUpload(formDataValue, (fileProgressCount) => { this.callbackUploadProgress(fileProgressCount, field) })
            .then(response => {
                this.saveFormElementData(field, response.file_id, formData, this.dataSaveCallback);


                this.setState({ uploadedFileCount: this.state.uploadedFileCount + 1 }, () => {
                    (this.state.uploadedFileCount === this.state.fileCount) &&
                        this.setState({ fileUploadDialog: false, saveLoader: true }, () => { this.saveAfterFileUploaded() });
                })
                // this.setState({fileUploadDialog: false }, () => { this.saveAfterFileUploaded() });


            })
    }


    cancelEvent = (event) => {
        this.redirectToEditor();
    }

    redirectBuilder(key) {
        let { redirect } = this.state;
        if (Function.isEmpty(redirect)) {
            if (key === "save") {
                this.redirectToEditor();
            }
            else if (key === "save-new") {
                this.redirectToPublish();
            }

        } else {
            if (redirect.type === Constants.REDIRECT_TYPE.FORM) {
                if (redirect.redirectMode === "modal") {
                    this.props.getModalFormData(redirect.formKey);
                } else {
                    const match = this.props.match;
                    let programkey = match.params.programkey;
                    // let formkey = match.params.formkey;
                    window.location = "/" + programkey + redirect.redirectUrl;
                }
            } else if (redirect.type === Constants.REDIRECT_TYPE.URL) {
                if (redirect.redirectMode === "external_link") {
                    window.open(redirect.redirectUrl, '_blank');
                }
            } else {
                window.location = redirect.redirectUrl;
            }
        }
    }

    redirectToEditor() {
        const match = this.props.match;
        let programkey = match.params.programkey;
        let clientId = match.params.clientId;
        let formkey = match.params.formkey;
        window.location = "/" + clientId + "/" + programkey + "/form/" + formkey + "/builder";
    }

    redirectToPublish() {
        const match = this.props.match;
        let programkey = match.params.programkey;
        let clientId = match.params.clientId;
        let formkey = match.params.formkey;
        window.location = "/" + clientId + "/" + programkey + "/form/" + formkey + "/publish";
    }

    globalValidationCheck() {
        this.props.formData.form_field_list.forEach(field => {
            field.triggerValidCheck = !field.triggerValidCheck;
        });
        this.props.updateData(this.props.formData, null);
    }

    createChildFields(formData) {
        if (formData && formData.form_field_list.length > 0) {
            formData.form_field_list.forEach(field => {
                if (field.group_field) {
                    if (!field.hasOwnProperty('children')) {
                        field["children"] = formData.form_field_list.filter(child => child.parent_id === field.id)
                    }
                }
            })

            //Deleting the child fields using Lodash.
            _.remove(formData.form_field_list, function (field) {
                return field.parent_id !== null;
            });
        }
    }

    render() {
        let { error, errorMessage } = this.state;
        let { formData, errorState, errorStateMessage } = this.props;
        this.createChildFields(formData);
        console.log("this.state.isFormValid", this.state.isFormValid);
        return (
            <div>
                {
                    !errorState ?
                        <div className="form-sections-fields">
                            {
                                formData && formData.form_field_list && formData.form_field_list.map(field => {
                                    if (field.is_hidden !== 'yes') {
                                        return FormCommon.fieldGenerate(field.field_type, field, this.handleGenOnChangeValue(field).bind(this), this.handleButtonEvents, () => this.handleDeleteFile(field))
                                    }
                                    else {
                                        return null;
                                    }
                                })
                            }
                            {(formData.form_type === FormConstant.FORM_TYPE.ONCLICK.code || formData.form_type === FormConstant.FORM_TYPE.SECTIONAL.code) && (
                                <div className="btn-wrap form-row">
                                    <Fab variant="extended" size="large" className="btn btn-large btn-plane grey-color no-shadow" onClick={this.props.handleClose}>
                                        Cancel
                                    </Fab> &nbsp;&nbsp;
                                    <Fab variant="extended" size="large" className="btn btn-large btn-plane primary-color no-shadow" disabled={!this.state.isFormValid} onClick={this.saveFormData.bind(this)}>
                                        {
                                            this.state.saveLoader === true ?
                                                <CircularProgress
                                                    color="secondary"
                                                    className="loader"
                                                />
                                                : ""
                                        }
                                        Save
                                    </Fab>
                                </div>
                            )}
                            {error && <h5 className="reset-info error">{errorMessage}</h5>}
                        </div>
                        :
                        <div>
                            {errorState && <h3>{errorStateMessage}</h3>}
                        </div>
                }
            </div>

        )
    }
}
FormViewComponent.defaultProps = {
    formData: { form_field_list: [] },
    errorState: false,
}
export default withRouter(FormViewComponent);