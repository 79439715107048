import React, { Component } from 'react';
import { DatePicker } from '@progress/kendo-react-dateinputs';
import { Popup } from "@progress/kendo-react-popup";
import InputLabel from '@material-ui/core/InputLabel';
import Description from '../validators/descriptions';
import MandatoryValidator from '../validators/mandatory-validator';
//Validation
import * as ErrorValidation from '../validators/error-validator';
//Custom 
import ErrorMessage from '../common/error-message';

class DateKendo extends Component {

    constructor(props) {
        super(props);
        this.state = {
            value: null,
            errorStates: [],
        };
    }

    handleChange = (event) => {
        let { allowed_days } = this.props;
        const dayOfWeek = event.value.getDay();
        let errors = ErrorValidation.updateErrorMessage(event.target.value.toISOString(), this.props.field_validations, this.props.condition_script, this.props.error_message); //event.target.value.toISOString() //.toUTCString()
        if (allowed_days) {
            let allowed_days_list = allowed_days.split(',');
            allowed_days_list.forEach(day => {
                if (dayOfWeek.toString() === day) {
                    this.setState({ value: event.value, errorStates: errors });
                }
            })
        }
        else {
            this.setState({ value: event.value, errorStates: errors });
        }
        this.props.handleDateChange(event.target.value.toISOString(), errors);
    }

    render() {
        let { name, label, instruction, is_mandatory, defaultLabel, disabled, selected_value, default_value, fieldStyle, customStyle } = this.props;
        let { value, errorStates } = this.state;
        let defaultFieldStyle = "field-box";
        let isDisabled = this.props.is_disable === 'yes' ? true : false;

        return (
            <div className={"form-row " + customStyle}>
                <div className={fieldStyle ? fieldStyle : defaultFieldStyle} ref={el => (this._el = el)}>
                    <InputLabel
                        htmlFor={name}
                        className="label">
                        {label ? label : defaultLabel}
                        <MandatoryValidator value={is_mandatory} />
                    </InputLabel>

                    <DatePicker
                        id={name}
                        margin="normal"
                        defaultValue={null}
                        className="field"
                        value={selected_value ? new Date(selected_value) : (default_value && new Date(default_value))}
                        popup={props => <Popup {...props} appendTo={this._el} />}
                        onChange={this.handleChange.bind(this)}
                        format="dd/MM/yyyy"
                        disabled={isDisabled ? isDisabled : disabled}
                    />
                </div>
                <Description value={instruction} />
                <ErrorMessage errorStates={errorStates} name={name} />
            </div>
        )
    }
}

DateKendo.defaultProps = {
    default_value: null,
}

export default DateKendo