import React, { Component } from 'react';

//API
import * as FormApi from '../../api/form-api';
import * as FormDataApi from '../../api/form-data-api.jsx';

//Custom
import LoadingComponent from '../../components/common/loading-component';
import Fab from '@material-ui/core/Fab';

//Constants
import * as FormCommon from '../../utilities/form-common'
import * as FormConstant from '../../utilities/constants'

//Utils
import * as ScriptEvaluator from '../../utilities/script-evaluator'

//Constants
import * as Constants from '../../utilities/constants';
import * as Function from '../../utilities/common-utils';

//Lodash
import _ from 'lodash';

class FormPreviewScene extends Component {

    state = {
        formData: { form_field_list: [] },
        loading: true,
        formUserDatas: [],
        validationStatus: {},
        isFormValid: false,
        workflowAction: [],
        rulesScript: {},
        count: 0,
        redirect: {},
    }

    componentDidMount() {
        const match = this.props.match;
        // let programkey = match.params.programkey;
        let formkey = match.params.formkey;
        this.getFormData(formkey);
    }

    findElementById(id) {
        let { formData } = this.state;

        let fieldList = formData.form_field_list;
        let selectedField = fieldList.filter(field => (field.id === id))[0];
        return selectedField;
    }

    getFormData(formkey) {
        this.setState({ loading: true });
        let formPromise = FormApi.getFormDataDetails(formkey);
        formPromise.then(formData => {
            formData.form_field_list.sort(function (a, b) {
                return a.position - b.position
            })
            this.sortOptionsAlpha(formData);
            let validationStatus = this.setValidationStatusArray(formData);
            let updatedValidationStatus = this.setFormPreloadDataCheck(formData, validationStatus);
            this.generateFormValidationStatus(updatedValidationStatus);
            this.initialFormData(formData);

            this.setState({ formData: formData, loading: false }, () => this.processRules());
        });
    }

    initialFormData(formData) {
        let { formUserDatas } = this.state;
        formData.form_field_list && formData.form_field_list.map(fieldDetails => {
            let formUserData = formUserDatas[fieldDetails.data_pagination_id - 1];
            if (!formUserData) {
                formUserData = {};
                formUserData["form_pagination_id"] = fieldDetails.data_pagination_id;
                formUserData["object_id"] = formData.id;
                formUserData["process_id"] = this.props.process_id;
                switch (formData.form_type) {
                    case Constants.FORM_TYPE.PARAMETER.code: {
                        formUserData["object_type"] = Constants.RECORD_TYPE.PROGRAM.code;
                        break;
                    }
                    default: {
                        formUserData["object_type"] = Constants.RECORD_TYPE.FORM.code;
                    }
                }
                formUserDatas[fieldDetails.data_pagination_id - 1] = formUserData;
            }
        })
        for (var i = 0; i < formUserDatas.length; i++) {
            let formUserData = formUserDatas[i];
            if (!formUserData) {
                formUserData = {};
                formUserData["form_pagination_id"] = i + 1;
                formUserData["object_id"] = formData.id;
                formUserData["process_id"] = this.props.process_id;
                switch (formData.form_type) {
                    case Constants.FORM_TYPE.PARAMETER.code: {
                        formUserData["object_type"] = Constants.RECORD_TYPE.PROGRAM.code;
                        break;
                    }
                    default: {
                        formUserData["object_type"] = Constants.RECORD_TYPE.FORM.code;
                    }
                }

                formUserDatas[i] = formUserData;
            }
        }

        this.setState({ formUserDatas: formUserDatas });
    }

    processRules() {
        let { formData } = this.state;
        let rulesScrits = JSON.parse(formData.rules_script);
        let workflowRules = JSON.parse(JSON.stringify(rulesScrits.rules));
        workflowRules.forEach(workflowRule => {
            workflowRule.forEach(rule => {
                if (rule.condition) {
                    rule.condition = ScriptEvaluator.evaluatecondition(rule.condition, formData.form_field_list, this.state.formUserDatas);
                } else {
                    rule.condition = true;
                }
            })
            //Only execute on change
            let executableWorkflowRule = workflowRule.filter(rule => rule.executeOn === 1);
            this.executeScript(executableWorkflowRule);
        })

    }

    executeScript(executableWorkflowRule, executeRedirect) {
        let formData = this.state.formData;
        executableWorkflowRule.forEach(rule => {
            if (eval(rule.condition)) {
                rule.tasks.forEach(task => {
                    switch (task.actionSpec) {
                        case Constants.FIELD_ACTIONS.HIDE:
                        case Constants.FIELD_ACTIONS.SHOW:
                        case Constants.FIELD_ACTIONS.ENABLE:
                        case Constants.FIELD_ACTIONS.DISABLE:
                        case Constants.FIELD_ACTIONS.SET: {
                            if (task.actionConfig) {
                                let fieldIds = task.actionConfig.fields;
                                fieldIds && fieldIds.forEach(fieldId => {
                                    let fieldList = formData.form_field_list;
                                    let selectedField = fieldList.filter(field => (field.id === fieldId))[0];
                                    ScriptEvaluator.actionPerformer(task.actionSpec, selectedField, task.actionConfig.values[fieldId]);
                                    this.saveFormElementData(selectedField, task.actionConfig.values[fieldId], formData);
                                    this.setState({ count: 1 })
                                })
                            }
                            break;
                        }
                        case Constants.FIELD_ACTIONS.REDIRECT:
                            this.setState({ redirect: task.actionConfig }, () => { (executeRedirect) && this.redirectBuilder() });
                            break;
                        default: break;
                    }
                })

            }
            else {
                if (this.state.count > 0) {
                    let tasks = JSON.parse(JSON.stringify(rule.tasks));
                    tasks.reverse();
                    tasks.forEach(task => {
                        if (task.actionConfig) {
                            let fieldIds = task.actionConfig.fields;
                            fieldIds && fieldIds.forEach(fieldId => {
                                let fieldList = formData.form_field_list;
                                let selectedField = fieldList.filter(field => (field.id === fieldId))[0];
                                ScriptEvaluator.actionResolver(task.actionSpec, selectedField);
                            })
                        }
                    })
                }
            }
        })
        this.setState({ formData: formData }, () => this.processSpecificFields);
    }

    sortOptionsAlpha(formData) {
        formData.form_field_list.forEach(field => {
            if (field.is_alphabetical === "yes") {
                field.field_option_list.sort(function (a, b) {
                    if (a.field_option_label < b.field_option_label) { return -1; }
                    if (a.field_option_label > b.field_option_label) { return 1; }
                    return 0;
                })
            }
        })
    }

    generateFormValidationStatus(validationStatus) {
        //If user keep all the fields non-mandatory, 
        //then the form is be elgible to Save after the page is just loaded.
        let isVal = true;
        for (var key in validationStatus) {
            if (validationStatus[key] === false) {
                isVal = false
            }
        }

        if (isVal) {
            this.setState({ isFormValid: true });
        }
    }

    setFormPreloadDataCheck(formData, validationStatus) {
        //preselect data setting
        formData.form_field_list.forEach(field => {

            switch (field.field_type) {
                case FormConstant.FieldIds.F_SINGLE_DROPDOWN_GROUP.code:
                case FormConstant.FieldIds.F_MULTIPLE_DROPDOWN_GROUP.code:
                case FormConstant.FieldIds.F_CHECKBOX_GROUP.code:
                    let selectedOptArr = [];
                    let selectedOptStr = "";
                    let checkValid = false;
                    field.field_option_list.forEach(option => {
                        if (option.checked) {
                            selectedOptArr.push(option.id);
                            checkValid = true;
                        }
                    });

                    //Set the field validation status with current vallid status
                    //validationStatus[field.id] = checkValid;
                    if (checkValid) {
                        validationStatus[field.id] = checkValid;
                    }

                    if (selectedOptArr.length > 0) {
                        selectedOptStr = selectedOptArr.join();
                    }
                    this.setState({ validationStatus: validationStatus });
                    this.saveFormElementData(field, selectedOptStr, formData);
                    break;

                default:

                    selectedOptStr = "";
                    checkValid = false;
                    if (field.default_value) {
                        selectedOptStr = field.default_value;
                        checkValid = true
                    }
                    if (checkValid) {
                        validationStatus[field.id] = checkValid;
                    }
                    this.setState({ validationStatus: validationStatus });
                    this.saveFormElementData(field, selectedOptStr, formData);
                    break;

            }
        })
        return validationStatus;
    }

    setValidationStatusArray(formData) {
        let validStatus = this.state.validationStatus;

        formData.form_field_list.forEach(field => {
            if (field.field_validations.length > 0 && field.field_validations.includes("v_required")) {
                validStatus[field.id] = false;
            }
            else {
                validStatus[field.id] = true;
            }
        })
        this.setState({ validationStatus: validStatus });
        return validStatus;
    }

    handleGenOnChangeValue = (fieldDetails) => (value, errors, childField) => {
        let { formData } = this.state;
        if (fieldDetails.group_field) {
            //This funtion is used to update the fieldOptionList of all the Child Dropdowns.
            this.checkChildDropdown(childField, value);

            let childProperties = JSON.parse(JSON.stringify(childField));
            this.checkValidation(childProperties, errors, value);
            this.saveFormElementData(childProperties, value, formData, this.dataSaveCallback);
            childProperties.selected_value = value;
            this.saveFormElementData(childProperties, value, formData);
        }
        else {
            //Validating field details
            this.checkValidation(fieldDetails, errors, value);
            this.saveFormElementData(fieldDetails, value, formData, this.dataSaveCallback);
            fieldDetails.selected_value = value;
        }
    }

    checkChildDropdown(childField, value) {
        if (childField.field_type === FormConstant.FieldIds.F_SINGLE_DROPDOWN_GROUP.code) {
            childField.field_option_list.forEach(field => {
                if (field.field_option_value === value) {
                    field.checked = true;
                }
                else {
                    field.checked = false;
                }
            })
        }
    }

    dataSaveCallback() {
        this.processRules();
        this.processSpecificFields();
    }

    processSpecificFields() {
        let { formData } = this.state;
        let fieldList = formData.form_field_list;
        fieldList.forEach(field => {
            switch (field.field_type) {
                case Constants.FieldIds.F_CALCULATION.code: {
                    let script = ScriptEvaluator.evaluateFieldCondition(field.formula_text, fieldList, this.state.formUserDatas);
                    field.selected_value = eval(script);
                    this.saveFormElementData(field, eval(script), formData, this.processRules);
                    break;
                }
                default: break;
            }
        })
        this.setState({ formData: formData });
    }

    handleButtonEvents = (fieldId, buttonId) => {
        let { formData } = this.state;
        let rulesScrits = JSON.parse(formData.rules_script);
        let workflowRules = JSON.parse(JSON.stringify(rulesScrits.rules));
        workflowRules.forEach(workflowRule => {
            let buttonWorkflowRule = workflowRule.filter(workflow => (workflow.executeOn === 2 && workflow.conditionFields.join().includes(fieldId + "#" + buttonId)));
            buttonWorkflowRule.forEach(rule => {
                if (rule.condition) {
                    rule.condition = ScriptEvaluator.evaluatecondition(rule.condition, formData.form_field_list, this.state.formUserDatas);
                } else {
                    rule.condition = true;
                }
            })
            this.executeScript(buttonWorkflowRule, true);
        })
    }

    saveFormElementData(fieldDetails, value, formData, callback) {
        let { formUserDatas } = this.state;

        let formUserData = formUserDatas[fieldDetails.data_pagination_id - 1];
        if (!formUserData) {
            formUserData = {};
            formUserData["form_pagination_id"] = fieldDetails.data_pagination_id;
            formUserData["object_id"] = formData.id;
            formUserDatas[fieldDetails.data_pagination_id - 1] = formUserData;
        }

        formUserData["data_attr" + fieldDetails.data_ref_id] = value;

        this.setState({ formUserDatas: formUserDatas }, callback);
    }

    checkValidation(fieldDetails, errors, value) {

        let validStatus = this.state.validationStatus;
        var valid = true;
        if (fieldDetails.field_validations.length > 0 || errors.length > 0) {
            //Field which are not mandatory and value is empty
            if (!fieldDetails.field_validations.includes("v_required") && !value && fieldDetails.field_type !== "f-radio-grp") {
                errors.forEach(err => {
                    err.isValid = true
                })
            }

            //Check for all validation status
            errors.forEach(err => {
                if (err.isValid === false) {
                    valid = false
                }
            })

            if (valid) {
                validStatus[fieldDetails.id] = true;
            }
            else {
                validStatus[fieldDetails.id] = false;
            }
        }

        var isVal = true;
        for (var key in validStatus) {
            if (validStatus[key] === false) {
                isVal = false
            }
        }

        if (isVal) {
            this.setState({ isFormValid: true });
        }
        else {
            this.setState({ isFormValid: false });
        }

        this.setState({ validationStatus: validStatus });
    }

    saveFormData() {
        if (this.state.isFormValid) {
            this.setState({ loading: true });
            FormDataApi.saveFormData(this.state.formUserDatas).then(res => {
                this.setState({ formUserDatas: res });
                this.redirectBuilder();
                this.setState({ loading: false });
            });
        } else {
            this.globalValidationCheck();
        }
    }

    cancelEvent = (event) => {
        this.redirectToEditor();
    }

    redirectBuilder() {
        let { redirect } = this.state;
        if (Function.isEmpty(redirect)) {
            this.redirectToEditor();
        } else {
            if (redirect.type === "form") {
                const match = this.props.match;
                let programkey = match.params.programkey;
                // let formkey = match.params.formkey;
                window.location = "/" + programkey + redirect.redirectUrl;
            } else {
                window.location = redirect.redirectUrl;
            }

        }

    }

    redirectToEditor() {
        const match = this.props.match;
        let programkey = match.params.programkey;
        let formkey = match.params.formkey;
        let clientId = match.params.clientId;
        window.location = "/" + clientId + "/" + programkey + "/form/" + formkey + "/builder";
    }

    globalValidationCheck() {
        this.state.formData.form_field_list.forEach(field => {
            field.triggerValidCheck = !field.triggerValidCheck;
        });
        this.setState({ formData: this.state.formData });
    }

    createChildFields(formData) {
        if (formData.form_field_list.length > 0) {
            formData.form_field_list.forEach(field => {
                if (field.group_field) {
                    if (!field.hasOwnProperty('children')) {
                        field["children"] = formData.form_field_list.filter(child => child.parent_id === field.id)
                    }
                }
            })

            //Deleting the child fields using Lodash.
            _.remove(formData.form_field_list, function (field) {
                return field.parent_id !== null;
            });
        }
    }

    render() {
        let { loading, formData } = this.state;
        this.createChildFields(formData);
        return (

            <section className="form-builder-sec form-preview-sec">
                {/* <Scrollbars style={{ width: '100%', height: '100%' }}> */}
                <div className="form-preview">
                    <div className="card-block">
                        <div className="card-header border-bottom">
                            <div className="sub-title">
                                <h5>{this.state.formData.form_name}</h5>
                                <h6>{this.state.formData.form_desc}</h6>
                            </div>
                            <div className="btn-wrap btn-reset">
                                <Fab variant="extended" size="small" className="btn btn-small btn-plane btn-curve transparent-color no-shadow">
                                    Reset
                                </Fab>
                                <Fab variant="extended" size="small" className="btn btn-small btn-plane btn-curve grey-color no-shadow" onClick={this.cancelEvent}>
                                    Edit
                                </Fab>
                            </div>
                        </div>
                        <div className="card-body">
                            {
                                formData.form_field_list.map(field => {
                                    if (field.is_hidden !== 'yes') {
                                        return FormCommon.fieldGenerate(field.field_type, field, this.handleGenOnChangeValue(field).bind(this), this.handleButtonEvents)
                                    } else {
                                        return null;
                                    }
                                })
                            }
                        </div>
                        <div className="card-footer border-top">

                            {this.props.type === "preview" ?
                                (
                                    <div className="btn-wrap">
                                        <Fab variant="extended" size="small" className="btn btn-small btn-plane btn-curve pink-color no-shadow" onClick={this.cancelEvent}>
                                            Exit Preview
                                        </Fab>
                                    </div>
                                ) :
                                this.state.formData.form_type !== FormConstant.FORM_TYPE.ACTION.code &&
                                (<div className="btn-wrap">
                                    <Fab variant="extended" size="small" className="btn btn-small btn-plane btn-curve no-shadow" disabled={!this.state.isFormValid} onClick={this.saveFormData.bind(this)}>
                                        Save
                                    </Fab>
                                    <Fab variant="extended" size="small" className="btn btn-small btn-plane btn-curve transparent-color no-shadow" onClick={this.cancelEvent}>
                                        Cancel
                                    </Fab>
                                </div>)
                            }

                        </div>

                    </div>
                </div>
                {/* </Scrollbars> */}
                <LoadingComponent loading={loading} />
            </section>
        )
    }
}

export default (FormPreviewScene);