import React, { Component } from 'react';
import InputLabel from '@material-ui/core/InputLabel';

class SectionFieldType extends Component {

    render() {
        let { label, defaultLabel } = this.props;
        return (
            <div className={"form-row form-sections-title"}>
                <h5>
                    {label ? label : defaultLabel}
                </h5>
            </div>
        );
    }
}
export default SectionFieldType