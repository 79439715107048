import * as AjaxHelper from "../utilities/ajax-helper"
import { ApiConstants } from "./common/api-constants"

let apiBaseUrl = () => (ApiConstants.DEV_MODE === 'true' ? ApiConstants.HOST : window.location.origin) + ApiConstants.API_VERSION;

export function getAllProgramStates() {
    return AjaxHelper.get(apiBaseUrl() + ApiConstants.PROGRAM, null, { "Content-Type": "application/json" });
}

export function getProgramByProgramKey(programKey) {
    return AjaxHelper.get(apiBaseUrl() + ApiConstants.PROGRAM + "/programKey/" + programKey, null, { "Content-Type": "application/json" });
}

export function createProgram(programData) {
    return AjaxHelper.post(apiBaseUrl() + ApiConstants.PROGRAM, programData, { "Content-Type": "application/json" });
}

export function createSection(sectionData) {
    return AjaxHelper.post(apiBaseUrl() + ApiConstants.PROGRAM_SECTION, sectionData, { "Content-Type": "application/json" });
}

export function getAllSectionCount(programKey) {
    return AjaxHelper.get(apiBaseUrl() + ApiConstants.PROGRAM_SECTION + "/" + programKey + "/statecount", null, { "Content-Type": "application/json" });
}

export function getAllSection(programKey) {
    return AjaxHelper.get(apiBaseUrl() + ApiConstants.PROGRAM_SECTION + "/" + programKey + "/details", null, { "Content-Type": "application/json" });
}

export function getSection(programKey) {
    return AjaxHelper.get(apiBaseUrl() + ApiConstants.PROGRAM_SECTION + "/" + programKey, null, { "Content-Type": "application/json" });
}

export function getAvalibaleSection(programKey) {
    return AjaxHelper.get(apiBaseUrl() + ApiConstants.PROGRAM_SECTION + "/" + programKey + "/available", null, { "Content-Type": "application/json" });
}

export function updateSectiontatus(sectionId, sectionStatus) {
    return AjaxHelper.put(apiBaseUrl() + ApiConstants.PROGRAM_SECTION + "/" + sectionId + "/status?sectionStatus=" + sectionStatus, null, { "Content-Type": "application/json" });
}

export function saveProgramData(programData) {
    return AjaxHelper.post(apiBaseUrl() + ApiConstants.PROGRAM_DATA, programData, { "Content-Type": "application/json" });
}

export function getProgramData(programkey) {
    return AjaxHelper.get(apiBaseUrl() + ApiConstants.PROGRAM_DATA + "/" + programkey, null, { "Content-Type": "application/json" });
}

export function getNavigationData(programkey) {
    return AjaxHelper.get(apiBaseUrl() + ApiConstants.PROGRAM + "/" + programkey + "/navigation", null, { "Content-Type": "application/json" });
}

export function getProgramDataFields(programkey) {
    return AjaxHelper.get(apiBaseUrl() + ApiConstants.PROGRAM_DATA + "/" + programkey + "/fields", null, { "Content-Type": "application/json" });
}

export function getSectionDetails(sectionKey) {
    return AjaxHelper.get(apiBaseUrl() + ApiConstants.PROGRAM_SECTION + "/" + sectionKey + "/sectionDetails", null, { "Content-Type": "application/json" });
}

export function updateSection(sectionDetails) {
    return AjaxHelper.put(apiBaseUrl() + ApiConstants.PROGRAM_SECTION, sectionDetails, { "Content-Type": "application/json" });
}
export function updateProgram(programDetails) {
    return AjaxHelper.put(apiBaseUrl() + ApiConstants.PROGRAM, programDetails, { "Content-Type": "application/json" });
}

export function getAllProgramByClientId(clientId) {
    return AjaxHelper.get(apiBaseUrl() + ApiConstants.PROGRAM + "/clientId/" + clientId, null, { "Content-Type": "application/json" });
}
