import * as AjaxHelper from "../utilities/ajax-helper"
import { ApiConstants } from "./common/api-constants"

let apiBaseUrl = () => (ApiConstants.DEV_MODE === 'true' ? ApiConstants.HOST : window.location.origin) + ApiConstants.API_VERSION;

export function saveDataSourceConfig(dataSourceConfig) {
    return AjaxHelper.put(apiBaseUrl() + ApiConstants.DATA_SOURCE_CONFIG, dataSourceConfig, { "Content-Type": "application/json" });
}

export function getDataSourceConfig() {
    return AjaxHelper.get(apiBaseUrl() + ApiConstants.DATA_SOURCE_CONFIG, null, { "Content-Type": "application/json" });
}
