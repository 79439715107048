import React, { Component } from 'react';

import SelectField from '../../components/fields/select';
import RadioField from '../../components/fields/radio';
import TextAreaField from '../../components/fields/textarea';
import Grid from '@material-ui/core/Grid';
import Fab from '@material-ui/core/Fab';
import Dialog from '@material-ui/core/Dialog';
import CloseIcon from '../../assets/images/icon/close.svg';
import WorkflowIllust from '../../assets/images/illustration/program-iso.svg';
import ScratchIllus from '../../assets/images/illustration/scratch.svg';
import Slide from '@material-ui/core/Slide';
import InputField from '../../components/fields/inputs';
import Button from '@material-ui/core/Button';
import { Scrollbars } from 'react-custom-scrollbars';

//API
import * as ProgramApi from '../../api/program-api';


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

class ProgramCreate extends Component {

    state = {
        open: true,
        programData: {},
    }

    createProgramData() {
        ProgramApi.createProgram(this.state.programData).then(res => {
            this.editProgram(res.program_key);
        });
    }

    editProgram = key => {
        window.location = "/" + key + "/program-dashboard";
    }

    handleProgramChange = (type) => (value) => {
        let programData = this.state.programData;
        programData[type] = value;
        this.setState({ programData: programData });
    }

    createNewWorkflow() {
        this.handleDialogOpen("formTypeDialogOpen");
    }

    render() {
        let { open } = this.state;
        let defalutSections = [
            {
                field_option_label: "Policy",
                field_option_value: "Policy",
            },
            {
                field_option_label: "Claim",
                field_option_value: "Claim"
            },
        ]
        let programType = [
            {
                field_option_label: "Individual",
                field_option_value: "individual",
            },
            {
                field_option_label: "Group",
                field_option_value: "group"
            }
        ]

        return (
            <main>
                <div className="workflow-fields-sec">
                    <Dialog open={open} TransitionComponent={Transition} maxWidth="xl">
                        <section className="workflow-dialog workflow-dialog-large">
                            <div className="card-block">
                                <div className="card-header border-bottom">
                                    <div className="sub-title">
                                        <h5>Program</h5>
                                    </div>
                                    <div className="close-dialog">
                                        <img src={CloseIcon} alt="CloseIcon"></img>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <Grid container>
                                        <Grid item md={4}>
                                            <div className="workflow-illust-wrap">
                                                <div className="sub-title">
                                                    <h2>Create a New Program</h2>
                                                </div>
                                                <div className="workflow-illust">
                                                    <img src={WorkflowIllust} alt="workflowIllust" />
                                                </div>
                                            </div>
                                        </Grid>
                                        <Grid item md={8}>
                                            <div className="workflow-dialog-forms form-builder-dashboard">
                                                <div className="tabs-container tabs-container-scroll">
                                                    <Scrollbars style={{ width: '100%', height: '100%' }}>
                                                        <div className="tabs-container-scroll-wrap">
                                                            <Grid item md={12}>
                                                                <div className="workflow-form-scene form-row">
                                                                    <div className="workflow-form-select active">
                                                                        <Button variant="contained" color="primary" className="btn">
                                                                            <img src={ScratchIllus} alt="ScratchIllus"></img>
                                                                        </Button>
                                                                        <h5>Scratch</h5>
                                                                    </div>
                                                                    {/* <div className="workflow-form-select">
                                                                        <Button variant="contained" color="primary" className="btn">
                                                                            <img src={TemplateIllus} alt="TemplateIllus"></img>
                                                                        </Button>
                                                                        <h5>Template</h5>
                                                                    </div> */}
                                                                    <div className="workflow-form-info">
                                                                        <h6 className="sub-title semi-bold">Create a Program from Scratch ?</h6>
                                                                        <h6 className="grey-color weight-300">You can create your program from scratch by creating and adding sections, forms,flows and users</h6>
                                                                    </div>
                                                                </div>
                                                            </Grid>
                                                            <div className="workflow-form-field">
                                                                <Grid container lg={12} md={12}>
                                                                    <Grid item lg={5} md={8}>
                                                                        <InputField label="Name of the Program" type="text" name="form_name" selected_value={this.state.programData.program_name} fieldStyle="field-box bg-plane grey-color" placeholder="Name" handleOnChangeValue={this.handleProgramChange("program_name").bind(this)} />
                                                                    </Grid>
                                                                    <Grid item lg={5} md={8}>
                                                                        <RadioField
                                                                            radioGroupLabel="Program_Type"
                                                                            radioData={programType}
                                                                            label="Program Type"
                                                                            radioFieldStyle="field-inline"
                                                                            selected_value={this.state.programData.program_type}
                                                                            radioChangeHandle={this.handleProgramChange("program_type").bind(this)}
                                                                        />
                                                                    </Grid>
                                                                </Grid>
                                                                <Grid container lg={12} md={12}>
                                                                    <Grid item lg={5} md={8}>
                                                                        <TextAreaField label="Description" name="form_description" rowmax="3" selected_value={this.state.programData.program_desc} fieldStyle="field-box bg-plane grey-color" placeholder="Description" handleOnChangeValue={this.handleProgramChange("program_desc").bind(this)} />
                                                                    </Grid>
                                                                    <Grid item lg={5} md={8}>
                                                                        <SelectField optionsData={defalutSections} is_search={false} selected_value={this.state.programData.master_policy} fieldStyle="field-box bg-plane grey-color" label="Master Policy Upload" radioChangeHandle={this.handleProgramChange("master_policy").bind(this)} />
                                                                    </Grid>
                                                                </Grid>
                                                            </div>
                                                        </div>
                                                    </Scrollbars>
                                                </div>
                                                <div className="form-row workflow-dialog-footer">
                                                    <div className="btn-wrap no-border">
                                                        <Fab variant="extended" size="large" className="btn btn-large btn-plane primary-color" onClick={this.createProgramData.bind(this)}>
                                                            Create Program
                                                        </Fab>
                                                    </div>
                                                </div>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </div>
                            </div>
                        </section>
                    </Dialog>
                </div>
            </main>
        )
    }
}

export default ProgramCreate;