import * as AjaxHelper from "../utilities/ajax-helper"
import { ApiConstants } from "./common/api-constants"

let apiBaseUrl = () => (ApiConstants.DEV_MODE === 'true' ? ApiConstants.HOST : window.location.origin) + ApiConstants.API_VERSION;

export function postFormData(formData) {
    return AjaxHelper.post(apiBaseUrl() + ApiConstants.FORM, formData, { "Content-Type": "application/json" });
}

export function putFormData(formId, formData) {
    return AjaxHelper.put(apiBaseUrl() + ApiConstants.FORM + "/" + formId, formData, { "Content-Type": "application/json" });
}

export function getAllFormData(programKey) {
    return AjaxHelper.get(apiBaseUrl() + ApiConstants.FORM + "/" + programKey + "/program", null, { "Content-Type": "application/json" });
}

export function getParameterFormData(programKey) {
    return AjaxHelper.get(apiBaseUrl() + ApiConstants.FORM + "/" + programKey + "/parameter", null, { "Content-Type": "application/json" });
}

export function getFormData(formId) {
    return AjaxHelper.get(apiBaseUrl() + ApiConstants.FORM + "/" + formId, null, { "Content-Type": "application/json" });
}

export function getFormDataSectional(formId) {
    return AjaxHelper.get(apiBaseUrl() + ApiConstants.FORM + "/" + formId + "/sectionform", null, { "Content-Type": "application/json" });
}

export function getFormDataByKey(formkey) {
    return AjaxHelper.get(apiBaseUrl() + ApiConstants.FORM + "/" + formkey + "/key", null, { "Content-Type": "application/json" });
}

export function getFormDataDetails(formkey, processId) {
    if (processId) {
        return AjaxHelper.get(apiBaseUrl() + ApiConstants.FORM + "/" + formkey + "/details?processId=" + processId, null, { "Content-Type": "application/json" });
    }
    else {
        return AjaxHelper.get(apiBaseUrl() + ApiConstants.FORM + "/" + formkey + "/details", null, { "Content-Type": "application/json" });

    }
}

export function deleteFormField(fieldId) {
    return AjaxHelper.del(apiBaseUrl() + ApiConstants.FIELD + "/" + fieldId, null, { "Content-Type": "application/json" });
}

export function deleteAllFormField(formId) {
    return AjaxHelper.del(apiBaseUrl() + ApiConstants.FIELD + "/" + formId + "/delete", null, { "Content-Type": "application/json" });
}

export function clearAndResetForm(formId, templateId) {
    return AjaxHelper.del(apiBaseUrl() + ApiConstants.FIELD + "/form/" + formId + "/template/" + templateId + "/reset", null, { "Content-Type": "application/json" });
}

export function addFieldDetails(fieldDetail, formId) {
    return AjaxHelper.post(apiBaseUrl() + ApiConstants.FIELD + "/" + formId, fieldDetail, { "Content-Type": "application/json" });
}

export function updateFormStatus(formId, status) {
    //return AjaxHelper.put(apiBaseUrl()+ApiConstants.FORM+"/"+formId+"/status?formStatus="+status , null, {"Content-Type":"application/json"});
}

export function duplicateForm(form) {
    return AjaxHelper.post(apiBaseUrl() + ApiConstants.FORM + ApiConstants.DUPLICATE, form, { "Content-Type": "application/json" });
}

export function getFormListFromProgramKeyAndFormType(programKey, formType) {
    return AjaxHelper.get(apiBaseUrl() + ApiConstants.FORM + "/parameter/" + programKey + "?formType=" + formType, null, { "Content-Type": "application/json" });
}
