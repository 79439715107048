import * as AjaxHelper from "../utilities/ajax-helper"
import { ApiConstants } from "./common/api-constants"

let apiBaseUrl = () => (ApiConstants.DEV_MODE === 'true' ? ApiConstants.HOST : window.location.origin) + ApiConstants.API_VERSION;

// export function getFormElementById(formId){
//     return AjaxHelper.get(apiBaseUrl+ApiConstants.FORM_ELEMENT_DATA+"/"+formId, null, {"Content-Type":"application/json"});
// }

export function getFormFieldMetadataTypes() {
    return AjaxHelper.get(apiBaseUrl() + ApiConstants.FORM_FIELD_METADATA + "/types", null, { "Content-Type": "application/json" });
}

export function getFormFieldMetadataProperties() {
    return AjaxHelper.get(apiBaseUrl() + ApiConstants.FORM_FIELD_METADATA + "/properties", null, { "Content-Type": "application/json" });
}
