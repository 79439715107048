import React, { Component } from 'react';
import Header from '../../components/header';
import ProgramIcon from '../../assets/images/icon/square-4-blue.svg';
import ArrowIcon from '../../assets/images/icon/chevron-blue.svg';
import { Scrollbars } from 'react-custom-scrollbars';
import Fab from '@material-ui/core/Fab';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import SearchField from '../../components/fields/search';

import Slide from '@material-ui/core/Slide';
import MultipleTabActions from '../../components/common/multiple-tab-actions';

//API
import * as UserApi from '../../api/user-api';

class PolicyList extends Component {
    state = {
        userProcessData: [],
        customStyleSearch: "",
        searchedProgramData: [],
    }

    componentWillMount() {
        UserApi.getLoggedInUser().then(data => {
            UserApi.getUserDetailsFromUserProcessMapping().then(res => {
                this.setState({ userProcessData: res, searchedProgramData: res });
            })
        })

    }

    toggleTimeline() {
        this.setState({ active: !this.state.active });
    }

    redirectToDetailsView(key, id) {
        const match = this.props.match;
        let clientId = match.params.clientId;
        window.location = '/' + clientId + '/' + key + "/details-view/" + id;
    }

    handleSearchOnChangeValue(event) {
        let { userProcessData } = this.state;

        if (event.target.value === "") {
            this.setState({ searchedProgramData: userProcessData });
        } else {
            let searchedData = [];
            searchedData = userProcessData.filter(program => program.program_name.toLowerCase().includes(event.target.value.toLowerCase()) || (program.section_name && program.section_name.toLowerCase().includes(event.target.value.toLowerCase())));
            this.setState({ searchedProgramData: searchedData });
        }

    }


    render() {
        let { active, tabAction, buttonState, userProcessData, searchedProgramData } = this.state;
        let toggleTimeline = active ? "toggle-timeline" : "";
        let ToggleBtnArrow = buttonState ? "toggle-arrow" : "";
        let colorSet = {
            'a': '#836eff',
            'b': '#ff5f88',
            'c': '#ffa66e',
            'd': '#ff5f88',
            'e': '#f7b500',
            'f': '#ea5455',
            'g': '#e02020',
            'h': '#44d7b6',
            'i': '#fa6400',
            'j': '#a0a9ba',
            'k': '#836eff',
            'l': '#ff5f88',
            'm': '#ffa66e',
            'n': '#ff5f88',
            'o': '#f7b500',
            'p': '#ea5455',
            'q': '#e02020',
            'r': '#44d7b6',
            's': '#fa6400',
            't': '#a0a9ba',
            'u': '#f7b500',
            'v': '#ea5455',
            'w': '#e02020',
            'x': '#44d7b6',
            'y': '#fa6400',
            'z': '#a0a9ba',
        }
        const match = this.props.match;
        let programkey = match.params.programkey;
        let clientId = match.params.clientId;
        console.log("userProcessData", userProcessData);

        return (
            <div className="CreateApplication">
                <Header headerType="dashboard" positionType="fixed" programKey={programkey} clientId={clientId} searchType="normalClick" customStyleSearch={this.state.customStyleSearch} handleSearchOnChangeValue={(event) => this.handleSearchOnChangeValue(event)} />
                <main>
                    <div className="workflow-fields-sec program-build-sec create-app-sec">
                        <div className="program-build-wrap">
                            <div className={"program-card-wrap program-build-timeline " + toggleTimeline}>
                                <div className="card-block">
                                    <div className="card-body">
                                        <section className="create-app-timeline">
                                            <div className="cretae-timeline-wrap">
                                                <div className={"program-timeline-btn active"}>
                                                    <Fab variant="extended" size="small" className="btn btn-small no-shadow btn-icon">
                                                        <img src={ProgramIcon} alt="ProgramIcon" />
                                                        {
                                                            !toggleTimeline && (
                                                                <span>Policy</span>
                                                            )
                                                        }
                                                    </Fab>
                                                </div>
                                            </div>
                                            <div className={"program-timeline-btn program-timeline-toggle " + ToggleBtnArrow} onClick={this.toggleTimeline.bind(this)} >
                                                <Fab variant="extended" size="small" className="btn btn-small no-shadow btn-toggle">
                                                    <img src={ArrowIcon} alt="ArrowIcon" />
                                                </Fab>
                                            </div>
                                        </section>
                                    </div>
                                </div>
                            </div>
                            <div className={"program-card-wrap program-build-form " + toggleTimeline}>
                                <div className="card-block">
                                    <div className="card-body">
                                        <div className="drawer-tabs drawer-tabs-round no-drawer-indicator workflow-tabs-wrap">
                                            <div className="drawer-tabs-header">
                                                <div className="tabs-section">
                                                </div>
                                                <div className="workflow-tabs-search">
                                                    <SearchField placeholder="Search here" searchType="normal" customStyle="white-color" />
                                                </div>
                                            </div>
                                            <div className="tab-container">
                                                <Scrollbars style={{ width: '100%', height: '100%' }}>
                                                    <div className="table-wrapper">
                                                        {
                                                            searchedProgramData.map(fields => {
                                                                return (
                                                                    <div className="create-app-block" onClick={() => this.redirectToDetailsView(fields.process_key, fields.process_id)}>
                                                                        <div className="create-app-wrap">

                                                                            <Fab variant="extended" size="large"
                                                                                className="btn btn-large btn-fab btn-plane no-shadow grey-color no-shadow"
                                                                                style={{ background: (colorSet["p"]) }}
                                                                            >
                                                                                {
                                                                                    fields.program_name !== null ?
                                                                                        fields.program_name.trim().split(" ").length === 1 ?
                                                                                            fields.program_name.trim().split(" ")[0].slice(0, 1) :
                                                                                            fields.program_name.trim().split(" ")[0].slice(0, 1) + fields.program_name.trim().split(" ")[1].slice(0, 1)
                                                                                        : ""
                                                                                }
                                                                            </Fab>

                                                                            <h5 className="create-info create-details">{fields.program_name}</h5>
                                                                            <h6 className="create-info create-details">{fields.section_name}</h6>
                                                                            <h6 className="font-med create-desc">{fields.id_field_name}</h6>
                                                                        </div>
                                                                        <div className="app-notify">
                                                                            <MultipleTabActions type="icons" iconList={["edit", "delete", "share"]} />
                                                                        </div>
                                                                    </div>

                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </Scrollbars>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        )
    }
}

export default PolicyList;