import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';

const styles = theme => ({
  progress: {
    margin: theme.spacing.unit * 2,
  },
});

function LoadingComponent(props) {
  const { classes, loading } = props;
  return (
    <Dialog
      open={loading}
      aria-labelledby="form-dialog-title"
      fullWidth={false}
      className="loader-wrap"
    >
      <div>
        <CircularProgress className={classes.progress} />
      </div>
    </Dialog>
  );
}

LoadingComponent.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(LoadingComponent);