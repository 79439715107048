import React, { Component } from 'react';

class MandatoryValidator extends Component {
    render() {
        const { value } = this.props

        return (
            value === "yes" ? <span className="validate-mark"> *</span> : ""
        )
    }
}

export default MandatoryValidator;