/* eslint-disable no-undef */
import React, { Component } from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import Fab from '@material-ui/core/Fab';
import Typography from '@material-ui/core/Typography';
import { Scrollbars } from 'react-custom-scrollbars';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUp from '@material-ui/icons/KeyboardArrowUp';
import SearchField from '../../components/fields/search';
import DetailsActivity from './details-activity';
import TableComponent from '../../components/common/table-component';
import MultipleTabActions from '../../components/common/multiple-tab-actions';
import EyeBlueIcon from '../../assets/images/icon/eye-blue.svg';

//Constants
import * as FormConstants from '../../utilities/constants';
import { icons } from '../../utilities/constants';

//Utilities
import * as RecordProcessor from '../../utilities/form-record-process';

//Api
import * as StorageFileApi from '../../api/file-storage-api';
import { es } from 'date-fns/locale';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            <Box p={3}>{children}</Box>
        </Typography>
    );
}

function a11yProps(index) {
    return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
}

const tableRows = [
    { id: 'fileName', numeric: false, disablePadding: false, label: 'File Name' },
    { id: 'type', numeric: true, disablePadding: false, label: 'Type' },
    { id: 'user', numeric: true, disablePadding: false, label: 'User' },
    { id: 'createdOn', numeric: true, disablePadding: false, label: 'Created on' },
    // { id: 'lastActionOn', numeric: true, disablePadding: false, label: 'Last Action on' },
    { id: 'actions', numeric: true, disablePadding: false, label: 'Actions' },
];

function createData(file_icon, file_name, userName, created_on, downloadFile, fileId) {
    let fileName = <span><img src={EyeBlueIcon} alt="EyeBlueIcon" /> {file_name}</span>;
    let type = "User Uploaded";
    let user = userName;
    let createdOn = created_on;
    // let lastActionOn = last_action_on;

    let actionView = (<MultipleTabActions type="icons" handleDownloadClick={() => { downloadFile(fileId) }} iconList={["download"]} />)//["upload","download","message","extra"]

    return { fileName, type, user, createdOn, actionView };
}

class DetailsFormTabs extends Component {
    state = {
        value: 0,
        customStyleSearch: 'only-small-search',
        heightOfDisplay: false,
        heightOfDocument: false,
        documentValue: 0,
        tableData: [],
    }
    handleChange(event, newValue) {
        this.setState({ value: newValue });
        this.props.handleFormSelectedOnTab(newValue);
        // this.props.handleTabChange(newValue);
    }

    handleDocChange(event, newValue) {
        this.setState({ documentValue: newValue });
    }

    searchBtnClick() {
        this.setState({ customStyleSearch: "" });
    }

    generateDataList(formDataList, updatedFormDataList, updateReverseAuditDate) {
        let dataAdd = []
        let dataFilter = []
        let i = 1;
        let j = 1;
        let objectId = '';
        let newFormDataList = JSON.parse(JSON.stringify(formDataList));
        newFormDataList.length > 0 && newFormDataList.forEach((data, index) => {

            //Sorting field List based on their positions.
            data.form.form_field_list.sort(function (a, b) {
                if (a.position < b.position) { return -1; }
                if (a.position > b.position) { return 1; }
            })

            if (data) {
                let recordDataList = RecordProcessor.generateProcessedRecords(data.form, data.records);
                //only show show true data
                recordDataList.columns = recordDataList.columns.filter(column => column.show);
                recordDataList.columns.forEach(column => {
                    recordDataList.rows.forEach(row => {
                        if (row[column.id]) {
                            column.value = row[column.id];
                            return;
                        }
                    })
                })
                data.fieldDataList = recordDataList.columns;
                delete data.records;
            }

            if (index !== 0) {
                data.auditTrialDate1 = updatedFormDataList[(newFormDataList.length - 1) - index];
                data.auditTrialDate2 = updateReverseAuditDate[(newFormDataList.length - 1) - index];
            }

        })

        newFormDataList.map((data, index) => {

            if (index !== 0) {
                if (index === 1 || objectId === data.form.id) {
                    data.countNumber = i;
                    objectId = data.form.id
                    i++;
                }
                else {
                    data.countNumber = j;
                    j++;
                }
            }
            if (index === 0) {
                dataAdd.push(data)
            } else {
                dataFilter.push(data)
            }
        })

        dataFilter.reverse();
        dataAdd = dataAdd.concat(dataFilter)
        return dataAdd;
    }

    handleViewClick(formData) {
        if (formData.fieldDataList.length > 6) {
            let { heightOfDisplay } = this.state;
            this.setState({ heightOfDisplay: !heightOfDisplay });
        }
    }

    handleViewClickDocument() {
        let { heightOfDocument } = this.state;
        this.setState({ heightOfDocument: !heightOfDocument });
    }

    downloadFile(fileId) {
        StorageFileApi.getFileUpload(Number(fileId)).then(response => {

        })
    }

    createFileTable(updatedFormList, processData) {
        let tableData = [];
        updatedFormList.forEach(formList => {
            formList.fieldDataList &&
                formList.fieldDataList.forEach(list => {
                    if (list.fieldType === FormConstants.FieldIds.F_UPLOAD.code && list.value && list.value !== "") {
                        tableData.push(createData(icons.i_mail.icon, list.label, formList.form && formList.form.created_by, formList.form && formList.auditTrialDate2, this.downloadFile, list.value))
                    }
                })
        })
        return tableData;
    }
    //this.convertFileIdToFileName(list.value)

    convertFileIdToFileName(fileId) {
        let { uploadedFileDetails } = this.props;
        let fileNameToReturn = fileId;
        uploadedFileDetails.forEach(fileDetails => {
            if (fileDetails.file_id === Number(fileId)) {
                fileNameToReturn = fileDetails.file_name;
            }
        })
        return fileNameToReturn;
    }

    render() {
        let { tab, formDataList, processData, updatedFormDataList, updateReverseAuditDate } = this.props;
        let { value, customStyleSearch, heightOfDisplay, heightOfDocument, documentValue } = this.state;
        let updatedFormList = this.generateDataList(formDataList, updatedFormDataList, updateReverseAuditDate);
        let tableData = this.createFileTable(updatedFormList, processData);
        return (
            <section className="workflow-header-x">
                <div className="workflow-nav workflow-tab-nav">
                    <div className="workflow-mega-switch">
                        <Tabs
                            value={value}
                            onChange={this.handleChange.bind(this)}
                            indicatorColor="primary"
                            textColor="primary"
                            variant="scrollable"
                            scrollButtons="auto"
                            aria-label="scrollable auto tabs example"
                        >
                            {
                                updatedFormList.map((formData, index) => (
                                    <Tab label={
                                        <Fab variant="extended" size="large" className={"btn workflow-switch-btn no-shadow" + (tab === "home" ? " active" : "")} >
                                            {formData.form.form_name} {index === 0 ? '' : formData.countNumber} {index === 0 ? '' : '|'}  {index === 0 ? '' : formData.auditTrialDate1}
                                        </Fab>} {...a11yProps(index)} />
                                ))
                            }
                        </Tabs>

                    </div>

                    <div className="details-view-block">
                        <Scrollbars style={{ width: '100%', height: '100%' }}>
                            <div className="tabs-conatiner">
                                {updatedFormList.map((formData, index) => (
                                    <TabPanel value={value} index={index}>
                                        <div className="form-builder-view">
                                            <div className="form-sections-overflow" style={{ height: heightOfDisplay ? "auto" : "400px", overflow: 'hidden' }}>
                                                {
                                                    [1].map(fields => {
                                                        return (
                                                            <div className="form-sections-wrap">
                                                                <div className="form-sections-fields">
                                                                    {
                                                                        formData.fieldDataList.map(fields => {
                                                                            return (
                                                                                <div className={fields.fieldType === FormConstants.FieldIds.F_SECTION_FIELD_TYPE.code ? "form-row form-sections-title" : "form-row"}>
                                                                                    <h6 className="label">{fields.label}</h6>
                                                                                    {
                                                                                        fields.fieldType === FormConstants.FieldIds.F_UPLOAD.code ?
                                                                                            <a className="field" href="#" onClick={() => { this.downloadFile(fields.value) }}>{this.convertFileIdToFileName(fields.value)}</a> :
                                                                                            fields.fieldType === FormConstants.FieldIds.F_SECTION_FIELD_TYPE.code ?
                                                                                                "" :
                                                                                                <h6 className="field">{fields.value}</h6>
                                                                                    }
                                                                                </div>
                                                                            )
                                                                        })
                                                                    }
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                            <div className="form-sections-wrap" style={{ marginTop: '15px' }}>
                                                <div className="form-sections-fields">
                                                    <div className="form-row" style={{ marginBottom: '10px' }}>
                                                        <div className="btn-wrap">
                                                            {!heightOfDisplay ?
                                                                <Fab variant="extended" size="small" className="btn btn-small btn-plane btn-fab white-color no-shadow btn-view-more" onClick={() => this.handleViewClick(formData)}>
                                                                    View More <KeyboardArrowDown />
                                                                </Fab>
                                                                :
                                                                <Fab variant="extended" size="small" className="btn btn-small btn-plane btn-fab white-color no-shadow btn-view-more" onClick={() => this.handleViewClick(formData)}>
                                                                    View Less <KeyboardArrowUp />
                                                                </Fab>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-builder-view">
                                            <div className="drawer-tabs drawer-tabs-round no-drawer-indicator workflow-tabs-wrap">
                                                <div className="drawer-tabs-header">
                                                    <Tabs
                                                        value={documentValue}
                                                        onChange={this.handleDocChange.bind(this)}
                                                        indicatorColor="primary"
                                                        textColor="primary"
                                                        variant="scrollable"
                                                        scrollButtons="auto"
                                                        aria-label="scrollable auto tabs example"
                                                    >
                                                        <Tab label="Document" {...a11yProps(0)}></Tab>
                                                        <Tab label="Activity" {...a11yProps(1)}></Tab>
                                                    </Tabs>
                                                    <div className="workflow-tabs-search">
                                                        <SearchField placeholder="Search here" searchType="normal" customStyle={customStyleSearch} searchBtnClick={this.searchBtnClick.bind(this)} />
                                                    </div>
                                                </div>
                                                <TabPanel value={documentValue} index={0}>
                                                    <div className="tab-container">
                                                        <div className="table-wrapper">
                                                            {/* Document Table */}
                                                            {/* {tableData.length>0 && ( */}
                                                            <div className="table-wrapper vertical-bottom">
                                                                <TableComponent
                                                                    tableLabel=""
                                                                    rows={tableRows}
                                                                    isPagginated={true}
                                                                    isSearchable={false}
                                                                    data={tableData}
                                                                    searchLabel=""
                                                                    isCheckBoxEnabled={false}
                                                                />
                                                            </div>
                                                            {/* )} */}
                                                        </div>
                                                    </div>
                                                </TabPanel>
                                                <TabPanel value={documentValue} index={1}>
                                                    <div className="tabs-conatiner">
                                                        {/* <div className="table-wrapper">
                                                                Activity Table
                                                            </div> */}
                                                        <div className="table-wrapper">
                                                            <div className="form-sections-overflow" style={{ height: heightOfDocument ? "auto" : "200px", overflow: 'hidden' }}>
                                                                <DetailsActivity auditTrail={this.props.auditTrail} />
                                                            </div>
                                                            <div className="form-sections-wrap" style={{ marginTop: '15px' }}>
                                                                <div className="form-sections-fields">
                                                                    <div className="form-row" style={{ marginBottom: '10px' }}>
                                                                        <div className="btn-wrap">
                                                                            {!heightOfDocument ?
                                                                                <Fab variant="extended" size="small" className="btn btn-small btn-plane btn-fab white-color no-shadow btn-view-more" onClick={() => { this.handleViewClickDocument() }}>
                                                                                    View More <KeyboardArrowDown />
                                                                                </Fab>
                                                                                :
                                                                                <Fab variant="extended" size="small" className="btn btn-small btn-plane btn-fab white-color no-shadow btn-view-more" onClick={() => { this.handleViewClickDocument() }}>
                                                                                    View Less <KeyboardArrowUp />
                                                                                </Fab>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </TabPanel>
                                            </div>
                                        </div>
                                    </TabPanel>
                                ))}
                            </div>
                        </Scrollbars>
                    </div>
                </div>
            </section>

        )
    }
}

export default DetailsFormTabs;