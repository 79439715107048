import * as InputFieldValidation from './field-validators/input-field-validation';
import * as CommonFieldValidation from './field-validators/common-field-validation';

export const ErrorMethod = {
    "v_required": CommonFieldValidation.requiredValidation,
    "v_number_only": InputFieldValidation.numberValidation,
    "v_size_limit": InputFieldValidation.sizeValidation,
    "v_email": InputFieldValidation.emailValidation,
    "v_time": InputFieldValidation.timeValidation,
    "v_condition": CommonFieldValidation.conditionValidation,
    "v_valid_phone_number": InputFieldValidation.phoneNumberValidation,
}

export function updateErrorMessage(value, field_validations, condition, errorMessage, size, userDataRecord, props) {
    let errors = [];
    if (field_validations) {
        field_validations.map(validation => {
            let validationFunct = ErrorMethod[validation];
            errors.push(validationFunct(value, size));
            return validationFunct;
        })
    }
    if (condition) {
        console.log("condition567", condition);
        condition = conditionCheckForContains(condition);

        let validationFunct = ErrorMethod["v_condition"];
        errors.push(validationFunct(value, condition, errorMessage, userDataRecord, props));
    }
    return errors;
}

function conditionCheckForContains(condition) {
    if (condition.includes("contains")) {
        condition = condition.replace(/contains/g, "includes");
    }
    return condition;
}

export function checkForBulkUploadData(value, field_validations, key, checkIfValueExist) {
    let error = updateErrorMessage(value, field_validations);
    let result = error[0] ? error[0].isValid : true
    if (checkIfValueExist) {
        switch (key) {
            case "Agent":
                return checkIfValueExist.includes(value.toLowerCase())
            case "Status":
                return checkIfValueExist.includes(value.toUpperCase())
            default:
        }
    }
    return result
}