import React, { Component } from 'react';
import Fab from '@material-ui/core/Fab';
import Drawer from '@material-ui/core/Drawer';
import closeIcon from '../../assets/images/icon/close.svg';
import backArrowIcon from '../../assets/images/icon/arrow-back-grey.svg';
import StarGreyIcon from '../../assets/images/icon/star-grey.svg';
import SearchField from '../fields/search';
import { Scrollbars } from 'react-custom-scrollbars';
import { icons } from '../../utilities/constants.jsx';

class WorkflowclientDrawer extends Component {
    state = {
        customStyleSearch: 'only-small-search',
        searchData: {},
        searchedData: "",
        tempSearchData: {},
    }
    searchBtnClick() {
        this.setState({ customStyleSearch: "" });
    }

    searchValue(value) {
        let { tempSearchData } = this.state;
        let { navigationData } = this.props;

        tempSearchData = JSON.parse(JSON.stringify(navigationData));

        let sections = tempSearchData.sections.filter(section => {
            return (
                section.section_name.includes(value))
        }
        )
        let forms = tempSearchData.forms.filter(form => {
            return (
                form.form_name.includes(value))
        }
        )
        let workflows = tempSearchData.workflows.filter(workflow => {
            return (
                workflow.workflow_name.includes(value))
        }
        )

        tempSearchData["sections"] = sections;
        tempSearchData["forms"] = forms;
        tempSearchData["workflows"] = workflows;
        this.setState({ tempSearchData: tempSearchData, searchedData: value });


    }

    redirectLink = (key) => event => {

        window.location = '/' + key + "/program-client-dashboard";
    }

    handleBackArrowChange() {
        let { dashboardBackKey, insideBlock, programKey, userType } = this.props;
        if (dashboardBackKey === "dashboard") {
            window.location = "/create-app";

        }
        else if (dashboardBackKey === "workspace") {
            if (insideBlock === "section") {
                window.location = '/' + this.props.clientId + "/" + programKey + "/section-dashboard";
            }
            if (insideBlock === "workflow") {
                window.location = '/' + this.props.clientId + "/" + programKey + "/workflows-dashboard";
            }
            if (insideBlock === "detailsView") {
                if (userType === 0) {
                    window.location = '/' + this.props.clientId + "/" + programKey + "/program-dashboard";
                } else {
                    window.location = "/create-app";
                }

            }

        }

    }
    render() {

        let { navigationData, programs } = this.props;
        let { customStyleSearch, searchedData, tempSearchData } = this.state;
        if (!searchedData) {
            tempSearchData = navigationData;
        }

        return (
            <div>
                <Drawer anchor="left" open={this.props.clientListDrawer} onClose={this.props.toggleDrawer('clientListDrawer', false)}>
                    <section className="workflow-setup-sec program-setup-sec">
                        <section className="setup-drawer-sec program-drawer-sec">
                            <div className="card-block">
                                <div className="card-header">
                                    <div className="card-subheader">
                                        <div className="card-title" style={{ width: "calc(100% - 95px)" }}>
                                            <h5>My Clients</h5>
                                        </div>
                                        <div className="card-left-title">
                                            <div className="actions setup-close">
                                                <div className="btn-wrap">
                                                    <Fab variant="extended" size="small" className="btn btn-small btn-plane btn-fab white-color no-shadow" onClick={this.props.toggleDrawer('clientListDrawer', false)}>
                                                        <img alt="edit" src={closeIcon} style={{ margin: 0 }}></img>
                                                    </Fab>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div className="tabs-container">
                                        <Scrollbars style={{ width: '100%', height: '100%' }}>
                                            <div className="drawer-sec">
                                                <div className="workflow-program-list">
                                                    <ul>
                                                        {this.props.clientData ?
                                                            this.props.clientData.map(client => {
                                                                return (

                                                                    <li className="active" onClick={this.redirectLink(client.id).bind(this)} >
                                                                        <img alt="StarGreyIcon" src={StarGreyIcon}></img> {client.name}
                                                                    </li>

                                                                )
                                                            }) : ""
                                                        }
                                                    </ul>
                                                </div>
                                            </div>
                                        </Scrollbars>
                                    </div>
                                </div>
                                {/* onClick={this.props.toggleDrawer('programFilterDrawer', false)} */}
                                <div className="card-footer program-verison-ctrl">
                                    <h6 className="font-small">V1.1</h6>
                                    <div className="btn-wrap">
                                        <Fab variant="extended" size="large" className="btn btn-large btn-plane grey-color no-shadow" onClick={this.handleBackArrowChange.bind(this)}>
                                            <img alt="edit" src={backArrowIcon} className="MuiSvgIcon"></img>
                                            <span className="back-to">{this.props.dashboardBackKey === "dashboard" ? "Dashboard" : (this.props.userType === 1 ? "Dashboard" : "Workspace")}</span>
                                            {/* <img alt="edit" src={closeIcon}></img> Workspace */}
                                        </Fab>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </section>
                </Drawer>
                {/* <Drawer anchor="left" open={this.props.programFilterDrawer} onClose={this.props.toggleDrawer('programFilterDrawer', false)}>
                    <section className="workflow-setup-sec program-setup-sec">
                        <section className="setup-drawer-sec">
                            <div className="card-block">
                                <div className="card-header">
                                    <div className="card-subheader">
                                        {navigationData  && 
                                        <div className="card-title">
                                            <h4>{this.props.programName || navigationData.program_name}</h4>
                                        </div>
                                        }
                                        <div className="card-left-title">
                                            <div className="workflow-tabs-wrap">
                                                <div className="workflow-tabs-search">
                                                    <SearchField placeholder="Search here" searchType="normal" customStyle={customStyleSearch} searchBtnClick={this.searchBtnClick.bind(this)}  handleOnChangeValue={(event) => this.searchValue(event.target.value)}  />
                                                </div>
                                            </div>
                                            <div className="actions setup-close">
                                                <div className="btn-wrap">
                                                    <Fab variant="extended" size="small" className="btn btn-small btn-plane btn-fab white-color no-shadow" onClick={this.props.toggleDrawer('programFilterDrawer', false)}>
                                                        <img alt="edit" src={closeIcon} style={{ margin: 0 }}></img>
                                                    </Fab>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div className="tabs-container-scroll">
                                        <Scrollbars style={{ width: '100%', height: '100%' }}>
                                            <div className="drawer-sec">
                                                <div className="pragram-filter-table">
                                                    <table>
                                                        <thead>
                                                            <tr>
                                                                <th width="33%"><h5>Section</h5></th>
                                                                <th width="33%"><h5>Forms</h5></th>
                                                                <th width="33%"><h5>Flow</h5></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>
                                                                    {
                                                                        tempSearchData && tempSearchData.hasOwnProperty("sections") ?
                                                                        tempSearchData.sections.map(data => {
                                                                            return (
                                                                                <ul className="workflow-program-list">
                                                                                    <li className="with-filter" onClick={this.redirectLink("section", data.section_key, false).bind(this)} >
                                                                                        <img alt="StarGreyIcon" src={data.section_icon == null ? icons.i_company.icon : icons[data.section_icon].icon }></img>
                                                                                        <span>{data.section_name}</span> */}
                {/* <img alt="FilterBlueIcon" src={FilterBlueIcon} className="filter-btn"></img> */}
                {/* </li>
                                                                                </ul>
                                                                            )
                                                                        }) : ""
                                                                    }
                                                                </td>
                                                                <td> */}
                {/* <dl>
                                                                        <dt><span class="state-tag musturd-color">Filtered by Section</span></dt>
                                                                        <dt><Chip
                                                                            size="small"
                                                                            label="Section"
                                                                            className="chips secondary-color"
                                                                            deleteIcon={<CancelIcon />}
                                                                            onDelete={handleDelete}
                                                                            color="primary"
                                                                        /></dt>
                                                                    </dl> */}
                {/* {tempSearchData && tempSearchData.hasOwnProperty("forms") ?
                                                                        tempSearchData.forms.map(data => {
                                                                            return (
                                                                                <ul className="workflow-program-list">
                                                                                    <li onClick={this.redirectLink("form", data.form_key, false).bind(this)} > */}
                {/* <img alt="StarGreyIcon" src={StarGreyIcon}></img>  */}
                {/* {data.form_name}
                                                                                    </li>
                                                                                </ul>
                                                                            )
                                                                        }) : ""
                                                                    }
                                                                </td>
                                                                <td>
                                                                    {tempSearchData && tempSearchData.hasOwnProperty("workflows") ?
                                                                        tempSearchData.workflows.map(data => {
                                                                            return (
                                                                                <ul className="workflow-program-list">
                                                                                    <li  onClick={this.redirectLink("workflow", data.workflow_key, false).bind(this)}  > */}
                {/* <img alt="StarGreyIcon" src={StarGreyIcon}></img>  */}
                {/* {data.workflow_name}
                                                                                    </li>
                                                                                </ul>
                                                                            )
                                                                        }) : ""
                                                                    }
                                                                </td>
                                                            </tr> */}
                {/* {
                                                                [1,2,3,4,5,6].map(fields => {
                                                                return (
                                                                        <tr>
                                                                            <td>
                                                                                <ul className="workflow-program-list with-filter">
                                                                                    <li>
                                                                                        <img alt="StarGreyIcon" src={StarGreyIcon}></img> 
                                                                                        <span>Program 5</span>
                                                                                        <img alt="FilterBlueIcon" src={FilterBlueIcon} className="filter-btn"></img> 
                                                                                    </li>
                                                                                </ul>
                                                                            </td>
                                                                            <td>
                                                                                <ul className="workflow-program-list">
                                                                                    <li>
                                                                                        <img alt="StarGreyIcon" src={StarGreyIcon}></img> Forms
                                                                                    </li>
                                                                                </ul>
                                                                            </td>
                                                                            <td>
                                                                                <ul className="workflow-program-list">
                                                                                    <li>
                                                                                        <img alt="StarGreyIcon" src={StarGreyIcon}></img> Flow
                                                                                    </li>
                                                                                </ul>
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                })
                                                            }    */}
                {/* 
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </Scrollbars>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </section>
                </Drawer> */}
            </div>

        );
    }
}

WorkflowclientDrawer.defaultProps = {
    userType: 0,
}
export default WorkflowclientDrawer;