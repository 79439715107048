import React, { Component } from 'react';

import Dialog from '@material-ui/core/Dialog';
import Fab from '@material-ui/core/Fab';
import CloseIcon from '../../assets/images/icon/close.svg';
import Grid from '@material-ui/core/Grid';
import { Scrollbars } from 'react-custom-scrollbars';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import SearchField from '../fields/search';

//Custom
import TextAreaField from '../fields/textarea';

class FormulaComponent extends Component {

    handleClickOpen = event => {
        this.props.handleDialogEvent(true);
        this.setState({ anchorEl: null });
    };

    handleClose = event => {
        this.props.handleDialogEvent(false);
    };

    handleFieldSelection = (fieldId) => (event) => {
        let { formula_text } = this.props;
        formula_text += (fieldId);
        this.props.formulaTextChangeEvent(formula_text, null);
    }

    displayFields() {
        let { formula_text, fields } = this.props;
        let updatedFormula = JSON.parse(JSON.stringify(formula_text));
        fields.forEach(field => {
            updatedFormula = updatedFormula.split(field.id).join(field.label.split(" ").join("_"));
        })
        return updatedFormula;
    }

    render() {
        let { fields, formula_text, formulaTextChangeEvent } = this.props;
        return (
            <Dialog
                open={this.props.openFormulaDialog}
                onClose={this.handleClose.bind(this)}
                aria-labelledby="responsive-dialog-title"
                maxWidth="md"
            >
                <section className="workflow-dialog workflow-dialog-small workflow-dialog-pri">
                    <div className="card-block">
                        <div className="card-header border-bottom p-b-25">
                            <div className="sub-title">
                                <h2 className="bold">Formula</h2>
                            </div>
                            <div className="close-dialog">
                                <img src={CloseIcon} alt="CloseIcon" onClick={this.handleClose.bind(this)}></img>
                            </div>
                        </div>
                        <div className="card-body p-b-0">
                            <div className="workflow-dialog-forms formula-dialog">
                                <Grid container spacing={24}>
                                    <Grid item sm={4}>
                                        <div className="card-block">
                                            <SearchField placeholder="Search" />
                                            <div className="tabs-container tabs-container-scroll">
                                                <Scrollbars style={{ width: '100%', height: '100%' }}>
                                                    <div className="tabs-container-scroll-wrap">
                                                        <List dense component="div" role="list">
                                                            {fields.map(field =>
                                                            (
                                                                <ListItem key={field.id} role="listitem" button onClick={this.handleFieldSelection(field.field_key).bind(this)}>
                                                                    <ListItemText id={field.id} primary={field.label} />
                                                                </ListItem>
                                                            )
                                                            )}
                                                        </List>
                                                    </div>
                                                </Scrollbars>
                                            </div>
                                        </div>
                                    </Grid>
                                    <Grid item sm={8}>
                                        <TextAreaField key={"formula"} withLabel={false} name="formula" rowmax="15" selected_value={formula_text} handleOnChangeValue={formulaTextChangeEvent} fieldStyle="field-box bg-plane grey-color" />
                                    </Grid>
                                </Grid>
                                <div className="form-row workflow-dialog-footer p-l-0 p-r-0">
                                    <div className="btn-wrap no-border">
                                        <Fab variant="extended" size="large" className="btn btn-large btn-plane primary-color" onClick={this.handleClose.bind(this)}>
                                            Save
                                        </Fab>
                                        <Fab variant="extended" size="large" className="btn btn-large btn-plane grey-color" onClick={this.handleClose.bind(this)}>
                                            Close
                                        </Fab>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </Dialog>
        );
    }
}

export default FormulaComponent;