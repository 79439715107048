import React, { Component } from 'react';
import { DropTarget } from 'react-dnd'
import FormFields from './formFields';
import DashBoardIllus from '../../assets/images/illustration/dashboard-builder-circle.svg';
import _ from 'lodash';

//Constants
import * as FormCommon from '../../utilities/form-common';
import * as FormConstants from '../../utilities/constants';

/** Detect the source get drop at target area */
function collect(connect, monitor) {
    return {
        connectDropTarget: connect.dropTarget(),
        hovered: monitor.isOver(),
        isOverCurrent: monitor.isOver({ shallow: true }),
        item: monitor.getItem()
    }
}

function checkGroupId(fields) {
    if (fields.length > 0) {
        fields.forEach((field, index) => {
            if (field.group_field) {
                if (!field.hasOwnProperty('children')) {
                    field["children"] = fields.filter(child => child.parent_id === field.id)
                }
            }
        })

        //Deleting the child fields using Lodash.
        _.remove(fields, function (field) {
            return field.parent_id !== null;
        });

        //This for loop is used to update the field Labels and set the options data for the dropdown fields.
        fields.forEach(field => {

            //Setting the Labels
            if (field.field_type === FormConstants.FieldIds.F_NAME.code || field.field_type === FormConstants.FieldIds.F_ADDRESS.code) {
                if (field.hasOwnProperty('children')) {
                    for (let i = 0; i < field.children.length; i++) {
                        if (field.field_type === FormConstants.FieldIds.F_NAME.code) {
                            field.children[i].label = field.display_name[i].field_option_label;
                        }
                        if (field.field_type === FormConstants.FieldIds.F_ADDRESS.code) {
                            field.children[i].label = field.display_name[i].field_option_label;
                        }
                    }
                }
            }

            //Setting the OptionsData for Dropdown Fields
            if (field.field_type === FormConstants.FieldIds.F_NAME.code) {
                field.children[0].field_option_list = field.prefix_name;
            }
            // if(field.field_type === FormConstants.FieldIds.F_ADDRESS.code)
            // {
            //     field.children[5].field_option_list = CountryData.COUNTRY_CODES; 
            // }

        })

    }
    return fields;
}


class FormCanvas extends Component {

    // fieldSelection(e, id){
    //     this.props.fieldSelection(e, id)
    // }

    render() {
        const {
            connectDropTarget,
            hovered,
            moveField,
            fields,
            fieldSelection,
            selectedField,
            fieldToDelete,
            fieldDuplicate,
            deleteFieldLoader,
            addFieldLoader,
            deleteFieldSuccess } = this.props;

        checkGroupId(fields);

        const dropColor = hovered ? 'rgba(0, 100, 161, 0.07)' : '#ffffff';
        let fieldsData = fields.map((field, i) => {
            return (
                <FormFields
                    key={field.id}
                    index={i}
                    id={field.id}
                    fieldComponent={FormCommon.fieldGenerate(field.field_type, field)}
                    moveFieldCard={moveField}
                    fieldSelected={e => { fieldSelection(e, i) }}
                    selectedField={selectedField}
                    fieldDelete={fieldToDelete(i)}
                    fieldDuplicate={fieldDuplicate(i)}
                    deleteLoader={deleteFieldLoader}
                    deleteSuccess={deleteFieldSuccess}
                    isDefault={field.default}
                />
            )
        })
        return (
            connectDropTarget(
                <div>
                    {fieldsData}
                    <div className="target-wrap">
                        {/* {item ? item.name : "Nothing"} */}

                        <div className="target-drop" style={{ background: dropColor }}>
                            <div className="drop-field">
                                <h6>
                                    {addFieldLoader ? "Adding" : "Drag And Drop for adding Additional Criteria"}
                                </h6>
                            </div>
                        </div>
                    </div>
                    {fieldsData.length <= 0 && (
                        <section className="blank-page-sec" style={{ marginTop: '85px' }}>
                            <div className="blank-page-wrap">
                                <div className="blank-page-icon">
                                    <img src={DashBoardIllus} alt="DashBoardIllus" />
                                </div>
                                <div className="blank-page-info">
                                    <h5>You haven't added any fields yet!</h5>
                                </div>
                            </div>
                        </section>
                    )}
                </div>
            )
        )
    }
}


export default DropTarget('FormToolbox', {}, collect)(FormCanvas);