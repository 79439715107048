import React from 'react'
import PropTypes from 'prop-types';

import ButtonField from '../../components/fields/button';

import { checkIfPdfIsDownloadable, downloadPolicyPdf } from '../../api/integrations-api';

/**
 * Integrations section displayed at the details view page. Currently only displays the
 * "Download PDF" integration if it is allowed by the API.
 */
export default class IntegrationsView extends React.Component {
    state = {
        // Controls if the Download PDF button is showed.
        showPdfDownload: false,
        // Controls if the error message is displayed.
        error: false,
        errorMessage: '',
    }

    componentDidMount() {
        // Check if the download PDF integration is added to the program. Only if it is added, we'll
        // show the "Download PDF" button.
        checkIfPdfIsDownloadable(this.props.processId)
            .then(()=> {
                this.setState({ showPdfDownload: true });
            })
            .catch(() => {
                this.setState({ showPdfDownload: false });
            });
    }

    /**
     * Event handler for a click event on the "Download PDF" button.
     */
    handleDownloadPdfClick() {
        downloadPolicyPdf(this.props.processId)
            .then((response) => {
                const blob = new Blob([response], { type: 'application/pdf' });
                const url = URL.createObjectURL(blob);
                window.open(url);
            })
            .catch(() => {
                this.setState({
                    error: true,
                    errorMessage: 'Error while fetching PDF. PDF could not be found.'
                });
            });
    }

    render() {
        const { error, errorMessage, showPdfDownload } = this.state;

        if (!showPdfDownload) {
            return (
                <h6>No integrations available</h6>
            );
        }

        return (
            <>
                <ButtonField
                    name="Download PDF"
                    handleClick={this.handleDownloadPdfClick.bind(this)}
                />
                {error && (
                    <h6 className="red-color">{errorMessage}</h6>
                )}
            </>
        );
    }
}

IntegrationsView.propTypes = {
    processId: PropTypes.number.isRequired,
};
