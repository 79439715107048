import React, { Component } from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import Description from '../validators/descriptions';
import MandatoryValidator from '../validators/mandatory-validator';

class AddressField extends Component {

    modifyChildFields(display_name, childrenAttributes) {

        //Selecting and De-selecting of Child Fields.
        for (let i = 0; i < display_name.length; i++) {
            if (!(display_name[i].checked)) {
                childrenAttributes[i] = null;
            }
        }
        return childrenAttributes;
    }


    render() {
        const { label, instruction, is_mandatory, fieldStyle, customStyle, childrenAttributes, display_name } = this.props;
        let defaultFieldStyle = "field-box";

        let displayChildAttributes = this.modifyChildFields(display_name, childrenAttributes);
        return (
            <div className={"form-group-field " + customStyle}>
                <div className={"form-row form-addres-field " + customStyle}>
                    <div className={fieldStyle ? fieldStyle : defaultFieldStyle}>
                        {/* <InputLabel 
                            shrink 
                            className="label">
                            {label}
                            <MandatoryValidator value={is_mandatory}/>
                        </InputLabel> */}
                        <div className="form-addres-row">
                            {displayChildAttributes.map(child => {
                                return child;
                            })
                            }
                        </div>
                    </div>
                    <div className="form-row-instruction">
                        <h6 className="font-med"></h6>
                    </div>
                </div>

                <div className={"form-row " + customStyle}>
                    <Description value={instruction} />
                </div>
            </div>
        )
    }
}

export default AddressField;