import * as AjaxHelper from "../utilities/ajax-helper"
import { ApiConstants } from "./common/api-constants"

let apiBaseUrl = () => (ApiConstants.DEV_MODE === 'true' ? ApiConstants.HOST : window.location.origin) + ApiConstants.API_VERSION;

export function createConfig(configData, clientId, programKey) {
    return AjaxHelper.post(apiBaseUrl() + '/' + clientId + '/' + programKey + "/config/motor", configData, { "Content-Type": "application/json" });
}

export function getConfig(clientId, programKey) {
    return AjaxHelper.get(apiBaseUrl() + '/' + clientId + '/' + programKey + "/config/motor", null, { "Content-Type": "application/json" });
}

export function getAllInsurerKit(clientId, programKey) {
    return AjaxHelper.get(apiBaseUrl() + '/' + clientId + '/' + programKey + "/config/motor/insurer-kits", null, { "Content-Type": "application/json" });
}

export function addInsurer(clientId, programKey, insurerData) {
    return AjaxHelper.post(apiBaseUrl() + "/" + clientId + "/" + programKey + ApiConstants.CONFIGURATION + ApiConstants.MOTOR_CONFIGURATION + ApiConstants.ADD_INSURER, insurerData, { "Content-Type": "application/json" });
}

export function getInsurerDetailsByProgramKeyAndObjectId(clientId, programKey, objectId) {
    return AjaxHelper.get(apiBaseUrl() + "/" + clientId + "/" + programKey + ApiConstants.CONFIGURATION + ApiConstants.MOTOR_CONFIGURATION + '/' + objectId + ApiConstants.INSURER_DETAILS, null, { "Content-Type": "application/json" });
}

export function getInsurersKitByMotorConfigId(clientId, programKey, motorConfigId) {
    return AjaxHelper.get(apiBaseUrl() + "/" + clientId + "/" + programKey + ApiConstants.CONFIGURATION + ApiConstants.MOTOR_CONFIGURATION + ApiConstants.INSURER_KIT + '/' + motorConfigId, null, { "Content-Type": "application/json" });
}

export function updateConfig(configData, clientId, programKey, motorConfigId) {
    return AjaxHelper.put(apiBaseUrl() + '/' + clientId + '/' + programKey + "/config/motor/" + motorConfigId, configData, { "Content-Type": "application/json" });
}

export function deleteMotorConfig(clientId, programKey, motorConfigId) {
    return AjaxHelper.del(apiBaseUrl() + '/' + clientId + '/' + programKey + "/config/motor/" + motorConfigId, null, { "Content-Type": "application/json" });
    // return AjaxHelper.del(apiBaseUrl()+ApiConstants.WORKFLOW_ACTION+"/"+triggerId, null, {"Content-Type":"application/json"});
}

export function getApiDetails(clientId, programKey, motorConfigId) {
    return AjaxHelper.get(apiBaseUrl() + "/" + clientId + "/" + programKey + "/config/motor/" + motorConfigId + "/apiDetails", null, { "Content-Type": "application/json" });
}

export function saveApiDetails(clientId, programKey, motorConfigId, request) {
    return AjaxHelper.post(apiBaseUrl() + "/" + clientId + "/" + programKey + "/config/motor/" + motorConfigId + "/apiDetails", request, { "Content-Type": "application/json" });
}

export function getNewApiDetails(clientId) {
    return AjaxHelper.get(apiBaseUrl() + "/clients/" + clientId + "/apiDetails", null, { "Content-Type": "application/json" });
}

export function saveNewApiDetails(clientId, request) {
    return AjaxHelper.post(apiBaseUrl() + "/clients/" + clientId + "/apiDetails", request, { "Content-Type": "application/json" });
}
