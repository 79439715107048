import React, { Component } from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import Description from '../validators/descriptions';
import MandatoryValidator from '../validators/mandatory-validator';

class NameField extends Component {

    handleSelectChangeEvent = (event) => (value) => {
        let { prefix_name } = this.props;

        prefix_name.forEach(name => {
            name.checked = (name.field_option_value === value)
        })
    }

    modifyChildFields(display_name, prefix_name, childrenAttributes) {

        //Selecting and De-selecting of Child Fields.
        for (let i = 0; i < display_name.length; i++) {
            if (!(display_name[i].checked)) {
                childrenAttributes[i] = null;
            }
        }
        return childrenAttributes;
    }


    render() {
        /** 
         * firstName: for label and id for name input
         * firstLabel: label text for name input
         * lastName: for label and id last input
         * lastLabel: label text for last input
         * type: defined type for input
         * <NameField firstName="first_name" firstLabel="Name" lastName="last_name" lastLabel=""/>
         * */
        let { label, instruction, is_mandatory, fieldStyle, customStyle } = this.props;
        // const defaulFirstLabel = "Name"
        let defaultFieldStyle = "field-box";
        // let isDisabled = this.props.is_disable === 'yes'? true: false;
        let { display_name, prefix_name, childrenAttributes } = this.props;
        let displayChildAttributes = this.modifyChildFields(display_name, prefix_name, childrenAttributes);
        console.log("displayChildAttributes", displayChildAttributes);
        return (
            <div className={"form-group-field " + customStyle}>
                <div className={"form-row form-name-field " + customStyle}>
                    <div className={fieldStyle ? fieldStyle : defaultFieldStyle}>
                        {/* <InputLabel 
                            shrink 
                            className="label">
                            {label}
                            <MandatoryValidator value={is_mandatory}/>
                        </InputLabel> */}
                        <div className="form-name-row">
                            {displayChildAttributes.map(child => {
                                return child;
                            })
                            }
                        </div>
                    </div>
                    {/* <div className="form-row-instruction">
                        <h6 className="font-med"></h6>
                    </div> */}
                    <Description value={instruction} />
                </div>
            </div>
        )
    }
}

export default NameField;