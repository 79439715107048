import React, { Component } from 'react';
/* import SettingFlowIllust from '../../assets/images/illustration/login-iso.svg'; */
import Header from '../../components/header';
import ProfileIcon from '../../assets/images/icon/single-profile.svg';
import ArrowIcon from '../../assets/images/icon/chevron-blue.svg';
import KeyBlueIcon from '../../assets/images/icon/key-blue.svg';
import Fab from '@material-ui/core/Fab';
import SettingProfile from '../../components/settings/setting-profile';
import SettingPassword from '../../components/settings/setting-password';
import * as UserApi from '../../api/user-api.jsx';
import * as StorageFileApi from '../../api/file-storage-api';


class SettingProfileScene extends Component {
    state = {
        active: false,
        buttonState: false,
        changeSetting: 'profile',
        userDetails: {},
    }

    componentWillMount() {
        UserApi.getLoggedInUser().then(user => {
            this.setState({ userDetails: user });
        })
    }

    updateUserDetails(userData) {
        this.setState({ userDetails: userData });
    }

    toggleTimeline() {
        this.setState({ active: !this.state.active });
    }
    settingChange(val) {
        this.setState({ changeSetting: val });
        console.log(this.state.changeSetting);
    }

    render() {
        let { buttonState, active, changeSetting } = this.state;
        let toggleTimeline = active ? "toggle-timeline" : "";
        let ToggleBtnArrow = buttonState ? "toggle-arrow" : "";
        const match = this.props.match;
        let programkey = match.params.programkey;
        return (
            <div className="SettingBuilderScene">
                <Header headerType="dashboard" positionType="fixed" programKey={programkey} />
                <main>
                    <div className="workflow-fields-sec program-build-sec create-app-sec level-1-header">
                        <div className="program-build-wrap">
                            <div className={"program-card-wrap program-build-timeline " + toggleTimeline}>
                                <div className="card-block">
                                    <div className="card-body">
                                        <section className="create-app-timeline">
                                            <div className="cretae-timeline-wrap">
                                                <div className={"program-timeline-btn " + (changeSetting === 'profile' ? 'active' : '')}>
                                                    <Fab variant="extended" size="small" className="btn btn-small no-shadow btn-icon" onClick={() => this.settingChange('profile')}>
                                                        <img src={ProfileIcon} alt="ProfileIcon" />
                                                        {
                                                            !toggleTimeline && (
                                                                <span>Profile</span>
                                                            )
                                                        }
                                                    </Fab>
                                                </div>
                                            </div>
                                            {/* <div className="cretae-timeline-wrap">
                                                <div className= {"program-timeline-btn " + (changeSetting === 'password' ? 'active' : '') }>
                                                    <Fab variant="extended" size="small" className="btn btn-small no-shadow btn-icon" onClick={()=> this.settingChange('password')}>
                                                        <img src={KeyBlueIcon} alt="KeyBlueIcon"/> 
                                                        {
                                                            !toggleTimeline && (
                                                                <span>Change Password</span>
                                                            )
                                                        }
                                                    </Fab>
                                                </div>
                                            </div> */}
                                            <div className={"program-timeline-btn program-timeline-toggle " + ToggleBtnArrow} onClick={this.toggleTimeline.bind(this)} >
                                                <Fab variant="extended" size="small" className="btn btn-small no-shadow btn-toggle">
                                                    <img src={ArrowIcon} alt="ArrowIcon" />
                                                </Fab>
                                            </div>
                                        </section>
                                    </div>
                                </div>
                            </div>
                            <div className={"program-card-wrap program-build-form profile-setting-sec " + toggleTimeline}>
                                <div className="card-block">
                                    <div className="card-body">
                                        {
                                            (changeSetting === 'profile') && (
                                                <SettingProfile
                                                    userDetails={this.state.userDetails}
                                                    updateUserDetails={this.updateUserDetails.bind(this)}
                                                />
                                            )
                                        }
                                        {/* {
                                            (changeSetting === 'password') && (
                                                <SettingPassword/>
                                            )
                                        } */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        )
    }
}

export default SettingProfileScene;