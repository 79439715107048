import React, { Component } from 'react';
import Fab from '@material-ui/core/Fab';

import Dialog from '@material-ui/core/Dialog';
import CloseIcon from '../../assets/images/icon/close.svg';

class SwitchTabActions extends Component {
    state = {
        open: false
    }

    handleClose() {
        this.setState({ open: false });
    }

    handleOpen() {
        this.setState({ open: true });
    }

    handleSave() {
        this.handleClose();
        this.props.handleStatusChange();
    }

    render() {
        let { status, handleStatusChange } = this.props;
        let { open } = this.state;
        let statusText = "";
        if (status) {
            statusText = status.charAt(0).toUpperCase() + status.slice(1);
        }
        return (
            <section className="switch-tab-sec">
                <div className="switch-tab-wrap">
                    <div className={"switch-block mar-auto-none " + status} onClick={(status === "disabled") ? handleStatusChange : this.handleOpen.bind(this)}>
                        <span className="switch-lab">{statusText}</span>
                        <Fab variant="extended" size="small" className="btn btn-small btn-plane btn-fab grey-color indicator"></Fab>
                    </div>
                </div>

                <Dialog open={open} onClose={this.handleClose.bind(this)} aria-labelledby="simple-dialog-title">
                    <section className="workflow-dialog workflow-dialog-small workflow-dialog-pri">
                        <div className="card-block">
                            <div className="card-header p-b-15">
                                <div className="sub-title">
                                    <h4 className="semi-bold">Confirm Status</h4>
                                </div>
                                <div className="close-dialog">
                                    <img src={CloseIcon} alt="close" onClick={this.handleClose.bind(this)}></img>
                                </div>
                            </div>
                            <div className="card-body p-t-15 p-b-45">
                                <div className="sub-title">
                                    <h5 className="weight-300 grey-color">Are you sure you want to disable and suspend all activities in this workflow?</h5>
                                </div>
                            </div>
                            <div className="card-footer border-top p-0 p-t-25">
                                <div className="btn-wrap">
                                    <Fab variant="extended" size="large" className="btn btn-large btn-plane primary-color" onClick={this.handleSave.bind(this)}>
                                        Save
                                    </Fab>
                                    <Fab variant="extended" size="large" className="btn btn-large btn-plane grey-color" onClick={this.handleClose.bind(this)}>
                                        Cancel
                                    </Fab>
                                </div>
                            </div>
                        </div>
                    </section>
                </Dialog>
            </section>
        );
    }
}

export default SwitchTabActions;

