import React, { Component } from 'react';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputLabel from '@material-ui/core/InputLabel';
import Description from '../validators/descriptions';
import MandatoryValidator from '../validators/mandatory-validator';
import InputField from '../fields/inputs';
//Validation
import * as ErrorValidation from '../validators/error-validator';
//Custom 
import ErrorMessage from '../common/error-message';

class RadioField extends Component {

    constructor(props) {
        super(props)
        this.state = {
            otherInputValue: false,
            validations: [],
            triggerInput: false,
            errorStates: [],
            otherLabelValue: null,
        }
    }

    handleOnChangeEvent = event => {
        let radioData = this.props;
        var validations = this.state.validations;
        let objTrue = { isValid: true };
        let objFalse = { isValid: false };
        //let errors = [];
        let errors = ErrorValidation.updateErrorMessage(event.target.value, this.props.field_validations, this.props.condition_script, this.props.error_message);
        if (radioData.hasOwnProperty('field_validations')) {
            if (radioData.field_validations.includes("v_required")) {
                //errors.push(objFalse);
                if (event.target.value === 'other') {
                    if (!validations.includes("v_required")) {
                        validations = validations.concat("v_required");
                        errors.push(objFalse);
                    }
                    this.setState({ otherInputValue: true });
                }
                else {
                    //errors[0].isValid = true; 
                    if (validations.includes("v_required")) {
                        validations = [];
                    }
                    this.setState({ otherInputValue: false })
                    this.setState({ triggerInput: !this.state.triggerInput });
                }
            }
            else {
                //errors.push(objTrue);
                if (event.target.value === 'other') {
                    if (!validations.includes("v_required")) {
                        validations = validations.concat("v_required");
                        errors.push(objFalse);
                    }
                    this.setState({ otherInputValue: true });
                }
                else {
                    //errors[0].isValid = true; 
                    if (validations.includes("v_required")) {
                        validations = [];
                    }
                    this.setState({ otherInputValue: false })
                    this.setState({ triggerInput: !this.state.triggerInput });
                }
            }

            this.setState({ validations: validations, errorStates: errors, otherLabelValue: "" });
        }
        this.props.radioChangeHandle(event.target.value, errors);
    }

    getRadioData() {
        let radioData = this.props.radioData;
        let updatedRadioData = radioData.filter(radio => {
            return !radio.option_type || radio.option_type === 0;
        })

        return updatedRadioData;
    }

    getOthersOption() {
        let radioData = this.props.radioData;
        let otherOption = radioData.filter(radio => {
            return radio.option_type === 1;
        })
        return otherOption;
    }

    inputOtherChangeHandle(value, errors) {
        this.setState({ otherLabelValue: value }, () => {
            this.props.radioChangeHandle(value, errors)
        })

    }
    render() {
        let { default_value, label, radioGroupId, instruction, value, is_mandatory, defaultLabel, fieldStyle, radioFieldStyle, customStyle, selected_value, field_option_list } = this.props;
        let { errorStates } = this.state;
        let defaultFieldStyle = "field-box";
        let defaultRadioFieldStyle = ""
        console.log("errorStates666", errorStates);
        let updatedRadioData = this.getRadioData();
        let otherOption = this.getOthersOption();
        console.log("updatedRadioData", updatedRadioData);
        let isDisabled = this.props.is_disable === 'yes' ? true : false;
        let radioContent = updatedRadioData.map((data, i) => {
            return (
                <FormControlLabel
                    key={i}
                    value={data.field_option_value}
                    disabled={isDisabled}
                    control={<Radio />}
                    label={data.field_option_label}
                />
            );
        })
        return (
            <div className={"form-group-field " + customStyle}>
                <div className={"form-row " + customStyle}>
                    <div className={fieldStyle ? fieldStyle : defaultFieldStyle}>
                        <InputLabel
                            htmlFor="radio"
                            className="label">
                            {label ? label : defaultLabel}
                            <MandatoryValidator value={is_mandatory} />
                        </InputLabel>
                        {console.log("selected_value Radio", selected_value, "asd", default_value)}
                        <RadioGroup
                            id={radioGroupId}
                            aria-label={label ? label : defaultLabel}
                            value={selected_value ? selected_value : (default_value ? default_value : "")}
                            onChange={this.handleOnChangeEvent}
                            className={radioFieldStyle ? radioFieldStyle : defaultRadioFieldStyle}
                        >
                            {radioContent}
                            {otherOption.length > 0 ?
                                <div className="other-options">
                                    <div className="choice-wrap">
                                        <div className="choice-row">
                                            <Radio
                                                value={"other"}
                                                disabled={isDisabled}
                                            />
                                            <InputField
                                                placeholder="Other"
                                                value={value}
                                                disabled={!this.state.otherInputValue}
                                                selected_value={this.state.otherLabelValue}
                                                handleOnChangeValue={(value, errors) => this.inputOtherChangeHandle(value, errors)}
                                                field_validations={this.state.validations}
                                                triggerValidCheck={this.state.triggerInput}
                                            />
                                        </div>
                                    </div>

                                </div>
                                : ""}
                        </RadioGroup>
                        <ErrorMessage errorStates={errorStates} />
                    </div>

                    <Description value={instruction} />
                </div>
            </div>

        )
    }
}

export default RadioField;