export const COUNTRY_CODES = [
    { code: "93", field_option_label: "Afghanistan", field_option_value: "AF", checked: false },
    { code: "355", field_option_label: "Albania", field_option_value: "AL", checked: false },
    { code: "213", field_option_label: "Algeria", field_option_value: "DZ", checked: false },
    { code: "1 684", field_option_label: "American Samoa", field_option_value: "AS", checked: false },
    { code: "376", field_option_label: "Andorra", field_option_value: "AD", checked: false },
    { code: "244", field_option_label: "Angola", field_option_value: "AO", checked: false },
    { code: "1 264", field_option_label: "Anguilla", field_option_value: "AI", checked: false },
    { code: "672", field_option_label: "Antarctica", field_option_value: "AQ", checked: false },
    { code: "1 268", field_option_label: "Antigua And Barbuda", field_option_value: "AG", checked: false },
    { code: "54", field_option_label: "Argentina", field_option_value: "AR", checked: false },
    { code: "374", field_option_label: "Armenia", field_option_value: "AM", checked: false },
    { code: "297", field_option_label: "Aruba", field_option_value: "AW", checked: false },
    { code: "247", field_option_label: "Ascension Island", field_option_value: "AC", checked: false },
    { code: "61", field_option_label: "Australia", field_option_value: "AU", checked: false },
    { code: "43", field_option_label: "Austria", field_option_value: "AT", checked: false },
    { code: "994", field_option_label: "Azerbaijan", field_option_value: "AZ", checked: false },
    { code: "1 242", field_option_label: "Bahamas", field_option_value: "BS", checked: false },
    { code: "973", field_option_label: "Bahrain", field_option_value: "BH", checked: false },
    { code: "880", field_option_label: "Bangladesh", field_option_value: "BD", checked: false },
    { code: "1 246", field_option_label: "Barbados", field_option_value: "BB", checked: false },
    { code: "375", field_option_label: "Belarus", field_option_value: "BY", checked: false },
    { code: "32", field_option_label: "Belgium", field_option_value: "BE", checked: false },
    { code: "501", field_option_label: "Belize", field_option_value: "BZ", checked: false },
    { code: "229", field_option_label: "Benin", field_option_value: "BJ", checked: false },
    { code: "1 441", field_option_label: "Bermuda", field_option_value: "BM", checked: false },
    { code: "975", field_option_label: "Bhutan", field_option_value: "BT", checked: false },
    { code: "591", field_option_label: "Bolivia, Plurinational State Of", field_option_value: "BO", checked: false },
    { code: "599", field_option_label: "Bonaire, Saint Eustatius And Saba", field_option_value: "BQ", checked: false },
    { code: "387", field_option_label: "Bosnia & Herzegovina", field_option_value: "BA", checked: false },
    { code: "267", field_option_label: "Botswana", field_option_value: "BW", checked: false },
    { code: "", field_option_label: "Bouvet Island", field_option_value: "BV", checked: false },
    { code: "55", field_option_label: "Brazil", field_option_value: "BR", checked: false },
    { code: "246", field_option_label: "British Indian Ocean Territory", field_option_value: "IO", checked: false },
    { code: "673", field_option_label: "Brunei Darussalam", field_option_value: "BN", checked: false },
    { code: "359", field_option_label: "Bulgaria", field_option_value: "BG", checked: false },
    { code: "226", field_option_label: "Burkina Faso", field_option_value: "BF", checked: false },
    { code: "257", field_option_label: "Burundi", field_option_value: "BI", checked: false },
    { code: "855", field_option_label: "Cambodia", field_option_value: "KH", checked: false },
    { code: "237", field_option_label: "Cameroon", field_option_value: "CM", checked: false },
    { code: "1", field_option_label: "Canada", field_option_value: "CA", checked: false },
    { code: "", field_option_label: "Canary Islands", field_option_value: "IC", checked: false },
    { code: "238", field_option_label: "Cape Verde", field_option_value: "CV", checked: false },
    { code: "1 345", field_option_label: "Cayman Islands", field_option_value: "KY", checked: false },
    { code: "236", field_option_label: "Central African Republic", field_option_value: "CF", checked: false },
    { code: "", field_option_label: "Ceuta, Mulilla", field_option_value: "EA", checked: false },
    { code: "235", field_option_label: "Chad", field_option_value: "TD", checked: false },
    { code: "56", field_option_label: "Chile", field_option_value: "CL", checked: false },
    { code: "86", field_option_label: "China", field_option_value: "CN", checked: false },
    { code: "61", field_option_label: "Christmas Island", field_option_value: "CX", checked: false },
    { code: "", field_option_label: "Clipperton Island", field_option_value: "CP", checked: false },
    { code: "61", field_option_label: "Cocos (Keeling) Islands", field_option_value: "CC", checked: false },
    { code: "57", field_option_label: "Colombia", field_option_value: "CO", checked: false },
    { code: "269", field_option_label: "Comoros", field_option_value: "KM", checked: false },
    { code: "682", field_option_label: "Cook Islands", field_option_value: "CK", checked: false },
    { code: "506", field_option_label: "Costa Rica", field_option_value: "CR", checked: false },
    { code: "225", field_option_label: "Cote d'Ivoire", field_option_value: "CI", checked: false },
    { code: "385", field_option_label: "Croatia", field_option_value: "HR", checked: false },
    { code: "53", field_option_label: "Cuba", field_option_value: "CU", checked: false },
    { code: "599", field_option_label: "Curacao", field_option_value: "CW", checked: false },
    { code: "357", field_option_label: "Cyprus", field_option_value: "CY", checked: false },
    { code: "420", field_option_label: "Czech Republic", field_option_value: "CZ", checked: false },
    { code: "243", field_option_label: "Democratic Republic Of Congo", field_option_value: "CD", checked: false },
    { code: "45", field_option_label: "Denmark", field_option_value: "DK", checked: false },
    { code: "", field_option_label: "Diego Garcia", field_option_value: "DG", checked: false },
    { code: "253", field_option_label: "Djibouti", field_option_value: "DJ", checked: false },
    { code: "1 767", field_option_label: "Dominica", field_option_value: "DM", checked: false },
    { code: "1 809", field_option_label: "Dominican Republic", field_option_value: "DO", checked: false },
    { code: "670", field_option_label: "East Timor", field_option_value: "TL", checked: false },
    { code: "593", field_option_label: "Ecuador", field_option_value: "EC", checked: false },
    { code: "20", field_option_label: "Egypt", field_option_value: "EG", checked: false },
    { code: "503", field_option_label: "El Salvador", field_option_value: "SV", checked: false },
    { code: "240", field_option_label: "Equatorial Guinea", field_option_value: "GQ", checked: false },
    { code: "291", field_option_label: "Eritrea", field_option_value: "ER", checked: false },
    { code: "372", field_option_label: "Estonia", field_option_value: "EE", checked: false },
    { code: "251", field_option_label: "Ethiopia", field_option_value: "ET", checked: false },
    { code: "388", field_option_label: "European Union", field_option_value: "EU", checked: false },
    { code: "500", field_option_label: "Falkland Islands", field_option_value: "FK", checked: false },
    { code: "298", field_option_label: "Faroe Islands", field_option_value: "FO", checked: false },
    { code: "679", field_option_label: "Fiji", field_option_value: "FJ", checked: false },
    { code: "358", field_option_label: "Finland", field_option_value: "FI", checked: false },
    { code: "33", field_option_label: "France", field_option_value: "FR", checked: false },
    { code: "241", field_option_label: "France, Metropolitan", field_option_value: "FX", checked: false },
    { code: "44", field_option_label: "French Guiana", field_option_value: "GF", checked: false },
    { code: "689", field_option_label: "French Polynesia", field_option_value: "PF", checked: false },
    { code: "", field_option_label: "French Southern Territories", field_option_value: "TF", checked: false },
    { code: "44", field_option_label: "Gabon", field_option_value: "GA", checked: false },
    { code: "220", field_option_label: "Gambia", field_option_value: "GM", checked: false },
    { code: "594", field_option_label: "Georgia", field_option_value: "GE", checked: false },
    { code: "49", field_option_label: "Germany", field_option_value: "DE", checked: false },
    { code: "233", field_option_label: "Ghana", field_option_value: "GH", checked: false },
    { code: "350", field_option_label: "Gibraltar", field_option_value: "GI", checked: false },
    { code: "30", field_option_label: "Greece", field_option_value: "GR", checked: false },
    { code: "299", field_option_label: "Greenland", field_option_value: "GL", checked: false },
    { code: "995", field_option_label: "Grenada", field_option_value: "GD", checked: false },
    { code: "590", field_option_label: "Guadeloupe", field_option_value: "GP", checked: false },
    { code: "1 671", field_option_label: "Guam", field_option_value: "GU", checked: false },
    { code: "502", field_option_label: "Guatemala", field_option_value: "GT", checked: false },
    { code: "", field_option_label: "Guernsey", field_option_value: "GG", checked: false },
    { code: "224", field_option_label: "Guinea", field_option_value: "GN", checked: false },
    { code: "245", field_option_label: "Guinea-bissau", field_option_value: "GW", checked: false },
    { code: "592", field_option_label: "Guyana", field_option_value: "GY", checked: false },
    { code: "509", field_option_label: "Haiti", field_option_value: "HT", checked: false },
    { code: "", field_option_label: "Heard Island And McDonald Islands", field_option_value: "HM", checked: false },
    { code: "504", field_option_label: "Honduras", field_option_value: "HN", checked: false },
    { code: "852", field_option_label: "Hong Kong", field_option_value: "HK", checked: false },
    { code: "36", field_option_label: "Hungary", field_option_value: "HU", checked: false },
    { code: "354", field_option_label: "Iceland", field_option_value: "IS", checked: false },
    { code: "91", field_option_label: "India", field_option_value: "IN", checked: false },
    { code: "62", field_option_label: "Indonesia", field_option_value: "ID", checked: false },
    { code: "98", field_option_label: "Iran, Islamic Republic Of", field_option_value: "IR", checked: false },
    { code: "964", field_option_label: "Iraq", field_option_value: "IQ", checked: false },
    { code: "353", field_option_label: "Ireland", field_option_value: "IE", checked: false },
    { code: "44", field_option_label: "Isle Of Man", field_option_value: "IM", checked: false },
    { code: "972", field_option_label: "Israel", field_option_value: "IL", checked: false },
    { code: "39", field_option_label: "Italy", field_option_value: "IT", checked: false },
    { code: "1 876", field_option_label: "Jamaica", field_option_value: "JM", checked: false },
    { code: "81", field_option_label: "Japan", field_option_value: "JP", checked: false },
    { code: "44", field_option_label: "Jersey", field_option_value: "JE", checked: false },
    { code: "962", field_option_label: "Jordan", field_option_value: "JO", checked: false },
    { code: "7", field_option_label: "Kazakhstan", field_option_value: "KZ", checked: false },
    { code: "254", field_option_label: "Kenya", field_option_value: "KE", checked: false },
    { code: "686", field_option_label: "Kiribati", field_option_value: "KI", checked: false },
    { code: "850", field_option_label: "Korea, Democratic People's Republic Of", field_option_value: "KP", checked: false },
    { code: "82", field_option_label: "Korea, Republic Of", field_option_value: "KR", checked: false },
    { code: "965", field_option_label: "Kuwait", field_option_value: "KW", checked: false },
    { code: "996", field_option_label: "Kyrgyzstan", field_option_value: "KG", checked: false },
    { code: "856", field_option_label: "Lao People's Democratic Republic", field_option_value: "LA", checked: false },
    { code: "371", field_option_label: "Latvia", field_option_value: "LV", checked: false },
    { code: "961", field_option_label: "Lebanon", field_option_value: "LB", checked: false },
    { code: "266", field_option_label: "Lesotho", field_option_value: "LS", checked: false },
    { code: "231", field_option_label: "Liberia", field_option_value: "LR", checked: false },
    { code: "218", field_option_label: "Libya", field_option_value: "LY", checked: false },
    { code: "423", field_option_label: "Liechtenstein", field_option_value: "LI", checked: false },
    { code: "370", field_option_label: "Lithuania", field_option_value: "LT", checked: false },
    { code: "352", field_option_label: "Luxembourg", field_option_value: "LU", checked: false },
    { code: "853", field_option_label: "Macao", field_option_value: "MO", checked: false },
    { code: "389", field_option_label: "Macedonia, The Former Yugoslav Republic Of", field_option_value: "MK", checked: false },
    { code: "261", field_option_label: "Madagascar", field_option_value: "MG", checked: false },
    { code: "265", field_option_label: "Malawi", field_option_value: "MW", checked: false },
    { code: "60", field_option_label: "Malaysia", field_option_value: "MY", checked: false },
    { code: "960", field_option_label: "Maldives", field_option_value: "MV", checked: false },
    { code: "223", field_option_label: "Mali", field_option_value: "ML", checked: false },
    { code: "356", field_option_label: "Malta", field_option_value: "MT", checked: false },
    { code: "692", field_option_label: "Marshall Islands", field_option_value: "MH", checked: false },
    { code: "596", field_option_label: "Martinique", field_option_value: "MQ", checked: false },
    { code: "222", field_option_label: "Mauritania", field_option_value: "MR", checked: false },
    { code: "230", field_option_label: "Mauritius", field_option_value: "MU", checked: false },
    { code: "262", field_option_label: "Mayotte", field_option_value: "YT", checked: false },
    { code: "52", field_option_label: "Mexico", field_option_value: "MX", checked: false },
    { code: "691", field_option_label: "Micronesia, Federated States Of", field_option_value: "FM", checked: false },
    { code: "373", field_option_label: "Moldova", field_option_value: "MD", checked: false },
    { code: "377", field_option_label: "Monaco", field_option_value: "MC", checked: false },
    { code: "976", field_option_label: "Mongolia", field_option_value: "MN", checked: false },
    { code: "382", field_option_label: "Montenegro", field_option_value: "ME", checked: false },
    { code: "1 664", field_option_label: "Montserrat", field_option_value: "MS", checked: false },
    { code: "212", field_option_label: "Morocco", field_option_value: "MA", checked: false },
    { code: "258", field_option_label: "Mozambique", field_option_value: "MZ", checked: false },
    { code: "95", field_option_label: "Myanmar", field_option_value: "MM", checked: false },
    { code: "264", field_option_label: "Namibia", field_option_value: "NA", checked: false },
    { code: "674", field_option_label: "Nauru", field_option_value: "NR", checked: false },
    { code: "977", field_option_label: "Nepal", field_option_value: "NP", checked: false },
    { code: "31", field_option_label: "Netherlands", field_option_value: "NL", checked: false },
    { code: "687", field_option_label: "New Caledonia", field_option_value: "NC", checked: false },
    { code: "64", field_option_label: "New Zealand", field_option_value: "NZ", checked: false },
    { code: "505", field_option_label: "Nicaragua", field_option_value: "NI", checked: false },
    { code: "227", field_option_label: "Niger", field_option_value: "NE", checked: false },
    { code: "234", field_option_label: "Nigeria", field_option_value: "NG", checked: false },
    { code: "683", field_option_label: "Niue", field_option_value: "NU", checked: false },
    { code: "672", field_option_label: "Norfolk Island", field_option_value: "NF", checked: false },
    { code: "1 670", field_option_label: "Northern Mariana Islands", field_option_value: "MP", checked: false },
    { code: "47", field_option_label: "Norway", field_option_value: "NO", checked: false },
    { code: "968", field_option_label: "Oman", field_option_value: "OM", checked: false },
    { code: "92", field_option_label: "Pakistan", field_option_value: "PK", checked: false },
    { code: "680", field_option_label: "Palau", field_option_value: "PW", checked: false },
    { code: "970", field_option_label: "Palestinian Territory, Occupied", field_option_value: "PS", checked: false },
    { code: "507", field_option_label: "Panama", field_option_value: "PA", checked: false },
    { code: "675", field_option_label: "Papua New Guinea", field_option_value: "PG", checked: false },
    { code: "595", field_option_label: "Paraguay", field_option_value: "PY", checked: false },
    { code: "51", field_option_label: "Peru", field_option_value: "PE", checked: false },
    { code: "63", field_option_label: "Philippines", field_option_value: "PH", checked: false },
    { code: "", field_option_label: "Pitcairn", field_option_value: "PN", checked: false },
    { code: "48", field_option_label: "Poland", field_option_value: "PL", checked: false },
    { code: "351", field_option_label: "Portugal", field_option_value: "PT", checked: false },
    { code: "1 787", field_option_label: "Puerto Rico", field_option_value: "PR", checked: false },
    { code: "974", field_option_label: "Qatar", field_option_value: "QA", checked: false },
    { code: "242", field_option_label: "Republic Of Congo", field_option_value: "CG", checked: false },
    { code: "262", field_option_label: "Reunion", field_option_value: "RE", checked: false },
    { code: "40", field_option_label: "Romania", field_option_value: "RO", checked: false },
    { code: "7", field_option_label: "Russian Federation", field_option_value: "RU", checked: false },
    { code: "250", field_option_label: "Rwanda", field_option_value: "RW", checked: false },
    { code: "590", field_option_label: "Saint Barthélemy", field_option_value: "BL", checked: false },
    { code: "290", field_option_label: "Saint Helena, Ascension And Tristan Da Cunha", field_option_value: "SH", checked: false },
    { code: "1 869", field_option_label: "Saint Kitts And Nevis", field_option_value: "KN", checked: false },
    { code: "1 758", field_option_label: "Saint Lucia", field_option_value: "LC", checked: false },
    { code: "590", field_option_label: "Saint Martin", field_option_value: "MF", checked: false },
    { code: "508", field_option_label: "Saint Pierre And Miquelon", field_option_value: "PM", checked: false },
    { code: "1 784", field_option_label: "Saint Vincent And The Grenadines", field_option_value: "VC", checked: false },
    { code: "685", field_option_label: "Samoa", field_option_value: "WS", checked: false },
    { code: "378", field_option_label: "San Marino", field_option_value: "SM", checked: false },
    { code: "239", field_option_label: "Sao Tome And Principe", field_option_value: "ST", checked: false },
    { code: "966", field_option_label: "Saudi Arabia", field_option_value: "SA", checked: false },
    { code: "221", field_option_label: "Senegal", field_option_value: "SN", checked: false },
    { code: "381", field_option_label: "Serbia", field_option_value: "RS", checked: false },
    { code: "248", field_option_label: "Seychelles", field_option_value: "SC", checked: false },
    { code: "232", field_option_label: "Sierra Leone", field_option_value: "SL", checked: false },
    { code: "65", field_option_label: "Singapore", field_option_value: "SG", checked: false },
    { code: "1 721", field_option_label: "Sint Maarten", field_option_value: "SX", checked: false },
    { code: "421", field_option_label: "Slovakia", field_option_value: "SK", checked: false },
    { code: "386", field_option_label: "Slovenia", field_option_value: "SI", checked: false },
    { code: "677", field_option_label: "Solomon Islands", field_option_value: "SB", checked: false },
    { code: "252", field_option_label: "Somalia", field_option_value: "SO", checked: false },
    { code: "27", field_option_label: "South Africa", field_option_value: "ZA", checked: false },
    { code: "", field_option_label: "South Georgia And The South Sandwich Islands", field_option_value: "GS", checked: false },
    { code: "34", field_option_label: "Spain", field_option_value: "ES", checked: false },
    { code: "94", field_option_label: "Sri Lanka", field_option_value: "LK", checked: false },
    { code: "249", field_option_label: "Sudan", field_option_value: "SD", checked: false },
    { code: "597", field_option_label: "Suriname", field_option_value: "SR", checked: false },
    { code: "47", field_option_label: "Svalbard And Jan Mayen", field_option_value: "SJ", checked: false },
    { code: "268", field_option_label: "Swaziland", field_option_value: "SZ", checked: false },
    { code: "46", field_option_label: "Sweden", field_option_value: "SE", checked: false },
    { code: "41", field_option_label: "Switzerland", field_option_value: "CH", checked: false },
    { code: "963", field_option_label: "Syrian Arab Republic", field_option_value: "SY", checked: false },
    { code: "886", field_option_label: "Taiwan, Province Of China", field_option_value: "TW", checked: false },
    { code: "992", field_option_label: "Tajikistan", field_option_value: "TJ", checked: false },
    { code: "255", field_option_label: "Tanzania, United Republic Of", field_option_value: "TZ", checked: false },
    { code: "66", field_option_label: "Thailand", field_option_value: "TH", checked: false },
    { code: "228", field_option_label: "Togo", field_option_value: "TG", checked: false },
    { code: "690", field_option_label: "Tokelau", field_option_value: "TK", checked: false },
    { code: "676", field_option_label: "Tonga", field_option_value: "TO", checked: false },
    { code: "1 868", field_option_label: "Trinidad And Tobago", field_option_value: "TT", checked: false },
    { code: "290", field_option_label: "Tristan de Cunha", field_option_value: "TA", checked: false },
    { code: "216", field_option_label: "Tunisia", field_option_value: "TN", checked: false },
    { code: "90", field_option_label: "Turkey", field_option_value: "TR", checked: false },
    { code: "993", field_option_label: "Turkmenistan", field_option_value: "TM", checked: false },
    { code: "1 649", field_option_label: "Turks And Caicos Islands", field_option_value: "TC", checked: false },
    { code: "688", field_option_label: "Tuvalu", field_option_value: "TV", checked: false },
    { code: "", field_option_label: "USSR", field_option_value: "SU", checked: false },
    { code: "256", field_option_label: "Uganda", field_option_value: "UG", checked: false },
    { code: "380", field_option_label: "Ukraine", field_option_value: "UA", checked: false },
    { code: "971", field_option_label: "United Arab Emirates", field_option_value: "AE", checked: false },
    { code: "1 473", field_option_label: "United Kingdom GB", field_option_value: "GB", checked: false },
    { code: "", field_option_label: "United Kingdom", field_option_value: "UK", checked: false },
    { code: "1", field_option_label: "United States", field_option_value: "US", checked: false },
    { code: "", field_option_label: "United States Minor Outlying Islands", field_option_value: "UM", checked: false },
    { code: "598", field_option_label: "Uruguay", field_option_value: "UY", checked: false },
    { code: "998", field_option_label: "Uzbekistan", field_option_value: "UZ", checked: false },
    { code: "678", field_option_label: "Vanuatu", field_option_value: "VU", checked: false },
    { code: "379", field_option_label: "Vatican City State", field_option_value: "VA", checked: false },
    { code: "58", field_option_label: "Venezuela, Bolivarian Republic Of", field_option_value: "VE", checked: false },
    { code: "84", field_option_label: "Viet Nam", field_option_value: "VN", checked: false },
    { code: "1 284", field_option_label: "Virgin Islands (British)", field_option_value: "VG", checked: false },
    { code: "1 340", field_option_label: "Virgin Islands (US)", field_option_value: "VI", checked: false },
    { code: "681", field_option_label: "Wallis And Futuna", field_option_value: "WF", checked: false },
    { code: "212", field_option_label: "Western Sahara", field_option_value: "EH", checked: false },
    { code: "260", field_option_label: "Zambia", field_option_value: "ZM", checked: false },
    { code: "967", field_option_label: "Yemen", field_option_value: "YE", checked: false },
    { code: "263", field_option_label: "Zimbabwe", field_option_value: "ZW", checked: false },
]
