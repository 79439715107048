import React, { Component } from 'react';
import ArrowBackGrey from '../../assets/images/icon/chevron-left.svg';
import TextAreaField from '../../components/fields/textarea';
import InputField from '../../components/fields/inputs';
import Fab from '@material-ui/core/Fab';
import { Scrollbars } from 'react-custom-scrollbars';
import closeIcon from '../../assets/images/icon/close.svg';
import MultipleTabActions from '../../components/common/multiple-tab-actions';

class SectionSetting extends Component {

    state = {
        sectionInfo: {},
    }

    componentWillMount() {
        let { sectionDetails } = this.props;
        this.setState({ sectionInfo: JSON.parse(JSON.stringify(sectionDetails)) });
    }

    handleSectionEditChange(value, type) {
        let { sectionInfo } = this.state;
        sectionInfo[type] = value;
        this.setState({ sectionInfo: sectionInfo })
    }

    handleSaveClick() {
        this.props.updateSectionDetails(this.state.sectionInfo);
        this.props.toggleDrawerSectionSettings("settingSection", false);
    }

    render() {
        let { sectionInfo } = this.state;
        return (
            <section className="workflow-setup-sec">
                <section className="setup-drawer-sec">
                    <div className="card-block">
                        <div className="card-header">
                            <div className="card-subheader">
                                <div className="card-title">
                                    <div className="actions setup-close" style={{ marginLeft: '-15px' }}>
                                        <div className="btn-wrap">
                                            <Fab variant="extended" size="small" className="btn btn-small btn-plane btn-fab white-color no-shadow" onClick={(event) => { this.props.toggleDrawerSectionSettings("settingSection", false) }}>
                                                <img alt="edit" src={ArrowBackGrey} style={{ margin: 0 }}></img> Go Back
                                            </Fab>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-left-title">
                                    <div className="actions setup-close">
                                        <div className="btn-wrap">
                                            <Fab variant="extended" size="small" className="btn btn-small btn-plane btn-fab white-color no-shadow" onClick={(event) => { this.props.toggleDrawerSectionSettings("settingSection", false) }}>
                                                Close <img alt="edit" src={closeIcon}></img>
                                            </Fab>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card-header border-bottom" style={{ padding: '8px 0px 25px' }}>
                            <div className="card-title">
                                <h2>Setting</h2>
                            </div>
                            {/* <div className="card-left-title">
                                <MultipleTabActions type="icons" iconList={["edit","delete"]}/>
                            </div> */}
                        </div>
                        <div className="card-body">
                            <div role="presentation" className="drawer-sec">
                                <div className="drawer-form-sec">
                                    <div className="tab-container tab-container-scroll" style={{ height: 'calc(100vh - 295px)' }}>
                                        <Scrollbars style={{ width: '100%', height: '100%' }}>
                                            <div className="state-field-add">
                                                <InputField
                                                    label="Name of the Section"
                                                    type="text" name="section_name"
                                                    placeholder="Enter"
                                                    customStyle="medium-row"
                                                    selected_value={sectionInfo ? sectionInfo.section_name : ""}
                                                    handleOnChangeValue={(value) => this.handleSectionEditChange(value, "section_name")}
                                                />

                                                <TextAreaField
                                                    label="Description"
                                                    type="textarea"
                                                    name="section_description"
                                                    placeholder="Description"
                                                    rowmax="5"
                                                    customStyle="medium-row"
                                                    selected_value={sectionInfo ? sectionInfo.description : ""}
                                                    handleOnChangeValue={(value) => this.handleSectionEditChange(value, "description")}
                                                />

                                                {/* <IconFilterPopover fieldStyle="field-box bg-plane grey-color field-icon" customStyle="adornment-only-icon" label="Select Icon Upload"/> */}
                                            </div>
                                        </Scrollbars>
                                    </div>
                                    <div className="card-footer border-top">
                                        <div className="btn-wrap">
                                            <Fab variant="extended" size="large" className="btn btn-large btn-plane primary-color" onClick={() => this.handleSaveClick()}>Save</Fab>
                                            <Fab variant="extended" size="large" className="btn btn-large btn-plane grey-color" onClick={(event) => { this.props.toggleDrawerSectionSettings("settingSection", false) }}>Cancel</Fab>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </section>
        )
    }
}

export default SectionSetting;