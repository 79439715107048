import React, { Component } from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import Fab from '@material-ui/core/Fab';
import Add from '@material-ui/icons/Add';
import Minimize from '@material-ui/icons/Minimize';
import InputField from '../fields/inputs';
import TextAreaField from '../fields/textarea';
import SelectField from '../../components/fields/select';

class NameDescChoice extends Component {

    handleOnChangeText = (key, index) => (value) => {
        let { checkData } = this.props;
        checkData[index][key] = value;
        this.props.optionOnChangeEvent(checkData);
    }

    addNewOption = index => event => {
        let checkData = this.props.checkData;
        let options = {
            position: index + 1,
            sub_state: true,
        };
        checkData.splice(index + 1, 0, options);
        this.props.optionOnChangeEvent(checkData);
    }

    deleteOption = index => event => {
        let checkData = this.props.checkData;
        this.props.handleDeleteOfSubState(checkData[index].id);
        checkData.splice(index, 1);
        this.props.optionOnChangeEvent(checkData);
    }

    modifyUserOptionList(permissionList, index) {
        let { checkData } = this.props;
        if (checkData[index].hasOwnProperty("permission_ids")) {
            permissionList.forEach(list => {
                list["checked"] = checkData[index].permission_ids ? checkData[index].permission_ids.includes(parseInt(list.field_option_value)) : false;
            })
        }
        return permissionList;
    }

    render() {
        let { checkData, label, description, title, isDynamic, filteredStateObj, newStateOrEdit, permissionList } = this.props;
        let checkDataUpdated = JSON.parse(JSON.stringify(checkData));
        let subData = filteredStateObj ? (filteredStateObj.sub_states_list.length > 0 ? filteredStateObj.sub_states_list : checkData) : "";
        return (
            <div className="form-row">
                <InputLabel
                    htmlFor={label}
                    className="label"
                >
                    {description ? description : ""}
                </InputLabel>
                {title && (
                    <div className="title border-bottom">
                        <h4>{title}</h4>
                    </div>
                )}
                <div className="choice-wrap">
                    {
                        checkDataUpdated.map((check, index) => {
                            return (
                                <div className="choice-row field-value-choice field-value-choice-single" key={index}>

                                    <InputField
                                        label="Name"
                                        type="text"
                                        placeholder="Enter"
                                        default_value={newStateOrEdit !== "new" ? subData[index].state_name : ""}
                                        selected_value={this.props.checkData[index].state_name}
                                        handleOnChangeValue={this.handleOnChangeText("state_name", index)}
                                    />

                                    <TextAreaField
                                        label="Description"
                                        type="textarea"
                                        placeholder="Description"
                                        default_value={newStateOrEdit !== "new" ? subData[index].state_description : ""}
                                        selected_value={this.props.checkData[index].state_description}
                                        handleOnChangeValue={this.handleOnChangeText("state_description", index)}
                                    />

                                    <SelectField
                                        label="Select Permissions"
                                        //optionsData={newStateOrEdit === "edit" ? this.modifyUserOptionList(JSON.parse(JSON.stringify(permissionList)), index) : JSON.parse(JSON.stringify(permissionList))}
                                        optionsData={this.modifyUserOptionList(JSON.parse(JSON.stringify(permissionList)), index)}
                                        isMultiple={true}
                                        radioChangeHandle={this.handleOnChangeText("permission_ids", index)}
                                        selected_value={this.props.checkData[index].permission_ids}
                                        fieldStyle="field-box" />

                                    {isDynamic &&
                                        (
                                            <div className="choice-action">
                                                <Fab aria-label="add_circle_outline" className="fabs-icons add" onClick={this.addNewOption(index)}>
                                                    <Add />
                                                </Fab>
                                                {(checkData.length <= 1 && index === 0) ? "" :
                                                    (
                                                        <Fab aria-label="highlight_off" className="fabs-icons remove" onClick={this.deleteOption(index)}>
                                                            <Minimize />
                                                        </Fab>
                                                    )
                                                }
                                            </div>
                                        )
                                    }
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        )
    }
}
NameDescChoice.defaultProps = {
    isDynamic: true,
    isDefault: false,
    parentFieldList: [],
}
export default NameDescChoice;