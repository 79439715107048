import React, { Component } from 'react';

class Description extends Component {
    render() {
        const { value } = this.props

        return (
            <div className="form-row-instruction">
                <h6 className="font-med">{value}</h6>
            </div>
        )
    }
}

export default Description;