import React, { Component } from 'react';
import Header from '../../components/header';
import WorkflowHeader from '../../components/workflow/workflow-header';
import DetailsFormTabs from '../../components/details-view/details-form-tabs';
import DetailsActionStatus from '../../components/details-view/details-action-status';
import { Redirect } from 'react-router-dom';

//dialog
import Dialog from '@material-ui/core/Dialog';
import CloseIcon from '../../assets/images/icon/close.svg';

//Custom component
import FormViewComponent from '../../components/form-view/form-view';
import LoadingComponent from '../../components/common/loading-component';
import { Scrollbars } from 'react-custom-scrollbars';
import AlertBox from '../../components/common/alert-box';
import Fab from '@material-ui/core/Fab';
import Drawer from '@material-ui/core/Drawer';
import ArrowBackGrey from '@material-ui/icons/KeyboardArrowLeft';



//API
import * as ProcessProgress from '../../api/process-api';
import * as ProgramApi from '../../api/program-api';
import * as FormApi from '../../api/form-api';
import * as AuditTrailApi from '../../api/audit-api'
import * as FormDataApi from '../../api/form-data-api.jsx';
import * as FileStorageApi from '../../api/file-storage-api.jsx';
import * as UserApi from '../../api/user-api';

//Constants
import * as Constants from '../../utilities/constants';

class DetailsView extends Component {

    state = {
        processData: {},
        sectionData: [],
        openModal: false,
        loading: false,
        modalFormData: { form_field_list: [] },
        linkData: {},
        auditTrail: [],
        reverseAuditTrail: [],
        redirectBackToDashboard: false,
        section_id: 1,
        warningMessages: [],
        openEditForm: false,
        sectionalFormData: {},
        formSelectedOnTab: 0,
        openDeleteTrigger: false,
        right: false,
        uploadedFileDetails: [],
        userDetails: {},
        errorState: false,
        errorStateMessage: "",
        redirectToErrorPage: false,
        updateReverseAuditTrail: [],
        updateReverseAuditDate: [],

    }

    componentWillMount() {
        if (this.props.location && this.props.location.state) {
            console.log("this.props.location.state.id", this.props.location.state.id);
            this.setState({ section_id: this.props.location.state.id });
        }
        this.getUserDetails();
    }

    redirectBackToDashboardChangeEvent = () => (value) => {
        this.setState({ redirectBackToDashboard: value });
    }

    getUserDetails() {
        UserApi.getLoggedInUser().then(user => {
            this.setState({ userDetails: user });
        });
    }

    componentDidMount() {
        this.getProcessDetails();

    }

    updateWarningMessageList(indexOfMessage) {
        let { warningMessages } = this.state;
        console.log("indexOfMessage", indexOfMessage, "warningMessages", warningMessages);
        warningMessages[indexOfMessage].valid = false;
        this.setState({ warningMessages: warningMessages });
    }



    setStatusMessageList() {
        let { warningMessages } = this.state;

        let messageMappingIdList = [];

        warningMessages &&
            warningMessages.forEach(data => {
                if (!messageMappingIdList.includes(data.message_mapping_id)) {
                    messageMappingIdList.push(data.message_mapping_id);
                }

            })

        ProcessProgress.updateMappingStatus(messageMappingIdList).then(data => {
            console.log("data231: ", data);
        })

    }

    getProcessDetails() {
        const match = this.props.match;
        let processkey = match.params.processkey;
        let programkey = match.params.programkey;
        ProgramApi.getAllSection(programkey).then(sectionData => {
            this.setState({ sectionData: sectionData }, () => {
                ProcessProgress.getProcessDetails(processkey).then(processData => {
                    this.setState({ processData: processData.process_progress_dto, linkData: processData._links, warningMessages: processData.process_progress_dto.warning_messages_list, sectionalFormData: processData.process_progress_dto.sectional_form_record && processData.process_progress_dto.sectional_form_record.form }, () => this.setStatusMessageList()); //, sectionalFormData: processData.processData.process_progress_dto.sectional_form_record && processData.sectional_form_record.form
                    this.getAuditTrail(processkey);
                })
                    .catch(error => {
                        let errorResponse = JSON.parse(error.response);
                        console.log("XOXOXOXO123", errorResponse);
                        this.setState({ redirectToErrorPage: true });
                    })
                FileStorageApi.getFileDetailsFromProcessKey(processkey).then(processData => {
                    this.setState({ uploadedFileDetails: processData });
                })
            });
        });
    }

    redirectToErrorPage() {
        console.log("herre me")
        if (this.state.redirectToErrorPage) {
            return <Redirect to={{
                pathname: "/error",
                state: {
                    userType: "2",
                }
            }} />
        }

    }

    handleFormSelectedOnTab(value) {
        this.setState({ formSelectedOnTab: value });
    }

    paginationChange = () => (key) => {

        if (this.state.linkData.hasOwnProperty(key)) {
            let splitLink = this.state.linkData[key].href.split("/");
            let newId = splitLink[splitLink.length - 1];
            let newUrl = window.location.href.substring(0, window.location.href.lastIndexOf('/')) + "/" + newId;
            window.history.pushState(null, '', newUrl);
            ProcessProgress.getPreviousAndNextData(this.state.linkData[key].href).then(processData => {
                this.setState({ processData: processData.process_progress_dto, linkData: processData._links });
                this.getAuditTrail(newId);
                // window.location = window.location.href.substring(0, window.location.href.lastIndexOf('/') + key);
            })
        }

    }

    duplicateRecord() {
        let records = JSON.parse(JSON.stringify(this.state.processData.sectional_form_record.records))
        records[0]["record_id"] = null;
        records[0]["common_record_id"] = null;
        records[0]["process_id"] = null;

        FormDataApi.saveFormData(records).then(res => {
            this.getProcessDetails();
        });
    }

    handleSetPriority = () => (key) => {
        let splitLink = window.location.href.split("/");
        let newId = splitLink[splitLink.length - 1];

        ProcessProgress.updatePriority(newId, key).then(response => {
            this.getProcessDetails();
        })

    }

    getAuditTrail(processId) {
        let updateOtherFormRecord = []
        let reverseAuditDate = []
        let res = []

        AuditTrailApi.getAuditTrailProcess(processId).then(auditData => {
            console.log("auditData222", auditData);
            let reverseAuditTrail = JSON.parse(JSON.stringify(auditData));
            reverseAuditTrail.reverse();
            res = auditData
            res.reverse();
            reverseAuditTrail.map((auditTrail, index) => {

                updateOtherFormRecord.push(res[index].created_on.substring(0, 10))
                reverseAuditDate.push(res[index].created_on.substring(0, 2) + "-"
                    + new Date(res[index].created_on.substring(3, 5) + '/'
                        + res[index].created_on.substring(0, 2) + '/' + res[index].created_on.substring(6, 10)).toLocaleString('en-us', { month: 'short' })
                    + "-" + res[index].created_on.substring(6, 10));

            })

            console.log("auditData222", reverseAuditTrail);
            this.setState({ auditTrail: auditData, reverseAuditTrail: reverseAuditTrail, updateReverseAuditTrail: updateOtherFormRecord, updateReverseAuditDate: reverseAuditDate });
        });
    }

    getModalFormData(formkey) {
        const match = this.props.match;
        let processkey = match.params.processkey;

        this.handleClickOpen();
        this.setState({ loading: true });
        let formPromise = FormApi.getFormDataDetails(formkey, processkey);
        formPromise.then(formData => {
            this.setState({ modalFormData: formData, loading: false, errorState: false });
        })
            .catch(error => {
                let errorResponse = JSON.parse(error.response);
                console.log("error123", error);
                this.setState({ loading: false, errorState: true, errorStateMessage: errorResponse.message });
            });
    }

    handleClickOpen() {
        this.setState({ openModal: true });
    }

    handleClose() {
        this.setState({ openModal: false });
        //clearing data
        this.setState({ modalFormData: { form_field_list: [] } });
    }

    afterDialogEventSave() {
        this.handleClose();
        //clearing data
        this.setState({ modalFormData: { form_field_list: [] } });
        this.getProcessDetails();
    }

    renderModalForm() {
        const match = this.props.match;
        let processkey = match.params.processkey;
        let { loading, modalFormData, processData } = this.state;
        return (
            <Dialog open={this.state.openModal} onClose={this.handleClose.bind(this)} aria-labelledby="simple-dialog-title" maxWidth="md">
                <section className="workflow-dialog workflow-dialog-large workflow-form-dialog">
                    <div className="card-block">
                        <div className="card-header border-bottom p-b-25">
                            <div className="sub-title">
                                <h2 className="bold">{modalFormData.form_name}</h2>
                            </div>
                            <div className="close-dialog">
                                <img src={CloseIcon} alt="CloseIcon" onClick={this.handleClose.bind(this)} ></img>
                            </div>
                        </div>
                        <LoadingComponent loading={loading} />
                    </div>
                    <div className="card-body p-t-25 p-b-0">
                        <div className="tabs-container tabs-container-scroll form-preview-dialog">
                            <Scrollbars style={{ width: '100%', height: '100%' }}>
                                <div className="tabs-container-scroll-wrap">
                                    {this.state.openModal &&
                                        (<FormViewComponent
                                            formData={modalFormData}
                                            process_id={processkey}
                                            afterSaveEvent={this.afterDialogEventSave.bind(this)}
                                            current_state={processData.current_state}
                                            current_sub_state={processData.current_sub_state}
                                            handleClose={() => this.handleClose()}
                                            errorState={this.state.errorState}
                                            errorStateMessage={this.state.errorStateMessage}
                                            updateData={(value, callback) => this.handleDataChangeEvent("modalFormData", value, callback)}
                                        />)
                                    }
                                </div>
                            </Scrollbars>
                        </div>
                    </div>
                </section>
            </Dialog>
        );
    }

    handleDataChangeEvent(key, value, callback) {
        console.log("key0088", key, "value", value, "callback", callback);
        if (key.split(".").length > 1) {
            let splitKeys = key.split(".");
            key = splitKeys[0];
            let valueObj = this.state[key];
            if (!valueObj) {
                valueObj = {};
            }
            valueObj[splitKeys[1]] = value;
            value = valueObj;
        }
        this.setState({ [key]: value }, callback);
    }

    getFormDataList(processData) {
        let formDataList = [];
        let reverseOtherFormRecords = [];

        reverseOtherFormRecords = processData.other_form_records

        if (processData.sectional_form_record) {
            processData.other_form_records.reverse();
            formDataList.push(processData.sectional_form_record);
            formDataList = formDataList.concat(processData.other_form_records);
        }
        return formDataList;
    }

    handleSectionChange(sectionId) {
        const match = this.props.match;
        let programKey = match.params.programkey;
        let clientId = match.params.clientId;
        window.location = '/' + clientId + '/' + programKey + "/dashboard";
    }

    onClickGoBackChange() {
        console.log("onClickGoBackChange", this.state.section_id, "redirectBackToDashboard", this.state.redirectBackToDashboard);
        let { userDetails } = this.state;
        if (this.state.redirectBackToDashboard) {
            if (userDetails && userDetails.user_type === 2) {
                //window.location = '/policy-list';
                return <Redirect to={{
                    pathname: '/policy-list',
                    state: { userName: userDetails.user_name }
                }}
                />
            }
            else {
                const match = this.props.match;
                let programKey = match.params.programkey;
                let clientId = match.params.clientId;
                return <Redirect to={{
                    pathname: '/' + clientId + '/' + programKey + "/dashboard",
                    state: { id: this.state.section_id }
                }}
                />
            }
        }
    }

    redirectOnEditClick() {
        console.log("ZZZZ");
        this.setState({ openEditForm: true });
    }

    redirectToFormUsingFormKey() {
        const match = this.props.match;
        let programKey = match.params.programkey;
        let clientId = match.params.clientId;
        let { processData, formSelectedOnTab } = this.state;

        let onClickFormData = {};

        if (formSelectedOnTab > 0) {
            if (processData.other_form_records && processData.other_form_records.length > 0) {
                onClickFormData["sectional_form_record"] = processData.other_form_records[formSelectedOnTab - 1];
            }
        }

        if (this.state.openEditForm) {
            let pathUrl = "";
            let recordToForward = {};
            if (formSelectedOnTab === 0) {
                pathUrl = '/' + clientId + '/' + programKey + "/form/" + processData.sectional_form_record.form.form_key + "/publish";
                recordToForward = processData;
            }
            else {
                pathUrl = '/' + clientId + '/' + programKey + "/form/" + processData.other_form_records[formSelectedOnTab - 1].form.form_key + '/publish';
                recordToForward = onClickFormData
            }


            return <Redirect to={{
                pathname: pathUrl,
                state: { processData: recordToForward },
            }}
            />
        }
    }

    dialogDeleteOpen() {
        this.setState({ openDeleteTrigger: true });
    }

    dialogDeleteClose() {
        this.setState({ openDeleteTrigger: false });
    }

    handleConfirmDeleteClick() {
        let { formSelectedOnTab, processData } = this.state;

        if (formSelectedOnTab === 0) {
            let recordSectionalData = processData.sectional_form_record.records;

            if (recordSectionalData && recordSectionalData.length > 0) {
                let commonRecordId = recordSectionalData[0].common_record_id;
                FormDataApi.deleteRecordById(commonRecordId).then(data => {
                    this.setState({ redirectBackToDashboard: true, openDeleteTrigger: false });
                });
            }
        }
        else {
            let recordSectionalData = processData.other_form_records && processData.other_form_records[formSelectedOnTab - 1].records;
            let commonRecordId = recordSectionalData[0].common_record_id;

            FormDataApi.deleteRecordById(commonRecordId).then(data => {
                this.setState({ openDeleteTrigger: false }, () => { this.getProcessDetails() });
            });
        }
    }

    getFormActions() {
        let { formSelectedOnTab, processData } = this.state;
        let form = null;
        let permissionList = ["message"];
        if (formSelectedOnTab === 0) {
            form = processData.sectional_form_record && processData.sectional_form_record.form;
        }
        else {
            form = processData.other_form_records && processData.other_form_records[formSelectedOnTab - 1].form;
        }

        if (form) {
            if (form.is_write) {
                permissionList.push("edit");
            }
            if (form.is_create) {
                permissionList.push("duplicate");
            }
            if (form.is_delete) {
                permissionList.push("delete");
            }
            if (form.is_read) {
                permissionList.push("pagination");
            }
        }
        return permissionList;
    }

    renderDialogDeleteTrigger() {
        return (
            <Dialog open={this.state.openDeleteTrigger} onClose={this.dialogDeleteClose.bind(this)} aria-labelledby="simple-dialog-title">
                <section className="workflow-dialog workflow-dialog-small workflow-dialog-pri">
                    <div className="card-block">
                        <div className="card-header border-bottom p-b-25">
                            <div className="sub-title">
                                <h2 className="bold">Delete</h2>
                            </div>
                            <div className="close-dialog">
                                <img src={CloseIcon} alt="CloseIcon" onClick={this.dialogDeleteClose.bind(this)} ></img>
                            </div>
                        </div>
                        <div className="card-body p-t-25 p-b-45">
                            <div className="sub-title">
                                <h5 className="weight-300">Do you really want to delete the Record ?</h5>
                            </div>
                            <div className="card-footer p-0 p-t-25">
                                <div className="btn-wrap">
                                    <Fab variant="extended" size="large" className="btn btn-large btn-plane primary-color" onClick={() => this.handleConfirmDeleteClick()}>
                                        Delete
                                    </Fab>
                                    <Fab variant="extended" size="large" className="btn btn-large btn-plane grey-color" onClick={this.dialogDeleteClose.bind(this)}>
                                        Cancel
                                    </Fab>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </Dialog>
        );
    }

    toggleDrawer = (side, open) => event => {
        this.setState({ [side]: open });
    };

    getUpdatedFormDataList(processData) {
        let otherFormRecord = []
        let updateOtherFormRecord = []
        otherFormRecord = otherFormRecord.concat(processData.other_form_records);
        otherFormRecord.map((formRecord, index) => {
            updateOtherFormRecord.push(this.state.updateReverseAuditTrail[index])
        })
        return updateOtherFormRecord;
    }

    render() {
        const match = this.props.match;
        let processkey = match.params.processkey;
        let { processData, sectionData, auditTrail, reverseAuditTrail, warningMessages, uploadedFileDetails, sectionalFormData, right, openEditForm, updateReverseAuditTrail, updateReverseAuditDate } = this.state;
        let dataList = this.getFormDataList(processData);
        let idFieldName = "";
        let recordId = "";
        let paginationId = "";
        let formActions = this.getFormActions();
        console.log("processData123", processData, auditTrail, reverseAuditTrail);
        if (dataList.length > 0) {
            if (dataList[0].form) {
                dataList[0].form.form_field_list.forEach(list => {

                    if (list.id_field === "yes") {
                        recordId = "data_attr" + list.data_ref_id;
                        paginationId = list.data_pagination_id;
                    }
                })
                if (recordId && paginationId) {
                    idFieldName = dataList[0].records.filter(record =>
                        record.form_pagination_id === paginationId)[0][recordId];
                }
            }
        }
        //State progress setting
        // let section = {};
        // if(processData.sectional_form_record){
        //     section = sectionData.filter( sectionInfo => sectionInfo.id === processData.sectional_form_record.form.section_id)[0];
        //     section.program_state_list.sort((a,b) => a.position-b.position);
        //     //Set current state and substate
        //     section.current_state_id = processData.current_state;
        //     section.current_sub_state_id = processData.current_sub_state;
        //     let successState = true;
        //     if(processData.current_state){
        //         section.program_state_list.forEach( state => {

        //             let successSubState;
        //             if(processData.current_state === state.id){
        //                 section.current_state_name = state.state_name;
        //                 successState = false;
        //                 successSubState = true;
        //             }else{
        //                 successSubState = successState;
        //             }
        //             state.status = successState;
        //             state.sub_states_list.sort((a,b) => a.position-b.position);

        //             if(processData.current_sub_state){
        //                 if(state.sub_states_list.length > 0){
        //                     state.sub_states_list.forEach( subState => {
        //                         subState.status = successSubState;
        //                         if(processData.current_sub_state === subState.id){
        //                             section.current_sub_state_name = subState.state_name;
        //                             successSubState = false;
        //                         }
        //                     })
        //                     //check if all sub state true to make state true
        //                     state.status = state.sub_states_list[state.sub_states_list.length-1].status;
        //                 }
        //             }else{
        //                 section.current_sub_state_name = null;
        //             }
        //         })
        //     }else{
        //         section.current_state_name = null;
        //     }
        // }

        //FIlter for state changes
        let stateProgress = [];
        let latestState = "";
        let section = {};
        auditTrail.filter(audit => (audit.activity_type === Constants.ACTIVITY_TYPE.CHANGE_STATE)).forEach(audit => {
            let stateData = audit.object_name.split("-");
            if (stateData[0]) {
                let tempState;
                if (stateData[0] === latestState) {
                    tempState = stateProgress[stateProgress.length - 1];
                }
                if (!tempState) {
                    let stateObj = {
                        state_name: stateData[0],
                        created_date: audit.created_on.split("|")[0],
                        status: true
                    }
                    stateObj.sub_states_list = [];
                    if (stateData[1]) {
                        let subStateObj = {
                            state_name: stateData[1],
                            created_date: audit.created_on.split("|")[0],
                            status: true
                        }
                        stateObj.sub_states_list.push(subStateObj);
                        section.current_sub_state_name = stateData[1];
                    } else {
                        section.current_sub_state_name = null;
                    }
                    stateProgress.push(stateObj);
                } else {
                    if (stateData[1]) {
                        let subStateObj = {
                            state_name: stateData[1],
                            created_date: audit.created_on.split("|")[0],
                            status: true
                        }
                        tempState.sub_states_list.push(subStateObj);
                        section.current_sub_state_name = stateData[1];
                    } else {
                        section.current_sub_state_name = null;
                    }
                }

                latestState = stateData[0];
                section.current_state_name = stateData[0];
            }
        })
        //update state with progress state
        section.program_state_list = stateProgress;

        let programkey = match.params.programkey;
        let clientId = match.params.clientId;
        //let formViewLink = '/'+clientId+'/'+programKey+"/form/"+formKey+"/publish";
        return (
            <div className="workflow-scene">
                <Header headerType="dashboard" positionType="static" programKey={programkey} />

                <WorkflowHeader workflowType="dashboard" detailsView={true} customStyle="workflow-header-sticky" tab="form" sectionData={sectionData} programKey={programkey} handleTabChange={this.handleSectionChange.bind(this)} insideBlock="detailsView" />
                <WorkflowHeader workflowType="details-view" tabActions={formActions} idFieldName={idFieldName} customStyle="details-header-sticky" tab="form" redirectOnEditClick={() => this.redirectOnEditClick()} handleDeleteClick={() => this.dialogDeleteOpen()} linkData={this.state.linkData} onClickChange={this.redirectBackToDashboardChangeEvent.bind(this)} paginationChange={this.paginationChange.bind(this)} duplicateRecord={this.duplicateRecord.bind(this)} handleSetPriority={this.handleSetPriority.bind(this)} currentPriority={processData.priority} />

                <main>
                    <section className="workflow-fields-sec details-view-sec">
                        <div className="details-view-wrap details-view-info ">
                            <div class="drawer-tabs">
                                <DetailsFormTabs
                                    formDataList={this.getFormDataList(processData)}
                                    updatedFormDataList={this.getUpdatedFormDataList(processData)}
                                    auditTrail={reverseAuditTrail}
                                    uploadedFileDetails={this.state.uploadedFileDetails}
                                    handleFormSelectedOnTab={(value) => this.handleFormSelectedOnTab(value)}
                                    processData={processData}
                                    updateReverseAuditDate={this.state.updateReverseAuditDate}
                                />
                            </div>
                        </div>
                        <div className="details-view-wrap details-view-actions">
                            <div class="drawer-tabs">
                                <DetailsActionStatus
                                    currentFormData={processData.current_form}
                                    sectionData={section}
                                    getModalFormData={this.getModalFormData.bind(this)}
                                    process_id={processkey}
                                    afterSaveEvent={this.afterDialogEventSave.bind(this)}
                                    current_state={processData.current_state}
                                    current_sub_state={processData.current_sub_state}
                                    processData={processData}
                                    warningMessages={warningMessages}
                                    updateWarningMessageList={(indexOfMessage) => this.updateWarningMessageList(indexOfMessage)}
                                    updateData={(value, callback) => this.handleDataChangeEvent("processData.current_form", value, callback)}
                                />
                            </div>
                        </div>
                        <div className="mob-detail-view">
                            <Fab variant="extended" size="small" className="btn btn-large btn-take-action" onClick={this.toggleDrawer('right', true)}>
                                <ArrowBackGrey />
                            </Fab>
                            <Drawer anchor="right" open={this.state.right} onClose={this.toggleDrawer('right', false)}>
                                <section className="workflow-setup-sec notification-setup-sec">
                                    <section className="setup-drawer-sec">
                                        <div className="card-block">
                                            <div className="card-header">
                                                <div className="card-subheader">
                                                    <div className="card-title">
                                                    </div>
                                                    <div className="card-left-title">
                                                        <div className="actions setup-close">
                                                            <div className="btn-wrap">
                                                                <Fab variant="extended" size="small" className="btn btn-small btn-plane btn-fab white-color no-shadow" onClick={this.toggleDrawer('right', false)}>
                                                                    Close <img alt="edit" src={CloseIcon}></img>
                                                                </Fab>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card-body">
                                                <div role="presentation" className="drawer-sec">
                                                    <div className="drawer-form-sec">
                                                        <div className="tab-container tab-container-scroll" style={{ height: 'calc(100vh - 135px)' }}>
                                                            <Scrollbars style={{ width: '100%', height: '100%' }}>
                                                                <div className="workflow-program-list notify-program-list">
                                                                    <div className="details-view-wrap details-view-actions">
                                                                        <div class="drawer-tabs">
                                                                            <DetailsActionStatus
                                                                                currentFormData={processData.current_form}
                                                                                sectionData={section}
                                                                                getModalFormData={this.getModalFormData.bind(this)}
                                                                                process_id={processkey}
                                                                                afterSaveEvent={this.afterDialogEventSave.bind(this)}
                                                                                current_state={processData.current_state}
                                                                                current_sub_state={processData.current_sub_state}
                                                                                processData={processData}
                                                                                warningMessages={warningMessages}
                                                                                updateData={(value, callback) => this.handleDataChangeEvent("processData.current_form", value, callback)}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Scrollbars>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                </section>
                            </Drawer>
                        </div>
                    </section>
                </main>
                {this.redirectToErrorPage()}
                {this.renderModalForm()}
                {this.onClickGoBackChange()}
                {this.redirectToFormUsingFormKey()}
                {this.renderDialogDeleteTrigger()}
                {/* <div className="alert-wrap">
                    <AlertBox alertType="success" alertTypeStatus="successAlert"/>
                    <AlertBox alertType="warning" alertTypeStatus="warningAlert"/>
                    <AlertBox alertType="info" alertTypeStatus="infoAlert"/>
                </div> */}
            </div>

        )
    }
}

export default DetailsView;