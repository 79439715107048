import React, { Component } from 'react';
import Fab from '@material-ui/core/Fab';
import StackIcon from '../../assets/images/illustration/stack.svg';
import MasterIcon from '../../assets/images/illustration/master.svg';
import PolicyParamIcon from '../../assets/images/illustration/policy-param.svg';
import CoverageLimitIcon from '../../assets/images/illustration/coverage-limit.svg';
import PaymentMIcon from '../../assets/images/illustration/payment-m.svg';
import TermConditionIcon from '../../assets/images/illustration/term-condition.svg';
import ArrowIcon from '../../assets/images/icon/chevron-blue.svg';

//Constants
import * as Constants from '../../utilities/constants';

class ConfigTimeline extends Component {

    handleOnClick = (type, typeIndex) => event => {
        let { timeline_count } = this.props;
        if (timeline_count >= typeIndex) {
            this.props.modifyProgramSelection(type);
        }
    }

    render() {
        let { basicDetailsStyle, insurersStyle, agentsStyle, apiDetailsStyle } = '';
        let { basicActive, insurersActive, agentsActive, apiActive } = '';
        let { timeline_count, buttonState, configSelection, isFinish } = this.props;
        let ToggleBtnArrow = buttonState ? "toggle-arrow" : "";
        let heightOfActiveBar = 1;
        let indicatorWidth = 16.66;
        let indicatorCount = 0;

        console.log("isFinished", isFinish, "timeline_count", timeline_count);
        //This Switch statement is used to highlight the current selected Program.
        switch (timeline_count) {
            case 1:
                heightOfActiveBar *= 1;
                indicatorWidth *= 0;
                indicatorCount += 0;
                break;

            case 2:
                heightOfActiveBar *= 100;
                indicatorWidth *= 1;
                indicatorCount += 1;
                basicDetailsStyle = " prev-active "
                break;

            case 3:
                heightOfActiveBar *= 200;
                indicatorWidth *= 2;
                indicatorCount += 2;
                basicDetailsStyle = insurersStyle = " prev-active ";
                break;

            case 4:
                heightOfActiveBar *= 300;
                indicatorWidth *= 3;
                indicatorCount += 3;
                basicDetailsStyle = insurersStyle = agentsStyle = " prev-active ";
                break;

            case 5:
                heightOfActiveBar *= 400;
                indicatorWidth *= 4;
                indicatorCount += 4;
                basicDetailsStyle = insurersStyle = agentsStyle = apiDetailsStyle = " prev-active ";
                break;

            // case 6:
            //     heightOfActiveBar *= 500;
            //     indicatorWidth *= 5;
            //     indicatorCount+=5;
            //     basicDetailsStyle = insurersStyle = agentsStyle = coverageStyle = paymentStyle = " prev-active ";
            //     break;

            default: break;

        }

        switch (configSelection) {
            case "basic_Details":
                basicActive = " active "; break;
            case "insureres":
                insurersActive = " active "; break;
            case "agents":
                agentsActive = " active "; break;
            case "api_details":
                apiActive = " active "; break;
            // case "payment_methods":
            //         paymentActive = " active "; break;
            // case "terms_conditions":
            //         termsActive = " active "; break;
            default: break;
        }

        if (isFinish) {
            indicatorCount = 4;
            indicatorWidth = 100;
        }

        return (
            <section className="program-timeline-sec">
                <div className="program-timeline-wrap">
                    <div className="program-timeline-block">
                        <div className={"program-timeline-btn " + basicActive + basicDetailsStyle} onClick={this.handleOnClick(Constants.CONFIG_PROCESS.BASIC_DETAILS, 1)}>
                            <Fab variant="extended" size="small" className="btn btn-shadow btn-small btn-icon">
                                <img src={StackIcon} alt="StackIcon" />
                            </Fab>
                            {
                                !buttonState && (
                                    <Fab variant="extended" size="large" className="btn btn-shadow btn-large btn-info">
                                        Basic Details
                                    </Fab>
                                )
                            }

                        </div>
                        <div className={"program-timeline-btn " + insurersActive + insurersStyle} onClick={this.handleOnClick(Constants.CONFIG_PROCESS.INSURERS, 2)}>
                            <Fab variant="extended" size="small" className="btn btn-shadow btn-small btn-icon">
                                <img src={MasterIcon} alt="MasterIcon" />
                            </Fab>
                            {
                                !buttonState && (
                                    <Fab variant="extended" size="large" className="btn btn-shadow btn-large btn-info">
                                        Insurers
                                    </Fab>
                                )
                            }
                        </div>
                        <div className={"program-timeline-btn " + agentsActive + agentsStyle} onClick={this.handleOnClick(Constants.CONFIG_PROCESS.AGENTS, 3)}>
                            <Fab variant="extended" size="small" className="btn btn-shadow btn-small btn-icon">
                                <img src={PolicyParamIcon} alt="PolicyParamIcon" />
                            </Fab>
                            {
                                !buttonState && (
                                    <Fab variant="extended" size="large" className="btn btn-shadow btn-large btn-info">
                                        Agents
                                    </Fab>
                                )
                            }

                        </div>
                        <div className={"program-timeline-btn " + apiActive + apiDetailsStyle} onClick={this.handleOnClick(Constants.CONFIG_PROCESS.API_DETAILS, 4)}>
                            <Fab variant="extended" size="small" className="btn btn-shadow btn-small btn-icon">
                                <img src={CoverageLimitIcon} alt="CoverageLimitIcon" />
                            </Fab>
                            {
                                !buttonState && (
                                    <Fab variant="extended" size="large" className="btn btn-shadow btn-large btn-info">
                                        Api Details
                                    </Fab>
                                )
                            }
                        </div>
                        {/* <div className={"program-timeline-btn " + paymentActive + paymentStyle} onClick={this.handleOnClick(Constants.PROGRAM.PAYMENT_METHODS,5)}>
                            <Fab variant="extended" size="small" className="btn btn-shadow btn-small btn-icon">
                                <img src={PaymentMIcon} alt="PaymentMIcon"/>
                            </Fab>
                            {
                                !buttonState && (
                                    <Fab variant="extended" size="large" className="btn btn-shadow btn-large btn-info">
                                        Payment Methods
                                    </Fab>
                                )
                            }
                        </div> */}
                        {/* <div className={"program-timeline-btn " + termsActive + termsConditionStyle} onClick={this.handleOnClick(Constants.PROGRAM.TERMS_CONDITIONS,6)}>
                            <Fab variant="extended" size="small" className="btn btn-shadow btn-small btn-icon">
                                <img src={TermConditionIcon} alt="TermConditionIcon"/>
                            </Fab>
                            {
                                !buttonState && (
                                    <Fab variant="extended" size="large" className="btn btn-shadow btn-large btn-info">
                                        Terms and Conditions
                                    </Fab>
                                )
                            }
                        </div> */}
                    </div>
                    {/* <div className="program-timeline-indicator" style={{height: heightOfActiveBar+"px"}}></div> */}
                    <div className={"program-timeline-btn program-timeline-toggle " + ToggleBtnArrow} onClick={this.props.buttonClick} >
                        <Fab variant="extended" size="small" className="btn btn-shadow btn-small btn-toggle">
                            <img src={ArrowIcon} alt="ArrowIcon" />
                        </Fab>
                    </div>
                </div>
                {
                    !buttonState && (
                        <div className="program-timeline-wrap">
                            <section className="progress-bar-wrap">
                                <div className="progress-title">
                                    <div className="title">
                                        <h6 className="font-med">Status</h6>
                                    </div>
                                    <div className="indicator-status">
                                        <h6 className="font-med">{indicatorCount + "/4"}</h6>
                                    </div>
                                </div>
                                <div className="progress-bar">
                                    <div className="indicator" style={{ width: indicatorWidth + '%' }}></div>
                                </div>
                            </section>
                        </div>
                    )
                }
            </section>
        )
    }
}

export default ConfigTimeline;