import * as AjaxHelper from "../utilities/ajax-helper"
import { ApiConstants } from "./common/api-constants"

let apiBaseUrl = () => (ApiConstants.DEV_MODE === 'true' ? ApiConstants.HOST : window.location.origin) + ApiConstants.API_VERSION;

export function saveWorkflowForm(workflowFormData) {
    return AjaxHelper.post(apiBaseUrl() + ApiConstants.WORKFLOW, workflowFormData, { "Content-Type": "application/json" });
}

export function getAllWorkflow(programKey) {
    return AjaxHelper.get(apiBaseUrl() + ApiConstants.WORKFLOW + "/" + programKey + "/program", null, { "Content-Type": "application/json" });
}

export function getWorkflowByKey(key) {
    return AjaxHelper.get(apiBaseUrl() + ApiConstants.WORKFLOW + "/" + key + "/details", null, { "Content-Type": "application/json" });
}

export function updateWorkflow(workflowFormData) {
    return AjaxHelper.put(apiBaseUrl() + ApiConstants.WORKFLOW, workflowFormData, { "Content-Type": "application/json" });
}

export function updateWorkflowStatus(workflowId, workflowStatus) {
    return AjaxHelper.put(apiBaseUrl() + ApiConstants.WORKFLOW + "/" + workflowId + "/status?workflowStatus=" + workflowStatus, null, { "Content-Type": "application/json" });
}
export function duplicateWorkflow(workflowData) {
    return AjaxHelper.post(apiBaseUrl() + ApiConstants.WORKFLOW + ApiConstants.DUPLICATE, workflowData, { "Content-Type": "application/json" });
}

export function getPaymentWorkflowByFormId(formId) {
    return AjaxHelper.get(apiBaseUrl() + ApiConstants.WORKFLOW + "/" + formId + "/payment", null, { "Content-Type": "application/json" });
}

export function getLedgerWorkflowByFormId(formId) {
    return AjaxHelper.get(apiBaseUrl() + ApiConstants.WORKFLOW + "/" + formId + "/ledger", null, { "Content-Type": "application/json" });
}

