import React, { Component } from 'react';
import InputBase from '@material-ui/core/InputBase';
import { Search } from '@material-ui/icons';
import Fab from '@material-ui/core/Fab';
import Drawer from '@material-ui/core/Drawer';
import CloseIcon from '../../assets/images/icon/close.svg';
import CheckBoxField from './checkbox';
import SelectField from './select';
import InputField from './inputs';

class SearchField extends Component {

    state = {
        right: false,
    }
    toggleDrawer = (side, open) => event => {
        this.setState({ [side]: open });
    };

    render() {
        const { name, type, value, placeholder, customStyle, searchType, searchBtnClick } = this.props;

        let defaultPlaceholder = "";
        let defaultOptions = [
            {
                field_option_value: "Name",
                field_option_label: "Name"
            }
        ]
        let defaultOptions1 = [
            {
                field_option_value: "Number",
                field_option_label: "Number"
            },
            {
                field_option_value: "User",
                field_option_label: "User"
            },
            {
                field_option_value: "City",
                field_option_label: "City"
            },
            {
                field_option_value: "Country",
                field_option_label: "Country"
            }
        ]
        let defaultConditions = [
            {
                field_option_value: "Test1",
                field_option_label: "Test 1"
            },
            {
                field_option_value: "Test2",
                field_option_label: "Test 2"
            },
            {
                field_option_value: "Test3",
                field_option_label: "Test 3"
            }
        ]
        // let defaultLabel = "Single Text";    

        return (
            <div className={"search-bar " + customStyle}>
                {
                    searchType === 'normal' ? (
                        <div className="normal-search">
                            <InputBase
                                id={name}
                                className="field"
                                type={type}
                                onChange={this.props.handleOnChangeValue}
                                value={value}
                                placeholder={placeholder ? placeholder : defaultPlaceholder}
                            />
                            <Fab variant="extended" size="small" className="btn btn-small btn-plane btn-curve btn-fab transparent-color grey-color no-shadow btn-search" onClick={searchBtnClick}>
                                <Search />
                            </Fab>
                        </div>
                    ) : (
                        <div className="advance-search">
                            <Fab variant="extended" size="small" className="btn btn-small btn-plane btn-curve btn-fab transparent-color grey-color no-shadow btn-search" onClick={searchBtnClick}>
                                <Search />
                            </Fab>
                            <InputBase
                                id={name}
                                className="field"
                                type={type}
                                onChange={this.props.handleOnChangeEvent}
                                value={value}
                                placeholder={placeholder ? placeholder : defaultPlaceholder}
                            />
                            <Fab variant="extended" size="small" className="btn btn-small btn-plane btn-curve transparent-color white-color no-shadow btn-advance" onClick={this.toggleDrawer('right', true)}>
                                Advanced
                            </Fab>
                        </div>
                    )
                }
                <Drawer anchor="right" open={this.state.right} onClose={this.toggleDrawer('right', false)}>
                    <section className="advance-search-opt">
                        <div className="card-block">
                            <div className="card-header border-bottom">
                                <div className="card-title">
                                    <h4>Advanced Search</h4>
                                </div>
                                <div className="actions setup-close">
                                    <div className="btn-wrap">
                                        <Fab variant="extended" size="small" className="btn btn-small btn-fab btn-plane btn-curve transparent-color grey-color no-shadow btn-advance" onClick={this.toggleDrawer('right', false)}>
                                            <img src={CloseIcon} alt="CloseIcon"></img>
                                        </Fab>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="drawer-form-wrap">
                                    <div className="drawer-actions active">
                                        <CheckBoxField checkData={defaultOptions} />
                                        <SelectField optionsData={defaultConditions} is_search={false} />
                                        <InputField />
                                    </div>
                                    <div className="drawer-actions">
                                        <CheckBoxField checkData={defaultOptions1} checkboxFieldStyle="field-col-1" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </Drawer>
                {/* <img src={searchIcon} alt="search"></img> */}
            </div>
        )
    }
}
SearchField.defaultProps = {
    searchType: 'normal',
    customStyle: ''
}

export default SearchField;