import React, { Component } from 'react';
import MandatoryValidator from '../validators/mandatory-validator';
import { TextField, withStyles } from '@material-ui/core';
//import 'react-phone-input-2/dist/style.css';
import InputLabel from '@material-ui/core/InputLabel';
import Description from '../validators/descriptions';
import PhoneInput from 'react-phone-input-2'

//Custom 
import ErrorMessage from '../common/error-message';

//Validation
import * as ErrorValidation from '../validators/error-validator';

const styles = theme => ({
  field: {
    margin: '10px 0',
  },
  countryList: {
    ...theme.typography.body1,
  },
});

class PhoneField extends Component {

  constructor(props) {
    super(props)
    this.state = {
      phone: '',
      errorStates: [],
    }
  }

  handleOnChange = (value, data) => {
    let errors = ErrorValidation.updateErrorMessage(value, this.props.field_validations, this.props.condition_script, this.props.error_message);
    this.setState({ errorStates: errors, phone: value });
    this.props.handleOnChangeValue(value, errors);
  }

  selectedCountryCode = (country_code_options) => {
    let array = [];
    let newArray = [];
    if (country_code_options) {
      array = country_code_options.split(",");
      array.forEach(arr => {
        newArray.push(arr.toLowerCase());
      })
    }
    return newArray;
  }

  render() {
    const {
      name,
      label,
      instruction,
      is_mandatory,
      disabled,
      placeholder,
      customStyle,
      value,
      defaultLabel,
      defaultField,
      country_code_options,
      default_country_code,
      selected_value,
      default_value,
    } = this.props;
    let { errorStates } = this.state;
    let isDisabled = (this.props.is_disable === 'yes');
    let field = TextField;
    console.log("default_value 444: ", default_value);
    console.log("selected_value PF", selected_value, "value", value);
    return (
      <div className={"form-row " + customStyle}>
        <InputLabel
          shrink
          htmlFor={name}
          className="label">
          {defaultLabel ? defaultLabel : label}
          <MandatoryValidator value={is_mandatory} />
        </InputLabel>
        <PhoneInput
          defaultCountry={default_country_code ? default_country_code.toLowerCase() : ''}
          country={default_country_code ? default_country_code.toLowerCase() : ''}
          //value={value?value: (selected_value ? selected_value :this.state.phone)}
          value={value ? value : (selected_value ? selected_value : (default_value ? default_value : this.state.phone))}
          placeholder={placeholder ? placeholder : ""}
          onChange={this.handleOnChange}
          component={defaultField ? defaultField : field}
          onlyCountries={this.selectedCountryCode(country_code_options)}
          disabled={isDisabled ? isDisabled : disabled}
        />
        <Description value={instruction} />
        <ErrorMessage errorStates={errorStates} name={name} />
      </div>
    )
  }
}

PhoneField.defaultProps = {
  withLabel: true,
  default_value: "",
  selected_value: "",
}

export default withStyles(styles)(PhoneField);
