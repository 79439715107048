import * as AjaxHelper from "../utilities/ajax-helper"
import { ApiConstants } from "./common/api-constants"

let apiBaseUrl = () => (ApiConstants.DEV_MODE === 'true' ? ApiConstants.HOST : window.location.origin) + ApiConstants.API_VERSION;

export function getProcessDetails(processId) {
    return AjaxHelper.get(apiBaseUrl() + ApiConstants.PROCESS_PROGRESS + "/" + processId, null, { "Content-Type": "application/json" });
}

export function getPreviousAndNextData(link) {
    return AjaxHelper.get(link, null, { "Content-Type": "application/json" });
}

export function updatePriority(processId, priority) {
    return AjaxHelper.put(apiBaseUrl() + ApiConstants.PROCESS_PROGRESS + "/" + processId + "/priority?priority=" + priority, null, { "Content-Type": "application/json" });
}

export function getProcessPending(programKey) {
    return AjaxHelper.get(apiBaseUrl() + ApiConstants.PROCESS_PROGRESS + "/" + programKey + "/program", null, { "Content-Type": "application/json" });
}

export function updateMappingStatus(messageMappingIdList) {
    return AjaxHelper.put(apiBaseUrl() + ApiConstants.PROCESS_PROGRESS + "/messageMappingId", messageMappingIdList, { "Content-Type": "application/json" });
}