import React, { Component } from 'react';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Fab from '@material-ui/core/Fab';
import MenuIcon from '../../assets/images/icon/menu.svg';
import DesignIcon from '../../assets/images/icon/build-blue.svg';
import WorkflowIcon from '../../assets/images/icon/workflow-blue.svg';
import SettingIcon from '../../assets/images/icon/setting-blue.svg';
import UsersIcon from '../../assets/images/icon/users-blue.svg';
import ProgramIcon from '../../assets/images/icon/program-grey.svg';
import ProgramDashIcon from '../../assets/images/icon/square-4-blue.svg';
import SectionDashIcon from '../../assets/images/icon/square-2-blue.svg';
import AddIcon from '@material-ui/icons/Add';
import HomeIcon from '../../assets/images/icon/home-blue.svg';
import PolicyIcon from '../../assets/images/icon/policy-blue.svg';
import TaskIcon from '../../assets/images/icon/task-blue.svg';
import ArrowBackGrey from '../../assets/images/icon/chevron-left.svg';
import MultipleTabActions from '../../components/common/multiple-tab-actions';
import Dialog from '@material-ui/core/Dialog';
import closeIcon from '../../assets/images/icon/close.svg';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Sectionllus from '../../assets/images/illustration/section-circle-1.svg';
import AddFlowIllus from '../../assets/images/illustration/flow-circle-2.svg';
import AddWorkflow2Illus from '../../assets/images/illustration/form-circle-2.svg';
import UserIllus from '../../assets/images/illustration/user-circle.svg';
import Slide from '@material-ui/core/Slide';
import PrioritySelect from '../common/priority-select';
import { Redirect } from 'react-router-dom';
import ProfileOnboard from '../common/profile-onboard';
import Popover from '@material-ui/core/Popover';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import WorkflowProgramDrawer from '../workflow/workflow-program-drawer';
import ProgramShare from '../common/program-share';
import { ExpandMore } from '@material-ui/icons';
import { icons } from '../../utilities/constants';
import * as ProgramApi from '../../api/program-api'
import * as UserApi from '../../api/user-api';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            <Box p={3}>{children}</Box>
        </Typography>
    );
}

function a11yProps(index) {
    // console.log("a11yProps", index)
    return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
}

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

class WorkflowHeader extends Component {

    state = {
        value: 0,
        detailsViewlink: null,
        programListDrawer: false,
        programFilterDrawer: false,
        triggerDialogOpen: false,
        navigationData: {},
        programs: [],
        openSection: false,
        redirect: 0,
        isTop: true,
        userType: 0,
    }

    constructor(props) {
        super(props);
    }

    toggleDrawer = (side, open) => event => {
        this.setState({ [side]: open });
    };

    handleDialogOpen(type) {
        this.setState({ [type]: true });
    }

    createNewWorkflow() {
        this.handleDialogOpen("triggerDialogOpen");
    }

    handleClose = (type) => (event) => {
        this.setState({ [type]: false });
    }

    handleChange(event, newValue) {
        this.setState({ value: newValue });
        this.props.handleTabChange(newValue);
    }

    redirectLink = (link) => () => {
        window.location = link;
    }

    redirectLinkOnAddClick = (link) => () => {
        let url = window.location.href;
        let urlSplitArray = url.split('/');
        let currentUrl = "/" + urlSplitArray[urlSplitArray.length - 2] + "/" + urlSplitArray[urlSplitArray.length - 1];

        if (link === currentUrl) {
            window.location = link;
        }
        this.setState({ redirect: link, openSection: true });
    }

    redirectLinkWithProps = () => {
        if (this.state.openSection) {
            this.setState({ openSection: false })
            return <Redirect to={{
                pathname: this.state.redirect,
                state: { id: true }
            }}
            />
        }
    }

    componentDidMount() {

        document.addEventListener('scroll', () => {
            const isTop = window.scrollY < 64;
            if (isTop !== this.state.isTop) {
                this.setState({ isTop })
            }
        });

        ProgramApi.getAllProgramStates().then(result => {
            this.setState({ programs: result });
        })

        if (this.props.workflowType === "normal" || this.props.workflowType === "dashboard") {
            ProgramApi.getNavigationData(this.props.programKey).then(result => {
                this.setState({ navigationData: result });
            })
        }

        if (this.props.workflowType === "details-view" || this.props.workflowType === "dashboard") {
            UserApi.getLoggedInUser().then(data => {
                this.setState({ userType: data.user_type });
            })
        }
    }

    componentWillUnmount() {
        window.onscroll = null;
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.selectedSectionIndex !== this.props.selectedSectionIndex) {
            this.setState({ value: nextProps.selectedSectionIndex });
        }
    }

    ProgramListClick(event) {
        this.setState({ anchorEl: event.currentTarget });
    }

    ProgramListClose() {
        this.setState({ anchorEl: !this.state.anchorEl });
    }

    doneChange() {
        let clientId = this.props.clientId;
        let programKey = this.props.programKey;
        console.log("doneChange", window.location.pathname.split("/"));
        let urlSplit = window.location.pathname.split("/");
        let redirectLink;
        switch (urlSplit[3]) {
            case "section": redirectLink = "/" + clientId + "/" + programKey + "/section-dashboard"; break;
            case "workflow": redirectLink = "/" + clientId + "/" + programKey + "/workflows-dashboard"; break;
        }
        window.location = redirectLink;
    }

    render() {
        let { clientId, programKey, tab, workflowType, customStyle, sectionData, componentName, insideValidation, isFormEdit } = this.props;
        let { value, triggerDialogOpen, programs, anchorEl, userType } = this.state;

        let workflowLink = "/" + clientId + "/" + programKey + "/workflows-dashboard";
        let formLink = "/" + clientId + "/" + programKey + "/forms-dashboard";
        let programLink = "/" + clientId + "/" + programKey + "/program-dashboard";
        let sectionLink = "/" + clientId + "/" + programKey + "/section-dashboard";
        let dashboard = "/" + clientId + "/" + programKey + "/dashboard";
        let userLink = "/" + clientId + "/" + programKey + "/users-dashboard";
        let settingLink = "/" + clientId + "/" + programKey + "/setting";
        let configLink = "/" + clientId + "/" + programKey + "/config-dashboard";
        const open = Boolean(anchorEl);
        const id = open ? 'simple-popper' : undefined;
        console.log("isFormEdit", isFormEdit);
        return (
            <div className={"workflow-header-wrap " + customStyle} >
                {this.redirectLinkWithProps()}
                {

                    workflowType === 'normal' ? (
                        <section className="workflow-header">
                            <Toolbar>
                                <div className="workflow-nav">
                                    <div className="workflow-mega-wrap">
                                        <div className="divider">
                                            <IconButton onClick={this.toggleDrawer('programListDrawer', true).bind(this)}>
                                                <img alt="" src={MenuIcon} />
                                            </IconButton>
                                            <Fab variant="extended" size="large" className="btn btn-plane workflow-menu-btn no-shadow" onClick={this.toggleDrawer('programFilterDrawer', true).bind(this)}>
                                                <h6><span className="app-name">{this.props.programName || this.state.navigationData.program_name}</span> <span className="comp-name">{componentName}</span></h6>
                                                <ExpandMore />
                                            </Fab>
                                        </div>
                                        <Fab variant="extended" size="small" className="btn btn-small btn-plane btn-fab workflow-add-btn no-shadow" onClick={this.createNewWorkflow.bind(this)}>
                                            <AddIcon />
                                        </Fab>
                                    </div>
                                </div>
                                {!insideValidation &&
                                    <div className="workflow-nav workflow-form-nav-x">
                                        <div className="workflow-mega-switch">
                                            {/* <Fab variant="extended" size="large" className={"btn workflow-switch-btn no-shadow"+(tab==="program" ? " active": "")} onClick={this.redirectLink("")}>
                                            <img alt="DesignIcon" class="icon" src={DesignIcon}/>
                                            Program
                                        </Fab>
                                        <Fab variant="extended" size="large" className={"btn workflow-switch-btn no-shadow"+(tab==="form" ? " active": "")} onClick={this.redirectLink(formLink)}>
                                            <img alt="DesignIcon" class="icon" src={DesignIcon}/>
                                            Build
                                        </Fab>
                                        <Fab variant="extended" size="large" className={"btn workflow-switch-btn no-shadow"+(tab==="workflow" ? " active" : "")} onClick={this.redirectLink(workflowLink)}>
                                            <img alt="WorkflowIcon" class="icon" src={WorkflowIcon}/>
                                            Flow
                                        </Fab>
                                        <Fab variant="extended" size="large" className={"btn workflow-switch-btn no-shadow"+(tab==="users" ? " active" : "")} onClick={this.redirectLink("")}>
                                            <img alt="SettingIcon" class="icon" src={UsersIcon}/>
                                            Users
                                        </Fab>
                                        <Fab variant="extended" size="large" className={"btn workflow-switch-btn no-shadow"+(tab==="setting" ? " active" : "")} onClick={this.redirectLink("")}>
                                            <img alt="SettingIcon" class="icon" src={SettingIcon}/>
                                            Setting
                                        </Fab> */}
                                            <Tabs
                                                value={tab}
                                                onChange={this.handleChange.bind(this)}
                                                indicatorColor="primary"
                                                textColor="primary"
                                                variant="scrollable"
                                                scrollButtons="auto"
                                                aria-label="scrollable auto tabs"
                                            >
                                                <Tab value="program" label={
                                                    <Fab variant="extended" size="large" className={"btn workflow-switch-btn no-shadow " + (tab === "program" ? " active" : "")} onClick={this.redirectLink(programLink)}>
                                                        <img alt="ProgramDashIcon" class="icon" src={ProgramDashIcon} /> Program
                                                    </Fab>}
                                                    {...a11yProps(0)} />
                                                <Tab value="section" label={
                                                    <Fab variant="extended" size="large" className={"btn workflow-switch-btn no-shadow " + (tab === "section" ? " active" : "")} onClick={this.redirectLink(sectionLink)}>
                                                        <img alt="SectionDashIcon" class="icon" src={SectionDashIcon} /> Section
                                                    </Fab>}
                                                    {...a11yProps(1)} />
                                                <Tab value="build" label={
                                                    <Fab variant="extended" size="large" className={"btn workflow-switch-btn no-shadow" + (tab === "build" ? " active" : "")} onClick={this.redirectLink(formLink)}>
                                                        <img alt="DesignIcon" class="icon" src={DesignIcon} /> Build
                                                    </Fab>}
                                                    {...a11yProps(2)} />
                                                <Tab value="workflow" label={
                                                    <Fab variant="extended" size="large" className={"btn workflow-switch-btn no-shadow" + (tab === "workflow" ? " active" : "")} onClick={this.redirectLink(workflowLink)}>
                                                        <img alt="WorkflowIcon" class="icon" src={WorkflowIcon} /> Flow
                                                    </Fab>} {...a11yProps(3)} />
                                                <Tab value="users" label={
                                                    <Fab variant="extended" size="large" className={"btn workflow-switch-btn no-shadow" + (tab === "users" ? " active" : "")} onClick={this.redirectLink(userLink)}>
                                                        <img alt="SettingIcon" class="icon" src={UsersIcon} /> Users
                                                    </Fab>} {...a11yProps(4)} />
                                                <Tab value="setting" label={
                                                    <Fab variant="extended" size="large" className={"btn workflow-switch-btn no-shadow" + (tab === "setting" ? " active" : "")} onClick={this.redirectLink(settingLink)}>
                                                        <img alt="SettingIcon" class="icon" src={SettingIcon} /> Setting
                                                    </Fab>} {...a11yProps(5)} />
                                                <Tab value="config" label={
                                                    <Fab variant="extended" size="large" className={"btn workflow-switch-btn no-shadow" + (tab === "config" ? " active" : "")} onClick={this.redirectLink(configLink)}>
                                                        <img alt="SettingIcon" class="icon" src={SettingIcon} /> Config
                                                    </Fab>} {...a11yProps(6)} />
                                            </Tabs>
                                        </div>
                                    </div>
                                }
                                <div className="workflow-nav">
                                    <div className="workflow-mega-wrap">

                                        {!insideValidation &&
                                            <div>
                                                <ProgramShare />
                                            </div>
                                        }
                                        {/* <Fab variant="extended" size="small" className="btn btn-plane btn-small secondary-color no-shadow workflow-setting-btn">
                                            Preview
                                        </Fab> &nbsp; */}
                                        {!insideValidation &&
                                            <Fab variant="extended" size="small" className="btn btn-plane btn-small no-shadow no-shadow workflow-setting-btn" onClick={this.redirectLink(dashboard)}>
                                                Access
                                            </Fab>
                                        }

                                        {insideValidation &&
                                            <div>
                                                <Fab variant="extended" size="small" className="btn btn-small btn-plane transparent-color no-shadow" onClick={this.props.settingChange}>
                                                    <img alt="SettingIcon" class="icon" src={SettingIcon} />
                                                    Setting
                                                </Fab> &nbsp;&nbsp;
                                                <Fab variant="extended" size="small" className="btn btn-small btn-plane primary-color no-shadow" onClick={this.doneChange.bind(this)}>
                                                    Done
                                                </Fab> &nbsp;&nbsp;
                                            </div>
                                        }
                                    </div>
                                </div>
                            </Toolbar>
                            <WorkflowProgramDrawer toggleDrawer={this.toggleDrawer.bind(this)} dashboardBackKey={insideValidation ? "workspace" : "dashboard"} insideBlock={this.props.insideBlock} programKey={this.props.programKey} clientId={this.props.rclientId} programListDrawer={this.state.programListDrawer} programs={programs} />
                            <WorkflowProgramDrawer toggleDrawer={this.toggleDrawer.bind(this)} programName={this.props.programName} programFilterDrawer={this.state.programFilterDrawer} navigationData={this.state.navigationData} />
                        </section>
                    ) : workflowType === 'dashboard' ? (
                        <section className={"workflow-header workflow-sticky " + (this.state.isTop ? '' : 'header-stick')}>
                            <Toolbar>
                                <div className="workflow-nav-tabs-wrap">
                                    <div className="workflow-nav">
                                        <div className="workflow-mega-wrap">
                                            <div className="divider">
                                                {
                                                    userType !== 2 ?
                                                        <Fab variant="extended" size="large" className="btn btn-plane workflow-menu-btn no-shadow" onClick={this.toggleDrawer('programListDrawer', true).bind(this)}>
                                                            {/* <IconButton> */}
                                                            <img alt="ProgramIcon" src={ProgramIcon} /> &nbsp;&nbsp;
                                                            {/* </IconButton> */}
                                                            <h6><span className="app-name">{this.state.navigationData.program_name}</span></h6>
                                                            <ExpandMore />
                                                        </Fab>
                                                        : ""
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    {!this.props.detailsView &&
                                        <div className="workflow-nav workflow-tab-nav">
                                            <div className="workflow-mega-switch">
                                                <Tabs
                                                    value={value}
                                                    onChange={this.handleChange.bind(this)}
                                                    indicatorColor="primary"
                                                    textColor="primary"
                                                    variant="scrollable"
                                                    scrollButtons="auto"
                                                    aria-label="scrollable auto tabs example"
                                                >
                                                    {/* <Tab label={
                                                        <Fab variant="extended" size="large" className={"btn workflow-switch-btn no-shadow" + (tab === "home" ? " active" : "")} >
                                                            <img alt="HomeIcon" class="icon" src={HomeIcon} /> Home
                                                        </Fab>}
                                                        {...a11yProps(0)} />  */}
                                                    {sectionData.map((section, index) => (
                                                        <Tab value={section.id} label={
                                                            <Fab variant="extended" size="large" className={"btn workflow-switch-btn no-shadow" + (tab === "policy" ? " active" : "")} >
                                                                <img alt="DesignIcon" class="icon" src={section.icon_name == null ? icons.i_company.icon : icons[section.icon_name].icon} /> {section.section_name}
                                                            </Fab>}
                                                            {...a11yProps(index)} />
                                                    ))}

                                                    {/* {
                                                        [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19].map(field=>{
                                                            return ( */}
                                                    <Tab label={
                                                        <Fab variant="extended" size="large" className={"btn workflow-switch-btn no-shadow" + (tab === "actions" ? " active" : "")}>
                                                            <img alt="SettingIcon" class="icon" src={TaskIcon} /> Actions
                                                        </Fab>} {...a11yProps(sectionData.length)} />
                                                    {/* )  
                                                        })
                                                    } */}
                                                    {/* <Tab label={
                                                    <Fab variant="extended" size="large" className={"btn workflow-switch-btn no-shadow"+(tab==="reports" ? " active": "")} >
                                                        <img alt="SettingIcon" class="icon" src={ReportIcon}/> Reports
                                                    </Fab> } {...a11yProps(4)} /> */}
                                                </Tabs>
                                            </div>
                                        </div>

                                    }
                                    <div className="workflow-nav workflow-nav-setting">
                                        <div className="workflow-mega-wrap">
                                            {!this.props.detailsView &&
                                                <Fab variant="extended" size="small" className="btn btn-small btn-plane btn-fab workflow-add-btn no-shadow" onClick={this.ProgramListClick.bind(this)}>
                                                    <AddIcon />
                                                </Fab>
                                            }
                                            {/* <Menu
                                            id="profile-menu"
                                            anchorEl={detailsViewlink}
                                            open={Boolean(detailsViewlink)}
                                            onClick={this.menuClose('detailsViewlink')}
                                            >
                                            {sectionData.map( (section, index) => (
                                                <MenuItem  onClick= { (event) =>  this.handleChange(event , section.id)  }  >
                                                    <img alt="DesignIcon" class="icon" src={PolicyIcon} /> {section.section_name}</MenuItem>
                                            ))}
                                        </Menu> */}
                                            <Popover
                                                id={id}
                                                open={open}
                                                anchorEl={anchorEl}
                                                onClose={this.ProgramListClose.bind(this)}
                                                anchorOrigin={{
                                                    vertical: 'bottom',
                                                    horizontal: 'center',
                                                }}
                                                transformOrigin={{
                                                    vertical: 'top',
                                                    horizontal: 'center',
                                                }}
                                            >
                                                <div className="program-popover-wrap">
                                                    {sectionData.map((section, index) => (
                                                        <Fab variant="extended" size="small"
                                                            className="btn btn-plane workflow-menu-btn white-color no-shadow"
                                                            onClick={() => { this.props.handleRedirect(section.id); this.ProgramListClose() }}>
                                                            <img alt="DesignIcon" class="icon" src={section.icon_name == null ? icons.i_company.icon : icons[section.icon_name].icon} /> {section.section_name}
                                                        </Fab>
                                                    ))}
                                                </div>
                                            </Popover>
                                            {
                                                !this.state.isTop && (
                                                    <div className="notify-aside notify-profile">
                                                        <ProfileOnboard programKey={this.props.programKey} />
                                                    </div>
                                                )
                                            }

                                        </div>
                                    </div>
                                </div>

                            </Toolbar>
                            <WorkflowProgramDrawer userType={this.state.userType} toggleDrawer={this.toggleDrawer.bind(this)} programListDrawer={this.state.programListDrawer} programs={this.state.programs} dashboardBackKey={"workspace"} programKey={this.props.programKey} insideBlock={this.props.insideBlock} />
                        </section>
                    ) : workflowType === 'form-build-up' ? (
                        <section className="workflow-header workflow-sticky workflow-form-header">
                            <Toolbar>
                                <div className="workflow-nav">
                                    <div className="workflow-mega-wrap">
                                        <Fab variant="extended" size="small" className="btn btn-small btn-plane grey-color transparent-color no-shadow go-back" onClick={isFormEdit ? this.props.onCancelAfterEdit : (this.props.redirectBackToDashboard ? this.props.handleRedirectBackToDashboard : this.props.onClickChange)}>
                                            <img alt="edit" src={ArrowBackGrey} style={{ margin: 0 }}></img> Go Back
                                        </Fab>
                                        <div className="workflow-form-title">
                                            <h4>{this.props.formName}</h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="workflow-nav">
                                    <div className="workflow-mega-wrap">
                                        <Fab variant="extended" size="small" className="btn btn-small btn-plane grey-color no-shadow" onClick={isFormEdit ? this.props.onCancelAfterEdit : (this.props.redirectBackToDashboard ? this.props.handleRedirectBackToDashboard : this.props.onClickChange)}>
                                            Cancel
                                        </Fab> &nbsp;&nbsp;
                                        {!isFormEdit &&
                                            <div>
                                                <Fab variant="extended" size="small" className="btn btn-small btn-plane primary-color no-shadow" disabled={this.props.disabled} onClick={this.props.onClickSaveNew}>
                                                    Save and New
                                                </Fab> &nbsp;&nbsp;
                                            </div>
                                        }
                                        <Fab variant="extended" size="small" className="btn btn-small btn-plane primary-color no-shadow" disabled={this.props.disabled} onClick={this.props.onClickSave}>
                                            Save
                                        </Fab>
                                    </div>
                                </div>
                            </Toolbar>
                        </section>
                    ) : workflowType === 'details-view' ? (
                        <section className="workflow-header details-view-header workflow-sticky workflow-form-header">
                            <Toolbar>
                                <div className="workflow-nav">
                                    <div className="workflow-mega-wrap">
                                        <Fab variant="extended" size="small" className="btn btn-small btn-plane grey-color transparent-color no-shadow go-back" onClick={this.props.onClickChange(true)}>
                                            <img alt="edit" src={ArrowBackGrey} style={{ margin: 0 }}></img> Go Back
                                        </Fab>
                                        <div className="workflow-form-title">
                                            <h4>{this.props.idFieldName}</h4>
                                        </div>
                                        <div className="workflow-form-title">
                                            {
                                                userType !== 2 &&
                                                <PrioritySelect handlePriority={this.props.handleSetPriority().bind(this)} currentPriority={this.props.currentPriority} />
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="workflow-nav">
                                    <div className="workflow-mega-wrap">
                                        <MultipleTabActions type="icons" userType={this.state.userType} iconList={this.props.tabActions} paginationChange={this.props.paginationChange().bind(this)} handelDuplicateClick={this.props.duplicateRecord} handleEditClick={this.props.redirectOnEditClick} handleDeleteClick={this.props.handleDeleteClick} />
                                    </div>
                                </div>
                            </Toolbar>
                        </section>
                    ) : ""
                }

                {/* Dailog for trigger type */}
                <Dialog open={triggerDialogOpen} onClose={this.handleClose("triggerDialogOpen")} TransitionComponent={Transition} maxWidth="xl">
                    <section className="workflow-dialog workflow-dialog-large">
                        <div className="card-block">
                            <div className="card-header border-bottom">
                                <div className="sub-title">
                                    <h5>Component Creator</h5>
                                </div>
                                <div className="close-dialog">
                                    <img src={closeIcon} alt="" onClick={this.handleClose("triggerDialogOpen")}></img>
                                </div>
                            </div>
                            <div className="card-body">
                                <Grid container>
                                    <Grid item md={4}>
                                        <div className="sub-title">
                                            <h2>Do you want to create a new Component ?</h2>
                                        </div>
                                    </Grid>
                                </Grid>
                                <div className="workflow-dialog-options">
                                    <div className="worflow-dialog-wrap">
                                        <div className="workflow-dialog-img">
                                            <Button variant="contained" color="primary" onClick={this.redirectLinkOnAddClick(sectionLink)}>
                                                <img src={Sectionllus} alt="Sectionllus"></img>
                                            </Button>
                                        </div>
                                        <h5>Section</h5>
                                    </div>
                                    <div className="worflow-dialog-wrap">
                                        <div className="workflow-dialog-img">
                                            <Button variant="contained" color="primary" onClick={this.redirectLinkOnAddClick(formLink)}>
                                                <img src={AddWorkflow2Illus} alt="AddWorkflow2Illus"></img>
                                            </Button>
                                        </div>
                                        <h5>Forms</h5>
                                    </div>
                                    <div className="worflow-dialog-wrap">
                                        <div className="workflow-dialog-img">
                                            <Button variant="contained" color="primary" onClick={this.redirectLinkOnAddClick(workflowLink)}>
                                                <img src={AddFlowIllus} alt="AddFlowIllus"></img>
                                            </Button>
                                        </div>
                                        <h5>Flow</h5>
                                    </div>
                                    <div className="worflow-dialog-wrap">
                                        <div className="workflow-dialog-img">
                                            <Button variant="contained" color="primary" onClick={this.redirectLink(userLink)}>
                                                <img src={UserIllus} alt="UserIllus"></img>
                                            </Button>
                                        </div>
                                        <h5>User</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </Dialog>
            </div>
        )
    }
}

WorkflowHeader.defaultProps = {
    workflowType: 'normal',
    customStyle: '',
    sectionData: [],
    tabActions: ["message", "edit", "duplicate", "delete", "pagination"],
}

export default WorkflowHeader;