import React, { Component } from 'react';

import Fab from '@material-ui/core/Fab';
import SelectField from '../fields/select';
import deleteIcon from '../../assets/images/icon/delete-red.svg';
import Radio from '@material-ui/core/Radio';
import InputField from '../fields/inputs';
import DropdownPopover from '../../components/common/dropdown-popover';

import * as Function from '../../utilities/common-utils';

//Field Value
import FieldValueComponent from '../common/field-value-component';

//Constants
import * as Constants from '../../utilities/constants';

class FieldOperation extends Component {
    state = {
        anchorElem: '',
        anchorElemRecord: '',
        indexValueOfPopover: 0,
    }

    handleCondFieldChange(fieldName, value, errors) {
        this.setState({ fieldDisable: false })
        let { workflowData, fieldOperation, fieldList } = this.props;
        console.log("fieldOperation444", fieldOperation, "fieldName", fieldName, "value", value);
        if (fieldName === "radioType") {
            fieldOperation.field_value = "";
            fieldOperation.value_type = value;
        } else {

            if (fieldName === "field_value") {
                switch (fieldOperation.field_type) {
                    case Constants.FieldIds.F_DATE.code:
                        fieldOperation[fieldName] = new Date(value).toISOString();
                        break;
                    case Constants.FieldIds.F_TIME.code:
                        value = new Date(value);

                        value.setDate(1);
                        value.setMonth(1);
                        value.setFullYear(1970);
                        value.setSeconds(0, 0);
                        fieldOperation[fieldName] = value.toISOString();
                        break;

                    case Constants.FieldIds.F_DATE_TIME.code:
                        value = new Date(value);
                        value.setSeconds(0, 0);
                        fieldOperation[fieldName] = value.toISOString();

                        break;
                    default:
                        fieldOperation[fieldName] = value;
                }
            }
            else {
                fieldOperation[fieldName] = value;
            }



            //fieldOperation["value_type"] = "value";
        }
        fieldOperation["workflow_id"] = workflowData.id;

        //getting field type on the basis of field ID
        if (fieldName === "field_id") {
            let selectedField = fieldList.filter(field => {
                return (field.field_option_value === value)
            });
            fieldOperation["field_type"] = selectedField[0].field_type;
        }

        //getting field conditions on the basis of field type
        let formFieldDetails = this.props.formFieldDetails;
        formFieldDetails.forEach(field => {
            if (field.field_code_txt === fieldOperation.field_type) {
                fieldOperation["field_conditions"] = field.field_conditions;
            }
        })

        //Set CheckNull attribute for isNull and isNotNull
        if (fieldName === 'operation') {
            if (fieldOperation.operation === "isNull" || fieldOperation.operation === "isNotNull") {
                fieldOperation["checkNull"] = true;
            }
            else {
                fieldOperation["checkNull"] = false;
            }
        }
        this.props.handleFieldOperationUpdate(fieldOperation);
    }

    handleDeleteOperation = (index) => (event) => {
        let { stepData } = this.props;
        stepData.field_operations.splice(index, 1);
        this.props.handleStepDataUpdate(stepData);
    }

    handleOperationUpdated(index, fieldName, value, errors) {
        let { stepData, fieldList, formFieldDetails } = this.props;
        console.log("stepData555", stepData);
        if (fieldName === "radioType") {
            stepData.field_operations[index].field_value = "";
            stepData.field_operations[index].value_type = value;
        } else {
            if (fieldName === "field_value") {
                switch (stepData.field_operations[index].field_type) {
                    case Constants.FieldIds.F_DATE.code:
                        stepData.field_operations[index][fieldName] = new Date(value).toISOString();
                        break;
                    case Constants.FieldIds.F_TIME.code:
                        value = new Date(value);

                        value.setDate(1);
                        value.setMonth(1);
                        value.setFullYear(1970);
                        value.setSeconds(0, 0);
                        stepData.field_operations[index][fieldName] = value.toISOString();
                        break;

                    case Constants.FieldIds.F_DATE_TIME.code:
                        value = new Date(value);
                        value.setSeconds(0, 0);
                        stepData.field_operations[index][fieldName] = value.toISOString();

                        break;
                    default:
                        stepData.field_operations[index][fieldName] = value;
                }
            }
            else {
                stepData.field_operations[index][fieldName] = value;
            }

            //stepData.field_operations[index][fieldName] = value;
            //stepData.field_operations[index].value_type = value;
        }
        if (fieldName === 'field_id') {
            fieldList.forEach(list => {
                if (list.field_option_value === value) {
                    stepData.field_operations[index].field_type = list.field_type;
                }
            })

            formFieldDetails.forEach(field => {
                if (field.field_code_txt === stepData.field_operations[index].field_type) {
                    stepData.field_operations[index].field_conditions = field.field_conditions;
                }
            })
        }
        //Updating the attribute value CheckNull
        if (stepData.field_operations[index].operation === 'isNull' || stepData.field_operations[index].operation === 'isNotNull') {
            stepData.field_operations[index].checkNull = true;
        }
        else {
            stepData.field_operations[index].checkNull = false;
        }
        this.props.handleStepDataUpdate(stepData);
    }

    handleNextOprClick = (condition) => (event) => {
        let stepData = this.props.stepData;

        let fieldOperation = this.props.fieldOperation;
        if (fieldOperation.field_id && fieldOperation.operation) {
            fieldOperation["next_operation"] = condition;
            if (!stepData.field_operations) {
                stepData.field_operations = [];
            }
            fieldOperation["position"] = stepData.field_operations.length;
            stepData.field_operations.push(fieldOperation);

            fieldOperation = {};
            this.setState({ fieldDisable: true });

            this.props.handleStepDataUpdate(stepData);
            this.props.handleFieldOperationUpdate(fieldOperation);
        }

    }

    handleOnChangeEventFunc = (index, fieldName) => (value, errors) => {
        this.handleOperationUpdated(index, "value_type", "value");
        this.handleOperationUpdated(index, "field_value", value);
    }

    handleCondFieldChangeEventFunc = (fieldName) => (value, errors) => {
        this.handleCondFieldChange("value_type", "value");
        this.handleCondFieldChange("field_value", value);
    }

    handleFieldSelected(field) {
        console.log("field2233", field);
        let { fieldOperation } = this.props;

        fieldOperation["field_value"] = field.field_key;
        switch (field.fieldType) {
            case Constants.DATA_TYPE.FORM_DATA: {
                fieldOperation["value_type"] = "field";
                break;
            }
            case Constants.DATA_TYPE.PROGRAM_DATA: {
                fieldOperation["value_type"] = "program";
                break;
            }
            case Constants.DATA_TYPE.SECTIONAL_DATA: {
                fieldOperation["value_type"] = "sectional";
                break;
            }
        }

        this.props.handleFieldOperationUpdate(fieldOperation);
    }

    handleCloseOfFieldDropdown = () => () => {
        this.setState({ anchorElem: '', anchorElemRecord: '' });
    }

    profileUserClick(event) {
        this.setState({ anchorElem: event.currentTarget });
    }
    // profileUserClose() {
    //     this.setState({anchorEl: !this.state.anchorEl});
    // }
    handleInputPopover = () => () => {

    }

    handleFieldSelectedRecord(field, index) {
        let { stepData } = this.props;

        console.log("handleFieldSelectedRecord", field);
        stepData.field_operations[index]["field_value"] = field.field_key;
        switch (field.fieldType) {
            case Constants.DATA_TYPE.FORM_DATA: {
                stepData.field_operations[index]["value_type"] = "field";
                break;
            }
            case Constants.DATA_TYPE.PROGRAM_DATA: {
                stepData.field_operations[index]["value_type"] = "program";
                break;
            }
            case Constants.DATA_TYPE.SECTIONAL_DATA: {
                stepData.field_operations[index]["value_type"] = "sectional";
                break;
            }
        }
        this.props.handleStepDataUpdate(stepData);
    }

    profileUserClickRecord(event, index) {
        this.setState({ anchorElemRecord: event.currentTarget, indexValueOfPopover: index });
    }

    render() {
        let { fieldList, stepData, fieldOperation, fieldDataList, parentFieldList, programDataLinkList, sectionFormData } = this.props;
        console.log("qwerty data", stepData);
        let fieldDisable = true;
        if (fieldOperation.field_id) {
            fieldDisable = false;
        }
        else {
            fieldDisable = true;
        }

        let check = true;
        let option = false;
        if (!(Function.isEmpty(fieldOperation)) && fieldOperation.hasOwnProperty('field_conditions')) {
            option = true;
        }
        let operations = [
            {
                field_option_label: "And",
                field_option_value: "and",
            },
            {
                field_option_label: "Or",
                field_option_value: "or"
            }
        ];

        let dataList = {};
        //Adding differentiation for field type i.e. formField, programField and sectionalField
        parentFieldList.map(field => field.fieldType = Constants.DATA_TYPE.FORM_DATA);
        programDataLinkList && programDataLinkList.map(field => field.fieldType = Constants.DATA_TYPE.PROGRAM_DATA);
        sectionFormData && sectionFormData.map(field => field.fieldType = Constants.DATA_TYPE.SECTIONAL_DATA);

        if (parentFieldList && parentFieldList.length > 0) {
            dataList["Form Fields"] = parentFieldList;
        }
        if (programDataLinkList && programDataLinkList.length > 0) {
            dataList["Program Data"] = programDataLinkList;
        }
        if (sectionFormData && sectionFormData.length > 0) {
            dataList["Sectional Form Fields"] = sectionFormData;
        }
        return (
            <div className="actions-prop-wrap">
                {
                    stepData &&
                    stepData.field_operations.map((field_operation, index) => {
                        return (
                            <div className="actions-prop-row flex-wrap">
                                <div className={"actions-prop-row-full choice-row field-value-choice field-value-choice-double"}>
                                    <SelectField withLabel={false} optionsData={fieldList}
                                        selected_value={field_operation.field_id}
                                        radioChangeHandle={(value, errors) => this.handleOperationUpdated(index, "field_id", value, errors)} />
                                    <SelectField withLabel={false} optionsData={field_operation.field_conditions ? field_operation.field_conditions : []}
                                        selected_value={field_operation.operation}
                                        radioChangeHandle={(value, errors) => this.handleOperationUpdated(index, "operation", value, errors)} />

                                    <div className="form-row">
                                        <div className="field-value-combine">
                                            {/* {parentFieldList.length > 0 && */}

                                            <Radio
                                                onChange={(errors) => this.handleOperationUpdated(index, "radioType", "value")}
                                                checked={field_operation.value_type === "value" ? true : false}
                                            />

                                            {/* } */}

                                            <FieldValueComponent
                                                fieldLabel="&nbsp;"
                                                fieldDataList={fieldDataList}
                                                fieldOperation={field_operation.value_type === "value" ? field_operation : {}}
                                                index={index}
                                                check={field_operation}
                                                handleOperationUpdated={this.handleOnChangeEventFunc}
                                                handleCondFieldChange={this.handleCondFieldChangeEventFunc} />
                                        </div>
                                        {
                                            // parentFieldList.length > 0 &&
                                            (
                                                <div className="field-value-combine">
                                                    <Radio
                                                        onChange={(errors) => this.handleOperationUpdated(index, "radioType", "field")}
                                                        checked={field_operation.value_type === "field" || field_operation.value_type === "program" || field_operation.value_type === "sectional" ? true : false}
                                                    />
                                                    {/* <SelectField
                                                        label="Field Name"
                                                        withLabel= {true}
                                                        disabled={field_operation.value_type === "value" ? true :field_operation.checkNull}
                                                        optionsData={parentFieldList}
                                                        selected_value={field_operation.value_type === "field" ? field_operation.field_value : []}
                                                        default_value={field_operation.field_value}
                                                        radioChangeHandle={(value, errors) => {this.handleOperationUpdated(index, "value_type", "field");this.handleOperationUpdated(index, "field_value", value)}}
                                                    /> */}

                                                    <div style={{ width: "100%" }}>
                                                        <InputField
                                                            placeholder="Search fields"
                                                            label="&nbsp; "
                                                            //default_value = {this.props.selectedIcon.text}
                                                            disabled={field_operation.value_type === "value" ? true : field_operation.checkNull}
                                                            default_value={field_operation.value_type === "field" || field_operation.value_type === "program" || field_operation.value_type === "sectional" ? field_operation.field_value : ""}
                                                            selected_value={field_operation.value_type === "field" || field_operation.value_type === "program" || field_operation.value_type === "sectional" ? field_operation.field_value : ""}
                                                            handleOnClickEvent={(event) => this.profileUserClickRecord(event, index)}
                                                            handleOnChangeValue={this.handleInputPopover.bind(this)}
                                                            fieldStyle="field-box field-adornment bg-plane"
                                                        />
                                                        <DropdownPopover
                                                            fieldStyle="field-box bg-plane field-icon"
                                                            disabled={field_operation.value_type === "value" ? true : field_operation.checkNull}
                                                            handleFieldSelected={(field) => this.handleFieldSelectedRecord(field, this.state.indexValueOfPopover)}
                                                            anchorElem={this.state.anchorElemRecord}
                                                            dataList={dataList}
                                                            handleCloseOfFieldDropdown={this.handleCloseOfFieldDropdown().bind(this)}
                                                        />
                                                    </div>
                                                </div>
                                            )
                                        }
                                    </div>

                                    {stepData.field_operations.length > 1 &&
                                        <div className="form-row btn-actions">
                                            <Fab aria-label="delete" variant="extended" size="small" className="btn btn-small btn-fab btn-plane btn-curve" onClick={this.handleDeleteOperation(index)}>
                                                <img alt="delete" src={deleteIcon}></img>
                                            </Fab>

                                        </div>
                                    }
                                </div>
                                <div className="actions-prop-row-full">
                                    <div className="form-row select-outline select-action sort-action">
                                        <SelectField withLabel={false} optionsData={operations}
                                            selected_value={field_operation.next_operation}
                                            radioChangeHandle={(value, errors) => this.handleOperationUpdated(index, "next_operation", value, errors)} />
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }

                <div className={"actions-prop-row choice-row field-value-choice field-value-choice-double"}>
                    <SelectField
                        withLabel={false}
                        optionsData={fieldList}
                        selected_value={fieldOperation.field_id}
                        radioChangeHandle={(value, errors) => this.handleCondFieldChange("field_id", value, errors)} />
                    <SelectField
                        withLabel={false}
                        optionsData={option ? fieldOperation.field_conditions : []}
                        selected_value={fieldOperation.operation}
                        disabled={fieldDisable}
                        radioChangeHandle={(value, errors) => this.handleCondFieldChange("operation", value, errors)} />

                    <div className="form-row">
                        <div className="field-value-combine">
                            {/* {parentFieldList.length > 0 && */}

                            <Radio
                                onChange={(errors) => this.handleCondFieldChange("radioType", "value")}
                                checked={fieldOperation.value_type === "value" ? true : false}
                            />

                            {/* } */}
                            <FieldValueComponent
                                fieldLabel="&nbsp;"
                                fieldDataList={fieldDataList}
                                fieldOperation={fieldOperation.value_type === "value" ? fieldOperation : {}}  //(!(parentFieldList.length > 0) && fieldOperation.operation ? fieldOperation : {})
                                check={!check}
                                handleOperationUpdated={this.handleOnChangeEventFunc}
                                handleCondFieldChange={this.handleCondFieldChangeEventFunc} />
                        </div>
                        {
                            // parentFieldList.length > 0 &&
                            (
                                <div className="field-value-combine">
                                    <Radio
                                        onChange={(errors) => this.handleCondFieldChange("radioType", "field")}
                                        checked={fieldOperation.value_type === "field" || fieldOperation.value_type === "program" || fieldOperation.value_type === "sectional" ? true : false}
                                    />
                                    {/* <SelectField
                                            withLabel= {true}
                                            disabled={fieldOperation.value_type === "value" ? true :fieldOperation.checkNull}
                                            optionsData={parentFieldList}
                                            selected_value={fieldOperation.value_type === "field" || fieldOperation.value_type === "program" ? fieldOperation.field_value : []}
                                            default_value={fieldOperation.field_value}
                                            radioChangeHandle={(value, errors) => {this.handleCondFieldChange("value_type", "field");this.handleCondFieldChange("field_value", value)}}
                                        /> */}

                                    <div style={{ width: "100%" }}>
                                        <InputField
                                            placeholder="Search fields"
                                            label="&nbsp; "
                                            //default_value = {this.props.selectedIcon.text}
                                            disabled={fieldOperation.value_type !== "field" ? true : fieldOperation.checkNull}
                                            default_value={fieldOperation.value_type === "field" || fieldOperation.value_type === "program" || fieldOperation.value_type === "sectional" ? fieldOperation.field_value : ""}
                                            selected_value={fieldOperation.value_type === "field" || fieldOperation.value_type === "program" || fieldOperation.value_type === "sectional" ? fieldOperation.field_value : ""}
                                            handleOnClickEvent={(fieldOperation.value_type === "field" || fieldOperation.value_type === "program" || fieldOperation.value_type === "sectional") && this.profileUserClick.bind(this)}
                                            handleOnChangeValue={this.handleInputPopover.bind(this)}
                                            fieldStyle="field-box field-adornment bg-plane"
                                        />
                                        <DropdownPopover
                                            fieldStyle="field-box bg-plane field-icon"
                                            disabled={fieldOperation.value_type === "value" ? true : fieldOperation.checkNull}
                                            handleFieldSelected={(field) => this.handleFieldSelected(field)}
                                            anchorElem={this.state.anchorElem}
                                            dataList={dataList}
                                            // selectedIcon={this.state.selectedIcon} 
                                            handleCloseOfFieldDropdown={this.handleCloseOfFieldDropdown().bind(this)}
                                        />
                                    </div>
                                </div>
                            )
                        }
                    </div>

                    <div className="form-row btn-actions">
                        <Fab aria-label="delete" variant="extended" size="small" className="btn btn-small btn-fab btn-plane btn-curve" onClick={this.props.handleFieldOperationMaster}>
                            <img alt="delete" src={deleteIcon}></img>
                        </Fab>
                    </div>
                </div>
                <div className="actions-prop-row">
                    <div className="btn-wrap">
                        <Fab variant="extended" size="small" className="btn btn-small btn-fab btn-plane btn-curve" onClick={this.handleNextOprClick("and")}> AND </Fab>
                        <Fab variant="extended" size="small" className="btn btn-small btn-fab btn-plane btn-curve" onClick={this.handleNextOprClick("or")}> Or </Fab>
                    </div>
                </div>
            </div>
        )
    }
}
FieldOperation.defaultProps = {
    parentFieldList: []
}
export default FieldOperation;