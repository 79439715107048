import React, { Component } from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Description from '../validators/descriptions';
import MandatoryValidator from '../validators/mandatory-validator';
import Checkbox from '@material-ui/core/Checkbox';
import { connect } from 'react-redux';
import { createFilterOptions } from '@material-ui/lab/Autocomplete';

//Validation
import * as ErrorValidation from '../validators/error-validator';

//Custom 
import ErrorMessage from '../common/error-message';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const filterOptions = createFilterOptions({
    matchFrom: 'any',
    stringify: option => option.field_option_label,
    limit: 20
});

class AutoCompleteField extends Component {
    state = {
        errorStates: [],
        optionsDataObj: [],
    };

    componentWillReceiveProps() {
        const { optionsData } = this.props;
        if (optionsData) {
            this.generateOptionObject(optionsData)
        }
    }

    generateOptionObject(optionsData) {
        //Code for already saved selected values
        let arr = [];
        optionsData.forEach(data => {
            if (data.checked) {
                arr.push(data);
            }
        })
        this.setState({ optionsDataObj: arr })
    }

    handleMultiSelectChange = (event, value) => {
        let { optionsData } = this.props;
        var multipleString = '';
        let multipleValues = [];
        value.forEach(option => {
            multipleValues.push(option.field_option_value)
        })

        multipleString = multipleValues.join();

        optionsData.forEach(options => {
            if (multipleValues.includes(options.field_option_value)) {
                options["checked"] = true;
            }
            else {
                options["checked"] = false;
            }
        })
        let errors = ErrorValidation.updateErrorMessage(multipleString, this.props.field_validations, this.props.condition_script, this.props.error_message);
        this.setState({ errorStates: errors });
        this.props.handleMultiSelectChange(multipleString);
    }

    getOptionSelectedValue = (option, value) => {
        console.log(option, value, "multipleString");
    }

    render() {
        let {
            label,
            is_mandatory,
            instruction,
            defaultLabel,
            fieldStyle,
            customStyle,
            disabled,
            withLabel,
            optionsData
        } = this.props;
        let { errorStates, optionsDataObj } = this.state;

        let defaultFieldStyle = "field-box";
        let isDisabled = this.props.is_disable === 'yes' ? true : false;

        console.log("optionsData222 label: " + label + " ", this.props.optionsData);

        return (
            <div className={"form-row " + customStyle}>
                <div className={fieldStyle ? fieldStyle : defaultFieldStyle}>
                    {
                        withLabel ? (
                            <InputLabel
                                htmlFor={label}
                                className="label"
                            >
                                {label ? label : defaultLabel}
                                <MandatoryValidator value={is_mandatory} />
                            </InputLabel>
                        ) : null
                    }
                    <div className="field ">
                        <div className="autocomplete-field">
                            <Autocomplete
                                size="small"
                                multiple
                                disabled={disabled}
                                options={optionsData}
                                disableCloseOnSelect
                                getOptionLabel={(option) => option.field_option_label}
                                filterOptions={filterOptions}
                                value={optionsDataObj ? optionsDataObj : []}
                                onChange={this.handleMultiSelectChange}
                                renderOption={(option, { selected }) => (
                                    <React.Fragment>
                                        <Checkbox
                                            icon={icon}
                                            checkedIcon={checkedIcon}
                                            style={{ marginRight: 8 }}
                                            checked={selected}
                                        />
                                        {option.field_option_label}
                                    </React.Fragment>
                                )}
                                renderInput={(params) => (
                                    <TextField {...params} />
                                )}
                            />
                        </div>
                    </div>
                    <ErrorMessage errorStates={errorStates} name={label} />
                </div>
                <Description value={instruction} />
            </div>
        )
    }
}

AutoCompleteField.defaultProps = {
    withLabel: true,
    default_value: [],
    selected_value: [],
}

const mapStateToProps = state => ({
    userDataRecord: state.formData,
});

export default connect(mapStateToProps)(AutoCompleteField);