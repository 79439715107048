import React, { Component } from 'react';
import SearchField from '../../components/fields/search';

//Custom component
import TableComponent from '../../components/common/table-component';
import ActionsViewSetup from '../../components/actions-view/actions-view-setup';

//API
import * as ProcessApi from '../../api/process-api';

const tableRows = [
    { id: 'form_name', numeric: false, disablePadding: false, label: 'Record Name' },
    { id: 'section', numeric: false, disablePadding: false, label: 'Record Form' },
    { id: 'entries', numeric: true, disablePadding: false, label: 'Section' },
    { id: 'status', numeric: true, disablePadding: false, label: 'Current State' },
    { id: 'priority', numeric: true, disablePadding: false, label: 'Priority' },
    { id: 'created_on', numeric: true, disablePadding: false, label: 'Created Date' },
    { id: 'actions', numeric: true, disablePadding: false, label: '' },
];

function createData(index, processData, programKey) {
    let priorityList = {
        "lowest": (<span className="state-tag musturd-color">Lowest</span>),
        "high": (<span className="state-tag pink-color">High</span>),
        "normal": (<span className="state-tag cyan-color">Normal</span>),
        "highest": (<span className="state-tag dark-maroon-color">Highest</span>),
        "low": (<span className="state-tag orange-color">Low</span>)
    }
    let recordNameView = <h5 className="weight-500 text-primary-color">{processData.record_name}</h5>;
    let recordFormView = processData.sectionl_form_name;
    let sectionView = processData.section_name;
    let currentStateView = (<span><h6>{processData.current_state_name}</h6><h6>{processData.current_sub_state_name}</h6></span>);
    let priorityView = priorityList[processData.priority];
    let createdDateView = processData.created_on;
    let actionView = (<ActionsViewSetup processId={processData.process_id} programKey={programKey} process_id={processData.process_id} currentStateName={processData.current_state_name} currentSubStateName={processData.current_sub_state_name} />);

    return { recordNameView, recordFormView, sectionView, currentStateView, priorityView, createdDateView, actionView };
}
class ActionsViewScene extends Component {

    state = {
        actionTableData: [],
        tabAction: 0,
        form: {},
        customStyleSearch: 'only-small-search',
    }

    componentDidMount() {
        let tableData = [];

        ProcessApi.getProcessPending(this.props.programKey).then(processList => {
            console.log("processList888", processList);
            processList.forEach((processData, index) => {
                tableData.push(createData(index, processData, this.props.programKey));
            })
            this.setState({ actionTableData: tableData });
        })
    }

    searchBtnClick() {
        this.setState({ customStyleSearch: "" });
    }

    render() {
        let { actionTableData, customStyleSearch } = this.state;
        return (
            <div className="workflow-fields-sec workflow-fields-dashboard">
                <div className="drawer-tabs drawer-tabs-round no-drawer-indicator workflow-tabs-wrap">
                    <div className="drawer-tabs-header">
                        <div></div>
                        <div className="workflow-tabs-search only-tabs-search">
                            <SearchField placeholder="Search here" searchType="normal" customStyle={customStyleSearch} searchBtnClick={this.searchBtnClick.bind(this)} />
                        </div>
                    </div>
                    <div className="tab-container">
                        <div className="table-wrapper action-table-wrapper">
                            <TableComponent
                                tableLabel={false}
                                rows={tableRows}
                                isPagginated={true}
                                isSearchable={false}
                                data={actionTableData}
                                searchLabel="Search for Forms"
                                isCheckBoxEnabled={false}
                                isToolboxPresent={false}
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ActionsViewScene;