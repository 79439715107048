import { isValidPhoneNumber } from 'react-phone-number-input'
export function numberValidation(value) {
    let validState = { isValid: true };
    //let regex = /^\d+$/;
    let regex = /^[0-9]+([,.][0-9]+)?$/g;
    if (!regex.test(value)) //ONLY INTEGERS ARE ACCEPTED
    {
        validState.isValid = false;
        validState.message = "Number Only";
    }

    return validState;
}

export function sizeValidation(value, size) {
    let validState = { isValid: true };
    if (value.length > size) {
        validState.isValid = false;
        validState.message = "Maximum Limit " + size + " Exceeded";
    }

    return validState;
}

export function emailValidation(value) {
    console.log("email", value);
    let validState = { isValid: true };
    var regex = /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i;
    if (!regex.test(value)) {
        validState.isValid = false;
        validState.message = "Email is invalid";
    }

    return validState;
}

export function timeValidation(value, size) {
    value = new Date(value);
    let validState = { isValid: true };
    let array = size.split(",");
    if (!(value.getHours() >= array[0] && value.getHours() <= array[1])) {
        validState.isValid = false;
        validState.message = "Choose time between " + array[0] + ":00 and " + array[1] + ":00";
    }

    return validState;
}

export function phoneNumberValidation(value) {
    let validState = { isValid: true };
    if (!(isValidPhoneNumber("+" + value))) {
        validState.isValid = false;
        validState.message = "Phone Number is invalid";
    }

    return validState;

}