import React, { Component } from 'react';

//Custom
import WorkflowMaster from '../../components/workflow/workflow-master';

//API
import * as WorkflowApi from '../../api/workflow-api';
import * as FormApi from '../../api/form-api';
import * as WorkflowActionApi from '../../api/action-api';
import * as StateApi from '../../api/program-state-api';
import * as ProgramApi from '../../api/program-api';
import * as UserApi from '../../api/user-api';


class WorkflowComponent extends Component {

    state = {
        formData: {},
        workflowData: {},
        stepDataList: [],
        programState: [],
        programSubState: {},
        workflowName: "",
        paymentWorkflowList: [],
        assureGateDataList: [],
        ledgerWorkflowList: [],
        programDataLinkList: [],
        sectionFormData: [],
        permissionList: [],
        programData: [],
    }

    constructor(props) {
        super(props);
        this.getStateDetails();
        this.getWorkflowDetails();
        this.getAllPermissions();
    }

    handleStepDataListChange = () => (stepData) => {
        this.setState({ stepDataList: stepData })
    }

    saveWorkflowData(workflowData) {
        console.log(workflowData);
        WorkflowActionApi.saveWorkflowAction(workflowData).then(data => {
            this.getWorkflowDetails();
        });
    }

    deleteWorkflowData(triggerId) {
        WorkflowActionApi.deleteWorkflowActionTrigger(triggerId).then(data => {
            this.getWorkflowDetails();
        });

        //this.getWorkflowDetails()
    }

    getAllPermissions() {
        const match = this.props.match;
        let programKey = match.params.programkey;
        let permissionList = [];
        UserApi.getAllPermission(programKey).then(permissions => {
            permissions.forEach(permission => {
                let permissionObj = {
                    checked: false,
                    field_option_label: permission.permission_name,
                    field_option_value: permission.permission_set_id.toString(),
                };
                permissionList.push(permissionObj);
            })

            this.setState({ permissionList: permissionList });
        })
    }

    getWorkflowDetails() {
        const match = this.props.match;
        // let programKey = match.params.programkey;
        let workfowkey = match.params.workfowkey;
        WorkflowApi.getWorkflowByKey(workfowkey).then(workflowData => {
            this.setState({ workflowData: workflowData, workflowName: workflowData.workflow_name });
            FormApi.getFormDataSectional(workflowData.workflow_trigger.form_id).then(formData => {
                if (formData.sectional_data) {
                    this.setSectionalForOnClickForm(formData.sectional_data);
                }

                this.setState({ formData: formData.form_data });
                WorkflowApi.getPaymentWorkflowByFormId(workflowData.workflow_trigger.form_id).then(data => {
                    this.setState({ paymentWorkflowList: data });
                })
                WorkflowApi.getLedgerWorkflowByFormId(workflowData.workflow_trigger.form_id).then(data => {
                    this.setState({ ledgerWorkflowList: data });
                })

            })
            WorkflowActionApi.getWorkflowActions(workflowData.id).then(workflowAction => {
                this.setState({ stepDataList: workflowAction });
            })
        });

    }

    setSectionalForOnClickForm(sectionalData) {
        let sectionFormData = [];
        sectionalData.form_field_list.forEach(field => {
            let formObj = {
                field_key: field.field_key,
                field_option_value: field.field_key,
                field_option_label: field.label,
                field_type: field.field_type,
            }
            sectionFormData.push(formObj);

        })
        this.setState({ sectionFormData: sectionFormData });
    }

    getStateDetails() {
        const match = this.props.match;
        let programKey = match.params.programkey;
        StateApi.getAllStatesByProgramKey(programKey).then(stateData => {
            let allStateData = [];
            let subStateObj = {};
            stateData.forEach(state => {
                allStateData.push(state);
                let subStateList = [];
                state.sub_states_list.forEach(subState => {
                    subStateList.push(subState);
                })
                subStateObj[state.id] = subStateList;
            })
            ProgramApi.getProgramDataFields(programKey).then(linkData => {
                console.log("linkdata", linkData);
                linkData &&
                    linkData.forEach(data => {
                        data["field_key"] = data.field_option_value;
                    })
                this.setState({ programDataLinkList: linkData });
            })
            ProgramApi.getProgramData(programKey).then(data => {
                data.program_key = programKey;
                this.setState({ programData: data });
            })
            console.log("handleGetProgramData ", this.state.programData);
            this.setState({ programState: allStateData, programSubState: subStateObj });
        })
    }

    changeWorkflowDetails(workflowData) {
        WorkflowApi.updateWorkflow(workflowData).then(workflow => {
            this.setState({ workflowData: workflow });
            this.getWorkflowDetails();
        })

    }

    render() {
        const match = this.props.match;
        let programKey = match.params.programkey;
        let clientId = match.params.clientId;
        let { workflowData, formData, stepDataList, programState, programSubState, paymentWorkflowList, ledgerWorkflowList, programDataLinkList, sectionFormData, assureGateDataList } = this.state;
        return (
            <WorkflowMaster
                workflowData={workflowData}
                formData={formData}
                handleNewAction={this.saveWorkflowData.bind(this)}
                stepDataList={stepDataList}
                programDataLinkList={programDataLinkList}
                workflowName={this.state.workflowName}
                deleteSelectedActionTrigger={this.deleteWorkflowData.bind(this)}
                programKey={programKey}
                clientId={clientId}
                programState={programState}
                programSubState={programSubState}
                programData={this.state.programData}
                sectionFormData={sectionFormData}
                paymentWorkflowList={paymentWorkflowList}
                assureGateDataList={assureGateDataList}
                ledgerWorkflowList={ledgerWorkflowList}
                permissionList={this.state.permissionList}
                changeWorkflowDetails={(workflowData) => this.changeWorkflowDetails(workflowData)}
                handleStepDataListChange={this.handleStepDataListChange().bind(this)}
            />
        )
    }
}

export default WorkflowComponent;