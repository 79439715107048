import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { ExpandMore } from '@material-ui/icons';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import logo_img from '../assets/images/asureKit.png';
import optima from '../assets/images/optima.png';
import notificationIcon from '../assets/images/icon/notification-fill-blue.svg';
import profileIcon from '../assets/images/icon/profile-blue.svg';
import helpIcon from '../assets/images/icon/help-blue.svg';
import SearchField from '../components/fields/search';

import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';
import { Search } from '@material-ui/icons';

import HeaderNotification from './headers/header-notification';
import SearchNotification from './headers/search-notification';
import ProfileOnboard from './common/profile-onboard';

import * as ProgramApi from '../api/program-api';
import * as UserApi from '../api/user-api';

import PropTypes from 'prop-types';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import Slide from '@material-ui/core/Slide';

import * as NotificationApi from '../api/notification-api';

/* Date: 11-01-2021
 Name: Milind Sewak
 Desc: Logo Image Configuration*/
import * as Constants from '../utilities/constants';
// End

function HideOnScroll(props) {
    const { children } = props;
    // Note that you normally won't need to set the window ref as useScrollTrigger
    // will default to window.
    // This is only being set here because the demo is in an iframe.
    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 64
    });

    return (
        <Slide appear={false} direction="left" in={trigger}>
            {children}
        </Slide>
    );
}

HideOnScroll.propTypes = {
    children: PropTypes.element.isRequired,
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window: PropTypes.func,
};

class Header extends Component {
    state = {
        menulink: null,
        customStyleSearch: 'only-small-search',
        anchorEl: false,
        notificationDrawer: false,
        notification: [],
        searchDrawer: false,
        redirectTo: '/create-app'
    };

    menuOpen = ele => event => {
        this.setState({ [ele]: event.currentTarget });
    };

    menuClose = ele => event => {
        this.setState({ [ele]: null });
    };

    searchBtnClick() {
        this.setState({ customStyleSearch: "" });
    }

    toggleDrawer = (side, open) => event => {
        this.setState({ [side]: open });
    };

    NotificationApiCall() {
        // NotificationApi.getNotifications().then(res => {
        //     this.setState({ notification: res });
        //     console.log("notification ", this.state.notification);
        // });

    }

    componentDidMount() {
        let { programKey } = this.props;
        this.NotificationApiCall();
        if (programKey && programKey !== undefined) {
            console.log("programKey777", programKey);
            ProgramApi.getNavigationData(programKey).then(result => {
                this.setState({ navigationData: result });
            })
        }
        this.getLoggedInUserDetails();
    }

    getLoggedInUserDetails() {
        UserApi.getLoggedInUser().then(data => {
            if (data.user_type === 0 || data.user_type === 1) {
                this.setState({ redirectTo: '/create-app' });
            }
            else {
                this.setState({ redirectTo: '/policy-list' });
            }
        })
    }

    changesNotifyStatus = (id) => () => {
        console.log("Notification id is", id)
        NotificationApi.updateNotificationStatus(id).then(result => {
            console.log("notification status updated", result);
        })
        const notification = this.state.notification.filter(c => c.notification_id !== id);
        this.setState({ notification });
    }

    changesNotifyStatusOfAll = () => {
        console.log("Clear all")
        const listofId = [];
        this.state.notification.map(m =>
            listofId.push(m.notification_id)
        )
        NotificationApi.updateNotificationStatusforAll(listofId).then(response => {
            console.log("notification list updated", response);
        })
        const newlist = [];
        this.setState({ notification: newlist })
        console.log("array of Id", listofId)
    }

    notificationindication() {
        let light = "notify-wrap notify-bell";
        light += this.state.notification.length === 0 ? "" : " active";
        console.log("custom css", light);
        return light;
    }


    render() {

        const { headerType, positionType, programKey, searchType, clientId } = this.props;
        const { profilelink, setProfile, redirectTo } = this.state;

        const Logo = (
            <div className="logo-wrap">
                <img src={Constants.CONFIG.LOGO} alt="Logo" />
            </div>
        )

        const LightTooltip = withStyles(theme => ({
            tooltip: {
                backgroundColor: theme.palette.common.white,
                color: 'rgba(0, 0, 0, 0.87)',
                //   position: 'absolute',
                left: '40%',
                transform: 'translateX(-50%)',
                boxShadow: theme.shadows[1],
                fontSize: 11,
            },
        }))(Tooltip);

        const Notification = (
            <nav>
                <div className="notify-aside">
                    <div className="notify-wrap notify-bell active">
                        <IconButton>
                            <img alt="" src={notificationIcon} />
                        </IconButton>
                    </div>
                    <div className="notify-wrap profile-wrap"
                        aria-owns={profilelink ? 'profile-menu' : undefined}
                        aria-haspopup="true"
                        onClick={this.menuOpen('profilelink')}>
                        <IconButton>
                            M
                        </IconButton>
                        <ExpandMore />
                    </div>
                    <Menu
                        id="profile-menu"
                        profileUser={profilelink}
                        open={Boolean(profilelink)}
                        onClose={this.menuClose('profilelink')}
                    >
                        <MenuItem onClick={this.menuClose('profilelink')}>Profile</MenuItem>
                    </Menu>
                </div>
            </nav>
        )

        const notificationList = (
            <div className="notify-wrap multiple-notify-action">
                {
                    this.props.searchType === "normalClick" ?
                        <div className="drawer-tabs-header notify-action">
                            <div></div>
                            <div className="workflow-tabs-search">
                                <SearchField placeholder="Search here" searchType="normal" customStyle={this.props.customStyleSearch} handleOnChangeValue={(event) => this.props.handleSearchOnChangeValue(event)} />
                            </div>
                        </div>
                        :
                        <div className="notify-action">
                            <LightTooltip title="Search">
                                <div className="notify-wrap notify-bell">
                                    <IconButton onClick={this.toggleDrawer('searchDrawer', true).bind(this)}>
                                        <Search />
                                    </IconButton>
                                </div>
                            </LightTooltip>
                        </div>
                }
                <div className="notify-action">
                    <LightTooltip title="Notification">
                        <div className={this.notificationindication()}>
                            <IconButton onClick={this.toggleDrawer('notificationDrawer', true).bind(this)}>
                                <img alt="" src={notificationIcon} />
                            </IconButton>
                        </div>
                    </LightTooltip>
                </div>
                <div className="notify-action mobile-user-action">
                    <div className="notify-wrap notify-bell">
                        <ProfileOnboard programKey={programKey} loginSet={"simple"} />
                    </div>
                </div>
            </div>
        )



        const dashboardNotification = (
            <nav>
                <div className="notify-aside notify-profile">
                    {notificationList}
                    <ProfileOnboard programKey={programKey} />
                </div>
            </nav>
        )

        return (
            <section className="header-sec">
                {
                    headerType === 'normal' ? (
                        <AppBar position="fixed" className="header">
                            <Toolbar>
                                <Grid container className="menu-grid">
                                    <Grid item sm={2}>
                                        <a href={redirectTo}>
                                            {Logo}
                                        </a>
                                    </Grid>
                                    <Grid item sm={6}>
                                        <SearchField placeholder="Search here" />
                                    </Grid>
                                    <Grid item xs={2}>
                                        {Notification}
                                    </Grid>
                                </Grid>
                            </Toolbar>
                        </AppBar>
                    ) : (
                        <AppBar position={positionType} className="header">
                            <Toolbar>
                                <Grid container className="menu-grid">
                                    <Grid item sm={2}>
                                        <a href={redirectTo}>
                                            {Logo}
                                        </a>
                                    </Grid>
                                    <Grid item xs={10}>
                                        {dashboardNotification}
                                    </Grid>
                                </Grid>
                            </Toolbar>
                        </AppBar>
                    )

                }
                <HideOnScroll {...this.props}>
                    <section className="scroll-form-right notify-aside">
                        {notificationList}
                    </section>
                </HideOnScroll>
                <HeaderNotification
                    toggleDrawer={this.toggleDrawer.bind(this)}
                    notificationDrawer={this.state.notificationDrawer}
                    notification={this.state.notification}
                    onchangesNotifyStatus={this.changesNotifyStatus}
                    changeNotifyStatusAll={this.changesNotifyStatusOfAll.bind(this)}
                >
                </HeaderNotification>

                <SearchNotification
                    programKey={programKey}
                    clientId={clientId}
                    toggleDrawer={this.toggleDrawer.bind(this)}
                    searchDrawer={this.state.searchDrawer}
                    notification={this.state.notification}
                    onchangesNotifyStatus={this.changesNotifyStatus}
                    changeNotifyStatusAll={this.changesNotifyStatusOfAll.bind(this)}
                >
                </SearchNotification>

            </section>
        )
    }
}
Header.defaultProps = {
    headerType: 'normal',
    positionType: 'static'
}
export default Header;