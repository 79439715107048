import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import Description from '../validators/descriptions';
import MandatoryValidator from '../validators/mandatory-validator';
import taskBlueIcon from '../../assets/images/icon/task-blue.svg';
import updateBlueIcon from '../../assets/images/icon/update-blue.svg';
import Fab from '@material-ui/core/Fab';

//Validation
import * as ErrorValidation from '../validators/error-validator';

//Custom 
import ErrorMessage from '../common/error-message';

//Constants
import * as Constants from '../../utilities/constants';
import { InputAdornment } from '@material-ui/core';

class GenerateKeyCode extends Component {

    state = {
        errorStates: [],
        validNumber: ''
    }

    handleOnChangeEvent = (event) => {
        let errors = ErrorValidation.updateErrorMessage(event.target.value, this.props.field_validations, this.props.condition_script, this.props.error_message, this.props.char_size);
        this.setState({ errorStates: errors });
        this.props.handleOnChangeValue(event.target.value, errors, this.props);
    }

    handleNumberChange = (event) => {
        let errors = ErrorValidation.updateErrorMessage(event.target.value, this.props.field_validations, this.props.condition_script, this.props.error_message);
        let validNumber = this.state.validNumber;
        if (event.target.value >= 0 && event.target.value <= 255) {
            validNumber = event.target.value;
        }
        this.setState({ errorStates: errors });
        this.setState({ validNumber: validNumber });
        this.props.handleOnChangeValue(validNumber, errors);
    }

    componentWillReceiveProps(nextProps) {

        //Global validation check
        if (nextProps.triggerValidCheck !== this.props.triggerValidCheck) {
            let errors = ErrorValidation.updateErrorMessage(nextProps.value, nextProps.field_validations, this.props.condition_script, this.props.error_message);
            this.setState({ errorStates: errors });
            //this.props.handleOnChangeValue(nextProps.value, errors);
        }
    }

    render() {
        /** 
         * name: for label and id for input
         * type: defined type for input
         * label: label text
         * instruction: instruction
         * placeValue: placeholder
         * fieldStyle: field-underline
         * <GenerateKeyCode label="Form Name" type="text" name="form_name"/>
         * */
        const {
            name,
            type,
            label,
            instruction,
            selected_value,
            is_mandatory,
            placeholder,
            defaultLabel,
            disabled,
            fieldStyle,
            customStyle,
            // isPhoneNumber, 
            // isEmail,
            default_value,
            withLabel,
            adornment,
            adornmentContent
        } = this.props;
        let { errorStates } = this.state;
        let defaultPlaceholder = "";
        let defaultFieldStyle = "field-box";
        let isDisabled = (this.props.is_disable === 'yes');
        return (
            <div className={"form-row field-with-extra " + customStyle}>
                <div className={fieldStyle ? fieldStyle : defaultFieldStyle}>

                    {
                        withLabel ? (
                            <InputLabel
                                shrink
                                htmlFor={name}
                                className="label">
                                {label ? label : defaultLabel}
                                <MandatoryValidator value={is_mandatory} />
                            </InputLabel>
                        ) : ""
                    }
                    <TextField
                        id={name}
                        className="field"
                        type={type}
                        onChange={this.props.type === Constants.FieldIds.F_NUMBER_TXT.code ? this.handleNumberChange : this.handleOnChangeEvent}
                        value={selected_value ? selected_value : default_value}
                        defaultValue={default_value}
                        placeholder={placeholder ? placeholder : defaultPlaceholder}
                        disabled={isDisabled ? isDisabled : disabled}
                        onClick={this.props.handleOnClickEvent}
                        InputProps={adornment === 'start' ? {
                            startAdornment: (
                                <InputAdornment position="start">
                                    {adornmentContent}
                                </InputAdornment>
                            )
                        } : adornment === 'end' ? {
                            endAdornment: (
                                <InputAdornment position="end">
                                    {adornmentContent}
                                </InputAdornment>
                            )
                        } : ""}
                    >
                    </TextField>
                    <div className="extra-field">
                        <div className="btn-wrap">
                            <Fab variant="extended" size="large" className="btn btn-large btn-plane white-color no-shadow">
                                <img src={taskBlueIcon} alt="taskBlueIcon" className="MuiSvgIcon"></img>
                                Copy Key
                            </Fab>
                            <Fab variant="extended" size="large" className="btn btn-large btn-plane white-color no-shadow">
                                <img src={updateBlueIcon} alt="updateBlueIcon" className="MuiSvgIcon"></img>
                                Regenerate key
                            </Fab>
                        </div>
                    </div>
                    <ErrorMessage errorStates={errorStates} name={name} />

                </div>
                <Description value={instruction} />
            </div>
        )
    }
}

GenerateKeyCode.defaultProps = {
    isPhoneNumber: false,
    isEmail: false,
    withLabel: true,
    default_value: "",
    selected_value: "",
    adornment: "",
}

export default GenerateKeyCode;