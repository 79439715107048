import * as Constants from '../utilities/constants';

const initialState = {
    formData: {},
    userFormData: [],
}

export default function (state = initialState, action) {
    switch (action.type) {
        case Constants.REDUX_ACTION.SET_FORM_DATA:
            return {
                ...state,
                formData: action.payload,
            }
        case Constants.REDUX_ACTION.SET_USER_DATA:
            return {
                ...state,
                userFormData: action.payload,
            }
        default: return state;
    }
}