import React, { Component } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import InputField from '../../components/fields/inputs';
import Fab from '@material-ui/core/Fab';
import RadioField from '../../components/fields/radio';
import { TRUE_OR_FALSE_OPTION } from '../../utilities/constants';
import { validDataSourceConfigDetails } from '../../utilities/data-source-config-validator';

//Api
import * as DataSourceConfigApi from '../../api/data-source-config-api';
import Slide from '@material-ui/core/Slide';


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

class DataSourceConfig extends Component {

    state = {
        dataSourceConfigDetails: {
            advanced_config: {}
        },
    }

    constructor(props) {
        super(props);
    }

    componentWillMount() {
        DataSourceConfigApi.getDataSourceConfig().then(data => {
            //Convert advanced config boolean value to string for radio button
            let advancedConfig = data.advanced_config;
            Object.keys(data.advanced_config).forEach((value) => {
                advancedConfig[value] = advancedConfig[value] === true || advancedConfig[value] === false
                    ? advancedConfig[value].toString() : advancedConfig[value];
            });
            data.advanced_config = advancedConfig;
            this.setState({ dataSourceConfigDetails: data });
        })
    }

    handleDataSourceConfigChange = (type) => (value) => {
        let { dataSourceConfigDetails } = this.state;
        if (Object.prototype.hasOwnProperty.call(dataSourceConfigDetails.advanced_config, type)) {
            dataSourceConfigDetails.advanced_config[type] = value;
        } else {
            dataSourceConfigDetails[type] = value;
        }
        this.setState({ dataSourceConfigDetails: dataSourceConfigDetails });
    }

    handleSaveDataSourceConfigDetails() {
        let { dataSourceConfigDetails } = this.state;

        //Check data source config details is valid
        if (validDataSourceConfigDetails(dataSourceConfigDetails)) {
            DataSourceConfigApi.saveDataSourceConfig(this.state.dataSourceConfigDetails).then(res => {
                window.location.reload();
            })
        }
    }

    redirectToProgram() {
        let cancel = window.confirm(" Are you sure you want to cancel?");
        if (cancel) {
            window.location.reload();
        }
    }

    render() {
        return (
            <div className="drawer-tabs workflow-tabs-wrap">
                <div className="drawer-tabs-header border-bottom">
                    <div className="workflow-title">
                        <h5>Database Config</h5>
                    </div>
                </div>
                <div className="tab-container m-0">
                    <Scrollbars style={{ width: '100%', height: '100%' }}>
                        <div className="profile-sec-wrap">
                            <div className="profile-sec-body">
                                <section className="add-fields-wrap">
                                    <div className="add-field-row">
                                        <div className="added-field-grp">
                                            <div className="added-field">
                                                <InputField
                                                    label="Host"
                                                    type="text"
                                                    name="host"
                                                    selected_value={this.state.dataSourceConfigDetails.host}
                                                    fieldStyle="field-box bg-plane grey-color"
                                                    placeholder="Enter"
                                                    handleOnChangeValue={this.handleDataSourceConfigChange("host").bind(this)}
                                                    is_mandatory="yes"
                                                    field_validations={["v_required"]}
                                                />
                                                <InputField
                                                    label="Port"
                                                    type="text"
                                                    name="port"
                                                    selected_value={this.state.dataSourceConfigDetails.port}
                                                    fieldStyle="field-box bg-plane grey-color"
                                                    placeholder="Enter"
                                                    handleOnChangeValue={this.handleDataSourceConfigChange("port").bind(this)}
                                                    is_mandatory="yes"
                                                    field_validations={["v_required"]}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="add-field-row">
                                        <div className="added-field-grp">
                                            <div className="added-field">
                                                <InputField
                                                    label="Database Name"
                                                    type="text"
                                                    name="database_name"
                                                    selected_value={this.state.dataSourceConfigDetails.database_name}
                                                    fieldStyle="field-box bg-plane grey-color"
                                                    placeholder="Enter"
                                                    handleOnChangeValue={this.handleDataSourceConfigChange("database_name").bind(this)}
                                                    is_mandatory="yes"
                                                    field_validations={["v_required"]}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="add-field-row">
                                        <div className="added-field-grp">
                                            <div className="added-field">
                                                <InputField
                                                    label="Username"
                                                    type="text"
                                                    name="username"
                                                    selected_value={this.state.dataSourceConfigDetails.username}
                                                    fieldStyle="field-box bg-plane grey-color"
                                                    placeholder="Enter"
                                                    handleOnChangeValue={this.handleDataSourceConfigChange("username").bind(this)}
                                                    is_mandatory="yes"
                                                    field_validations={["v_required"]}
                                                />
                                                <InputField
                                                    label="Password"
                                                    type="password"
                                                    name="password"
                                                    selected_value={this.state.dataSourceConfigDetails.password}
                                                    fieldStyle="field-box bg-plane grey-color"
                                                    placeholder="Enter"
                                                    handleOnChangeValue={this.handleDataSourceConfigChange("password").bind(this)}
                                                    is_mandatory="yes"
                                                    field_validations={["v_required"]}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="add-field-row">
                                        <div className="added-field-grp">
                                            <div className="added-field">
                                                <InputField
                                                    label="Minimum Idle Connection"
                                                    type="number"
                                                    name="minimum_idle"
                                                    selected_value={this.state.dataSourceConfigDetails.advanced_config.minimum_idle}
                                                    fieldStyle="field-box bg-plane grey-color"
                                                    placeholder="Enter"
                                                    handleOnChangeValue={this.handleDataSourceConfigChange("minimum_idle").bind(this)}
                                                    is_mandatory="yes"
                                                    field_validations={["v_required", "v_number_only"]}
                                                />
                                                <InputField
                                                    label="Maximum Pool Size"
                                                    type="number"
                                                    name="maximum_pool_size"
                                                    selected_value={this.state.dataSourceConfigDetails.advanced_config.maximum_pool_size}
                                                    fieldStyle="field-box bg-plane grey-color"
                                                    placeholder="Enter"
                                                    handleOnChangeValue={this.handleDataSourceConfigChange("maximum_pool_size").bind(this)}
                                                    is_mandatory="yes"
                                                    field_validations={["v_required", "v_number_only"]}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="add-field-row">
                                        <div className="added-field-grp">
                                            <div className="added-field">
                                                <InputField
                                                    label="Idle Timeout"
                                                    type="number"
                                                    name="idle_timeout"
                                                    selected_value={this.state.dataSourceConfigDetails.advanced_config.idle_timeout}
                                                    fieldStyle="field-box bg-plane grey-color"
                                                    placeholder="Enter"
                                                    handleOnChangeValue={this.handleDataSourceConfigChange("idle_timeout").bind(this)}
                                                    is_mandatory="yes"
                                                    field_validations={["v_required", "v_number_only"]}
                                                />
                                                <InputField
                                                    label="Connection Max Lifetime"
                                                    type="number"
                                                    name="max_lifetime"
                                                    selected_value={this.state.dataSourceConfigDetails.advanced_config.max_lifetime}
                                                    fieldStyle="field-box bg-plane grey-color"
                                                    placeholder="Enter"
                                                    handleOnChangeValue={this.handleDataSourceConfigChange("max_lifetime").bind(this)}
                                                    is_mandatory="yes"
                                                    field_validations={["v_required", "v_number_only"]}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="add-field-row">
                                        <div className="added-field-grp">
                                            <div className="added-field">
                                                <InputField
                                                    label="Connection Timeout"
                                                    type="number"
                                                    name="connection_timeout"
                                                    selected_value={this.state.dataSourceConfigDetails.advanced_config.connection_timeout}
                                                    fieldStyle="field-box bg-plane grey-color"
                                                    placeholder="Enter"
                                                    handleOnChangeValue={this.handleDataSourceConfigChange("connection_timeout").bind(this)}
                                                    is_mandatory="yes"
                                                    field_validations={["v_required", "v_number_only"]}
                                                />
                                                <InputField
                                                    label="Keep Alive Time"
                                                    type="number"
                                                    name="keepalive_time"
                                                    selected_value={this.state.dataSourceConfigDetails.advanced_config.keepalive_time}
                                                    fieldStyle="field-box bg-plane grey-color"
                                                    placeholder="Enter"
                                                    handleOnChangeValue={this.handleDataSourceConfigChange("keepalive_time").bind(this)}
                                                    is_mandatory="yes"
                                                    field_validations={["v_required", "v_number_only"]}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="add-field-row">
                                        <div className="added-field-grp">
                                            <div className="added-field">
                                                <RadioField
                                                    radioGroupLabel="Verify Server Certificate"
                                                    radioData={TRUE_OR_FALSE_OPTION}
                                                    label="Verify Server Certificate"
                                                    radioFieldStyle="field-inline"
                                                    default_value={this.state.dataSourceConfigDetails.advanced_config.verify_server_certificate}
                                                    selected_value={this.state.dataSourceConfigDetails.advanced_config.verify_server_certificate}
                                                    radioChangeHandle={this.handleDataSourceConfigChange("verify_server_certificate").bind(this)}
                                                />
                                                <RadioField
                                                    radioGroupLabel="Use SSL"
                                                    radioData={TRUE_OR_FALSE_OPTION}
                                                    label="Use SSL"
                                                    radioFieldStyle="field-inline"
                                                    default_value={this.state.dataSourceConfigDetails.advanced_config.use_ssl}
                                                    selected_value={this.state.dataSourceConfigDetails.advanced_config.use_ssl}
                                                    radioChangeHandle={this.handleDataSourceConfigChange("use_ssl").bind(this)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="add-field-row">
                                        <div className="added-field-grp">
                                            <div className="added-field">
                                                <RadioField
                                                    radioGroupLabel="Require SSL"
                                                    radioData={TRUE_OR_FALSE_OPTION}
                                                    label="Require SSL"
                                                    radioFieldStyle="field-inline"
                                                    default_value={this.state.dataSourceConfigDetails.advanced_config.require_ssl}
                                                    selected_value={this.state.dataSourceConfigDetails.advanced_config.require_ssl}
                                                    radioChangeHandle={this.handleDataSourceConfigChange("require_ssl").bind(this)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="add-field-row">
                                        <div className="added-field-grp">
                                            <div className="added-field">
                                                <InputField
                                                    label="Trust certificate key store url"
                                                    type="text"
                                                    name="trust_certificate_key_store_url"
                                                    selected_value={this.state.dataSourceConfigDetails.advanced_config.trust_certificate_key_store_url}
                                                    fieldStyle="field-box bg-plane grey-color"
                                                    placeholder="Enter"
                                                    handleOnChangeValue={this.handleDataSourceConfigChange("trust_certificate_key_store_url").bind(this)}
                                                //  is_mandatory="yes"
                                                // field_validations={["v_required"]}
                                                />
                                                <InputField
                                                    label="Trust certificate key store password"
                                                    type="password"
                                                    name="trust_certificate_key_store_password"
                                                    selected_value={this.state.dataSourceConfigDetails.advanced_config.trust_certificate_key_store_password}
                                                    fieldStyle="field-box bg-plane grey-color"
                                                    placeholder="Enter"
                                                    handleOnChangeValue={this.handleDataSourceConfigChange("trust_certificate_key_store_password").bind(this)}
                                                //is_mandatory="yes"
                                                //field_validations={["v_required"]}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </Scrollbars>
                </div>
                <div className="card-footer border-top p-l-0 p-r-0">
                    <div className="btn-wrap">
                        <Fab variant="extended" size="large" className="btn btn-large btn-plane primary-color" onClick={() => this.handleSaveDataSourceConfigDetails()}>
                            Save
                        </Fab>
                        <Fab variant="extended" size="large" className="btn btn-large btn-plane grey-color" onClick={() => this.redirectToProgram()}>
                            Cancel
                        </Fab>
                    </div>
                </div>
            </div>
        )
    }

}

export default DataSourceConfig;
