import React, { Component } from 'react';
import { Redirect } from 'react-router-dom'
import InputField from '../../components/fields/inputs';
import CheckBoxField from '../../components/fields/checkbox';
import Fab from '@material-ui/core/Fab'
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import IconButton from '@material-ui/core/IconButton';
import LoginIllus from '../../assets/images/illustration/login-iso.svg';
// import Logo from '../../assets/images/asureKit.png';

//API
import * as UserApi from '../../api/user-api';

/* Date: 11-01-2021
 Name: Milind Sewak
 Desc: Logo Image Configuration*/
import * as Constants from '../../utilities/constants';
// End

class SetPassword extends Component {

    state = {
        validUser: null,
        password: '',
        confirmPassword: '',
        showPassword: '',
        showConfirmPassword: '',
        userData: {},
        userAllowedToLogin: false,
        errorMessage: "",
    }

    componentWillMount() {
        let search = window.location.search;
        let params = new URLSearchParams(search);
        let resetToken = params.get('token');
        UserApi.getUserDetailsFromToken(resetToken).then(data => {
            if (!data) {
                this.setState({ validUser: false });
            } else {
                this.setState({ validUser: (resetToken === data.reset_token), userData: data });
            }
        })

    }



    handlePasswordChange(key, value) {
        this.setState({ [key]: value });
    }

    handleSetPassword() {
        let { password, confirmPassword, userData } = this.state;
        var format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
        if (password === confirmPassword) {
            if (userData) {
                userData.password = confirmPassword;
                //userData.password_reset_date = new Date(); 
                //userData.reset_token=null;
            }
            if (password.length >= 8 && format.test(password)) {
                UserApi.postUpdatedPasswordToUserAuth(userData).then(data => {
                    this.setState({ userAllowedToLogin: false });
                    this.handleRedirectToLoginPage();
                })
                this.setState({ errorMessage: "" })
            } else {
                this.setState({ errorMessage: "Password length should be more than 8 characters and contain atleast one special character." });
            }


        } else {
            this.setState({ errorMessage: "Password does not match" });
        }
    }

    handleClickShowPassword(key) {
        this.setState({ [key]: !this.state[key] });
    }

    handleRedirectToLoginPage() {
        window.location = "/";
    }

    render() {
        let { validUser, userData, password, confirmPassword, showPassword, showConfirmPassword, userAllowedToLogin, errorMessage } = this.state;
        return (
            <section className="login-sec">
                <div className="login-wrap">
                    <div className="login-wrap-info">
                        <div className="login-info">
                            <h4>Welcome to the </h4>
                            <h2>Future of Insurance</h2>
                            <h5>To stay connected with us please sign in with your personal info.</h5>
                        </div>
                        <div className="login-illus">
                            <img src={LoginIllus} alt="LoginIllus" />
                        </div>
                    </div>
                    <div className="login-wrap-form">
                        <div className="login-form">
                            <div className="logo">
                                <img src={Constants.CONFIG.LOGO} alt="AssureKit" />
                            </div>
                            {
                                userAllowedToLogin ? (
                                    <section className={"forget-main "}>
                                        <div className="login-form-field">
                                            <div className="form-row">
                                                <div className="login-header">
                                                    <h4>Password Set</h4>
                                                    <div className="login-extra-info-x">
                                                        <h5>Don’t worry. We’ll email you password reset link with instructions.</h5>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="btn-wrap form-row">
                                                <Fab variant="extended" size="large" className="btn btn-large btn-plane no-shadow primary-color" onClick={() => this.handleRedirectToLoginPage()}>
                                                    Login To Continue
                                                </Fab>
                                            </div>
                                        </div>
                                    </section>
                                ) : (
                                    validUser ?
                                        <section className={"forget-main "}>
                                            <div className="login-form-field">
                                                <div className="form-row">
                                                    <div className="login-header">
                                                        <h4>Welcome {userData.username}</h4>
                                                    </div>
                                                </div>
                                                <InputField
                                                    label="Set Password"
                                                    type={showPassword ? 'text' : 'password'}
                                                    placeholder="Enter"
                                                    name="login-password"
                                                    adornment="end"
                                                    fieldStyle="field-box field-adornment bg-plane grey-color"
                                                    adornmentContent={
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={() => this.handleClickShowPassword("showPassword")}
                                                        >
                                                            {showPassword ? <Visibility /> : <VisibilityOff />}
                                                        </IconButton>
                                                    }
                                                    selected_value={password}
                                                    handleOnChangeValue={(value) => this.handlePasswordChange("password", value)}
                                                />
                                                <InputField
                                                    label="Confirm Password"
                                                    type={showConfirmPassword ? 'text' : 'password'}
                                                    placeholder="Enter"
                                                    name="login-confirm"
                                                    adornment="end"
                                                    fieldStyle="field-box field-adornment bg-plane grey-color"
                                                    adornmentContent={
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={() => this.handleClickShowPassword("showConfirmPassword")}
                                                        >
                                                            {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                                                        </IconButton>
                                                    }
                                                    selected_value={confirmPassword}
                                                    handleOnChangeValue={(value) => this.handlePasswordChange("confirmPassword", value)}
                                                />
                                                <h6 style={{ color: "red" }}>{errorMessage}</h6>
                                                <div className="btn-wrap form-row">
                                                    <Fab variant="extended" size="large" className="btn btn-large btn-plane no-shadow primary-color" onClick={() => { this.handleSetPassword() }}>
                                                        Change Password
                                                    </Fab>
                                                </div>
                                            </div>
                                        </section>
                                        :
                                        <section className={"forget-main "}>
                                            <div className="login-form-field">
                                                <div className="form-row">
                                                    <div className="login-header">
                                                        <h4>Invalid User</h4>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                )
                            }
                            <div className="login-footer">
                                <p>By clicking sign in, you agree to our<br /> <a href="//assurekit.com/terms-and-conditions.html" className="link" target="_blank">Terms &amp; Conditions</a> and <a href="//assurekit.com/privacy-policy.html" target="_blank" className="link">Privacy Policy.</a></p>
                                <h5>Copyright &copy; Assurekit, All Rights Reserved</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default (SetPassword);