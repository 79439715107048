import React, { Component } from 'react';
import Fab from '@material-ui/core/Fab';
import StackIcon from '../../assets/images/illustration/stack.svg';
import MasterIcon from '../../assets/images/illustration/master.svg';
import PolicyParamIcon from '../../assets/images/illustration/policy-param.svg';
import CoverageLimitIcon from '../../assets/images/illustration/coverage-limit.svg';
import PaymentMIcon from '../../assets/images/illustration/payment-m.svg';
import TermConditionIcon from '../../assets/images/illustration/term-condition.svg';
import ArrowIcon from '../../assets/images/icon/chevron-blue.svg';

//Constants
import * as Constants from '../../utilities/constants';

class ProgramTimeline extends Component {

    handleOnClick = (type, typeIndex) => event => {
        let { timeline_count } = this.props;
        if (timeline_count >= typeIndex) {
            this.props.modifyProgramSelection(type);
        }
    }

    render() {
        let { stackHolderStyle, masterPolicyStyle, policyParameterStyle, coverageStyle, paymentStyle, termsConditionStyle } = '';
        let { stackActive, masterActive, policyActive, coverageActive, paymentActive, termsActive } = '';
        let { timeline_count, buttonState, programSelection, isFinish } = this.props;
        let ToggleBtnArrow = buttonState ? "toggle-arrow" : "";
        let heightOfActiveBar = 1;
        let indicatorWidth = 16.66;
        let indicatorCount = 0;

        console.log("isFinished", isFinish, "timeline_count", timeline_count);
        //This Switch statement is used to highlight the current selected Program.
        switch (timeline_count) {
            case 1:
                heightOfActiveBar *= 1;
                indicatorWidth *= 0;
                indicatorCount += 0;
                break;

            case 2:
                heightOfActiveBar *= 100;
                indicatorWidth *= 1;
                indicatorCount += 1;
                stackHolderStyle = " prev-active "
                break;

            case 3:
                heightOfActiveBar *= 200;
                indicatorWidth *= 2;
                indicatorCount += 2;
                stackHolderStyle = masterPolicyStyle = " prev-active ";
                break;

            case 4:
                heightOfActiveBar *= 300;
                indicatorWidth *= 3;
                indicatorCount += 3;
                stackHolderStyle = masterPolicyStyle = policyParameterStyle = " prev-active ";
                break;

            case 5:
                heightOfActiveBar *= 400;
                indicatorWidth *= 4;
                indicatorCount += 4;
                stackHolderStyle = masterPolicyStyle = policyParameterStyle = coverageStyle = " prev-active ";
                break;

            case 6:
                heightOfActiveBar *= 500;
                indicatorWidth *= 5;
                indicatorCount += 5;
                stackHolderStyle = masterPolicyStyle = policyParameterStyle = coverageStyle = paymentStyle = " prev-active ";
                break;

            default: break;

        }

        switch (programSelection) {
            case "stack_holder":
                stackActive = " active "; break;
            case "master_policy":
                masterActive = " active "; break;
            case "policy_parameters":
                policyActive = " active "; break;
            case "coverage_limits":
                coverageActive = " active "; break;
            case "payment_methods":
                paymentActive = " active "; break;
            case "terms_conditions":
                termsActive = " active "; break;
            default: break;
        }

        if (isFinish) {
            indicatorCount = 6;
            indicatorWidth = 100;
        }

        return (
            <section className="program-timeline-sec">
                <div className="program-timeline-wrap">
                    <div className="program-timeline-block">
                        <div className={"program-timeline-btn " + stackActive + stackHolderStyle} onClick={this.handleOnClick(Constants.PROGRAM.STACK_HOLDER, 1)}>
                            <Fab variant="extended" size="small" className="btn btn-shadow btn-small btn-icon">
                                <img src={StackIcon} alt="StackIcon" />
                            </Fab>
                            {
                                !buttonState && (
                                    <Fab variant="extended" size="large" className="btn btn-shadow btn-large btn-info">
                                        Stake Holder
                                    </Fab>
                                )
                            }

                        </div>
                        <div className={"program-timeline-btn " + masterActive + masterPolicyStyle} onClick={this.handleOnClick(Constants.PROGRAM.MASTER_POLICY, 2)}>
                            <Fab variant="extended" size="small" className="btn btn-shadow btn-small btn-icon">
                                <img src={MasterIcon} alt="MasterIcon" />
                            </Fab>
                            {
                                !buttonState && (
                                    <Fab variant="extended" size="large" className="btn btn-shadow btn-large btn-info">
                                        Master Policy
                                    </Fab>
                                )
                            }
                        </div>
                        <div className={"program-timeline-btn " + policyActive + policyParameterStyle} onClick={this.handleOnClick(Constants.PROGRAM.POLICY_PARAMETER, 3)}>
                            <Fab variant="extended" size="small" className="btn btn-shadow btn-small btn-icon">
                                <img src={PolicyParamIcon} alt="PolicyParamIcon" />
                            </Fab>
                            {
                                !buttonState && (
                                    <Fab variant="extended" size="large" className="btn btn-shadow btn-large btn-info">
                                        Policy Parameters
                                    </Fab>
                                )
                            }

                        </div>
                        <div className={"program-timeline-btn " + coverageActive + coverageStyle} onClick={this.handleOnClick(Constants.PROGRAM.COVERAGE_LIMITS, 4)}>
                            <Fab variant="extended" size="small" className="btn btn-shadow btn-small btn-icon">
                                <img src={CoverageLimitIcon} alt="CoverageLimitIcon" />
                            </Fab>
                            {
                                !buttonState && (
                                    <Fab variant="extended" size="large" className="btn btn-shadow btn-large btn-info">
                                        Coverage and Limits
                                    </Fab>
                                )
                            }
                        </div>
                        <div className={"program-timeline-btn " + paymentActive + paymentStyle} onClick={this.handleOnClick(Constants.PROGRAM.PAYMENT_METHODS, 5)}>
                            <Fab variant="extended" size="small" className="btn btn-shadow btn-small btn-icon">
                                <img src={PaymentMIcon} alt="PaymentMIcon" />
                            </Fab>
                            {
                                !buttonState && (
                                    <Fab variant="extended" size="large" className="btn btn-shadow btn-large btn-info">
                                        Payment Methods
                                    </Fab>
                                )
                            }
                        </div>
                        <div className={"program-timeline-btn " + termsActive + termsConditionStyle} onClick={this.handleOnClick(Constants.PROGRAM.TERMS_CONDITIONS, 6)}>
                            <Fab variant="extended" size="small" className="btn btn-shadow btn-small btn-icon">
                                <img src={TermConditionIcon} alt="TermConditionIcon" />
                            </Fab>
                            {
                                !buttonState && (
                                    <Fab variant="extended" size="large" className="btn btn-shadow btn-large btn-info">
                                        Terms and Conditions
                                    </Fab>
                                )
                            }
                        </div>
                    </div>
                    {/* <div className="program-timeline-indicator" style={{height: heightOfActiveBar+"px"}}></div> */}
                    <div className={"program-timeline-btn program-timeline-toggle " + ToggleBtnArrow} onClick={this.props.buttonClick} >
                        <Fab variant="extended" size="small" className="btn btn-shadow btn-small btn-toggle">
                            <img src={ArrowIcon} alt="ArrowIcon" />
                        </Fab>
                    </div>
                </div>
                {
                    !buttonState && (
                        <div className="program-timeline-wrap">
                            <section className="progress-bar-wrap">
                                <div className="progress-title">
                                    <div className="title">
                                        <h6 className="font-med">Status</h6>
                                    </div>
                                    <div className="indicator-status">
                                        <h6 className="font-med">{indicatorCount + "/6"}</h6>
                                    </div>
                                </div>
                                <div className="progress-bar">
                                    <div className="indicator" style={{ width: indicatorWidth + '%' }}></div>
                                </div>
                            </section>
                        </div>
                    )
                }
            </section>
        )
    }
}

export default ProgramTimeline;