import React, { Component } from 'react';
import SearchField from '../fields/search';
import FormToolbox from './formToolbox';
import FormCanvas from './formCanvas';
import FormProperties from './formProperties';
import { Scrollbars } from 'react-custom-scrollbars';
import CircularProgress from '@material-ui/core/CircularProgress';
import BorderColor from '@material-ui/icons/BorderColor';
import Fab from '@material-ui/core/Fab';
import uploadIcons from '../../assets/images/icon/upload.svg';
import MuiExpansionPanel from '@material-ui/core/ExpansionPanel';
import MuiExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import MuiExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import InputField from '../../components/fields/inputs';
import EyeIcon from '../../assets/images/icon/eye-blue.svg';
import closeIcon from '../../assets/images/icon/close.svg';
import DialogBox from '../../components/fields/dialog-box'

//Constants
import * as FormConstants from '../../utilities/constants';
import * as CommonUtils from '../../utilities/common-utils';

//API
import * as FormApi from '../../api/form-api';
import * as FormTemplateApi from '../../api/form-template-api';

const update = require('immutability-helper');

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

class FormBuilderMaster extends Component {

    state = {
        fields: [],
        selectedField: -1,
        form: {},
        saveLoader: false,
        deleteFieldLoader: false,
        addFieldLoader: false,
        deleteFieldSuccess: false,
        previewRedirect: false,
        publishRedirect: false,
        recordRedirect: false,
        expanded: 'basicField',
        fieldOperation: {},
        openFormulaDialog: false,
        snackbarOpen: false,
        search: "",
        formTemplate: {},
        openTemplateDialog: false,
        errorObj: {},
        templateSaveLoader: false,
        showErrorBox: false,
        errorMessage: ""
    }

    componentDidMount() {
        this.generateFormFromData(this.props.formData);
    }

    renderPreviewRedirect = () => {
        if (this.state.previewRedirect) {
            // return <Redirect to={'/'+this.props.programKey+"/form/"+this.props.formKey+"/preview"} />
            window.location = '/' + this.props.clientId + '/' + this.props.programKey + "/form/" + this.props.formKey + "/preview";
        }
    }

    renderRecordRedirect = () => {
        if (this.state.recordRedirect) {
            // return <Redirect to={'/'+this.props.programKey+"/form/"+this.props.formKey+"/preview"} />
            window.location = '/' + this.props.clientId + '/' + this.props.programKey + "/form/" + this.props.formKey + "/records";
        }
    }

    renderPublishRedirect = () => {
        if (this.state.publishRedirect) {
            // return <Redirect to={'/'+this.props.programKey+"/form/"+this.props.formKey} />
            window.location = '/' + this.props.clientId + '/' + this.props.programKey + "/form/" + this.props.formKey + "/publish";
        }
    }

    tempTimer = () => {
        this.setState({ saveLoader: false });
        clearInterval(this.timer);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.formData !== this.props.formData) {
            this.generateFormFromData(nextProps.formData);
        }
    }

    generateField = (id) => {
        console.log("generateField", id);
        this.setState({ addFieldLoader: true });
        let updatedFields = JSON.parse(JSON.stringify(this.state.fields));

        //getting the form field properties
        let selectedFormFieldType = this.props.formFieldTypes.filter(formFieldType => {
            return formFieldType.field_code_txt === id;
        })[0];
        //  console.log("selectedFormFieldType",selectedFormFieldType);
        let propertyMetadata = this.generatePropertiesData(FormConstants.PropertyIds.P_CHAR_SIZE);
        let propertyMetadataLayout = this.generatePropertiesData(FormConstants.PropertyIds.P_FIELD_LAYOUT);
        let data = {};
        data.position = updatedFields.length;
        data.field_type = id;
        data.label = selectedFormFieldType.default_label;
        data.placeholder = selectedFormFieldType.default_placeholder;
        data.field_validations = selectedFormFieldType.field_validations;
        data.group_field = selectedFormFieldType.group_field;
        data.field_link = selectedFormFieldType.default_link;
        if (id === FormConstants.FieldIds.F_SINGLE_TEXT.code || id === FormConstants.FieldIds.F_EMAIL_TEXT.code) {
            data.char_size = (propertyMetadata ? propertyMetadata.default_value : 255);
        }
        if (id === FormConstants.FieldIds.F_DATE.code || id === FormConstants.FieldIds.F_DATE_TIME.code) {
            data.allowed_days = '0,1,2,3,4,5,6';
        }
        if (id === FormConstants.FieldIds.F_TIME.code || id === FormConstants.FieldIds.F_DATE_TIME.code) {
            data.allowed_hours = '0,23';
        }
        if (id === FormConstants.FieldIds.F_RADIO_GROUP.code || id === FormConstants.FieldIds.F_CHECKBOX_GROUP.code) {
            data.field_layout = propertyMetadataLayout.default_value;
        }

        if (id === FormConstants.FieldIds.F_POLICY_ID.code) {
            data.is_disable = "yes";
        }
        //Checking for multi value fields
        this.optionDetailCheck(id, data);

        if (id === FormConstants.FieldIds.F_NAME.code || id === FormConstants.FieldIds.F_ADDRESS.code) {
            data.group_field = true;
        }

        FormApi.addFieldDetails(data, this.state.form.id).then(fieldList => {
            fieldList.forEach(response => {
                if (!response.parent_id) {
                    response.propertyTypes = selectedFormFieldType.field_properties_ids;
                }
                updatedFields.push(response);
            })

            this.setState({ fields: this.positionUpdation(updatedFields), addFieldLoader: false });
        });
    }


    //genererate unique field link name 
    generateUniqueLink() {
        FormApi.addFieldDetails(this.state.fields[this.state.selectedField], this.state.form.id).then(fieldList => {

        });
    }

    optionDetailCheck(code, data) {
        switch (code) {
            case FormConstants.FieldIds.F_RADIO_GROUP.code: {
                data.field_option_list = this.generateOptionData(FormConstants.PropertyIds.P_SIN_OPT);
                break;
            }
            case FormConstants.FieldIds.F_CHECKBOX_GROUP.code: {
                data.field_option_list = this.generateOptionData(FormConstants.PropertyIds.P_MUL_OPT);
                break;
            }
            case FormConstants.FieldIds.F_SINGLE_DROPDOWN_GROUP.code: {
                data.field_option_list = this.generateOptionData(FormConstants.PropertyIds.P_SIN_OPT);
                break;
            }
            case FormConstants.FieldIds.F_MULTIPLE_DROPDOWN_GROUP.code: {
                data.field_option_list = this.generateOptionData(FormConstants.PropertyIds.P_MUL_OPT);
                break;
            }
            case FormConstants.FieldIds.F_NAME.code: {
                data.display_name = this.generateOptionData(FormConstants.PropertyIds.P_DISPLAY_NAME);
                data.prefix_name = this.generateOptionData(FormConstants.PropertyIds.P_PREFIX_NAME);
                break;
            }
            case FormConstants.FieldIds.F_ADDRESS.code: {
                data.display_name = this.generateOptionData(FormConstants.PropertyIds.P_DISPLAY_ADDRESS);
                break;
            }
            case FormConstants.FieldIds.F_ACTION_BUTTONS.code: {
                data.field_option_list = this.generateOptionData(FormConstants.PropertyIds.P_MUL_NAMES);
                break;
            }
            default: break;
        }
    }

    generateOptionData(optionType) {
        let { formFieldProperties } = this.props;
        let propertyMetadata = formFieldProperties.filter(formFieldProperty => formFieldProperty.property_code === optionType)[0]
        let options = [];
        console.log("propertyMetadata", propertyMetadata);
        propertyMetadata.field_options.sort((a, b) => {
            return a.field_option_order - b.field_option_order
        })
        propertyMetadata.field_options.map(fieldOption => {
            let obj = fieldOption;
            if (!(optionType === FormConstants.PropertyIds.P_DISPLAY_NAME || optionType === FormConstants.PropertyIds.P_PREFIX_NAME || optionType === FormConstants.PropertyIds.P_DISPLAY_ADDRESS)) {
                obj.checked = false;
            }
            options.push(obj);
            return options;
        })
        return options;
    }

    generatePropertiesData(optionType) {
        let { formFieldProperties } = this.props;
        let propertyMetadata = formFieldProperties.filter(formFieldProperty => formFieldProperty.property_code === optionType)[0]
        return propertyMetadata;
    }

    moveGenerateField = (dragIndex, hoverIndex) => {
        const { fields } = this.state;
        const dragCard = fields[dragIndex]
        //Skip movement of default
        if (dragCard.default === true) {
            return;
        }
        this.setState(update(this.state, {
            fields: {
                $splice: [[dragIndex, 1], [hoverIndex, 0, dragCard]],
            }
        }))
        let newFields = this.state.fields
        for (var i = 0; i < newFields.length; i++) {
            newFields[i].position = i;

            if (newFields[i].group_field) {
                if (newFields[i].children && newFields[i].children.length > 0) {
                    newFields[i].children.forEach(child => {
                        i++;
                        child.position = i;
                    })
                }
            }
        }
        this.setState({ fields: this.positionUpdation(newFields) })
        this.setState({ selectedField: hoverIndex });
    }

    fieldSelectionClick(e, id) {
        e.preventDefault();
        this.setState({ selectedField: id });
    }

    positionUpdation(updateField) {
        let defaultFieldIndexs = [];
        let index = 0;
        for (var i = 0; i < updateField.length; i++) {
            if (updateField[i].default === true) {
                defaultFieldIndexs.push(i)
            } else {
                updateField[i].position = index++;

                if (updateField[i].group_field) {
                    if (updateField[i].children && updateField[i].children.length > 0) {
                        updateField[i].children.forEach(child => {
                            child.position = index;
                            index++
                        })
                    }
                }
            }
        }
        //Updating for default field type to come to bottom
        defaultFieldIndexs.forEach(defaultIndex => {
            updateField[defaultIndex].position = index++;
        })
        //sort fields
        updateField.sort(function (a, b) {
            return a.position - b.position
        })
        return updateField;
    }

    fieldDuplicate = id => event => {

        let updateField = JSON.parse(JSON.stringify(this.state.fields));
        let duplicatefield = JSON.parse(JSON.stringify(updateField[id]));
        //duplicatefield.id=null;
        delete duplicatefield['id'];


        FormApi.addFieldDetails(duplicatefield, this.state.form.id).then(res => {
            duplicatefield["field_key"] = res[0].field_key;
            duplicatefield["id"] = res[0].id;
            updateField.splice(id + 1, 0, duplicatefield);
            this.setState({ fields: this.positionUpdation(updateField) });
        });

    }


    fieldToDeleteClick = id => event => {
        let updateField = JSON.parse(JSON.stringify(this.state.fields));
        this.setState({ deleteFieldLoader: true },
            () => {
                let deletePromise = FormApi.deleteFormField(updateField[id].id);
                deletePromise.then(response => {

                    this.setState({ deleteFieldSuccess: true },
                        () => {
                            setTimeout(() => {
                                updateField.splice(id, 1);
                                this.setState({ selectedField: -2, fields: this.positionUpdation(updateField), deleteFieldLoader: false, deleteFieldSuccess: false })
                            }, 500);
                        });

                }).catch(error => {
                    let errorResponse = JSON.parse(error.response);
                    this.setState({ showErrorBox: true, errorMessage: errorResponse.message, deleteFieldLoader: false })
                });
            }
        )

    }

    deleteAllFormField = () => {
        this.props.handleLoading(true)
        FormApi.deleteAllFormField(this.state.form.id).then(res => {
            this.setState({ selectedField: -1, fields: this.positionUpdation([]) })
            this.props.handleLoading(false)
        }).catch(error => {
            let errorResponse = JSON.parse(error.response);
            this.setState({ showErrorBox: true, errorMessage: errorResponse.message, deleteFieldLoader: false })
            this.props.handleLoading(false)
        })
    }

    clearAndResetFrom = () => {
        let { form } = this.state;
        this.props.handleLoading(true);
        FormApi.clearAndResetForm(form.id, form.template_id).then(res => {
            window.location.reload();
        }).catch(error => {
            let errorResponse = JSON.parse(error.response);
            this.setState({ showErrorBox: true, errorMessage: errorResponse.message})
            this.props.handleLoading(false)
        })
    }

    stateUpdate(key, value) {
        this.setState({ [key]: value });
    }

    fieldPropertyChange(type, val) {
        let { selectedField } = this.state;
        console.log("type456", type, "val", val, "selectedField", selectedField);
        let updatedfields = JSON.parse(JSON.stringify(this.state.fields));
        updatedfields[selectedField][type] = val;
        this.setState({ fields: updatedfields });
    }

    fieldMandatoryChange(type, val) {
        let { selectedField } = this.state;
        let updatedfields = JSON.parse(JSON.stringify(this.state.fields));

        let fieldType = updatedfields[selectedField].field_type;
        if (fieldType === "f-name" || fieldType === "f-address") {
            updatedfields[selectedField][type] = "yes";

            let mandatoryChildList = val.split(',');

            updatedfields[selectedField] &&
                updatedfields[selectedField].display_name.forEach((child, index) => {
                    if (mandatoryChildList.includes(child.field_option_value) && child.checked) {
                        updatedfields[selectedField].children[index][type] = "yes";
                    } else {
                        updatedfields[selectedField].children[index][type] = "";
                    }
                })
        } else {
            updatedfields[selectedField][type] = val;
        }
        this.setState({ fields: updatedfields });

    }

    disableFieldsAndChildFields(type, val) {
        let { selectedField } = this.state;
        console.log("type4567", type, "val", val, "selectedField", selectedField);
        let updatedfields = JSON.parse(JSON.stringify(this.state.fields));

        let chosenField = updatedfields[selectedField];
        if (chosenField.group_field) {
            chosenField.children.forEach(child => {
                child[type] = val;
            })
        }
        chosenField[type] = val;
        this.setState({ fields: updatedfields });
    }

    saveFieldOperation(prevSelectedValue, type) {
        let fieldOperation = JSON.parse(JSON.stringify(this.state.fieldOperation));
        // console.log("fieldOperation12345", fieldOperation, "fieldOperation.operation", fieldOperation.operation);
        let updatedfields = JSON.parse(JSON.stringify(this.state.fields));
        //testing if all fields are getting validated
        if (updatedfields.length > 0 && prevSelectedValue > -1 && fieldOperation.operation && updatedfields[prevSelectedValue].error_message) {

            if (fieldOperation.operation === "isNull" || fieldOperation.operation === "isNotNull" || fieldOperation.field_value) {
                let operations = updatedfields[prevSelectedValue] && updatedfields[prevSelectedValue][type];
                if (!operations) {
                    operations = [];
                }
                operations.push(fieldOperation);
                fieldOperation = {};
                this.setState({ fieldOperation: fieldOperation })
                updatedfields[prevSelectedValue][type] = operations;
                this.setState({ fields: updatedfields });
            }
        } else {
            fieldOperation = {};
            let operations = updatedfields[prevSelectedValue] && updatedfields[prevSelectedValue][type];
            if (operations && operations[operations.length - 1]) {
                operations[operations.length - 1]["next_operation"] = null;
            }
            this.setState({ fieldOperation: fieldOperation })
        }
        return updatedfields;
    }

    fieldOperationChange = (type) => (index, oprType, val) => {
        let { selectedField } = this.state;
        let fieldOperation = JSON.parse(JSON.stringify(this.state.fieldOperation));
        let updatedfields = JSON.parse(JSON.stringify(this.state.fields));
        let operations = updatedfields[selectedField][type];
        console.log("fieldType222", updatedfields[selectedField]);
        let isNewData = false;
        if (!operations) {
            operations = [];
        }
        if (operations[index]) {
            fieldOperation = operations[index];
        } else {
            isNewData = true;
        }

        if (oprType === 'delete') {
            if (index === null) {
                fieldOperation = {};
                this.setState({ fieldOperation: fieldOperation })
            } else {
                operations.splice(index, 1);
            }
        }
        else {
            fieldOperation[oprType] = val;
            if (updatedfields[selectedField].field_type === "f-number-txt" && oprType === "field_value") {
                fieldOperation[oprType] = Number(val);
            }
        }

        //Set CheckNull attribute for isNull and isNotNull
        if (oprType === 'operation') {
            fieldOperation["checkNull"] = (fieldOperation.operation === "isNull" || fieldOperation.operation === "isNotNull");
        }
        if (isNewData && oprType === "next_operation") {
            operations.push(fieldOperation);
            fieldOperation = {};
            this.setState({ fieldOperation: fieldOperation })
        }
        if (isNewData) {
            this.setState({ fieldOperation: fieldOperation });
        }
        this.fieldPropertyChange(type, operations);
    }

    allowedHoursPropertyChange = (type) => (val, errors, nameProp) => {
        let { selectedField } = this.state;
        let updatedfields = JSON.parse(JSON.stringify(this.state.fields));
        let str = updatedfields[selectedField].allowed_hours;
        let array = [];
        array = str.split(",");

        if (nameProp.name === 'field_hours_from') {
            array[0] = val;
            updatedfields[selectedField][type] = array.join();
        }
        else if (nameProp.name === 'field_hours_to') {
            array[1] = val;
            updatedfields[selectedField][type] = array.join();
        }
        this.setState({ fields: updatedfields });
    }

    formDataChange = (type) => val => {
        let { form } = this.state;
        let updatedForm = form;
        updatedForm[type] = val;
        this.setState({ form: updatedForm });
    }

    handleCopyToClip(action) {
        console.log("copy ", action);
        this.setState({ snackbarOpen: action });
    }

    saveForm() {
        let formObj = this.state.form;
        formObj.form_field_list = this.saveFieldOperation(this.state.selectedField, "field_operations");
        this.setState({ saveLoader: true }, this.props.updateForm(formObj, this.dataSavedResponse.bind(this)));
    }

    onSaveTemplateClickHandler() {
        let { form, formTemplate } = this.state;
        if (formTemplate.name) {
            // First save form and then call save template api
            form.form_field_list = this.saveFieldOperation(this.state.selectedField, "field_operations");
            this.setState({ templateSaveLoader: true }, this.props.updateForm(form, this.saveTemplateApiCall.bind(this)));
        }
    }

    saveTemplateApiCall(res, formData) {
        let { formTemplate } = this.state;
        formTemplate.form_field_list = formData.form_field_list
        FormTemplateApi.saveFormTemplate(formTemplate).then(res => {
            this.setState({ openTemplateDialog: false, templateSaveLoader: false, formTemplate: {} })
        }).catch(error => {
            let errorResponse = JSON.parse(error.response);
            let errorObj = {
                "value": formTemplate.name,
                "isValid": false,
                "message": errorResponse.message
            }
            this.setState({ errorObj: errorObj, templateSaveLoader: false })
        });
    }

    updateForm() {
        let formObj = this.state.form;
        formObj.fields = this.state.fields;
        this.setState({ saveLoader: true }, this.props.updateForm(formObj, this.dataSavedResponse.bind(this)));
    }

    dataSavedResponse(responseData) {
        this.generateFormFromData(responseData);
        this.setState({ saveLoader: false });
    }

    generateFormFromData(formData) {
        if (!CommonUtils.isEmpty(formData)) {
            formData.form_field_list.map(data => {
                let selectedFormFieldType = this.props.formFieldTypes.filter(formFieldType => {
                    return formFieldType.field_code_txt === data.field_type;
                })[0];

                data.propertyTypes = selectedFormFieldType.field_properties_ids;
                return data;
            })

            formData.form_field_list.sort(function (a, b) {
                return a.position - b.position
            })

            this.setState({ form: formData, fields: formData.form_field_list });
            this.addActionButton(formData);
        }
    }

    addActionButton(formData) {
        if (formData.form_type === FormConstants.FORM_TYPE.ACTION.code) {
            // let actionButtonId = this.props.formFieldTypes.filter( fieldType => 
            //     fieldType.field_code_txt === FormConstants.FieldIds.F_ACTION_BUTTONS.code)[0].id;
            // this.generateField(FormConstants.FieldIds.F_ACTION_BUTTONS.code);
        }
    }

    editFormDetails = event => {
        this.fieldSelectionClick(event, -1);
    }

    previewDirect = event => {
        this.setState({ previewRedirect: true });
    }

    recordDirect = event => {
        this.setState({ recordRedirect: true });
    }

    publishDirect = event => {
        this.setState({ publishRedirect: true });
    }


    handleChange = panel => (event, expanded) => {
        this.setState({
            expanded: expanded ? panel : false
        });
    };

    handleFormTemplateChange = (key, value) => {
        let { formTemplate } = this.state;
        let updatedFormTemplate = formTemplate;
        updatedFormTemplate[key] = value;
        this.setState({ formTemplate: updatedFormTemplate, errorObj: {} });
    }

    doneChange() {
        let { programKey } = this.props;
        console.log("doneChange", window.location.pathname.split("/"));
        window.location = "/" + this.props.clientId + "/" + programKey + "/forms-dashboard";
    }

    searchFieldChange(event) {
        this.setState({ search: event.target.value });
    }

    render() {

        let filteredFieldNames = this.props.formFieldTypes.filter(field => {
            return field.field_name.toLowerCase().startsWith(this.state.search.toLowerCase().trim());
        })
        let { selectedField, fields, saveLoader, deleteFieldLoader, addFieldLoader, deleteFieldSuccess, form, expanded, fieldOperation, openFormulaDialog, openTemplateDialog, formTemplate, errorObj, templateSaveLoader, showErrorBox, errorMessage } = this.state;
        let { formFieldProperties, formFieldTypes, sectionOption } = this.props;

        return (
            <section className="form-builder-sec dashboard-bg">
                {this.renderPreviewRedirect()}
                {this.renderRecordRedirect()}
                {this.renderPublishRedirect()}
                {/* <div className="aside-wrap">
                    <SideNavigation/>
                </div> */}
                <div className="main-wrap">
                    <div className="build-master overflow-hidden">
                        <div className="build-master-tool">
                            <div className="form-tool-box card-block">
                                <div className="card-header">
                                    <SearchField placeholder="Search Fields" handleOnChangeValue={(event) => this.searchFieldChange(event)} />
                                </div>
                                <div className="card-body">
                                    <Scrollbars style={{ width: '100%', height: '100%' }}>
                                        <div className="tool-accordion">
                                            <MuiExpansionPanel square expanded={expanded === 'basicField'} onChange={this.handleChange('basicField')}>
                                                <MuiExpansionPanelSummary aria-controls="basic-content" id="basic-header">
                                                    <h5>Basic Fields</h5>
                                                </MuiExpansionPanelSummary>
                                                <MuiExpansionPanelDetails>
                                                    <div className="source-wrap">
                                                        <ul className="source-list">
                                                            {
                                                                filteredFieldNames.filter(formFieldTypes => formFieldTypes.field_category === "basic").map((item, index) => (
                                                                    <FormToolbox key={item.field_type_id} item={item} handleDrop={(id) => this.generateField(id)} />
                                                                ))
                                                            }
                                                        </ul>
                                                    </div>
                                                </MuiExpansionPanelDetails>
                                            </MuiExpansionPanel>
                                            <MuiExpansionPanel square expanded={expanded === 'advanceField'} onChange={this.handleChange('advanceField')}>
                                                <MuiExpansionPanelSummary aria-controls="advance-content" id="basic-header">
                                                    <h5>Advance Fields</h5>
                                                </MuiExpansionPanelSummary>
                                                <MuiExpansionPanelDetails>
                                                    <div className="source-wrap">
                                                        <ul className="source-list">
                                                            {
                                                                filteredFieldNames.filter(formFieldTypes => formFieldTypes.field_category === "advance").map((item, index) => (
                                                                    <FormToolbox key={item.field_type_id} item={item} handleDrop={(id) => this.generateField(id)} />
                                                                ))
                                                            }
                                                        </ul>
                                                    </div>
                                                </MuiExpansionPanelDetails>
                                            </MuiExpansionPanel>
                                            <MuiExpansionPanel square expanded={expanded === 'systemField'} onChange={this.handleChange('systemField')}>
                                                <MuiExpansionPanelSummary aria-controls="advance-content" id="basic-header">
                                                    <h5>System Elements</h5>
                                                </MuiExpansionPanelSummary>
                                                <MuiExpansionPanelDetails>
                                                    <div className="source-wrap">
                                                        <ul className="source-list">
                                                            {
                                                                filteredFieldNames.filter(formFieldTypes => formFieldTypes.field_category === "system").map((item, index) => (
                                                                    <FormToolbox key={item.field_type_id} item={item} handleDrop={(id) => this.generateField(id)} />
                                                                ))
                                                            }
                                                        </ul>
                                                    </div>
                                                </MuiExpansionPanelDetails>
                                            </MuiExpansionPanel>
                                        </div>
                                    </Scrollbars>
                                </div>
                            </div>
                        </div>
                        <div className="build-master-canvas">
                            <div className="form-canvas card-block">
                                <div className="form-canvas-body">
                                    <div className="form-canvas-wrap">
                                        <div className="card-header border-bottom">
                                            <div className="sub-title">
                                                <div className="form-description">
                                                    <h5>{this.state.form.form_name}</h5>
                                                    <h6>{this.state.form.form_desc}</h6>
                                                </div>
                                                <div className="btn-wrap" onClick={this.editFormDetails}>
                                                    <Fab variant="extended" size="small" className="btn btn-small btn-fab btn-plane white-color no-shadow">
                                                        <BorderColor />
                                                    </Fab>
                                                </div>
                                            </div>
                                            <div className="btn-wrap">
                                                <Fab variant="extended" size="small" className="btn btn-small btn-plane white-color no-shadow" onClick={this.recordDirect}>
                                                    <img src={EyeIcon} alt="EyeIcon" className="MuiSvgIcon"></img>
                                                    View Entries
                                                </Fab>
                                                <Fab variant="extended" size="small" className="btn btn-small btn-plane white-color no-shadow" onClick={this.publishDirect}>
                                                    <img alt="uploadIcons" src={uploadIcons} className="MuiSvgIcon"></img>
                                                    Publish
                                                </Fab>
                                                <Fab variant="extended" size="small" className="btn btn-small btn-outline green-color save-form no-shadow" onClick={() => this.setState({ openTemplateDialog: true })}>
                                                    Save as template
                                                </Fab>
                                                <Fab variant="extended" size="small" className="btn btn-small btn-plane green-color save-form no-shadow" onClick={this.saveForm.bind(this)}>
                                                    {
                                                        saveLoader === true ?
                                                            <CircularProgress
                                                                color="secondary"
                                                                className="loader"
                                                            />
                                                            : ""
                                                    }
                                                    Save
                                                </Fab>
                                                <Fab variant="extended" size="small" className="btn btn-small btn-plane primary-color save-form no-shadow" onClick={this.doneChange.bind(this)}>
                                                    Done
                                                </Fab>
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <div className="form-canvas-cont">
                                                <Scrollbars style={{ width: '100%', height: '100%' }}>
                                                    <FormCanvas
                                                        fields={this.state.fields}
                                                        selectedField={selectedField}
                                                        moveField={this.moveGenerateField}
                                                        fieldSelection={this.fieldSelectionClick.bind(this)}
                                                        fieldToDelete={this.fieldToDeleteClick.bind(this)}
                                                        fieldDuplicate={this.fieldDuplicate.bind(this)}
                                                        deleteFieldLoader={deleteFieldLoader}
                                                        deleteFieldSuccess={deleteFieldSuccess}
                                                        addFieldLoader={addFieldLoader}
                                                    />

                                                </Scrollbars>
                                            </div>
                                            <div className="form-properties-wrap">
                                                <div className="card-body">
                                                    <div className="card-header border-bottom">
                                                        <div className="sub-title">
                                                            <h5>Properties</h5>
                                                        </div>
                                                    </div>
                                                    <div className="form-prop">
                                                        <Scrollbars style={{ width: '100%', height: '100%' }}>
                                                            {selectedField > -2 ?
                                                                (

                                                                    <div>
                                                                        <FormProperties
                                                                            formData={form}
                                                                            fields={fields}
                                                                            formDataUpdate={this.formDataChange}
                                                                            formFieldProperties={formFieldProperties}
                                                                            formFieldTypes={formFieldTypes}
                                                                            selectedField={selectedField}
                                                                            fieldOperation={fieldOperation}
                                                                            openFormulaDialog={openFormulaDialog}
                                                                            sectionOption={sectionOption}
                                                                            selectedFieldProperty={(selectedField === -1) ? {} : fields[selectedField]}
                                                                            selectedFieldPropertyTypes={(selectedField === -1) ? [FormConstants.PropertyIds.P_FORM] : fields[selectedField].propertyTypes}
                                                                            labelOnChange={(value, error) => this.fieldPropertyChange("label", value)}
                                                                            fieldLinkOnChange={(value, error) => this.fieldPropertyChange("field_key", value)}
                                                                            instructionOnChange={(value, error) => this.fieldPropertyChange("instruction", value)}
                                                                            checkValidateChangeHandle={(value, error) => this.fieldMandatoryChange("is_mandatory", value)}
                                                                            checkVisibleChangeHandle={(value, error) => this.fieldPropertyChange("is_hidden", value)}
                                                                            checkStateChangeHandle={(value, error) => this.disableFieldsAndChildFields("is_disable", value)}
                                                                            checkOrderChangeHandle={(value, error) => this.fieldPropertyChange("is_alphabetical_order", value)}
                                                                            placeholderOnChange={(value, error) => this.fieldPropertyChange("placeholder", value)}
                                                                            allowedDaysOnChange={(value, error) => this.fieldPropertyChange("allowed_days", value)}
                                                                            allowedHoursOnChange={this.allowedHoursPropertyChange("allowed_hours").bind(this)}
                                                                            fieldLayoutOnChange={(value, error) => this.fieldPropertyChange("field_layout", value)}
                                                                            charSizeOnChange={(value, error) => this.fieldPropertyChange("char_size", value)}
                                                                            remainingCharOnChange={(value, error) => this.fieldPropertyChange("remaining_char", value)}
                                                                            remainingCharLimitOnChange={(value, error) => this.fieldPropertyChange("remaining_char_limit", value)}
                                                                            optionOnChange={(value, error) => this.fieldPropertyChange("field_option_list", value)}
                                                                            namesOnChange={(value, error) => this.fieldPropertyChange("display_name", value)}
                                                                            prefixOnChange={(value, error) => this.fieldPropertyChange("prefix_name", value)}
                                                                            addressOnChange={(value, error) => this.fieldPropertyChange("display_name", value)}
                                                                            countryCodeOnChange={(value, error) => this.fieldPropertyChange("country_code_options", value)}
                                                                            defaultCountryCodeOnChange={(value, error) => this.fieldPropertyChange("default_country_code", value)}
                                                                            optionSelectedChange={(value, error) => this.fieldPropertyChange("default_value", value)}
                                                                            fieldSizeOnChange={(value, error) => this.fieldPropertyChange("field_size", value)}
                                                                            fieldOperationChange={this.fieldOperationChange("field_operations").bind(this)}
                                                                            saveFieldOperation={(prevSelectedValue) => this.saveFieldOperation(prevSelectedValue, "field_operations")}
                                                                            errorMessageOnChange={(value, error) => this.fieldPropertyChange("error_message", value)}
                                                                            formulaDialogOpen={(value) => this.stateUpdate("openFormulaDialog", value)}
                                                                            formulaTextChangeEvent={(value, error) => this.fieldPropertyChange("formula_text", value)}
                                                                            generateUniqueLink={this.generateUniqueLink.bind(this)}
                                                                            idFieldChange={(value, error) => this.fieldPropertyChange("id_field", value)}
                                                                            handleCopyToClip={this.handleCopyToClip.bind(this)}
                                                                            snackbarOpen={this.state.snackbarOpen}
                                                                            deleteAllFormField={() => this.deleteAllFormField()}
                                                                            clearAndResetFrom={() => this.clearAndResetFrom()}
                                                                        />
                                                                        <div className="properties-block">
                                                                            <div className="card-body">
                                                                                <div className="properties-form">
                                                                                    {/* <div className="form-row">
                                                                                                <div className="properties-conditional">
                                                                                                    <div className="field-box">
                                                                                                        <InputLabel className="label">Conditional Validation</InputLabel>
                                                                                                    </div>
                                                                                                    <div className="actions-prop-wrap">
                                                                                                        <div className="actions-prop-row">
                                                                                                            <SelectField withLabel={false} optionsData={defalutSelect}/>
                                                                                                            <SelectField withLabel={false} optionsData={defalutSelect}/>
                                                                                                            <InputField withLabel={false} placeholder="Enter Value"/>
                                                                                                        </div>
                                                                                                        <div className="actions-prop-row">
                                                                                                            <div className="btn-wrap">
                                                                                                                <Fab variant="extended" size="small" className="btn btn-small btn-fab btn-plane btn-curve"> AND </Fab>
                                                                                                                <Fab variant="extended" size="small" className="btn btn-small btn-fab btn-plane btn-curve"> Or </Fab>
                                                                                                            </div>
                                                                                                            <div className="p-t-15">
                                                                                                                <TextAreaField label="Error Message" placeholder="Description" rowmax="3"/>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div> */}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                ) : ""
                                                            }
                                                        </Scrollbars>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* <div className="form-canvas-wrap">
                                        {
                                            this.state.cards.map((card, i) => (
                                            <Card
                                                key={card.id}
                                                index={i}
                                                id={card.id}
                                                text={card.text}
                                                moveCard={this.moveCard}
                                            />
                                            )) 
                                        }
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Dialog open={openTemplateDialog} onClose={() => this.setState({ openTemplateDialog: false })} TransitionComponent={Transition} maxWidth="sm">
                    <section className="workflow-dialog workflow-dialog-pri">
                        <div className="card-block">
                            <div className="card-header border-bottom p-b-25">
                                <div className="sub-title">
                                    <h3 className="bold">Save template</h3>
                                </div>
                                <div className="close-dialog">
                                    <img src={closeIcon} alt="" onClick={() => this.setState({ openTemplateDialog: false })}></img>
                                </div>
                            </div>
                            <div className="card-body p-t-25 p-b-45">
                                <InputField
                                    label="Name"
                                    name="name"
                                    type="text"
                                    placeholder="Name"
                                    fieldStyle="field-box bg-plane grey-color"
                                    selected_value={formTemplate?.name}
                                    handleOnChangeValue={(value) => this.handleFormTemplateChange('name', value)}
                                    is_mandatory="yes"
                                    field_validations={["v_required"]}
                                    errorObj={errorObj}
                                />
                            </div>
                            <div className="card-footer border-top p-0 p-t-25">
                                <div className="btn-wrap justify-btn-end">
                                    <Fab variant="extended" size="large" className="btn btn-large btn-outline green-color" onClick={() => this.onSaveTemplateClickHandler()} disabled={!formTemplate.name}>
                                        {
                                            templateSaveLoader === true &&
                                            <CircularProgress
                                                color="secondary"
                                                className="loader"
                                            />
                                        }
                                        Save template
                                    </Fab>
                                </div>
                            </div>
                        </div>
                    </section>
                </Dialog>
                <DialogBox
                    title={"Error"}
                    content={errorMessage}
                    open={showErrorBox}
                    onClose={() => this.setState({showErrorBox: false})}
                />
            </section>
        )
    }
}

export default FormBuilderMaster;