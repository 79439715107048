import React, { Component } from 'react';
import * as FormCommon from '../../utilities/form-common'
import * as ConfigurationApi from '../../api/configuration-api';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import * as Constants from '../../utilities/constants';
import Fab from '@material-ui/core/Fab';
import * as FormConstant from '../../utilities/constants';
import AppBar from '@material-ui/core/AppBar';
import { Scrollbars } from 'react-custom-scrollbars';
import RichTextKendo from '../kendo/rich-text';
import * as MotorApi from '../../api/motor-api';

class ConfigInsurers extends Component {

    state = {
        formData: [],
        tabIndex: 0,
        formUserDatas: [],
        programKey: "",
        insurerDetails: {},
        insurerKitData: {},
        validationStatus: {},
        rtoDetails: []
    }

    constructor(props) {
        super(props);
        console.log("props: ", props);
    }


    componentWillMount() {
        this.handleGetRTODetails();
        // this.handleGetInsurerKitData();
    }

    handleGetRTODetails() {
        MotorApi.getMotorRTODetails().then(data => {
            let rtoList = []
            data.forEach(rtoData => {
                let obj = {
                    field_option_label: rtoData.rto_code.trim() + " [" + rtoData.rto_name.toUpperCase() + "]",
                    field_option_value: rtoData.rto_code.trim()
                };
                rtoList.push(obj);
            })
            this.setState({ rtoDetails: rtoList }, () => { this.handleGetInsurerKitData() })
        })
    }


    handleGetInsurerKitData() {
        let clientId = this.props.clientId;
        ConfigurationApi.getInsurersKitByMotorConfigId(clientId, this.props.programkey, this.props.motorConfigId).then(data => {
            this.handleGetInsurerDetails()
            this.setState({ formData: data });
        })
    }

    handleNextClick() {
        this.props.modifyProgramSelection(Constants.CONFIG_PROCESS.AGENTS, 3);
    }
    handleBackClick() {
        this.props.modifyProgramSelection(Constants.CONFIG_PROCESS.BASIC_DETAILS, 1);
    }

    insertRecordDataToFields() {
        let { formData, formUserDatas, insurerDetails } = this.state;
        if ((insurerDetails.length - 1) < this.state.tabIndex) {
            return;
        }
        formData[this.state.tabIndex].data_fields.forEach((field, index) => {
            field.field_option_list = this.state.rtoDetails
            if (field.field_type !== Constants.FieldIds.F_UPLOAD.code) {


                if (field.group_field) {
                    field.children.forEach(child => {
                        let dataRefId = "data_attr" + child.data_ref_id;
                        let paginationId = field.data_pagination_id;
                        let recordValue = this.searchReferenceIdRecord(dataRefId, paginationId);

                        if (child.field_option_list) {
                            recordValue = this.mapRecordLabelToValue(child.field_option_list, recordValue, child.field_type);
                            child.selected_value = recordValue;
                        }
                        else {
                            child.selected_value = recordValue;
                        }
                        formData[paginationId - 1][dataRefId] = recordValue
                    })
                }
                else {
                    let dataRefId = "data_attr" + field.data_ref_id;
                    let paginationId = field.data_pagination_id;
                    let recordValue = this.searchReferenceIdRecord(dataRefId, paginationId);
                    if (field.field_option_list && field.field_option_list.length > 0) {
                        recordValue = this.mapRecordLabelToValue(field.field_option_list, recordValue, field.field_type);

                        field.selected_value = recordValue;
                    }
                    else {
                        if (field.field_type === "f-date" || field.field_type === "f-date-time") {
                            if (recordValue) {
                                field.selected_value = new Date(recordValue);
                            }
                        }
                        else {
                            field.selected_value = recordValue;
                        }
                    }

                    formData[paginationId - 1][dataRefId] = recordValue
                }
            }
        })

        // formUserDatas.forEach((data, index) => {
        //     if (processData.sectional_form_record.records[index]) {
        //         data["common_record_id"] = processData.sectional_form_record.records[index].common_record_id;
        //         data["process_id"] = processData.sectional_form_record.records[index].process_id;
        //         data["record_id"] = processData.sectional_form_record.records[index].record_id;
        //     }
        // })

        let updatedValidationStatus = this.setFormPreloadDataCheck(formData, this.state.validationStatus);
        // this.generateFormValidationStatus(updatedValidationStatus);
        this.setState({ formData: formData, formUserDatas: formUserDatas });
    }


    searchReferenceIdRecord(dataRefId, paginationId) {
        let { insurerDetails } = this.state;
        let recordData = insurerDetails[this.state.tabIndex].records;
        console.log("recordData: ", insurerDetails)
        for (let key in recordData) {
            if (key === dataRefId) {
                return recordData[key];
            }
        }
        return "";
    }

    mapRecordLabelToValue(optionList, recordValue, fieldType) {
        switch (fieldType) {
            case "f-radio-grp":
            case "f-sin-dropdown-grp":
                optionList.forEach(list => {
                    if (list.field_option_label === recordValue) {
                        recordValue = list.field_option_value;
                        list.checked = true;
                    }
                })
                break;

            case "f-multi-dropdown-grp":
            case "f-checkbox-grp":
            case "f-multi-dropdown-search-grp":
                let recordValueArray = recordValue.split(',');
                optionList.forEach(list => {

                    list.checked = (recordValueArray.includes(list.field_option_value));
                })

                let newRecordValue = [];
                recordValueArray.forEach(record => {
                    optionList.forEach(list => {
                        if (list.field_option_value === record) {
                            newRecordValue.push(list.field_option_value);
                        }
                    })
                })
                recordValue = newRecordValue.join(',');
                break;

            default:
                break;
        }
        return recordValue;
    }


    setFormPreloadDataCheck(formData, validationStatus) {
        //preselect data setting
        formData[this.state.tabIndex].data_fields.forEach(field => {

            switch (field.field_type) {
                case FormConstant.FieldIds.F_SINGLE_DROPDOWN_GROUP.code:
                case FormConstant.FieldIds.F_MULTIPLE_DROPDOWN_GROUP.code:
                case FormConstant.FieldIds.F_CHECKBOX_GROUP.code:
                    let selectedOptArr = [];
                    let selectedOptStr = "";
                    let checkValid = false;
                    field.field_option_list &&
                        field.field_option_list.forEach(option => {
                            if (option.checked) {
                                selectedOptArr.push(option.field_option_value);
                                checkValid = true;
                            }
                        });

                    //Set the field validation status with current vallid status
                    //validationStatus[field.id] = checkValid;
                    if (checkValid) {
                        validationStatus[field.id] = checkValid;
                    }

                    if (selectedOptArr.length > 0) {
                        selectedOptStr = selectedOptArr.join();
                    }
                    this.setState({ validationStatus: validationStatus });
                    this.saveFormElementData(field, selectedOptStr, formData);
                    break;

                default:

                    let selectedOptStrValue = "";
                    let checkValidFlag = false;
                    if (field.default_value || field.selected_value) {
                        selectedOptStrValue = field.default_value ? field.default_value : (field.selected_value ? field.selected_value : "");
                        checkValidFlag = true
                    }
                    if (checkValidFlag) {
                        validationStatus[field.id] = checkValidFlag;
                    }

                    if (field.group_field) {
                        field.children && field.children.forEach(child => {
                            if ((child.default_value && child.default_value !== "") || (child.selected_value && child.selected_value !== "")) {
                                validationStatus[child.id] = true;
                            }
                        })
                    }

                    this.setState({ validationStatus: validationStatus });
                    this.saveFormElementData(field, selectedOptStrValue, formData);
                    break;

            }
        })
        return validationStatus;
    }


    handleGetInsurerDetails(index) {

        if (index == undefined) {
            index = 0;
        }

        let clientId = this.props.clientId;
        ConfigurationApi.getInsurerDetailsByProgramKeyAndObjectId(clientId, this.props.programkey, this.props.motorConfigId).then(data => {
            console.log("getInsurerDetailsByProgramKey.data: ", data);
            if (data != null && data.length > 0) {
                this.setState({ insurerDetails: data });
                this.insertRecordDataToFields();
            }
        })
    }


    handleTabChangeClicked(index) {
        this.setState({ tabIndex: index })
        this.handleGetInsurerDetails(index);
    }

    handleGenOnChangeValue = (fieldDetails) => (value, errors, childField) => {
        let { formData } = this.props;
        //Validating field details
        this.checkValidation(fieldDetails, errors, value);
        this.saveFormElementData(fieldDetails, value, formData, this.dataSaveCallback);
        fieldDetails.selected_value = value;

    }


    saveFormElementData(fieldDetails, value, formData, callback) {
        let { formUserDatas } = this.state;
        let formUserData = formUserDatas[fieldDetails.data_pagination_id - 1];
        console.log("saveFormElementData.formUserData: ", formUserData);
        if (!formUserData) {
            formUserData = {};
            formUserData["form_pagination_id"] = fieldDetails.data_pagination_id;
            formUserDatas[fieldDetails.data_pagination_id - 1] = formUserData;
            formUserData["programKey"] = this.props.programkey;
            formUserData["object_type"] = Constants.RECORD_TYPE.CONFIGURATION.code;
        }

        formUserData["data_attr" + fieldDetails.data_ref_id] = value;

        this.setState({ formUserDatas: formUserDatas }, callback);
    }


    checkValidation(fieldDetails, errors, value) {

        let validStatus = this.state.validationStatus;
        var valid = true;
        if (fieldDetails.field_validations != null && (fieldDetails.field_validations.length > 0 || errors.length > 0)) {
            //Field which are not mandatory and value is empty
            if (!fieldDetails.field_validations.includes("v_required") && !value && fieldDetails.field_type !== "f-radio-grp") {
                errors.forEach(err => {
                    err.isValid = true
                })
            }

            //Check for all validation status
            errors.forEach(err => {
                if (err.isValid === false) {
                    valid = false
                }
            })

            if (valid) {
                validStatus[fieldDetails.id] = true;
            }
            else {
                validStatus[fieldDetails.id] = false;
            }
        }

        var isVal = true;
        for (var key in validStatus) {
            if (validStatus[key] === false) {
                isVal = false
            }
        }

        if (isVal) {
            this.setState({ isFormValid: true });
        }
        else {
            this.setState({ isFormValid: false });
        }

        this.setState({ validationStatus: validStatus });
    }

    handleSubmitClicked(id) {
        let formInsurerData = {};
        console.log("this.state.insurerDetails: ", this.state.insurerDetails[this.state.tabIndex]);
        formInsurerData = this.state.formUserDatas[0];
        formInsurerData.object_id = id;
        console.log("insurerDetails: ", this.state.insurerDetails);
        console.log("this.state.tabIndex: ", this.state.tabIndex);


        if ((this.state.insurerDetails.length > this.state.tabIndex) && (this.state.insurerDetails.length > 0)) {
            formInsurerData.id = this.state.insurerDetails[this.state.tabIndex].id
        }
        console.log("formInsurerData: ", formInsurerData);
        let clientId = this.props.clientId;
        ConfigurationApi.addInsurer(clientId, this.props.programkey, formInsurerData).then(res => {
            this.handleGetInsurerDetails(this.state.tabIndex);
        });

    }


    render() {
        let { formData, errorState, errorStateMessage, tabIndex, insurerDetails, rtoDetails } = this.state;
        console.log("insurerDetails: ", insurerDetails);
        return (

            <div className="drawer-tabs workflow-tabs-wrap">
                <div className="drawer-tabs-header border-bottom">
                    <div className="workflow-title">
                        <h5>Insurers</h5>
                    </div>
                </div>
                <div className="workflow-nav workflow-tab-nav">
                    <div className="workflow-mega-switch">
                        <Tabs
                            indicatorColor="primary"
                            textColor="primary"
                            variant="scrollable"
                            scrollButtons="auto"
                            aria-label="scrollable auto tabs">
                            {formData.map((p, index) => (

                                <Tab label={<Fab variant="extended" size="large" className={"btn workflow-switch-btn no-shadow" + (index === tabIndex ? " active" : "")} >
                                    {p.insurer_name}
                                </Fab>} onClick={() => this.handleTabChangeClicked(index)} />

                            ))}
                        </Tabs>
                    </div>
                </div>
                <div className="tab-container" style={{ height: 'calc(100vh - 455px)' }}>
                    <Scrollbars style={{ width: '100%', height: '100%' }}>
                        <section className="policy-term-wrap">
                            <div className="add-field-row">
                                {
                                    formData[tabIndex] && formData[tabIndex].data_fields && formData[tabIndex].data_fields.map(field => {
                                        field["field_option_list"] = rtoDetails ? rtoDetails : [];
                                        return FormCommon.fieldGenerate(field.field_type, field, this.handleGenOnChangeValue(field).bind(this), this.handleButtonEvents, () => this.handleDeleteFile(field))
                                    })
                                }
                            </div>
                            <div className="add-field-row p-r-15">
                                <Fab variant="extended" size="large"
                                    className="btn btn-large btn-plane primary-color right-align"
                                    onClick={() => this.handleSubmitClicked(insurerDetails[tabIndex].id)}>
                                    Submit
                                </Fab>
                            </div>
                        </section>
                    </Scrollbars>
                </div>
                <div className="card-footer border-top p-l-0 p-r-0">
                    <div className="drawer-tabs-header">
                        <div className="workflow-title">
                            <Fab variant="extended" size="large" className="btn btn-large btn-outline pink-color" onClick={this.props.handleShowConfirmDeleteDialog}>
                                Delete
                            </Fab>
                        </div>
                        <div className="workflow-tabs-search btn-wrap">
                            <Fab variant="extended" size="large" className="btn btn-large btn-plane secondary-color"
                                onClick={this.handleBackClick.bind(this)}>
                                Back
                            </Fab>
                            <Fab variant="extended" size="large" className="btn btn-large btn-plane primary-color"
                                onClick={this.handleNextClick.bind(this)}>
                                Next
                            </Fab>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ConfigInsurers;