import React, { Component } from 'react';
import Fab from '@material-ui/core/Fab';
import EyeIcon from '../../assets/images/icon/eye-blue.svg';

import InputLabel from '@material-ui/core/InputLabel';
import InputField from '../fields/inputs';
import TextAreaField from '../fields/textarea';
import SelectField from '../fields/select';
import DateKendo from '../kendo/date-picker';
import DateRangePickerKendo from '../kendo/date-range-picker';
import RadioField from '../fields/radio';

import { Scrollbars } from 'react-custom-scrollbars';

//Constants
import * as Constants from '../../utilities/constants';

class ProgramMasterPolicy extends Component {

    state = {
        masterPolicy: {},
    }
    componentWillMount() {
        let { programData } = this.props;
        let { masterPolicy } = this.state;
        if (programData.hasOwnProperty("master_policy") && programData.master_policy != null) {
            masterPolicy = programData.master_policy;
        }
        else {
            masterPolicy["program_key"] = this.props.programkey;
        }
        this.setState({ masterPolicy: masterPolicy })
    }

    handleNextClick() {
        let { masterPolicy } = this.state;
        console.log("masterPolicy", masterPolicy);
        if (masterPolicy.policy_name && masterPolicy.master_policy_number) {
            this.props.handleSaveProgramData();
            this.props.modifyProgramSelection(Constants.PROGRAM.POLICY_PARAMETER, 3);
        }
    }

    handleBackClick() {
        this.props.modifyProgramSelection(Constants.PROGRAM.STACK_HOLDER, 1);
    }

    handleMasterPolicyChange = (key) => (value) => {
        let masterPolicy = this.state.masterPolicy;
        let { programData } = this.props;
        if (key === "policy_period") {
            masterPolicy["policy_start_date"] = value.start && value.start.toString();
            masterPolicy["policy_end_date"] = value.end && value.end.toString();
        }
        else {
            masterPolicy[key] = value;
        }
        programData["master_policy"] = masterPolicy
        this.setState({ masterPolicy: masterPolicy });
        this.props.modifyProgramData(programData);
    }

    render() {

        let { masterPolicy } = this.state;
        let defalutSections = [
            {
                field_option_label: "1 Year",
                field_option_value: "1-year"
            },
            {
                field_option_label: "2 Years",
                field_option_value: "2-years"
            },
            {
                field_option_label: "3 Years",
                field_option_value: "3-years"
            },
        ]
        let policyPeriod = [
            {
                field_option_label: "Year",
                field_option_value: "year",
            },
            {
                field_option_label: "Month",
                field_option_value: "month",
            },
            {
                field_option_label: "Day",
                field_option_value: "day"
            },
        ]
        let policyType = [
            {
                field_option_label: "Claims Made",
                field_option_value: "claims_made",
            },
            {
                field_option_label: "Occurance Made",
                field_option_value: "occurance_made"
            }
        ]

        return (
            <div className="drawer-tabs workflow-tabs-wrap">
                <div className="drawer-tabs-header border-bottom">
                    <div className="workflow-title">
                        <h5>Master Policy</h5>
                    </div>
                    <div className="workflow-tabs-search">
                        <Fab variant="extended" size="large" className="btn btn-large secondary-color workflow-add-btn no-shadow" onClick={this.props.handleDownloadMasterPolicyFile} style={{ minWidth: '180px' }}>
                            <img src={EyeIcon} alt="EyeIcon" className="MuiSvgIcon" />
                            Master Policy
                        </Fab>
                    </div>
                </div>
                <div className="tab-container">
                    <Scrollbars style={{ width: '100%', height: '100%' }}>
                        <section className="add-fields-wrap">
                            <div className="add-field-row">
                                <div className="added-field-grp">
                                    <div className="added-field">
                                        <InputField label="Policy Name" type="text" name="policy_name" fieldStyle="field-box bg-plane grey-color" placeholder="Enter" is_mandatory="yes" field_validations={["v_required"]} selected_value={masterPolicy !== null && masterPolicy.policy_name !== null ? masterPolicy.policy_name : ""} default_value={masterPolicy != null && masterPolicy.policy_name != null ? masterPolicy.policy_name : ""} handleOnChangeValue={this.handleMasterPolicyChange("policy_name")} />
                                    </div>
                                </div>
                            </div>
                            <div className="add-field-row">
                                <div className="added-field-grp">
                                    <div className="added-field">
                                        <TextAreaField label="Policy Interest" type="textarea" name="policy_interest" fieldStyle="field-box bg-plane grey-color" placeholder="Description" rowmax="5" selected_value={masterPolicy != null && masterPolicy.policy_interest !== null ? masterPolicy.policy_interest : ''} default_value={masterPolicy != null && masterPolicy.policy_interest !== null ? masterPolicy.policy_interest : ''} handleOnChangeValue={this.handleMasterPolicyChange("policy_interest")} />
                                    </div>
                                </div>
                            </div>
                            <div className="add-field-row">
                                <div className="added-field-grp">
                                    <div className="added-field">
                                        <InputField label="Master Policy Number" type="text" name="master_policy_number" fieldStyle="field-box bg-plane grey-color" placeholder="Enter" is_mandatory="yes" field_validations={["v_required"]} default_value={masterPolicy != null && masterPolicy.master_policy_number !== null ? masterPolicy.master_policy_number : ''} selected_value={masterPolicy != null && masterPolicy.master_policy_number !== null ? masterPolicy.master_policy_number : ''} handleOnChangeValue={this.handleMasterPolicyChange("master_policy_number")} />
                                    </div>
                                </div>
                            </div>
                            {/* <div className="add-field-row">
                                    <div className="added-field-grp">
                                        <div className="added-field">
                                            <SelectField label="Policy Type" optionsData={defalutSections} name="policy_type" is_search={false} fieldStyle="field-box bg-plane grey-color" default_value={masterPolicy!=null && masterPolicy.policy_type_select !== null ? masterPolicy.policy_type_select : ''} selected_value={masterPolicy!=null && masterPolicy.policy_type_select !== null ? masterPolicy.policy_type_select : ''} radioChangeHandle={this.handleMasterPolicyChange("policy_type_select")}/>
                                            <SelectField label="Policy Issue Date" optionsData={defalutSections} name="policy_issue_date" is_search={false} fieldStyle="field-box bg-plane grey-color" default_value={masterPolicy!=null && masterPolicy.policy_issue_date !== null? masterPolicy.policy_issue_date : ''} selected_value={masterPolicy!=null && masterPolicy.policy_issue_date !== null ? masterPolicy.policy_issue_date : ''} radioChangeHandle={this.handleMasterPolicyChange("policy_issue_date")}/>
                                        </div>
                                    </div>
                                </div> */}
                            <div className="add-field-row">
                                <div className="form-row"><InputLabel className="label">Policy Period</InputLabel></div>
                                <div className="added-field-grp">
                                    <div className="added-field">
                                        <DateRangePickerKendo
                                            name="Policy Date Range"
                                            instruction="Date Range"
                                            fieldStyle="field-box"
                                            value={{
                                                start: masterPolicy != null && masterPolicy.policy_start_date !== null ? new Date(masterPolicy.policy_start_date) : null,
                                                end: masterPolicy != null && masterPolicy.policy_end_date !== null ? new Date(masterPolicy.policy_end_date) : null,
                                            }}
                                            handleDateChange={this.handleMasterPolicyChange("policy_period")}
                                        />

                                    </div>
                                </div>
                            </div>
                            <div className="add-field-row">
                                <div className="added-field-grp">
                                    <div className="added-field">
                                        <SelectField label="Policy Duration" optionsData={defalutSections} name="policy_duration" is_search={false} default_value={masterPolicy != null && masterPolicy.policy_duration != null ? masterPolicy.policy_duration : []} fieldStyle="field-box bg-plane grey-color" selected_value={masterPolicy != null && masterPolicy.policy_duration != null ? masterPolicy.policy_duration : []} radioChangeHandle={this.handleMasterPolicyChange("policy_duration")} />
                                    </div>
                                </div>
                            </div>
                            <div className="add-field-row">
                                <div className="added-field-grp added-field-selection-grp">
                                    <div className="added-field">
                                        <RadioField
                                            radioGroupLabel="Policy Type"
                                            radioData={policyType}
                                            label="Policy Type"
                                            radioFieldStyle="field-inline"
                                            default_value={masterPolicy != null && masterPolicy.policy_type != null ? masterPolicy.policy_type : ''}
                                            selected_value={masterPolicy != null && masterPolicy.policy_type != null ? masterPolicy.policy_type : ''}
                                            radioChangeHandle={this.handleMasterPolicyChange("policy_type")}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="add-field-row">
                                <div className="form-row"><InputLabel className="label">Claim Servicing Date</InputLabel></div>
                                <div className="added-field-grp">
                                    <div className="added-field">
                                        <InputField type="text" name="policy_start_date" fieldStyle="field-box bg-plane grey-color" placeholder="No. of Periods" default_value={masterPolicy != null && masterPolicy.claim_service_date_text != null ? masterPolicy.claim_service_date_text : ''} selected_value={masterPolicy != null ? masterPolicy.claim_service_date_text : ''} handleOnChangeValue={this.handleMasterPolicyChange("claim_service_date_text")} />
                                        <SelectField optionsData={policyPeriod} name="policy_period" is_search={false} fieldStyle="field-box bg-plane grey-color" default_value={masterPolicy != null && masterPolicy.claim_service_date_select != null ? masterPolicy.claim_service_date_select : []} selected_value={masterPolicy != null ? masterPolicy.claim_service_date_select : []} radioChangeHandle={this.handleMasterPolicyChange("claim_service_date_select")} />
                                    </div>
                                </div>
                            </div>
                        </section>
                    </Scrollbars>
                </div>
                <div className="card-footer border-top p-l-0 p-r-0">
                    <div className="btn-wrap">
                        <Fab variant="extended" size="large" className="btn btn-large btn-plane primary-color" onClick={this.handleNextClick.bind(this)}>
                            Next
                        </Fab>
                        <Fab variant="extended" size="large" className="btn btn-large btn-plane grey-color" onClick={this.handleBackClick.bind(this)}>
                            Back
                        </Fab>
                    </div>
                </div>
            </div>
        )
    }
}

export default ProgramMasterPolicy;