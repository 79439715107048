import React, { Component } from 'react';
import ArrowBackGrey from '../../assets/images/icon/chevron-left.svg';
import InputField from '../../components/fields/inputs';
import RadioField from '../../components/fields/radio';
import Fab from '@material-ui/core/Fab';
import { Scrollbars } from 'react-custom-scrollbars';
import closeIcon from '../../assets/images/icon/close.svg';
import MultipleTabActions from '../../components/common/multiple-tab-actions';

class WorkflowSetting extends Component {

    state = {
        workflowDetails: {},
    }

    componentWillMount() {
        let { workflowData } = this.props;
        this.setState({ workflowDetails: JSON.parse(JSON.stringify(workflowData)) });
    }

    handleWorkflowDetailsEdit(value, type) {
        let { workflowDetails } = this.state;

        if (type === "trigger_type") {
            workflowDetails.workflow_trigger[type] = value;
        }
        else {
            workflowDetails[type] = value;
        }
        this.setState({ workflowDetails: workflowDetails });
    }

    handleSaveClick() {
        let { workflowDetails } = this.state;

        this.props.changeWorkflowDetails(workflowDetails);
        this.props.toggleDrawerWorkflowSettings("settingWorkflow", false);
    }


    render() {

        let { workflowData } = this.props;
        let { workflowDetails } = this.state;
        let workflowRecordCreated = [
            {
                field_option_label: "Created or Edited",
                field_option_value: "created_or_edited",
            },
            {
                field_option_label: "Created",
                field_option_value: "created"
            },
            {
                field_option_label: "Edited",
                field_option_value: "edited"
            },
            {
                field_option_label: "Deleted",
                field_option_value: "deleted"
            }
        ]
        return (
            <section className="workflow-setup-sec">
                <section className="setup-drawer-sec">
                    <div className="card-block">
                        <div className="card-header">
                            <div className="card-subheader">
                                <div className="card-title">
                                    {/* <div className="actions setup-close" style={{marginLeft: '-15px'}}>
                                        <div className="btn-wrap">
                                            <Fab variant="extended" size="small" className="btn btn-small btn-plane btn-fab white-color no-shadow" onClick={(event)=>{this.props.toggleDrawerWorkflowSettings("settingWorkflow", false)}}>
                                                <img alt="edit" src={ArrowBackGrey} style={{ margin: 0 }}></img> Go Back
                                            </Fab>
                                        </div>
                                    </div> */}
                                </div>
                                <div className="card-left-title">
                                    <div className="actions setup-close">
                                        <div className="btn-wrap">
                                            <Fab variant="extended" size="small" className="btn btn-small btn-plane btn-fab white-color no-shadow" onClick={(event) => { this.props.toggleDrawerWorkflowSettings("settingWorkflow", false) }}>
                                                Close <img alt="edit" src={closeIcon}></img>
                                            </Fab>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card-header border-bottom" style={{ padding: '8px 0px 25px' }}>
                            <div className="card-title">
                                <h2>Workflow Properties</h2>
                            </div>
                            {/* <div className="card-left-title">
                                <MultipleTabActions type="icons" iconList={["edit","delete"]}/>
                            </div> */}
                        </div>
                        <div className="card-body">
                            <div role="presentation" className="drawer-sec">
                                <div className="drawer-form-sec">
                                    <div className="tab-container tab-container-scroll" style={{ height: 'calc(100vh - 295px)' }}>
                                        <Scrollbars style={{ width: '100%', height: '100%' }}>
                                            <div className="state-field-add">
                                                <InputField
                                                    label="Workflow Name"
                                                    type="text" name="workflow_name"
                                                    placeholder="Enter"
                                                    customStyle="medium-row"
                                                    selected_value={workflowDetails ? workflowDetails.workflow_name : ""}
                                                    handleOnChangeValue={(value) => this.handleWorkflowDetailsEdit(value, "workflow_name")}
                                                />

                                                <RadioField
                                                    radioGroupLabel="When record is"
                                                    radioData={workflowRecordCreated}
                                                    label="When record is"
                                                    selected_value={workflowDetails ? (workflowDetails.workflow_trigger ? workflowDetails.workflow_trigger.trigger_type : '') : ""}
                                                    radioChangeHandle={(value) => this.handleWorkflowDetailsEdit(value, "trigger_type")}
                                                />

                                                {/* <IconFilterPopover fieldStyle="field-box bg-plane grey-color field-icon" customStyle="adornment-only-icon" label="Select Icon Upload"/> */}
                                            </div>
                                        </Scrollbars>
                                    </div>
                                    <div className="card-footer border-top">
                                        <div className="btn-wrap">
                                            <Fab variant="extended" size="large" className="btn btn-large btn-plane primary-color" onClick={() => this.handleSaveClick()}>Save</Fab>
                                            <Fab variant="extended" size="large" className="btn btn-large btn-plane grey-color" onClick={(event) => { this.props.toggleDrawerWorkflowSettings("settingWorkflow", false) }}>Cancel</Fab>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </section>
        )
    }
}

export default WorkflowSetting;