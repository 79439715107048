import React, { Component } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import Fab from '@material-ui/core/Fab';
import SelectField from '../../components/fields/select';
import SwitchButtonActions from '../../components/fields/switch-button-action';
import RadioField from '../../components/fields/radio';
import InputField from '../../components/fields/inputs';
import TextAreaField from '../../components/fields/textarea';
import AutoCompleteField from '../../components/fields/auto-complete-field';
import { fetchProfileImageClient } from '../../actions/profileImageAction';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import * as FormApi from '../../api/form-api';
import * as ConfigApi from '../../api/configuration-api';
import * as ClientApi from '../../api/client-api';

//Constants
import * as Constants from '../../utilities/constants';

//  * @author milind on 4-02-2021
//  * @{project} assurekit
class ConfigBasicDetails extends Component {

    state = {
        stakeholder: {},
        brandColor: '#0000FF',
        multipleInsurers: [],
        active: true,
        defaultQuote: '',
        formList: [],
        insurerList: [],
        sortedList: [],
        sortedByValue: [],
        createConfigBasicDetails: {
            show_vehicle_edit_button: true,
            motor_type: '2W'
        },
        isActiveRcValidation: false,
        isActiveEditButton: true,
        isActivePolicyTypeFilter: false,
        isActiveShowIdvFilter: false,
        isActiveShowAddOnsFilter: true,
        isActiveUseClientLogo: false,
        isActiveUseClientColor: false,
        isActivePoweredByAssurekit: true, // default so that every config creation shows this by default
        isActiveUseClientContent: false,
        quoteSortBy: 'I',
        formKey: '',
        inspectionFormKey: '',
        idvFilter: '',
        policyFilter: '',
        hexValue: '',
        motorConfigId: 0,
        sortedInsurers: '',
        value: [],
        clientData: {},
        isIntermediary: false,
        remainingChar: "no",
        clientContent: false
    }

    constructor(props) {
        super(props);
        //    const match = this.props.match;
        //    let programkey = match.params.programkey;
        this.handleChangeSwitchButton(this.state.brandColor, "hex_value");
        this.getAllInsurerKit(this.props.programData.client_id, this.props.programData.program_key);
        this.getAllFormData(this.props.programkey);


        ClientApi.getClientById(this.props.programData.client_id).then(res => {
            this.setState({ clientData: res });
            this.getProfileImage(res.profile_image_id);
            this.handleChangeSwitchButton(res.profile_image_id, "client_profile_image_id");
            this.setState({ isIntermediary: res.intermediary });
        })

    }

    getProfileImage(profileImageId) {
        if (profileImageId && !this.props.profileImage) {
            this.props.fetchProfileImageClient(profileImageId);
        }
    }

    setCreateConfigBasicDetails(createConfigBasicDetails) {
        this.setState({ createConfigBasicDetails: createConfigBasicDetails });
    }

    getConfigData(clienId, programKey) {
        ConfigApi.getConfig(clienId, programKey).then(res => {
            let createConfigBasicDetails = {};
            let value = []
            res.associated_insurers.forEach(insurer => {
                value.push(insurer.insurer_kit_id + '');
            })
            this.handleMultipleInsurers("selected_insurers", value);

            createConfigBasicDetails["selected_insurers"] = value
            // // this.setState({ multipleInsurers: value })
            this.setState({ createConfigBasicDetails: createConfigBasicDetails });

            this.props.addMotorConfigId(res.id);
            this.setState({ motorConfigId: res.id })
            this.setState({ formKey: res.form_key })
            this.setState({ inspectionFormKey: res.inspection_form_key })
            this.setState({ idvFilter: res.idv_filter_type })
            this.setState({ policyFilter: res.policy_filter_type })
            this.setState({ quoteSortBy: res.quote_sort_by })
            this.setState({ isActiveRcValidation: res.allow_rc_validation })
            this.setState({ isActiveEditButton: res.show_vehicle_edit_button })
            this.setState({ isActiveShowAddOnsFilter: res.show_add_ons_filter })
            this.setState({ isActivePoweredByAssurekit: res.show_powered_by_assurekit })
            this.setState({ isActivePolicyTypeFilter: res.policy_filter_type !== null ? true : false })
            this.setState({ isActiveShowIdvFilter: res.idv_filter_type !== null ? true : false })
            this.setState({ isActiveUseClientLogo: res.client_profile_image_id === null ? false : true })
            this.setState({ isActiveUseClientColor: res.hex_value === null ? false : true })
            this.setState({ isActiveUseClientContent: res.client_quote_content === Constants.QUOTES_CONTENT.CONTENT || res.client_quote_content === null ? false : true })
            this.setState({ remainingChar: res.client_quote_content === Constants.QUOTES_CONTENT.CONTENT || res.client_quote_content === null ? "no" : "yes" })

            createConfigBasicDetails["quote_sort_value"] = res.quote_sort_value;
            createConfigBasicDetails["form_key"] = res.form_key;
            createConfigBasicDetails["inspection_form_key"] = res.inspection_form_key;
            createConfigBasicDetails["motor_type"] = res.type;
            createConfigBasicDetails["client_profile_image_id"] = res.client_profile_image_id;

            this.handleChangeSwitchButton(res.allow_rc_validation, "allow_rc_validation");
            this.handleChangeSwitchButton(res.show_vehicle_edit_button, "show_vehicle_edit_button");
            this.handleChangeSwitchButton(res.policy_filter_type === null ? "system_default" : res.policy_filter_type, "policy_filter_type");
            this.handleChangeSwitchButton(res.idv_filter_type === null ? "minimum" : res.idv_filter_type, "idv_filter_type");
            this.handleChangeSwitchButton(res.policy_filter_type !== null ? true : false, "show_policy_type_filter");
            this.handleChangeSwitchButton(res.idv_filter_type !== null ? true : false, "show_idv_filter");
            this.handleChangeSwitchButton(res.show_add_ons_filter, "show_add_ons_control");
            this.handleChangeSwitchButton(res.show_powered_by_assurekit, "show_powered_by_assurekit");
            this.handleChangeSwitchButton(res.hex_value === null ? this.state.brandColor : res.hex_value, "hex_value");
            this.handleChangeSwitchButton(res.quote_sort_value, "quote_sort_value");
            this.handleChangeSwitchButton(res.client_profile_image_id === null ? null : res.client_profile_image_id, "client_profile_image_id");
            this.handleChangeSwitchButton(res.client_quote_content === Constants.QUOTES_CONTENT.CONTENT ? Constants.QUOTES_CONTENT.CONTENT : res.client_quote_content, "client_quote_content");

            if (res.quote_sort_by === "P") {
                this.setState({ sortedInsurers: res.quote_sort_value })
                this.handleSortByChange("quote_sort_by", res.quote_sort_by);

            } else {

                this.setState({ multipleInsurers: value })
                // this.handleMultipleInsurers("selected_insurers", value);
                this.handleSortByChange("quote_sort_by", res.quote_sort_by);
            }

            // this.handleGetSortedValue("sort", res.quote_sort_value);
            this.setState({ createConfigBasicDetails: createConfigBasicDetails });
        });

    }

    getAllFormData(programkey) {

        FormApi.getAllFormData(programkey).then(res => {
            let formList = [];
            res.forEach(form => {
                let obj = {
                    field_option_label: form.form_name,
                    field_option_value: form.form_key
                };
                formList.push(obj);
            })
            this.setState({ formList: formList });
        });
    }

    getAllInsurerKit(clienId, programKey) {
        ConfigApi.getAllInsurerKit(clienId, programKey).then(res => {
            let insurerList = [];
            res.forEach(optionData => {
                let obj = {
                    field_option_label: optionData.insurer_name,
                    field_option_value: optionData.insurer_id + ''
                };
                insurerList.push(obj);
            })
            this.setState({ insurerList: insurerList });
            this.getConfigData(this.props.programData.client_id, this.props.programData.program_key);
        })
    }

    handleOnFormSelection(key, type, value) {
        this.setState({ formSelection: key })
    }

    handleGetSortedValue(sort, check) {

        let sortedList = [];
        this.state.insurerList.forEach(optionData => {

            if (sort == 'sort') {
                if (optionData.field_option_value === check) {
                    let obj = {
                        field_option_label: optionData.field_option_label,
                        field_option_value: optionData.field_option_value
                    };
                    sortedList.push(obj);
                }
            } else {
                if (optionData.checked) {
                    let obj = {
                        field_option_label: optionData.field_option_label,
                        field_option_value: optionData.field_option_value
                    };
                    sortedList.push(obj);
                }
            }

        })
        this.setState({ sortedList: sortedList });
    }

    handleMultipleInsurers(key, value) {
        let { insurerList } = this.state;
        let associatedInsurers = []

        //Convert value to array if it's string
        if (typeof value === 'string') {
            value = value.split(",");
        }
        //adding existing selected values to list
        insurerList.forEach(list => {
            list.checked = (value.includes(list.field_option_value));
        })
        this.setState({ insurerList: insurerList });
        this.handleGetSortedValue('', key);

        this.state.insurerList.forEach(optionData => {
            if (optionData.checked) {
                associatedInsurers.push(optionData.field_option_value)
            }
        })
        this.handleChangeSwitchButton(associatedInsurers, key);
    }

    handleConfigChange = (type) => (value) => {
        let { createConfigBasicDetails } = this.state;
        createConfigBasicDetails[type] = value;
        this.setState({ createConfigBasicDetails: createConfigBasicDetails });
    }

    handleChangeSwitchButton(key, type) {
        let { createConfigBasicDetails } = this.state;
        createConfigBasicDetails[type] = key;
        this.setState({ createConfigBasicDetails: createConfigBasicDetails });
    }

    handleToggleChange = (key) => () => {
        if (key === "allow_rc_validation") {
            const updatedRCValidationState = !this.state.isActiveRcValidation
            this.setState({ isActiveRcValidation: updatedRCValidationState });
            this.handleChangeSwitchButton(updatedRCValidationState, key)
            if (!updatedRCValidationState) {
                this.setState({ isActiveEditButton: true });
                this.handleChangeSwitchButton(true, "show_vehicle_edit_button")
            }
        }
        if (key === "show_vehicle_edit_button") {

            this.setState({ isActiveEditButton: !this.state.isActiveEditButton });
            this.handleChangeSwitchButton(!this.state.isActiveEditButton, key)
        }
        if (key === "show_policy_type_filter") {

            this.setState({ isActivePolicyTypeFilter: !this.state.isActivePolicyTypeFilter });
            this.handleChangeSwitchButton(!this.state.isActivePolicyTypeFilter, key)
        }
        if (key === "show_idv_filter") {

            this.setState({ isActiveShowIdvFilter: !this.state.isActiveShowIdvFilter });
            this.handleChangeSwitchButton(!this.state.isActiveShowIdvFilter, key)
        }
        if (key === "show_powered_by_assurekit") {

            this.setState({ isActivePoweredByAssurekit: !this.state.isActivePoweredByAssurekit });
            this.handleChangeSwitchButton(!this.state.isActivePoweredByAssurekit, key)
        }
        if (key === "show_add_ons_control") {

            this.setState({ isActiveShowAddOnsFilter: !this.state.isActiveShowAddOnsFilter });
            this.handleChangeSwitchButton(!this.state.isActiveShowAddOnsFilter, key)
        }
        if (key === "use_client_logo") {

            this.setState({ isActiveUseClientLogo: !this.state.isActiveUseClientLogo });
            this.handleChangeSwitchButton(!this.state.isActiveUseClientLogo, key)

            if (this.state.isActiveUseClientLogo) {

                this.handleChangeSwitchButton(null, "client_profile_image_id");
            } else {

                this.handleChangeSwitchButton(this.state.clientData.profile_image_id, "client_profile_image_id");
            }
        }
        if (key === "use_client_content") {
            console.log("isActiveUseClientContent", this.state.isActiveUseClientContent);
            this.setState({ isActiveUseClientContent: !this.state.isActiveUseClientContent });
            this.handleChangeSwitchButton(!this.state.isActiveUseClientContent, key)
            if (!this.state.isActiveUseClientContent) {
                this.handleChangeSwitchButton(null, "client_quote_content");
                this.handleChangeSwitchButton(this.state.createConfigBasicDetails.client_quote_content, "client_quote_content");
                this.setState({ remainingChar: "yes" })
            } else {
                this.handleChangeSwitchButton(Constants.QUOTES_CONTENT.CONTENT, "client_quote_content");
                this.setState({ remainingChar: "no" })
            }
            this.setState({ clientContent: false });
        }

        if (key === "use_custom_color") {

            this.setState({ isActiveUseClientColor: !this.state.isActiveUseClientColor });
            this.handleChangeSwitchButton(!this.state.isActiveUseClientColor, key)
        }
        if (key === "intermediary") {
            this.setState({ isIntermediary: !this.state.isIntermediary }, () => { this.handleClientData(key)(this.state.isIntermediary) });
        }
    }

    handleNextCheck() {
        if (this.state.isActiveUseClientContent) {
            if (this.state.createConfigBasicDetails.client_quote_content === null || this.state.createConfigBasicDetails.client_quote_content === '') {

                this.setState({ clientContent: true });
            } else {
                this.setState({ clientContent: false });
                this.handleNext();
            }
        } else {
            this.setState({ clientContent: false });
            this.handleNext();
        }
    }

    handleNext() {

        let { createConfigBasicDetails } = this.state;
        let { motorConfigId } = this.state
        if (!(this.state.isActiveUseClientColor)) {
            this.handleChangeSwitchButton(null, "hex_value");
        }

        if (this.state.quoteSortBy) {
            this.handleChangeSwitchButton(this.state.quoteSortBy, "quote_sort_by");
        }

        if (this.state.isActivePolicyTypeFilter && !createConfigBasicDetails.policy_filter_type) {
            this.handleChangeSwitchButton('system_default', "policy_filter_type");
        }

        if (this.state.isActiveShowIdvFilter && !createConfigBasicDetails.idv_filter_type) {
            this.handleChangeSwitchButton('minimum', "idv_filter_type");
        }

        if (createConfigBasicDetails?.selected_insurers?.length !== 0) {
            if (this.props.programData.client_id) {
                ClientApi.updateClient(this.props.programData.client_id, this.state.clientData).then(res => { })
            }
            if (motorConfigId > 0) {
                ConfigApi.updateConfig(createConfigBasicDetails, this.props.programData.client_id, this.props.programData.program_key, motorConfigId).then(res => {
                    this.props.modifyProgramSelection(Constants.CONFIG_PROCESS.INSURERS, 2);
                });
            } else {
                ConfigApi.createConfig(createConfigBasicDetails, this.props.programData.client_id, this.props.programData.program_key).then(res => {
                    this.props.addMotorConfigId(res.id);
                    this.props.modifyProgramSelection(Constants.CONFIG_PROCESS.INSURERS, 2);
                });

            }
        }
    }

    handleSortByChange(key, value) {
        let sortByPremium = [
            {
                field_option_label: "Low To High",
                field_option_value: "LOW_To_HIGH",
            },
            {
                field_option_label: "High To Low",
                field_option_value: "HIGH_TO_LOW"
            }
        ]

        let sortedInsurerList = [];
        this.setState({ quoteSortBy: value });
        this.handleChangeSwitchButton(value, key);
        if (value === "P") {
            this.setState({ sortedList: sortByPremium });

        } else {
            if (this.state.createConfigBasicDetails['selected_insurers'] !== undefined) {
                this.state.insurerList.forEach(insurerKit => {
                    if (this.state.createConfigBasicDetails['selected_insurers'].includes(insurerKit.field_option_value)) {
                        sortedInsurerList.push(insurerKit);
                    }
                })
                this.setState({ sortedList: sortedInsurerList });
            } else {
                this.setState({ sortedList: [] });
            }

        }
        this.setState({ sortedInsurers: value })
    }

    handleClientData = (key) => (val) => {
        let { clientData } = this.state;
        clientData[key] = val;
        this.setState({ clientData: clientData });
    }

    render() {
        let { brandColor, active, defaultQuote, formList, insurerList, sortedList, createConfigBasicDetails, isActiveRcValidation,
            isActiveUseClientContent, remainingChar, isActiveEditButton, isActivePolicyTypeFilter, isActiveShowIdvFilter, isActiveShowAddOnsFilter, isActiveUseClientColor, isActiveUseClientLogo,
            sortedByValue, quoteSortBy, formKey, inspectionFormKey, idvFilter, policyFilter, hexValue, motorConfigId, sortedInsurers, value, isIntermediary, clientData, clientContent, isActivePoweredByAssurekit } = this.state;

        let motorTypes = [
            {
                field_option_label: "Two Wheeler",
                field_option_value: "2W",
            },
            {
                field_option_label: "Four Wheeler",
                field_option_value: "4W",
            }
        ];

        let policyFilterType = [
            {
                field_option_label: "System Default",
                field_option_value: "system_default",
            },
            {
                field_option_label: "Comprehensive",
                field_option_value: "comprehensive",
            },
            {
                field_option_label: "Own Damage",
                field_option_value: "own_damage"
            },
            {
                field_option_label: "Third Party",
                field_option_value: "third_party"
            }
        ]
        let VehicleIDV = [
            {
                field_option_label: "Minimum",
                field_option_value: "minimum",
            },
            {
                field_option_label: "Recommended",
                field_option_value: "recommended"
            },
            {
                field_option_label: "Maximum",
                field_option_value: "maximum"
            }
        ]
        let defaultQuoteList = [
            {
                field_option_label: "Prefered Insurance",
                field_option_value: "I",
            },
            {
                field_option_label: "By Premium Value",
                field_option_value: "P"
            }
        ]
        return (
            <div className="drawer-tabs workflow-tabs-wrap">
                <div className="drawer-tabs-header border-bottom">
                    <div className="workflow-title">
                        <h5>Basic Details</h5>
                    </div>
                    <div className="workflow-tabs-search"></div>
                </div>
                <div className="tab-container m-0">
                    <Scrollbars style={{ width: '100%', height: '100%' }}>
                        <div className="profile-sec-wrap">

                            <div className="profile-sec-body">

                                <div className="drawer-tabs-header ">
                                    <div className="workflow-title">
                                        <h5 className='p-b-10'>Generic Control</h5>
                                        <h6 className="grey-color weight-300 p-b-10">These will set the basic guiding rule set for AssureGate</h6>
                                        <div className="workflow-tabs-search"></div>
                                    </div>
                                </div>
                            </div>
                            <br></br>
                            <section className="add-fields-wrap">
                                <div className="add-field-row">
                                    <div className="added-field-grp added-single-grp">
                                        <div className="added-field">

                                            <br></br>
                                            <SelectField
                                                label="Motor Type"
                                                withLabel={true}
                                                optionsData={motorTypes}
                                                selected_value={createConfigBasicDetails.motor_type ? createConfigBasicDetails.motor_type : '2W'}
                                                fieldStyle="field-box bg-plane grey-color"
                                                radioChangeHandle={(key, value, errors) => this.handleChangeSwitchButton(key, "motor_type")}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="add-field-row">
                                    <div className="added-field-grp added-single-grp">
                                        <div className="added-field">
                                            <SelectField
                                                label="Policy form"
                                                withLabel={true}
                                                optionsData={formList}
                                                selected_value={createConfigBasicDetails.form_key ? createConfigBasicDetails.form_key : formKey}
                                                fieldStyle="field-box bg-plane grey-color"
                                                radioChangeHandle={(key, value, errors) => this.handleChangeSwitchButton(key, "form_key")}
                                            />
                                            {createConfigBasicDetails.motor_type === '4W' && <SelectField
                                                label="Inspection form"
                                                withLabel={true}
                                                optionsData={formList}
                                                selected_value={createConfigBasicDetails.inspection_form_key ? createConfigBasicDetails.inspection_form_key : inspectionFormKey}
                                                fieldStyle="field-box bg-plane grey-color"
                                                radioChangeHandle={(key, value, errors) => this.handleChangeSwitchButton(key, "inspection_form_key")}
                                            />}
                                        </div>
                                    </div>
                                </div>
                                <div className="add-field-row">
                                    <div className="added-field-grp added-more-width">
                                        <div className="added-field">
                                            {/* <SelectField
                                                label="Associated Insurance"
                                                withLabel={true}
                                                isMultiple={true}
                                                optionsData={insurerList}
                                                selected_value={createConfigBasicDetails.selected_insurers ? this.state.multipleInsurers : createConfigBasicDetails.selected_insurers}
                                                fieldStyle="field-box bg-plane grey-color"
                                                radioChangeHandle={(key, value, errors) => this.handleMultipleInsurers("selected_insurers", key)}
                                            /> */}
                                            <AutoCompleteField
                                                label="Associated Insurance"
                                                withLabel={true}
                                                fieldStyle="field-box bg-plane grey-color"
                                                optionsData={insurerList}
                                                handleMultiSelectChange={(key, value, errors) => this.handleMultipleInsurers("selected_insurers", key, errors)}
                                                is_mandatory="yes"
                                                field_validations={["v_required"]}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <section className="add-fields-wrap">
                                <div className="add-field-row">
                                    <div className="added-field-grp added-more-width">
                                        <div className="added-field">
                                            <SwitchButtonActions
                                                label="Intermediary"
                                                fieldStyle="field-box bg-plane grey-color"
                                                isChecked={isIntermediary}
                                                // headerLabel="Brand Colour"
                                                withLabel={true}
                                                onChange={this.handleToggleChange("intermediary").bind(this)}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="add-field-row">
                                    <div className="added-field-grp added-single-grp">
                                        <div className="added-field">

                                            <br></br>
                                            <InputField
                                                label="Intermediary Name"
                                                type="text" fieldStyle="field-box bg-plane grey-color"
                                                placeholder="Intermediary Name"
                                                selected_value={clientData.intermediary_name ? clientData.intermediary_name : ""}
                                                handleOnChangeValue={this.handleClientData("intermediary_name").bind(this)}
                                                disabled={!isIntermediary}

                                            />
                                            <InputField
                                                label="IRDAI Registration No"
                                                type="text" fieldStyle="field-box bg-plane grey-color"
                                                selected_value={clientData.irdai_registration_no ? clientData.irdai_registration_no : ""}
                                                placeholder="IRDAI Registration No"
                                                handleOnChangeValue={this.handleClientData("irdai_registration_no").bind(this)}
                                                disabled={!isIntermediary}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="add-field-row">
                                    <div className="added-field-grp added-single-grp">
                                        <div className="added-field">
                                            <InputField
                                                label="Valid Till"
                                                type="date" fieldStyle="field-box bg-plane grey-color"
                                                selected_value={clientData.valid_till ? clientData.valid_till : ""}
                                                handleOnChangeValue={this.handleClientData("valid_till").bind(this)}
                                                disabled={!isIntermediary}
                                            />
                                            <InputField
                                                label="CIN"
                                                type="text" fieldStyle="field-box bg-plane grey-color"
                                                selected_value={clientData.cin ? clientData.cin : ""}
                                                placeholder="CIN"
                                                handleOnChangeValue={this.handleClientData("cin").bind(this)}
                                                disabled={!isIntermediary}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <br></br>
                            <div className="drawer-tabs-header ">
                                <div className="workflow-title">
                                    <h5 className='p-b-10'>Get Details Section Control</h5>
                                    <div className="workflow-tabs-search"></div>
                                </div>
                            </div>
                            <br></br>
                            <section className="add-fields-wrap">
                                <div className="add-field-row">
                                    <div className="added-field-grp added-single-grp">
                                        <div className="added-field">
                                            <SwitchButtonActions
                                                label="Vehicle Data Extraction"
                                                fieldStyle="field-box bg-plane grey-color"
                                                isChecked={isActiveRcValidation}
                                                withLabel={true}
                                                onChange={this.handleToggleChange("allow_rc_validation").bind(this)}
                                            />
                                        </div>
                                        {
                                            isActiveRcValidation &&
                                            <div className="added-field">
                                                <SwitchButtonActions
                                                    label="Show vehicle edit option"
                                                    fieldStyle="field-box bg-plane grey-color"
                                                    isChecked={isActiveEditButton}
                                                    withLabel={true}
                                                    onChange={this.handleToggleChange("show_vehicle_edit_button").bind(this)}
                                                />
                                            </div>
                                        }
                                    </div>
                                </div>
                            </section>
                            <br></br>
                            <div className="drawer-tabs-header ">
                                <div className="blank-page-info">
                                    <h5 className='p-b-10'>Filter-Sort Control</h5>
                                    <h6 className="grey-color weight-300 p-b-10">These will allow the clients to control the ways consumers find the policy they want.</h6>
                                    <div className="workflow-tabs-search"></div>
                                </div>
                            </div>
                            <br></br>
                            <section className="add-fields-wrap">
                                <div className="add-field-row">
                                    <div className="added-field-grp added-single-grp">
                                        <div className="added-field">
                                            <SwitchButtonActions
                                                label="Show Policy Type Filter"
                                                fieldStyle="field-box bg-plane grey-color"
                                                isChecked={isActivePolicyTypeFilter}
                                                withLabel={true}
                                                onChange={this.handleToggleChange("show_policy_type_filter").bind(this)}
                                            />
                                        </div>
                                        <div className="added-field" style={{ display: isActivePolicyTypeFilter ? "block" : "none" }}>
                                            <RadioField
                                                radioData={policyFilterType}
                                                radioFieldStyle="field-inline"
                                                default_value={createConfigBasicDetails.policy_filter_type ? createConfigBasicDetails.policy_filter_type : 'system_default'}
                                                selected_value={createConfigBasicDetails.policy_filter_type ? createConfigBasicDetails.policy_filter_type : policyFilter}
                                                radioChangeHandle={this.handleConfigChange("policy_filter_type").bind(this)}
                                            />

                                        </div>
                                    </div>
                                </div>
                                <div className="add-field-row">
                                    <div className="added-field-grp added-single-grp">
                                        <div className="added-field">
                                            <SwitchButtonActions
                                                label="Show Vehicle IDV filter"
                                                fieldStyle="field-box bg-plane grey-color"
                                                isChecked={isActiveShowIdvFilter}
                                                withLabel={true}
                                                onChange={this.handleToggleChange("show_idv_filter").bind(this)}
                                            />
                                        </div>
                                        <div className="added-field" style={{ display: isActiveShowIdvFilter ? "block" : "none" }}>
                                            <RadioField
                                                radioData={VehicleIDV}
                                                radioFieldStyle="field-inline"
                                                default_value={createConfigBasicDetails.idv_filter_type ? createConfigBasicDetails.idv_filter_type : 'minimum'}
                                                selected_value={this.state.createConfigBasicDetails.idv_filter_type ? this.state.createConfigBasicDetails.idv_filter_type : idvFilter}
                                                radioChangeHandle={this.handleConfigChange("idv_filter_type").bind(this)}
                                            />
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="add-field-row">
                                    <div className="added-field-grp">
                                        <div className="added-field">
                                            <SwitchButtonActions
                                                label="Show Add-ons Control"
                                                fieldStyle="field-box bg-plane grey-color"
                                                isChecked={isActiveShowAddOnsFilter}
                                                withLabel={true}
                                                onChange={this.handleToggleChange("show_add_ons_control").bind(this)}
                                            />

                                        </div>
                                    </div>
                                </div> */}
                            </section>
                            <br></br>
                            <div className="drawer-tabs-header ">
                                <div className="workflow-title">
                                    <h5 className='p-b-10'>Default quote sort by</h5>
                                    <div className="workflow-tabs-search"></div>
                                </div>
                            </div>
                            <br></br>
                            <section className="add-fields-wrap">
                                <div className="add-field-row">
                                    <div className="added-field-grp added-single-grp">
                                        <div className="added-field">
                                            <RadioField
                                                radioData={defaultQuoteList}
                                                radioFieldStyle="field-inline"
                                                default_value={quoteSortBy}
                                                selected_value={createConfigBasicDetails.quote_sort_by ? quoteSortBy : createConfigBasicDetails.quote_sort_by}
                                                radioChangeHandle={(key, value, errors) => this.handleSortByChange("quote_sort_by", key)}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="add-field-row">
                                    <div className="added-field-grp added-single-grp">
                                        <div className="added-field">
                                            <SelectField
                                                withLabel={false}
                                                optionsData={sortedList}
                                                selected_value={createConfigBasicDetails.quote_sort_value == '' ? sortedInsurers : createConfigBasicDetails.quote_sort_value}
                                                // default_value={createConfigBasicDetails.quote_sort_value}
                                                fieldStyle="field-box bg-plane grey-color"
                                                radioChangeHandle={(key, value, errors) => this.handleChangeSwitchButton(key, "quote_sort_value")}
                                            />

                                        </div>
                                    </div>
                                </div>
                            </section>
                            <br></br>
                            <div className="drawer-tabs-header ">
                                <div className="workflow-title">
                                    <h5 className='p-b-10'>Visual Control</h5>
                                    <h6 className="grey-color weight-300 p-b-10">These setting will define the overall visual representation of AssureGate from a client' perspective.</h6>
                                    <div className="workflow-tabs-search"></div>
                                </div>
                            </div>
                            <br></br>
                            <section className="add-fields-wrap">
                                <div className="add-field-row">
                                    <div className="added-field-grp added-single-grp">
                                        <div className="added-field">
                                            <SwitchButtonActions
                                                label="Choose Client Logo"
                                                fieldStyle="field-box bg-plane grey-color"
                                                isChecked={isActiveUseClientLogo}
                                                headerLabel="Logo Control"
                                                withLabel={true}
                                                onChange={this.handleToggleChange("use_client_logo").bind(this)}
                                            />
                                        </div>
                                        <div className="added-field field-extend" >
                                            <div className="profile-sec-avtr">
                                                {/* <Avatar alt="profile" className="avatar-img" src={this.props.profileImage ? this.props.profileImage : Blank_Avatar} /> */}
                                                <img alt="" src={isActiveUseClientLogo ? this.props.profileImage : Constants.CONFIG.LOGO} className="client-profile" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="add-field-row">
                                    {/* <div className="added-field-grp added-single-grp"> */}
                                    <div className="added-field">
                                        <SwitchButtonActions
                                            label="Show it on footer"
                                            fieldStyle="field-box bg-plane grey-color"
                                            isChecked={isActivePoweredByAssurekit}
                                            headerLabel="Powered by Assurekit"
                                            withLabel={true}
                                            onChange={this.handleToggleChange("show_powered_by_assurekit").bind(this)}
                                        />

                                    </div>
                                    {/* </div> */}
                                </div>
                                <div className="add-field-row">
                                    <div className="added-field-grp added-single-grp">
                                        <div className="added-field">
                                            <SwitchButtonActions
                                                label="Use Client Colour"
                                                fieldStyle="field-box bg-plane grey-color"
                                                isChecked={isActiveUseClientColor}
                                                headerLabel="Brand Colour"
                                                withLabel={true}
                                                onChange={this.handleToggleChange("use_custom_color").bind(this)}
                                            />

                                        </div>
                                        <div className="added-field field-extend" style={{ display: createConfigBasicDetails.use_custom_color || isActiveUseClientColor ? "inherit" : "none" }} >
                                            <InputField
                                                type="text" fieldStyle="field-box bg-plane grey-color"
                                                selected_value={createConfigBasicDetails.hex_value}
                                                handleOnChangeValue={this.handleConfigChange("hex_value").bind(this)}
                                            />
                                            <input type="text" class="brand-color-change" style={{ background: createConfigBasicDetails.hex_value }} disabled />
                                        </div>
                                    </div>
                                </div>
                                <div className="add-field-row">
                                    <div className="added-field-grp added-single-grp">
                                        <div className="added-field">
                                            <SwitchButtonActions
                                                label="Use Client's Content"
                                                fieldStyle="field-box bg-plane grey-color"
                                                isChecked={isActiveUseClientContent}
                                                headerLabel="No Quotes Contents"
                                                withLabel={true}
                                                onChange={this.handleToggleChange("use_client_content").bind(this)}
                                            />
                                        </div>
                                        <div className="added-field field-extend">
                                            <TextAreaField type="textarea" remaining_char_limit={160} remaining_char={remainingChar} fieldStyle="field-box bg-plane grey-color" placeholder="Description" rowmax="4" selected_value={isActiveUseClientContent ? createConfigBasicDetails.client_quote_content : Constants.QUOTES_CONTENT.CONTENT} handleOnChangeValue={this.handleConfigChange("client_quote_content").bind(this)} />
                                            {this.state.clientContent ?
                                                <span style={{ color: "red", paddingTop: '99px' }}>Required</span> : ''
                                            }
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </Scrollbars>
                </div>
                <div className="card-footer border-top p-l-0 p-r-0">
                    <div className="btn-wrap justify-space-between">
                        <Fab variant="extended" size="large" disabled={motorConfigId ? false : true} className="btn btn-large btn-outline pink-color" onClick={this.props.handleShowConfirmDeleteDialog}>
                            Delete
                        </Fab>
                        <Fab variant="extended" size="large" className="btn btn-large btn-plane primary-color" onClick={this.handleNextCheck.bind(this)}>
                            Next
                        </Fab>
                    </div>
                </div>
            </div>
        )
    }
}

ConfigBasicDetails.propTypes = {
    fetchProfileImageClient: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
    profileImage: state.profileImage.profileImageClient,
});

export default connect(mapStateToProps, { fetchProfileImageClient })(ConfigBasicDetails);