import Fab from '@material-ui/core/Fab';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import React, { Component } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
//Custom component
import FormViewComponent from '../form-view/form-view';
import DetailsTimeline from './details-timeline';
import AlertBox from '../../components/common/alert-box';
import { withRouter } from 'react-router';

import IntegrationsView from '../form-view/integrations-view';

class DetailsActionStatus extends Component {

    state = {
        selectedTab: "action",
    }

    handleSelectState(key, value) {
        this.setState({ [key]: value });
    }

    handleDataChangeEvent(key, value, callback) {
        console.log("handleDataChangeEvent", key, value);
        if (key.split(".").length > 1) {
            let splitKeys = key.split(".");
            key = splitKeys[0];
            let valueObj = this.state[key];
            if (!valueObj) {
                valueObj = {};
            }
            valueObj[splitKeys[1]] = value;
            value = valueObj;
        }
        console.log(key, value);
        this.setState({ [key]: value }, callback);
    }

    checkAvailableActions(processData) {
        let availableActions = false;
        if (processData && processData.hasOwnProperty("current_form") && processData.current_form) {
            processData.current_form.form_field_list.forEach(list => {
                if (list.field_type === "f-action-but-group") {
                    list.field_option_list.forEach(option => {
                        if (!(option.is_hidden === "yes")) {
                            availableActions = true;
                        }
                    })
                }
                if (list.field_type !== "f-action-but-group") {
                    if (!(list.is_hidden === "yes")) {
                        availableActions = true;
                    }
                }
            })
        }
        return availableActions
    }

    selectColorOnAlertType(alertType) {
        switch (alertType) {
            case "warning-type": return "#F4A460";
            case "error-type": return "#8B0000";
            case "success-type": return "#008000"
            default: return "#F4A460";
        }
    }

    selectAlertType(alertType) {
        switch (alertType) {
            case "warning-type": return "warning";
            case "error-type": return "error";
            case "success-type": return "success";
            default: return "warning";
        }
    }

    selectAlertStatusType(alertType) {
        switch (alertType) {
            case "warning-type": return "warningAlert";
            case "error-type": return "errorAlert";
            case "success-type": return "successAlert";
            default: return "warning";
        }
    }

    getCurrentStateName(sectionData) {
        if (sectionData.program_state_list[0]) {
            sectionData.current_state_name = Object.values(sectionData.program_state_list[0])[0]
        }
    }

    render() {
        console.log("details action status props:", this.props);
        let { selectedTab } = this.state;
        let { currentFormData, sectionData, getModalFormData, process_id, afterSaveEvent, current_state, current_sub_state, updateData, processData, warningMessages } = this.props;

        console.log("warningMessages", warningMessages);
        console.log("currentFormData122", currentFormData, "getModalFormData", getModalFormData, "processId", process_id, "afterSaveEvent", afterSaveEvent, "current_state", current_state, "current_sub_state", current_sub_state, "updateData", updateData);
        this.getCurrentStateName(sectionData);


        let warning = false,
            error = false,
            success = false;


        warningMessages &&
            warningMessages.forEach(message => {
                switch (message.alert_type) {
                    case "success-type":
                        success = true;
                        break;
                    case "error-type":
                        error = true;
                        break;
                    case "warning-type":
                        warning = true;
                        break;
                    default:
                        break;
                }
            })

        return (
            <section className="details-action-sec">
                <div className="details-view-block">
                    <div className="drawer-tabs-header">
                        <Fab variant="extended" size="large" className={"btn btn-large btn-plane btn-fab grey-color no-shadow " + (selectedTab === "action" ? "active" : "")} onClick={event => { this.handleSelectState("selectedTab", "action") }}>
                            Action
                        </Fab>
                        <Fab variant="extended" size="large" className={"btn btn-latrge btn-plane btn-fab grey-color no-shadow " + (selectedTab === "timeline" ? "active" : "")} onClick={event => { this.handleSelectState("selectedTab", "timeline") }}>
                            Timeline
                        </Fab>
                    </div>
                    {(selectedTab === "timeline") && (
                        <div className="tabs-conatiner">
                            <Scrollbars style={{ width: '100%', height: '100%' }}>
                                <DetailsTimeline sectionData={sectionData} />
                            </Scrollbars>
                        </div>
                    )}
                    {(selectedTab === "action") && (
                        <div className="tabs-conatiner">
                            <Scrollbars style={{ width: '100%', height: '100%' }}>
                                <div className="details-status-wrap">
                                    <div className="details-status-header">
                                        <h5>Current State</h5>
                                    </div>
                                    <div className="form-row details-form-row">
                                        <h6 className="label">State Name</h6>
                                        <h6 className="field">{sectionData.current_state_name}</h6>
                                    </div>
                                    {sectionData.current_sub_state_name && (
                                        <div className="form-row details-form-row">
                                            <h6 className="label">Sub State Name</h6>
                                            <h6 className="field">{sectionData.current_sub_state_name}</h6>
                                        </div>
                                    )}
                                </div>
                                <div className="details-status-wrap">
                                    <div className="details-status-header">
                                        <h5>Available Actions</h5>
                                    </div>
                                    <div className="form-row details-action-status">
                                        {currentFormData &&
                                            (<FormViewComponent
                                                formData={currentFormData}
                                                getModalFormData={getModalFormData}
                                                process_id={process_id}
                                                afterSaveEvent={afterSaveEvent}
                                                current_state={current_state}
                                                current_sub_state={current_sub_state}
                                                updateData={updateData}
                                            />)
                                        }
                                        {
                                            !(this.checkAvailableActions(processData)) ?
                                                <h6> No Action Available</h6> :
                                                ""
                                        }
                                    </div>
                                </div>

                                {/* Integrations section */}
                                <div className="details-status-wrap">
                                    <div className="details-status-header">
                                        <h5>Integrations</h5>
                                    </div>
                                    <div className="form-row details-action-status">
                                        <IntegrationsView
                                            processId={process_id}
                                        />
                                    </div>
                                </div>

                                <div className="details-status-wrap">
                                    {warning && (
                                        <div>
                                            <div className="details-status-header">
                                                <h5>Warnings</h5>
                                            </div>
                                            <div className="details-status-warning">
                                                <ul>
                                                    {
                                                        warningMessages.map(message => {
                                                            return (
                                                                message.alert_type === "warning-type" && <li style={{ color: this.selectColorOnAlertType(message.alert_type) }}>{message.warning_text}</li>
                                                            )
                                                        })
                                                    }
                                                </ul>
                                            </div>
                                        </div>
                                    )}

                                    {error && (
                                        <div>
                                            <div className="details-status-header">
                                                <h5>Errors</h5>
                                            </div>
                                            <div className="details-status-warning">
                                                <ul>
                                                    {
                                                        warningMessages && warningMessages.length > 0 &&
                                                        warningMessages.map(message => {
                                                            return (
                                                                message.alert_type === "error-type" && <li style={{ color: this.selectColorOnAlertType(message.alert_type) }}>{message.warning_text}</li>
                                                            )
                                                        })
                                                    }
                                                </ul>
                                            </div>
                                        </div>
                                    )}

                                    {success && (
                                        <div>
                                            <div className="details-status-header">
                                                <h5>Success</h5>
                                            </div>
                                            <div className="details-status-warning">
                                                <ul>
                                                    {
                                                        warningMessages && warningMessages.length > 0 &&
                                                        warningMessages.map(message => {
                                                            return (
                                                                message.alert_type === "success-type" && <li style={{ color: this.selectColorOnAlertType(message.alert_type) }}>{message.warning_text}</li>
                                                            )
                                                        })
                                                    }
                                                </ul>
                                            </div>
                                        </div>
                                    )}

                                    <div className="alert-wrap">
                                        {
                                            warningMessages && warningMessages.length > 0 &&
                                            warningMessages.map((message, index) => {
                                                if (message.valid) {
                                                    return (
                                                        <AlertBox indexOfMessage={index} updateWarningMessageList={(indexOfMessage) => this.props.updateWarningMessageList(indexOfMessage)} alertType={this.selectAlertType(message.alert_type)} alertTypeStatus={this.selectAlertStatusType(message.alert_type)} warningMessage={message} />
                                                    );
                                                }
                                            })
                                        }
                                    </div>
                                    <div className="details-action-status">
                                        {/* <div className="details-action-row"> */}
                                        {/* <Fab variant="extended" size="small" className="btn btn-small btn-plane btn-fab white-color no-shadow" >
                                                    View More <KeyboardArrowDown/>
                                                </Fab> */}
                                        {/* </div> */}
                                    </div>
                                </div>
                            </Scrollbars>
                        </div>
                    )}


                </div>
            </section>
        )
    }
}

export default withRouter(DetailsActionStatus);
