import * as AjaxHelper from "../utilities/ajax-helper"
import { ApiConstants } from "./common/api-constants"

let apiBaseUrl = () => (ApiConstants.DEV_MODE === 'true' ? ApiConstants.HOST : window.location.origin) + ApiConstants.API_VERSION;

export function postFileUpload(formData, callback) {
    return AjaxHelper.post(apiBaseUrl() + ApiConstants.FILE_UPLOAD, formData, {}, null, true, callback);
}

export function getFileUpload(fileId) {
    return AjaxHelper.get(apiBaseUrl() + ApiConstants.FILE_UPLOAD + "/" + fileId, null, { "Content-Type": "application/json" }, true);
}

export function getFileDetailsFromProcessKey(processId) {
    return AjaxHelper.get(apiBaseUrl() + ApiConstants.FILE_UPLOAD + "/" + processId + "/program", null, { "Content-Type": "application/json" });
}

export function postFileUploadToFolder(folderId, formData, callback) {
    return AjaxHelper.post(apiBaseUrl() + ApiConstants.FILE_UPLOAD + "/" + folderId, formData, {}, null, true, callback);
}

export function getLedgerReport(programkey) {
    return AjaxHelper.get(apiBaseUrl() + ApiConstants.FILE_UPLOAD + "/legerReport/" + programkey, null, { "Content-type": "application/json" }, true, "blob");
}
