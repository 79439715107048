import React, { Component } from 'react';
import SuccessGreenIcon from '../../assets/images/icon/tick-green.svg';
import WarningRedIcon from '../../assets/images/icon/warning-red.svg';
import InfoMustardIcon from '../../assets/images/icon/info-mustard.svg';
import CloseRedIcon from '../../assets/images/icon/close-red-round.svg';
import Fab from '@material-ui/core/Fab';
import Slide from '@material-ui/core/Slide';
import { yellow } from '@material-ui/core/colors';

class AlertBox extends Component {

    state = {
        successAlert: false,
        warningAlert: false,
        infoAlert: false,
        errorAlert: false,
    }

    componentWillMount() {
        let { alertTypeStatus } = this.props;
        this.setState({ [alertTypeStatus]: true });
    }

    alertClose(key, indexOfMessage) {
        this.setState({ [key]: false });
        this.props.updateWarningMessageList(indexOfMessage);
    }
    render() {
        const { alertType, warningMessage, indexOfMessage } = this.props;
        const { successAlert, warningAlert, infoAlert, errorAlert } = this.state;
        return (
            <section className="alert-sec">

                {
                    alertType === 'success' ? (
                        <Slide direction="up" in={successAlert} mountOnEnter unmountOnExit>
                            <div className="card-block">
                                <div className="card-body card-success">
                                    <div className="alert-close" onClick={(event) => this.alertClose("successAlert", indexOfMessage)}>
                                        <img src={CloseRedIcon} alt="CloseRedIcon" />
                                    </div>
                                    <div className="alert-icon">
                                        <img src={SuccessGreenIcon} alt="SuccessGreenIcon" />
                                    </div>
                                    <div className="alert-info">
                                        <h6 className="font-med">Success Message</h6>
                                        <p><br></br>{warningMessage.warning_text}</p>
                                        <Fab variant="extended" size="small" className="btn btn-samll no-shadow" style={{ color: '#008000' }}>
                                            View
                                        </Fab>
                                    </div>
                                </div>
                            </div>
                        </Slide>
                    ) : alertType === 'warning' ? (
                        <Slide direction="up" in={warningAlert} mountOnEnter unmountOnExit>
                            <div className="card-block">
                                <div className="card-body card-warning">
                                    <div className="alert-close" onClick={(event) => this.alertClose("warningAlert", indexOfMessage)}>
                                        <img src={CloseRedIcon} alt="CloseRedIcon" />
                                    </div>
                                    <div className="alert-icon">
                                        <img src={InfoMustardIcon} alt="InfoMustardIcon" />
                                    </div>
                                    <div className="alert-info">
                                        <h6 className="font-med">Warning Message</h6>
                                        <p><br></br>{warningMessage.warning_text}</p>
                                        <p className="grey-color">Please Check</p>
                                        <Fab variant="extended" size="small" className="btn btn-samll no-shadow" style={{ color: '#F4A460' }}>
                                            View
                                        </Fab>
                                    </div>
                                </div>
                            </div>
                        </Slide>
                    ) : alertType === 'info' ? (
                        <Slide direction="up" in={infoAlert} mountOnEnter unmountOnExit>
                            <div className="card-block">
                                <div className="card-body card-info">
                                    <div className="alert-close" onClick={(event) => this.alertClose("infoAlert", indexOfMessage)}>
                                        <img src={CloseRedIcon} alt="CloseRedIcon" />
                                    </div>
                                    <div className="alert-icon">
                                        <img src={InfoMustardIcon} alt="InfoMustardIcon" />
                                    </div>
                                    <div className="alert-info">
                                        <h6 className="font-med">Alert Massage</h6>
                                        <p>Oops! <br></br> An error occurred.</p>
                                        <p className="grey-color">Please try again</p>
                                        <Fab variant="extended" size="small" className="btn btn-samll no-shadow">
                                            View
                                        </Fab>
                                    </div>
                                </div>
                            </div>
                        </Slide>
                    ) : alertType === 'error' ? (
                        <Slide direction="up" in={errorAlert} mountOnEnter unmountOnExit>
                            <div className="card-block">
                                <div className="card-body card-info">
                                    <div className="alert-close" onClick={(event) => this.alertClose("errorAlert", indexOfMessage)}>
                                        <img src={CloseRedIcon} alt="CloseRedIcon" />
                                    </div>
                                    <div className="alert-icon">
                                        <img src={WarningRedIcon} alt="WarningRedIcon" />
                                    </div>
                                    <div className="alert-info">
                                        <h6 className="font-med">Error Message</h6>
                                        <p><br></br>{warningMessage.warning_text}</p>
                                        <p className="grey-color">Please try again</p>
                                        <Fab variant="extended" size="small" className="btn btn-samll no-shadow" style={{ color: '#8B0000' }}>
                                            View
                                        </Fab>
                                    </div>
                                </div>
                            </div>
                        </Slide>
                    ) : ''
                }

            </section>
        )
    }
}

AlertBox.defaultProps = {
    alertType: ""
}

export default AlertBox;