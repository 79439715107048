import React, { Component } from 'react';
import Fab from '@material-ui/core/Fab';
import closeIcon from '../../assets/images/icon/close.svg';
import updateGreyIcon from '../../assets/images/icon/update-grey.svg';
import addGreyIcon from '../../assets/images/icon/add-grey.svg';
import changeGreyIcon from '../../assets/images/icon/change-priority-grey.svg';
// import StarGreyIcon from '../../assets/images/icon/star-grey.svg';
import { Scrollbars } from 'react-custom-scrollbars';
import Avatar from '@material-ui/core/Avatar';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';
import eyeBlueIcon from '../../assets/images/icon/eye-blue.svg';
// import ArrowForwardGrey from '@material-ui/icons/KeyboardArrowRight';
// import * as NotificationApi from '../../api/notification-api';

import Drawer from '@material-ui/core/Drawer';

const LightTooltip = withStyles(theme => ({
    tooltip: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        position: 'static',
        left: '50%',
        transform: 'translateX(-50%)',
        boxShadow: theme.shadows[1],
        fontSize: 11,
    },
}))(Tooltip);

class HeaderNotification extends Component {

    // changesNotifyStatus=(id)=>()=>{
    //     console.log("Notification id is", id)
    //     NotificationApi.updateNotificationStatus(id).then(result => {
    //         console.log("notification status updated",result);
    //     })
    // }

    // Notify() {
    //    console.log("notify status of all")
    // }
    render() {
        let { notification } = this.props;
        console.log("notification", notification);
        return (
            <div>
                <Drawer anchor="right" open={this.props.notificationDrawer} onClose={this.props.toggleDrawer('notificationDrawer', false)}>

                    <section className="workflow-setup-sec notification-setup-sec">
                        <section className="setup-drawer-sec">
                            <div className="card-block">
                                <div className="card-header">
                                    <div className="card-subheader">
                                        <div className="card-title">
                                        </div>
                                        <div className="card-left-title">
                                            <div className="actions setup-close">
                                                <div className="btn-wrap">
                                                    <Fab variant="extended" size="small" className="btn btn-small btn-plane btn-fab white-color no-shadow" onClick={this.props.toggleDrawer('notificationDrawer', false)}>
                                                        Close <img alt="edit" src={closeIcon}></img>
                                                    </Fab>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-header border-bottom" style={{ padding: '8px 0px 25px' }}>
                                    <div className="card-title">
                                        <h2>Notifications</h2>
                                    </div>
                                    <div className="notify-count">
                                        <h6 className="font-med">{notification.length}</h6>
                                    </div>
                                    <LightTooltip title="Mark all as read">
                                        <Fab variant="extended" size="small" className="btn btn-small btn-plane btn-fab transparent-color no-shadow grey-color">
                                            <img src={eyeBlueIcon} alt="eyeBlueIcon" style={{ width: '30px' }} />
                                        </Fab>
                                    </LightTooltip>
                                </div>
                                <div className="card-body">
                                    <div role="presentation" className="drawer-sec">
                                        <div className="drawer-form-sec">
                                            <div className="tab-container tab-container-scroll" style={{ height: 'calc(100vh - 185px)' }}>
                                                <Scrollbars style={{ width: '100%', height: '100%' }}>
                                                    <div className="workflow-program-list notify-program-list">
                                                        {notification ?
                                                            notification.map((notification, index) => {
                                                                return (
                                                                    <div className="notify-program-row" onClick={this.props.onchangesNotifyStatus(notification.notification_id)} >

                                                                        {
                                                                            notification.notification_type === 'UPDATE_RECORD' ? (
                                                                                <Avatar alt="profile" className="avatar-img" src={updateGreyIcon} />
                                                                            ) : notification.notification_type === 'ADD_RECORD' ? (
                                                                                <Avatar alt="profile" className="avatar-img" src={addGreyIcon} />
                                                                            ) : notification.notification_type === 'CHANGE_STATE' ? (
                                                                                <Avatar alt="profile" className="avatar-img" src={changeGreyIcon} />
                                                                            ) : ""
                                                                        }
                                                                        <ul>
                                                                            <li className="notify-title">
                                                                                <h6>{notification.notification_type}</h6>
                                                                            </li>
                                                                            <li className="notify-desp">
                                                                                <h6 className="font-med">{notification.notification_text}</h6>
                                                                            </li>
                                                                            <li className="notify-created">
                                                                                <h6 className="font-small">{notification.created_on}</h6>
                                                                            </li>
                                                                        </ul>
                                                                        {/* <ArrowForwardGrey className="notify-forward"/> */}
                                                                    </div>
                                                                )
                                                            }) : ""
                                                        }
                                                    </div>
                                                </Scrollbars>
                                            </div>
                                            {/* <div className="card-footer border-top">
                                            <div className="btn-wrap">
                                                <Fab onClick={this.props.changeNotifyStatusAll} variant="extended" size="large" className="btn btn-large btn-plane primary-color">View All</Fab>
                                                <Fab variant="extended" size="large" className="btn btn-large btn-plane grey-color">Cancel</Fab>
                                            </div>
                                        </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </section>
                </Drawer>
            </div>
        );
    }
}
export default HeaderNotification;