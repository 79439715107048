import React, { Component } from 'react';
import Header from '../../components/header';
import WorkflowHeader from '../../components/workflow/workflow-header';

//Programs
import ProgramTimeline from '../../components/program/program-timeline';
import ProgramStackHolder from '../../components/program/program-stack-holder';
import ProgramMasterPolicy from '../../components/program/program-master-policy';
import ProgramPolicyParam from '../../components/program/program-policy-param';
import ProgramCoverageLimit from '../../components/program/program-coverage-limit';
import ProgramPaymentMethod from '../../components/program/program-payment-method';
import ProgramTermCondition from '../../components/program/program-term-condition';

//Constants
import * as Constants from '../../utilities/constants';

//API
import * as ProgramDataApi from '../../api/program-api';
import * as StorageFileApi from '../../api/file-storage-api';

class ProgramDashboard extends Component {

    state = {
        active: false,
        programSelection: Constants.PROGRAM.STACK_HOLDER,
        programData: {},
        timeline_count: 1,
        programDetails: {},
        isFinish: false,
        masterPolicyFileUpload: null,
    }

    componentWillMount() {
        const match = this.props.match;
        let clientId = match.params.clientId;
        let programkey = match.params.programkey;
        let programData = this.state.programData;
        this.handleGetProgramInfo(programkey);
        this.handleGetProgramData(programkey);
        programData["client_id"] = clientId;
        programData["program_key"] = programkey;
        programData["timeline_count"] = this.state.timeline_count;
        this.setState({ programData: programData });
    }

    handleGetProgramInfo(programKey) {
        ProgramDataApi.getProgramByProgramKey(programKey).then(data => {
            this.setState({ masterPolicyFileUpload: data.master_policy_file_upload });
        })
    }

    handleDownloadMasterPolicyFile() {
        let { masterPolicyFileUpload } = this.state;
        if (masterPolicyFileUpload) {
            StorageFileApi.getFileUpload(Number(masterPolicyFileUpload)).then(response => {
            })
        }
    }

    handleGetProgramData(programkey) {
        ProgramDataApi.getProgramData(programkey).then(data => {
            console.log("handleGetProgramData ", data);
            data.program_key = programkey;
            this.setState({ programData: data });
            if (data.is_finish) {
                this.setState({ isFinish: true });
            }
            if (data.timeline_count === 6) {
                this.handleGetProgramSelection(data.timeline_count);
                this.setState({ timeline_count: data.timeline_count });
            }
            else {
                this.handleGetProgramSelection(data.timeline_count + 1);
                this.setState({ timeline_count: data.timeline_count + 1 });
            }
        })
    }

    handleGetProgramSelection(timeline_count) {
        switch (timeline_count) {
            case 1:
                this.setState({ programSelection: Constants.PROGRAM.STACK_HOLDER });
                break;
            case 2:
                this.setState({ programSelection: Constants.PROGRAM.MASTER_POLICY });
                break;
            case 3:
                this.setState({ programSelection: Constants.PROGRAM.POLICY_PARAMETER });
                break;
            case 4:
                this.setState({ programSelection: Constants.PROGRAM.COVERAGE_LIMITS });
                break;
            case 5:
                this.setState({ programSelection: Constants.PROGRAM.PAYMENT_METHODS });
                break;
            case 6:
                this.setState({ programSelection: Constants.PROGRAM.TERMS_CONDITIONS });
                break;
            default:
                this.setState({ programSelection: Constants.PROGRAM.STACK_HOLDER });
                break;
        }
    }

    handleSaveProgramData() {
        let { programData } = this.state;

        ProgramDataApi.saveProgramData(programData).then(data => {
            this.setState({ programData: data })
        })
    }

    toggleTimeline() {
        this.setState({ active: !this.state.active });
    }

    modifyProgramSelection(programType, programIndex, isFinished) {
        let { timeline_count, programData } = this.state;
        if (timeline_count < programIndex) {
            timeline_count = programIndex;
            programData["timeline_count"] = timeline_count;
            console.log("timeline_count", timeline_count);
        }
        this.setState({ programSelection: programType, timeline_count: timeline_count, programData: programData });
    }

    modifyProgramData(data) {
        this.setState({ programData: data });
    }

    selectionProgramType(programType) {
        const match = this.props.match;
        let clientId = match.params.clientId;
        let programkey = match.params.programkey;

        switch (programType) {
            case Constants.PROGRAM.STACK_HOLDER:
                return (
                    <ProgramStackHolder
                        modifyProgramSelection={this.modifyProgramSelection.bind(this)}
                        programData={this.state.programData}
                        modifyProgramData={this.modifyProgramData.bind(this)}
                        handleSaveProgramData={this.handleSaveProgramData.bind(this)}
                        clientId={clientId}
                        programkey={programkey}
                        handleDownloadMasterPolicyFile={() => this.handleDownloadMasterPolicyFile()}
                    />
                );

            case Constants.PROGRAM.MASTER_POLICY:
                return (
                    <ProgramMasterPolicy
                        modifyProgramSelection={this.modifyProgramSelection.bind(this)}
                        programData={this.state.programData}
                        modifyProgramData={this.modifyProgramData.bind(this)}
                        handleSaveProgramData={this.handleSaveProgramData.bind(this)}
                        clientId={clientId}
                        programkey={programkey}
                        handleDownloadMasterPolicyFile={() => this.handleDownloadMasterPolicyFile()}
                    />
                );

            case Constants.PROGRAM.POLICY_PARAMETER:
                return (
                    <ProgramPolicyParam
                        modifyProgramSelection={this.modifyProgramSelection.bind(this)}
                        programData={this.state.programData}
                        modifyProgramData={this.modifyProgramData.bind(this)}
                        handleSaveProgramData={this.handleSaveProgramData.bind(this)}
                        clientId={clientId}
                        programkey={programkey}
                        handleDownloadMasterPolicyFile={() => this.handleDownloadMasterPolicyFile()}
                    />
                );

            case Constants.PROGRAM.COVERAGE_LIMITS:
                return (
                    <ProgramCoverageLimit
                        modifyProgramSelection={this.modifyProgramSelection.bind(this)}
                        programData={this.state.programData}
                        modifyProgramData={this.modifyProgramData.bind(this)}
                        handleSaveProgramData={this.handleSaveProgramData.bind(this)}
                        clientId={clientId}
                        programkey={programkey}
                        handleDownloadMasterPolicyFile={() => this.handleDownloadMasterPolicyFile()}
                    />
                );

            case Constants.PROGRAM.PAYMENT_METHODS:
                return (
                    <ProgramPaymentMethod
                        modifyProgramSelection={this.modifyProgramSelection.bind(this)}
                        programData={this.state.programData}
                        modifyProgramData={this.modifyProgramData.bind(this)}
                        handleSaveProgramData={this.handleSaveProgramData.bind(this)}
                        clientId={clientId}
                        programkey={programkey}
                        handleDownloadMasterPolicyFile={() => this.handleDownloadMasterPolicyFile()}
                    />
                );

            case Constants.PROGRAM.TERMS_CONDITIONS:
                return (
                    <ProgramTermCondition
                        modifyProgramSelection={this.modifyProgramSelection.bind(this)}
                        programData={this.state.programData}
                        modifyProgramData={this.modifyProgramData.bind(this)}
                        handleSaveProgramData={this.handleSaveProgramData.bind(this)}
                        clientId={clientId}
                        programkey={programkey}
                        handleDownloadMasterPolicyFile={() => this.handleDownloadMasterPolicyFile()}
                    />
                );

            default: break;
        }

    }

    render() {
        let { active, programSelection, programData } = this.state;
        let toggleTimeline = active ? "toggle-timeline" : "";
        const match = this.props.match;
        let clientId = match.params.clientId;
        let programkey = match.params.programkey;
        console.log("Waha", programData);
        return (
            <div className="ProgramDashboard">
                <Header headerType="dashboard" positionType="fixed" programKey={programkey} />
                <WorkflowHeader workflowType="normal" tab="program" clientId={clientId} programKey={programkey} />
                <main>
                    <div className="workflow-fields-sec program-build-sec">
                        <div className="program-build-wrap">
                            <div className={"program-card-wrap program-build-timeline " + toggleTimeline}>
                                <div className="card-block">
                                    <div className="card-body">
                                        <ProgramTimeline
                                            buttonClick={this.toggleTimeline.bind(this)}
                                            buttonState={this.state.active}
                                            modifyProgramSelection={this.modifyProgramSelection.bind(this)}
                                            programSelection={this.state.programSelection}
                                            timeline_count={this.state.timeline_count}
                                            isFinish={this.state.isFinish}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className={"program-card-wrap program-build-form " + toggleTimeline}>
                                <div className="card-block">
                                    <div className="card-body">
                                        {this.selectionProgramType(programSelection)}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        )
    }
}

export default ProgramDashboard;