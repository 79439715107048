//Constants
import { DateTime } from 'luxon';
import * as Constant from './constants'

export function generateProcessedRecords(form, formRecordList, type) {
    let formFieldList = form.form_field_list;
    let recordTableColumns = [];
    let recordTableData = [];

    let dateAttributeTable = [];
    let timeAtrributeTable = [];
    let numberAtrributeTable = [];
    let dateAndTimeTable = [];


    let checkPagination = false;
    for (let i = 0; i < formFieldList.length; i++) {
        if (formFieldList[i].data_pagination_id > 1) {
            checkPagination = true;
            break;
        }
    }
    formFieldList.forEach(formfield => {
        if (formfield.group_field) {
            let subGroupField = formFieldList.filter(field => field.parent_id === formfield.id)
            formfield.display_name && formfield.display_name.forEach((subfield, index) => {
                if (subfield.checked === false) {
                    if (subGroupField[index]) { subGroupField[index].is_hidden = true; }
                }
                else {
                    if (subGroupField[index]) {
                        subGroupField[index].is_hidden = false;
                    }
                }
            })
        }
    })
    //Iterate through form field List
    formFieldList.forEach(formField => {
        //filter out group field
        if ((!formField.group_field && !formField.is_hidden) || (type !== "dashboard" && formField.field_type === Constant.FieldIds.F_SECTION_FIELD_TYPE.code)) {
            //Generate row names with keys along with pagination
            let keyId = "data_attr" + formField.data_ref_id + (checkPagination ? "-" + formField.data_pagination_id : "");

            switch (formField.field_type) {
                case Constant.FieldIds.F_DATE.code:
                    dateAttributeTable.push(keyId);
                    break;

                case Constant.FieldIds.F_TIME.code:
                    timeAtrributeTable.push(keyId);
                    break;

                case Constant.FieldIds.F_DATE_TIME.code:
                    dateAndTimeTable.push(keyId);
                    break;
                case Constant.FieldIds.F_NUMBER_TXT.code:
                    numberAtrributeTable.push(keyId);
                default:
                    break;
            }
            recordTableColumns.push({ id: keyId, numeric: true, showData: true, show: true, disablePadding: false, label: formField.label, fieldType: formField.field_type, fieldOptions: formField.field_option_list });
        }
    })
    recordTableColumns.push({ id: "process_id", numeric: true, showData: false, show: false, disablePadding: false, label: "Process Id", fieldType: "String", fieldOptions: null })
    if (checkPagination) {
        formRecordList = formatRecordByPage(formRecordList);
    }
    formRecordList.forEach(formRecord => {
        let recordTableDataObj = {};
        recordTableColumns.forEach(recordRow => {
            recordTableDataObj[recordRow.id] = formRecord[recordRow.id];
        })
        recordTableData.push(recordTableDataObj);
    })
    console.log("dateAttributeTable", dateAttributeTable);

    console.log("recordTableData_678", recordTableData);

    //Formatting ISO Date to dd/mm/yyyy
    if (dateAttributeTable.length > 0) {
        recordTableData.forEach(data => {
            dateAttributeTable.forEach(attr => {
                let date = "";
                if (data[attr] && data[attr] !== undefined) {
                    date = new Date(data[attr]);
                    date = DateTime.fromJSDate(date);
                    // date = DateTime.fromJSDate(date).toFormat("DD/MM/YYYY");
                    // date = date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear();
                    // data[attr] = date
                    data[attr] = date.toFormat("dd/MM/yyyy");
                }
            })
        })
    }

    //Formatting ISO Time to hh:mm:ss
    if (timeAtrributeTable.length > 0) {
        recordTableData.forEach(data => {
            timeAtrributeTable.forEach(attr => {
                let date = "";
                if (data[attr] && data[attr] !== undefined) {
                    date = new Date(data[attr]);
                    date = DateTime.fromJSDate(date).toFormat("hh:mm:ss");
                    //date = date.toTimeString();  
                    // date = date.toLocaleTimeString();
                    data[attr] = date;
                }
            })
        })
    }

    //Formatting ISO Date and Time to dd/mm/yyyy and hh:mm:ss
    if (dateAndTimeTable.length > 0) {
        recordTableData.forEach(data => {
            dateAndTimeTable.forEach(attr => {
                let date = "";
                let time = "";
                if (data[attr] && data[attr] !== undefined) {
                    date = new Date(data[attr]);
                    // time = new Date(data[attr]);
                    // date = date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear() + " ";
                    // date += time.toLocaleTimeString();
                    date = DateTime.fromJSDate(date).toFormat("dd/MM/yyyy hh:mm:ss");
                    data[attr] = date;
                }
            })
        })
    }

    //Formatting number data 
    if (numberAtrributeTable.length > 0) {
        recordTableData.forEach(data => {
            numberAtrributeTable.forEach(attr => {
                if (data[attr] && data[attr] !== undefined) {
                    data[attr] = Number(data[attr]);
                }
            })
        })
    }


    let recordDetails = {
        columns: recordTableColumns,
        rows: recordTableData,
        form: form
    }
    console.log("recordDetails", recordDetails);
    return recordDetails;
}

// function generateJson(columns, rows) {
//     let recordArray = [];
//     rows.forEach(rowData => {
//         let recordData = {};
//         columns.forEach(columnData => {
//             recordData[columnData.label] = rowData[columnData.id];
//         })
//         recordArray.push(recordData);
//     })
//     return recordArray;
// }

function formatRecordByPage(records) {
    let updatedRecordsObj = {};
    records.forEach(record => {
        let page = record.form_pagination_id;
        if (!updatedRecordsObj[record.common_record_id]) {
            updatedRecordsObj[record.common_record_id] = {};
        }
        for (var key in record) {
            if (key.includes("data_attr")) {
                updatedRecordsObj[record.common_record_id][key + "-" + page] = record[key];
            } else {
                //code for process id addition and other fields
                updatedRecordsObj[record.common_record_id][key] = record[key];
            }

        }
    })

    let updatedRecords = [];
    for (var key in updatedRecordsObj) {
        updatedRecords.push(updatedRecordsObj[key]);
    }

    return updatedRecords;
}