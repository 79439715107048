import React, { Component } from 'react';
import Fab from '@material-ui/core/Fab';
import editIcon from '../../assets/images/icon/edit-grey.svg';
import verDots from '../../assets/images/icon/more-vert.svg';
import Add from '@material-ui/icons/Add';
import Button from '@material-ui/core/Button';
import Icons from '../common/icon-common';

//Constants
import * as Constants from '../../utilities/constants';

class WorkflowFields extends Component {

    //This function creates fieldList which possess field id,type and label.
    createFieldList(formData) {
        let fieldList = [];
        if (formData.form_field_list) {
            formData.form_field_list.forEach(formField => {
                let obj = {
                    field_option_label: formField.label,
                    field_option_value: formField.id,
                    field_type: formField.field_type,
                    field_options: formField.field_option_list,
                }
                fieldList.push(obj);
            })
        }
        return fieldList;
    }

    //This function converts Field ids to Field Name
    convertIdsToFieldName(fieldList, stepDataList) {
        console.log("fieldList22", fieldList, "stepDataList", stepDataList);
        let { programSubState, programState } = this.props;
        let displayRules = '';
        let valueOperation = '';
        if (stepDataList.length > 0 && fieldList.length > 0) {
            stepDataList.forEach(action => {
                if (action.field_operations.length > 0) {
                    action.field_operations.forEach(operation => {
                        let performStr = operation.next_operation ? operation.next_operation.toUpperCase() : '';
                        switch (operation.operation) {
                            case Constants.FIELD_OPERATION.IS_NULL:
                            case Constants.FIELD_OPERATION.IS_NOT_NULL:
                            case Constants.FIELD_OPERATION.CLICKED:
                                valueOperation = ' ';
                                break;
                            default:
                                fieldList.forEach((field, index) => {
                                    if (field.field_option_value === operation.field_id) {
                                        switch (field.field_type) {
                                            case Constants.FieldIds.F_SINGLE_DROPDOWN_GROUP.code:
                                            case Constants.FieldIds.F_RADIO_GROUP.code:
                                            case Constants.FieldIds.F_CHECKBOX_GROUP.code:
                                            case Constants.FieldIds.F_MULTIPLE_DROPDOWN_GROUP.code:
                                                let optionValuePresent = false;
                                                field.field_options.forEach(option => {
                                                    if (option.field_option_value === operation.field_value) {
                                                        valueOperation = "(\"" + option.field_option_label + "\") ";
                                                        optionValuePresent = true;
                                                    }

                                                })
                                                if (!optionValuePresent) {
                                                    valueOperation = "(\"" + operation.field_value + "\") ";
                                                }
                                                break;

                                            default:
                                                valueOperation = "(\"" + operation.field_value + "\") ";
                                                break;
                                        }

                                    }
                                })
                                break;
                        }

                        switch (operation.metadata) {
                            case "state": {
                                let stateName = programState.filter(state => state.id === operation.field_value)[0].state_name;
                                valueOperation = "(\"" + stateName + "\") ";
                                break;
                            }
                            case "substate": {
                                let subStateName = "";
                                for (let key in programSubState) {
                                    programSubState[key].forEach(subState => {
                                        if (subState.id === operation.field_value) {
                                            subStateName = subState.state_name;
                                            return;
                                        }
                                    })
                                }
                                valueOperation = "(\"" + subStateName + "\") ";
                                break;
                            }
                            default: break;
                        }
                        fieldList.forEach(list => {
                            console.log("list555", list);
                            let label = "";
                            let isFound = false;
                            switch (operation.operation) {
                                case Constants.FIELD_OPERATION.CLICKED:
                                    let splitValue = operation.field_id.split("#");
                                    isFound = list.field_option_value === splitValue[0];
                                    if (isFound) {
                                        label = list.field_option_label.split(' ').join('_');
                                        let optionName = list.field_options.filter(fieldOption =>
                                            fieldOption.field_option_value === splitValue[1])[0].field_option_label.split(' ').join('_');
                                        label = label + "#" + optionName;
                                    }
                                    break;
                                default:
                                    isFound = list.field_option_value === operation.field_id;
                                    label = isFound && list.field_option_label.split(' ').join('_');
                            }
                            if (isFound) {
                                displayRules = displayRules + label + "." + operation.operation + valueOperation + performStr + " ";
                            }
                        })
                    })
                    action["rules_script_display"] = displayRules;
                    displayRules = '';
                }

            })
        }
    }

    //This function will add Action_Type and Display_Action into the ActionData Array
    addActionSpecs(fieldList, stepDataList) {
        let { programState, programSubState } = this.props;
        let formActions = Constants.FIELD_ACTIONS;
        let { totalFields, comma } = '';
        let actionTypeArray = [];
        let actionFieldsArray = [];
        if (stepDataList.length > 0 && fieldList.length > 0) {
            stepDataList.forEach((data, index) => {
                data.actions.forEach(action => {
                    for (var key in formActions) {
                        if (formActions[key] === action.action_spec) {
                            actionTypeArray.push(key);
                        }
                    }

                    totalFields = " ";
                    switch (action.action_spec) {
                        case Constants.FIELD_ACTIONS.HIDE:
                        case Constants.FIELD_ACTIONS.SHOW:
                        case Constants.FIELD_ACTIONS.ENABLE:
                        case Constants.FIELD_ACTIONS.DISABLE:
                        case Constants.FIELD_ACTIONS.PAYMENT_INIT:
                        case Constants.FIELD_ACTIONS.SET: {
                            action.fields &&
                                action.fields.forEach((field, index) => {
                                    comma = (index < (action.fields.length - 1)) ? ',' : '';
                                    fieldList.forEach(list => {
                                        //Check for button and group elements
                                        let idSplit = field?.field_id?.split("#");
                                        console.log("qwerty", idSplit);
                                        if (idSplit && idSplit[1]) {
                                            if (list.field_option_value === idSplit[0]) {
                                                let optionName = list.field_options.filter(fieldOption =>
                                                    fieldOption.field_option_value === idSplit[1])[0].field_option_label.split(' ').join('_');
                                                totalFields = totalFields + list.field_option_label + "#" + optionName + comma;
                                            }
                                        } else {
                                            if (list.field_option_value === field.field_id) {
                                                totalFields = totalFields + list.field_option_label + comma;
                                            }
                                        }
                                    })

                                    if (action.action_type === Constants.ACTIONS_TYPE.PAYMENT) {
                                        totalFields = totalFields + field.field_id + comma;
                                    }

                                });

                            actionFieldsArray.push("[" + totalFields + " ]");
                            totalFields = '';
                            break;
                        }
                        case Constants.FIELD_ACTIONS.REDIRECT: {
                            if (action.redirect.destination_type === Constants.REDIRECT_TYPE.FORM) {
                                totalFields = action.redirect.form_key;
                                actionFieldsArray.push("[" + totalFields + " ]");
                            } else if (action.redirect.destination_type === Constants.REDIRECT_TYPE.URL) {
                                totalFields = action.redirect.redirect_url;
                                actionFieldsArray.push("[" + totalFields + " ]");
                            }
                            break;
                        }
                        case Constants.FIELD_ACTIONS.ADD_RECORD: {
                            totalFields = action.form_access.form_key;
                            actionFieldsArray.push("[" + totalFields + " ]");
                            break;
                        }
                        case Constants.FIELD_ACTIONS.UPDATE_RECORD: {
                            totalFields = action.form_access.form_key;
                            actionFieldsArray.push("[" + totalFields + " ]");
                            break;
                        }
                        case Constants.FIELD_ACTIONS.DELETE_RECORD: {
                            totalFields = action.form_access.form_key;
                            actionFieldsArray.push("[" + totalFields + " ]");
                            break;
                        }
                        case Constants.FIELD_ACTIONS.STATE: {
                            console.log("FIELD_ACTIONS.STATE", programState, programSubState);
                            let stateName = programState.filter(state => state.id === action.state.state_id)[0].state_name;
                            let subStateName = "";
                            if (action.state.sub_state_id) {
                                subStateName = "-" + programSubState[action.state.state_id].filter(subState => subState.id === action.state.sub_state_id)[0].state_name;
                            }
                            totalFields = stateName + subStateName;
                            actionFieldsArray.push("[" + totalFields + " ]");
                            break;
                        }
                        default:
                            actionFieldsArray.push(" ");
                            break;
                    }
                })
                data["action_type"] = actionTypeArray;
                data["display_action"] = actionFieldsArray;
                actionTypeArray = [];
                actionFieldsArray = [];

            })
        }

    }

    render() {
        let { formData, stepDataList, workflowData } = this.props;
        console.log("stepDataList", stepDataList);
        let fieldList = this.createFieldList(formData);
        this.convertIdsToFieldName(fieldList, stepDataList);
        this.addActionSpecs(fieldList, stepDataList);
        return (
            <section className="workflow-fields-sec">
                <div className="card-block">
                    <div className="sub-title">
                        <h5>Add an action that needs to be executed by an event in the form <span><strong>{formData.form_name}</strong></span>, while a record is being <span><strong>{workflowData && workflowData.workflow_trigger && workflowData.workflow_trigger.trigger_type}</strong></span></h5>
                    </div>
                    <div className="workflow-fields-wrap">
                        <div className="fields-wrap">
                            {
                                stepDataList.map((action, index) => {
                                    return (
                                        this.renderTrigggerAction(action, index)
                                    )
                                })
                            }
                        </div>
                        <div className="fields-wrap add-fields-wrap">
                            <div className="fields-list">
                                <div className="card-block">
                                    <div className="card-header">
                                        <div className="card-title">
                                            <span className="card-holde-left"><Add /></span>
                                            <Button onClick={(event) => this.props.toggleDrawer(event, "new", -1)}>
                                                Add New Action
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }

    renderTrigggerAction(stepData, index) {
        return (
            <div className="fields-list">
                <div className="card-block">
                    <div className="card-header border-bottom">
                        <div className="card-title">
                            <span className="card-holde-left"></span>
                            <h5>
                                {stepData.payment_response ?
                                    (stepData.payment_response) : stepData.ledger_response ? stepData.ledger_response :
                                        (stepData.conditional ? "Execute only if " : "Execute without Conditions")
                                }
                                <span className="card-criteria">{stepData.rules_script_display}</span></h5>
                        </div>
                        <div className="actions">
                            <div className="btn-wrap">
                                {!(stepData.payment_response) &&
                                    <div>
                                        <Fab aria-label="edit" className="fabs-icons" onClick={this.props.openCloseEditDialog(index).bind(this)}>
                                            <img alt="edit" src={editIcon}></img>
                                        </Fab>

                                        <Fab aria-label="delete" className="fabs-icons"
                                            onClick={(event) => (this.props.getActionTrigggerId(event, stepData.id))}
                                        >
                                            <img alt="delete" src={Icons.deleteIcon}></img>
                                        </Fab>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="w-fields-prop-wrap">
                            {
                                stepData.actions.map((fields, i) => {
                                    return (
                                        this.renderAction(stepData, index, i)
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    renderAction(stepData, index, i) {
        return (
            <div className="w-fields-list">
                <div className="w-fields-grp">
                    <span className="w-fields-sort"><img src={verDots} alt="" /><img src={verDots} alt="" /></span>
                    <div className="card-title">
                        {stepData.action_type &&
                            <h6>{stepData.action_type[i]}&nbsp;<span>{stepData.display_action[i]}</span></h6>
                        }
                    </div>
                    <div className="actions">
                        <div className="btn-wrap">
                            <Fab aria-label="edit" className="fabs-icons" onClick={(event) => { this.props.toggleDrawer(event, "editAction", index, i, stepData.conditional); this.props.editSubWorkflowButton(index, i) }}>
                                <img alt="edit" src={editIcon}></img>
                            </Fab>
                            {!(stepData.payment_response || stepData.ledger_response) || i !== 0 ?
                                <Fab aria-label="delete" className="fabs-icons"
                                    onClick={(event) => this.props.deleteAction(event, stepData, index, i)}
                                // onClick={(event) => console.log(stepData.actions[i])}
                                >
                                    <img alt="delete" src={Icons.deleteIcon}></img>
                                </Fab>
                                : ""
                            }
                        </div>
                    </div>
                </div>
                <div className="actions">
                    <div className="btn-wrap">
                        <Fab aria-label="edit"
                            className="fabs-icons add-icon"
                            // onClick={this.props.toggleDrawer}  onClick={(event) => this.props.toggleDrawer(event,"editAction",index)}
                            onClick={(event) => this.props.toggleDrawer(event, 'edit', index, null, stepData.conditional)}
                        >
                            {/* <img alt="edit" src={addIcon}></img> */}
                            <Add />
                        </Fab>
                    </div>
                </div>
            </div>
        )
    }
}

export default WorkflowFields;