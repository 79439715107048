import React, { Component } from 'react';
import Fab from '@material-ui/core/Fab';
import EyeWhiteIcon from '../../assets/images/icon/eye-white.svg';
import LinkWhiteIcon from '../../assets/images/icon/link-white.svg';
import EditWhiteIcon from '../../assets/images/icon/edit-white.svg';
import LaptopScreenWhiteIcon from '../../assets/images/icon/laptop-screen-white.svg';

class SpeedDialComp extends Component {
    state = {
        activeDial: '',
        activeToggle: false,
        viewTab: {
            tabName: '',
            tabStatus: false,
        },
        showSub: false,
    };

    dialClick(status) {
        this.setState({ activeDial: status });
        this.setState({ activeToggle: !this.state.activeToggle });
        this.setState({ showSub: !this.state.showSub });
        console.log(this.state.activeDial, this.state.activeToggle, this.state.showSub);
    };

    activateView(status) {
        const { viewTab } = { ...this.state };
        const currentState = viewTab;
        console.log(currentState['tabName']);
        if (currentState['tabName'] === status) {

            currentState['tabStatus'] = !currentState['tabStatus'];
        } else {

            currentState['tabStatus'] = true;
        }
        currentState['tabName'] = status;
        this.setState({ activeToggle: !this.state.activeToggle });
        this.setState({ showSub: !this.state.showSub });
        this.setState({ viewTab: currentState });
        console.log(this.state)
    }

    editProgram() {
        let { clientId, programKey } = this.props;
        //const match = this.props.match; 
        //let clientId = match.params.clientId;
        window.location = '/' + clientId + '/' + programKey + '/forms-dashboard';
    }

    render() {

        const { activeDial, activeToggle, viewTab, showSub } = this.state;

        return (
            <section className="speed-dial-sec">
                <div className={"speed-dial-main " + (activeToggle ? 'active' : "")}>
                    <div className="btn-wrap">
                        <Fab variant="extended" size="small"
                            className={"btn btn-small primary-color no-shadow " + (viewTab.tabStatus && activeDial === 'view' ? 'active' : "")}
                            onClick={() => this.dialClick("view")}>
                            <img src={EyeWhiteIcon} alt="EyeWhiteIcon}" className="MuiSvgIcon" />
                            View
                        </Fab>
                        {/* <Fab variant="extended" size="small" 
                            className={"btn btn-small primary-color no-shadow "}>
                                <img src={LinkWhiteIcon} alt="LinkWhiteIcon" className="MuiSvgIcon"/>
                                Share
                        </Fab> */}
                        <Fab variant="extended" size="small"
                            className={"btn btn-small primary-color no-shadow "} onClick={this.editProgram.bind(this)}>
                            <img src={EditWhiteIcon} alt="EditWhiteIcon" className="MuiSvgIcon" />
                            Edit
                        </Fab>
                    </div>
                </div>
                <div className={"speed-dial-sub " + (showSub ? 'active' : "")}>
                    <div className="btn-wrap">
                        <Fab variant="extended" size="small"
                            className={"btn btn-small primary-color no-shadow " + (viewTab.tabStatus && viewTab.tabName === 'laptop' ? 'active' : "")}
                            onClick={() => this.activateView("laptop")}>
                            <img src={LaptopScreenWhiteIcon} alt="LaptopScreenWhiteIcon}" className="MuiSvgIcon" />
                            Laptop
                        </Fab>
                        {/* <Fab variant="extended" size="small" 
                            className={"btn btn-small primary-color no-shadow " + ( viewTab.tabStatus && viewTab.tabName === 'phone' ? 'active' : "")} 
                            onClick={() => this.activateView("phone")}>
                            <img src={PhoneScreenWhiteIcon} alt="PhoneScreenWhiteIcon" className="MuiSvgIcon"/>
                            Phone
                        </Fab>
                        <Fab variant="extended" size="small" 
                            className={"btn btn-small primary-color no-shadow " + ( viewTab.tabStatus && viewTab.tabName === 'tablet' ? 'active' : "")} 
                            onClick={() => this.activateView("tablet")}>
                            <img src={TabletScreenWhiteIcon} alt="TabletScreenWhiteIcon" className="MuiSvgIcon"/>
                            Tablet
                        </Fab> */}
                    </div>
                </div>
            </section>
        )
    }
}

export default SpeedDialComp;