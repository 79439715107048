import React, { Component } from 'react';
import Fab from '@material-ui/core/Fab';
import { RECORD_STATUS } from '../../utilities/constants';
import { Scrollbars } from 'react-custom-scrollbars';

//Custom component
import TableComponent from '../../components/common/table-component';
import SwitchButtonActions from '../../components/fields/switch-button-action';

//API
import * as TeamMemberApi from '../../api/team-member-api';

//Constants
import * as Constants from '../../utilities/constants';

const tableUserRows = [
    { id: 'name', numeric: false, disablePadding: false, label: 'Name' },
    { id: 'employeeId', numeric: false, disablePadding: false, label: 'Employee ID' },
    { id: 'status', numeric: true, disablePadding: false, label: 'Status' },
];

function createAgentsData(name, id, status, handleToggleChange) {
    let employeeId = "";
    let statusToggle = (<SwitchButtonActions label="Active" name="status" isChecked={status} onChange={handleToggleChange(id, status)} />);
    return { name, employeeId, statusToggle };
}

class ConfigAgents extends Component {
    state = {
        agentsData: [],
        status: false,
        agentsStatus: {},
        agentTempStatus: {}
    }

    constructor(props) {
        super(props);
        this.handleGetAllAgentsData();
    }

    handleGetAllAgentsData() {
        let programKey = this.props.programkey;
        this.getAgentDetails(programKey);
        this.getAgentStatusDetails(programKey);
    }

    getAgentDetails(programKey) {
        TeamMemberApi.getAllAgentsData(programKey).then(data => {
            this.setState({ agentsData: data });
        });
    }

    getAgentStatusDetails(programKey) {
        TeamMemberApi.getAllAgentsStatus(programKey).then(data => {
            let agentsStatus = {};
            if (data == null) {
                agentsStatus["agents"] = {};
                this.setState({ agentsStatus: agentsStatus });
            } else {
                this.setState({ agentsStatus: data })
            }
        });
    }

    handleToggleChange = (id, status) => event => {
        let { agentsStatus } = this.state;
        agentsStatus.agents[id] = !status;
        this.setState({ agentsStatus: agentsStatus });
    }

    handleFinishClick() {
        this.handleSaveProgramData();
        this.props.modifyProgramSelection(Constants.CONFIG_PROCESS.API_DETAILS, 4);
    }

    handleSaveProgramData() {
        let { agentsStatus } = this.state;
        let programKey = this.props.programkey;
        agentsStatus["program_key"] = programKey;
        agentsStatus["agents"] = this.state.agentsStatus.agents;
        TeamMemberApi.saveAgentsStatus(agentsStatus).then(data => {
            this.setState({ agentsStatus: data });
        });
    }

    handleBackClick() {
        this.props.modifyProgramSelection(Constants.CONFIG_PROCESS.INSURERS, 2);
    }

    render() {
        let { agentsData, agentsStatus } = this.state;
        let agentsTableData = [];
        if (agentsData != null) {
            agentsData.forEach(data => {
                let status = false;
                if (agentsStatus && agentsStatus.agents) {
                    status = agentsStatus.agents[data.id];
                }
                agentsTableData.push(createAgentsData(data.name, data.id, status, this.handleToggleChange));
            });
        }

        return (
            <div className="drawer-tabs workflow-tabs-wrap">
                <div className="drawer-tabs-header border-bottom">
                    <div className="workflow-title">
                        <h5>Agents</h5>
                    </div>
                </div>
                <div className="drawer-tabs drawer-tabs-round no-drawer-indicator workflow-tabs-wrap">
                    <div className="tab-container program-tab-scroll">
                        <Scrollbars style={{ width: '100%', height: '100%' }}>
                            <div className="tab-container">
                                <div className="table-wrapper vertical-bottom">
                                    <TableComponent
                                        tableLabel=""
                                        rows={tableUserRows}
                                        isPagginated={true}
                                        isSearchable={false}
                                        data={agentsTableData}
                                        searchLabel=""
                                        isCheckBoxEnabled={false}
                                    />
                                </div>
                            </div>
                        </Scrollbars>
                    </div>
                </div>
                <div className="card-footer border-top p-l-0 p-r-0">
                    <div className="drawer-tabs-header">
                        <div className="workflow-title">
                            <Fab variant="extended" size="large" className="btn btn-large btn-outline pink-color"
                                onClick={this.props.handleShowConfirmDeleteDialog}>
                                Delete
                            </Fab>
                        </div>
                        <div className="workflow-tabs-search btn-wrap">
                            <Fab variant="extended" size="large" className="btn btn-large btn-plane secondary-color"
                                onClick={this.handleBackClick.bind(this)}>
                                Back
                            </Fab>
                            <Fab variant="extended" size="large" className="btn btn-large btn-plane primary-color"
                                onClick={this.handleFinishClick.bind(this)}>
                                Next
                            </Fab>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ConfigAgents;