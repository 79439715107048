import React, { Component } from 'react';
import { ExpandMore } from '@material-ui/icons';
import Fab from '@material-ui/core/Fab';
import Popover from '@material-ui/core/Popover';

class PrioritySelect extends Component {
    state = {
        anchorEl: false,
        priorityState: "low"
    };
    priorityClick(event) {
        this.setState({ anchorEl: event.currentTarget });
    }

    priorityClose(prioritySet) {
        this.setState({ anchorEl: !this.state.anchorEl });
        this.setState({ priorityState: prioritySet });
        console.log(this.state.priorityState);
        this.props.handlePriority(prioritySet);
    }

    priorityOnClose(event) {
        this.setState({ anchorEl: !this.state.anchorEl });
    }


    render() {

        const { anchorEl, priorityState } = this.state;
        let { currentPriority } = this.props;
        const open = Boolean(anchorEl);
        const id = open ? 'simple-popper' : undefined;
        currentPriority = currentPriority ? currentPriority : priorityState;

        return (
            <section className="priority-select-sec">
                <Fab variant="extended" size="large" className={"btn btn-large btn-outline btn-priority " + currentPriority} onClick={this.priorityClick.bind(this)}>
                    {currentPriority}
                    <ExpandMore className="MuiSvgIcon" />
                </Fab>
                <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={this.priorityOnClose.bind(this)}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                >
                    <div className="priority-select-wrap">
                        <div className="btn-wrap">
                            <Fab
                                variant="extended"
                                size="small"
                                className={"btn btn-large btn-plane white-color no-shadow low " + (currentPriority === 'low' ? 'active' : "")}
                                onClick={() => { this.priorityClose('low') }}>
                                Low
                            </Fab>
                            <Fab variant="extended" size="small"
                                className={"btn btn-large btn-plane white-color no-shadow lowest " + (currentPriority === 'lowest' ? 'active' : "")}
                                onClick={() => { this.priorityClose('lowest') }}>
                                Lowest
                            </Fab>
                            <Fab variant="extended" size="small"
                                className={"btn btn-large btn-plane white-color no-shadow high " + (currentPriority === 'high' ? 'active' : "")}
                                onClick={() => this.priorityClose('high')}>
                                High
                            </Fab>
                            <Fab variant="extended" size="small"
                                className={"btn btn-large btn-plane white-color no-shadow highest " + (currentPriority === 'highest' ? 'active' : "")}
                                onClick={() => this.priorityClose('highest')}>
                                Highest
                            </Fab>
                            <Fab variant="extended" size="small"
                                className={"btn btn-large btn-plane white-color no-shadow normal " + (currentPriority === 'normal' ? 'active' : "")}
                                onClick={() => this.priorityClose('normal')}>
                                Normal
                            </Fab>
                        </div>
                    </div>
                </Popover>
            </section>
        )
    }
}

export default PrioritySelect;