import React, { Component } from 'react';
import AddFieldsComponent from '../common/add-fields';
import Fab from '@material-ui/core/Fab';
import EyeIcon from '../../assets/images/icon/eye-blue.svg';
import AddIcon from '../../assets/images/icon/add-blue.svg';
import CoverageLimitIllus from '../../assets/images/illustration/coverage-limit-circle.svg';
import SearchField from '../fields/search';
import Drawer from '@material-ui/core/Drawer';
import closeIcon from '../../assets/images/icon/close.svg';
import RadioField from '../fields/radio';
import TableComponent from '../common/table-component';
import UrlTabActions from '../common/url-tab-actions';
import SwitchTabActions from '../common/switch-tab-actions';
import MultipleTabActions from '../common/multiple-tab-actions';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { Scrollbars } from 'react-custom-scrollbars';

//Constants
import * as Constants from '../../utilities/constants';

const tableRowsIndividual = [
    { id: 'name', numeric: true, disablePadding: false, label: 'Name' },
    { id: 'loi', numeric: true, disablePadding: false, label: 'LOI' },
    { id: 'detectable', numeric: true, disablePadding: false, label: 'Detectable' },
    { id: 'exclusion', numeric: true, disablePadding: false, label: 'Exclusion' },
    { id: 'created', numeric: true, disablePadding: false, label: 'Created on' },
    { id: 'actions', numeric: true, disablePadding: false, label: 'Actions' },
];

const tableRowsGroup = [
    { id: 'name', numeric: true, disablePadding: false, label: 'Name' },
    { id: 'loi', numeric: true, disablePadding: false, label: 'LOI' },
    { id: 'detectable', numeric: true, disablePadding: false, label: 'Detectable' },
    { id: 'exclusion', numeric: true, disablePadding: false, label: 'Exclusion' },
    { id: 'created', numeric: true, disablePadding: false, label: 'Created on' },
    { id: 'actions', numeric: true, disablePadding: false, label: 'Actions' },
];

function createDataIndividual(coverName, loi, detectable, exclusion, toggleDrawer, deleteCoverage) {
    let name = (<div className="table-name"><UrlTabActions formName={coverName} /></div>);
    let loiValue = loi;
    let detectableValue = detectable;
    let exclusionValue = exclusion;
    let createdOn = "15-Jun-2019";
    let actionView = (<MultipleTabActions type="icons" handleEditClick={toggleDrawer} handleDeleteClick={deleteCoverage} iconList={["edit", "delete"]} />);
    return { name, loiValue, detectableValue, exclusionValue, createdOn, actionView };
}

function createDataGroup(group_name, groupName, loi, detectable, exclusion, toggleDrawer, deleteCoverageGroup) {
    let groupNameUI = "";
    if (group_name) {
        groupNameUI = (<h5 className="table-title weight-500 text-primary-color">{group_name}</h5>);
    }
    let groupNameView = (<div className="table-name">{groupNameUI}<UrlTabActions formName={groupName} /></div>);
    let loiValue = loi;
    let detectableValue = detectable;
    let exclusionValue = exclusion;
    let createdOn = "15-Jun-2019";
    let actionView = (<MultipleTabActions type="icons" handleEditClick={toggleDrawer} handleDeleteClick={deleteCoverageGroup} iconList={["edit", "delete"]} />);
    return { groupNameView, loiValue, detectableValue, exclusionValue, createdOn, actionView };
}
class ProgramCoverageLimit extends Component {
    state = {
        right: false,
        programGroupType: true,
        coverageData: {},
        tabAction: 0,
        coverageDataNew: {},
        typeOfCover: "",
    }

    componentWillMount() {
        let { coverageData } = this.state;
        let { programData } = this.props;
        if (programData.hasOwnProperty("coverage_and_limits") && programData.coverage_and_limits != null) {
            coverageData = programData.coverage_and_limits;
        }
        else {
            coverageData["program_key"] = this.props.programkey;
        }

        this.setState({ coverageData: coverageData });
    }

    handleCoverageDataChange(coverageDataPara) {
        let { typeOfCover } = this.state;
        let { programData } = this.props;

        if (typeOfCover === "edit") {
            programData["coverage_and_limits"] = coverageDataPara;
            this.props.modifyProgramData(programData);
            this.setState({ coverageData: coverageDataPara });
        }
        if (typeOfCover === "new") {
            this.setState({ coverageDataNew: coverageDataPara });
        }
    }

    handleNextClick() {
        let { coverageData } = this.state;

        if ((coverageData.individual_cover && coverageData.individual_cover.length > 0) || (coverageData.group_cover && coverageData.group_cover[0].group_list.length > 0)) {
            this.props.handleSaveProgramData();
            this.props.modifyProgramSelection(Constants.PROGRAM.PAYMENT_METHODS, 5);
        }

    }

    handleBackClick() {
        this.props.modifyProgramSelection(Constants.PROGRAM.POLICY_PARAMETER, 3);
    }

    handleChange = () => (value) => {
        this.setState({ programGroupType: (value === "individual") });
    }

    handleActionChange(event, newValue) {
        this.setState({ tabAction: newValue });
    }

    toggleDrawer(side, open, typeOfCover, editType) {
        this.setState({ [side]: open, typeOfCover: typeOfCover, programGroupType: (editType === "individual") });
    };


    deleteCoverage(index) {
        let { coverageData } = this.state;
        let { programData } = this.props;
        if (coverageData.individual_cover.length > 0) {
            coverageData.individual_cover.splice(index, 1);
        }
        programData.coverage_and_limits = coverageData;
        this.props.modifyProgramData(programData);
        this.setState({ coverageData: coverageData },
            () => this.props.handleSaveProgramData()
        );
    }

    deleteCoverageGroup(index, i) {
        let { coverageData } = this.state;
        let { programData } = this.props;
        if (coverageData.group_cover.length > 0) {
            coverageData.group_cover[index] &&
                coverageData.group_cover[index].group_list.splice(i, 1);

            if (coverageData.group_cover[index] && coverageData.group_cover[index].group_list.length === 0) {
                coverageData.group_cover.splice(index, 1);
            }

        }
        programData.coverage_and_limits = coverageData;
        this.props.modifyProgramData(programData);
        this.setState({ coverageData: coverageData },
            () => this.props.handleSaveProgramData()
        );
    }


    handleSaveCoverageData() {
        let { coverageData, coverageDataNew, typeOfCover, programGroupType } = this.state;
        let { programData } = this.props;
        if (typeOfCover === "edit") {
            if (programGroupType) {
                if (coverageData.individual_cover.length > 0) {
                    let coverNameValidation = true;

                    coverageData.individual_cover.forEach(cover => {
                        if (cover.cover_name === null || cover.cover_name === "" || cover.cover_name === undefined) {
                            coverNameValidation = false;
                        }
                    })
                    if (coverNameValidation) {
                        this.props.modifyProgramData(programData);
                        this.props.handleSaveProgramData();
                        this.toggleDrawer('right', false, null);
                    }
                }
            }
            else {
                if (coverageData.group_cover.length > 0) {
                    let coverNameandListValidation = true;

                    coverageData.group_cover.forEach(cover => {
                        cover.group_list.forEach(list => {
                            if (list.cover_name === null || list.cover_name === "" || list.cover_name === undefined || cover.group_name === null || cover.group_name === "") {
                                coverNameandListValidation = false;
                            }
                        })
                    })
                    if (coverNameandListValidation) {
                        this.props.modifyProgramData(programData);
                        this.props.handleSaveProgramData();
                        this.toggleDrawer('right', false, null);
                    }
                }
            }
        }

        if (typeOfCover === "new") {
            let individual = coverageDataNew.individual_cover;
            let group = coverageDataNew.group_cover;

            if (individual) {
                let coverNameValidation = true;

                individual.forEach(cover => {
                    if (cover.cover_name === null || cover.cover_name === "" || cover.cover_name === undefined) {
                        coverNameValidation = false;
                    }
                })
                if (!coverNameValidation) {
                    individual = null;
                }
            }

            if (group) {

                let coverNameandListValidation = true;

                group.forEach(cover => {
                    cover.group_list.forEach(list => {
                        if (list.cover_name === null || list.cover_name === "" || list.cover_name === undefined || cover.group_name === null || cover.group_name === "") {
                            coverNameandListValidation = false;
                        }
                    })
                })
                if (!coverNameandListValidation) {
                    group = null;
                }

            }
            if (individual) {
                if (coverageData.hasOwnProperty("individual_cover") && coverageData.individual_cover !== undefined) {
                    coverageData.individual_cover = coverageData.individual_cover.concat(individual);
                    programData["coverage_and_limits"] = coverageData;
                }
                else if (!(coverageData.hasOwnProperty("individual_cover"))) {
                    coverageData.individual_cover = coverageDataNew.individual_cover;
                    programData["coverage_and_limits"] = coverageData;
                }

            }
            if (group) {
                if (coverageData.hasOwnProperty("group_cover") && coverageData.group_cover !== undefined) {
                    group.forEach((list, index) => {
                        coverageData.group_cover.push(list);
                    })
                    programData["coverage_and_limits"] = coverageData;
                }
                else if (!(coverageData.hasOwnProperty("group_cover"))) {
                    coverageData.group_cover = coverageDataNew.group_cover;
                    programData["coverage_and_limits"] = coverageData;
                }
            }


            this.props.modifyProgramData(programData);

            this.toggleDrawer('right', false, null);
            this.setState({ coverageData: coverageData, coverageDataNew: {} },
                () => this.props.handleSaveProgramData()
            );

        }
    }

    handleCancelCover() {
        let { coverageData } = this.state;
        console.log("handleCancelCover", coverageData);

        coverageData.individual_cover &&
            coverageData.individual_cover.forEach((individual, index) => {
                if (individual.cover_name === "" || individual.cover_name === null) {
                    coverageData.individual_cover.splice(index, 1);
                }
            })

        coverageData.group_cover &&
            coverageData.group_cover.forEach((group, index) => {
                if (group.group_name === "" || group.group_name === null) {
                    coverageData.group_cover.splice(index, 1);
                }
                else {
                    group.group_list &&
                        group.group_list.forEach((list, ind) => {
                            if (list.cover_name === "" || list.cover_name === null) {
                                group.group_list.splice(ind, 1);
                                return;
                            }
                        })
                }
            })



        this.toggleDrawer('right', false, null);
    }

    render() {
        let { coverageData, tabAction, coverageDataNew, typeOfCover } = this.state;
        let tableDataIndividual = [];
        let tableDataGroup = [];

        console.log("programGroupType", this.state.programGroupType);

        if (coverageData.hasOwnProperty("individual_cover") && coverageData.individual_cover !== null) {
            coverageData.individual_cover.forEach((cover, index) => {
                tableDataIndividual.push(createDataIndividual(cover.cover_name, cover.limit_of_liability, cover.detectable, cover.exclusion, (event) => { this.toggleDrawer('right', true, "edit", "individual") }, (event) => { this.deleteCoverage(index) }));
            })
        }

        if (coverageData.hasOwnProperty("group_cover") && coverageData.group_cover !== null) {
            coverageData.group_cover.forEach((cover, index) => {
                if (cover) {
                    cover.group_list.forEach((data, i) => {
                        tableDataGroup.push(createDataGroup(cover.group_name, data.cover_name, data.limit_of_liability, data.detectable, data.exclusion, (event) => { this.toggleDrawer('right', true, "edit", "group") }, (event) => { this.deleteCoverageGroup(index, i) }));
                    })
                }
            })
        }
        let programType = [
            {
                field_option_label: "Individual",
                field_option_value: "individual",
            },
            {
                field_option_label: "Group",
                field_option_value: "group"
            }
        ]
        return (
            <div className="drawer-tabs workflow-tabs-wrap">
                <div className="drawer-tabs-header border-bottom">
                    <div className="workflow-title">
                        <h5>Coverage and Limits</h5>
                    </div>
                    <div className="workflow-tabs-search">
                        <Fab variant="extended" size="large" className="btn btn-large secondary-color workflow-add-btn no-shadow " onClick={this.props.handleDownloadMasterPolicyFile} style={{ minWidth: '180px' }}>
                            <img src={EyeIcon} alt="EyeIcon" className="MuiSvgIcon" />
                            Master Policy
                        </Fab>
                    </div>
                </div>
                <div className="drawer-tabs-header">
                    <div className="workflow-title">
                        <div className="drawer-tabs drawer-tabs-round no-drawer-indicator workflow-tabs-wrap">
                            <div className="drawer-tabs-header">
                                <Tabs value={tabAction} onChange={this.handleActionChange.bind(this)}>
                                    <Tab label="Individual" />
                                    <Tab label="Group" />
                                </Tabs>
                            </div>
                        </div>
                    </div>
                    <div className="workflow-tabs-search">
                        <SearchField placeholder="Search here" />
                        <Fab variant="extended" size="large" className="btn btn-large secondary-color workflow-add-btn no-shadow" onClick={(event) => { this.toggleDrawer('right', true, "new", "individual") }}>
                            <img src={AddIcon} alt="AddIcon" /> &nbsp;
                            Add Cover
                        </Fab>
                    </div>
                </div>
                <div className="drawer-tabs drawer-tabs-round no-drawer-indicator workflow-tabs-wrap">


                    {tabAction === 0 &&
                        <div className="tab-container program-tab-scroll">
                            <Scrollbars style={{ width: '100%', height: '100%' }}>
                                {tableDataIndividual.length > 0 && (
                                    <div className="tab-container">
                                        <div className="table-wrapper vertical-bottom">
                                            <TableComponent
                                                tableLabel=""
                                                rows={tableRowsIndividual}
                                                isPagginated={true}
                                                isSearchable={false}
                                                data={tableDataIndividual}
                                                searchLabel=""
                                                isCheckBoxEnabled={false}
                                            />
                                        </div>
                                    </div>
                                )}
                                {tableDataIndividual.length <= 0 && (
                                    <section className="blank-page-sec">
                                        <div className="blank-page-wrap">
                                            <div className="blank-page-icon">
                                                <img src={CoverageLimitIllus} alt="CoverageLimitIllus" />
                                            </div>
                                            <div className="blank-page-info">
                                                <h5>You haven't added any Coverage and Limits yet!</h5>
                                            </div>
                                            <Fab variant="extended" size="large" className="btn btn-large workflow-add-btn no-shadow primary-color" onClick={(event) => { this.toggleDrawer('right', true, "new") }}>
                                                New Cover
                                            </Fab>
                                        </div>
                                    </section>
                                )}
                            </Scrollbars>
                        </div>
                    }

                    {tabAction === 1 &&
                        <div className="tab-container program-tab-scroll">
                            <Scrollbars style={{ width: '100%', height: '100%' }}>
                                {tableDataGroup.length > 0 && (
                                    <div className="tab-container">
                                        <div className="table-wrapper vertical-bottom">
                                            <TableComponent
                                                tableLabel=""
                                                rows={tableRowsGroup}
                                                isPagginated={true}
                                                isSearchable={false}
                                                data={tableDataGroup}
                                                searchLabel=""
                                                isCheckBoxEnabled={false}
                                            />
                                        </div>
                                    </div>
                                )}
                                {tableDataIndividual.length <= 0 && (
                                    <section className="blank-page-sec">
                                        <div className="blank-page-wrap">
                                            <div className="blank-page-icon">
                                                <img src={CoverageLimitIllus} alt="CoverageLimitIllus" />
                                            </div>
                                            <div className="blank-page-info">
                                                <h5>You haven't added any Coverage and Limits yet!</h5>
                                            </div>
                                            <Fab variant="extended" size="large" className="btn btn-large workflow-add-btn no-shadow primary-color" onClick={event => this.handleOpen("open")}>
                                                New Cover
                                            </Fab>
                                        </div>
                                    </section>
                                )}
                            </Scrollbars>
                        </div>
                    }
                </div>

                <div className="card-footer border-top p-l-0 p-r-0">
                    <div className="btn-wrap">
                        <Fab variant="extended" size="large" className="btn btn-large btn-plane primary-color" onClick={this.handleNextClick.bind(this)}>
                            Next
                        </Fab>
                        <Fab variant="extended" size="large" className="btn btn-large btn-plane grey-color" onClick={this.handleBackClick.bind(this)}>
                            Back
                        </Fab>
                    </div>
                </div>

                <Drawer anchor="right" open={this.state.right} onClose={() => { this.handleCancelCover() }}>
                    <section className="workflow-setup-sec program-setup-sec">
                        <section className="setup-drawer-sec">
                            <div className="card-block">
                                <div className="card-header border-bottom">
                                    <div className="card-title">
                                        <h2>Add Cover</h2>
                                    </div>
                                    <div className="card-left-title" style={{ width: 'auto' }}>
                                        <div className="actions setup-close">
                                            <div className="btn-wrap">
                                                <Fab variant="extended" size="small" className="btn btn-small btn-plane btn-fab white-color no-shadow" onClick={() => { this.handleCancelCover() }}>
                                                    Close <img alt="edit" src={closeIcon}></img>
                                                </Fab>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div role="presentation" className="drawer-sec">
                                        <div className="drawer-form-sec">
                                            <section className="add-fields-wrap">
                                                <div className="add-field-row">
                                                    <RadioField
                                                        radioGroupLabel="Program Type"
                                                        radioData={programType}
                                                        label="Program Type"
                                                        radioFieldStyle="field-inline"
                                                        customStyle="m-0"
                                                        default_value={this.state.programGroupType ? "individual" : "group"}
                                                        radioChangeHandle={this.handleChange().bind(this)}
                                                    />
                                                </div>
                                            </section>
                                            <div className="tab-container tab-container-scroll" style={{ height: 'calc(100vh - 295px)' }}>
                                                <Scrollbars style={{ width: '100%', height: '100%' }}>
                                                    <AddFieldsComponent
                                                        addFieldType={this.state.programGroupType ? "add-multiple-field" : "add-group-field"}
                                                        coverageData={typeOfCover === "edit" ? coverageData : coverageDataNew}
                                                        handleCoverageDataChange={this.handleCoverageDataChange.bind(this)}
                                                    />
                                                </Scrollbars>
                                            </div>
                                            <div className="card-footer border-top">
                                                <div className="btn-wrap">
                                                    <Fab variant="extended" size="large" className="btn btn-large btn-plane primary-color" onClick={(event) => { this.handleSaveCoverageData() }}> Save</Fab>
                                                    <Fab variant="extended" size="large" className="btn btn-large btn-plane grey-color" onClick={() => { this.handleCancelCover() }}>Cancel</Fab>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </section>
                </Drawer>
            </div>
        )
    }
}

export default ProgramCoverageLimit;