import React, { Component } from 'react';
import Header from '../../components/header';
import WorkflowClientHeader from '../../components/workflow/workflow-client-header';

//Constants
import * as Constants from '../../utilities/constants';

//API
import * as ClientApi from '../../api/client-api';

class ClientDashboard extends Component {

    state = {
        active: false,
        sidebarActive: Constants.CLIENT_SIDEBAR.BASIC_DETAILS,
        buttonState: false,
        clientData: {}
    }

    componentWillMount() {
        const match = this.props.match;
        let clientId = match.params.clientId;
        this.handleGetClientData(clientId);
    }

    handleGetClientData(clientId) {
        ClientApi.getClientById(clientId).then(data => {
            this.setState({ clientData: data });
        })
    }

    handleSaveClientData() {
        let { clientData } = this.state;
        ClientApi.updateClient(clientData).then(data => {
            this.setState({ clientData: data })
        })
    }

    handleSidebarSelection = (selectedSidebar) => () => {
        this.setState({
            sidebarActive: selectedSidebar
        })
    }

    toggleTimeline() {
        this.setState({ active: !this.state.active });
    }

    // performSelectedSidebarAction(sidebarActive) {
    //     const match = this.props.match; 
    //     let clientId = match.params.clientId;

    //     switch(sidebarActive) {
    //         case Constants.CLIENT_SIDEBAR.BASIC_DETAILS:
    //             return(
    //                 <ClientBasicDetail 
    //                 clientData={this.state.clientData}
    //                 handleSaveClientData={this.handleSaveClientData.bind(this)}
    //                 clientId={clientId}
    //                 />
    //             );

    //         default: break;
    //     }
    // }

    render() {
        let { active, sidebarActive, buttonState, clientData } = this.state;
        const match = this.props.match;
        let clientID = match.params.clientId;

        let { clientId } = this.props
        clientId = clientID
        console.log("Id 1 " + clientID + " Id 2 " + clientId);

        //let ToggleBtnArrow = buttonState ? "toggle-arrow" : "";
        let toggleTimeline = active ? "toggle-timeline" : "";

        console.log("Client Dashboard's Client Data: ", clientData);
        return (
            <div className="ClientDashboard">
                <Header headerType="dashboard" positionType="fixed" clientId={clientId} />
                <WorkflowClientHeader workflowType="normal" tab="client" clientId={clientId} />
                <main>
                    <div className="workflow-fields-sec program-build-sec create-app-sec level-1-header">
                        <div className="program-build-wrap">
                            <div className={"program-card-wrap program-build-timeline " + toggleTimeline}>
                                <div className="card-block">
                                    <div className="card-body">
                                        {/* <ClientTimeline 
                                            buttonClick={this.toggleTimeline.bind(this)}
                                            buttonState={this.state.active}
                                            modifyProgramSelection={this.modifyProgramSelection.bind(this)}
                                            programSelection={this.state.programSelection}
                                            timeline_count={this.state.timeline_count}
                                            isFinish={this.state.isFinish}
                                        /> */}
                                    </div>
                                </div>
                            </div>
                            <div className={"program-card-wrap program-build-form " + toggleTimeline}>
                                <div className="card-block">
                                    <div className="card-body">
                                        {/* {this.selectionProgramType(programSelection)} */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        )
    }
}

export default ClientDashboard;