import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import Fab from '@material-ui/core/Fab';
import Slide from '@material-ui/core/Slide';
import closeIcon from '../../assets/images/icon/close.svg';
import ArrowBackGrey from '../../assets/images/icon/chevron-left.svg';
import WorkFlowIllust from '../../assets/images/illustration/flow-iso.svg';
import WorkflowHeader from '../../components/workflow/workflow-header';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import SearchField from '../../components/fields/search';
import AddIcon from '@material-ui/icons/Add';
import FormIllus from '../../assets/images/illustration/form.svg';
import AddScheduleIllus from '../../assets/images/illustration/add-date-1.svg';
import AddPremiumIllus from '../../assets/images/illustration/add-premium.svg';
import AddPaymentIllus from '../../assets/images/illustration/add-payment.svg';
import CalculatePremiumIllus from '../../assets/images/illustration/calculate-premium.svg';
import PaymentIllus from '../../assets/images/illustration/payment.svg';
import DateIllus from '../../assets/images/illustration/date.svg';
import { Scrollbars } from 'react-custom-scrollbars';

//Custom component
import TableComponent from '../../components/common/table-component';
import Header from '../../components/header';
import RadioField from '../../components/fields/radio';
import SelectField from '../../components/fields/select';
import InputField from '../../components/fields/inputs';
import UrlTabActions from '../../components/common/url-tab-actions';
import SwitchTabActions from '../../components/common/switch-tab-actions';
import MultipleTabActions from '../../components/common/multiple-tab-actions';

//API
import * as FormApi from '../../api/form-api';
import * as WorkflowApi from '../../api/workflow-api';


const formTrigger = [
    {
        field_option_label: "Created",
        field_option_value: "created",
    },
    {
        field_option_label: "Created or Edited",
        field_option_value: "createdOrEdited",
    },
    {
        field_option_label: "Edited",
        field_option_value: "edited",
    },
    {
        field_option_label: "Deleted",
        field_option_value: "deleted",
    },
    {
        field_option_label: "Always",
        field_option_value: "always",
        option_type: 2
    },
]

const tableRows = [
    { id: 'workflow_name', numeric: false, disablePadding: false, label: 'Name' },
    { id: 'details', numeric: true, disablePadding: false, label: 'Details' },
    { id: 'when', numeric: true, disablePadding: false, label: 'When' },
    //{ id: 'status', numeric: true, disablePadding: false, label: 'Status' },
    { id: 'createdOn', numeric: true, disablePadding: false, label: 'Created on' },
    { id: 'UpdatedOn', numeric: true, disablePadding: false, label: 'Updated on' },
    { id: 'actions', numeric: true, disablePadding: false, label: 'Actions' },
];


function createData(index, formName, actions, workflowKey, workflow_name, workflow_type, workflowStatus, trigger_type, formId, created_on, modifiedOn, editFormRedirect, handleStatusChange, dublicateWorkflow) {
    let formNameUI = "";
    if (formName) {
        formNameUI = (<h5 className="table-title weight-500 text-primary-color">{formName}</h5>);
    }
    let formNameView = (<div className="table-name">{formNameUI}<UrlTabActions formName={workflow_name} handleNameClick={editFormRedirect(workflowKey)} /></div>);
    let details = (<div className="bold text-primary-color">{actions.length} Actions</div>);
    let when = trigger_type && formTrigger.filter(formTrigger => formTrigger.field_option_value === trigger_type)[0].field_option_label;
    //let statusView = (<SwitchTabActions status={(workflowStatus === "active")? "enabled" : "disabled"} handleStatusChange={handleStatusChange(workflowKey,formId,workflowStatus)}/>);
    let createdOn = created_on;
    let UpdatedOn = modifiedOn;
    let actionView = (<MultipleTabActions type="icons" handleEditClick={editFormRedirect(workflowKey)} handelDuplicateClick={dublicateWorkflow(formId, workflowKey)} iconList={["edit", "duplicate"]} />)
    return { formNameView, details, when, createdOn, UpdatedOn, actionView };
}

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


class WorkflowDashboard extends Component {

    state = {
        tableData: [],
        triggerDialogOpen: false,
        workflowDialogOpen: false,
        selectedTriggerType: 0,
        formList: [],
        workflowFormData: {
            workflow_trigger: {
                trigger_type: "always"
            },
        },
        formTableData: [],
        scheduleTableData: [],
        calculationTableData: [],
        paymentTableData: [],
        ledgerTableData: [],
        tabAction: 0,
        workflowData: [],
        customStyleSearch: '',
        dataReceived: false,
    }

    constructor(props) {
        super(props);
        this.getAllFormData();
    }

    componentWillMount() {
        if (this.props.location && this.props.location.state) {
            if (this.props.location.state.id) {
                this.setState({ triggerDialogOpen: true })
            }
            else {
                this.setState({ triggerDialogOpen: false })
            }
        }
    }

    getAllFormData() {
        let tableData = [];
        let formTableData = [];
        let scheduleTableData = [];
        let calculationTableData = [];
        let paymentTableData = [];
        let ledgerTableData = [];
        const match = this.props.match;
        let programkey = match.params.programkey;
        WorkflowApi.getAllWorkflow(programkey).then(res => {
            res.forEach((form) => {
                form.workflows.filter(workflow => workflow.workflow_type === "1").forEach((workflow, index) => {
                    if (index === 0) {
                        workflow['form_name'] = form.form_name;
                    }
                    formTableData.push(createData(index, workflow.form_name, workflow.actions, workflow.workflow_key, workflow.workflow_name, workflow.workflow_type, workflow.status, workflow.trigger_type, workflow.form_id, workflow.created_on, workflow.modified_on, this.editWorkflowRedirect, this.handleStatusChange, this.handleDublicateData));
                });
                form.workflows.filter(workflow => workflow.workflow_type === "2").forEach((workflow, index) => {
                    if (index === 0) {
                        workflow['form_name'] = form.form_name;
                    }
                    scheduleTableData.push(createData(index, workflow.form_name, workflow.actions, workflow.workflow_key, workflow.workflow_name, workflow.workflow_type, workflow.status, workflow.trigger_type, workflow.form_id, workflow.created_on, workflow.modified_on, this.editWorkflowRedirect, this.handleStatusChange, this.handleDublicateData));
                });
                form.workflows.filter(workflow => workflow.workflow_type === "3").forEach((workflow, index) => {
                    if (index === 0) {
                        workflow['form_name'] = form.form_name;
                    }
                    calculationTableData.push(createData(index, workflow.form_name, workflow.actions, workflow.workflow_key, workflow.workflow_name, workflow.workflow_type, workflow.status, workflow.trigger_type, workflow.form_id, workflow.created_on, workflow.modified_on, this.editWorkflowRedirect, this.handleStatusChange, this.handleDublicateData));
                });
                form.workflows.filter(workflow => workflow.workflow_type === "4").forEach((workflow, index) => {
                    if (index === 0) {
                        workflow['form_name'] = form.form_name;
                    }
                    paymentTableData.push(createData(index, workflow.form_name, workflow.actions, workflow.workflow_key, workflow.workflow_name, workflow.workflow_type, workflow.status, workflow.trigger_type, workflow.form_id, workflow.created_on, workflow.modified_on, this.editWorkflowRedirect, this.handleStatusChange, this.handleDublicateData));
                });
                form.workflows.filter(workflow => workflow.workflow_type === "5").forEach((workflow, index) => {
                    if (index === 0) {
                        workflow['form_name'] = form.form_name;
                    }
                    ledgerTableData.push(createData(index, workflow.form_name, workflow.actions, workflow.workflow_key, workflow.workflow_name, workflow.workflow_type, workflow.status, workflow.trigger_type, workflow.form_id, workflow.created_on, workflow.modified_on, this.editWorkflowRedirect, this.handleStatusChange, this.handleDublicateData));
                });
                // workflowList.push(workflow);
                // console.log("workflowType ",workflow.workflow_type)

            })
            this.setState({ tableData: tableData, workflowData: res, ledgerTableData: ledgerTableData, scheduleTableData: scheduleTableData, calculationTableData: calculationTableData, paymentTableData: paymentTableData, formTableData: formTableData, dataReceived: true });
        });

        FormApi.getAllFormData(programkey).then(res => {
            let formList = [];
            res.forEach(form => {
                let obj = {
                    field_option_label: form.form_name,
                    field_option_value: form.id,
                };
                formList.push(obj);
            })
            this.setState({ formList: formList });
        });
    }

    generateTable(workflowKey, formId, workflowStatus) {
        let { workflowData } = this.state;
        let workflowId = workflowData.filter(workflowData => workflowData.form_id === formId)[0].workflows.filter(workflow => workflow.workflow_key === workflowKey)[0].workflow_id;
        WorkflowApi.updateWorkflowStatus(workflowId, workflowStatus).then(res => {
            this.getAllFormData();
        });
    }

    handleDublicateData = (formId, workflowKey) => event => {
        const match = this.props.match;
        let programkey = match.params.programkey;
        let selectedWorkflow = this.state.workflowData.filter(form => form.form_id === formId)[0].workflows.filter(workflow => workflow.workflow_key === workflowKey)[0];
        let workflowTrigger = {};
        workflowTrigger["trigger_type"] = selectedWorkflow.trigger_type;
        workflowTrigger["form_id"] = selectedWorkflow.form_id;
        selectedWorkflow["workflow_trigger"] = workflowTrigger;
        selectedWorkflow["program_key"] = programkey;
        selectedWorkflow["id"] = selectedWorkflow.workflow_id;
        selectedWorkflow["modified_on"] = null;
        selectedWorkflow["created_on"] = null;
        WorkflowApi.duplicateWorkflow(selectedWorkflow).then(result => {
            console.log("duplicated workflow", result)
            this.getAllFormData()

        })
    }

    handleStatusChange = (workflowKey, formId, workflowStatus) => event => {
        workflowStatus = (workflowStatus === "active") ? "inactive" : "active";
        this.generateTable(workflowKey, formId, workflowStatus);
    }

    editWorkflowRedirect = key => event => {
        event.stopPropagation();
        this.editWorkflow(key);
    }

    editWorkflow = key => {
        const match = this.props.match;
        let programkey = match.params.programkey;
        let clientId = match.params.clientId;
        window.location = "/" + clientId + "/" + programkey + "/workflow/" + key + "/builder";
    }

    recordsFormRedirect = key => event => {
        event.stopPropagation();
        const match = this.props.match;
        let programkey = match.params.programkey;
        let clientId = match.params.clientId;
        window.location = "/" + clientId + "/" + programkey + "/form/" + key + "/records";
    }

    handleDialogOpen(type) {
        this.setState({ [type]: true });
    }

    handleClose = (type) => (event) => {
        this.setState({ [type]: false });
    }

    createNewWorkflow() {
        this.handleDialogOpen("triggerDialogOpen");
    }

    addSpecificWorkflow = (key, tabAction) => () => {
        this.setState({ [key]: true });
        let workflowData = this.state.workflowFormData;
        workflowData["workflow_type"] = tabAction;
    }

    selectTriggerHandle = (triggerType) => (event) => {
        this.setState({ selectedTriggerType: triggerType });
        this.handleClose("triggerDialogOpen");
        this.handleDialogOpen("workflowDialogOpen");
        this.setWorkflowFormData("workflow_type", triggerType);
    }

    handleFormChange = (fieldName) => (value, errors) => {
        this.setWorkflowFormData(fieldName, value);
    }

    setWorkflowFormData(fieldName, value) {
        let { workflowFormData } = this.state;
        if (fieldName.split(".").length > 1) {
            let fieldSplit = fieldName.split(".");
            fieldName = fieldSplit[0];
            if (!workflowFormData[fieldName]) {
                workflowFormData[fieldName] = {};
            }
            workflowFormData[fieldName][fieldSplit[1]] = value;
        } else {
            workflowFormData[fieldName] = value;
        }
        this.setState({ workflowFormData: workflowFormData });
    }

    saveWorkflowData() {
        const match = this.props.match;
        let programkey = match.params.programkey;
        let workflowData = this.state.workflowFormData;
        workflowData["program_key"] = programkey;
        if (workflowData.workflow_name) {
            WorkflowApi.saveWorkflowForm(workflowData).then(res => {
                this.editWorkflow(res.workflow_key);
            });
        }

    }

    handleActionChange(event, newValue) {
        this.setState({ tabAction: newValue });
    }

    searchBtnClick() {
        let { workflowData, customStyleSearch } = this.state;
        console.log("customStyleSearch", customStyleSearch, "workflowData", workflowData);
        let searchedWorkflowData = [];
        let formTableData = [];
        let scheduleTableData = [];
        let calculationTableData = [];
        let paymentTableData = [];
        let ledgerTableData = [];
        // searchedSectionData = sectionData.filter(section=>(section.section_name.toLowerCase().includes(customStyleSearch.toLowerCase())));
        // console.log("searchedSectionData", searchedSectionData);

        workflowData.forEach(form => {
            if (form.form_name.toLowerCase().includes(customStyleSearch.toLowerCase())) {
                searchedWorkflowData.push(form);
            } else {
                let check = false;
                if (form.workflows && form.workflows.length > 0) {
                    form.workflows.forEach(workflow => {

                        if (!check && workflow.workflow_name.toLowerCase().includes(customStyleSearch.toLowerCase())) {
                            searchedWorkflowData.push(form);
                            check = true;
                        }
                    })
                }
            }
        })

        console.log("searchedWorkflowData", searchedWorkflowData);

        if (searchedWorkflowData && searchedWorkflowData.length > 0) {
            searchedWorkflowData.forEach((form) => {
                form.workflows.filter(workflow => workflow.workflow_type === "1").forEach((workflow, index) => {
                    if (index === 0) {
                        workflow['form_name'] = form.form_name;
                    }
                    formTableData.push(createData(index, workflow.form_name, workflow.actions, workflow.workflow_key, workflow.workflow_name, workflow.workflow_type, workflow.status, workflow.trigger_type, workflow.form_id, workflow.created_on, workflow.modified_on, this.editWorkflowRedirect, this.handleStatusChange, this.handleDublicateData));
                });
                form.workflows.filter(workflow => workflow.workflow_type === "2").forEach((workflow, index) => {
                    if (index === 0) {
                        workflow['form_name'] = form.form_name;
                    }
                    scheduleTableData.push(createData(index, workflow.form_name, workflow.actions, workflow.workflow_key, workflow.workflow_name, workflow.workflow_type, workflow.status, workflow.trigger_type, workflow.form_id, workflow.created_on, workflow.modified_on, this.editWorkflowRedirect, this.handleStatusChange, this.handleDublicateData));
                });
                form.workflows.filter(workflow => workflow.workflow_type === "3").forEach((workflow, index) => {
                    if (index === 0) {
                        workflow['form_name'] = form.form_name;
                    }
                    calculationTableData.push(createData(index, workflow.form_name, workflow.actions, workflow.workflow_key, workflow.workflow_name, workflow.workflow_type, workflow.status, workflow.trigger_type, workflow.form_id, workflow.created_on, workflow.modified_on, this.editWorkflowRedirect, this.handleStatusChange, this.handleDublicateData));
                });
                form.workflows.filter(workflow => workflow.workflow_type === "4").forEach((workflow, index) => {
                    if (index === 0) {
                        workflow['form_name'] = form.form_name;
                    }
                    paymentTableData.push(createData(index, workflow.form_name, workflow.actions, workflow.workflow_key, workflow.workflow_name, workflow.workflow_type, workflow.status, workflow.trigger_type, workflow.form_id, workflow.created_on, workflow.modified_on, this.editWorkflowRedirect, this.handleStatusChange, this.handleDublicateData));
                });
                form.workflows.filter(workflow => workflow.workflow_type === "5").forEach((workflow, index) => {
                    if (index === 0) {
                        workflow['form_name'] = form.form_name;
                    }
                    ledgerTableData.push(createData(index, workflow.form_name, workflow.actions, workflow.workflow_key, workflow.workflow_name, workflow.workflow_type, workflow.status, workflow.trigger_type, workflow.form_id, workflow.created_on, workflow.modified_on, this.editWorkflowRedirect, this.handleStatusChange, this.handleDublicateData));
                });

            })
            this.setState({ scheduleTableData: scheduleTableData, calculationTableData: calculationTableData, ledgerTableData: ledgerTableData, paymentTableData: paymentTableData, formTableData: formTableData });
        }

    }

    handleOnChangeValue(event) {
        let { workflowData } = this.state;
        let formTableData = [];
        let scheduleTableData = [];
        let calculationTableData = [];
        let paymentTableData = [];
        let ledgerTableData = [];
        if (event.target.value === "") {
            if (workflowData && workflowData.length > 0) {
                workflowData.forEach((form) => {
                    form.workflows.filter(workflow => workflow.workflow_type === "1").forEach((workflow, index) => {
                        if (index === 0) {
                            workflow['form_name'] = form.form_name;
                        }
                        formTableData.push(createData(index, workflow.form_name, workflow.actions, workflow.workflow_key, workflow.workflow_name, workflow.workflow_type, workflow.status, workflow.trigger_type, workflow.form_id, workflow.created_on, workflow.modified_on, this.editWorkflowRedirect, this.handleStatusChange, this.handleDublicateData));
                    });
                    form.workflows.filter(workflow => workflow.workflow_type === "2").forEach((workflow, index) => {
                        if (index === 0) {
                            workflow['form_name'] = form.form_name;
                        }
                        scheduleTableData.push(createData(index, workflow.form_name, workflow.actions, workflow.workflow_key, workflow.workflow_name, workflow.workflow_type, workflow.status, workflow.trigger_type, workflow.form_id, workflow.created_on, workflow.modified_on, this.editWorkflowRedirect, this.handleStatusChange, this.handleDublicateData));
                    });
                    form.workflows.filter(workflow => workflow.workflow_type === "3").forEach((workflow, index) => {
                        if (index === 0) {
                            workflow['form_name'] = form.form_name;
                        }
                        calculationTableData.push(createData(index, workflow.form_name, workflow.actions, workflow.workflow_key, workflow.workflow_name, workflow.workflow_type, workflow.status, workflow.trigger_type, workflow.form_id, workflow.created_on, workflow.modified_on, this.editWorkflowRedirect, this.handleStatusChange, this.handleDublicateData));
                    });
                    form.workflows.filter(workflow => workflow.workflow_type === "4").forEach((workflow, index) => {
                        if (index === 0) {
                            workflow['form_name'] = form.form_name;
                        }
                        paymentTableData.push(createData(index, workflow.form_name, workflow.actions, workflow.workflow_key, workflow.workflow_name, workflow.workflow_type, workflow.status, workflow.trigger_type, workflow.form_id, workflow.created_on, workflow.modified_on, this.editWorkflowRedirect, this.handleStatusChange, this.handleDublicateData));
                    });
                    form.workflows.filter(workflow => workflow.workflow_type === "5").forEach((workflow, index) => {
                        if (index === 0) {
                            workflow['form_name'] = form.form_name;
                        }
                        ledgerTableData.push(createData(index, workflow.form_name, workflow.actions, workflow.workflow_key, workflow.workflow_name, workflow.workflow_type, workflow.status, workflow.trigger_type, workflow.form_id, workflow.created_on, workflow.modified_on, this.editWorkflowRedirect, this.handleStatusChange, this.handleDublicateData));
                    });
                    // workflowList.push(workflow);
                    // console.log("workflowType ",workflow.workflow_type)

                })
                this.setState({ scheduleTableData: scheduleTableData, calculationTableData: calculationTableData, ledgerTableData: ledgerTableData, paymentTableData: paymentTableData, formTableData: formTableData });

            }
        }
        this.setState({ customStyleSearch: event.target.value });
    }

    render() {
        let { triggerDialogOpen, workflowDialogOpen, formList, tabAction, workflowFormData, customStyleSearch, formTableData, scheduleTableData, calculationTableData, paymentTableData, ledgerTableData, dataReceived, selectedTriggerType } = this.state;
        const match = this.props.match;
        let clientId = match.params.clientId;
        let programkey = match.params.programkey;
        console.log("selectedTriggerType", selectedTriggerType);
        return (
            <div className="FormBuilderScene">
                <Header headerType="dashboard" positionType="fixed" programKey={programkey} />
                <WorkflowHeader workflowType="normal" clientId={clientId} programKey={programkey} tab="workflow" />
                <main>
                    {
                        <div className="workflow-fields-sec workflow-fields-dashboard">
                            {/* <Grid container spacing={24}>
                            <Grid item sm={10}/>
                            <Grid item xs={12} sm={2}>
                                <Button variant="contained" color="primary" > 
                                    Create New Workflow
                                </Button>
                            </Grid>
                        </Grid> */}
                            <div className="drawer-tabs drawer-tabs-round no-drawer-indicator workflow-tabs-wrap">
                                <div className="drawer-tabs-header">
                                    <Tabs value={tabAction} onChange={this.handleActionChange.bind(this)}>
                                        <Tab label="Form Workflow" />
                                        {/* <Tab label="Schedules" /> */}
                                        {/* <Tab label="Premium" /> */}
                                        <Tab label="Payments" />
                                        <Tab label="Ledger flow" />
                                    </Tabs>
                                    <div className="workflow-tabs-search">
                                        <SearchField placeholder="Search here" searchType="normal" customStyle={customStyleSearch} handleOnChangeValue={this.handleOnChangeValue.bind(this)} searchBtnClick={this.searchBtnClick.bind(this)} />
                                        <Fab variant="extended" size="large" className="btn btn-large workflow-add-btn no-shadow" onClick={this.createNewWorkflow.bind(this)}>
                                            <AddIcon />
                                            New Flow
                                        </Fab>
                                    </div>
                                </div>

                                {tabAction === 0 &&
                                    <div className="tab-container">
                                        {formTableData.length > 0 && (
                                            <div className="table-wrapper vertical-bottom">
                                                <TableComponent
                                                    tableLabel=""
                                                    rows={tableRows}
                                                    isPagginated={true}
                                                    isSearchable={false}
                                                    data={formTableData}
                                                    searchLabel=""
                                                    isCheckBoxEnabled={false}
                                                />
                                            </div>
                                        )
                                        }
                                        {dataReceived && formTableData.length <= 0 && (
                                            <section className="blank-page-sec">
                                                <div className="blank-page-wrap">
                                                    <div className="blank-page-icon">
                                                        <img src={FormIllus} alt="FormIllus" />
                                                    </div>
                                                    <div className="blank-page-info">
                                                        <h5>You haven't added any form flows yet!</h5>
                                                        <h6>Run actions when a record is created, edited or deleted</h6>
                                                    </div>
                                                    <Fab variant="extended" size="large" className="btn btn-large workflow-add-btn no-shadow primary-color" onClick={this.addSpecificWorkflow("workflowDialogOpen", 1).bind(this)}>
                                                        Add Form Workflow
                                                    </Fab>
                                                </div>
                                            </section>
                                        )}
                                    </div>}
                                {/* {tabAction === 1 && 
                                <div className="tab-container">
                                    {scheduleTableData.length>0 && (
                                        <div className="table-wrapper vertical-bottom">
                                            <TableComponent 
                                                tableLabel=""
                                                rows={tableRows}
                                                isPagginated={true}
                                                isSearchable={false}
                                                data={scheduleTableData}
                                                searchLabel=""
                                                isCheckBoxEnabled={false}
                                            />
                                        </div>
                                    )}
                                    {dataReceived && scheduleTableData.length<=0 && (
                                       <section className="blank-page-sec">
                                            <div className="blank-page-wrap">
                                                <div className="blank-page-icon">
                                                    <img src={AddScheduleIllus} alt="AddScheduleIllus"/>
                                                </div>
                                                <div className="blank-page-info">
                                                    <h5>You haven't added any scheduled flows yet!</h5>
                                                    <h6>Run actions at a specific time, on a scheduled interval, or at times saved in records.</h6>
                                                </div>
                                                <Fab variant="extended" size="large" className="btn btn-large workflow-add-btn no-shadow primary-color" onClick={this.addSpecificWorkflow("workflowDialogOpen",2).bind(this)}>
                                                    Add Schedules
                                                </Fab>
                                            </div>
                                        </section>
                                    )}
                                </div>} */}
                                {/* {tabAction === 2 && 
                                <div className="tab-container">
                                    {calculationTableData.length>0 && (
                                        <div className="table-wrapper vertical-bottom">
                                            <TableComponent 
                                                tableLabel=""
                                                rows={tableRows}
                                                isPagginated={true}
                                                isSearchable={false}
                                                data={calculationTableData}
                                                searchLabel=""
                                                isCheckBoxEnabled={false}
                                            />
                                        </div>
                                    )}
                                    {dataReceived && calculationTableData.length<=0 && (
                                         <section className="blank-page-sec">
                                            <div className="blank-page-wrap">
                                                <div className="blank-page-icon">
                                                    <img src={AddPremiumIllus} alt="AddPremiumIllus"/>
                                                </div>
                                                <div className="blank-page-info">
                                                    <h5>You haven't added any Premium flows yet!</h5>
                                                    <h6>Run actions that need to be exected for calculating premium.</h6>
                                                </div>
                                                <Fab variant="extended" size="large" className="btn btn-large workflow-add-btn no-shadow primary-color" onClick={this.addSpecificWorkflow("workflowDialogOpen",3).bind(this)}>
                                                    Add Premimum
                                                </Fab>
                                            </div>
                                        </section>
                                    )}
                                </div>} */}
                                {tabAction === 1 &&
                                    <div className="tab-container">
                                        {paymentTableData.length > 0 && (
                                            <div className="table-wrapper vertical-bottom">
                                                <TableComponent
                                                    tableLabel=""
                                                    rows={tableRows}
                                                    isPagginated={true}
                                                    isSearchable={false}
                                                    data={paymentTableData}
                                                    searchLabel=""
                                                    isCheckBoxEnabled={false}
                                                />
                                            </div>
                                        )}
                                        {dataReceived && paymentTableData.length <= 0 && (
                                            <section className="blank-page-sec">
                                                <div className="blank-page-wrap">
                                                    <div className="blank-page-icon">
                                                        <img src={AddPaymentIllus} alt="AddPaymentIllus" />
                                                    </div>
                                                    <div className="blank-page-info">
                                                        <h5>You haven't added any payment yet!</h5>
                                                        <h6>Run actions when a payment submitted succeeds or fails</h6>
                                                    </div>
                                                    <Fab variant="extended" size="large" className="btn btn-large workflow-add-btn no-shadow primary-color" onClick={this.addSpecificWorkflow("workflowDialogOpen", 4).bind(this)}>
                                                        Add Payment
                                                    </Fab>
                                                </div>
                                            </section>
                                        )}
                                    </div>}
                                {//vivek 21/01 for new ledger workflow 
                                }
                                {tabAction === 2 &&
                                    <div className="tab-container">
                                        {ledgerTableData.length > 0 && (
                                            <div className="table-wrapper vertical-bottom">
                                                <TableComponent
                                                    tableLabel=""
                                                    rows={tableRows}
                                                    isPagginated={true}
                                                    isSearchable={false}
                                                    data={ledgerTableData}
                                                    searchLabel=""
                                                    isCheckBoxEnabled={false}
                                                />
                                            </div>
                                        )}
                                        {dataReceived && ledgerTableData.length <= 0 && (
                                            <section className="blank-page-sec">
                                                <div className="blank-page-wrap">
                                                    <div className="blank-page-icon">
                                                        <img src={AddPaymentIllus} alt="AddPaymentIllus" />
                                                    </div>
                                                    <div className="blank-page-info">
                                                        <h5>You haven't added any Ledger  yet!</h5>
                                                        <h6>Run actions when a Ledger submitted succeeds or fails</h6>
                                                    </div>
                                                    <Fab variant="extended" size="large" className="btn btn-large workflow-add-btn no-shadow primary-color" onClick={this.addSpecificWorkflow("workflowDialogOpen", 5).bind(this)}>
                                                        Add Ledger
                                                    </Fab>
                                                </div>
                                            </section>
                                        )}
                                    </div>}
                                { //end 
                                }
                            </div>

                            {/* Dialog for trigger type */}
                            <Dialog open={triggerDialogOpen} onClose={this.handleClose("triggerDialogOpen")} TransitionComponent={Transition} maxWidth="xl">
                                <section className="workflow-dialog workflow-dialog-large">
                                    <div className="card-block">
                                        <div className="card-header border-bottom">
                                            <div className="sub-title">
                                                <h5>Workflow Builder</h5>
                                            </div>
                                            <div className="close-dialog">
                                                <img src={closeIcon} alt="" onClick={this.handleClose("triggerDialogOpen")}></img>
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <Grid container>
                                                <Grid item md={4}>
                                                    <div className="sub-title">
                                                        <h2>When do you want to trigger your workflow?</h2>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                            <div className="workflow-dialog-options">
                                                <div className="worflow-dialog-wrap">
                                                    <div className="workflow-dialog-img">
                                                        <Button variant="contained" color="primary" onClick={this.selectTriggerHandle(1).bind(this)}>
                                                            <img src={FormIllus} alt="FormIllus"></img>
                                                        </Button>
                                                    </div>
                                                    <h5>While using the form</h5>
                                                </div>
                                                {/* <div className="worflow-dialog-wrap">
                                                <div className="workflow-dialog-img">
                                                    <Button variant="contained" color="primary" onClick={this.selectTriggerHandle(2).bind(this)}>
                                                        <img src={DateIllus} alt="DateIllus"></img>
                                                    </Button>
                                                </div>
                                                <h5>On a scheduled date</h5>
                                            </div> */}
                                                {/* <div className="worflow-dialog-wrap">
                                                <div className="workflow-dialog-img">
                                                    <Button variant="contained" color="primary" onClick={this.selectTriggerHandle(3).bind(this)}>
                                                        <img src={CalculatePremiumIllus} alt="CalculatePremiumIllus"></img>
                                                    </Button>
                                                </div>
                                                <h5>For Calculating Premium</h5>
                                            </div> */}
                                                <div className="worflow-dialog-wrap">
                                                    <div className="workflow-dialog-img">
                                                        <Button variant="contained" color="primary" onClick={this.selectTriggerHandle(4).bind(this)}>
                                                            <img src={PaymentIllus} alt="PaymentIllus"></img>
                                                        </Button>
                                                    </div>
                                                    <h5>During payment process</h5>
                                                </div>
                                                {/* { added new ledger in dialog } */}
                                                <div className="worflow-dialog-wrap">
                                                    <div className="workflow-dialog-img">
                                                        <Button variant="contained" color="primary" onClick={this.selectTriggerHandle(5).bind(this)}>
                                                            <img src={PaymentIllus} alt="PaymentIllus"></img>
                                                        </Button>
                                                    </div>
                                                    <h5>while using Ledger workflow</h5>
                                                </div>
                                                {/* { end } */}
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </Dialog>
                            {/* Dialog for workflow create form */}
                            <Dialog open={workflowDialogOpen} onClose={this.handleClose("workflowDialogOpen")} TransitionComponent={Transition} maxWidth="xl">
                                <section className="workflow-dialog workflow-dialog-large">
                                    <div className="card-block">
                                        <div className="card-header border-bottom">
                                            <div className="sub-title">
                                                <div className="close-dialog">
                                                    <Button color="inherit" onClick={this.handleClose("workflowDialogOpen")}>
                                                        <img src={ArrowBackGrey} alt=""></img> Go Back
                                                    </Button>
                                                </div>
                                            </div>
                                            <div className="close-dialog">
                                                <img src={closeIcon} alt="" onClick={this.handleClose("workflowDialogOpen")}></img>
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <Grid container>
                                                <Grid item md={4}>
                                                    <div className="workflow-illust-wrap">
                                                        <div className="sub-title">
                                                            <h2>Run workflow on any activity in the form</h2>
                                                        </div>
                                                        <div className="workflow-illust">
                                                            <img src={WorkFlowIllust} alt="WorkFlowIllust" />
                                                        </div>
                                                    </div>
                                                </Grid>
                                                <Grid item md={8}>
                                                    <div className="workflow-dialog-forms">
                                                        <div className="tabs-container tabs-container-scroll">
                                                            <Scrollbars style={{ width: '100%', height: '100%' }}>
                                                                <div className="tabs-container-scroll-wrap">
                                                                    <Grid item md={5}>
                                                                        <SelectField
                                                                            label="Choose form"
                                                                            optionsData={formList}
                                                                            radioChangeHandle={this.handleFormChange("workflow_trigger.form_id")}
                                                                            selected_value={workflowFormData.workflow_trigger.form_id}
                                                                            fieldStyle="field-box bg-plane grey-color" />
                                                                    </Grid>
                                                                    {tabAction !== 3 &&
                                                                        <RadioField
                                                                            radioGroupLabel="Run when a record is"
                                                                            radioData={formTrigger}
                                                                            label="Validation"
                                                                            radioChangeHandle={this.handleFormChange("workflow_trigger.trigger_type")}
                                                                            radioFieldStyle="field-inline"
                                                                            selected_value={workflowFormData.workflow_trigger.trigger_type}
                                                                        />
                                                                    }
                                                                    <Grid item md={5}>
                                                                        <InputField label="Name of workflow" type="text" name="field_label" fieldStyle="field-box bg-plane grey-color"
                                                                            handleOnChangeValue={this.handleFormChange("workflow_name")} selected_value={workflowFormData.workflow_name} />
                                                                    </Grid>
                                                                </div>
                                                            </Scrollbars>
                                                        </div>
                                                        <div className="form-row workflow-dialog-footer">
                                                            <div className="btn-wrap no-border">
                                                                <Fab variant="extended" size="large" className="btn btn-large btn-plane primary-color" onClick={this.saveWorkflowData.bind(this)}>
                                                                    Create Workflow
                                                                </Fab>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </div>
                                    </div>
                                </section>
                            </Dialog>
                        </div>
                    }
                </main>
            </div>
        )
    }
}

export default WorkflowDashboard;