import React, { Component } from 'react';
import Fab from '@material-ui/core/Fab';
import Drawer from '@material-ui/core/Drawer';
import closeIcon from '../../assets/images/icon/close.svg';
import RadioField from '../../components/fields/radio';
import InputField from '../../components/fields/inputs';
import TextAreaField from '../../components/fields/textarea';
import CheckBoxField from '../../components/fields/checkbox';
import NameDescChoice from '../../components/properties/name-desc-choice';
import { Scrollbars } from 'react-custom-scrollbars';
import SelectField from '../../components/fields/select';

class SectionSetup extends Component {

    state = {
        assign: false,
        subStateCheck: false,
        nameData: [],
        newStateData: {
            sub_states_list: [],
        },
        newState: {},

        editState: {},
        filteredStateObj: {
            sub_states_list: [],
        },
    }

    componentWillMount() {

        let { nameData, filteredStateObj, userOptionListCopy } = this.state;
        let { stateData, parentIndex, newStateOrEdit, permissionList } = this.props;

        let obj = {
            position: 0,
            sub_state: true,
            sub_name: "",
            sub_description: "",
        };
        nameData.push(obj);

        if (newStateOrEdit === "edit") {
            filteredStateObj = stateData[parentIndex];
        }
        if (newStateOrEdit === "edit" && filteredStateObj.state_type) {
            this.setState({ assign: true })
        }
        if (newStateOrEdit === "edit" && filteredStateObj.sub_states_list.length > 0) {
            this.setState({ subStateCheck: true })
        }

        this.setState({ nameData: nameData, filteredStateObj: filteredStateObj });
    }

    handleCheckChange = (key) => (value) => {
        if (key === "assign") {
            this.setState({ assign: !this.state.assign });
        }
        if (key === "subState") {
            this.setState({ subStateCheck: !this.state.subStateCheck });
        }
    }

    handleInputChange = (key) => (value) => {
        let { newStateOrEdit } = this.props;
        let { filteredStateObj } = this.state;
        if (newStateOrEdit === "new") {
            let newStateData = this.state.newStateData;
            console.log("newStateData", newStateData, "key", key, "value", value);
            newStateData[key] = value;
            newStateData["sub_state"] = false;
            this.setState({ newStateData: newStateData });
        }
        if (newStateOrEdit === "edit") {
            console.log("key1234", key, "value", value);
            filteredStateObj[key] = value;
            this.setState({ filteredStateObj: filteredStateObj });
        }
    }

    handleSubStateChange = (key) => (value) => {
        let { stateData, indexOfState, newStateOrEdit, childIndex } = this.props;
        let { filteredStateObj, newState } = this.state;

        if (newStateOrEdit === "new") {
            newState[key] = value;
            newState["sub_state"] = true;
            newState["position"] = stateData[indexOfState].sub_states_list.length;
            this.setState({ newState: newState });
        }

        if (newStateOrEdit === "edit") {
            filteredStateObj.sub_states_list[childIndex][key] = value;
            this.setState({ filteredStateObj: filteredStateObj });
        }

    }

    handleSaveState() {
        let { newStateOrEdit } = this.props;
        let { filteredStateObj, assign, subStateCheck, newStateData } = this.state;


        if (newStateOrEdit === "new") {
            if (!assign) {
                newStateData.state_type = null;
            }
            if (!subStateCheck) {
                let substatesID = [];
                newStateData.sub_states_list.length > 0 &&
                    newStateData.sub_states_list.forEach(list => {
                        substatesID.push(list.id);
                    })
                newStateData.sub_states_list = [];
                this.props.handleDeleteOfAllSubStatesById(substatesID);
            }
            console.log("State_Data_1", newStateData);
            this.props.handleSaveAction(newStateData);
        }
        if (newStateOrEdit === "edit") {
            if (!assign) {
                filteredStateObj.state_type = null;
            }
            if (!subStateCheck) {
                let substatesID = [];
                filteredStateObj.sub_states_list.length > 0 &&
                    filteredStateObj.sub_states_list.forEach(list => {
                        substatesID.push(list.id);
                    })
                filteredStateObj.sub_states_list = [];
                this.props.handleDeleteOfAllSubStatesById(substatesID);
            }
            console.log("State_Data_2", newStateData);
            this.props.handleSaveAction(filteredStateObj);
        }
    }

    optionOnChange = () => (checkData) => {
        let { newStateData, filteredStateObj } = this.state;
        let { newStateOrEdit } = this.props;
        if (newStateOrEdit === "new") {
            newStateData["sub_states_list"] = checkData;
            this.setState({ nameData: checkData, newStateData: newStateData });
        }
        if (newStateOrEdit === "edit") {
            filteredStateObj["sub_states_list"] = checkData;
            this.setState({ nameData: checkData, filteredStateObj: filteredStateObj });
        }
    }

    handleSaveSubState() {
        let { newState, filteredStateObj } = this.state;
        let { stateData, indexOfState, newStateOrEdit } = this.props;
        if (newStateOrEdit === "new") {
            let filterStateData = stateData[indexOfState];
            filterStateData.sub_states_list.push(newState);
            console.log("State_Data_3", filterStateData);
            this.props.handleSaveAction(filterStateData);
        }
        if (newStateOrEdit === "edit") {
            console.log("State_Data_4", filteredStateObj);
            this.props.handleSaveAction(filteredStateObj);
        }
    }

    modifyUserOptionList(permissionList) {
        let { filteredStateObj } = this.state;
        permissionList.forEach((list, index) => {
            list["checked"] = filteredStateObj.permission_ids ? filteredStateObj.permission_ids.includes(parseInt(list.field_option_value)) : false;
        })
        return permissionList;
    }

    newCreationOfUserOptionList(permissionList) {
        let newStateData = this.state.newStateData;
        if (newStateData.hasOwnProperty("permission_ids")) {
            permissionList.forEach((list, index) => {
                list["checked"] = newStateData.permission_ids.includes(parseInt(list.field_option_value));
            })
        }
        return permissionList;
    }

    userOptionListSubState() {
        let { permissionList, childIndex } = this.props;
        let { filteredStateObj } = this.state;

        permissionList.forEach((list) => {
            list["checked"] = filteredStateObj.sub_states_list[childIndex].permission_ids.includes(parseInt(list.field_option_value));
        })

        return permissionList;
    }

    render() {
        let { displayStateComponents, stateData, parentIndex, childIndex, editStatus, newStateOrEdit, permissionList } = this.props;
        let { newStateData, newState, filteredStateObj, assign, subStateCheck } = this.state;
        let filteredSubStateObj = {};
        console.log("newStateData222", newStateData);
        // console.log("permissionList", permissionList);
        // console.log("stateData", stateData);
        // console.log("newStateData ",newStateData, "filteredStateObj", filteredStateObj, "newState", newState);
        console.log("filteredStateObj", filteredStateObj);
        if (childIndex > -1) {
            filteredSubStateObj = stateData[parentIndex].sub_states_list[childIndex];
        }

        let stateType = [
            {
                field_option_label: "Start",
                field_option_value: "start_state",
            },
            {
                field_option_label: "End",
                field_option_value: "end_state",
            }
        ]

        let checkStateType = [
            {
                field_option_label: "Assign State Type",
                field_option_value: "assign-state-type",
                checked: assign
            }
        ]

        let subState = [
            {
                field_option_label: "Add Substate",
                field_option_value: "subState",
                checked: subStateCheck,
            }
        ]

        console.log("newStateOrEdit", newStateOrEdit);
        return (
            <Drawer anchor="right" open={this.props.addState} onClose={(event) => { this.props.toggleDrawer('addState', false) }}>
                <section className="workflow-setup-sec">
                    <section className="setup-drawer-sec">
                        <div className="card-block">
                            <div className="card-header">
                                <div className="card-subheader">
                                    <div className="card-title">
                                    </div>
                                    <div className="card-left-title">
                                        <div className="actions setup-close">
                                            <div className="btn-wrap">
                                                <Fab variant="extended" size="small" className="btn btn-small btn-plane btn-fab white-color no-shadow" onClick={(event) => { this.props.toggleDrawer('addState', false) }}>
                                                    Close <img alt="edit" src={closeIcon}></img>
                                                </Fab>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card-header border-bottom" style={{ padding: '8px 0px 25px' }}>
                                {
                                    newStateOrEdit === "new" ?
                                        displayStateComponents ?
                                            <div className="card-title">
                                                <h2>Add State</h2>
                                            </div>
                                            :
                                            <div className="card-title">
                                                <h2>Add Sub State</h2>
                                            </div>
                                        : newStateOrEdit === "edit" ?
                                            displayStateComponents ?
                                                <div className="card-title">
                                                    <h2>Edit State</h2>
                                                </div>
                                                :
                                                <div className="card-title">
                                                    <h2>Edit Sub State</h2>
                                                </div>
                                            : ""
                                }
                            </div>
                            <div className="card-body">
                                <div role="presentation" className="drawer-sec">
                                    <div className="drawer-form-sec">
                                        <div className="tab-container tab-container-scroll" style={{ height: 'calc(100vh - 295px)' }}>
                                            <Scrollbars style={{ width: '100%', height: '100%' }}>
                                                <div className="state-field-add">
                                                    <InputField
                                                        label="Name of the State"
                                                        type="text" name="state_name"
                                                        placeholder="Enter"
                                                        customStyle="medium-row"
                                                        default_value={newStateOrEdit !== "new" ? (editStatus ? (filteredStateObj ? filteredStateObj.state_name : "") : (filteredSubStateObj ? filteredSubStateObj.state_name : "")) : ""}
                                                        selected_value={displayStateComponents ? newStateData.state_name : newState.state_name}
                                                        handleOnChangeValue={displayStateComponents ? this.handleInputChange("state_name").bind(this) : this.handleSubStateChange("state_name").bind(this)}
                                                    />

                                                    <TextAreaField
                                                        label="Description"
                                                        type="textarea"
                                                        name="state_description"
                                                        placeholder="Description"
                                                        rowmax="5"
                                                        default_value={newStateOrEdit !== "new" ? (editStatus ? (filteredStateObj ? filteredStateObj.state_description : "") : (filteredSubStateObj ? filteredSubStateObj.state_description : "")) : ""}
                                                        customStyle="medium-row"
                                                        selected_value={displayStateComponents ? newStateData.state_description : newState.state_description}
                                                        handleOnChangeValue={displayStateComponents ? this.handleInputChange("state_description").bind(this) : this.handleSubStateChange("state_description").bind(this)}
                                                    />
                                                    <SelectField
                                                        label="Select Permissions"
                                                        optionsData={displayStateComponents ? (newStateOrEdit === "edit" ? this.modifyUserOptionList(JSON.parse(JSON.stringify(permissionList))) : this.newCreationOfUserOptionList(JSON.parse(JSON.stringify(permissionList)))) : this.userOptionListSubState()}
                                                        isMultiple={true}
                                                        customStyle="medium-row"
                                                        radioChangeHandle={displayStateComponents ? this.handleInputChange("permission_ids").bind(this) : this.handleSubStateChange("permission_ids").bind(this)}
                                                        selected_value={displayStateComponents ? newStateData.permission_ids : newState.permission_ids}
                                                        fieldStyle="field-box" />

                                                    {displayStateComponents &&
                                                        <div className="state-field-add">
                                                            <CheckBoxField
                                                                checkData={checkStateType}
                                                                checkChangeHandle={this.handleCheckChange("assign").bind(this)}
                                                                customStyle="state-field-check"
                                                            />
                                                            {assign ?
                                                                <RadioField
                                                                    radioData={stateType}
                                                                    radioFieldStyle="field-inline"
                                                                    selected_value={newStateOrEdit === "new" ? newStateData.state_type : filteredStateObj.state_type}
                                                                    radioChangeHandle={this.handleInputChange("state_type").bind(this)}
                                                                    default_value={newStateOrEdit === "edit" ? filteredStateObj.state_type : ""}
                                                                    customStyle="state-field-radio"
                                                                />
                                                                : ""
                                                            }

                                                            <CheckBoxField
                                                                checkData={subState}
                                                                checkChangeHandle={this.handleCheckChange("subState").bind(this)}
                                                                customStyle="state-field-check"
                                                            />

                                                            {this.state.subStateCheck ?
                                                                <NameDescChoice
                                                                    isDynamic={true}
                                                                    checkData={newStateOrEdit === "new" ? this.state.nameData : (filteredStateObj.sub_states_list.length > 0 ? filteredStateObj.sub_states_list : this.state.nameData)}
                                                                    newStateData={this.state.newStateData}
                                                                    filteredStateObj={filteredStateObj}
                                                                    newStateOrEdit={newStateOrEdit}
                                                                    optionOnChangeEvent={this.optionOnChange().bind(this)}
                                                                    permissionList={JSON.parse(JSON.stringify(permissionList))}
                                                                    handleDeleteOfSubState={this.props.handleDeleteOfSubState.bind(this)}
                                                                /> : ""
                                                            }
                                                        </div>
                                                    }
                                                </div>
                                            </Scrollbars>
                                        </div>
                                        <div className="card-footer border-top">
                                            <div className="btn-wrap">
                                                <Fab variant="extended" size="large" className="btn btn-large btn-plane primary-color" onClick={(event) => { displayStateComponents ? this.handleSaveState() : this.handleSaveSubState(); this.props.toggleDrawer('addState', false) }}>Save</Fab>
                                                <Fab variant="extended" size="large" className="btn btn-large btn-plane grey-color" onClick={(event) => { this.props.toggleDrawer('addState', false) }}>Cancel</Fab>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </section>
            </Drawer>

        );
    }
}
export default SectionSetup;