import React, { Component } from 'react';
import TaskIcon from '../../assets/images/icon/task-blue.svg';

import CloseIcon from '../../assets/images/icon/close.svg';
import updateGreyIcon from '../../assets/images/icon/update-grey.svg';
import addGreyIcon from '../../assets/images/icon/add-grey.svg';
import changeGreyIcon from '../../assets/images/icon/change-priority-grey.svg';

class DetailsActivity extends Component {

    selectIconForAuditData(activityType) {

        switch (activityType) {
            case "UPDATE_RECORD":
                return updateGreyIcon;
            case "ADD_RECORD":
                return addGreyIcon;
            default:
                return TaskIcon;
        }
    }

    render() {
        let { auditTrail } = this.props;
        return (
            <section className="details-activity-sec">
                <div className="details-activity-wrap">
                    {
                        auditTrail.map(auditData => {
                            return (
                                <div className="details-activity-row">
                                    <div className="activitiy-date">
                                        <h6 className="font-med">{auditData.created_on}</h6>
                                    </div>
                                    <div className="activitiy-icon">
                                        <img src={this.selectIconForAuditData(auditData.activity_type)} alt="TaskIcon" />
                                    </div>
                                    <div className="activitiy-info">
                                        <h6>{auditData.activity_text}</h6>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </section>
        )
    }
}

export default DetailsActivity;