import { getClientSharingGateCode } from "../api/client-api";
import { ApiConstants } from "../api/common/api-constants";
import { SOURCE } from "../utilities/constants";
import { CODE_TYPE } from './../utilities/constants';
import { getTenantAssureGateUrl } from '../utilities/multitenant';

export const generateAssuregatePayloadByFormData = (prefillData, programKey) => {
    const dataWithDateType = ["registrationDate", "policyExpiryDate", "dob", "policyTPExpiryDate"]
    let data = {};

    for (const [key, value] of Object.entries(prefillData)) {
        let payloadValue = value;
        if (dataWithDateType.includes(key)) {
            payloadValue = getDateInDDMMYYYY(value);
        }
        data[key] = payloadValue;
    }
    data.mobileNo = prefillData?.mobileNo?.substring(2);
    let payload = {
        code_type: CODE_TYPE.TIME_BOUND,
        payload: { ...data, source: SOURCE.CORE, },
        program_key: programKey
    }
    console.log("payloadValue ", payload)
    return payload;
}

export const getAssureGateOpenUrl = async (prefillPayloadData, programKey) => {
    const payload = generateAssuregatePayloadByFormData(prefillPayloadData, programKey);
    try {
        const res = await getClientSharingGateCode(payload);
        return `${getTenantAssureGateUrl()}?code=${res.code}`;
    } catch (err) {
        console.error("Error while opening gate");
        return null;
    }
}

export const openAssurgateInNewTab = async (prefillPayloadData, programKey, callback) => {
    const url = await getAssureGateOpenUrl(prefillPayloadData, programKey);
    if (url) window.open(url, '_blank')
    callback(url);
}

const getDateInDDMMYYYY = (value) => {
    let date = new Date(value);
    let mm = date.getMonth() + 1;
    let finalMM = mm < 10 ? `0${mm}` : mm
    let dd = date.toLocaleString("en-US", { day: '2-digit' });
    let yy = date.getFullYear();
    return `${dd}-${finalMM}-${yy}`;
}