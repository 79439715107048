import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
// import Button from '@material-ui/core/Button';
import Fab from '@material-ui/core/Fab';
// import IconButton from '@material-ui/core/IconButton';
// import AddIcon from '@material-ui/icons/Add';
// import DeleteIcon from '@material-ui/icons/Delete';
import NavigationIcon from '@material-ui/icons/Navigation';
import Divider from '@material-ui/core/Divider';
import DateKendo from '../kendo/date-picker';
import DateRangePickerKendo from '../kendo/date-range-picker';
import DateTimePickerKendo from '../kendo/date-time-picker';
import TimePickerKendo from '../kendo/time-picker';
import RichTextKendo from '../kendo/rich-text';
// import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';

class Components extends Component {
    render() {
        return (
            <div class="form-row">
                <div className="text-wrap">
                    <p>For Bold just add class 'bold'</p>
                    <h1>h1 Sample Text</h1>
                    <h2>h2 Sample Text</h2>
                    <h3>h3 Sample Text</h3>
                    <h4>h4 Sample Text</h4>
                    <h5>h5 Sample Text</h5>
                    <h6>h6 Sample Text</h6>
                    <h6 class="font-med">h6.font-med Sample Text</h6>
                    <h6 class="font-small">h6.font-small Sample Text</h6>
                </div>

                <div className="btn-wrap">
                    <h1>Large Button</h1>
                    <Grid container spacing={4}>
                        <Grid item xs={2}>
                            <Fab variant="extended" size="large" className="btn btn-large btn-plane primary-color">
                                {/* <NavigationIcon/> */}
                                Primary
                            </Fab>
                        </Grid>
                        <Grid item xs={2}>
                            <Fab variant="extended" size="large" className="btn btn-large btn-plane primary-color">
                                <NavigationIcon />
                                Primary
                            </Fab>
                        </Grid>
                        <Grid item xs={2}>
                            <Fab variant="extended" size="large" className="btn btn-large btn-plane btn-curve primary-color">
                                Primary
                            </Fab>
                        </Grid>
                        <Grid item xs={2}>
                            <Fab variant="extended" size="large" className="btn btn-large btn-plane btn-curve primary-color">
                                <NavigationIcon />
                                Primary
                            </Fab>
                        </Grid>
                        <Grid item xs={2}>
                            <Fab variant="extended" size="large" className="btn btn-large btn-plane btn-fab primary-color">
                                <NavigationIcon />
                            </Fab>
                        </Grid>
                    </Grid>
                    <Divider light />
                    <Grid container spacing={4}>
                        <Grid item xs={2}>
                            <Fab variant="extended" size="large" className="btn btn-large btn-outline primary-color">
                                {/* <NavigationIcon/> */}
                                Primary
                            </Fab>
                        </Grid>
                        <Grid item xs={2}>
                            <Fab variant="extended" size="large" className="btn btn-large btn-outline primary-color">
                                <NavigationIcon />
                                Primary
                            </Fab>
                        </Grid>
                        <Grid item xs={2}>
                            <Fab variant="extended" size="large" className="btn btn-large btn-outline btn-curve primary-color">
                                Primary
                            </Fab>
                        </Grid>
                        <Grid item xs={2}>
                            <Fab variant="extended" size="large" className="btn btn-large btn-outline btn-curve primary-color">
                                <NavigationIcon />
                                Primary
                            </Fab>
                        </Grid>
                        <Grid item xs={2}>
                            <Fab variant="extended" size="large" className="btn btn-large btn-outline btn-fab primary-color">
                                <NavigationIcon />
                            </Fab>
                        </Grid>
                    </Grid>
                    <Divider light />
                    <Grid container spacing={4}>
                        <Grid item xs={2}>
                            <Fab variant="extended" size="large" className="btn btn-large btn-plane green-color">
                                {/* <NavigationIcon/> */}
                                Primary
                            </Fab>
                        </Grid>
                        <Grid item xs={2}>
                            <Fab variant="extended" size="large" className="btn btn-large btn-plane green-color">
                                <NavigationIcon />
                                Primary
                            </Fab>
                        </Grid>
                        <Grid item xs={2}>
                            <Fab variant="extended" size="large" className="btn btn-large btn-curve btn-plane green-color">
                                Primary
                            </Fab>
                        </Grid>
                        <Grid item xs={2}>
                            <Fab variant="extended" size="large" className="btn btn-large btn-curve btn-plane green-color">
                                <NavigationIcon />
                                Primary
                            </Fab>
                        </Grid>
                        <Grid item xs={2}>
                            <Fab variant="extended" size="large" className="btn btn-large btn-fab btn-plane green-color">
                                <NavigationIcon />
                            </Fab>
                        </Grid>
                    </Grid>
                    <Divider light />
                    <Grid container spacing={4}>
                        <Grid item xs={2}>
                            <Fab variant="extended" size="large" className="btn btn-large btn-outline green-color">
                                {/* <NavigationIcon/> */}
                                Primary
                            </Fab>
                        </Grid>
                        <Grid item xs={2}>
                            <Fab variant="extended" size="large" className="btn btn-large btn-outline green-color">
                                <NavigationIcon />
                                Primary
                            </Fab>
                        </Grid>
                        <Grid item xs={2}>
                            <Fab variant="extended" size="large" className="btn btn-large btn-outline btn-curve green-color">
                                Primary
                            </Fab>
                        </Grid>
                        <Grid item xs={2}>
                            <Fab variant="extended" size="large" className="btn btn-large btn-outline btn-curve green-color">
                                <NavigationIcon />
                                Primary
                            </Fab>
                        </Grid>
                        <Grid item xs={2}>
                            <Fab variant="extended" size="large" className="btn btn-large btn-outline btn-fab green-color">
                                <NavigationIcon />
                            </Fab>
                        </Grid>
                    </Grid>
                    <Divider light />
                    <Grid container spacing={4}>
                        <Grid item xs={2}>
                            <Fab variant="extended" size="large" className="btn btn-large btn-plane secondary-color">
                                {/* <NavigationIcon/> */}
                                Primary
                            </Fab>
                        </Grid>
                        <Grid item xs={2}>
                            <Fab variant="extended" size="large" className="btn btn-large btn-plane secondary-color">
                                <NavigationIcon />
                                Primary
                            </Fab>
                        </Grid>
                        <Grid item xs={2}>
                            <Fab variant="extended" size="large" className="btn btn-large btn-curve btn-plane secondary-color">
                                Primary
                            </Fab>
                        </Grid>
                        <Grid item xs={2}>
                            <Fab variant="extended" size="large" className="btn btn-large btn-curve btn-plane secondary-color">
                                <NavigationIcon />
                                Primary
                            </Fab>
                        </Grid>
                        <Grid item xs={2}>
                            <Fab variant="extended" size="large" className="btn btn-large btn-fab btn-plane secondary-color">
                                <NavigationIcon />
                            </Fab>
                        </Grid>
                    </Grid>
                    <Divider light />
                    <Grid container spacing={4}>
                        <Grid item xs={2}>
                            <Fab variant="extended" size="large" className="btn btn-large btn-outline secondary-color">
                                {/* <NavigationIcon/> */}
                                Primary
                            </Fab>
                        </Grid>
                        <Grid item xs={2}>
                            <Fab variant="extended" size="large" className="btn btn-large btn-outline secondary-color">
                                <NavigationIcon />
                                Primary
                            </Fab>
                        </Grid>
                        <Grid item xs={2}>
                            <Fab variant="extended" size="large" className="btn btn-large btn-outline btn-curve secondary-color">
                                Primary
                            </Fab>
                        </Grid>
                        <Grid item xs={2}>
                            <Fab variant="extended" size="large" className="btn btn-large btn-outline btn-curve secondary-color">
                                <NavigationIcon />
                                Primary
                            </Fab>
                        </Grid>
                        <Grid item xs={2}>
                            <Fab variant="extended" size="large" className="btn btn-large btn-outline btn-fab secondary-color">
                                <NavigationIcon />
                            </Fab>
                        </Grid>
                    </Grid>
                    <Divider light />
                    <Grid container spacing={4}>
                        <Grid item xs={2}>
                            <Fab variant="extended" size="large" className="btn btn-large btn-plane grey-color">
                                {/* <NavigationIcon/> */}
                                Primary
                            </Fab>
                        </Grid>
                        <Grid item xs={2}>
                            <Fab variant="extended" size="large" className="btn btn-large btn-plane grey-color">
                                <NavigationIcon />
                                Primary
                            </Fab>
                        </Grid>
                        <Grid item xs={2}>
                            <Fab variant="extended" size="large" className="btn btn-large btn-curve btn-plane grey-color">
                                Primary
                            </Fab>
                        </Grid>
                        <Grid item xs={2}>
                            <Fab variant="extended" size="large" className="btn btn-large btn-curve btn-plane grey-color">
                                <NavigationIcon />
                                Primary
                            </Fab>
                        </Grid>
                        <Grid item xs={2}>
                            <Fab variant="extended" size="large" className="btn btn-large btn-fab btn-plane grey-color">
                                <NavigationIcon />
                            </Fab>
                        </Grid>
                    </Grid>
                    <Divider light />
                    <Grid container spacing={4}>
                        <Grid item xs={2}>
                            <Fab variant="extended" size="large" className="btn btn-large btn-outline btn-plane grey-color">
                                {/* <NavigationIcon/> */}
                                Primary
                            </Fab>
                        </Grid>
                        <Grid item xs={2}>
                            <Fab variant="extended" size="large" className="btn btn-large btn-outline grey-color">
                                <NavigationIcon />
                                Primary
                            </Fab>
                        </Grid>
                        <Grid item xs={2}>
                            <Fab variant="extended" size="large" className="btn btn-large btn-outline btn-curve grey-color">
                                Primary
                            </Fab>
                        </Grid>
                        <Grid item xs={2}>
                            <Fab variant="extended" size="large" className="btn btn-large btn-outline btn-curve grey-color">
                                <NavigationIcon />
                                Primary
                            </Fab>
                        </Grid>
                        <Grid item xs={2}>
                            <Fab variant="extended" size="large" className="btn btn-large btn-outline btn-fab grey-color">
                                <NavigationIcon />
                            </Fab>
                        </Grid>
                    </Grid>
                    <Divider light />
                    <Grid container spacing={4}>
                        <Grid item xs={2}>
                            <Fab variant="extended" size="large" className="btn btn-large btn-plane white-color">
                                {/* <NavigationIcon/> */}
                                Primary
                            </Fab>
                        </Grid>
                        <Grid item xs={2}>
                            <Fab variant="extended" size="large" className="btn btn-large btn-plane white-color">
                                <NavigationIcon />
                                Primary
                            </Fab>
                        </Grid>
                        <Grid item xs={2}>
                            <Fab variant="extended" size="large" className="btn btn-large btn-curve btn-plane white-color">
                                Primary
                            </Fab>
                        </Grid>
                        <Grid item xs={2}>
                            <Fab variant="extended" size="large" className="btn btn-large btn-curve btn-plane white-color">
                                <NavigationIcon />
                                Primary
                            </Fab>
                        </Grid>
                        <Grid item xs={2}>
                            <Fab variant="extended" size="large" className="btn btn-large btn-fab btn-plane white-color">
                                <NavigationIcon />
                            </Fab>
                        </Grid>
                    </Grid>
                    <Divider light />
                    <Grid container spacing={4}>
                        <Grid item xs={2}>
                            <Fab variant="extended" size="large" className="btn btn-large btn-outline white-color">
                                {/* <NavigationIcon/> */}
                                Primary
                            </Fab>
                        </Grid>
                        <Grid item xs={2}>
                            <Fab variant="extended" size="large" className="btn btn-large btn-outline white-color">
                                <NavigationIcon />
                                Primary
                            </Fab>
                        </Grid>
                        <Grid item xs={2}>
                            <Fab variant="extended" size="large" className="btn btn-large btn-outline btn-curve white-color">
                                Primary
                            </Fab>
                        </Grid>
                        <Grid item xs={2}>
                            <Fab variant="extended" size="large" className="btn btn-large btn-outline btn-curve white-color">
                                <NavigationIcon />
                                Primary
                            </Fab>
                        </Grid>
                        <Grid item xs={2}>
                            <Fab variant="extended" size="large" className="btn btn-large btn-outline btn-fab white-color">
                                <NavigationIcon />
                            </Fab>
                        </Grid>
                    </Grid>
                </div>
                <div className="btn-wrap">
                    <h1>Small Button</h1>
                    <Grid container spacing={4}>
                        <Grid item xs={2}>
                            <Fab variant="extended" size="small" disabled className="btn btn-small btn-plane">
                                {/* <NavigationIcon/> */}
                                Primary
                            </Fab>
                        </Grid>
                        <Grid item xs={2}>
                            <Fab variant="extended" size="small" disabled className="btn btn-small btn-plane">
                                <NavigationIcon />
                                Primary
                            </Fab>
                        </Grid>
                        <Grid item xs={2}>
                            <Fab variant="extended" size="small" disabled className="btn btn-small btn-plane btn-curve">
                                Primary
                            </Fab>
                        </Grid>
                        <Grid item xs={2}>
                            <Fab variant="extended" size="small" disabled className="btn btn-small btn-plane btn-curve">
                                <NavigationIcon />
                                Primary
                            </Fab>
                        </Grid>
                        <Grid item xs={2}>
                            <Fab variant="extended" size="small" disabled className="btn btn-small btn-plane btn-fab">
                                <NavigationIcon />
                            </Fab>
                        </Grid>
                    </Grid>
                    <Divider light />
                    <Grid container spacing={4}>
                        <Grid item xs={2}>
                            <Fab variant="extended" size="small" className="btn btn-small btn-plane">
                                {/* <NavigationIcon/> */}
                                Primary
                            </Fab>
                        </Grid>
                        <Grid item xs={2}>
                            <Fab variant="extended" size="small" className="btn btn-small btn-plane">
                                <NavigationIcon />
                                Primary
                            </Fab>
                        </Grid>
                        <Grid item xs={2}>
                            <Fab variant="extended" size="small" className="btn btn-small btn-plane btn-curve">
                                Primary
                            </Fab>
                        </Grid>
                        <Grid item xs={2}>
                            <Fab variant="extended" size="small" className="btn btn-small btn-plane btn-curve">
                                <NavigationIcon />
                                Primary
                            </Fab>
                        </Grid>
                        <Grid item xs={2}>
                            <Fab variant="extended" size="small" className="btn btn-small btn-plane btn-fab">
                                <NavigationIcon />
                            </Fab>
                        </Grid>
                    </Grid>
                    <Divider light />
                    <Grid container spacing={4}>
                        <Grid item xs={2}>
                            <Fab variant="extended" size="small" className="btn btn-small btn-plane green-color">
                                {/* <NavigationIcon/> */}
                                Primary
                            </Fab>
                        </Grid>
                        <Grid item xs={2}>
                            <Fab variant="extended" size="small" className="btn btn-small btn-plane green-color">
                                <NavigationIcon />
                                Primary
                            </Fab>
                        </Grid>
                        <Grid item xs={2}>
                            <Fab variant="extended" size="small" className="btn btn-small btn-plane btn-curve green-color">
                                Primary
                            </Fab>
                        </Grid>
                        <Grid item xs={2}>
                            <Fab variant="extended" size="small" className="btn btn-small btn-plane btn-curve green-color">
                                <NavigationIcon />
                                Primary
                            </Fab>
                        </Grid>
                        <Grid item xs={2}>
                            <Fab variant="extended" size="small" className="btn btn-small btn-plane btn-fab green-color">
                                <NavigationIcon />
                            </Fab>
                        </Grid>
                    </Grid>
                    <Divider light />
                    <Grid container spacing={4}>
                        <Grid item xs={2}>
                            <Fab variant="extended" size="small" className="btn btn-small btn-plane secondary-color">
                                {/* <NavigationIcon/> */}
                                Primary
                            </Fab>
                        </Grid>
                        <Grid item xs={2}>
                            <Fab variant="extended" size="small" className="btn btn-small btn-plane secondary-color">
                                <NavigationIcon />
                                Primary
                            </Fab>
                        </Grid>
                        <Grid item xs={2}>
                            <Fab variant="extended" size="small" className="btn btn-small btn-plane btn-curve secondary-color">
                                Primary
                            </Fab>
                        </Grid>
                        <Grid item xs={2}>
                            <Fab variant="extended" size="small" className="btn btn-small btn-plane btn-curve secondary-color">
                                <NavigationIcon />
                                Primary
                            </Fab>
                        </Grid>
                        <Grid item xs={2}>
                            <Fab variant="extended" size="small" className="btn btn-small btn-plane btn-fab secondary-color">
                                <NavigationIcon />
                            </Fab>
                        </Grid>
                    </Grid>
                    <Divider light />
                    <Grid container spacing={4}>
                        <Grid item xs={2}>
                            <Fab variant="extended" size="small" className="btn btn-small btn-plane grey-color">
                                {/* <NavigationIcon/> */}
                                Primary
                            </Fab>
                        </Grid>
                        <Grid item xs={2}>
                            <Fab variant="extended" size="small" className="btn btn-small btn-plane grey-color">
                                <NavigationIcon />
                                Primary
                            </Fab>
                        </Grid>
                        <Grid item xs={2}>
                            <Fab variant="extended" size="small" className="btn btn-small btn-plane btn-curve grey-color">
                                Primary
                            </Fab>
                        </Grid>
                        <Grid item xs={2}>
                            <Fab variant="extended" size="small" className="btn btn-small btn-plane btn-curve grey-color">
                                <NavigationIcon />
                                Primary
                            </Fab>
                        </Grid>
                        <Grid item xs={2}>
                            <Fab variant="extended" size="small" className="btn btn-small btn-plane btn-fab grey-color">
                                <NavigationIcon />
                            </Fab>
                        </Grid>
                    </Grid>
                </div>
                <div className="form-row">
                    <h1>Kendo Component</h1>
                    <DateKendo label="Date Picker" name="Date_Picker" instruction="instruction" fieldStyle="field-box" />
                    <DateRangePickerKendo label="Date Range Picker" value={{ start: null, end: null }} name="Date_Range_Picker" instruction="instruction" fieldStyle="field-box" />
                    <DateTimePickerKendo label="Date Time Picker" name="Date_Time_Picker" instruction="instruction" fieldStyle="field-box" />
                    <TimePickerKendo label="Time Picker" name="Time_Picker" instruction="instruction" fieldStyle="field-box" />
                    <RichTextKendo label="Rich Text" name="Rich_Text" instruction="instruction" fieldStyle="field-box" rowmax="120" />
                </div>
                <div className="form-row">
                    <h1>Icon Filter</h1>
                    {/* <IconFilterPopover label="Selection" name="Selection" instruction="instruction" customStyle="adornment-only-icon" fieldStyle="field-box"/> */}
                </div>
            </div>
        )
    }
}

export default Components;