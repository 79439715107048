import React, { Component } from 'react';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import FileCopy from '@material-ui/icons/FileCopy';
import SwapHoriz from '@material-ui/icons/SwapHoriz';
import Fab from '@material-ui/core/Fab';
import CloseIcon from '../../assets/images/icon/close.svg';
import { Scrollbars } from 'react-custom-scrollbars';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import InputField from '../fields/inputs';





const options = [
    { name: 'Import', icons: ArrowDownward, dialog: 'import' },
    { name: 'Copy', icons: FileCopy, dialog: 'copy' },
    { name: 'Asign Value', icons: SwapHoriz, dialog: 'assign' },
];

class AdvanceChoice extends Component {
    state = {
        anchorEl: null,
        importDialogOpen: false,
        copyDialogOpen: false,
        assignDialogOpen: false,
    };

    openMenu = event => {
        this.setState({ anchorEl: event.currentTarget });
    };

    closeMenu = () => {
        this.setState({ anchorEl: null });
    };

    handleClickOpen = dialogName => event => {
        this.setState({ [dialogName + "DialogOpen"]: true, anchorEl: null });
    };

    handleClose = dialogName => event => {
        this.setState({ [dialogName + "DialogOpen"]: false });
    };

    handleAssignTextChange = (index) => (value) => {
        let radioData = this.props.radioData;
        radioData[index].field_option_coded = value;
        this.props.optionOnChangeEvent(radioData);
    }


    importDialog() {
        return (
            <Dialog
                fullScreen={this.state.fullScreen}
                open={this.state.importDialogOpen}
                onClose={this.handleClose("import")}
                aria-labelledby="responsive-dialog-title"
                maxWidth="md"
            >
                <section className="workflow-dialog workflow-dialog-small workflow-dialog-pri">
                    <div className="card-block">
                        <div className="card-header border-bottom p-b-25">
                            <div className="sub-title">
                                <h2 className="bold">Import</h2>
                            </div>
                            <div className="close-dialog">
                                <img src={CloseIcon} alt="" onClick={this.handleClose("import")}></img>
                            </div>
                        </div>
                        <div className="card-body p-b-0">
                            <div className="workflow-dialog-forms formula-dialog">
                                <div className="tabs-container tabs-container-scroll">
                                    <Scrollbars style={{ width: '100%', height: '100%' }}>
                                        <div className="tabs-container-scroll-wrap">
                                            <h2 className="bold">Import</h2>
                                        </div>
                                    </Scrollbars>
                                </div>
                                <div className="form-row workflow-dialog-footer p-l-0 p-r-0">
                                    <div className="btn-wrap no-border">
                                        <Fab variant="extended" size="large" className="btn btn-large btn-plane primary-color" onClick={this.handleClose("import")}>
                                            Save
                                        </Fab>
                                        <Fab variant="extended" size="large" className="btn btn-large btn-plane grey-color" onClick={this.handleClose("import")}>
                                            Cancel
                                        </Fab>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </Dialog>
        )
    }
    copyDialog() {
        return (
            <Dialog
                fullScreen={this.state.fullScreen}
                open={this.state.copyDialogOpen}
                onClose={this.handleClose("copy")}
                aria-labelledby="responsive-dialog-title"
                maxWidth="md"
            >
                <section className="workflow-dialog workflow-dialog-small workflow-dialog-pri">
                    <div className="card-block">
                        <div className="card-header border-bottom p-b-25">
                            <div className="sub-title">
                                <h2 className="bold">Copy</h2>
                            </div>
                            <div className="close-dialog">
                                <img src={CloseIcon} alt="" onClick={this.handleClose("copy")}></img>
                            </div>
                        </div>
                        <div className="card-body p-b-0">
                            <div className="workflow-dialog-forms formula-dialog">
                                <div className="tabs-container tabs-container-scroll">
                                    <Scrollbars style={{ width: '100%', height: '100%' }}>
                                        <div className="tabs-container-scroll-wrap">
                                            <h2 className="bold">Copy</h2>
                                        </div>
                                    </Scrollbars>
                                </div>
                                <div className="form-row workflow-dialog-footer p-l-0 p-r-0">
                                    <div className="btn-wrap no-border">
                                        <Fab variant="extended" size="large" className="btn btn-large btn-plane primary-color" onClick={this.handleClose("copy")}>
                                            Save
                                        </Fab>
                                        <Fab variant="extended" size="large" className="btn btn-large btn-plane grey-color" onClick={this.handleClose("copy")}>
                                            Cancel
                                        </Fab>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </Dialog>
        )
    }
    assignValueDialog(radioData) {
        return (
            <div>
                <Dialog
                    fullScreen={this.state.fullScreen}
                    open={this.state.assignDialogOpen}
                    onClose={this.handleClose("assign")}
                    aria-labelledby="responsive-dialog-title"
                    maxWidth="md"
                >
                    <section className="workflow-dialog workflow-dialog-small workflow-dialog-pri">
                        <div className="card-block">
                            <div className="card-header border-bottom p-b-25">
                                <div className="sub-title">
                                    <h2 className="bold">Assign a value to each devices</h2>
                                </div>
                                <div className="close-dialog">
                                    <img src={CloseIcon} alt="" onClick={this.handleClose("assign")}></img>
                                </div>
                            </div>
                            <div className="card-body p-b-0">
                                <div className="workflow-dialog-forms formula-dialog">
                                    <div className="tabs-container tabs-container-scroll">
                                        <Scrollbars style={{ width: '100%', height: '100%' }}>
                                            <div className="tabs-container-scroll-wrap">
                                                <div className="table-wrapper table-strip-line">
                                                    <Table>
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell className="p-t-15 p-b-15">Labels</TableCell>
                                                                <TableCell className="p-t-15 p-b-15">Code Values</TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {radioData &&
                                                                radioData.map((data, index) => {
                                                                    return (
                                                                        <TableRow>
                                                                            <TableCell>{data.field_option_label}</TableCell>
                                                                            <TableCell>
                                                                                <InputField
                                                                                    selected_value={data.field_option_coded}
                                                                                    handleOnChangeValue={this.handleAssignTextChange(index).bind(this)}
                                                                                />
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    );
                                                                })
                                                            }

                                                        </TableBody>
                                                    </Table>
                                                </div>
                                            </div>
                                        </Scrollbars>
                                    </div>
                                    <div className="form-row workflow-dialog-footer p-l-0 p-r-0">
                                        <div className="btn-wrap no-border">
                                            <Fab variant="extended" size="large" className="btn btn-large btn-plane primary-color" onClick={this.handleClose("assign")}>
                                                Save
                                            </Fab>
                                            <Fab variant="extended" size="large" className="btn btn-large btn-plane grey-color" onClick={this.handleClose("assign")}>
                                                Cancel
                                            </Fab>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </Dialog>
            </div>
        )
    }

    render() {
        const { anchorEl } = this.state;
        const open = Boolean(anchorEl);
        let radioData = this.props.radioData;


        return (
            <div className="properties-choice-wrap">
                <IconButton
                    aria-label="More"
                    aria-owns={anchorEl ? 'long-menu' : undefined}
                    aria-haspopup="true"
                    onClick={this.openMenu}
                >
                    <MoreVertIcon />
                </IconButton>
                <Menu
                    id="long-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={this.closeMenu}
                    className="menu-wrap menu-small"
                >
                    {options.map((option, index) => (
                        <MenuItem key={index} onClick={this.handleClickOpen(option.dialog)}>
                            {React.createElement(option.icons)}
                            {option.name}
                        </MenuItem>
                    ))}
                </Menu>
                {this.importDialog()}
                {this.copyDialog()}
                {this.assignValueDialog(radioData)}
            </div>
        )
    }
}

export default AdvanceChoice;