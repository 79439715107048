import React, { Component } from 'react';
import Fab from '@material-ui/core/Fab';
import Drawer from '@material-ui/core/Drawer';
import { Scrollbars } from 'react-custom-scrollbars';
import closeIcon from '../../assets/images/icon/close.svg';
import MultipleTabActions from '../../components/common/multiple-tab-actions';

//Custom component
import ActionFormMasterComponent from '../action-form/action-form-master-component';

//API
import * as ProcessProgress from '../../api/process-api';
import * as ProgramApi from '../../api/program-api';
import * as RecordProcessor from '../../utilities/form-record-process';

class ActionsViewSetup extends Component {
    state = {
        right: false,
        tabAction: 0,
        processData: { form: {} },
        linkData: {},
        sectionData: {},
    }

    componentDidMount() {
        this.getProcessDetails();
    }

    getProcessDetails(callback) {
        ProcessProgress.getProcessDetails(this.props.process_id).then(processData => {
            this.setState({ processData: processData.process_progress_dto, linkData: processData._links }, callback);
        });
    }

    afterDialogEventSave() {
        this.getProcessDetails(null);
    }

    generateData(formData) {
        console.log("formData", formData);
        let updatedFormData = JSON.parse(JSON.stringify(formData));
        let recordDataList = RecordProcessor.generateProcessedRecords(updatedFormData.form, updatedFormData.records);
        recordDataList.columns.forEach(column => {
            recordDataList.rows.forEach(row => {
                if (row[column.id]) {
                    column.value = row[column.id];
                    return;
                }
            })
        })
        updatedFormData.fieldDataList = recordDataList.columns;
        delete updatedFormData.records;
        return updatedFormData;
    }

    toggleDrawer = (side, open) => event => {
        this.getProcessDetails(() => this.setState({ [side]: open }));
    };

    handleActionChange(event, newValue) {
        this.setState({ tabAction: newValue });
    };

    displayIdField() {
        let { processData } = this.state;
        let idFieldName = "";
        let recordId = "";
        let paginationId = "";
        let dataList = this.getFormDataList(processData);
        if (dataList.length > 0) {
            if (dataList[0].form) {
                dataList[0].form.form_field_list.forEach(list => {

                    if (list.id_field === "yes") {
                        recordId = "data_attr" + list.data_ref_id;
                        paginationId = list.data_pagination_id;
                    }
                })
                if (recordId && paginationId) {
                    idFieldName = dataList[0].records.filter(record =>
                        record.form_pagination_id === paginationId)[0][recordId];
                }
            }
        }
        return idFieldName;
    }

    getFormDataList(processData) {
        let formDataList = [];
        if (processData.sectional_form_record) {
            formDataList.push(processData.sectional_form_record);
            formDataList = formDataList.concat(processData.other_form_records);
        }
        return formDataList;
    }

    render() {
        const { tabAction, processData } = this.state;
        let { programKey, currentStateName, currentSubStateName } = this.props;
        let recordData = { fieldDataList: [] };
        if (processData.sectional_form_record) {
            recordData = this.generateData(processData.sectional_form_record);
        }

        return (
            <section className="actions-view-sec">
                <div className="btn-wrap">
                    <Fab variant="extended" size="small" className="btn btn-large btn-outline primary-color btn-take-action" onClick={this.toggleDrawer('right', true)}>
                        &nbsp; Take Action &nbsp;
                    </Fab>
                </div>
                <Drawer anchor="right" open={this.state.right} onClose={this.toggleDrawer('right', false)}>
                    <section className="workflow-setup-sec actions-setup-sec">
                        <section className="setup-drawer-sec">
                            <div className="card-block">
                                <div className="card-header">
                                    <div className="card-title">
                                        <h2>{this.state.right && this.displayIdField()}</h2>
                                    </div>
                                    <div className="card-left-title" style={{ width: 'auto' }}>
                                        <div className="actions setup-close">
                                            <div className="btn-wrap">
                                                <Fab variant="extended" size="small" className="btn btn-small btn-plane btn-fab white-color no-shadow" onClick={this.toggleDrawer('right', false)}>
                                                    Close <img alt="edit" src={closeIcon}></img>
                                                </Fab>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-header border-bottom actions-card-header">
                                    <div className="actions-card-info">
                                        {
                                            currentStateName &&
                                            <div className="form-row details-form-row">
                                                <h6 className="label">State Name</h6>
                                                <h6 className="field">{currentStateName}</h6>
                                            </div>
                                        }
                                    </div>
                                    <div className="actions-card-info">
                                        {
                                            currentSubStateName &&
                                            <div className="form-row details-form-row">
                                                <h6 className="label">Sub State Name</h6>
                                                <h6 className="field">{currentSubStateName}</h6>
                                            </div>
                                        }
                                    </div>
                                    {/* <div className="actions-card-info actions-list-info">
                                        <MultipleTabActions type="icons" iconList={["message","edit","duplicate","delete"]}/>
                                    </div> */}
                                </div>
                                <div className="card-body">
                                    <div role="presentation" className="drawer-sec">
                                        <div className="drawer-form-sec">
                                            <div className="tab-container tab-container-scroll" style={{ height: 'calc(100vh - 295px)' }}>
                                                <Scrollbars style={{ width: '100%', height: '100%' }}>
                                                    <section className="add-fields-wrap">
                                                        <div className="add-field-row">
                                                            {
                                                                [1].map(fields => {
                                                                    return (
                                                                        <div className="form-sections-wrap">
                                                                            <div className="form-sections-fields">
                                                                                {
                                                                                    recordData.fieldDataList.map(fields => {
                                                                                        return (
                                                                                            <div className="form-row">
                                                                                                <h6 className="label">{fields.label}</h6>
                                                                                                <h6 className="field">{fields.value}</h6>
                                                                                            </div>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                    </section>
                                                </Scrollbars>
                                            </div>
                                            <div className="card-footer border-top">
                                                <div className="drawer-tabs-header">
                                                    <div className="tabs-section">
                                                        {/* <Tabs 
                                                            value={tabAction}
                                                            onChange={this.handleActionChange.bind(this)}
                                                            variant="scrollable"
                                                            scrollButtons="auto"
                                                            aria-label="scrollable auto tabs">
                                                                <Tab label="Sectional" />
                                                                <Tab label="On Click" />
                                                                <Tab label="Action" />
                                                                <Tab label="Action" />
                                                                <Tab label="Action" />
                                                                <Tab label="Action" />
                                                                <Tab label="Action" />
                                                                <Tab label="Action" />
                                                                <Tab label="Action" />
                                                                <Tab label="Action" />
                                                        </Tabs> */}
                                                        {processData.current_form &&
                                                            processData.current_form.form_field_list.length > 0 ?
                                                            <ActionFormMasterComponent
                                                                programKey={programKey}
                                                                processData={processData}
                                                                afterDialogEventSave={this.afterDialogEventSave.bind(this)}
                                                            />
                                                            : <h6> No Action Available</h6>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </section>
                </Drawer>
            </section>
        )
    }
}

export default ActionsViewSetup;