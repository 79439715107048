import * as Constants from "../utilities/constants";
import * as StorageFileApi from "../api/file-storage-api";
import * as UserApi from "../api/user-api";
import * as ClientApi from "../api/client-api";
import Blank_Avatar from "../assets/images/blank-avatar.png";

export function fetchProfileImageUser() {
  return function (dispatch) {
    UserApi.getProfileImage().then((res) => {
      let blobUrl = window.URL.createObjectURL(res);
      dispatch({
        type: Constants.REDUX_ACTION.FETCH_PROFILE_IMAGE_USER,
        payload: blobUrl,
      });
    });
  };
}

export function fetchProfileImageClient() {
  return function (dispatch) {
    ClientApi.getProfileImage().then((res) => {
      let blobUrl = window.URL.createObjectURL(res);
      dispatch({
        type: Constants.REDUX_ACTION.FETCH_PROFILE_IMAGE_CLIENT,
        payload: blobUrl,
      });
    });
  };
}

export function changeProfileImage(
  formDataValue,
  userDetails,
  uploadPic,
  loaderFunction
) {
  return function (dispatch) {
    StorageFileApi.postFileUpload(formDataValue).then((res) => {
      userDetails.profile_image_id = res.file_id;
      loaderFunction();
      UserApi.updateUserDetails(userDetails.user_id, userDetails).then(
        (response) => {
          dispatch({
            type: Constants.REDUX_ACTION.CHANGE_PROFILE_IMAGE_USER,
            payload: uploadPic,
          });
        }
      );
    });
  };
}

export function changeClientProfileImage(
  formDataValue,
  clientDetails,
  uploadPic,
  loaderFunction
) {
  return function (dispatch) {
    StorageFileApi.postFileUploadToFolder(
      clientDetails.folder,
      formDataValue
    ).then((res) => {
      clientDetails.profile_image_id = res.file_id;
      loaderFunction();
      ClientApi.updateClient(clientDetails.id, clientDetails).then(
        (response) => {
          dispatch({
            type: Constants.REDUX_ACTION.CHANGE_PROFILE_IMAGE_CLIENT,
            payload: uploadPic,
          });
        }
      );
    });
  };
}

export function deleteProfileImage(userDetails) {
  return function (dispatch) {
    userDetails.profile_image_id = null;
    UserApi.updateUserDetails(userDetails.user_id, userDetails).then(
      (response) => {
        dispatch({
          type: Constants.REDUX_ACTION.DELETE_PROFILE_IMAGE,
          payload: Blank_Avatar,
        });
      }
    );
  };
}
