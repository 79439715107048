import React, { Component } from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Description from '../validators/descriptions';
import MandatoryValidator from '../validators/mandatory-validator';

//Custom 
import ErrorMessage from '../common/error-message';

class AutoComplete extends Component {
    state = {
        errorStates: [],
    };

    render() {
        let {
            id,
            label,
            is_mandatory,
            instruction,
            defaultLabel,
            fieldStyle,
            customStyle,
            disabled,
            optionsData,
            onChange,
            noOptionsText,
            value
        } = this.props;
        let { errorStates } = this.state;
        let defaultFieldStyle = "field-box";

        return (
            <div className={"form-row " + customStyle}>
                <div className={fieldStyle ? fieldStyle : defaultFieldStyle}>
                    <InputLabel
                        htmlFor={label}
                        className="label"
                    >
                        {label ? label : defaultLabel}
                        <MandatoryValidator value={is_mandatory} />
                    </InputLabel>
                    <div className="field ">
                        <div className="autocomplete-field">
                            <Autocomplete
                                noOptionsText={noOptionsText}
                                id={id}
                                value={value}
                                onChange={(event, value) => { onChange(value) }}
                                options={optionsData}
                                getOptionLabel={(option) => option?.field_option_label ?? ''}
                                renderInput={params => (
                                    <TextField {...params} />
                                )}
                                disabled={disabled}
                            />
                        </div>
                    </div>
                    <ErrorMessage errorStates={errorStates} name={label} />
                </div>
                <Description value={instruction} />
            </div>
        )
    }
}

AutoComplete.defaultProps = {
    withLabel: true,
    default_value: [],
    selected_value: [],
}

export default AutoComplete;