import React, { Component } from 'react';
import Header from '../../components/header';
import WorkflowHeader from '../../components/workflow/workflow-header';
import Grid from '@material-ui/core/Grid';
import ClaimsIcons1 from '../../assets/images/illustration/settled-claim-circle.svg';
import ClaimsIcons2 from '../../assets/images/illustration/claims-circle.svg';
import ClaimsIcons3 from '../../assets/images/illustration/policy-circle.svg';
import ClaimsIcons4 from '../../assets/images/illustration/premium-circle.svg';
import SpeedDialComp from '../../components/common/speed-dial';
import AlertBox from '../../components/common/alert-box';
import { Redirect } from 'react-router-dom';

//API
import * as ProgramApi from '../../api/program-api';
import * as FormDataApi from '../../api/form-data-api';
import * as UserApi from '../../api/user-api.jsx';

//Custom component
import DataGridTable from '../../components/dashboard/data-grid-table';
import ActionsViewScene from '../actions-view-scene/actions-view-scene';

//Utilities
import * as RecordProcessor from '../../utilities/form-record-process';


class DashboardMaster extends Component {

    state = {
        sectionData: [],
        selectedSection: {},
        selectedSectionIndex: 1,
        scrollTop: 0,
        gridRecordDetails: {
            rows: [],
            columns: [],
        },
        workflowHeaderStick: false,
        sectionIdToRedirect: '',
        redirectToPublish: false,
        loggedInUserDetails: {},
    }

    constructor(props) {
        super(props)
        this.myRef = React.createRef()
    }

    gridRecordDetailsChange = () => (columnState) => {
        let { gridRecordDetails } = this.state;
        gridRecordDetails.columns = columnState;
        this.setState({ gridRecordDetails: gridRecordDetails });
    }

    componentWillMount() {
        this.getSectionDetails();
        this.getLoggedInUserDetails();
    }


    getSectionDetails() {
        const match = this.props.match;
        let programKey = match.params.programkey;
        ProgramApi.getSection(programKey).then(sections => {
            this.setState({ sectionData: sections, selectedSectionIndex: sections[0].id });
            this.handleSectionChange(this.state.selectedSectionIndex);

            //When we comeback from Details-view to dashboard, we carry Section Id with us.
            if (this.props.location && this.props.location.state) {
                let sectionKey = this.props.location.state.id;
                let selectedSectionData = sections.filter(section => section.id === this.state.selectedSectionIndex)[0];
                console.log("selection 76", selectedSectionData);

                if (sectionKey !== 0 && sectionKey !== sections.length + 1 && selectedSectionData) {
                    this.getFormData(selectedSectionData.form_key);
                }
                this.setState({ selectedSectionIndex: sections[0].id, selectedSection: selectedSectionData });
            }
        });
    }

    getLoggedInUserDetails() {
        UserApi.getLoggedInUser().then(user => {
            this.setState({ loggedInUserDetails: user });
        })
    }

    /* WorkflowHeaderChange=(event)=>()=>{
        this.setState({workflowHeaderStick: !this.state.workflowHeaderStick});
        console.log("this.state.workflowHeaderStick",this.state.workflowHeaderStick);
    } */

    handleSectionChange(sectionId) {
        let selectedSectionData = this.state.sectionData.filter(section => section.id === sectionId)[0];
        console.log("selectedSectionData", selectedSectionData);
        if (sectionId !== 1 && sectionId !== this.state.sectionData.length) {
            this.getFormData(selectedSectionData.form_key);
        }
        this.setState({ selectedSectionIndex: sectionId, selectedSection: selectedSectionData });
    }

    handleRedirect(sectionId) {
        this.setState({ sectionIdToRedirect: sectionId, redirectToPublish: true });
    }

    handleRedirectOnAddClick() {
        console.log("add button handleRedirect");
        const match = this.props.match;
        let clientId = match.params.clientId;
        if (this.state.redirectToPublish) {
            let selectedSectionData = this.state.sectionData.filter(section => section.id === this.state.sectionIdToRedirect)[0];
            console.log("selectedSectionData", selectedSectionData, "this.state.sectionData");
            if (selectedSectionData.form_key) {
                return <Redirect to={{
                    pathname: "/" + clientId + "/" + selectedSectionData.program_key + "/form/" + selectedSectionData.form_key + "/publish",
                    state: { addNewForm: true }
                }}
                />
            }
        }

    }

    getFormData(formKey) {
        this.setState({
            gridRecordDetails: {
                rows: [],
                columns: []
            }
        })
        FormDataApi.getFromData(formKey).then(formRecorData => {
            console.log("formRecorData: ", formRecorData);
            formRecorData.records.reverse();
            let recordData = RecordProcessor.generateProcessedRecords(formRecorData.form, formRecorData.records, "dashboard");
            this.setState({ gridRecordDetails: recordData });
        })
    }

    /* state = {
        expanded: "section1",
        myRef:  React.createRef(),
        scrollTop: 0
    } */
    handleChange = panel => (event, expanded) => {
        this.setState({
            expanded: expanded ? panel : false
        });
    };

    onScroll = () => {
        // const scrollY = window.scrollY //Don't get confused by what's scrolling - It's not the window
        const scrollTop = this.myRef.current.scrollTop
        // console.log(`onScroll, window.scrollY: ${scrollY} myRef.scrollTop: ${scrollTop}`)
        this.setState({
            scrollTop: scrollTop
        })
    }

    render() {
        let { sectionData, selectedSection, selectedSectionIndex, gridRecordDetails, loggedInUserDetails } = this.state;
        const match = this.props.match;
        let programkey = match.params.programkey;
        let clientId = match.params.clientId;
        console.log("gridRecordDetails: ", gridRecordDetails);
        return (
            <section ref={this.myRef} onScroll={this.onScroll}>
                <Header
                    headerType="dashboard"
                    programKey={programkey}
                    clientId={clientId}
                />
                <WorkflowHeader workflowType="dashboard" customStyle="workflow-header-sticky" tab="form"
                    sectionData={sectionData} programKey={programkey} clientId={clientId} handleTabChange={this.handleSectionChange.bind(this)}
                    selectedSectionIndex={selectedSectionIndex} handleRedirect={this.handleRedirect.bind(this)} insideBlock="detailsView" />
                <main>
                    {selectedSectionIndex === 0 && (
                        <div className="workflow-fields-sec dashboard-wrap">
                            <div className="dashboard-details-wrap">
                                <div className="dashboard-details-head">
                                    <div className="dashboard-title">
                                        <h3>Dashbord</h3>
                                        <h6>Sample page for dashbord.</h6>
                                    </div>
                                    <div className="dashboard-props">
                                        {/* <SelectField optionsData={defaultConditions} is_search={false} customStyle="dashboard-select"/> */}
                                    </div>
                                </div>
                            </div>
                            <div className="dashboard-details-wrap">
                                <Grid container spacing={4}>
                                    <Grid item xs={12} lg={8} md={6}>
                                        <div className="card-block">
                                            <div className="charts-wrap">
                                                <h4>Claim Analysis - Causewise</h4>
                                            </div>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} lg={4} md={6}>
                                        <div className="card-block">
                                            <div className="charts-wrap">
                                                <h4>Claim Status</h4>
                                            </div>
                                        </div>
                                    </Grid>
                                </Grid>
                            </div>
                            <div className="dashboard-details-wrap">
                                <Grid container spacing={4}>
                                    <Grid item xs={12} sm={6} md={3}>
                                        <div className="card-block">
                                            <div className="info-cards-wrap">
                                                <div className="info-cards">
                                                    <h2>100</h2>
                                                    <h6>Total Claims</h6>
                                                </div>
                                                <div className="info-cards-img">
                                                    <img src={ClaimsIcons1} alt="ClaimsIcons" />
                                                </div>
                                            </div>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={3}>
                                        <div className="card-block">
                                            <div className="info-cards-wrap">
                                                <div className="info-cards">
                                                    <h2>₹ 20,00,000</h2>
                                                    <h6>Total Claim Amount</h6>
                                                </div>
                                                <div className="info-cards-img">
                                                    <img src={ClaimsIcons2} alt="ClaimsIcons" />
                                                </div>
                                            </div>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={3}>
                                        <div className="card-block">
                                            <div className="info-cards-wrap">
                                                <div className="info-cards">
                                                    <h2>88</h2>
                                                    <h6>Total Claims Settled</h6>
                                                </div>
                                                <div className="info-cards-img">
                                                    <img src={ClaimsIcons3} alt="ClaimsIcons" />
                                                </div>
                                            </div>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={3}>
                                        <div className="card-block">
                                            <div className="info-cards-wrap">
                                                <div className="info-cards">
                                                    <h2>₹ 17,50,000</h2>
                                                    <h6>Settled Claim Amount</h6>
                                                </div>
                                                <div className="info-cards-img">
                                                    <img src={ClaimsIcons4} alt="ClaimsIcons" />
                                                </div>
                                            </div>
                                        </div>
                                    </Grid>
                                </Grid>
                            </div>
                            <div className="dashboard-details-wrap">
                                <Grid container spacing={4}>
                                    <Grid item xs={12} md={6} lg={7}>
                                        <div className="card-block">
                                            <div className="charts-wrap">
                                                <h4>Claims Ratio</h4>
                                            </div>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={5}>
                                        <div className="card-block">
                                            <div className="charts-wrap">
                                                <h4>Claims Ageing</h4>
                                            </div>
                                        </div>
                                    </Grid>
                                </Grid>
                            </div>
                        </div>
                    )}
                    {selectedSectionIndex !== 0 && selectedSectionIndex !== sectionData.length && (
                        <div className="data-grid-wrap table tr-first-color">
                            <DataGridTable gridRecordDetails={gridRecordDetails} gridRecordDetailsChange={this.gridRecordDetailsChange().bind(this)} getSectionDetails={() => this.getSectionDetails()} getFormData={(formKey) => this.getFormData(formKey)} programKey={programkey} clientId={clientId} formKey={selectedSection && selectedSection.form_key} selectedSectionIndex={selectedSectionIndex} />
                        </div>
                    )}
                    {selectedSectionIndex === sectionData.length && (
                        <ActionsViewScene programKey={programkey} clientId={clientId} />
                    )}

                    {/* <div className="alert-wrap">
                        <AlertBox alertType="success" alertTypeStatus="successAlert"/>
                        <AlertBox alertType="warning" alertTypeStatus="warningAlert"/>
                        <AlertBox alertType="info" alertTypeStatus="infoAlert"/>
                    </div> */}
                </main>
                {
                    loggedInUserDetails &&
                    loggedInUserDetails.user_type === 0 &&
                    <SpeedDialComp programKey={programkey} clientId={clientId} />
                }
                {this.handleRedirectOnAddClick()}
            </section>
        )
    }
}

export default DashboardMaster;
