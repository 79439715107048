import React, { Component } from 'react';
import Fab from '@material-ui/core/Fab';
import closeIcon from '../../assets/images/icon/close.svg';
import notificationIcon from '../../assets/images/icon/notification-fill.svg';
import singleLineIcon from '../../assets/images/icon/single-line.svg';
import cloudIcon from '../../assets/images/icon/cloud.svg';
import integrationIcon from '../../assets/images/icon/integration.svg';
import Square2 from '../../assets/images/icon/square-2-blue.svg';
import Button from '@material-ui/core/Button';
import RadioField from '../fields/radio';
import SelectField from '../fields/select';
import ArrowBackGrey from '../../assets/images/icon/chevron-left.svg';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import InputLabel from '@material-ui/core/InputLabel';
import { Scrollbars } from 'react-custom-scrollbars';

import MailIcon from '../../assets/images/icon/mail-blue.svg';
import MessageIcon from '../../assets/images/icon/message-blue.svg';
import MobPhoneIcon from '../../assets/images/icon/mob-phone-blue.svg';
import TickIcon from '../../assets/images/icon/tick-blue.svg';
import LinkIcon from '../../assets/images/icon/link-blue.svg';
import WarningIcon from '../../assets/images/icon/warning-blue.svg';
import HideIcon from '../../assets/images/icon/hide-blue.svg';
import EyeIcon from '../../assets/images/icon/eye-blue.svg';
import EnableIcon from '../../assets/images/icon/enable-blue.svg';
import DisableIcon from '../../assets/images/icon/disable-2-blue.svg';
import SetFieldIcon from '../../assets/images/icon/setfield-blue.svg';
import InputIcon from '../../assets/images/icon/input-blue.svg';
import StateIcon from '../../assets/images/icon/state-blue.svg';
import AddIcon from '../../assets/images/icon/add-blue.svg';
import DeleteIcon from '../../assets/images/icon/delete-blue.svg';
import UpdateIcon from '../../assets/images/icon/update-blue.svg';

//Custom
import FieldOperation from './field-operations-component';
import InputField from '../fields/inputs';
import CheckBoxField from '../fields/checkbox';
import RichTextKendo from '../kendo/rich-text';
import * as Function from '../../utilities/common-utils';
import FieldValueChoice from '../../components/properties/field-value-choice';
import DropdownPopover from '../../components/common/dropdown-popover';
import UpdateLedger from '../../components/common/update-ledger';

//Contants
import * as Constants from '../../utilities/constants';
import { icons } from '../../utilities/constants';

//API
import * as FormElementApi from '../../api/form-builder-api';
import * as FormApi from '../../api/form-api';
import * as FormDataApi from '../../api/form-data-api';

import TextAreaField from '../fields/textarea';
import ProgramShare from '../common/program-share';

const transactionFieldList = [
    {
        field_option_label: "Payment Id",
        field_key: "paymentId",
    },
    {
        field_option_label: "Entity",
        field_key: "entity",
    },
    {
        field_option_label: "Amount",
        field_key: "amount",
    },
    {
        field_option_label: "Currency",
        field_key: "currency",
    },
    {
        field_option_label: "Status",
        field_key: "status",
    },
    {
        field_option_label: "Method",
        field_key: "method",
    },
    {
        field_option_label: "Order Id",
        field_key: "orderId",
    },
    {
        field_option_label: "Description",
        field_key: "description",
    },
    {
        field_option_label: "International",
        field_key: "international",
    },
    {
        field_option_label: "Amount Refunded",
        field_key: "amountRefunded",
    },
    {
        field_option_label: "Captured",
        field_key: "captured",
    },
    {
        field_option_label: "Email",
        field_key: "email",
    },
    {
        field_option_label: "Contact",
        field_key: "contact",
    },
    {
        field_option_label: "Fee",
        field_key: "fee",
    },
    {
        field_option_label: "Tax",
        field_key: "tax",
    },
    {
        field_option_label: "Error Code",
        field_key: "errorCode",
    },
    {
        field_option_label: "Error Description",
        field_key: "errorDescription",
    },
]
let arrayConst = {
    "new-india-assurance": "New India Assurance",
    "icici-lombard": "ICICI Lombard",
    "apollo-munich": "Apollo Munich",
    "hdfc-ergo": "HDFC ERGO",
    "marsh-mclennan": "Marsh & McLennan",
    "india-nivesh": "India Nivesh",
    "unicorn-insurance": "Unicorn Insurance",
    "howden-insurance": "Howden Insurance Brokers India Private Limited",
    "optima-insurance-brokers": "Optima Insurance Brokers Pvt. Ltd",
    "iffco-tokio-general-insurance": "Iffco Tokio General Insurance",
    "unilight-insurance-brokers": "Unilight Insurance Brokers"
}
class WorkflowSetups extends Component {

    state = {
        conditionValue: '',
        actionType: null,
        action: null,
        fieldList: [],
        actionFieldList: [],
        selectedFields: null,
        parameterFormList: [],
        state: {
            state_id: null,
            sub_state_id: null,
        },
        stepData: {
            field_operations: [],
            field_set_value: []
        },
        fieldOperation: {},
        formFieldDetails: [],
        redirectData: {},
        form_access: {
            formFieldRecordsMadate: [],
            formFieldRecordsOther: [],
            field_operations: [],
            fieldOperation: {},
            recordType: "currentRecord",
        },
        setFieldValueList: [{}],
        fieldListData: [],
        sendInvitation: {
            email: '',
            permission_set_id: null,
        },
        setOtherFieldList: [{}],
        buyPolicy: {
            amount: null,
            policy_id: null,
            stake_holder: null,

        },
        sendEmail: {
            send_to: null,
            cc: null,
            bcc: null,
            reply_to: null,
            subject: null,
            content: null,
            submitted_data: null,
        },
        ccEmail: false,
        bccEmail: false,
        replyToEmail: false,
        sendSms: {
            message: "",
            receiver: null
        },
        warning: {
            warning_text: '',
            alert_type: 'warning-type',
        },
        selectedIcon: { text: "Square2", icon: Square2 },
        anchorElem: "",
        anchorEmailSubject: "",
        anchorEmailContent: "",
        anchorEmailSendTo: "",
        anchorEmailCc: "",
        anchorSendSMS: "",
        anchorAmount: "",
        anchorPolicyId: "",
        anchorProposalId: "",
        paymentWorkflowData: [],
        assureGateData: [],
        ledgerWorkflowData: [],
        stakeholdersData: null,
        paymentWorkflowSelect: null,
        ledgerWorkflowSelect: null,
        policyGenerator: {
            form_field_id: null,
            policy_generation_mode: null,
            program_parameter_form: null,
            program_parameter_data_field: null,
            program_parameter_update_field: null
        },
        downloadPdf: {
            proposalId: null,
        },
    }

    componentWillMount() {
        let { formData, newChildIndex, stepDataCopy, newOrEdit, workflowData, conditionExist } = this.props;
        let { state, setFieldValueList, form_access, setOtherFieldList } = this.state;

        console.log("stepDataCopy", stepDataCopy, "newChildIndex", newChildIndex);
        console.log("State", this.state);
        console.log("props", this.props);
        if (conditionExist) {
            this.setState({ conditionValue: "condition" });
        } else {
            this.setState({ conditionValue: "" });
        }


        let fieldList = [];
        let fieldConditionObj = {};
        let actionFieldList = [];
        if (formData.form_field_list && formData.form_field_list.length > 0) {
            fieldList = this.generateFieldListWithGroup(formData.form_field_list).fieldList;
            actionFieldList = this.generateFieldListWithGroup(formData.form_field_list).actionFieldList;
            this.setState({ fieldList: fieldList, actionFieldList: actionFieldList });
        }

        let formElementPromise = FormElementApi.getFormFieldMetadataTypes();
        formElementPromise.then(data => {

            let formFieldDetails = data;
            fieldList.forEach(list => {
                formFieldDetails.forEach(detail => {
                    if (list.field_type === detail.field_code_txt) {
                        fieldConditionObj[list.field_option_value] = detail.field_conditions;
                    }
                })
            })
            //Adding fieldConditions to form_access for Update Records
            if (newOrEdit === "editAction") {
                let recordUpdateObj = stepDataCopy.actions[newChildIndex];
                if (recordUpdateObj.action_type === Constants.ACTIONS_TYPE.DATA_ACCESS && recordUpdateObj.action_spec === Constants.FIELD_ACTIONS.UPDATE_RECORD) {
                    form_access["form_key"] = recordUpdateObj.form_access.form_key;
                    // recordUpdateObj.form_access.form_field_records.forEach(record=>{
                    //     if(record.mandatory)
                    //     {
                    //         record["disabled"] = true;
                    //         form_access.formFieldRecordsMadate.push(record);
                    //     }
                    //     else{
                    //         form_access.formFieldRecordsOther.push(record);
                    //     }
                    // })
                    // form_access.formFieldRecordsOther = recordUpdateObj.form_access.form_field_records;
                    if (recordUpdateObj.form_access.field_criteria.length > 0) {
                        form_access.recordType = "specifyCriteria";
                        form_access.field_operations = recordUpdateObj.form_access.field_criteria;
                        form_access.field_operations.forEach((operation, index) => {
                            operation["field_conditions"] = fieldConditionObj[operation.field_id];
                        })
                    }
                    else {
                        form_access.recordType = "currentRecord";
                    }

                    if (form_access.form_key !== formData.form_key) {
                        let newFormConditionsObj = {};
                        let fieldListData = form_access.form_key ? workflowData.form_list.filter(form => form.field_option_value === form_access.form_key)[0].field_metadata : [];

                        fieldListData.forEach(list => {
                            formFieldDetails.forEach(detail => {
                                if (list.field_type === detail.field_code_txt) {
                                    newFormConditionsObj[list.id] = detail.field_conditions;
                                }
                            })
                        })
                        form_access.field_operations.forEach((operation, index) => {
                            operation["field_conditions"] = newFormConditionsObj[operation.field_id];
                        })

                    }

                }
            }
            this.setState({ formFieldDetails: data }, () => { this.generateSelectedFormFields() });
        })

        if (newOrEdit === "editAction") {

            //This is used to set the default values of State and Substate which we need during editing.
            let obj = stepDataCopy.actions[newChildIndex].state;
            if (obj) {
                state.state_id = obj.state_id;
                state.sub_state_id = obj.sub_state_id;
            }

            //This is used to assign default value to SET FIELDS VALUE
            let newObj = stepDataCopy.actions[newChildIndex].action_spec;
            let newPayObj = stepDataCopy.actions[newChildIndex].payment_workflow;
            if (newObj === Constants.FIELD_ACTIONS.SET || newObj === Constants.FIELD_ACTIONS.PAYMENT_INIT || newObj === Constants.FIELD_ACTIONS.PAYMENT_SUCCESS || newObj === Constants.FIELD_ACTIONS.PAYMENT_FAIL) {
                setFieldValueList = stepDataCopy.actions[newChildIndex].fields ? stepDataCopy.actions[newChildIndex].fields : [{}];
                if (newPayObj) {
                    this.setState({ paymentWorkflowSelect: stepDataCopy.actions[newChildIndex].payment_workflow })
                }
            }
            let newledObj = stepDataCopy.actions[newChildIndex].ledger_workflow;
            if (newObj === Constants.FIELD_ACTIONS.BUY_POLICY || newObj === Constants.FIELD_ACTIONS.LEDGER_SUCCESS || newObj === Constants.FIELD_ACTIONS.LEDGER_FAIL || newObj === Constants.FIELD_ACTIONS.LEDGER_FAIL || newObj === Constants.FIELD_ACTIONS.CANCEL_POLICY || newObj === Constants.FIELD_ACTIONS.OPEN_ASSUREGATE) {
                setFieldValueList = stepDataCopy.actions[newChildIndex].fields ? stepDataCopy.actions[newChildIndex].fields : [{}];
                if (newledObj) {
                    this.setState({ ledgerWorkflowSelect: stepDataCopy.actions[newChildIndex].ledger_workflow })
                }
            }

            if (newObj === Constants.FIELD_ACTIONS.BUY_POLICY) {
                this.setState({ buyPolicy: stepDataCopy.actions[newChildIndex].buy_policy });
            }

            if (newObj === Constants.FIELD_ACTIONS.CANCEL_POLICY) {
                setOtherFieldList = stepDataCopy.actions[newChildIndex].fields ? stepDataCopy.actions[newChildIndex].fields : [{}];
                this.setState({ setOtherFieldList: stepDataCopy.actions[newChildIndex].other_fields });
            }

            if (newObj === Constants.FIELD_ACTIONS.OPEN_ASSUREGATE) {
                setOtherFieldList = stepDataCopy.actions[newChildIndex].fields ? stepDataCopy.actions[newChildIndex].fields : [{}];
                this.setState({ setOtherFieldList: stepDataCopy.actions[newChildIndex].other_fields });
                console.log("qwerty", setOtherFieldList);
            }

            // Set the proposal ID and broker ID for download PDF.
            if (newObj === Constants.FIELD_ACTIONS.PDF_DOWNLOAD) {
                const { proposal_id } = stepDataCopy.actions[newChildIndex].download_pdf;
                this.setState({
                    downloadPdf: {
                        proposalId: proposal_id,
                    }
                })
            }

            //This is used to set formFieldRecordsMadate and formFieldRecordsOther when editing Add records
            let recordObj = stepDataCopy.actions[newChildIndex];
            if (recordObj.action_type === Constants.ACTIONS_TYPE.DATA_ACCESS && (recordObj.action_spec === Constants.FIELD_ACTIONS.UPDATE_RECORD || recordObj.action_spec === Constants.FIELD_ACTIONS.ADD_RECORD)) {

                form_access["form_key"] = recordObj.form_access.form_key;

                recordObj.form_access.form_field_records.forEach(record => {
                    if (record.mandatory) {
                        record["disabled"] = true;
                        form_access.formFieldRecordsMadate.push(record);
                    }
                    else {
                        form_access.formFieldRecordsOther.push(record);
                    }
                })
                //form_access.formFieldRecordsOther = recordObj.form_access.form_field_records;
            }

            //This is used to set default checked fields to true in fieldList
            if (stepDataCopy.actions[newChildIndex].action_type === Constants.ACTIONS_TYPE.FIELDS_ACTION) {
                this.setState({ actionFieldList: this.setDefaultFieldList(actionFieldList) });
            }

            if (stepDataCopy.actions[newChildIndex].action_spec === Constants.FIELD_ACTIONS.WARNING_MESSAGE) {
                this.setState({ warning: stepDataCopy.actions[newChildIndex].warning });
            }

            if (stepDataCopy.actions[newChildIndex].action_type === Constants.ACTIONS_TYPE.NOTIFICATION && stepDataCopy.actions[newChildIndex].action_spec === Constants.FIELD_ACTIONS.SEND_EMAIL) {
                let { ccEmail, bccEmail, replyToEmail } = this.state;
                let emailData = stepDataCopy.actions[newChildIndex].email;
                ccEmail = emailData.cc;
                bccEmail = emailData.bcc;
                replyToEmail = emailData.reply_to;

                this.setState({ sendEmail: emailData, ccEmail: ccEmail, bccEmail: bccEmail, replyToEmail: replyToEmail });
            }

            if (stepDataCopy.actions[newChildIndex].action_type === Constants.ACTIONS_TYPE.NOTIFICATION && stepDataCopy.actions[newChildIndex].action_spec === Constants.FIELD_ACTIONS.REDIRECT) {
                this.setState({ redirectData: stepDataCopy.actions[newChildIndex].redirect });
            }

            if (stepDataCopy.actions[newChildIndex].action_type === Constants.ACTIONS_TYPE.NOTIFICATION && stepDataCopy.actions[newChildIndex].action_spec === Constants.FIELD_ACTIONS.SEND_SMS) {
                this.setState({ sendSms: stepDataCopy.actions[newChildIndex].sms });
            }

            if (stepDataCopy.actions[newChildIndex].action_type === Constants.ACTIONS_TYPE.FIELDS_ACTION && stepDataCopy.actions[newChildIndex].action_spec === Constants.FIELD_ACTIONS.POLICY_GENERATOR) {
                this.setState({ policyGenerator: stepDataCopy.actions[newChildIndex].policy_generator });
            }
            if (stepDataCopy.actions[newChildIndex].action_type === "notification" && stepDataCopy.actions[newChildIndex].action_spec === "send-invitation") {
                this.setState({ sendInvitation: stepDataCopy.actions[newChildIndex].send_invitation });
            }

            if (stepDataCopy.actions.length > 0 && newChildIndex >= 0) {
                this.setState({
                    actionType: stepDataCopy.actions[newChildIndex].action_type,
                    action: stepDataCopy.actions[newChildIndex].action_spec,
                    state: state,
                    setFieldValueList: setFieldValueList,
                    form_access: form_access,
                    fieldList: fieldList
                },
                    () => { this.generateSelectedFormFields() });
            }
        }
    }

    handleFieldOperationMaster = () => () => {
        this.setState({ fieldOperation: {} });
    }

    handleChange = (key) => (event, newValue) => {
        this.setState({ [key]: newValue });
    }

    conditionChangeHandle = (value, errors) => {
        this.setState({ conditionValue: value });
    }

    handleActionChange = (key, actionType) => (event) => {
        this.setState({ [key]: actionType });

        if (key === "actionType") {
            console.log("here", key, actionType);
            let stepData = this.state.stepData;
            let fieldOperation = this.state.fieldOperation;
            if (!Function.isEmpty(fieldOperation)) {
                if (!stepData.field_operations) {
                    stepData.field_operations = [];
                }
                fieldOperation["position"] = stepData.field_operations.length;
                stepData.field_operations.push(fieldOperation);
            }
            this.setState({ stepData: stepData });

        }
        console.log(this.state, key, actionType);

    }

    handleBackClick = (event, breadCrumbValue) => {
        if (breadCrumbValue) {
            this.setState({ [breadCrumbValue]: null });
        }
        if (this.state.action) {
            this.setState({ action: null });
        } else if (this.state.actionType) {
            this.setState({ actionType: null, form_access: { formFieldRecordsMadate: [], formFieldRecordsOther: [{}] } });
        }
        let stepData = this.state.stepData;
        stepData.field_operations.splice(stepData.field_operations.length - 1, 1); //Deleting the last element of ActionData
        this.setState({ stepData: stepData });
    }

    handleDataChangeEvent(key, value, errors, callback) {
        console.log("key_123", key, "value", value);
        let { action } = this.state;
        if (key.split(".").length > 1) {
            let splitKeys = key.split(".");
            key = splitKeys[0];
            let valueObj = this.state[key];
            if (!valueObj) {
                valueObj = {};
            }
            if (splitKeys[1] === "form_key" && valueObj.hasOwnProperty("form_key") && action !== Constants.FIELD_ACTIONS.REDIRECT) {
                if (valueObj.form_key !== value) {
                    valueObj = {
                        formFieldRecordsMadate: [],
                        formFieldRecordsOther: [{}],
                        field_operations: [],
                        fieldOperation: {},
                        recordType: "currentRecord",
                    }
                    this.generateSelectedFormFields();
                }
            }

            if (splitKeys[1] === "form_key" && action === Constants.FIELD_ACTIONS.UPDATE_RECORD && this.props.formData.form_key !== value) {
                valueObj = {
                    formFieldRecordsMadate: [],
                    formFieldRecordsOther: [{}],
                    field_operations: [],
                    fieldOperation: {},
                    recordType: "specifyCriteria",
                }
                this.generateSelectedFormFields();
            }
            valueObj[splitKeys[1]] = value;
            value = valueObj;
        }
        console.log(key, value);
        this.setState({ [key]: value }, callback);
    }

    handleSaveAction = (event) => {
        let workflowStateData = this.state;
        let { workflowData, parentIndex, stepDataList } = this.props;
        let stepData = (parentIndex < 0) ? workflowStateData.stepData : stepDataList[parentIndex];
        stepData["conditional"] = (workflowStateData.conditionValue === "condition");
        //Updating Actions
        stepData["actions"] = this.getActionData(stepData);

        stepData["workflow_id"] = workflowData.id;

        if (parentIndex < 0) {
            stepData["position"] = stepDataList.length;
        }
        //Saving the data
        this.props.handleNewAction(stepData);


        //console.log("Verifying the Out Put", stepData);
        this.props.toggleDrawer('right', false);
    }

    getActionData(stepData) {
        let workflowStateData = this.state;
        let { newOrEdit, newParentIndex, newChildIndex } = this.props;
        //Setting Action for #Field Action#
        let actions = stepData.actions;
        let actionObj = {
            action_type: workflowStateData.actionType,
            action_spec: workflowStateData.action,
            position: actions ? actions.length : 0,
        }

        if (!actions) {
            actions = [];
        }
        console.log("workflowStateData: ", workflowStateData);
        switch (workflowStateData.action) {
            case Constants.FIELD_ACTIONS.HIDE:
            case Constants.FIELD_ACTIONS.SHOW:
            case Constants.FIELD_ACTIONS.ENABLE:
            case Constants.FIELD_ACTIONS.DISABLE: this.generateFieldForAction(actionObj); break;
            case Constants.FIELD_ACTIONS.SET: actionObj["fields"] = workflowStateData.setFieldValueList; break;
            case Constants.FIELD_ACTIONS.POLICY_GENERATOR: actionObj["policy_generator"] = workflowStateData.policyGenerator; break;
            case Constants.FIELD_ACTIONS.PAYMENT_INIT: actionObj["fields"] = workflowStateData.setFieldValueList; actionObj["payment_workflow"] = this.state.paymentWorkflowSelect; break;
            case Constants.FIELD_ACTIONS.OPEN_ASSUREGATE: actionObj["fields"] = workflowStateData.setFieldValueList; actionObj["assuregate_data"] = this.state.assureGateData; break;
            case Constants.FIELD_ACTIONS.BUY_POLICY: actionObj["buy_policy"] = workflowStateData.buyPolicy; actionObj["ledger_workflow"] = this.state.ledgerWorkflowSelect; break;
            case Constants.FIELD_ACTIONS.CANCEL_POLICY: actionObj["fields"] = workflowStateData.setFieldValueList; actionObj["other_fields"] = this.state.setOtherFieldList; break;
            case Constants.FIELD_ACTIONS.PAYMENT_SUCCESS:
            case Constants.FIELD_ACTIONS.PAYMENT_FAIL: actionObj["fields"] = workflowStateData.setFieldValueList; break;
            case Constants.FIELD_ACTIONS.LEDGER_SUCCESS:
            case Constants.FIELD_ACTIONS.LEDGER_FAIL: actionObj["fields"] = workflowStateData.setFieldValueList; break;
            case Constants.FIELD_ACTIONS.REDIRECT: actionObj["redirect"] = workflowStateData.redirectData; break;
            case Constants.FIELD_ACTIONS.ADD_RECORD: this.generateFormAccessAction(actionObj); break;
            case Constants.FIELD_ACTIONS.UPDATE_RECORD: this.generateFormAccessAction(actionObj); break;
            case Constants.FIELD_ACTIONS.DELETE_RECORD: this.generateFormAccessAction(actionObj); break;
            case Constants.FIELD_ACTIONS.STATE: this.generateStateAction(actionObj); break;
            case Constants.FIELD_ACTIONS.SEND_EMAIL: actionObj["email"] = workflowStateData.sendEmail; break;
            case Constants.FIELD_ACTIONS.SEND_SMS: actionObj["sms"] = workflowStateData.sendSms; break;
            case Constants.FIELD_ACTIONS.WARNING_MESSAGE: actionObj["warning"] = workflowStateData.warning; break;
            case Constants.FIELD_ACTIONS.SEND_INVITATION: actionObj["send_invitation"] = workflowStateData.sendInvitation; break;
            case Constants.FIELD_ACTIONS.PDF_DOWNLOAD:
                actionObj.download_pdf_proposal_id = workflowStateData.downloadPdf.proposalId;
                break;
            default: break;
        }

        if (newOrEdit === "editAction") {
            actions[newChildIndex] = actionObj;
        }
        else {
            actions.push(actionObj);
        }
        return actions;
    }

    // setPaymentWorkflowInActionTrigger(){
    //     let {paymentWorkflowSelect} = this.state;
    //     let {paymentWorkflowList} = this.props;
    //     let workflowObj = {};
    //     paymentWorkflowList.forEach(data=>{
    //         if(data.workflow_key === paymentWorkflowSelect)
    //         {
    //             workflowObj = data;
    //         }
    //     })
    //     return workflowObj;
    // }

    generateStateAction(actionObj) {
        let { state } = this.state;
        actionObj["state"] = state;
    }

    generateFormAccessAction(actionObj) {
        let { form_access, action } = this.state;
        console.log("form_access", form_access);
        //Updating Action for access_form
        if (form_access.form_key) {
            let formFieldData = [];
            //render and set mandatory data
            if (action !== Constants.FIELD_ACTIONS.UPDATE_RECORD) {
                form_access.formFieldRecordsMadate.forEach(formField => {
                    formField["mandatory"] = formField.disabled;
                    formFieldData.push(formField)
                })
            }

            //set other data
            form_access.formFieldRecordsOther.forEach(formField => {
                formFieldData.push(formField)
            })
            form_access.formFieldRecordsMadate = [];
            form_access.formFieldRecordsOther = [];
            form_access["form_field_records"] = formFieldData;

            //Set field criteria
            if (form_access.fieldOperation.field_id) {
                form_access.field_operations.push(form_access.fieldOperation);
            }
            if (form_access.recordType === "specifyCriteria") {
                form_access["current_record"] = false;
                form_access["field_criteria"] = form_access.field_operations;
            }
            else {
                form_access["current_record"] = true;
                form_access["field_criteria"] = [];
            }
            form_access.fieldOperation = {};
            form_access.field_operations = [];
            actionObj["form_access"] = form_access;
        }
    }



    generateFieldForAction(actionObj) {
        let { selectedFields } = this.state;
        if (selectedFields) {
            let fieldsArr = [];
            selectedFields.split(",").forEach(fieldId => {
                let fieldObj = {
                    field_id: fieldId
                }
                fieldsArr.push(fieldObj);
            })
            actionObj["fields"] = fieldsArr;
        }
    }

    handleStateUpdate(key, value) {
        this.setState({ [key]: value });
    }

    generateSelectedFormFields() {

        let { form_access } = this.state;
        let { workflowData } = this.props;

        //Reset record Data
        this.clearRecords(form_access);

        let fieldListData = form_access.form_key ? workflowData.form_list.filter(form => form.field_option_value === form_access.form_key)[0].field_metadata : [];
        //madatory field data creation
        let filteredMandatoryData = fieldListData.filter(field => field.is_mandatory === "yes" && !(field.group_field)); //fieldListData.filter( field => field.is_mandatory === "yes").forEach(field => {

        filteredMandatoryData.forEach(field => {
            if (!form_access.formFieldRecordsMadate) {
                form_access.formFieldRecordsMadate = [];
            }
            let isPresent = false;
            form_access.formFieldRecordsMadate.forEach(formField => {
                if (formField.field_id === field.id) {
                    isPresent = true;
                }
            })
            if (!isPresent) {
                let optionData = {};
                optionData.field_id = field.id;
                optionData.disabled = true;
                form_access.formFieldRecordsMadate.push(optionData);
            }
        });


        this.setState({ fieldListData: fieldListData, form_access: form_access });
    }

    componentDidMount() {
        let { programkey } = this.props;
        // if (formData.form_field_list && formData.form_field_list.length > 0) {
        //     let fieldList = this.generateFieldListWithGroup(formData.form_field_list);
        //     this.setState({ fieldList: fieldList });
        // }

        // let formElementPromise = FormElementApi.getFormFieldMetadataTypes();
        // formElementPromise.then( data => {
        //     this.setState({ formFieldDetails: data });
        // })

        this.setPaymentWorkflowDataSet();
        this.setLedgerWorkflowDataSet();

        FormApi.getFormListFromProgramKeyAndFormType(programkey, Constants.FORM_TYPE.PARAMETER.code).then(formDataList => {
            this.setState({ parameterFormList: formDataList });
        })

    }

    setPaymentWorkflowDataSet() {
        let { paymentWorkflowList } = this.props;
        let paymentWorkflowData = [];
        paymentWorkflowList.forEach(list => {
            let obj = {
                field_option_value: list.workflow_key,
                field_option_label: list.workflow_name,
            }
            paymentWorkflowData.push(obj);
        })
        this.setState({ paymentWorkflowData: paymentWorkflowData })
    }

    setLedgerWorkflowDataSet() {
        let { ledgerWorkflowList } = this.props;
        let ledgerWorkflowData = [];
        ledgerWorkflowList.forEach(list => {
            let obj = {
                field_option_value: list.workflow_key,
                field_option_label: list.workflow_name,
            }
            ledgerWorkflowData.push(obj);
        })
        this.setState({ ledgerWorkflowData: ledgerWorkflowData })
    }

    generateFieldListWithGroup(form_field_list) {


        console.log("form field", fieldList);
        let fieldList = [];
        let actionFieldList = [];
        form_field_list.forEach(formField => {
            if (formField.group_field) {

                //only for below field type we are saving the field in actionFieldList
                switch (formField.field_type) {
                    case Constants.FieldIds.F_NAME.code:
                    case Constants.FieldIds.F_ADDRESS.code:
                    case Constants.FieldIds.F_SECTION_FIELD_TYPE.code:
                        {
                            let parendObj = {
                                field_option_label: formField.label,
                                field_option_value: formField.id,
                                field_type: formField.field_type,
                                field_key: formField.field_key
                            }
                            actionFieldList.push(parendObj);
                            break;
                        }
                    default: break;
                }

                if (formField.field_option_list) {
                    formField.field_option_list.forEach(fieldOption => {
                        let obj = {
                            field_option_label: fieldOption.field_option_label,
                            field_option_value: formField.id + "#" + fieldOption.field_option_value,
                            field_type: formField.field_type,
                            field_key: formField.field_key
                        }
                        fieldList.push(obj);
                        actionFieldList.push(obj);
                    })
                }
            } else {
                let obj = {
                    field_option_label: formField.label,
                    field_option_value: formField.id,
                    field_type: formField.field_type,
                    field_key: formField.field_key
                }
                fieldList.push(obj);
                //Adding this to ignore child elements in actionFieldList
                if (!formField.parent_id) {
                    actionFieldList.push(obj);
                }

            }
        })
        return {
            "fieldList": fieldList,
            "actionFieldList": actionFieldList,
        };
    }

    clearRecords(form_access) {

        if (this.props.newOrEdit !== "editAction") {
            form_access.formFieldRecordsMadate = [];
            form_access.formFieldRecordsOther = [{}];
            form_access.field_operations = [];
        }


        form_access.fieldOperation = {};
    }

    //Sets fieldList.checked to true if field is present
    setDefaultFieldList(fieldList) {
        let { newChildIndex, stepDataCopy, newOrEdit } = this.props;
        if (newOrEdit === "editAction") {

            let childFields = stepDataCopy.actions[newChildIndex].fields;
            if (childFields && childFields.length > 0) {
                childFields.forEach(field => {
                    fieldList.forEach(list => {
                        if (field.field_id === list.field_option_value) {
                            list["checked"] = true;
                        }
                    })
                })
            }
            return fieldList;
        }
        return fieldList;
    }


    // getPhoneEligibleFieldList(fieldList){
    //     fieldList = this.setDefaultFieldList(fieldList);
    //     fieldList = fieldList.filter( field => {
    //         switch(field.field_type){
    //             case Constants.FieldIds.F_PHONE_TEXT.code: return true;
    //             case Constants.FieldIds.F_NUMBER_TXT.code: return true;
    //             default: return false;
    //         }
    //     });
    //     return fieldList;
    // }

    getCategorizedList(fieldList) {
        fieldList = this.setDefaultFieldList(fieldList);
        let sectionFormData = JSON.parse(JSON.stringify(this.props.sectionFormData));

        sectionFormData.map(sectionData => {
            sectionData.field_key = "sect@" + sectionData.field_key;
        })

        let categoryList = {
            "Form Fields": fieldList,
            "Sectional Form Fields": sectionFormData
        };
        return categoryList;
    }

    getCategorizedFilteredList(fieldList, firstFieldType, secondFieldType) {
        console.log("firstFieldType", firstFieldType);
        let sectionFormData = JSON.parse(JSON.stringify(this.props.sectionFormData));
        fieldList = this.setDefaultFieldList(fieldList);
        let sectionFormFilteredData = [];

        sectionFormData.map(sectionData => {
            sectionData.field_key = "sect@" + sectionData.field_key;
            if (sectionData.field_type === firstFieldType || sectionData.field_type === secondFieldType) {
                sectionFormFilteredData.push(sectionData);
            }

        })

        let categoryList = {
            "Form Fields": fieldList,
            "Sectional Form Fields": sectionFormFilteredData,
        };

        console.log("Field List", fieldList, firstFieldType, secondFieldType);
        return categoryList;
    }

    onInsertFieldClick(event) {
        this.setState({ anchorElem: event.currentTarget });

    }

    onInsertFieldClickEmailSubject(event) {
        this.setState({ anchorEmailSubject: event.currentTarget, anchorEmailContent: "" })
    }

    onInsertFieldClickEmailContent(event) {
        this.setState({ anchorEmailContent: event.currentTarget, anchorEmailSubject: "" })
    }

    renderFieldSelection() {
        let { fieldList, actionFieldList } = this.state;
        return (
            <div>
                <div className="tab-container tab-container-scroll">
                    <Scrollbars style={{ width: '100%', height: '100%' }}>
                        <SelectField
                            label="Select fields"
                            optionsData={actionFieldList} //newOrEdit === "editAction" ? defaultFieldList : fieldList
                            isMultiple={true}
                            radioChangeHandle={(value, errors) => this.handleDataChangeEvent("selectedFields", value, errors)}
                            customStyle="medium-row"
                            isChip={true}
                        />
                    </Scrollbars>
                </div>
                {this.renderSaveButton()}
            </div>
        );
    }

    renderStateSelection() {
        let { state } = this.state;
        let { programState, programSubState } = this.props;
        return (
            <div>
                <div className="tab-container tab-container-scroll">
                    <Scrollbars style={{ width: '100%', height: '100%' }}>
                        <SelectField
                            label='Select State'
                            optionsData={this.convertStateDataToOptionsData(programState)}
                            radioChangeHandle={(value, errors) => this.handleDataChangeEvent("state.state_id", value, errors, null)}
                            customStyle="medium-row"
                            selected_value={state.state_id ? state.state_id : ''}
                        />
                        {state.state_id && (
                            <SelectField
                                label='Select Substate'
                                optionsData={this.convertStateDataToOptionsData(programSubState[state.state_id])}
                                radioChangeHandle={(value, errors) => this.handleDataChangeEvent("state.sub_state_id", value, errors, null)}
                                customStyle="medium-row"
                                selected_value={state.sub_state_id ? state.sub_state_id : ''}
                            />
                        )}
                    </Scrollbars>
                </div>
                {this.renderSaveButton()}
            </div>
        );
    }

    renderPolicyGenerator() {
        let { fieldList, policyGenerator, parameterFormList } = this.state;
        let policyFieldList = fieldList.filter(list => {
            return list.field_type === Constants.FieldIds.F_POLICY_ID.code
        })


        const policyGenList = [
            {
                checked: false,
                field_option_label: "Program",
                field_option_value: Constants.POLICY_GENERATION_MODE.PROGRAM,
            },
            {
                checked: false,
                field_option_label: "Client",
                field_option_value: Constants.POLICY_GENERATION_MODE.CLIENT,
            }
        ]

        let parameterList = [];
        let parameterDataList = [];
        let parameterUpdateList = [];

        parameterFormList &&
            parameterFormList.forEach(formList => {
                let formObj = {
                    checked: false,
                    field_option_label: formList.form_name,
                    field_option_value: formList.form_key,
                }
                parameterList.push(formObj);

                if (policyGenerator.program_parameter_form === formList.form_key) {
                    formList.form_field_list.forEach(fieldList => {
                        if (fieldList.field_type === Constants.FieldIds.F_SINGLE_TEXT.code) {
                            let obj = {
                                checked: false,
                                field_option_label: fieldList.label,
                                field_option_value: fieldList.id,
                            }
                            parameterDataList.push(obj);
                        }
                        if (fieldList.field_type === Constants.FieldIds.F_RADIO_GROUP.code) {
                            let radioObj = {
                                checked: false,
                                field_option_label: fieldList.label,
                                field_option_value: fieldList.id,
                            }
                            parameterUpdateList.push(radioObj);
                        }
                    })
                }
            })

        return (
            <div>
                <div className="tab-container tab-container-scroll">
                    <Scrollbars style={{ width: '100%', height: '100%' }}>
                        <SelectField
                            label='Policy Fields'
                            optionsData={policyFieldList}
                            radioChangeHandle={(value, errors) => this.handleDataChangeEvent("policyGenerator.form_field_id", value, errors, null)}
                            customStyle="medium-row"
                            selected_value={policyGenerator.form_field_id}
                        />
                        <SelectField
                            label='Policy Generation Mode'
                            optionsData={policyGenList}
                            radioChangeHandle={(value, errors) => this.handleDataChangeEvent("policyGenerator.policy_generation_mode", value, errors, null)}
                            customStyle="medium-row"
                            selected_value={policyGenerator.policy_generation_mode}
                        />
                        {
                            policyGenerator.policy_generation_mode === "program" &&
                            <div>
                                <SelectField
                                    label='Select Policy Parameter Form'
                                    optionsData={parameterList}
                                    radioChangeHandle={(value, errors) => this.handleDataChangeEvent("policyGenerator.program_parameter_form", value, errors, null)}
                                    customStyle="medium-row"
                                    selected_value={policyGenerator.program_parameter_form}
                                />

                                <SelectField
                                    label='Policy Parameter Data Field'
                                    optionsData={parameterDataList}
                                    radioChangeHandle={(value, errors) => this.handleDataChangeEvent("policyGenerator.program_parameter_data_field", value, errors, null)}
                                    customStyle="medium-row"
                                    selected_value={policyGenerator.program_parameter_data_field}
                                />

                                <SelectField
                                    label='Policy Parameter Updated Field'
                                    optionsData={parameterUpdateList}
                                    radioChangeHandle={(value, errors) => this.handleDataChangeEvent("policyGenerator.program_parameter_update_field", value, errors, null)}
                                    customStyle="medium-row"
                                    selected_value={policyGenerator.program_parameter_update_field}
                                />
                            </div>
                        }
                    </Scrollbars>
                </div>
                {this.renderSaveButton()}
            </div>
        );
    }

    convertStateDataToOptionsData(programState) {
        let optionsData = [];
        programState.forEach(program => {
            let obj = {
                field_option_label: program.state_name,
                field_option_value: program.id,
                position: program.position,
            }
            optionsData.push(obj);
        })
        return optionsData;
    }

    handleFieldSelected(field) {
        let { sendSms } = this.state;
        sendSms.message += "${" + field.field_key + "}";
        this.setState({ sendSms: sendSms });
    }


    renderSetField() {
        let { formData, workflowData } = this.props;
        let { fieldList, setFieldValueList, actionType, action, paymentWorkflowData } = this.state;

        let paymentAttributesList = [
            {
                field_option_label: "Amount",
                field_option_value: "amount",
            },
            {
                field_option_label: "Merchant Name",
                field_option_value: "merchant_name",
            },
            {
                field_option_label: "Purchase Description",
                field_option_value: "description",
            },
            {
                field_option_label: "Customer Name",
                field_option_value: "customer_name",
            },
            {
                field_option_label: "Email",
                field_option_value: "email",
            },
            {
                field_option_label: "Address",
                field_option_value: "address",
            }
        ]

        return (
            <div>
                <div className="tab-container tab-container-scroll">
                    <Scrollbars style={{ width: '100%', height: '100%' }}>
                        <FieldValueChoice
                            fieldDataList={formData.form_field_list}
                            fieldList={action === Constants.FIELD_ACTIONS.PAYMENT_INIT ? paymentAttributesList : fieldList}
                            checkData={setFieldValueList}
                            workflowData={workflowData}
                            programDataLinkList={this.props.programDataLinkList}
                            sectionFormData={this.props.sectionFormData}
                            parentFieldList={actionType === Constants.ACTIONS_TYPE.PAYMENT_STATUS ? transactionFieldList : fieldList}
                            optionOnChangeEvent={(value, errors) => this.handleDataChangeEvent("setFieldValueList", value, errors)}
                        />
                        {action === Constants.FIELD_ACTIONS.PAYMENT_INIT &&
                            <SelectField
                                label="Select Payment Workflow"
                                optionsData={paymentWorkflowData}
                                radioChangeHandle={(value, errors) => this.handleDataChangeEvent("paymentWorkflowSelect", value, errors)}
                                customStyle="medium-row"
                                selected_value={this.state.paymentWorkflowSelect}
                                isChip={true}
                            />
                        }


                    </Scrollbars>
                </div>
                {this.renderSaveButton()}
            </div>
        )
    }

    renderOpenAssureGateFields() {
        let { formData, workflowData } = this.props;
        let { fieldList, setFieldValueList, actionType, action } = this.state;

        let assureGateMappingList = Constants.ASSUREGATE_FIELD_DATA;

        return (
            <div>
                <div className="tab-container tab-container-scroll">
                    <Scrollbars style={{ width: '100%', height: '100%' }}>
                        <FieldValueChoice
                            fieldDataList={formData.form_field_list}
                            fieldList={action === Constants.FIELD_ACTIONS.OPEN_ASSUREGATE ? assureGateMappingList : fieldList}
                            checkData={setFieldValueList}
                            workflowData={workflowData}
                            programDataLinkList={this.props.programDataLinkList}
                            sectionFormData={this.props.sectionFormData}
                            parentFieldList={actionType === Constants.ACTIONS_TYPE.PAYMENT_STATUS ? transactionFieldList : fieldList}
                            optionOnChangeEvent={(value, errors) => this.handleDataChangeEvent("setFieldValueList", value, errors)}
                        />
                    </Scrollbars>
                </div>
                {this.renderSaveButton()}
            </div>
        )
    }


    // cancel-policy fields
    renderSetCancelField() {
        let { formData, workflowData } = this.props;
        let { setOtherFieldList, fieldList, action, setFieldValueList } = this.state;
        console.log("cancelFields", setFieldValueList);
        let ledgerAttributesList = [
            {
                field_option_label: "Policy Id",
                field_option_value: "policy_id",
            }
        ]

        return (
            <div>
                <div className="tab-container tab-container-scroll">
                    <Scrollbars style={{ width: '100%', height: '100%' }}>
                        <FieldValueChoice
                            fieldDataList={formData.form_field_list}
                            fieldList={action === Constants.FIELD_ACTIONS.CANCEL_POLICY ? ledgerAttributesList : fieldList}
                            checkData={setFieldValueList}
                            workflowData={workflowData}
                            programDataLinkList={this.props.programDataLinkList}
                            sectionFormData={this.props.sectionFormData}
                            // parentFieldList={actionType === Constants.ACTIONS_TYPE.PAYMENT_STATUS ? transactionFieldList : fieldList}
                            parentFieldList={fieldList}
                            optionOnChangeEvent={(value, errors) => this.handleDataChangeEvent("setFieldValueList", value, errors)}
                        />
                        <UpdateLedger
                            checkData={setOtherFieldList}
                            optionOnChangeEvent={(value, errors) => this.handleDataChangeEvent("setOtherFieldList", value, errors)}
                        />
                    </Scrollbars>
                </div>
                {this.renderSaveButton()}
            </div>
        )
    }



    renderSetLedger() {
        let { programDataLinkList, sectionFormData } = this.props;
        let { fieldList, setFieldValueList, ledgerWorkflowData, stakeholdersData, buyPolicy } = this.state;
        console.log("stakeholdersData", stakeholdersData, "checkvalue", setFieldValueList);
        var stakeHolderValue = this.props.programData.stake_holder.insurance_broker.concat(this.props.programData.stake_holder.insurance_company);
        console.log("Payment Data", stakeHolderValue);
        let dataList = {};

        //Adding differentiation for field type i.e. formField, programField and sectionalField
        fieldList.map(field => field.fieldType = Constants.DATA_TYPE.FORM_DATA);
        programDataLinkList && programDataLinkList.map(field => field.fieldType = Constants.DATA_TYPE.PROGRAM_DATA);
        sectionFormData && sectionFormData.map(field => field.fieldType = Constants.DATA_TYPE.SECTIONAL_DATA);

        dataList["Form Fields"] = fieldList;
        dataList["Program Data"] = programDataLinkList ? programDataLinkList : [];
        dataList["Sectional Form Fields"] = sectionFormData ? sectionFormData : [];

        console.log("formfields data", fieldList, programDataLinkList, sectionFormData);
        let stakeHolders = stakeHolderValue.reduce(function (arr, elem) {

            arr.push({
                field_option_label: arrayConst[elem],
                field_option_value: elem,
            })
            return arr;
        }, []);

        let ledgerAttributesList = [
            {
                field_option_label: "Amount",
                field_option_value: "amount",
            },
            {
                field_option_label: "Policy Id",
                field_option_value: "policy_id",
            }
        ]

        return (
            <div>
                <div className="tab-container tab-container-scroll">
                    <Scrollbars style={{ width: '100%', height: '100%' }}>
                        <InputField
                            label="Amount"
                            placeholder="Search fields"
                            customStyle="medium-row"
                            default_value={buyPolicy.amount ? buyPolicy.amount : ""}
                            selected_value={buyPolicy.amount ? buyPolicy.amount : ""}
                            handleOnClickEvent={(event) => this.openSendToDropDownPopover(event, "anchorAmount")}
                            handleOnChangeValue={this.handleInputPopover.bind(this)}
                            fieldStyle="field-box field-adornment bg-plane"
                        />
                        <DropdownPopover
                            fieldStyle="field-box bg-plane field-icon"
                            customStyle="adornment-only-icon"
                            label="Select Icon Upload"
                            handleFieldSelected={(field) => this.handleBuyPolicySelectField(field, "amount")}
                            anchorElem={this.state.anchorAmount}
                            dataList={this.getCategorizedFilteredList(this.filteringFieldsFromFieldList(Constants.FieldIds.F_NUMBER_TXT.code), Constants.FieldIds.F_NUMBER_TXT.code)}
                            handleCloseOfFieldDropdown={this.handleCloseOfFieldDropdown().bind(this)}
                        />
                        <InputField
                            label="Policy Id"
                            placeholder="Search fields"
                            customStyle="medium-row"
                            default_value={buyPolicy.policy_id ? buyPolicy.policy_id : ""}
                            selected_value={buyPolicy.policy_id ? buyPolicy.policy_id : ""}
                            handleOnClickEvent={(event) => this.openSendToDropDownPopover(event, "anchorPolicyId")}
                            handleOnChangeValue={this.handleInputPopover.bind(this)}
                            fieldStyle="field-box field-adornment bg-plane"
                        />
                        <DropdownPopover
                            fieldStyle="field-box bg-plane field-icon"
                            customStyle="adornment-only-icon"
                            label="Select Icon Upload"
                            handleFieldSelected={(field) => this.handleBuyPolicySelectField(field, "policy_id")}
                            anchorElem={this.state.anchorPolicyId}
                            dataList={dataList}
                            handleCloseOfFieldDropdown={this.handleCloseOfFieldDropdown().bind(this)}
                        />
                        <SelectField
                            label="Stake Holder"
                            optionsData={stakeHolders}
                            customStyle="medium-row"
                            selected_value={buyPolicy.stake_holder}
                            radioChangeHandle={(value, errors) => this.handleDataChangeEvent("buyPolicy.stake_holder", value, errors)}
                            isChip={true}
                        />
                        <SelectField
                            label="Select Ledger Workflow"
                            optionsData={ledgerWorkflowData}
                            radioChangeHandle={(value, errors) => this.handleDataChangeEvent("ledgerWorkflowSelect", value, errors)}
                            customStyle="medium-row"
                            selected_value={this.state.ledgerWorkflowSelect}
                            isChip={true}
                        />
                    </Scrollbars>
                </div>
                {this.renderSaveButton()}
            </div>
        )
    }

    renderPDFDownloadIntegration() {
        const { proposalId } = this.state.downloadPdf;

        const handleProposalFieldChange = (field) => {
            this.setState(
                { downloadPdf: { ...this.state.downloadPdf, proposalId: field.field_key } }
            );
        }

        return (
            <>
                <div className="tab-container tab-container-scroll">
                    <Scrollbars style={{ width: '100%', height: '100%' }}>
                        {
                            /* Proposal ID field. This dropdown field filters out all the single
                            line text fields for the user's selection. */
                        }
                        <>
                            <InputField
                                name="proposalId"
                                type="text"
                                label="Proposal ID field"
                                placeholder="Select proposal ID field"
                                selected_value={proposalId}
                                handleOnChangeValue={() => {}}
                                handleOnClickEvent={
                                    (event) => this.openSendToDropDownPopover(
                                        event,
                                        "anchorProposalId"
                                    )
                                }
                            />
                            <DropdownPopover
                                className="field-box bg-plane field-ci"
                                customStyle="adornment-only-icon"
                                label="Select proposal ID field"
                                anchorElem={this.state.anchorProposalId}
                                dataList={
                                    this.getCategorizedFilteredList(
                                        this.filteringFieldsFromFieldList(
                                            Constants.FieldIds.F_SINGLE_TEXT.code
                                        ),
                                        Constants.FieldIds.F_SINGLE_TEXT.code,
                                    )
                                }
                                handleFieldSelected={handleProposalFieldChange}
                                handleCloseOfFieldDropdown={
                                    this.handleCloseOfFieldDropdown().bind(this)
                                }
                            />
                        </>
                    </Scrollbars>
                </div>
                {this.renderSaveButton()}
            </>
        );
    }

    renderRedirectForm() {
        let { workflowData } = this.props;
        let { redirectData } = this.state;
        let typeData = [
            {
                field_option_label: "Form",
                field_option_value: Constants.REDIRECT_TYPE.FORM
            },
            {
                field_option_label: "External Link",
                field_option_value: Constants.REDIRECT_TYPE.URL
            }
        ]

        return (
            <div>
                <div className="tab-container tab-container-scroll">
                    <Scrollbars style={{ width: '100%', height: '100%' }}>
                        <SelectField
                            label="Type"
                            optionsData={typeData}
                            radioChangeHandle={(value, errors) => this.handleDataChangeEvent("redirectData.destination_type", value, errors)}
                            customStyle="medium-row"
                            selected_value={redirectData.destination_type}
                            isChip={true}
                        />
                        {
                            redirectData.destination_type === Constants.REDIRECT_TYPE.FORM &&
                            <SelectField
                                label="Form"
                                optionsData={workflowData.form_list}
                                radioChangeHandle={(value, errors) => this.handleDataChangeEvent("redirectData.form_key", value, errors, () => { this.handleDataChangeEvent("redirectData.redirect_mode", "modal", errors) })}
                                customStyle="medium-row"
                                selected_value={redirectData.form_key}
                                isChip={true}
                            />
                        }
                        {
                            redirectData.destination_type === Constants.REDIRECT_TYPE.URL &&
                            <InputField
                                label="URL"
                                placeholder="https://assurekit.com"
                                customStyle="medium-row"
                                selected_value={redirectData.redirect_url ? redirectData.redirect_url : ""}
                                handleOnChangeValue={(value, errors) => this.handleDataChangeEvent("redirectData.redirect_url", value, errors, () => { this.handleDataChangeEvent("redirectData.redirect_mode", "external_link", errors) })}
                            />
                        }
                    </Scrollbars>
                </div>

                {this.renderSaveButton()}
            </div>
        );
    }

    filteringFieldsFromFieldList(firstFieldType, secondFieldType) {
        let { fieldList } = this.state;
        let filteredList = [];
        let fieldData = JSON.parse(JSON.stringify(fieldList));
        fieldData.forEach(field => {
            if (field.field_type === firstFieldType || field.field_type === secondFieldType) {
                filteredList.push(field);
            }
        })

        console.log(fieldList, firstFieldType, secondFieldType);

        return filteredList;
    }


    modifyEmailListDropDown(key) {
        let { sendEmail } = this.state;
        let sendEmailToArray = sendEmail[key] ? sendEmail[key].split(',') : [];
        let filteredList = this.filteringFieldsFromFieldList(Constants.FieldIds.F_EMAIL_TEXT.code);
        filteredList.forEach(list => {
            list["checked"] = (sendEmailToArray.includes(list.field_option_value));
        })
        return filteredList
    }

    modifySmsListDropDown(key) {
        let { sendSms } = this.state;
        let sendSmsToArray = sendSms[key] ? sendSms[key].split(',') : [];
        let filteredList = this.filteringFieldsFromFieldList(Constants.FieldIds.F_PHONE_TEXT.code, Constants.FieldIds.F_NUMBER_TXT.code);
        filteredList.forEach(list => {
            list["checked"] = (sendSmsToArray.includes(list.field_option_value));
        })
        return filteredList
    }

    handleEmailAtrributesClick = (key) => () => {
        this.setState({ [key]: !this.state[key] }, () => { this.emptyEmailAttributes(key) })
    }

    emptyEmailAttributes(key) {
        console.log("emptyEmailAttributes", key);
        let { sendEmail } = this.state;
        if (!this.state[key]) {
            switch (key) {
                case "ccEmail": sendEmail.cc = null; break;
                case "bccEmail": sendEmail.bcc = null; break;
                case "replyToEmail": sendEmail.reply_to = null; break;
                default: break;
            }
            this.setState({ sendEmail: sendEmail });
        }
    }

    handleFieldSelectedInEmailContent(field) {
        //console.log("workflow", field);
        let { sendEmail } = this.state;
        if (sendEmail.content) {
            sendEmail.content += "<p>" + "${" + field.field_key + "}" + "</p>";
        }
        else {
            sendEmail.content = "<p>" + "${" + field.field_key + "}" + "</p>";
        }
        this.setState({ sendEmail: sendEmail, anchorEmailContent: "" });
    }

    handleFieldSelectedInEmailSubject(field) {
        //console.log("workflow", field);
        let { sendEmail } = this.state;
        if (sendEmail.subject) {
            sendEmail.subject += "${" + field.field_key + "}";
        }
        else {
            sendEmail.subject = "${" + field.field_key + "}";
        }

        this.setState({ sendEmail: sendEmail, anchorEmailSubject: "" });
    }

    handleCloseOfFieldDropdown = () => () => {
        this.setState({
            anchorEmailSubject: '',
            anchorEmailContent: '',
            anchorElem: '', anchorEmailSendTo: '',
            anchorEmailCc: '', anchorEmailBcc: '',
            anchorEmailReplyTo: '',
            anchorSendSMS: '',
            anchorSendInvitation: '',
            anchorAmount: '',
            anchorPolicyId: '',
            anchorProposalId: '',
        });
    }

    openSendToDropDownPopover(event, key) {

        //console.log("drop",event, key);
        this.setState({ [key]: event.currentTarget });
    }

    handleInputPopover() {

    }

    handleEmailSelectField(field, key) {
        console.log("key 123", key);
        let { sendEmail } = this.state;
        //sendEmail[key] += "${"+field.field_key+"}";
        sendEmail[key] = field.field_key;
        this.setState({ sendEmail: sendEmail });
    }

    handleSmsSelectField(field, key) {
        let { sendSms } = this.state;
        //sendEmail[key] += "${"+field.field_key+"}";
        sendSms[key] = field.field_key;
        this.setState({ sendSms: sendSms });
    }

    handleBuyPolicySelectField(field, key) {
        let { buyPolicy } = this.state;
        buyPolicy[key] = field.field_key;
        this.setState({ buyPolicy: buyPolicy });
    }

    handleSendInvitationSelectField(field, key) {
        console.log("key 123", key);
        let { sendInvitation } = this.state;
        //sendEmail[key] += "${"+field.field_key+"}";
        sendInvitation[key] = field.field_key;
        this.setState({ sendInvitation: sendInvitation });
    }

    renderSendEmail() {
        let { sendEmail, ccEmail, bccEmail, replyToEmail, fieldOperation } = this.state;
        console.log("sendEmail", sendEmail);
        // let submitedData = [
        //     {
        //         field_option_label: "Include user submitted data",
        //         field_option_value: "submited_data",
        //         checked: (sendEmail.submitted_data === "submited_data")
        //     }
        // ]
        return (
            <div>
                <div className="tab-container tab-container-scroll">
                    <Scrollbars style={{ width: '100%', height: '100%' }}>
                        <div class="field-with-extra medium-row">
                            {/* <SelectField
                                label="To"
                                optionsData={this.modifyEmailListDropDown("send_to")}
                                customStyle="medium-row"
                                isChip={true}
                                isMultiple={true}
                                default_value={sendEmail.send_to}
                                selected_value={sendEmail.send_to}
                                radioChangeHandle={(value, errors) => this.handleDataChangeEvent("sendEmail.send_to", value, errors)}
                            /> */}

                            <InputField
                                label="Send To"
                                customStyle="medium-row"
                                placeholder="Search fields"
                                default_value={sendEmail.send_to ? sendEmail.send_to : ""}
                                selected_value={sendEmail.send_to ? sendEmail.send_to : ""}
                                handleOnClickEvent={(event) => this.openSendToDropDownPopover(event, "anchorEmailSendTo")}
                                handleOnChangeValue={this.handleInputPopover.bind(this)}
                                fieldStyle="field-box field-adornment bg-plane"
                            />
                            <DropdownPopover
                                fieldStyle="field-box bg-plane field-icon"
                                customStyle="adornment-only-icon"
                                label="Select Icon Upload"
                                handleFieldSelected={(field) => this.handleEmailSelectField(field, "send_to")}
                                anchorElem={this.state.anchorEmailSendTo}
                                dataList={this.getCategorizedFilteredList(this.filteringFieldsFromFieldList(Constants.FieldIds.F_EMAIL_TEXT.code), Constants.FieldIds.F_EMAIL_TEXT.code)}
                                handleCloseOfFieldDropdown={this.handleCloseOfFieldDropdown().bind(this)}
                            />

                            <div className="form-row extra-field medium-row">
                                <div className="btn-wrap">
                                    <Fab variant="extended" size="large" className="btn btn-large btn-plane white-color no-shadow" onClick={this.handleEmailAtrributesClick("ccEmail").bind(this)}>
                                        {ccEmail ? "Remove CC" : "Add CC"}
                                    </Fab>
                                    <Fab variant="extended" size="large" className="btn btn-large btn-plane white-color no-shadow" onClick={this.handleEmailAtrributesClick("bccEmail").bind(this)}>
                                        {bccEmail ? "Remove BCC" : "Add BCC"}
                                    </Fab>
                                    <Fab variant="extended" size="large" className="btn btn-large btn-plane white-color no-shadow" onClick={this.handleEmailAtrributesClick("replyToEmail").bind(this)}>
                                        {replyToEmail ? "Remove Reply-to" : "Add Reply-to"}
                                    </Fab>
                                </div>
                                {
                                    ccEmail &&
                                    <div>

                                        <InputField
                                            label="CC"
                                            placeholder="Search fields"
                                            default_value={sendEmail.cc ? sendEmail.cc : ""}
                                            selected_value={sendEmail.cc ? sendEmail.cc : ""}
                                            handleOnClickEvent={(event) => this.openSendToDropDownPopover(event, "anchorEmailCc")}
                                            handleOnChangeValue={this.handleInputPopover.bind(this)}
                                            fieldStyle="field-box field-adornment bg-plane"
                                        />
                                        <DropdownPopover
                                            fieldStyle="field-box bg-plane field-icon"
                                            customStyle="adornment-only-icon"
                                            label="Select Icon Upload"
                                            handleFieldSelected={(field) => this.handleEmailSelectField(field, "cc")}
                                            anchorElem={this.state.anchorEmailCc}
                                            dataList={this.getCategorizedFilteredList(this.filteringFieldsFromFieldList(Constants.FieldIds.F_EMAIL_TEXT.code), Constants.FieldIds.F_EMAIL_TEXT.code)}
                                            handleCloseOfFieldDropdown={this.handleCloseOfFieldDropdown().bind(this)}
                                        />
                                    </div>
                                }
                                {
                                    bccEmail &&
                                    <div>

                                        <InputField
                                            label="BCC"
                                            placeholder="Search fields"
                                            default_value={sendEmail.bcc ? sendEmail.bcc : ""}
                                            selected_value={sendEmail.bcc ? sendEmail.bcc : ""}
                                            handleOnClickEvent={(event) => this.openSendToDropDownPopover(event, "anchorEmailBcc")}
                                            handleOnChangeValue={this.handleInputPopover.bind(this)}
                                            fieldStyle="field-box field-adornment bg-plane"
                                        />
                                        <DropdownPopover
                                            fieldStyle="field-box bg-plane field-icon"
                                            customStyle="adornment-only-icon"
                                            label="Select Icon Upload"
                                            handleFieldSelected={(field) => this.handleEmailSelectField(field, "bcc")}
                                            anchorElem={this.state.anchorEmailBcc}
                                            dataList={this.getCategorizedFilteredList(this.filteringFieldsFromFieldList(Constants.FieldIds.F_EMAIL_TEXT.code), Constants.FieldIds.F_EMAIL_TEXT.code)}
                                            handleCloseOfFieldDropdown={this.handleCloseOfFieldDropdown().bind(this)}
                                        />
                                    </div>
                                }
                                {
                                    replyToEmail &&
                                    <div>

                                        <InputField
                                            label="Reply To"
                                            placeholder="Search fields"
                                            default_value={sendEmail.reply_to ? sendEmail.reply_to : ""}
                                            selected_value={sendEmail.reply_to ? sendEmail.reply_to : ""}
                                            handleOnClickEvent={(event) => this.openSendToDropDownPopover(event, "anchorEmailReplyTo")}
                                            handleOnChangeValue={this.handleInputPopover.bind(this)}
                                            fieldStyle="field-box field-adornment bg-plane"
                                        />
                                        <DropdownPopover
                                            fieldStyle="field-box bg-plane field-icon"
                                            customStyle="adornment-only-icon"
                                            label="Select Icon Upload"
                                            handleFieldSelected={(field) => this.handleEmailSelectField(field, "reply_to")}
                                            anchorElem={this.state.anchorEmailReplyTo}
                                            dataList={this.getCategorizedFilteredList(this.filteringFieldsFromFieldList(Constants.FieldIds.F_EMAIL_TEXT.code), Constants.FieldIds.F_EMAIL_TEXT.code)}
                                            handleCloseOfFieldDropdown={this.handleCloseOfFieldDropdown().bind(this)}
                                        />
                                    </div>
                                }
                            </div>
                        </div>

                        <div className="field-with-extra">
                            <InputField
                                label="Subject"
                                customStyle="medium-row"
                                default_value={sendEmail.subject}
                                selected_value={sendEmail.subject}
                                handleOnChangeValue={(value, errors) => this.handleDataChangeEvent("sendEmail.subject", value, errors)}
                            />
                            <div className="form-row extra-field medium-row">
                                <div className="btn-wrap">
                                    <Fab variant="extended" size="large" className="btn btn-large btn-plane white-color no-shadow" onClick={(event) => this.onInsertFieldClickEmailSubject(event)}>
                                        Insert fields
                                    </Fab>
                                </div>
                                <DropdownPopover fieldStyle="field-box bg-plane field-icon" customStyle="adornment-only-icon" label="Select Icon Upload"
                                    handleFieldSelected={(field) => this.handleFieldSelectedInEmailSubject(field)} anchorElem={this.state.anchorEmailSubject}
                                    dataList={this.getCategorizedList(this.state.fieldList)} selectedIcon={this.state.selectedIcon} handleCloseOfFieldDropdown={this.handleCloseOfFieldDropdown().bind(this)} />
                            </div>
                        </div>

                        <div className="field-with-extra">
                            <RichTextKendo label="Message" name="message" default_value={sendEmail.content} fieldStyle="field-box bg-plane small-icon" customStyle="medium-row" rowmax="120" handleOnChangeValue={(value) => this.handleDataChangeEvent("sendEmail.content", value, null)} />
                            <div className="form-row extra-field medium-row">
                                <div className="btn-wrap">
                                    <Fab variant="extended" size="large" className="btn btn-large btn-plane white-color no-shadow" onClick={(event) => this.onInsertFieldClickEmailContent(event)}>
                                        Insert fields
                                    </Fab>
                                </div>
                                <DropdownPopover fieldStyle="field-box bg-plane field-icon" customStyle="adornment-only-icon" label="Select Icon Upload"
                                    handleFieldSelected={(field) => this.handleFieldSelectedInEmailContent(field)} anchorElem={this.state.anchorEmailContent}
                                    dataList={this.getCategorizedList(this.state.fieldList)} selectedIcon={this.state.selectedIcon} handleCloseOfFieldDropdown={this.handleCloseOfFieldDropdown().bind(this)} />
                            </div>
                        </div>

                        {/* <CheckBoxField
                            checkData={submitedData}
                            customStyle="state-field-check"
                            checkChangeHandle={(value, errors) => this.handleDataChangeEvent("sendEmail.submitted_data", value, errors)}
                        /> */}
                    </Scrollbars>
                </div>

                {this.renderSaveButton()}
            </div>
        );
    }

    renderSendSms() {
        let { fieldList, sendSms } = this.state;
        return (
            <div>
                <div className="tab-container tab-container-scroll">
                    <Scrollbars style={{ width: '100%', height: '100%' }}>
                        {/* <SelectField
                            label="To"
                            optionsData={this.modifySmsListDropDown("receiver")}
                            customStyle="medium-row"
                            isChip={true}
                            isMultiple={true}
                            selected_value={sendSms.receiver}
                            radioChangeHandle={(value, errors) => this.handleDataChangeEvent("sendSms.receiver", value, errors)}
                        /> */}
                        <InputField
                            label="To"
                            placeholder="Search fields"
                            customStyle="medium-row"
                            default_value={sendSms.receiver ? sendSms.receiver : ""}
                            selected_value={sendSms.receiver ? sendSms.receiver : ""}
                            handleOnClickEvent={(event) => this.openSendToDropDownPopover(event, "anchorSendSMS")}
                            handleOnChangeValue={this.handleInputPopover.bind(this)}
                            fieldStyle="field-box field-adornment bg-plane"
                        />
                        <DropdownPopover
                            fieldStyle="field-box bg-plane field-icon"
                            customStyle="adornment-only-icon"
                            label="Select Icon Upload"
                            handleFieldSelected={(field) => this.handleSmsSelectField(field, "receiver")}
                            anchorElem={this.state.anchorSendSMS}
                            dataList={this.getCategorizedFilteredList(this.filteringFieldsFromFieldList(Constants.FieldIds.F_PHONE_TEXT.code, Constants.FieldIds.F_NUMBER_TXT.code), Constants.FieldIds.F_PHONE_TEXT.code, Constants.FieldIds.F_NUMBER_TXT.code)}
                            handleCloseOfFieldDropdown={this.handleCloseOfFieldDropdown().bind(this)}
                        />
                        <div class="field-with-extra medium-row">
                            <TextAreaField
                                label="Message"
                                placeholder="Text"
                                rowmax="5"
                                customStyle="medium-row"
                                selected_value={sendSms.message}
                                handleOnChangeValue={(value, errors) => this.handleDataChangeEvent("sendSms.message", value, errors)}
                            />
                            <div className="form-row extra-field medium-row">
                                <div className="btn-wrap">
                                    <Fab variant="extended" size="large" className="btn btn-large btn-plane white-color no-shadow" onClick={(event) => this.onInsertFieldClick(event)}>
                                        Insert fields
                                    </Fab>
                                </div>
                                <DropdownPopover fieldStyle="field-box bg-plane field-icon" customStyle="adornment-only-icon" label="Select Icon Upload"
                                    handleFieldSelected={(field) => this.handleFieldSelected(field)} anchorElem={this.state.anchorElem}
                                    dataList={this.getCategorizedList(fieldList)} selectedIcon={this.state.selectedIcon} handleCloseOfFieldDropdown={this.handleCloseOfFieldDropdown().bind(this)} />
                            </div>
                        </div>
                    </Scrollbars>
                </div>

                {this.renderSaveButton()}
            </div>
        );
    }
    renderMobileNotification() {
        let sendSmsConnection = [
            {
                field_option_label: "User 1",
                field_option_value: "user_1"
            },
            {
                field_option_label: "User 2",
                field_option_value: "user_2"
            }
        ]
        return (
            <div>
                <div className="tab-container tab-container-scroll">
                    <Scrollbars style={{ width: '100%', height: '100%' }}>
                        <SelectField
                            label="Select user based on"
                            optionsData={sendSmsConnection}
                            customStyle="medium-row"
                            isChip={true}
                        />
                        <SelectField
                            label="To"
                            optionsData={sendSmsConnection}
                            customStyle="medium-row"
                            isChip={true}
                        />
                        <InputField
                            label="Title"
                            customStyle="medium-row"
                            placeholder="Enter"
                        />
                        <div class="field-with-extra">
                            <TextAreaField
                                label="Message"
                                placeholder="Text"
                                customStyle="medium-row"
                                rowmax="5"
                            />
                            <div className="form-row extra-field medium-row">
                                <div className="btn-wrap">
                                    <Fab variant="extended" size="large" className="btn btn-large btn-plane white-color no-shadow">
                                        Insert fields
                                    </Fab>
                                </div>
                            </div>
                        </div>
                        <SelectField
                            label="View"
                            optionsData={sendSmsConnection}
                            customStyle="medium-row"
                            isChip={true}
                        />
                    </Scrollbars>
                </div>

                {this.renderSaveButton()}
            </div>
        );
    }
    renderSuccessMessage() {
        return (
            <div>
                <div className="tab-container tab-container-scroll">
                    <Scrollbars style={{ width: '100%', height: '100%' }}>
                        <div class="field-with-extra">
                            <TextAreaField
                                label="Message"
                                placeholder="Text"
                                customStyle="medium-row"
                                rowmax="5"
                            />
                            <div className="form-row extra-field medium-row">
                                <div className="btn-wrap">
                                    <Fab variant="extended" size="large" className="btn btn-large btn-plane white-color no-shadow">
                                        Insert fields
                                    </Fab>
                                </div>
                            </div>
                        </div>
                    </Scrollbars>
                </div>

                {this.renderSaveButton()}
            </div>
        );
    }
    renderWarningMessage() {
        let { warning } = this.state;
        let alertType = [
            {
                field_option_value: "success-type",
                field_option_label: "Success",
            },
            {
                field_option_value: "warning-type",
                field_option_label: "Warning",
            },
            {
                field_option_value: "error-type",
                field_option_label: "Error",
            }
        ]
        return (
            <div>
                <div className="tab-container tab-container-scroll">
                    <Scrollbars style={{ width: '100%', height: '100%' }}>
                        <TextAreaField
                            label="Message"
                            placeholder="Text"
                            customStyle="medium-row"
                            rowmax="5"
                            default_value={warning && warning.warning_text}
                            selected_value={warning && warning.warning_text}
                            handleOnChangeValue={(value) => this.handleDataChangeEvent("warning.warning_text", value)}
                        />
                        <SelectField
                            label="Select Alert Type"
                            optionsData={alertType}
                            customStyle="medium-row"
                            isChip={true}
                            default_value={warning && warning.alert_type}
                            selected_value={warning && warning.alert_type}
                            radioChangeHandle={(value, errors) => this.handleDataChangeEvent("warning.alert_type", value, errors)}
                        />
                    </Scrollbars>
                </div>

                {this.renderSaveButton()}
            </div>
        );
    }

    renderSendInvitation() {
        let { fieldList, sendInvitation } = this.state;
        let { permissionList } = this.props;
        let emailFieldList = [];
        fieldList.forEach(field => {
            if (field.field_type === Constants.FieldIds.F_EMAIL_TEXT.code) {
                emailFieldList.push(field);
            }
        })
        return (
            <div>
                <div className="tab-container tab-container-scroll">
                    <Scrollbars style={{ width: '100%', height: '100%' }}>
                        {/* <SelectField
                            label="Select Email fields"
                            optionsData={emailFieldList} //newOrEdit === "editAction" ? defaultFieldList : fieldList
                            selected_value={sendInvitation.email}
                            radioChangeHandle={(value, errors) => this.handleDataChangeEvent("sendInvitation.email", value, errors)}
                            customStyle="medium-row"
                        /> */}

                        <InputField
                            label="Select Email Field"
                            customStyle="medium-row"
                            placeholder="Search fields"
                            default_value={sendInvitation.email ? sendInvitation.email : ""}
                            selected_value={sendInvitation.email ? sendInvitation.email : ""}
                            handleOnClickEvent={(event) => this.openSendToDropDownPopover(event, "anchorSendInvitation")}
                            handleOnChangeValue={this.handleInputPopover.bind(this)}
                            fieldStyle="field-box field-adornment bg-plane"
                        />
                        <DropdownPopover
                            fieldStyle="field-box bg-plane field-icon"
                            customStyle="adornment-only-icon"
                            label="Select Icon Upload"
                            handleFieldSelected={(field) => this.handleSendInvitationSelectField(field, "email")}
                            anchorElem={this.state.anchorSendInvitation}
                            dataList={this.getCategorizedFilteredList(this.filteringFieldsFromFieldList(Constants.FieldIds.F_EMAIL_TEXT.code), Constants.FieldIds.F_EMAIL_TEXT.code)}
                            handleCloseOfFieldDropdown={this.handleCloseOfFieldDropdown().bind(this)}
                        />
                        <SelectField
                            label="Select Permission"
                            optionsData={permissionList}
                            customStyle="medium-row"
                            default_value={sendInvitation && sendInvitation.permission_set_id}
                            selected_value={sendInvitation && sendInvitation.permission_set_id}
                            radioChangeHandle={(value, errors) => this.handleDataChangeEvent("sendInvitation.permission_set_id", value, errors)}
                        />
                    </Scrollbars>

                </div>
                {this.renderSaveButton()}
            </div>
        );
    }


    renderFormDataAction(actionType) {
        let { form_access, fieldListData, formFieldDetails, fieldList } = this.state;
        console.log("Form Access", form_access, fieldListData);
        let { workflowData, formData } = this.props;
        //All Records
        let allFieldList = this.generateFieldListWithGroup(fieldListData).fieldList;

        //get mandatory and other fields
        let mandateFieldList = this.generateFieldListWithGroup(fieldListData.filter(field => (field.is_mandatory === "yes" && field.field_type !== Constants.FieldIds.F_ACTION_BUTTONS.code))).fieldList;
        let otherFieldList = this.generateFieldListWithGroup(fieldListData.filter(field => field.is_mandatory !== "yes" && field.field_type !== Constants.FieldIds.F_ACTION_BUTTONS.code)).fieldList;

        let criteriaType = [
            {
                field_option_label: "Current record",
                field_option_value: "currentRecord",
            },
            {
                field_option_label: "Specify Criteria",
                field_option_value: "specifyCriteria",
            }
        ]

        let ledgerType = [
            {
                field_option_label: "Buy Policy",
                field_option_value: "buy_policy"
            },
            {
                field_option_label: "Cancel Policy",
                field_option_value: "cancel_policy"
            }
        ]


        return (
            <div>
                <div className="tab-container tab-container-scroll">
                    <Scrollbars style={{ width: '100%', height: '100%' }}>
                        {actionType !== Constants.FIELD_ACTIONS.UPDATE_LEDGER &&
                            (
                                <div>
                                    <SelectField
                                        label="Select a form to update a record"
                                        optionsData={workflowData.form_list}
                                        radioChangeHandle={(value, errors) => this.handleDataChangeEvent("form_access.form_key", value, errors, this.generateSelectedFormFields)}
                                        selected_value={form_access.form_key}
                                        default_value={form_access.form_key}
                                        isChip={true}
                                        customStyle="medium-row"
                                    />

                                    {form_access.form_key &&
                                        (
                                            <div>
                                                {((formData.form_key === form_access.form_key) && actionType === Constants.FIELD_ACTIONS.UPDATE_RECORD) &&
                                                    (
                                                        <div>
                                                            <RadioField
                                                                default_value="specifyCriteria"
                                                                radioGroupLabel="Set Criteria: Which records should be updated?"
                                                                radioData={criteriaType}
                                                                selected_value={form_access.recordType}
                                                                label="Set Criteria: Which records should be updated?"
                                                                radioFieldStyle="field-inline"
                                                                radioChangeHandle={(value, errors) => this.handleDataChangeEvent("form_access.recordType", value, errors)}
                                                            />
                                                        </div>

                                                    )}
                                                {((actionType === Constants.FIELD_ACTIONS.DELETE_RECORD) || ((actionType === Constants.FIELD_ACTIONS.UPDATE_RECORD && formData.form_key !== form_access.form_key) || form_access.recordType === "specifyCriteria")) &&
                                                    (
                                                        <div className="drawer-actions">
                                                            <FieldOperation
                                                                fieldList={allFieldList}
                                                                parentFieldList={fieldList}
                                                                programDataLinkList={this.props.programDataLinkList}
                                                                sectionFormData={this.props.sectionFormData}
                                                                formFieldDetails={formFieldDetails}
                                                                fieldDataList={fieldListData}
                                                                stepData={form_access}
                                                                fieldOperation={form_access.fieldOperation}
                                                                workflowData={workflowData}
                                                                handleFieldOperationUpdate={(value) => this.handleDataChangeEvent("form_access.fieldOperation", value)}
                                                                handleStepDataUpdate={(value) => this.handleDataChangeEvent("form_access", value)}
                                                                handleFieldOperationMaster={() => this.handleDataChangeEvent("form_access.fieldOperation", {})}
                                                            />
                                                        </div>
                                                    )}
                                                <div className="form-row">
                                                    <InputLabel className="label">
                                                        Update Fields: Which fields should be updated?
                                                    </InputLabel>
                                                </div>


                                                {actionType !== Constants.FIELD_ACTIONS.UPDATE_RECORD && mandateFieldList.length > 0 &&
                                                    (
                                                        <FieldValueChoice
                                                            fieldDataList={fieldListData}
                                                            fieldList={mandateFieldList}
                                                            checkData={form_access.formFieldRecordsMadate}
                                                            programDataLinkList={this.props.programDataLinkList}
                                                            sectionFormData={this.props.sectionFormData}
                                                            optionOnChangeEvent={(value, errors) => this.handleDataChangeEvent("form_access.formFieldRecordsMadate", value, errors)}
                                                            title="Mandatory Fields"
                                                            isDefault={true}
                                                            isDynamic={false}
                                                            parentFieldList={fieldList} />
                                                    )
                                                }



                                                {otherFieldList.length > 0 &&
                                                    (
                                                        <FieldValueChoice
                                                            fieldDataList={fieldListData}
                                                            fieldList={actionType === Constants.FIELD_ACTIONS.UPDATE_RECORD ? allFieldList : otherFieldList}
                                                            checkData={form_access.formFieldRecordsOther}
                                                            programDataLinkList={this.props.programDataLinkList}
                                                            sectionFormData={this.props.sectionFormData}
                                                            optionOnChangeEvent={(value, errors) => this.handleDataChangeEvent("form_access.formFieldRecordsOther", value, errors)}
                                                            title="Other Fields"
                                                            parentFieldList={fieldList} />
                                                    )
                                                }
                                            </div>
                                        )
                                    }
                                </div>
                            )
                        }
                    </Scrollbars>
                </div>

                {this.renderSaveButton()}
            </div>
        )
    }

    renderSaveButton() {
        return (
            <div className="card-footer border-top">
                <div className="btn-wrap">
                    <Fab variant="extended" size="large" className="btn btn-large btn-plane primary-color" onClick={this.handleSaveAction}>Save</Fab>
                    {/* <Button variant="contained" className="btn primary-btn" onClick={this.handleSaveAction}>Save</Button> */}
                    <Fab variant="extended" size="large" className="btn btn-large btn-plane" onClick={this.props.toggleDrawer}>Cancel</Fab>
                </div>
            </div>
        );
    }



    renderActionSelection() {
        let { actionType } = this.state;
        return (
            <div className="drawer-tabs">
                <AppBar position="static">
                    <Tabs value={actionType} onChange={this.handleChange("actionType").bind(this)}>
                        <Tab label="Notification" value={Constants.ACTIONS_TYPE.NOTIFICATION} icon={<img alt="notification" src={notificationIcon}></img>} />
                        <Tab label="Field Actions" value={Constants.ACTIONS_TYPE.FIELDS_ACTION} icon={<img alt="singleLineIcon" src={singleLineIcon}></img>} />
                        <Tab label="Data Access" value={Constants.ACTIONS_TYPE.DATA_ACCESS} icon={<img alt="cloudIcon" src={cloudIcon}></img>} />
                        <Tab label="Integrations" value={Constants.ACTIONS_TYPE.INTEGRATION} icon={<img alt="integrationIcon" src={integrationIcon}></img>} />
                        {/* <Tab label="Deluge Script" value={Constants.ACTIONS_TYPE.SCRIPT} icon={<img alt="scriptIcon" src={scriptIcon}></img>} /> */}
                    </Tabs>
                </AppBar>
                {actionType === Constants.ACTIONS_TYPE.NOTIFICATION &&
                    <div className="tab-container">
                        <div className="form-row"><label className="label">Select an action</label></div>
                        <div className="actions-wrap actions-tabs-wrap">
                            <div className="actions-block" onClick={this.handleActionChange("action", Constants.FIELD_ACTIONS.SEND_EMAIL)}>
                                <div className="actions-btn btn-round">
                                    <Button>
                                        <img alt="MailIcon" src={MailIcon}></img>
                                    </Button>
                                </div>
                                <div className="actions-info">
                                    <h5>Send Email</h5>
                                </div>
                            </div>
                            <div className="actions-block" onClick={this.handleActionChange("action", Constants.FIELD_ACTIONS.SEND_SMS)}>
                                <div className="actions-btn btn-round">
                                    <Button>
                                        <img alt="MessageIcon" src={MessageIcon}></img>
                                    </Button>
                                </div>
                                <div className="actions-info">
                                    <h5>Send SMS</h5>
                                </div>
                            </div>
                            {/* <div className="actions-block" onClick={this.handleActionChange("action", Constants.FIELD_ACTIONS.MOBILE_NOTIFY)}>
                                <div className="actions-btn btn-round">
                                    <Button>
                                        <img alt="MobPhoneIcon" src={MobPhoneIcon}></img>
                                    </Button>
                                </div>
                                <div className="actions-info">
                                    <h5>Send Mobile Notification</h5>
                                </div>
                            </div> */}
                            {/* <div className="actions-block" onClick={this.handleActionChange("action", Constants.FIELD_ACTIONS.SUCCESS_MESSAGE)}>
                                <div className="actions-btn btn-round">
                                    <Button>
                                        <img alt="TickIcon" src={TickIcon}></img>
                                    </Button>
                                </div>
                                <div className="actions-info">
                                    <h5>Send Success Message</h5>
                                </div>
                            </div> */}
                            <div className="actions-block" onClick={this.handleActionChange("action", Constants.FIELD_ACTIONS.REDIRECT)}>
                                <div className="actions-btn btn-round">
                                    <Button>
                                        <img alt="LinkIcon" src={LinkIcon}></img>
                                    </Button>
                                </div>
                                <div className="actions-info">
                                    <h5>Redirect to URL</h5>
                                </div>
                            </div>
                            <div className="actions-block" onClick={this.handleActionChange("action", Constants.FIELD_ACTIONS.WARNING_MESSAGE)}>
                                <div className="actions-btn btn-round">
                                    <Button>
                                        <img alt="WarningIcon" src={WarningIcon}></img>
                                    </Button>
                                </div>
                                <div className="actions-info">
                                    <h5>Add Message</h5>
                                </div>
                            </div>
                            <div className="actions-block" onClick={this.handleActionChange("action", Constants.FIELD_ACTIONS.SEND_INVITATION)}>
                                <div className="actions-btn btn-round">
                                    <Button>
                                        <img alt="LinkIcon" src={LinkIcon}></img>
                                    </Button>
                                </div>
                                <div className="actions-info">
                                    <h5>Send Invitation</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {actionType === Constants.ACTIONS_TYPE.FIELDS_ACTION &&
                    <div className="tab-container">
                        <div className="form-row"><label className="label">Select an action</label></div>
                        <div className="actions-wrap actions-tabs-wrap">
                            <div className="actions-block" onClick={this.handleActionChange("action", Constants.FIELD_ACTIONS.HIDE)}>
                                <div className="actions-btn btn-round">
                                    <Button>
                                        <img alt="HideIcon" src={HideIcon}></img>
                                    </Button>
                                </div>
                                <div className="actions-info">
                                    <h5>Hide fields</h5>
                                </div>
                            </div>
                            <div className="actions-block" onClick={this.handleActionChange("action", Constants.FIELD_ACTIONS.SHOW)}>
                                <div className="actions-btn btn-round">
                                    <Button>
                                        <img alt="EyeIcon" src={EyeIcon}></img>
                                    </Button>
                                </div>
                                <div className="actions-info">
                                    <h5>Show fields</h5>
                                </div>
                            </div>
                            <div className="actions-block" onClick={this.handleActionChange("action", Constants.FIELD_ACTIONS.ENABLE)}>
                                <div className="actions-btn btn-round">
                                    <Button>
                                        <img alt="EnableIcon" src={EnableIcon}></img>
                                    </Button>
                                </div>
                                <div className="actions-info">
                                    <h5>Enable fields</h5>
                                </div>
                            </div>
                            <div className="actions-block" onClick={this.handleActionChange("action", Constants.FIELD_ACTIONS.DISABLE)}>
                                <div className="actions-btn btn-round">
                                    <Button>
                                        <img alt="DisableIcon" src={DisableIcon}></img>
                                    </Button>
                                </div>
                                <div className="actions-info">
                                    <h5>Disabled fields</h5>
                                </div>
                            </div>
                            <div className="actions-block" onClick={this.handleActionChange("action", Constants.FIELD_ACTIONS.SET)}>
                                <div className="actions-btn btn-round">
                                    <Button>
                                        <img alt="SetFieldIcon" src={SetFieldIcon}></img>
                                    </Button>
                                </div>
                                <div className="actions-info">
                                    <h5>Set fields value</h5>
                                </div>
                            </div>
                            {/* <div className="actions-block" onClick={this.handleActionChange("action", Constants.FIELD_ACTIONS.HIDE)}>
                                <div className="actions-btn btn-round">
                                    <Button>
                                        <img alt="InputIcon" src={InputIcon}></img>
                                    </Button>
                                </div>
                                <div className="actions-info">
                                    <h5>On user input</h5>
                                    <h6 className="font-med">When changing value of a field</h6>
                                </div>
                            </div> */}
                            <div className="actions-block" onClick={this.handleActionChange("action", Constants.FIELD_ACTIONS.STATE)}>
                                <div className="actions-btn btn-round">
                                    <Button>
                                        <img alt="StateIcon" src={StateIcon}></img>
                                    </Button>
                                </div>
                                <div className="actions-info">
                                    <h5>Change State</h5>
                                    <h6 className="font-med">Change State</h6>
                                </div>
                            </div>
                            <div className="actions-block" onClick={this.handleActionChange("action", Constants.FIELD_ACTIONS.POLICY_GENERATOR)}>
                                <div className="actions-btn btn-round">
                                    <Button>
                                        <img alt="StateIcon" src={StateIcon}></img>
                                    </Button>
                                </div>
                                <div className="actions-info">
                                    <h5>Policy Generator</h5>
                                    <h6 className="font-med">Policy Generator</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {actionType === Constants.ACTIONS_TYPE.DATA_ACCESS &&
                    <div className="tab-container">
                        <div className="form-row"><label className="label">Select an action</label></div>
                        <div className="actions-wrap actions-tabs-wrap">
                            <div className="actions-block" onClick={this.handleActionChange("action", Constants.FIELD_ACTIONS.ADD_RECORD)}>
                                <div className="actions-btn btn-round">
                                    <Button>
                                        <img alt="AddIcon" src={AddIcon}></img>
                                    </Button>
                                </div>
                                <div className="actions-info">
                                    <h5>Add record</h5>
                                </div>
                            </div>
                            <div className="actions-block" onClick={this.handleActionChange("action", Constants.FIELD_ACTIONS.UPDATE_RECORD)}>
                                <div className="actions-btn btn-round">
                                    <Button>
                                        <img alt="UpdateIcon" src={UpdateIcon}></img>
                                    </Button>
                                </div>
                                <div className="actions-info">
                                    <h5>Update record</h5>
                                </div>
                            </div>
                            <div className="actions-block" onClick={this.handleActionChange("action", Constants.FIELD_ACTIONS.DELETE_RECORD)}>
                                <div className="actions-btn btn-round">
                                    <Button>
                                        <img alt="DeleteIcon" src={DeleteIcon}></img>
                                    </Button>
                                </div>
                                <div className="actions-info">
                                    <h5>Delete record</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {actionType === Constants.ACTIONS_TYPE.INTEGRATION &&
                    <div className="tab-container">
                        <div className="form-row"><label className="label">Add a record to a service</label></div>
                        <div className="actions-wrap actions-tabs-wrap">
                            {/* <div className="actions-block selected">
                                <div className="actions-btn btn-round">
                                    <Button>
                                        <img alt="notification" src={notificationIcon}></img>
                                    </Button>
                                </div>
                                <div className="actions-info">
                                    <h5>Quickbooks</h5>
                                </div>
                            </div>
                            <div className="actions-block">
                                <div className="actions-btn btn-round">
                                    <Button>
                                        <img alt="singleLineIcon" src={singleLineIcon}></img>
                                    </Button>
                                </div>
                                <div className="actions-info">
                                    <h5>Salesforce</h5>
                                </div>
                            </div>
                            <div className="actions-block">
                                <div className="actions-btn btn-round">
                                    <Button>
                                        <img alt="cloudIcon" src={cloudIcon}></img>
                                    </Button>
                                </div>
                                <div className="actions-info">
                                    <h5>AssureKit CRM</h5>
                                </div>
                            </div>
                            <div className="actions-block">
                                <div className="actions-btn btn-round">
                                    <Button>
                                        <img alt="cloudIcon" src={cloudIcon}></img>
                                    </Button>
                                </div>
                                <div className="actions-info">
                                    <h5>AssureKit Recurit</h5>
                                </div>
                            </div> */}
                            <div className="actions-block" onClick={this.handleActionChange("action", Constants.FIELD_ACTIONS.PAYMENT_INIT)}>
                                <div className="actions-btn btn-round">
                                    <Button>
                                        <img alt="SetFieldIcon" src={SetFieldIcon}></img>
                                    </Button>
                                </div>
                                <div className="actions-info">
                                    <h5>Payment Integration</h5>
                                </div>
                            </div>
                            <div className="actions-block" onClick={this.handleActionChange("action", Constants.FIELD_ACTIONS.OPEN_ASSUREGATE)}>
                                <div className="actions-btn btn-round">
                                    <Button>
                                        <img alt="SetFieldIcon" src={SetFieldIcon}></img>
                                    </Button>
                                </div>
                                <div className="actions-info">
                                    <h5>Assure Gate</h5>
                                </div>
                            </div>
                        </div>

                        {/* { cancel policy vivek } */}
                        <div className="actions-wrap actions-tabs-wrap">
                            <div className="actions-block" onClick={this.handleActionChange("action", Constants.FIELD_ACTIONS.CANCEL_POLICY)}>
                                <div className="actions-btn btn-round">
                                    <Button>
                                        <img alt="SetFieldIcon" src={SetFieldIcon}></img>
                                    </Button>
                                </div>
                                <div className="actions-info">
                                    <h5>Cancel Policy</h5>
                                </div>
                            </div>
                            <div className="actions-block" onClick={this.handleActionChange("action", Constants.FIELD_ACTIONS.BUY_POLICY)}>
                                <div className="actions-btn btn-round">
                                    <Button>
                                        <img alt="SetFieldIcon" src={SetFieldIcon}></img>
                                    </Button>
                                </div>
                                <div className="actions-info">
                                    <h5>Buy Policy</h5>
                                </div>
                            </div>
                        </div>
                        <div className="actions-wrap actions-tabs-wrap">
                            <div className="actions-block" onClick={this.handleActionChange("action", Constants.FIELD_ACTIONS.PDF_DOWNLOAD)}>
                                <div className="actions-btn btn-round">
                                    <Button>
                                        <img src={SetFieldIcon} alt="SetFieldIcon" />
                                    </Button>
                                </div>
                                <div className="actions-info">
                                    <h5>PDF Download</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {actionType === Constants.ACTIONS_TYPE.SCRIPT &&
                    <div className="tab-container">
                        <div className="form-row"><label className="label">Select an event</label></div>
                        <div className="actions-wrap actions-tabs-wrap">
                            <div className="actions-block selected">
                                <div className="actions-btn btn-round">
                                    <Button>
                                        <img alt="notification" src={notificationIcon}></img>
                                    </Button>
                                </div>
                                <div className="actions-info">
                                    <h5>On load</h5>
                                    <h6 className="font-med">On form load</h6>
                                </div>
                            </div>
                            <div className="actions-block">
                                <div className="actions-btn btn-round">
                                    <Button>
                                        <img alt="singleLineIcon" src={singleLineIcon}></img>
                                    </Button>
                                </div>
                                <div className="actions-info">
                                    <h5>On validate</h5>
                                    <h6 className="font-med">Before submitting the form</h6>
                                </div>
                            </div>
                            <div className="actions-block">
                                <div className="actions-btn btn-round">
                                    <Button>
                                        <img alt="cloudIcon" src={cloudIcon}></img>
                                    </Button>
                                </div>
                                <div className="actions-info">
                                    <h5>On success</h5>
                                    <h6 className="font-med">After Successful form submission</h6>
                                </div>
                            </div>
                            <div className="actions-block">
                                <div className="actions-btn btn-round">
                                    <Button>
                                        <img alt="cloudIcon" src={cloudIcon}></img>
                                    </Button>
                                </div>
                                <div className="actions-info">
                                    <h5>On user input</h5>
                                    <h6 className="font-med">When changing value of a field</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {/* {actionType === Constants.ACTIONS_TYPE.PAYMENT &&
                    <div className="tab-container">
                        <div className="form-row"><label className="label">Map Payment details to form fields</label></div>
                            <div className="actions-wrap actions-tabs-wrap">

                            </div>
                    </div>
                } */}
            </div>
        )
    }


    renderActionType() {
        return (
            <div className="form-row">
                <label htmlFor="" className="label">Action type</label>
                <div className="actions-wrap">
                    <div className="actions-block" onClick={this.handleActionChange("actionType", Constants.ACTIONS_TYPE.NOTIFICATION)}>
                        <div className="actions-btn">
                            <Button className="apricot-color">
                                <img alt="notification" src={notificationIcon}></img>
                            </Button>
                        </div>
                        <div className="actions-info">
                            <h3>Notification</h3>
                            <p>Notify through email, SMS, Redirect to URL</p>
                        </div>
                    </div>
                    <div className="actions-block" onClick={this.handleActionChange("actionType", Constants.ACTIONS_TYPE.FIELDS_ACTION)}>
                        <div className="actions-btn">
                            <Button className="primary-color">
                                <img alt="singleLineIcon" src={singleLineIcon}></img>
                            </Button>
                        </div>
                        <div className="actions-info">
                            <h3>Field actions</h3>
                            <p>Field actions like show fields and enable fields</p>
                        </div>
                    </div>
                    <div className="actions-block" onClick={this.handleActionChange("actionType", Constants.ACTIONS_TYPE.DATA_ACCESS)}>
                        <div className="actions-btn">
                            <Button className="pink-color">
                                <img alt="cloudIcon" src={cloudIcon}></img>
                            </Button>
                        </div>
                        <div className="actions-info">
                            <h3>Data access</h3>
                            <p>Add, update records in another form</p>
                        </div>
                    </div>
                    <div className="actions-block" onClick={this.handleActionChange("actionType", Constants.ACTIONS_TYPE.INTEGRATION)}>
                        <div className="actions-btn">
                            <Button className="cornflower-color">
                                <img alt="integrationIcon" src={integrationIcon}></img>
                            </Button>
                        </div>
                        <div className="actions-info">
                            <h3>Integrations</h3>
                            <p>Push submitted data to another service</p>
                        </div>
                    </div>
                    {
                        // this.props.formData.form_type !== Constants.FORM_TYPE.SECTIONAL.code &&
                        // <div className="actions-block" onClick={this.handleActionChange("actionType", Constants.ACTIONS_TYPE.PAYMENT)}>
                        //     <div className="actions-btn">
                        //         <Button className="cornflower-color">
                        //             <img alt="integrationIcon" src={integrationIcon}></img>
                        //         </Button>
                        //     </div>
                        //     <div className="actions-info">
                        //         <h3>Payment</h3>
                        //         <p>Map payment details to your form field</p>
                        //     </div>
                        // </div>
                    }
                    {/* <div className="actions-block" onClick={this.handleActionChange("actionType", Constants.ACTIONS_TYPE.SCRIPT)}>
                        <div className="actions-btn">
                            <Button className="green-color">
                                <img alt="scriptIcon" src={scriptIcon}></img>
                            </Button>
                        </div>
                        <div className="actions-info">
                            <h3>Deluge Script</h3>
                            <p>Write script in events like on load, on success</p>
                        </div>
                    </div> */}
                </div>
            </div>
        );
    }

    renderActionForm() {
        switch (this.state.action) {
            case Constants.FIELD_ACTIONS.HIDE:
            case Constants.FIELD_ACTIONS.SHOW:
            case Constants.FIELD_ACTIONS.ENABLE:
            case Constants.FIELD_ACTIONS.DISABLE: return this.renderFieldSelection();
            case Constants.FIELD_ACTIONS.STATE: return this.renderStateSelection();
            case Constants.FIELD_ACTIONS.POLICY_GENERATOR: return this.renderPolicyGenerator();
            case Constants.FIELD_ACTIONS.SET: return this.renderSetField();
            case Constants.FIELD_ACTIONS.PAYMENT_INIT: return this.renderSetField();
            case Constants.FIELD_ACTIONS.OPEN_ASSUREGATE: return this.renderOpenAssureGateFields();
            case Constants.FIELD_ACTIONS.BUY_POLICY: return this.renderSetLedger();
            case Constants.FIELD_ACTIONS.PDF_DOWNLOAD: return this.renderPDFDownloadIntegration();
            case Constants.FIELD_ACTIONS.CANCEL_POLICY: return this.renderSetCancelField();
            case Constants.FIELD_ACTIONS.REDIRECT: return this.renderRedirectForm();
            case Constants.FIELD_ACTIONS.SEND_EMAIL: return this.renderSendEmail();
            case Constants.FIELD_ACTIONS.SEND_SMS: return this.renderSendSms();
            case Constants.FIELD_ACTIONS.MOBILE_NOTIFY: return this.renderMobileNotification();
            case Constants.FIELD_ACTIONS.SUCCESS_MESSAGE: return this.renderSuccessMessage();
            case Constants.FIELD_ACTIONS.WARNING_MESSAGE: return this.renderWarningMessage();
            case Constants.FIELD_ACTIONS.SEND_INVITATION: return this.renderSendInvitation();
            case Constants.FIELD_ACTIONS.ADD_RECORD: return this.renderFormDataAction(Constants.FIELD_ACTIONS.ADD_RECORD);
            case Constants.FIELD_ACTIONS.UPDATE_RECORD: return this.renderFormDataAction(Constants.FIELD_ACTIONS.UPDATE_RECORD);
            case Constants.FIELD_ACTIONS.UPDATE_LEDGER: return this.renderFormDataAction(Constants.FIELD_ACTIONS.UPDATE_LEDGER);
            case Constants.FIELD_ACTIONS.DELETE_RECORD: return this.renderFormDataAction(Constants.FIELD_ACTIONS.DELETE_RECORD);
            case Constants.FIELD_ACTIONS.PAYMENT_SUCCESS: return this.renderSetField();
            case Constants.FIELD_ACTIONS.PAYMENT_FAIL: return this.renderSetField();
            case Constants.FIELD_ACTIONS.LEDGER_SUCCESS: return this.renderSetField();
            case Constants.FIELD_ACTIONS.LEDGER_FAIL: return this.renderSetField();
            default: break;
        }
    }

    render() {
        let { conditionValue, fieldList, stepData, fieldOperation, formFieldDetails, actionFieldList } = this.state;
        let { workflowData, formData, newOrEdit, stepDataList } = this.props;

        let conditionList = [
            {
                field_option_label: "Always",
                field_option_value: "always",
            },
            {
                field_option_label: "On Condition",
                field_option_value: "condition"
            }
        ]
        return (
            <section className="setup-drawer-sec">
                <div className="card-block">
                    <div className="card-header">
                        <div className="card-subheader">
                            <div className="card-left-title">
                                {
                                    this.state.actionType &&
                                    newOrEdit !== "editAction" &&
                                    <div className="actions setup-close" style={{ marginLeft: '-15px' }}>
                                        <div className="btn-wrap">
                                            <Fab variant="extended" onClick={this.handleBackClick.bind(this)} size="small" className="btn btn-small btn-plane btn-fab white-color no-shadow">
                                                <img alt="edit" src={ArrowBackGrey} style={{ margin: 0 }}></img> Go Back
                                            </Fab>
                                        </div>
                                    </div>
                                }
                            </div>
                            <div className="actions setup-close">
                                <div className="btn-wrap">
                                    <Fab variant="extended" size="small" className="btn btn-small btn-plane btn-fab white-color no-shadow" onClick={this.props.toggleDrawer}>
                                        Close <img alt="edit" src={closeIcon}></img>
                                    </Fab>
                                </div>
                            </div>
                        </div>
                    </div>
                    {
                        this.state.action !== "payment-success" || this.state.action !== "ledger-success" || this.state.action !== "ledger-fail" &&
                        this.state.action &&
                        <div className="card-breadcrum">
                            <Breadcrumbs separator="›" aria-label="breadcrumb">
                                <Link color="inherit" href="#" onClick={(event) => this.handleBackClick(event, "actionType")}>
                                    Add New Action
                                </Link>
                                <Link color="inherit" href="#" onClick={(event) => this.handleBackClick(event, "action")}>
                                    {Constants.ACTIONS_TYPE_NAME[this.state.actionType]}
                                </Link>
                                <Link color="inherit" href="#">
                                    {Constants.FIELD_ACTIONS_NAME[this.state.action]}
                                </Link>
                            </Breadcrumbs>
                        </div>
                    }
                    {
                        !this.state.action &&
                        <div className="card-header border-bottom" style={{ padding: "8px 0px 25px" }}>
                            <div className="card-title">
                                <h2>Add New Action</h2>
                            </div>
                        </div>
                    }
                    {
                        this.state.action &&
                        <div className="card-header border-bottom" style={{ padding: "8px 0px 25px" }}>
                            <div className="card-title">
                                <h2>{Constants.FIELD_ACTIONS_NAME[this.state.action]}</h2>
                            </div>
                        </div>
                    }
                    <div className="card-body">
                        <div role="presentation" className="drawer-sec">
                            <div className="drawer-form-sec">
                                {
                                    !this.state.actionType ?
                                        (
                                            <div>
                                                <div className="tab-container tab-container-scroll" style={{ height: 'calc(100vh - 185px)' }}>
                                                    <Scrollbars style={{ width: '100%', height: '100%' }}>
                                                        <div className="drawer-actions">
                                                            {

                                                                newOrEdit === "new" &&
                                                                <RadioField
                                                                    default_value="always"
                                                                    radioGroupLabel="Validation"
                                                                    radioData={conditionList}
                                                                    selected_value={conditionValue}
                                                                    label="Validation"
                                                                    radioFieldStyle="field-inline"
                                                                    radioChangeHandle={this.conditionChangeHandle}
                                                                />

                                                            }
                                                        </div>
                                                        <div className="drawer-actions">
                                                            {this.state.conditionValue === 'condition' && newOrEdit !== "edit" ?
                                                                <FieldOperation
                                                                    fieldList={fieldList}
                                                                    formFieldDetails={formFieldDetails}
                                                                    fieldDataList={formData.form_field_list}
                                                                    stepData={stepData}
                                                                    stepDataList={stepDataList}
                                                                    fieldOperation={fieldOperation}
                                                                    workflowData={workflowData}
                                                                    programDataLinkList={this.props.programDataLinkList}
                                                                    sectionFormData={this.props.sectionFormData}
                                                                    handleFieldOperationUpdate={(value) => this.handleStateUpdate("fieldOperation", value)}
                                                                    handleStepDataUpdate={(value) => this.handleStateUpdate("stepData", value)}
                                                                    handleFieldOperationMaster={this.handleFieldOperationMaster().bind(this)}
                                                                />
                                                                : ""
                                                            }
                                                        </div>
                                                        <div className="drawer-actions">
                                                            {this.renderActionType()}
                                                        </div>
                                                    </Scrollbars>
                                                </div>
                                            </div>
                                        ) : (
                                            !this.state.action ?
                                                <div className="drawer-actions">
                                                    {this.renderActionSelection()}
                                                </div>
                                                : (
                                                    this.renderActionForm()
                                                )
                                        )
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }

}

export default WorkflowSetups;
