import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import SearchField from '../../components/fields/search';
import AddIcon from '@material-ui/icons/Add';
import Fab from '@material-ui/core/Fab';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import SelectField from '../../components/fields/select';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import CloseIcon from '../../assets/images/icon/close.svg';
import Slide from '@material-ui/core/Slide';
import ArrowBackGrey from '../../assets/images/icon/chevron-left.svg';
import InputField from '../../components/fields/inputs';
import BuildIllust from '../../assets/images/illustration/build-iso.svg';
import TextAreaField from '../../components/fields/textarea';
import SectionalIllus from '../../assets/images/illustration/sectional.svg';
import EventBasedIllus from '../../assets/images/illustration/event.svg';
import OnClickIllus from '../../assets/images/illustration/on-click.svg';
import ScratchIllus from '../../assets/images/illustration/scratch.svg';
import { Scrollbars } from 'react-custom-scrollbars';

//Custom component
import TableComponent from '../../components/common/table-component';
import Header from '../../components/header';
import WorkflowHeader from '../../components/workflow/workflow-header';
import UrlTabActions from '../../components/common/url-tab-actions';
import SwitchTabActions from '../../components/common/switch-tab-actions';
import MultipleTabActions from '../../components/common/multiple-tab-actions';
import AutoComplete from '../../components/fields/autocomplete-single-select';

//API
import * as FormApi from '../../api/form-api';
import * as ProgramApi from '../../api/program-api';
import * as FormTemplateApi from '../../api/form-template-api';

//Constants
import * as Constants from '../../utilities/constants';

const tableRowsforSection = [
    { id: 'form_name', numeric: false, disablePadding: false, label: 'Name' },
    { id: 'section', numeric: false, disablePadding: false, label: 'Section' },
    { id: 'entries', numeric: true, disablePadding: false, label: 'Entries' },
    //{ id: 'status', numeric: true, disablePadding: false, label: 'Status' },
    { id: 'created_on', numeric: true, disablePadding: false, label: 'Created on' },
    { id: 'actions', numeric: true, disablePadding: false, label: 'Actions' },
];

const tableRows = [
    { id: 'form_name', numeric: false, disablePadding: false, label: 'Name' },
    { id: 'entries', numeric: true, disablePadding: false, label: 'Entries' },
    //{ id: 'status', numeric: true, disablePadding: false, label: 'Status' },
    { id: 'created_on', numeric: true, disablePadding: false, label: 'Created on' },
    { id: 'actions', numeric: true, disablePadding: false, label: 'Actions' },
];

function createSectionData(index, form, sectionList, editFormRedirect, recordsFormRedirect, previewFormRedirect, handleStatusChange, dublicateForm) {
    let selectedSection = {
        field_option_label: form.section_name,
        field_option_value: form.section_id,
    };

    let formNameView = (<UrlTabActions formName={form.form_name} handleNameClick={editFormRedirect(form.form_key)} />);
    let sectionName = form.section_name;
    let recordCount = (<div className="entries-shift"><UrlTabActions formName={form.record_count} withImg={false} handleNameClick={recordsFormRedirect(form.form_key)} /></div>);
    //let statusView = (<SwitchTabActions status={(form.form_status === "active")? "enabled" : "disabled"} handleStatusChange={handleStatusChange(form.id,form.form_status)}/>);
    let actionView = (<MultipleTabActions type="icons" handleEditClick={editFormRedirect(form.form_key)} handelDuplicateClick={dublicateForm(form.form_key)} iconList={["edit"]} />)
    let created_on = form.created_on;
    return { formNameView, sectionName, recordCount, created_on, actionView };
}

function createData(index, form, sectionList, editFormRedirect, recordsFormRedirect, previewFormRedirect, handleStatusChange, dublicateForm) {
    let selectedSection = {
        field_option_label: form.section_name,
        field_option_value: form.section_id,
    };

    // sectionList.unshift(selectedSection);
    let formNameView = (<UrlTabActions formName={form.form_name} handleNameClick={editFormRedirect(form.form_key)} />);
    // let sectionView = "";
    // if(form.form_type === Constants.FORM_TYPE.SECTIONAL.code){
    //     sectionView = (<div className="auto-select">
    //                     <SelectField
    //                         optionsData={sectionList}
    //                         is_search={false}
    //                         selected_value={form.section_id}
    //                     />
    //                 </div>);
    // }

    let recordCount = (<div className="entries-shift"><UrlTabActions formName={form.record_count} withImg={false} handleNameClick={recordsFormRedirect(form.form_key)} /></div>);
    //let statusView = (<SwitchTabActions status={(form.form_status === "active")? "enabled" : "disabled"} handleStatusChange={handleStatusChange(form.id,form.form_status)}/>);
    let actionView = (<MultipleTabActions type="icons" handleEditClick={editFormRedirect(form.form_key)} handelDuplicateClick={dublicateForm(form.form_key)} iconList={["edit", "duplicate"]} />)
    let created_on = form.created_on;
    return { formNameView, recordCount, created_on, actionView };
}

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
class FormDashboard extends Component {

    state = {
        formList: [],
        sectionalTableData: [],
        actionTableData: [],
        onClickTableData: [],
        tabAction: 0,
        selectedTriggerType: "",
        formTypeDialogOpen: false,
        formDialogOpen: false,
        form: {},
        customStyleSearch: '',
        sectionOption: [],
        dataReceived: false,
        listOfSectionalForms: [],
        formTemplateList: []
    }

    constructor(props) {
        super(props);
        this.getAvailSectionData(this.getAllFormData);
    }

    componentWillMount() {
        this.getAllFormTemplate();
        if (this.props.location && this.props.location.state) {
            if (this.props.location.state.id) {
                this.setState({ formTypeDialogOpen: true })
            }
            else {
                this.setState({ formTypeDialogOpen: false })
            }
        }
    }

    getAllFormTemplate() {
        FormTemplateApi.getAllFormTemplate().then(res => {
            let formTemplateList = [];
            res.forEach(template => {
                let obj = {
                    field_option_label: template.name,
                    field_option_value: template.id
                }
                formTemplateList.push(obj);
            })
            this.setState({ formTemplateList: formTemplateList });
        })
    }

    addSpecificForm = (key) => () => {
        this.setState({ [key]: true })
    }

    handleDialogOpen(type) {
        this.setState({ [type]: true });
    }

    handleClose = (type) => (event) => {
        this.setState({ [type]: false });
    }

    handleOnChangeValue = (fieldType) => (value) => {
        let { form } = this.state;
        form[fieldType] = value;
        this.setState({ form: form });
    }

    handleSubmitEvent = (event) => {
        const match = this.props.match;
        let programkey = match.params.programkey;
        let formData = this.state.form;
        formData["program_key"] = programkey;
        formData["template_id"] = formData.template_id?.field_option_value;
        if (formData.form_name) {
            FormApi.postFormData(formData).then(res => {
                // this.getAllFormData();
                this.editForm(res.form_key);
                this.setState({ formTypeDialogOpen: false, formDialogOpen: false })
            });
        }

    }

    createNewForm() {
        this.handleDialogOpen("formTypeDialogOpen");
    }
    selectTriggerHandle = (triggerType) => (event) => {
        let { form } = this.state;
        form["form_type"] = triggerType;
        this.setState({ selectedTriggerType: triggerType, form: form });
        this.handleClose("formTypeDialogOpen");
        this.handleDialogOpen("formDialogOpen");
        // this.setWorkflowFormData("workflow_type", triggerType);
    }
    getAllFormData() {
        const match = this.props.match;
        let programkey = match.params.programkey;
        FormApi.getAllFormData(programkey).then(res => {
            let actionTableData = [];
            let sectionalTableData = [];
            let onClickTableData = [];
            res.forEach((form, index) => {
                switch (form.form_type) {
                    case Constants.FORM_TYPE.SECTIONAL.code: {
                        this.createSectionalFormList(form);
                        sectionalTableData.push(createSectionData(index, form, this.state.sectionOption, this.editFormRedirect, this.recordsFormRedirect, this.previewFormRedirect, this.handleStatusChange, this.handleDublicateData));
                        break;
                    }
                    case Constants.FORM_TYPE.ONCLICK.code: {
                        onClickTableData.push(createData(index, form, this.state.sectionOption, this.editFormRedirect, this.recordsFormRedirect, this.previewFormRedirect, this.handleStatusChange, this.handleDublicateData));
                        break;
                    }
                    case Constants.FORM_TYPE.ACTION.code: {
                        actionTableData.push(createData(index, form, this.state.sectionOption, this.editFormRedirect, this.recordsFormRedirect, this.previewFormRedirect, this.handleStatusChange, this.handleDublicateData));
                        break;
                    }
                    default: break;
                }
            })
            this.setState({ formList: res, actionTableData: actionTableData, sectionalTableData: sectionalTableData, onClickTableData: onClickTableData, dataReceived: true });
        });
    }

    createSectionalFormList(form) {
        let { listOfSectionalForms } = this.state;
        let formObj = {
            checked: false,
            field_option_value: form.id,
            field_option_label: form.form_name,
        }

        listOfSectionalForms.push(formObj);
        this.setState({ listOfSectionalForms: listOfSectionalForms });
    }

    getAvailSectionData(callback) {
        const match = this.props.match;
        let programkey = match.params.programkey;
        ProgramApi.getAvalibaleSection(programkey).then(sections => {
            let sectionOption = [];
            console.log("sections", sections);
            sections.forEach(section => {
                let obj = {
                    field_option_label: section.section_name,
                    field_option_value: section.id
                }
                sectionOption.push(obj);
            })
            this.setState({ sectionOption: sectionOption }, callback);
        })
    }

    generateTable(formId, status) {
        FormApi.updateFormStatus(formId, status).then(res => {
            this.getAllFormData();
        });

        // let {formList} = this.state;
        // let actionTableData = [];
        // let sectionalTableData = [];
        // formList.forEach( (form, index) =>{
        //     switch(form.form_type){
        //         case Constants.FORM_TYPE.SECTIONAL.code: {
        //             sectionalTableData.push(createData(index, form.form_key, form.form_name, form.form_desc, form.created_on, form.form_status === "Active" ? "Active": "Inactive", this.editFormRedirect, this.recordsFormRedirect, this.handleStatusChange));
        //             break;
        //         }
        //         case Constants.FORM_TYPE.ACTION.code: {
        //             actionTableData.push(createData(index, form.form_key, form.form_name, form.form_desc, form.created_on, form.form_status === "Active" ? "Active": "Inactive", this.editFormRedirect, this.recordsFormRedirect, this.handleStatusChange));
        //             break;
        //         }
        //         default: break;
        //     }
        // })         
        // this.setState({ actionTableData: actionTableData, sectionalTableData: sectionalTableData });


    }

    handleDublicateData = (key) => event => {
        const match = this.props.match;
        let programkey = match.params.programkey;
        let form = JSON.parse(JSON.stringify(this.state.formList))
        form = form.filter(formData => { return (formData.form_key === key) })[0];
        form["program_key"] = programkey;
        form["created_on"] = null;
        form["modified_on"] = null;
        console.log("form key ", form);
        FormApi.duplicateForm(form).then(res => {
            console.log("duplicated form", res);
            this.getAllFormData();
        })
    }

    editFormRedirect = key => event => {
        event.stopPropagation();
        this.editForm(key);
    }

    editForm = key => {
        const match = this.props.match;
        let programkey = match.params.programkey;
        let clientId = match.params.clientId;
        window.location = "/" + clientId + "/" + programkey + "/form/" + key + "/builder";
    }

    previewFormRedirect = key => event => {
        event.stopPropagation();
        const match = this.props.match;
        let programkey = match.params.programkey;
        let clientId = match.params.clientId;
        window.location = "/" + clientId + "/" + programkey + "/form/" + key + "/preview";
    }

    recordsFormRedirect = key => event => {
        event.stopPropagation();
        const match = this.props.match;
        let programkey = match.params.programkey;
        let clientId = match.params.clientId;
        window.location = "/" + clientId + "/" + programkey + "/form/" + key + "/records";
    }

    handleStatusChange = (formId, status) => event => {
        // event.stopPropagation();
        // let {formList} = this.state;
        status = (status === "active") ? "inactive" : "active";
        this.generateTable(formId, status);
        // formList[index].form_status = (formList[index].form_status === "Active") ? 1 : 0;
        // this.setState({formList:formList}, () => this.generateTable());
    }

    handleActionChange(event, newValue) {
        this.setState({ tabAction: newValue });
    }

    searchBtnClick() {
        let { formList, customStyleSearch } = this.state;
        let actionTableData = [];
        let sectionalTableData = [];
        let onClickTableData = [];

        console.log("formList2223", formList, "customStyleSearch", customStyleSearch);
        let searchedFormData = formList.filter(form => (form.form_name.toLowerCase().includes(customStyleSearch.toLowerCase())));
        console.log("searchedFormData", searchedFormData);

        if (searchedFormData && searchedFormData.length > 0) {
            searchedFormData.forEach((form, index) => {
                switch (form.form_type) {
                    case Constants.FORM_TYPE.SECTIONAL.code: {
                        this.createSectionalFormList(form);
                        sectionalTableData.push(createSectionData(index, form, this.state.sectionOption, this.editFormRedirect, this.recordsFormRedirect, this.previewFormRedirect, this.handleStatusChange, this.handleDublicateData));
                        break;
                    }
                    case Constants.FORM_TYPE.ONCLICK.code: {
                        onClickTableData.push(createData(index, form, this.state.sectionOption, this.editFormRedirect, this.recordsFormRedirect, this.previewFormRedirect, this.handleStatusChange, this.handleDublicateData));
                        break;
                    }
                    case Constants.FORM_TYPE.ACTION.code: {
                        actionTableData.push(createData(index, form, this.state.sectionOption, this.editFormRedirect, this.recordsFormRedirect, this.previewFormRedirect, this.handleStatusChange, this.handleDublicateData));
                        break;
                    }
                    default: break;
                }
            })
            this.setState({ actionTableData: actionTableData, sectionalTableData: sectionalTableData, onClickTableData: onClickTableData });
        }
    }

    handleSearchOnChangeValue(event) {
        let { formList } = this.state;
        if (event.target.value === "") {
            if (formList && formList.length > 0) {
                let actionTableData = [];
                let sectionalTableData = [];
                let onClickTableData = [];
                formList.forEach((form, index) => {
                    switch (form.form_type) {
                        case Constants.FORM_TYPE.SECTIONAL.code: {
                            this.createSectionalFormList(form);
                            sectionalTableData.push(createSectionData(index, form, this.state.sectionOption, this.editFormRedirect, this.recordsFormRedirect, this.previewFormRedirect, this.handleStatusChange, this.handleDublicateData));
                            break;
                        }
                        case Constants.FORM_TYPE.ONCLICK.code: {
                            onClickTableData.push(createData(index, form, this.state.sectionOption, this.editFormRedirect, this.recordsFormRedirect, this.previewFormRedirect, this.handleStatusChange, this.handleDublicateData));
                            break;
                        }
                        case Constants.FORM_TYPE.ACTION.code: {
                            actionTableData.push(createData(index, form, this.state.sectionOption, this.editFormRedirect, this.recordsFormRedirect, this.previewFormRedirect, this.handleStatusChange, this.handleDublicateData));
                            break;
                        }
                        default: break;
                    }
                })
                this.setState({ actionTableData: actionTableData, sectionalTableData: sectionalTableData, onClickTableData: onClickTableData });

            }
        }
        console.log("event.target.value", event.target.value);
        this.setState({ customStyleSearch: event.target.value });
    }

    render() {
        let { sectionalTableData, onClickTableData, actionTableData, tabAction, formTypeDialogOpen, formDialogOpen, form, customStyleSearch, sectionOption, dataReceived, formTemplateList } = this.state;
        const match = this.props.match;
        let clientId = match.params.clientId;
        let programkey = match.params.programkey;
        console.log("render()", this.state.selectedTriggerType, Constants.FORM_TYPE.SECTIONAL.code);
        return (
            <div className="FormBuilderScene">
                <Header headerType="dashboard" positionType="fixed" programKey={programkey} />
                <WorkflowHeader workflowType="normal" clientId={clientId} programKey={programkey} tab="build" />
                <main>
                    {
                        <div className="workflow-fields-sec workflow-fields-dashboard">
                            {/* <Grid container spacing={24}>
                            <Grid item sm={10}/>
                            <Grid item xs={12} sm={2}>
                                <Button variant="contained" color="primary" > 
                                    Create New Form
                                </Button>
                            </Grid>
                        </Grid> */}
                            <div className="drawer-tabs drawer-tabs-round no-drawer-indicator workflow-tabs-wrap">
                                <div className="drawer-tabs-header">
                                    <Tabs value={tabAction} onChange={this.handleActionChange.bind(this)}>
                                        <Tab label="Sectional" />
                                        <Tab label="On Click" />
                                        <Tab label="Action" />
                                    </Tabs>
                                    <div className="workflow-tabs-search">
                                        <SearchField placeholder="Search here" searchType="normal" customStyle={customStyleSearch} handleOnChangeValue={this.handleSearchOnChangeValue.bind(this)} searchBtnClick={this.searchBtnClick.bind(this)} />
                                        {/* href={"/create-form"} */}
                                        <Fab variant="extended" size="large" className="btn btn-large workflow-add-btn no-shadow" onClick={this.createNewForm.bind(this)}>
                                            <AddIcon />
                                            New Form
                                        </Fab>
                                    </div>
                                </div>
                                {tabAction === 0 &&
                                    <div className="tab-container">
                                        {sectionalTableData.length > 0 && (
                                            <div className="table-wrapper">
                                                <TableComponent
                                                    tableLabel={false}
                                                    rows={tableRowsforSection}
                                                    isPagginated={true}
                                                    isSearchable={false}
                                                    data={sectionalTableData}
                                                    searchLabel="Search for Forms"
                                                    isCheckBoxEnabled={false}
                                                    isToolboxPresent={false}
                                                />
                                            </div>
                                        )}
                                        {dataReceived && sectionalTableData.length <= 0 && (
                                            <section className="blank-page-sec">
                                                <div className="blank-page-wrap">
                                                    <div className="blank-page-icon">
                                                        <img src={SectionalIllus} alt="SectionalIllus" />
                                                    </div>
                                                    <div className="blank-page-info">
                                                        <h5>You haven't added any Sectional forms yet!</h5>
                                                        <h6>Create section forms and add fields to it according to your business requirements</h6>
                                                    </div>
                                                    <Fab variant="extended" size="large" className="btn btn-large workflow-add-btn no-shadow primary-color" onClick={this.selectTriggerHandle(Constants.FORM_TYPE.SECTIONAL.code).bind(this)}>
                                                        Add Sectional Form
                                                    </Fab>
                                                </div>
                                            </section>
                                        )}
                                    </div>}
                                {tabAction === 1 &&
                                    <div className="tab-container">
                                        {onClickTableData.length > 0 && (
                                            <div className="table-wrapper">
                                                <TableComponent
                                                    tableLabel={false}
                                                    rows={tableRows}
                                                    isPagginated={true}
                                                    isSearchable={false}
                                                    data={onClickTableData}
                                                    searchLabel="Search for Forms"
                                                    isCheckBoxEnabled={false}
                                                    isToolboxPresent={false}
                                                />
                                            </div>
                                        )}
                                        {dataReceived && onClickTableData.length <= 0 && (
                                            <section className="blank-page-sec">
                                                <div className="blank-page-wrap">
                                                    <div className="blank-page-icon">
                                                        <img src={OnClickIllus} alt="OnClickIllus" />
                                                    </div>
                                                    <div className="blank-page-info">
                                                        <h5>You haven't added any On-Click forms yet!</h5>
                                                        <h6>Create on-click forms and add fields to it according to your business requirements</h6>
                                                    </div>
                                                    <Fab variant="extended" size="large" className="btn btn-large workflow-add-btn no-shadow primary-color" onClick={this.selectTriggerHandle(Constants.FORM_TYPE.ONCLICK.code).bind(this)}>
                                                        Add Click Form
                                                    </Fab>
                                                </div>
                                            </section>
                                        )}
                                    </div>}
                                {tabAction === 2 &&
                                    <div className="tab-container">
                                        {actionTableData.length > 0 && (
                                            <div className="table-wrapper">
                                                <TableComponent
                                                    tableLabel={false}
                                                    rows={tableRows}
                                                    isPagginated={true}
                                                    isSearchable={false}
                                                    data={actionTableData}
                                                    searchLabel="Search for Forms"
                                                    isCheckBoxEnabled={false}
                                                    isToolboxPresent={false}
                                                />
                                            </div>
                                        )}
                                        {dataReceived && actionTableData.length <= 0 && (
                                            <section className="blank-page-sec">
                                                <div className="blank-page-wrap">
                                                    <div className="blank-page-icon">
                                                        <img src={EventBasedIllus} alt="EventBasedIllus" />
                                                    </div>
                                                    <div className="blank-page-info">
                                                        <h5>You haven't added any Action forms yet!</h5>
                                                        <h6>Create action forms and add actions to it according to your business requirements</h6>
                                                    </div>
                                                    <Fab variant="extended" size="large" className="btn btn-large workflow-add-btn no-shadow primary-color" onClick={this.selectTriggerHandle(Constants.FORM_TYPE.ACTION.code).bind(this)}>
                                                        Add Action Form
                                                    </Fab>
                                                </div>
                                            </section>
                                        )}
                                    </div>}
                            </div>

                            {/* Dailog for trigger type */}
                            <Dialog open={formTypeDialogOpen} onClose={this.handleClose("formTypeDialogOpen")} TransitionComponent={Transition} maxWidth="xl">
                                <section className="workflow-dialog workflow-dialog-large">
                                    <div className="card-block">
                                        <div className="card-header border-bottom">
                                            <div className="sub-title">
                                                <h5>Form Builder</h5>
                                            </div>
                                            <div className="close-dialog">
                                                <img src={CloseIcon} alt="" onClick={this.handleClose("formTypeDialogOpen")}></img>
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <Grid container>
                                                <Grid item md={3}>
                                                    <div className="sub-title">
                                                        <h2>What type of Form do you want to build?</h2>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                            <div className="workflow-dialog-options workflow-dialog-options-3">
                                                <div className="worflow-dialog-wrap">
                                                    <div className="workflow-dialog-img">
                                                        <Button variant="contained" color="primary" onClick={this.selectTriggerHandle(Constants.FORM_TYPE.SECTIONAL.code).bind(this)}>
                                                            <img src={SectionalIllus} alt="SectionalIllus"></img>
                                                        </Button>
                                                    </div>
                                                    <h5>Sectional</h5>
                                                </div>
                                                <div className="worflow-dialog-wrap">
                                                    <div className="workflow-dialog-img">
                                                        <Button variant="contained" color="primary" onClick={this.selectTriggerHandle(Constants.FORM_TYPE.ACTION.code).bind(this)}>
                                                            <img src={EventBasedIllus} alt="EventBasedIllus"></img>
                                                        </Button>
                                                    </div>
                                                    <h5>Action Based</h5>
                                                </div>
                                                <div className="worflow-dialog-wrap">
                                                    <div className="workflow-dialog-img">
                                                        <Button variant="contained" color="primary" onClick={this.selectTriggerHandle(Constants.FORM_TYPE.ONCLICK.code).bind(this)}>
                                                            <img src={OnClickIllus} alt="OnClickIllus"></img>
                                                        </Button>
                                                    </div>
                                                    <h5>On-Click</h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </Dialog>
                            {/* Dialog for workflow create form */}
                            <Dialog open={formDialogOpen} onClose={this.handleClose("formDialogOpen")} TransitionComponent={Transition} maxWidth="xl">
                                <section className="workflow-dialog workflow-dialog-large">
                                    <div className="card-block">
                                        <div className="card-header border-bottom">
                                            <div className="sub-title">
                                                <div className="close-dialog">
                                                    <Button color="inherit" onClick={this.handleClose("formDialogOpen")}>
                                                        <img src={ArrowBackGrey} alt=""></img> Go Back
                                                    </Button>
                                                </div>
                                            </div>
                                            <div className="close-dialog">
                                                <img src={CloseIcon} alt="" onClick={this.handleClose("formDialogOpen")}></img>
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <Grid container>
                                                <Grid item md={4}>
                                                    <div className="workflow-illust-wrap">
                                                        <div className="sub-title">
                                                            <h2>How would you like to create your form?</h2>
                                                        </div>
                                                        <div className="workflow-illust">
                                                            <img src={BuildIllust} alt="BuildIllust" />
                                                        </div>
                                                    </div>
                                                </Grid>
                                                <Grid item md={8}>
                                                    <div className="workflow-dialog-forms form-builder-dashboard">
                                                        <div className="tabs-container tabs-container-scroll">
                                                            <Scrollbars style={{ width: '100%', height: '100%' }}>
                                                                <div className="tabs-container-scroll-wrap">
                                                                    <Grid item md={12}>
                                                                        <div className="workflow-form-scene form-row">
                                                                            <div className="workflow-form-select active">
                                                                                <Button variant="contained" color="primary" className="btn">
                                                                                    <img src={ScratchIllus} alt="ScratchIllus"></img>
                                                                                </Button>
                                                                                <h5>Scratch</h5>
                                                                            </div>
                                                                            {/* <div className="workflow-form-select">
                                                                            <Button variant="contained" color="primary" className="btn">
                                                                                <img src={TemplateIllus} alt="TemplateIllus"></img>
                                                                            </Button>
                                                                            <h5>Template</h5>
                                                                        </div> */}
                                                                            {this.state.selectedTriggerType === Constants.FORM_TYPE.SECTIONAL.code && (
                                                                                <div className="workflow-form-info">
                                                                                    <h6 className="sub-title semi-bold">Create a Sectional form from scratch?</h6>
                                                                                    <h6 className="grey-color weight-300">You can create a sectional form from scratch by adding fields to it</h6>
                                                                                </div>
                                                                            )}
                                                                            {this.state.selectedTriggerType === Constants.FORM_TYPE.ONCLICK.code && (
                                                                                <div className="workflow-form-info">
                                                                                    <h6 className="sub-title semi-bold">Create an On-Click form from scratch?</h6>
                                                                                    <h6 className="grey-color weight-300">You can create an on-click form from scratch by adding fields to it and assigning it to buttons</h6>
                                                                                </div>
                                                                            )}
                                                                            {this.state.selectedTriggerType === Constants.FORM_TYPE.ACTION.code && (
                                                                                <div className="workflow-form-info">
                                                                                    <h6 className="sub-title semi-bold">Create an Action form from scratch?</h6>
                                                                                    <h6 className="grey-color weight-300">You can create an action form from scratch by adding fields to it and adding custom actions</h6>
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                    </Grid>
                                                                    <div className="workflow-form-field">
                                                                        {this.state.selectedTriggerType === Constants.FORM_TYPE.SECTIONAL.code && (
                                                                            <Grid container lg={12} md={12}>
                                                                                <Grid item lg={5} md={8}>
                                                                                    <SelectField optionsData={sectionOption} is_search={false} fieldStyle="field-box bg-plane grey-color" label="Section" radioChangeHandle={this.handleOnChangeValue("section_id").bind(this)} selected_value={form.section_id} />
                                                                                </Grid>
                                                                                <Grid item lg={5} md={8}>
                                                                                    <AutoComplete
                                                                                        id="template"
                                                                                        label="Select Template"
                                                                                        fieldStyle="field-box bg-plane grey-color"
                                                                                        optionsData={formTemplateList}
                                                                                        value={form.template_id}
                                                                                        onChange={this.handleOnChangeValue("template_id").bind(this)}
                                                                                        noOptionsText={"No template available"}
                                                                                    />
                                                                                </Grid>
                                                                            </Grid>
                                                                        )}
                                                                        {this.state.selectedTriggerType === Constants.FORM_TYPE.ONCLICK.code && (
                                                                            <Grid item md={5}>
                                                                                <SelectField optionsData={this.state.listOfSectionalForms} is_search={false} fieldStyle="field-box bg-plane grey-color" label="Select a Sectional Form" radioChangeHandle={this.handleOnChangeValue("sectional_form_id").bind(this)} selected_value={form.sectional_form_id} />
                                                                            </Grid>
                                                                        )}
                                                                        <Grid item md={5}>
                                                                            <InputField label="Name of the Form" handleOnChangeValue={this.handleOnChangeValue("form_name").bind(this)} selected_value={form.form_name} type="text" name="form_name" fieldStyle="field-box bg-plane grey-color" placeholder="Name" />
                                                                        </Grid>
                                                                        <Grid item md={5}>
                                                                            <TextAreaField label="Description" handleOnChangeValue={this.handleOnChangeValue("form_desc").bind(this)} selected_value={form.form_desc} name="form_description" rowmax="3" fieldStyle="field-box bg-plane grey-color" placeholder="Description" />
                                                                        </Grid>
                                                                    </div>
                                                                </div>
                                                            </Scrollbars>
                                                        </div>
                                                        <div className="form-row workflow-dialog-footer">
                                                            <div className="btn-wrap no-border">
                                                                <Fab variant="extended" size="large" className="btn btn-large btn-plane primary-color" onClick={this.handleSubmitEvent}>
                                                                    Create Form
                                                                </Fab>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </div>
                                    </div>
                                </section>
                            </Dialog>
                        </div>
                    }
                </main>
            </div>
        )
    }
}

export default FormDashboard;