import React, { Component } from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import InputField from '../fields/inputs';
import TextAreaField from '../fields/textarea';
import SelectField from '../fields/select';
import Minimize from '@material-ui/icons/Minimize';
import Fab from '@material-ui/core/Fab';
import AddIcon from '../../assets/images/icon/add-blue.svg';
import FieldAdditionSubtraction from './field-addition-subtraction';

//Constants
import * as FormConstant from '../../utilities/constants'

/* Start 7/1/2020: Kuldeep Changes For Ledger */
let arrayConst = {
    "new-india-assurance": "New India Assurance",
    "icici-lombard": "ICICI Lombard",
    "apollo-munich": "Apollo Munich",
    "hdfc-ergo": "HDFC ERGO",
    "marsh-mclennan": "Marsh & McLennan",
    "india-nivesh": "India Nivesh",
    "unicorn-insurance": "Unicorn Insurance",
    "howden-insurance": "Howden Insurance Brokers India Private Limited",
    "optima-insurance-brokers": "Optima Insurance Brokers Pvt. Ltd",
    "iffco-tokio-general-insurance": "Iffco Tokio General Insurance",
    "unilight-insurance-brokers": "Unilight Insurance Brokers"
}
/* End 7/1/2020: Kuldeep Changes For Ledger */


const insuranceCompanySections = [
    {
        field_option_label: "New India Assurance",
        field_option_value: "new-india-assurance",
    },
    {
        field_option_label: "ICICI Lombard",
        field_option_value: "icici-lombard"
    },
    {
        field_option_label: "Apollo Munich",
        field_option_value: "apollo-munich"
    },
    {
        field_option_label: "HDFC ERGO",
        field_option_value: "hdfc-ergo"
    },
    {
        field_option_label: "Iffco Tokio General Insurance",
        field_option_value: "iffco-tokio-general-insurance"
    }
]

const brokerSection = [
    {
        field_option_label: "Marsh & McLennan",
        field_option_value: "marsh-mclennan",
    },
    {
        field_option_label: "India Nivesh",
        field_option_value: "india-nivesh",
    },
    {
        field_option_label: "Unicorn Insurance",
        field_option_value: "unicorn-insurance",
    },
    {
        field_option_label: "Howden Insurance Brokers India Private Limited",
        field_option_value: "howden-insurance"
    },
    {
        field_option_label: "Optima Insurance Brokers Pvt. Ltd",
        field_option_value: "optima-insurance-brokers",
    },
    {
        field_option_label: "Unilight Insurance Brokers",
        field_option_value: "unilight-insurance-brokers",
    }
]
const clientSection = [
    {
        field_option_label: "Croma",
        field_option_value: "croma",
    },
    {
        field_option_label: "Vijay Sales",
        field_option_value: "vijay-sales",
    },
    {
        field_option_label: "TCS",
        field_option_value: "tcs",
    },
    {
        field_option_label: "SeaSpeed Marine",
        field_option_value: "seaspeed",
    },
    {
        field_option_label: "Vega Helmets",
        field_option_value: "vega-helmets",
    },
    {
        field_option_label: "Senco",
        field_option_value: "senco",
    }
]
const policyForSection = [
    // {
    //     field_option_label: "Croma",
    //     field_option_value: "croma",
    // },
    // {
    //     field_option_label: "Vijay Sales",
    //     field_option_value: "vijay-sales",
    // },
    // {
    //     field_option_label: "TCS",
    //     field_option_value: "tcs",
    // },
    // {
    //     field_option_label: "SeaSpeed Marine",
    //     field_option_value: "seaspeed",
    // },
    {
        field_option_label: "Customers",
        field_option_value: "customers",
    },
    {
        field_option_label: "Employees",
        field_option_value: "employees",
    },
    // {
    //     field_option_label: "Assets",
    //     field_option_value: "assets",
    // }
]
class AddFieldsComponent extends Component {

    state = {
        individualCover: [],
        groupCover: [],
        apdPayment: [],
        isChecked: true,
    }

    emptyObjectCreation(positionValue) {
        let obj = {
            cover_name: "",
            limit_of_liability: "",
            detectable: "",
            exclusion: "",
            description: "",
            //position: positionValue,
        }
        return obj;
    }

    emptyPaymentObjectCreation(positionValue) {
        let obj = {
            amount: "",
            amount_currency: "rs",
            warning_amount_currency: "rs",
            warning_amount: "",
            minimum_amount_currency: "rs",
            minimum_amount: "",
            stake_holder: "",
        }
        return obj;
    }
    componentWillMount() {
        let { individualCover, groupCover, apdPayment } = this.state;
        let { coverageData, paymentData } = this.props;
        let groupArray = [];
        let apdPay = [];


        if (paymentData) {
            if (paymentData.hasOwnProperty("apd")) {
                apdPayment = apdPay.push(paymentData.apd);
            } else {
                //creating initial object for apdPayment
                apdPayment.push(this.emptyPaymentObjectCreation(apdPayment.length));
            }
            this.setState({ apdPayment: apdPayment });
        }

        if (coverageData) {

            if (coverageData.hasOwnProperty("individual_cover")) {
                individualCover = coverageData.individual_cover;
            }
            if (coverageData.hasOwnProperty("group_cover")) {
                groupCover = coverageData.group_cover;
            }
            else {

                //Creating initial object for Individual Cover.
                individualCover.push(this.emptyObjectCreation(individualCover.length));

                //Creating initial object for Group Cover.
                groupArray.push(this.emptyObjectCreation(individualCover.length - 1));
                let groupObj = {
                    group_name: "",
                    group_list: groupArray,
                }
                groupCover.push(groupObj);

                // coverageData["individual_cover"] = individualCover;
                // coverageData["group_cover"] = groupCover;
                //this.props.handleCoverageDataChange(coverageData);

            }
        }
        this.setState({ individualCover: individualCover, groupCover: groupCover });
    }

    handleApdAddition = (indexIndividual, key, indexGroup) => event => {
        let { apdPayment } = this.state;
        let { paymentData } = this.props;

        apdPayment.push(this.emptyPaymentObjectCreation(apdPayment.length));
        paymentData[key] = apdPayment;
        this.props.handlePaymentDataChange(paymentData);
        this.setState({ [key]: apdPayment });
    }

    handleApdDeletion = (indexIndividual, key, indexGroup) => event => {
        let { apdPayment } = this.state;
        let { paymentData } = this.props;

        if (apdPayment.length > 1) {
            apdPayment.splice(indexIndividual, 1);
            paymentData[key] = apdPayment;
            this.props.handlePaymentDataChange(paymentData);
            this.setState({ [key]: apdPayment });
        }
    }

    handleIndividualAddition = (indexIndividual, key, indexGroup) => event => {
        let { individualCover, groupCover } = this.state;
        let { coverageData } = this.props;
        if (key === "individual_cover") {
            individualCover.push(this.emptyObjectCreation(individualCover.length));
            coverageData[key] = individualCover;
            this.props.handleCoverageDataChange(coverageData);
            this.setState({ [key]: individualCover });
        }
        else if (key === "group_cover") {
            groupCover[indexGroup].group_list.push(this.emptyObjectCreation(groupCover[indexGroup].group_list.length));
            coverageData[key] = groupCover;
            this.props.handleCoverageDataChange(coverageData);
            this.setState({ [key]: groupCover });
        }
    }

    handleIndividualDeletion = (indexIndividual, key, indexGroup) => event => {
        let { individualCover, groupCover } = this.state;
        let { coverageData } = this.props;

        if (key === "individual_cover") {
            if (individualCover.length > 1) {
                individualCover.splice(indexIndividual, 1);
                coverageData[key] = individualCover;
                this.props.handleCoverageDataChange(coverageData);
                this.setState({ [key]: individualCover });
            }
        }
        else if (key === "group_cover") {
            if (groupCover[indexGroup].group_list.length > 1) {
                groupCover[indexGroup].group_list.splice(indexIndividual, 1);
                coverageData[key] = groupCover;
                this.props.handleCoverageDataChange(coverageData);
                this.setState({ [key]: groupCover });
            }
        }
    }

    handleGroupAddition() {
        let groupCover = this.state.groupCover;
        let { coverageData } = this.props;
        let testArray = [];
        testArray.push(this.emptyObjectCreation(groupCover[groupCover.length - 1].group_list.length));

        let groupObj = {
            group_name: "",
            group_list: testArray,
        }

        groupCover.push(groupObj);
        coverageData["group_cover"] = groupCover;
        this.props.handleCoverageDataChange(coverageData);
        this.setState({ groupCover: groupCover });
    }

    handleGroupDeletion = (index) => (event) => {
        let groupCover = this.state.groupCover;
        let { coverageData } = this.props;

        //There should be atleast one item in the Individual Cover.
        if (groupCover.length > 1) {
            groupCover.splice(index, 1);
        }
        coverageData["group_cover"] = groupCover;
        this.props.handleCoverageDataChange(coverageData);
        this.setState({ groupCover: groupCover });
    }

    handleApdPaymentMethodChanges = (key, type, indexIndividual) => (value) => {
        let { apdPayment } = this.state;
        let { paymentData, programDetails } = this.props;
        if (key === "apd") {
            apdPayment[indexIndividual][type] = value;
            apdPayment[indexIndividual]["new"] = true;
            apdPayment[indexIndividual]['publish'] = programDetails["program_published"];
            paymentData[key] = apdPayment;
        }
        this.props.handlePaymentDataChange(paymentData);
        this.setState({ apdPayment: apdPayment });
    }

    selectFieldChange(event) {
        this.setState({ value: event.target.value })
    }

    handleIndividualChange = (key, type, indexIndividual, indexGroup) => (value) => {

        let { individualCover, groupCover } = this.state;
        let { coverageData } = this.props;

        if (key === "individual_cover") {
            individualCover[indexIndividual][type] = value;
            coverageData[key] = individualCover;
        }
        else if (key === "group_cover") {
            if (type === "group_name") {
                groupCover[indexGroup][type] = value;
                coverageData[key] = groupCover;
            }
            else {
                groupCover[indexGroup].group_list[indexIndividual][type] = value;
                coverageData[key] = groupCover;
            }
        }


        this.props.handleCoverageDataChange(coverageData);
        this.setState({ individualCover: individualCover, groupCover: groupCover });
    }

    apdField(indexIndividual, key, indexGroup, apd) {
        let { apdPayment } = this.state;
        /* Start 7/1/2020: Kuldeep Changes For Ledger */
        var stakeHolderValue = this.props.programData.stake_holder.insurance_broker.concat(this.props.programData.stake_holder.insurance_company);
        let stakeHolders = stakeHolderValue.reduce(function (arr, elem) {

            arr.push({
                field_option_label: arrayConst[elem],
                field_option_value: elem,
            })
            return arr;
        }, []);
        /* End 7/1/2020: Kuldeep Changes For Ledger */
        let defalutSections = [
            {
                field_option_label: "\u20B9",
                field_option_value: "rs",
            },
            {
                field_option_label: "\u0024",
                field_option_value: "usd"
            },
            {
                field_option_label: "\u00A5",
                field_option_value: "yen"
            },
        ]
        return (
            <div className="add-multiple-wrap-sec">
                <div className="add-field-row add-field-btn">
                    <div className="added-field-grp">
                        <Fab variant="extended" size="small" className="btn btn-small btn-plane secondary-color btn-fab no-shadow " onClick={this.
                            handleApdAddition(indexIndividual, key, indexGroup)}>
                            <img src={AddIcon} alt="AddIcon" />
                        </Fab>
                        <Fab variant="extended" size="small" className="btn btn-small btn-plane pink-color btn-fab no-shadow btn-delete" onClick={this.handleApdDeletion(indexIndividual, key, indexGroup)}>
                            <Minimize />
                        </Fab>
                    </div>
                </div>
                <div className="add-field-row">
                    <div className="added-field-grp">
                        <div className="added-field side-select-field">

                            <InputField
                                label="Amount"
                                type="text"
                                name="amount"
                                adornment="start"
                                adornmentContent={<SelectField optionsData={defalutSections} default_value="rs" selected_value={apd != null && (apd.amount_currency ? apd.amount_currency : "rs")} radioChangeHandle={this.handleApdPaymentMethodChanges(key, "amount_currency", indexIndividual).bind(this)} />}
                                fieldStyle="field-box field-adornment bg-plane light-grey-text-color"
                                placeholder="Enter"
                                selected_value={apd != null ? apd.amount : ''}
                                handleOnChangeValue={this.handleApdPaymentMethodChanges(key, "amount", indexIndividual).bind(this)}
                            />
                            <InputField
                                label="Warning Amount"
                                type="text"
                                name="warning_amount"
                                adornment="start"
                                adornmentContent={<SelectField optionsData={defalutSections} default_value="rs" selected_value={apd != null && (apd.warning_amount_currency ? apd.warning_amount_currency : "rs")} radioChangeHandle={this.handleApdPaymentMethodChanges(key, "warning_amount_currency", indexIndividual).bind(this)} />}
                                fieldStyle="field-box field-adornment bg-plane  light-grey-text-color"
                                placeholder="Enter"
                                selected_value={apd != null ? apd.warning_amount : ''}
                                handleOnChangeValue={this.handleApdPaymentMethodChanges(key, "warning_amount", indexIndividual).bind(this)}
                            />
                        </div>
                    </div>
                    <div className="added-field-grp">
                        <div className="added-field side-select-field">
                            <InputField
                                label="Minimum Amount"
                                type="text"
                                name="minimum_amount"
                                adornment="start"
                                adornmentContent={<SelectField optionsData={defalutSections} default_value="rs" selected_value={apd != null && (apd.minimum_amount_currency ? apd.minimum_amount_currency : "rs")} radioChangeHandle={this.handleApdPaymentMethodChanges(key, "minimum_amount_currency", indexIndividual).bind(this)} />}
                                fieldStyle="field-box field-adornment bg-plane light-grey-text-color"
                                placeholder="Enter"
                                selected_value={apd != null ? apd.minimum_amount : ''}
                                handleOnChangeValue={this.handleApdPaymentMethodChanges(key, "minimum_amount", indexIndividual).bind(this)}
                            />

                            <SelectField
                                label="Stake Holder"
                                optionsData={stakeHolders}
                                name="stake_holder"
                                is_search={false}
                                fieldStyle="field-box bg-plane grey-color"
                                default_value={apd != null && apd.stake_holder != null ? apd.stake_holder : []}
                                selected_value={apd != null ? apd.stake_holder : []}
                                radioChangeHandle={this.handleApdPaymentMethodChanges(key, "stake_holder", indexIndividual).bind(this)}
                            />
                        </div>
                    </div>
                    <div className="added-field-grp">
                        <div className="added-field side-select-field">
                            <InputField
                                label="UTR No."
                                type="text"
                                name="utr_no "
                                fieldStyle="field-box bg-plane grey-color"
                                default_value={apd != null && apd.utr_no != null ? apd.utr_no : ""}
                                selected_value={apd != null ? apd.utr_no : ""}
                                placeholder="Enter"
                                handleOnChangeValue={this.handleApdPaymentMethodChanges(key, "utr_no", indexIndividual).bind(this)}
                            />
                            <InputField
                                label="Instrument"
                                type="text"
                                name="instrument"
                                fieldStyle="field-box bg-plane grey-color"
                                default_value={apd != null && apd.instrument != null ? apd.instrument : ""}
                                selected_value={apd != null ? apd.instrument : ""}
                                placeholder="Enter"
                                handleOnChangeValue={this.handleApdPaymentMethodChanges(key, "instrument", indexIndividual).bind(this)}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }


    groupField(indexIndividual, key, indexGroup, coverData) {
        return (
            <div className="add-multiple-wrap-sec">
                <div className="add-field-row add-field-btn">
                    <div className="added-field-grp">
                        <Fab variant="extended" size="small" className="btn btn-small btn-plane secondary-color btn-fab no-shadow " onClick={this.handleIndividualAddition(indexIndividual, key, indexGroup)}>
                            <img src={AddIcon} alt="AddIcon" />
                        </Fab>
                        <Fab variant="extended" size="small" className="btn btn-small btn-plane pink-color btn-fab no-shadow btn-delete" onClick={this.handleIndividualDeletion(indexIndividual, key, indexGroup)}>
                            <Minimize />
                        </Fab>
                    </div>
                </div>
                <div className="add-field-row">
                    <div className="added-field-grp">
                        <div className="added-field">
                            <InputField label="Cover Name" type="text" name="cover_name" fieldStyle="field-box bg-plane grey-color" default_value={coverData.cover_name} selected_value={coverData.cover_name} placeholder="Enter" handleOnChangeValue={this.handleIndividualChange(key, "cover_name", indexIndividual, indexGroup).bind(this)} />
                            <InputField label="Limit of liability" type="text" name="limit_of_liability" fieldStyle="field-box bg-plane grey-color" default_value={coverData.limit_of_liability} selected_value={coverData.limit_of_liability} placeholder="Enter" handleOnChangeValue={this.handleIndividualChange(key, "limit_of_liability", indexIndividual, indexGroup).bind(this)} />
                        </div>
                    </div>
                    <div className="added-field-grp">
                        <div className="added-field">
                            <InputField label="Detectable" type="text" name="detectable" fieldStyle="field-box bg-plane grey-color" placeholder="Enter" default_value={coverData.detectable} selected_value={coverData.detectable} handleOnChangeValue={this.handleIndividualChange(key, "detectable", indexIndividual, indexGroup).bind(this)} />
                            <InputField label="Exclusion" type="text" name="exclusion" fieldStyle="field-box bg-plane grey-color" placeholder="Enter" default_value={coverData.exclusion} selected_value={coverData.exclusion} handleOnChangeValue={this.handleIndividualChange(key, "exclusion", indexIndividual, indexGroup).bind(this)} />
                        </div>
                    </div>
                    <div className="added-field-grp">
                        <div className="added-field">
                            <TextAreaField label="Description" type="textarea" name="description" fieldStyle="field-box bg-plane grey-color" placeholder="Description" default_value={coverData.description} rowmax="5" selected_value={coverData.description} handleOnChangeValue={this.handleIndividualChange(key, "description", indexIndividual, indexGroup).bind(this)} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }


    render() {
        let { individualCover, groupCover, apdPayment } = this.state;
        const { addFieldType } = this.props;
        let { stakeholder } = this.props;
        return (
            <section className="add-fields-wrap">
                {
                    addFieldType === 'add-single-field' ? (
                        <div className="add-fields-wrap-sec">
                            <div className="add-field-row">
                                <div className="form-row"><InputLabel className="label">Insurance Company</InputLabel></div>
                                <FieldAdditionSubtraction
                                    defaultSections={insuranceCompanySections}
                                    fieldType={FormConstant.FieldIds.F_SINGLE_DROPDOWN_GROUP.code}
                                    type="insurance_company"
                                    selectedData={stakeholder}
                                    handleProgramChange={this.props.handleStakeholderData}
                                />
                            </div>
                            <div className="add-field-row">
                                <div className="form-row"><InputLabel className="label">Insurance Broker</InputLabel></div>
                                <FieldAdditionSubtraction
                                    defaultSections={brokerSection}
                                    fieldType={FormConstant.FieldIds.F_SINGLE_DROPDOWN_GROUP.code}
                                    type="insurance_broker"
                                    selectedData={stakeholder}
                                    handleProgramChange={this.props.handleStakeholderData}
                                />
                            </div>
                            <div className="add-field-row">
                                <div className="form-row"><InputLabel className="label">Client</InputLabel></div>
                                <FieldAdditionSubtraction
                                    defaultSections={clientSection}
                                    fieldType={FormConstant.FieldIds.F_SINGLE_DROPDOWN_GROUP.code}
                                    type="insurance_client"
                                    selectedData={stakeholder}
                                    handleProgramChange={this.props.handleStakeholderData}
                                />
                            </div>
                            <div className="add-field-row">
                                <div className="form-row"><InputLabel className="label">Policy For</InputLabel></div>
                                <FieldAdditionSubtraction
                                    defaultSections={policyForSection}
                                    fieldType={FormConstant.FieldIds.F_SINGLE_DROPDOWN_GROUP.code}
                                    type="insurance_policy_for"
                                    selectedData={stakeholder}
                                    handleProgramChange={this.props.handleStakeholderData}
                                />
                            </div>
                            <div className="add-field-row">
                                <div className="form-row"><InputLabel className="label">Vendors</InputLabel></div>
                                <FieldAdditionSubtraction
                                    defaultSections={[]}
                                    fieldType={FormConstant.FieldIds.F_SINGLE_TEXT.code}
                                    type="insurance_vendors"
                                    selectedData={stakeholder}
                                    handleProgramChange={this.props.handleStakeholderData}
                                />
                            </div>
                        </div>
                    ) : addFieldType === 'add-multiple-field' ? (
                        <div className="add-fields-wrap-sec">
                            {

                                individualCover.map((cover, index) => {
                                    return (this.groupField(index, "individual_cover", -1, cover))
                                })
                            }
                        </div>
                    ) : addFieldType === 'add-group-field' ? (
                        <div className="add-fields-wrap-sec">
                            {
                                groupCover.map((cover, index) => {
                                    return (
                                        <div className="add-multiple-wrap-sec add-grp-wrap-sec">
                                            <div className="add-field-row add-field-btn">
                                                <div className="added-field-grp">
                                                    <Fab variant="extended" size="small" className="btn btn-small btn-plane secondary-color btn-fab no-shadow " onClick={this.handleGroupAddition.bind(this)}>
                                                        <img src={AddIcon} alt="AddIcon" />
                                                    </Fab>
                                                    <Fab variant="extended" size="small" className="btn btn-small btn-plane pink-color btn-fab no-shadow btn-delete" onClick={this.handleGroupDeletion(index).bind(this)}>
                                                        <Minimize />
                                                    </Fab>
                                                </div>
                                            </div>
                                            <div className="add-field-row">
                                                <div className="added-field-grp">
                                                    <div className="added-field">
                                                        <InputField label="Group Name" type="text" name="group_cover_name" fieldStyle="field-box bg-plane grey-color" default_value={cover.group_name} selected_value={cover.group_name} placeholder="Enter" handleOnChangeValue={this.handleIndividualChange("group_cover", "group_name", -1, index).bind(this)} />
                                                    </div>
                                                </div>
                                                {
                                                    cover.group_list.map((property, i) => {
                                                        return (
                                                            this.groupField(i, "group_cover", index, property)
                                                        );
                                                    })

                                                }
                                            </div>
                                        </div>
                                    );
                                })
                            }


                        </div>
                    ) : addFieldType === 'add-apd-field' ? (
                        <div className="add-fields-wrap-sec">
                            {

                                apdPayment.map((apd, index) => {
                                    return (this.apdField(index, "apd", -1, apd));
                                })
                            }
                        </div>
                    ) : ""
                }

            </section>
        )
    }
}

AddFieldsComponent.defaultProps = {
    searchType: 'add-single-field'
}

export default AddFieldsComponent;