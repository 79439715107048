import * as AjaxHelper from "../utilities/ajax-helper"
import { ApiConstants } from "./common/api-constants"

let apiBaseUrl = () => (ApiConstants.DEV_MODE === 'true' ? ApiConstants.HOST : window.location.origin) + ApiConstants.API_VERSION;

export function getUserLoginToken(loginData) {
    return AjaxHelper.post(apiBaseUrl() + ApiConstants.AUTH_USER + "/token", loginData, { "Content-Type": "application/json" });
}

export function emailVerification(emailId) {
    return AjaxHelper.post(apiBaseUrl() + ApiConstants.AUTH_USER + "/verification", emailId, { "Content-Type": "application/json" });
}
