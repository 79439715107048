import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import Description from '../validators/descriptions';
import MandatoryValidator from '../validators/mandatory-validator';
import TextCounter from './text-counter';

//Validation
import * as ErrorValidation from '../validators/error-validator';

//Custom 
import ErrorMessage from '../common/error-message';

class TextAreaField extends Component {

    state = {
        errorStates: [],
    }

    componentWillMount() {
        let { default_value, selected_value } = this.props;

        if ((selected_value === "" || selected_value === null || selected_value === undefined) && default_value) {
            let errors = ErrorValidation.updateErrorMessage(default_value, this.props.field_validations, this.props.condition_script, this.props.error_message, this.props.char_size);
            this.props.handleOnChangeValue && this.props.handleOnChangeValue(default_value, errors, this.props);
        }
    }

    handleOnChangeEvent = (event) => {
        //validating errors
        let errors = ErrorValidation.updateErrorMessage(event.target.value, this.props.field_validations, this.props.condition_script, this.props.error_message);
        this.setState({ errorStates: errors });
        this.props.handleOnChangeValue(event.target.value, errors);
    }

    componentWillReceiveProps(nextProps) {
        //Global validation check
        if (nextProps.triggerValidCheck !== this.props.triggerValidCheck) {
            let errors = ErrorValidation.updateErrorMessage(nextProps.value, nextProps.field_validations, this.props.condition_script, this.props.error_message);
            this.setState({ errorStates: errors });
        }
    }

    render() {
        /** 
         * name: for label and id for input
         * label: label text
         * rowmax: for multiline
         * instruction: instruction
         * value: value
         * fieldStyle: field-underline
         * <TextAreaField label="Textarea" name="form_textarea" rowmax="3" value="value" instruction="instruction"/>
         * */

        let {
            name,
            label,
            rowmax,
            instruction,
            selected_value,
            default_value,
            is_mandatory,
            placeholder,
            defaultLabel,
            fieldStyle,
            customStyle,
            withLabel,
            remaining_char,
            remaining_char_limit,
        } = this.props;
        let { errorStates } = this.state;
        let defaultPlaceholder = "";
        let defaultFieldStyle = "field-box";
        let isDisabled = this.props.is_disable === 'yes' ? true : false;
        if (selected_value && (selected_value.length > remaining_char_limit) && remaining_char === "yes") {
            selected_value = selected_value.substring(0, remaining_char_limit);
        }
        return (
            <div className={"form-row " + customStyle}>
                <div className={fieldStyle ? fieldStyle : defaultFieldStyle}>
                    {withLabel &&
                        <InputLabel
                            htmlFor={name}
                            className="label textarea-count">
                            <span>
                                {label ? label : defaultLabel}
                                <MandatoryValidator value={is_mandatory} />
                            </span>
                            {
                                remaining_char === "yes" &&
                                <TextCounter
                                    dataLength={selected_value && selected_value.length}
                                    remaining_char_limit={remaining_char_limit}
                                />
                            }
                        </InputLabel>
                    }
                    <TextField
                        id={name}
                        multiline
                        rows={rowmax}
                        className="field"
                        value={selected_value ? selected_value : (default_value ? default_value : "")}
                        onChange={this.handleOnChangeEvent}
                        placeholder={placeholder ? placeholder : defaultPlaceholder}
                        disabled={isDisabled}
                        onClick={this.props.handleClickEvent}
                    />
                    <ErrorMessage errorStates={errorStates} name={name} />
                </div>
                <Description value={instruction} />
            </div>
        )
    }
}

TextAreaField.defaultProps = {
    default_value: "",
    selected_value: "",
    withLabel: true
}

export default TextAreaField;