/* eslint-disable react/style-prop-object */
import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import Description from '../validators/descriptions';
import MandatoryValidator from '../validators/mandatory-validator';
import Fab from '@material-ui/core/Fab';
import { InputAdornment } from '@material-ui/core';
import DeleteRedIcon from '../../assets/images/icon/delete-red.svg';

//Validation
import * as ErrorValidation from '../validators/error-validator';

//Custom 
import ErrorMessage from '../common/error-message';

import Slide from '@material-ui/core/Slide';
import CloseIcon from '../../assets/images/icon/close.svg'
import Dialog from '@material-ui/core/Dialog';
import Grid from '@material-ui/core/Grid';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


class UploadField extends Component {

    state = {
        errorStates: [],
        validNumber: '',
        uploadedFile: null,
        open: false
    }

    handleUploadChange = (event) => {
        console.log("Upload file", event.target.files[0]);
        let allowedExtensions = /(\.jpg|\.jpeg|\.png|\.doc|\.docx|\.pdf)$/i;

        if (!allowedExtensions.exec(event.target.files[0].name)) {
            this.setState({ open: true });

        } else {
            let errors = ErrorValidation.updateErrorMessage(event.target.files[0], this.props.field_validations, this.props.condition_script, this.props.error_message, this.props.char_size);

            const files = event.target.files;
            const fileList = Object.keys(files).map(file => files[file]);
            console.log("fileList123", fileList);
            this.setState({ uploadedFile: event.target.files[0], errorStates: errors })
            this.props.handleUploadCreation(event.target.files[0], []);
        }
    }

    componentWillReceiveProps(nextProps) {

        //Global validation check
        if (nextProps.triggerValidCheck !== this.props.triggerValidCheck) {
            let errors = ErrorValidation.updateErrorMessage(nextProps.value, nextProps.field_validations, this.props.condition_script, this.props.error_message);
            this.setState({ errorStates: errors });
            //this.props.handleOnChangeValue(nextProps.value, errors);
        }
    }

    handleClose(key) {
        this.setState({ [key]: false });
    }

    render() {
        /** 
         * name: for label and id for input
         * type: defined type for input
         * label: label text
         * instruction: instruction
         * placeValue: placeholder
         * fieldStyle: field-underline
         * <UploadField label="Form Name" type="text" name="form_name"/>
         * */
        const {
            name,
            type,
            label,
            instruction,
            selected_value,
            is_mandatory,
            placeholder,
            defaultLabel,
            disabled,
            fieldStyle,
            customStyle,
            default_value,
            withLabel,
            adornment,
            adornmentContent,
            uploadFile,
            field_validations,
        } = this.props;
        let { errorStates } = this.state;
        let defaultPlaceholder = "";
        let defaultFieldStyle = "field-box";
        let isDisabled = (this.props.is_disable === 'yes');
        //let errors = ErrorValidation.updateErrorMessage( uploadFile, this.props.field_validations);
        let errors = this.state.errorStates;
        return (
            <div className={"form-row " + customStyle}>
                <div className={fieldStyle ? fieldStyle : defaultFieldStyle}>

                    {
                        withLabel ? (
                            <InputLabel
                                shrink
                                htmlFor={name}
                                className="label">
                                {label ? label : defaultLabel}
                                <MandatoryValidator value={is_mandatory} />
                            </InputLabel>
                        ) : ""
                    }

                    <Fab variant="extended" size="small" className="btn btn-small btn-plane primary-color">
                        <InputLabel htmlFor={name} className="label">+ Upload Document</InputLabel>
                    </Fab>
                    <TextField
                        id={name}
                        className="field"
                        type={type}
                        onChange={this.handleUploadChange}
                        value={selected_value ? selected_value : default_value}
                        defaultValue={default_value}
                        placeholder={placeholder ? placeholder : defaultPlaceholder}
                        disabled={isDisabled ? isDisabled : disabled}
                        //onClick={this.props.handleOnClickEvent}
                        InputProps={adornment === 'start' ? {
                            startAdornment: (
                                <InputAdornment position="start">
                                    {adornmentContent}
                                </InputAdornment>
                            )
                        } : adornment === 'end' ? {
                            endAdornment: (
                                <InputAdornment position="end">
                                    {adornmentContent}
                                </InputAdornment>
                            )
                        } : ""}
                    >
                    </TextField>
                    {
                        uploadFile &&
                        <img src={DeleteRedIcon} alt="DeleteRedIcon" onClick={() => this.props.handleDeleteFile(errors, this.state.uploadedFile)} />
                    }
                    <ErrorMessage errorStates={errors} name={name} />

                </div>
                <Description value={uploadFile ? uploadFile.name + " uploaded" : ""} />

                <Description value={instruction} />
                <Dialog open={this.state.open} onClose={event => this.handleClose("open")} TransitionComponent={Transition} >

                    <div className="card-block">
                        <div className="card-header border-bottom" style={{ color: 'white', backgroundColor: 'orange', paddingLeft: '5px', paddingBottom: '5px', paddingRight: '0px' }}>
                            <div className="sub-title">
                                <h2>Alert!</h2>
                            </div>
                        </div>
                        <div className="card-body" style={{ paddingTop: '20px' }}>
                            <Grid container>
                                <Grid item md={12}>
                                    <p style={{ whiteSpace: 'break-spaces' }}><br /> Invalid file format, please upload a file with one of the following formats
                                        {label === 'Master Policy Upload' ?
                                            '  (.pdf .doc .docx)' : '  (.jpg .jpeg .png .doc .docx .pdf)'
                                        } </p>
                                    <div className="workflow-dialog-forms form-builder-dashboard">
                                        <div className="form-row workflow-dialog-footer">
                                            <div className="btn-wrap no-border">

                                                <Fab variant="extended" className="btn btn-large" style={{ height: '35px', paddingLeft: '0px', paddingRight: '0px', border: '1px solid orange', color: 'orange', backgroundColor: 'white', float: 'right' }} onClick={event => this.handleClose("open")}>
                                                    Done
                                                </Fab>
                                            </div>
                                        </div>
                                    </div>
                                </Grid>
                                <Grid item md={3}>
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                </Dialog>
            </div>

        )
    }
}

UploadField.defaultProps = {
    isPhoneNumber: false,
    isEmail: false,
    withLabel: true,
    default_value: "",
    selected_value: "",
    adornment: "",
}

export default UploadField;