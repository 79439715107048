import React, { Component } from 'react';
import { Fragment } from 'react';
import { GridDetailRow, GridToolbar } from '@progress/kendo-react-grid';
import TextField from '@material-ui/core/TextField';
import {
    Grid, GridColumn as Column
} from '@progress/kendo-react-grid';

import { GridPDFExport } from '@progress/kendo-react-pdf';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import { process } from '@progress/kendo-data-query';
import Fab from '@material-ui/core/Fab';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import MultipleTabActions from '../../components/common/multiple-tab-actions';
import AddIcon from '@material-ui/icons/Add';
import ExcelGreenIcon from '../../assets/images/icon/xls-green.svg';
import ExcelBlueIcon from '../../assets/images/icon/xls-blue.svg';
//import { ExcelRenderer } from "react-excel-renderer";
import { Fabric } from 'office-ui-fabric-react/lib/Fabric';
import { DefaultButton } from 'office-ui-fabric-react/lib/Button';
import XLSX from 'xlsx';

import PdfRedIcon from '../../assets/images/icon/pdf-red.svg';
import DeleteRedIcon from '../../assets/images/icon/delete-red.svg';
import UploadBlueIcon from '../../assets/images/icon/upload.svg';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';

import { CustomColumnMenu } from './customColumnMenu.jsx';

//Custom
import LoadingComponent from '../../components/common/loading-component';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Draggable from 'react-draggable';

//Constants
import * as Constants from '../../utilities/constants';

//API
import * as FormDataApi from '../../api/form-data-api.jsx';
import { DateTime } from 'luxon';

class DetailComponent extends GridDetailRow {
    render() {
        const dataItem = this.props.dataItem;
        return (
            <div>
                <section style={{ width: "200px", float: "left" }}>
                    <p><strong>Street:</strong> {dataItem.shipAddress.street}</p>
                    <p><strong>City:</strong> {dataItem.shipAddress.city}</p>
                    <p><strong>Country:</strong> {dataItem.shipAddress.country}</p>
                    <p><strong>Postal Code:</strong> {dataItem.shipAddress.postalCode}</p>
                </section>
                <Grid style={{ width: "500px" }} data={dataItem.details}></Grid>
            </div>
        );
    }
}

const SheetJSFT = [
    "xlsx", "xlsb", "xlsm", "xls", "xml", "csv", "txt", "ods", "fods", "uos", "sylk", "dif", "dbf", "prn", "qpw", "123", "wb*", "wq*", "html", "htm"
].map(function (x) { return "." + x; }).join(",");

const make_cols = refstr => {
    let o = [], C = XLSX.utils.decode_range(refstr).e.c + 1;
    for (var i = 0; i < C; ++i) o[i] = { name: XLSX.utils.encode_col(i), key: i }
    return o;
};

function PaperComponent(props) {
    return (
        <Draggable cancel={'[class*="MuiDialogContent-root"]'}>
            <Paper {...props} />
        </Draggable>
    );
}

class GridTable extends Component {

    locales = [
        {
            language: 'en-US',
            locale: 'en'
        },
        {
            language: 'es-ES',
            locale: 'es'
        }
    ]
    constructor(props) {
        super(props);
        const dataState = {
            skip: 0,
            take: 20,
            sort: [

            ],
            group: [

            ]
        };
        this.fileInput = null;
        this.state = {
            dataResult: process(props.gridRecordDetails.rows.slice(0), dataState),
            dataState: dataState,
            currentLocale: this.locales[0],
            exportExcel: '',
            excelData: [],
            cols: [],
            loading: false,
            errorMessage: '',
            open: false,
            filter: {},
            dataResultDownload: []
        };
    }

    renderUpdateSuccessfullDialog() {

        return (
            <Dialog
                open={this.state.open}
                onClose={() => this.handleClose()}
                PaperComponent={PaperComponent}
                aria-labelledby="draggable-dialog-title"
            >
                <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                    INVALID DATA
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <div dangerouslySetInnerHTML={{ __html: this.state.errorMessage }}></div>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={() => this.handleClose()} color="primary">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            dataResult: process(nextProps.gridRecordDetails.rows, this.state.dataState), dataResultDownload: nextProps.gridRecordDetails.rows
        });
    }

    dataStateChange = (event) => {
        this.setState({
            dataResult: process(this.props.gridRecordDetails.rows, event.data),
            dataState: event.data
        });
    }

    expandChange = (event) => {
        const isExpanded =
            event.dataItem.expanded === undefined ?
                event.dataItem.aggregates : event.dataItem.expanded;
        event.dataItem.expanded = !isExpanded;

        this.setState({ ...this.state });
    }
    _export;

    exportExcel = () => {
        this.setState({ exportExcel: 'entire-table' }, () => this._export.save());

    }

    exportTemplateExcel = () => {
        this.setState({ exportExcel: 'table-template' }, () => this._export.save());
    }

    _pdfExport;
    exportPDF = () => {
        this._pdfExport.save();
    }

    renderColumns(columns) {
        console.log("columns", columns)
        return columns.map(column =>
            column.show ?
                (
                    <Column field={column.id}
                        width="200px"
                        title={column.label}
                        columnMenu={
                            props =>
                                <CustomColumnMenu
                                    {...props}
                                    columns={this.props.gridRecordDetails.columns}
                                    onColumnsSubmit={this.onColumnsSubmit.bind(this)}
                                />
                        }
                    />
                ) : ""
        )
    }


    onColumnsSubmit = (columnsState) => {
        this.props.gridRecordDetailsChange(columnsState);
    }

    fileHandler = (event) => {
        let fileObj = event.target.files[0];
        console.log("fileHandler", fileObj);

        if (fileObj) {
            /* Boilerplate to set up FileReader */
            const reader = new FileReader();
            const rABS = !!reader.readAsBinaryString;
            console.log("asd", reader);

            let data = null;
            reader.onload = (e) => {
                /* Parse data */
                const bstr = e.target.result;
                const wb = XLSX.read(bstr, { type: rABS ? 'binary' : 'array', bookVBA: true });
                /* Get first worksheet */
                const wsname = wb.SheetNames[0];
                const ws = wb.Sheets[wsname];
                /* Convert array of arrays */
                data = XLSX.utils.sheet_to_json(ws, { defval: "" });
                /* Update state */
                this.setState({ excelData: data, cols: make_cols(ws['!ref']) }, () => {
                    // console.log("ZOZOZO",JSON.stringify(this.state.data, null, 2));
                    this.saveExcelData(data);
                });
            };
            console.log("data12345", data);
            if (rABS) {
                reader.readAsBinaryString(fileObj);
            } else {
                reader.readAsArrayBuffer(fileObj);
            }
            this.fileInput.value = "";


        }
    }

    handleClose() {
        this.setState({ open: false, errorMessage: "" });
    }

    createHtmlErrorMessage(key, value, index, errorText) {
        let keyStyle = '<em>' + key + '</em>';
        let valueStyle = '<i>' + value + '</i>';
        let rowValue = index + 1;
        let message = '' + keyStyle + ' contains invalid data ' + valueStyle + ' at row ' + rowValue;
        message = '<h6>' + message + '</h6><br>';
        console.log("error123", message);
        errorText = errorText + message;
        return errorText;
    }

    saveExcelData(excelDataList) {
        let { gridRecordDetails } = this.props;
        let { errorMessage } = this.state;
        console.log("gridRecordDetails444", gridRecordDetails, "excelDataList", excelDataList);
        let recordData = [];
        let breakCondition = false;
        let discardFields = ['f-upload', 'f-policy-id', 'f-state-txt', 'f-sub-state-txt', 'f-date-time'];
        let errorText = ''
        this.setState({ loading: true });
        excelDataList.forEach((excelData, index) => {
            let formFieldList = gridRecordDetails.form.form_field_list;
            let formUserDatas = [];
            for (let key in excelData) {
                let filterField = formFieldList.filter(field => field.label === key)[0];
                if (filterField != null && !discardFields.includes(filterField.field_type)) {
                    let formUserData = formUserDatas[filterField.data_pagination_id - 1];
                    if (!this.checkFieldValidations(filterField, excelData[key], excelData)) {
                        breakCondition = true;
                        errorText = this.createHtmlErrorMessage(key, excelData[key], index, errorText);
                    }
                    else {
                        if (!formUserData) {
                            formUserData = {};
                            formUserData["form_pagination_id"] = filterField.data_pagination_id;
                            formUserData["object_id"] = gridRecordDetails.form.id;
                            switch (gridRecordDetails.form.form_type) {
                                case Constants.FORM_TYPE.PARAMETER.code: {
                                    formUserData["object_type"] = Constants.RECORD_TYPE.PROGRAM.code;
                                    break;
                                }
                                default: {
                                    formUserData["object_type"] = Constants.RECORD_TYPE.FORM.code;
                                }
                            }
                        }
                    }
                    formUserDatas[filterField.data_pagination_id - 1] = formUserData;
                    formUserData["data_attr" + filterField.data_ref_id] = excelData[key];
                }
            }
            console.log("formUserDatas222", formUserDatas);
            recordData[index] = formUserDatas;
        })
        console.log("recordData123", recordData, " breakcondition: ", breakCondition, "errorMessage", errorMessage);
        this.setState({ errorMessage: errorText });
        if (!breakCondition) {
            FormDataApi.saveBulkFormData(recordData).then(response => {
                window.location.reload(false);
                this.setState({ loading: false });
                this.props.getSectionDetails();
                this.props.getFormData(gridRecordDetails.form.form_key);
            }).catch(error => {
                let errorResponse = JSON.parse(error.response);
                this.setState({ open: true, errorMessage: errorResponse.message, loading: false });
            })
        } else {
            this.setState({ open: true, loading: false });
        }
    }

    intervalFunction(progressStatus) {
        FormDataApi.bulkUploadStatusCheck().then(data => {
            console.log("status_logged: ", data);
            progressStatus = data;
            // if(data === 100){
            //     clearInterval(var1);
            // }
        })
    }

    checkFieldValidations(filterField, fieldValue, excelData) {
        console.log("filterField1245", filterField, "fieldValue", fieldValue);

        let fieldValidation = this.fieldValidation(filterField, fieldValue, excelData);
        let valueValidation = this.valueValidation(filterField, fieldValue, excelData);

        console.log(fieldValidation, valueValidation);
        return fieldValidation && valueValidation;

    }

    fieldValidation(filterField, fieldValue, excelData) {
        switch (filterField.field_type) {

            case 'f-radio-grp':
            case 'f-checkbox-grp':
            case 'f-sin-dropdown-grp':
            case 'f-multi-dropdown-grp':

                let valueExist = false;
                if (filterField && filterField.field_option_list) {
                    filterField.field_option_list.forEach(data => {
                        let labelValue = data.field_option_label ? data.field_option_label.trim().toLowerCase() : "";
                        let entryValue = fieldValue ? fieldValue.trim().toLowerCase() : "";
                        if (labelValue == entryValue) {
                            valueExist = true;
                            excelData[filterField.label] = data.field_option_value;
                        }
                        if (entryValue == "") {
                            valueExist = true;
                            //excelData[filterField.label] = "";
                        }
                    })
                }
                if (filterField.label === "Country") {
                    return true;
                }

                return valueExist;

            case 'f-date':
                console.log("fieldValue", fieldValue)
                let dateList = fieldValue.split("/", 3);
                if (dateList[0] >= 1 && dateList[0] <= 31 && dateList[1] >= 1 && dateList[1] <= 12) {
                    let dateValue = new Date(dateList[2], dateList[1] - 1, dateList[0]);
                    console.log("filterField.label", filterField.label)
                    excelData[filterField.label] = dateValue;
                    return true;
                }

                return false;


            case 'f-time':
                let timeList = fieldValue.split(":", 2);
                if (timeList[0] >= 0 && timeList[0] <= 23 && timeList[1] >= 0 && timeList[1] <= 59) {
                    let todayDate = new Date();
                    let dateValue = new Date(todayDate.getFullYear(), todayDate.getMonth(), todayDate.getDay(), timeList[0], timeList[1]);
                    excelData[filterField.label] = dateValue;
                    return true;
                }
                return false;

            case "f-number-txt":
                var regex = /^\d+$/;

                if (regex.test(fieldValue)) {
                    return true;
                }
                if (fieldValue == "") {
                    return true;
                }
                return false;


            case 'f-email-txt':
                var regex = /\w+@\w+\.(net|com|org|co.in|co.org)/;
                if (regex.test(fieldValue)) {
                    return true;
                }
                return false;
                break;


            case 'f-upload':
            case 'f-policy-id':
            case 'f-state-txt':
            case 'f-sub-state-txt':
            case 'f-data-time':
                delete excelData[filterField.label];
                return true;


            default: return true;
        }
    }

    valueValidation(filterField, fieldValue, excelData) {
        if (filterField.is_mandatory) {
            if (!fieldValue) {
                return false;
            }
        }

        if (filterField.is_hidden) {
            if (fieldValue) {
                excelData[filterField.label] = "";
            }
        }

        if (filterField.is_disable) {
            if (fieldValue) {
                excelData[filterField.label] = "";
            }
        }

        return true;
    }

    uploadButtonClick() {
        document.getElementById("imageUpload").click();
    }

    render() {
        const LightTooltip = withStyles(theme => ({
            tooltip: {
                backgroundColor: theme.palette.common.white,
                color: 'rgba(0, 0, 0, 0.87)',
                position: 'absolute',
                left: '50%',
                transform: 'translateX(-50%)',
                boxShadow: theme.shadows[1],
                fontSize: 11,
            },
        }))(Tooltip);
        let { gridRecordDetails } = this.props;
        let { exportExcel, dataResultDownload } = this.state;
        let gridRecordRowTemplate = JSON.parse(JSON.stringify(this.props.gridRecordDetails));
        gridRecordRowTemplate.rows = [];
        console.log("asda", this.state.errorMessage);
        console.log("gridRecordDetails_123", gridRecordDetails, "gridRecordRowTemplate", gridRecordRowTemplate, "exportExcel", exportExcel, "filterData", this.state.dataResult.data);
        console.log("errorMessage1777", this.state.errorMessage);
        return (
            <LocalizationProvider language={this.state.currentLocale.language}>
                <IntlProvider locale={this.state.currentLocale.locale} >
                    <div>
                        {this.renderUpdateSuccessfullDialog()}
                        <LoadingComponent loading={this.state.loading} />
                        <ExcelExport
                            data={exportExcel === 'table-template' ? gridRecordRowTemplate.rows : this.state.dataResultDownload}
                            ref={(exporter) => { this._export = exporter; }}

                        >
                            <Grid
                                // style={{ height: 'calc(100vh - 225px)' }}
                                sortable
                                groupable
                                reorderable
                                pageable={{ buttonCount: 4, pageSizes: [5, 10, 25, 50, 100] }}
                                data={this.state.dataResult}
                                {...this.state.dataState}
                                //                 onFilterChange={(e) => {
                                //     this.setState({
                                //         filter: 
                                //     });
                                // }}

                                onDataStateChange={this.dataStateChange}
                                onRowClick={(e) => {
                                    if (this.props.gridRecordDetails.form && this.props.gridRecordDetails.form.is_read === true) {
                                        this.props.onRowClickEvent(e);
                                    }
                                }}
                                onExpandChange={this.expandChange}
                            >

                                <GridToolbar>
                                    <div className="drawer-tabs-header">
                                        <div className="workflow-fields-entry">
                                            <div className="workflow-entry-details">
                                                {/* <h2>Entries</h2><span className="badge">{gridRecordDetails.rows.length}</span> */}
                                                <h2>Entries</h2><span className="badge">{this.props.gridRecordDetails.rows.length}</span>
                                            </div>
                                        </div>
                                        <div className="workflow-tbl-sec">
                                            <div className="workflow-tbl-wrap">
                                                <MultipleTabActions type="icons" iconList={["search"]} /> &nbsp;&nbsp;&nbsp;
                                                <div className="workflow-action-grp">
                                                    <LightTooltip title="Export Excel">
                                                        <Fab onClick={this.exportExcel} variant="extended" size="small" className="btn btn-small btn-plane btn-fab transparent-color no-shadow grey-color">
                                                            <img src={ExcelGreenIcon} alt="ExcelGreenIcon" />
                                                        </Fab>
                                                    </LightTooltip>

                                                    <LightTooltip title="Export Table Template">
                                                        <Fab onClick={this.exportTemplateExcel} variant="extended" size="small" className="btn btn-small btn-plane btn-fab transparent-color no-shadow grey-color">
                                                            <img src={ExcelBlueIcon} alt="ExcelBlueIcon" />
                                                        </Fab>
                                                    </LightTooltip>

                                                    <LightTooltip title="Export PDF">
                                                        <Fab onClick={this.exportPDF} variant="extended" size="small" className="btn btn-small btn-plane btn-fab transparent-color no-shadow grey-color">
                                                            <img src={PdfRedIcon} alt="PdfRedIcon" />
                                                        </Fab>
                                                    </LightTooltip>
                                                    <LightTooltip title="Delete">
                                                        <Fab onClick={this.exportPDF} variant="extended" size="small" className="btn btn-small btn-plane btn-fab transparent-color no-shadow grey-color">
                                                            <img src={DeleteRedIcon} alt="DuplicatePinkIcon" />
                                                        </Fab>
                                                    </LightTooltip>
                                                    <LightTooltip title="Upload Bulk Data">
                                                        <Fab onClick={this.uploadButtonClick} variant="extended" size="small" className="btn btn-small btn-plane btn-fab transparent-color no-shadow grey-color">
                                                            <img src={UploadBlueIcon} alt="DuplicatePinkIcon" />
                                                        </Fab>
                                                    </LightTooltip>
                                                    <input
                                                        id="imageUpload" hidden
                                                        ref={fileInput => (this.fileInput = fileInput)}
                                                        type="file"
                                                        onChange={this.fileHandler.bind(this)}
                                                        accept={SheetJSFT}
                                                        style={{ "padding": "10px" }}
                                                    />


                                                </div> &nbsp;&nbsp;
                                                {this.props.gridRecordDetails.form && this.props.gridRecordDetails.form.is_create === true && (
                                                    <Fab variant="extended" size="small" className="btn btn-large btn-plane workflow-add-btn no-shadow primary-color" onClick={this.props.redirectLink}>
                                                        <AddIcon /> Add New
                                                    </Fab>
                                                )}

                                            </div>
                                        </div>
                                    </div>
                                    {/* Locale:&nbsp;&nbsp;&nbsp;
                                    <DropDownList
                                        value={this.state.currentLocale}
                                        textField="language"
                                        onChange={(e) => { this.setState({ currentLocale: e.target.value }); }}
                                        data={this.locales} />&nbsp;&nbsp;&nbsp; */}

                                </GridToolbar>
                                {this.renderColumns(gridRecordDetails.columns)}
                            </Grid>
                        </ExcelExport>
                        <GridPDFExport
                            ref={(element) => { this._pdfExport = element; }}
                            margin="1cm" >
                            {<Grid data={process(this.props.gridRecordDetails.rows, { skip: this.state.dataState.skip, take: this.state.dataState.take })} >
                                {this.renderColumns(gridRecordDetails.columns)}
                            </Grid>}
                        </GridPDFExport>
                    </div>
                </IntlProvider>
            </LocalizationProvider>
        );
    }
}

export default GridTable;