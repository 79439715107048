import React, { Component } from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputLabel from '@material-ui/core/InputLabel';
import MandatoryValidator from '../validators/mandatory-validator';
import Switch from '@material-ui/core/Switch';

class SwitchButtonActions extends Component {
    render() {
        const {
            fieldStyle,
            customStyle,
            label,
            isChecked,
            name,
            withLabel,
            headerLabel,
            is_mandatory,
            onChange,
        } = this.props;
        let defaultFieldStyle = "field-box";
        let defaultLabel = "";
        return (
            <div className={"form-row " + customStyle}>
                <div className={fieldStyle ? fieldStyle : defaultFieldStyle}>
                    {
                        withLabel ? (
                            <InputLabel
                                shrink
                                htmlFor={name}
                                className="label">
                                {headerLabel ? headerLabel : defaultLabel}
                                <MandatoryValidator value={is_mandatory} />
                            </InputLabel>
                        ) : null
                    }
                    <FormControlLabel
                        control={
                            <Switch
                                checked={isChecked}
                                name={name}
                                className="toogle-switch"
                                onChange={onChange}
                            />
                        }
                        label={label}
                    />
                </div>
            </div>
        )
    }
}

export default SwitchButtonActions;