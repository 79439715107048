import React, { Component } from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import InputField from '../fields/inputs';
import Fab from '@material-ui/core/Fab';
import Add from '@material-ui/icons/Add';
import Minimize from '@material-ui/icons/Minimize';
import Chip from '@material-ui/core/Chip';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

class MultipleChoice extends Component {

    state = {
        countClicks: this.props.checkData.length > 2 ? this.props.checkData.length - 4 : this.props.checkData.length - 2,
        isClicked: false,
        chipValue: (this.props.checkData.length > 4),
    }
    menuOpen = ele => event => {
        this.setState({ [ele]: event.currentTarget });
    };

    menuClose(ele, event) {
        this.setState({ [ele]: null });
    };
    handleChange = index => event => {
        let checkData = this.props.checkData;
        //Change this
        checkData[index].checked = event.target.checked;
        this.props.optionOnChangeEvent(checkData);
    };

    handleOnChangeEvent(index, key, value) {
        let checkData = this.props.checkData;
        checkData[index][key] = value;
        this.props.optionOnChangeEvent(checkData);
    }

    addNewOption = index => event => {
        let checkData = this.props.checkData;
        let option = {
            field_option_label: "",
            checked: false,
            field_option_value: new Date().getTime().toString(),
        };
        checkData.splice(index + 1, 0, option);

        if (checkData.length > 4) {
            this.setState({ countClicks: checkData.length - 3 });
        }
        else {
            this.setState({ countClicks: 0 });
        }

        this.props.optionOnChangeEvent(checkData);
    }

    getCheckData() {
        let { checkData } = this.props;

        let updatedCheckData = checkData.filter(check => {
            return !check.option_type || check.option_type === 0;
        })

        if (this.state.chipValue) {
            if (this.state.isClicked) {
                return updatedCheckData
            }
            else {

                return updatedCheckData.splice(0, 4);
            }
        }
        else {
            return updatedCheckData
        }
    }

    deleteOption = index => event => {
        let checkData = this.props.checkData;
        checkData.splice(index, 1);

        this.props.optionOnChangeEvent(checkData);

        if (checkData.length <= 4) {
            this.setState({ chipValue: false })
        }
        else {
            this.setState((prevState) => {
                return {
                    countClicks: prevState.countClicks - 1
                }
            })
        }

    }

    handleColorChange = (index, color) => (event) => {
        this.menuClose('buttonColorMenu' + index, event);
        this.handleOnChangeEvent(index, "field_metadata", color);
    }

    clickToDisplay = event => {
        this.setState({ isClicked: true, countClicks: 0, chipValue: false })
    }

    render() {
        let { isRigid, isCheckboxEnabled, showColors } = this.props;
        let checkData = this.getCheckData();
        return (
            <div className="form-row">
                <div className="choice-wrap">
                    {
                        checkData.map((check, index) => {
                            return (
                                <div className="choice-row" key={index}>

                                    {
                                        (isCheckboxEnabled) &&
                                        <Checkbox
                                            value={check.field_option_value}
                                            name="radio-button"
                                            aria-label="A"
                                            checked={check.checked}
                                            onChange={this.handleChange(index)}
                                            disabled={check.disabled}
                                        />
                                    }
                                    <InputField selected_value={check.field_option_label} handleOnChangeValue={(value, errors) => this.handleOnChangeEvent(index, "field_option_label", value)} />
                                    {!(isRigid) &&
                                        <div className="choice-action">

                                            {showColors &&
                                                (
                                                    <div>
                                                        <Fab aria-label="add_circle_outline" className="fabs-icons add" aria-owns={this.state["buttonColorMenu" + index] ? 'profile-menu' : undefined} aria-haspopup="true" onClick={this.menuOpen('buttonColorMenu' + index)}>
                                                            <span className={"color-set " + check.field_metadata}></span>
                                                        </Fab>
                                                        <Menu
                                                            id="profile-menu"
                                                            anchorEl={this.state["buttonColorMenu" + index]}
                                                            open={Boolean(this.state["buttonColorMenu" + index])}
                                                            onClose={(e) => this.menuClose('buttonColorMenu' + index, e)}
                                                        >
                                                            <MenuItem onClick={this.handleColorChange(index, "primary-color")}> <span className="color-set primary-color"></span> </MenuItem>
                                                            <MenuItem onClick={this.handleColorChange(index, "white-color")}> <span className="color-set white-color"></span> </MenuItem>
                                                            <MenuItem onClick={this.handleColorChange(index, "grey-color")}> <span className="color-set grey-color"></span> </MenuItem>
                                                            <MenuItem onClick={this.handleColorChange(index, "secondary-color")}> <span className="color-set secondary-color"></span> </MenuItem>
                                                            <MenuItem onClick={this.handleColorChange(index, "green-color")}> <span className="color-set green-color"></span> </MenuItem>
                                                            <MenuItem onClick={this.handleColorChange(index, "apricot-color")}> <span className="color-set apricot-color"></span> </MenuItem>
                                                            <MenuItem onClick={this.handleColorChange(index, "musturd-color")}> <span className="color-set musturd-color"></span> </MenuItem>
                                                            <MenuItem onClick={this.handleColorChange(index, "maroon-color")}> <span className="color-set maroon-color"></span> </MenuItem>
                                                            <MenuItem onClick={this.handleColorChange(index, "cyan-color")}> <span className="color-set cyan-color"></span> </MenuItem>
                                                            <MenuItem onClick={this.handleColorChange(index, "orange-color")}> <span className="color-set orange-color"></span> </MenuItem>

                                                        </Menu>
                                                    </div>
                                                )}
                                            <Fab aria-label="add_circle_outline" className="fabs-icons add" onClick={this.addNewOption(index)}>
                                                <Add />
                                            </Fab>
                                            {(checkData.length <= 1 && index === 0) ? "" :
                                                (
                                                    <Fab aria-label="highlight_off" className="fabs-icons remove" onClick={this.deleteOption(index)}>
                                                        <Minimize />
                                                    </Fab>
                                                )
                                            }
                                        </div>
                                    }


                                </div>
                            )
                        })
                    }
                </div>
                <div className="choice-wrap choice-count">
                    {this.state.chipValue &&
                        <Chip variant="outlined"
                            label={"" + this.state.countClicks + "+ more"}
                            onClick={this.clickToDisplay}
                        />
                    }
                </div>
            </div>
        )
    }
}

MultipleChoice.defaultProps = {
    isCheckboxEnabled: true,
    showColors: false,
}
export default MultipleChoice;