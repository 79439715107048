import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import Fab from '@material-ui/core/Fab';
import Slide from '@material-ui/core/Slide';
import closeIcon from '../../assets/images/icon/close.svg';
import SectionIllust from '../../assets/images/illustration/section-iso.svg';
import WorkflowHeader from '../../components/workflow/workflow-header';

import SearchField from '../../components/fields/search';
import AddIcon from '@material-ui/icons/Add';
import AddSectionIllus from '../../assets/images/illustration/add-section-1.svg'

//Custom component
import TableComponent from '../../components/common/table-component';
import TextAreaField from '../../components/fields/textarea';
import Header from '../../components/header'
import InputField from '../../components/fields/inputs';
import UrlTabActions from '../../components/common/url-tab-actions';
import SwitchTabActions from '../../components/common/switch-tab-actions';
import MultipleTabActions from '../../components/common/multiple-tab-actions';
import { Scrollbars } from 'react-custom-scrollbars';
import IconFilterPopover from '../../components/common/icon-select';
import Square2 from '../../assets/images/icon/square-2-blue.svg';
import { icons } from '../../utilities/constants';
//API
import * as ProgramApi from '../../api/program-api';


const tableRows = [
    { id: 'icon', numeric: false, disablePadding: false, label: 'Icon' },
    { id: 'name', numeric: true, disablePadding: false, label: 'Name' },
    { id: 'details', numeric: true, disablePadding: false, label: 'Details' },
    { id: 'information', numeric: true, disablePadding: false, label: 'Information' },
    // { id: 'status', numeric: true, disablePadding: false, label: 'Status' },
    { id: 'createdOn', numeric: true, disablePadding: false, label: 'Created on' },
    { id: 'UpdatedOn', numeric: true, disablePadding: false, label: 'Updated on' },
    { id: 'actions', numeric: true, disablePadding: false, label: 'Actions' },
];


function createData(index, section_key, section_id, section_icon, section_name, stateList, description, section_status, created_on, modified_on, editFormRedirect, handleStatusChange, dublicateSection) {
    let icon = (<img src={section_icon} alt="section_icon" />);
    let sectionNameView = (<div className="table-name"><UrlTabActions formName={section_name} withImg={false} handleNameClick={editFormRedirect(section_key)} /></div>);
    let details = (<div className="bold text-primary-color">{stateList.length} states</div>);
    //let statusView = (<SwitchTabActions status={(section_status === "active")? "enabled" : "disabled"} handleStatusChange={handleStatusChange(section_id,section_status)}/>);
    let createdOn = created_on;
    let updatedOn = modified_on;
    let actionView = (<MultipleTabActions type="icons" handleEditClick={editFormRedirect(section_key)} handelDuplicateClick={dublicateSection(section_key)} iconList={["edit", "duplicate"]} />)
    return { icon, sectionNameView, details, description, createdOn, updatedOn, actionView };
}

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

class SectionDashboard extends Component {

    state = {
        tableData: [],
        open: false,
        stateOpen: false,
        selectedTriggerType: 0,
        formList: [],
        workflowFormData: {
            workflow_trigger: {}
        },
        sectionData: [],
        customStyleSearch: '',

        sectionObj: {
            program_state_list: [],
        },
        stateObj: {},
        selectedIcon: { text: "Square2", icon: Square2 },
        dataReceived: false,
    }

    constructor(props) {
        super(props);
        this.getAllFormData();
    }

    componentWillMount() {
        if (this.props.location && this.props.location.state) {
            if (this.props.location.state.id) {
                this.setState({ open: true })
            }
            else {
                this.setState({ open: false })
            }
        }
    }

    getAllFormData() {
        let tableData = [];
        const match = this.props.match;
        let programkey = match.params.programkey;
        ProgramApi.getAllSectionCount(programkey).then(sectionData => {
            console.log("sectionData", sectionData);
            sectionData.forEach((section, index) => {
                let section_icon = section.icon_name === null ? icons.i_company.icon : icons[section.icon_name].icon
                tableData.push(createData(index, section.section_key, section.id, section_icon, section.section_name, section.program_state_list, section.description, section.section_status, section.created_on, section.modified_on, this.editWorkflowRedirect, this.handleStatusChange, this.handleDublicateData));
            })
            this.setState({ tableData: tableData, sectionData: sectionData, dataReceived: true });
        });

    }

    generateTable(section_id, sectionstatus) {
        ProgramApi.updateSectiontatus(section_id, sectionstatus).then(res => {
            this.getAllFormData();
        });
    }

    handleDublicateData = (key) => event => {
        let sectionData = JSON.parse(JSON.stringify(this.state.sectionData));
        sectionData = sectionData.filter(section => { return (section.section_key === key) })
        let sectionObj = sectionData[0];
        let stateList = [];
        sectionData[0].program_state_list.forEach(state => {
            if (!state.sub_state) {
                let subStateList = [];
                let substates = sectionData[0].program_state_list.filter(sub => { return (sub.sub_state === true) })
                substates.forEach(sub => {
                    if (sub.parent_id === state.id) {
                        sub["id"] = null;
                        sub["modified_on"] = null;
                        sub["section_id"] = null;
                        sub["section_key"] = null;
                        sub["parent_id"] = null;
                        sub["postition"] = null;
                        sub["created_on"] = null
                        subStateList.push(sub);
                    }
                })
                state["sub_states_list"] = subStateList;
                state["id"] = null;
                state["modified_on"] = null;
                state["section_id"] = null;
                state["section_key"] = null;
                state["postition"] = null;
                state["created_on"] = null
                stateList.push(state);
            }
        })
        sectionObj["program_state_list"] = stateList;
        sectionObj["section_id"] = null;
        sectionObj["form_id"] = null;
        sectionObj["form_key"] = null;
        sectionObj["section_key"] = null;
        sectionObj["created_on"] = null;
        sectionObj["modified_on"] = null;
        sectionObj["section_alias"] = null;
        sectionObj["used"] = null;
        sectionObj["id"] = null;
        ProgramApi.createSection(sectionObj).then(response => {
            console.log("duplicate section", response);
            this.getAllFormData();
        })
    }

    handleStatusChange = (section_id, sectionstatus) => event => {
        sectionstatus = (sectionstatus === "active") ? "inactive" : "active";
        this.generateTable(section_id, sectionstatus);
    }

    editWorkflowRedirect = key => event => {
        event.stopPropagation();
        this.editWorkflow(key);
    }

    editSectionFlowRedirect(key) {
        console.log("editWorkflowRedirect", key);
        this.editWorkflow(key);
    }

    editWorkflow = (key) => {
        const match = this.props.match;
        let programkey = match.params.programkey;
        let clientId = match.params.clientId;
        window.location = "/" + clientId + "/" + programkey + "/section/" + key + "/builder";
    }

    recordsFormRedirect = key => event => {
        event.stopPropagation();
        const match = this.props.match;
        let programkey = match.params.programkey;
        let clientId = match.params.clientId;
        window.location = "/" + clientId + "/" + programkey + "/form/" + key + "/records";
    }

    handleClose(key) {
        this.setState({ [key]: false });
    }

    handleOpen(key) {
        this.setState({ [key]: true });
    }

    handleFormChange = (fieldName) => (value, errors) => {
        this.setWorkflowFormData(fieldName, value);
    }

    setWorkflowFormData(fieldName, value) {
        let { workflowFormData } = this.state;
        if (fieldName.split(".").length > 1) {
            let fieldSplit = fieldName.split(".");
            fieldName = fieldSplit[0];
            if (!workflowFormData[fieldName]) {
                workflowFormData[fieldName] = {};
            }
            workflowFormData[fieldName][fieldSplit[1]] = value;
        } else {
            workflowFormData[fieldName] = value;
        }
        this.setState({ workflowFormData: workflowFormData });
    }

    searchBtnClick() {
        let { sectionData, customStyleSearch } = this.state;
        console.log("customStyleSearch", customStyleSearch, "sectionData", sectionData);
        let searchedSectionData = [];
        let tableData = [];
        searchedSectionData = sectionData.filter(section => (section.section_name.toLowerCase().includes(customStyleSearch.toLowerCase())));
        console.log("searchedSectionData", searchedSectionData);

        if (searchedSectionData && searchedSectionData.length > 0) {
            searchedSectionData.forEach((section, index) => {
                let section_icon = section.icon_name === null ? icons.i_company.icon : icons[section.icon_name].icon
                tableData.push(createData(index, section.section_key, section.id, section_icon, section.section_name, section.program_state_list, section.description, section.section_status, section.created_on, section.modified_on, this.editWorkflowRedirect, this.handleStatusChange, this.handleDublicateData));
            })
            this.setState({ tableData: tableData });
        }

    }

    handleOnChangeValue(event) {
        let { sectionData } = this.state;
        if (event.target.value === "") {
            if (sectionData && sectionData.length > 0) {
                let tableData = [];
                sectionData.forEach((section, index) => {
                    let section_icon = section.icon_name === null ? icons.i_company.icon : icons[section.icon_name].icon
                    tableData.push(createData(index, section.section_key, section.id, section_icon, section.section_name, section.program_state_list, section.description, section.section_status, section.created_on, section.modified_on, this.editWorkflowRedirect, this.handleStatusChange, this.handleDublicateData));
                })
                this.setState({ tableData: tableData });
            }
        }
        this.setState({ customStyleSearch: event.target.value });
    }

    handleDataChangeEvent(key, value, errors, callback) {
        if (key.split(".").length > 1) {
            let splitKeys = key.split(".");
            key = splitKeys[0];
            let valueObj = this.state[key];
            if (!valueObj) {
                valueObj = {};
            }
            valueObj[splitKeys[1]] = value;
            value = valueObj;
        }
        this.setState({ [key]: value }, callback);
    }

    handleSaveEvent() {
        let { sectionObj } = this.state;
        console.log("sectionObj", sectionObj);
        const match = this.props.match;
        let programkey = match.params.programkey;

        //Setting extra data
        // stateObj.position = 0;
        // stateObj.sub_state = false;
        // sectionObj.program_state_list = [stateObj];

        if (sectionObj.section_name) {
            sectionObj.program_key = programkey;
            ProgramApi.createSection(sectionObj).then(response => {
                console.log("response123", response);
                this.setState({ stateOpen: false, open: false, sectionObj: {}, stateObj: {} });
                this.getAllFormData();
                this.editSectionFlowRedirect(response.section_key);
            })
        }
    }
    handleFunction(id) {
    }
    selectIcon(id, text, icon) {
        let sectionObj = this.state.sectionObj;
        sectionObj["icon_name"] = id;
        this.setState({ sectionObj: sectionObj });
        this.setState({ selectedIcon: { text, icon } })
    }

    render() {
        let { tableData, open, stateOpen, sectionObj, stateObj, customStyleSearch, dataReceived } = this.state;
        console.log("tableData", tableData);
        const match = this.props.match;
        let clientId = match.params.clientId;
        let programkey = match.params.programkey;

        return (
            <div className="FormBuilderScene">
                <Header headerType="dashboard" positionType="fixed" programKey={programkey} clientId={clientId} />
                <WorkflowHeader workflowType="normal" clientId={clientId} programKey={programkey} tab="section" handleTabChange={this.handleFunction.bind(this)} />
                <main>
                    {
                        <div className="workflow-fields-sec workflow-fields-dashboard">
                            <div className="drawer-tabs drawer-tabs-round no-drawer-indicator workflow-tabs-wrap">
                                <div className="drawer-tabs-header">
                                    <div></div>
                                    <div className="workflow-tabs-search">
                                        <SearchField placeholder="Search here" searchType="normal" customStyle={customStyleSearch} handleOnChangeValue={this.handleOnChangeValue.bind(this)} searchBtnClick={this.searchBtnClick.bind(this)} />
                                        <Fab variant="extended" size="large" className="btn btn-large workflow-add-btn no-shadow" onClick={event => this.handleOpen("open")} style={{ minWidth: '175px' }}>
                                            <AddIcon /> New Section
                                        </Fab>
                                    </div>
                                </div>
                                <div className="tab-container">
                                    {tableData.length > 0 && (
                                        <div className="table-wrapper vertical-bottom">
                                            <TableComponent
                                                tableLabel=""
                                                rows={tableRows}
                                                isPagginated={true}
                                                isSearchable={false}
                                                data={tableData}
                                                searchLabel=""
                                                isCheckBoxEnabled={false}
                                            />
                                        </div>
                                    )}
                                    {/* <BlankPageCreate 
                                    imgIcon="" 
                                    infoHeader="You haven't added any Section yet!" 
                                    infoDiscription="Add users and assign permissions according to your business logic" 
                                    btnText="Add Section"/> */}
                                    {dataReceived && tableData.length <= 0 && (
                                        <section className="blank-page-sec">
                                            <div className="blank-page-wrap">
                                                <div className="blank-page-icon">
                                                    <img src={AddSectionIllus} alt="AddSectionIllus" />
                                                </div>
                                                <div className="blank-page-info">
                                                    <h5>You haven't added any Sections yet!</h5>
                                                    <h6>Create Sections and attach forms and flows to it according to your business requirement</h6>
                                                </div>
                                                <Fab variant="extended" size="large" className="btn btn-large workflow-add-btn no-shadow primary-color" onClick={event => this.handleOpen("open")}>
                                                    Add Section
                                                </Fab>
                                            </div>
                                        </section>
                                    )}
                                </div>
                            </div>

                            {/* Dailog for section form */}
                            <Dialog open={open} onClose={event => this.handleClose("open")} TransitionComponent={Transition} maxWidth="xl">
                                <section className="workflow-dialog workflow-dialog-large">
                                    <div className="card-block">
                                        <div className="card-header border-bottom">
                                            <div className="sub-title">
                                                <h5>Section</h5>
                                            </div>
                                            <div className="close-dialog">
                                                <img src={closeIcon} alt="" onClick={event => this.handleClose("open")}></img>
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <Grid container>
                                                <Grid item md={4}>
                                                    <div className="workflow-illust-wrap">
                                                        <div className="sub-title">
                                                            <h2>Create a New Section</h2>
                                                        </div>
                                                        <div className="workflow-illust">
                                                            <img src={SectionIllust} alt="SectionIllust" />
                                                        </div>
                                                    </div>
                                                </Grid>
                                                <Grid item md={8}>
                                                    <div className="workflow-dialog-forms">
                                                        <div className="tabs-container tabs-container-scroll">
                                                            <Scrollbars style={{ width: '100%', height: '100%' }}>
                                                                <div className="tabs-container-scroll-wrap">
                                                                    <Grid item md={5}>
                                                                        <InputField label="Name of the Section " type="text" name="field_label" fieldStyle="field-box bg-plane grey-color"
                                                                            handleOnChangeValue={(value, errors) => this.handleDataChangeEvent("sectionObj.section_name", value)} selected_value={sectionObj.section_name} placeholder="Enter" />
                                                                    </Grid>
                                                                    <Grid item md={5}>
                                                                        <TextAreaField label="Description" rowmax="3" fieldStyle="field-box bg-plane grey-color" placeholder="Description" name="field_description"
                                                                            handleOnChangeValue={(value, errors) => this.handleDataChangeEvent("sectionObj.description", value)} selected_value={sectionObj.description} />
                                                                    </Grid>
                                                                    <Grid item md={5}>

                                                                        <IconFilterPopover fieldStyle="field-box bg-plane grey-color field-icon" customStyle="adornment-only-icon" label="Select Icon Upload"
                                                                            newSelectedicon={this.selectIcon.bind(this)}
                                                                            Iconslist={icons} selectedIcon={this.state.selectedIcon} />
                                                                        {/* <SelectField optionsData={defalutSections} isChip={true} is_search={true} fieldStyle="field-box bg-plane grey-color field-icon" label="Select Icon Upload"/> */}
                                                                    </Grid>
                                                                </div>
                                                            </Scrollbars>
                                                        </div>
                                                        <div className="form-row workflow-dialog-footer">
                                                            <div className="btn-wrap no-border">
                                                                <Fab variant="extended" size="large" className="btn btn-large btn-plane primary-color" onClick={this.handleSaveEvent.bind(this)}>
                                                                    Create Section
                                                                </Fab>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </div>
                                    </div>
                                </section>
                            </Dialog>
                            {/* Dialog for section create form */}
                            {/* Dailog for state form */}
                            <Dialog open={stateOpen} onClose={event => this.handleClose("stateOpen")} TransitionComponent={Transition} maxWidth="xl">
                                <section className="workflow-dialog workflow-dialog-large">
                                    <div className="card-block">
                                        <div className="card-header border-bottom">
                                            <div className="sub-title">
                                                <h5>Program</h5>
                                            </div>
                                            <div className="close-dialog">
                                                <img src={closeIcon} alt="" onClick={event => this.handleClose("stateOpen")}></img>
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <Grid container>
                                                <Grid item md={4}>
                                                    <div className="workflow-illust-wrap">
                                                        <div className="sub-title">
                                                            <h2>Create a New State</h2>
                                                        </div>
                                                        <div className="workflow-illust">
                                                            <img src={SectionIllust} alt="SectionIllust" />
                                                        </div>
                                                    </div>
                                                </Grid>
                                                <Grid item md={8}>
                                                    <div className="workflow-dialog-forms">
                                                        <div className="tabs-container tabs-container-scroll">
                                                            <Scrollbars style={{ width: '100%', height: '100%' }}>
                                                                <div className="tabs-container-scroll-wrap">
                                                                    <Grid item md={5}>
                                                                        <InputField label="Name of the State " type="text" name="field_label" fieldStyle="field-box bg-plane grey-color"
                                                                            handleOnChangeValue={(value, errors) => this.handleDataChangeEvent("stateObj.state_name", value)} selected_value={stateObj.state_name} placeholder="Enter" />
                                                                    </Grid>
                                                                    <Grid item md={5}>
                                                                        <TextAreaField label="Description" rowmax="3" fieldStyle="field-box bg-plane grey-color" placeholder="Description"
                                                                            handleOnChangeValue={(value, errors) => this.handleDataChangeEvent("stateObj.state_description", value)} selected_value={stateObj.state_description} />
                                                                    </Grid>
                                                                </div>
                                                            </Scrollbars>
                                                        </div>
                                                        <div className="form-row workflow-dialog-footer">
                                                            <div className="btn-wrap no-border">
                                                                <Fab variant="extended" size="large" className="btn btn-large btn-plane primary-color" onClick={this.handleSaveEvent.bind(this)}>
                                                                    Create Section And State
                                                                </Fab>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </div>
                                    </div>
                                </section>
                            </Dialog>
                            {/* Dialog for state create form */}
                        </div>
                    }
                </main>
            </div>
        )
    }
}

export default SectionDashboard;