import * as Constants from '../utilities/constants';

export function setFormData(formData) {
    return function (dispatch) {
        dispatch({
            type: Constants.REDUX_ACTION.SET_FORM_DATA,
            payload: formData,
        })

    }
}

export function setUserData(userData) {
    return function (dispatch) {
        dispatch({
            type: Constants.REDUX_ACTION.SET_USER_DATA,
            payload: userData,
        })


    }
}