import React, { Component } from 'react';
import Fab from '@material-ui/core/Fab';
import Drawer from '@material-ui/core/Drawer';
import EyeIcon from '../../assets/images/icon/eye-blue.svg';
import DownloadIcon from '../../assets/images/icon/download-blue.svg';
import closeIcon from '../../assets/images/icon/close.svg';
import AddIcon from '../../assets/images/icon/add-blue.svg';
import CoverageLimitIllus from '../../assets/images/illustration/coverage-limit-circle.svg';
import RadioField from '../fields/radio';
import SearchField from '../fields/search';
import { Scrollbars } from 'react-custom-scrollbars';
import AddFieldsComponent from '../common/add-fields';
import TableComponent from '../common/table-component';
//Constants
import * as Constants from '../../utilities/constants';
import * as StorageFileApi from '../../api/file-storage-api';
import * as ProgramApi from '../../api/program-api';

const sections = [
    {
        field_option_label: "New India Assurance",
        field_option_value: "new-india-assurance",
    },
    {
        field_option_label: "ICICI Lombard",
        field_option_value: "icici-lombard"
    },
    {
        field_option_label: "Apollo Munich",
        field_option_value: "apollo-munich"
    },
    {
        field_option_label: "HDFC ERGO",
        field_option_value: "hdfc-ergo"
    },
    {
        field_option_label: "Iffco Tokio General Insurance",
        field_option_value: "iffco-tokio-general-insurance"
    },
    {
        field_option_label: "Marsh & McLennan",
        field_option_value: "marsh-mclennan",
    },
    {
        field_option_label: "India Nivesh",
        field_option_value: "india-nivesh",
    },
    {
        field_option_label: "Unicorn Insurance",
        field_option_value: "unicorn-insurance",
    },
    {
        field_option_label: "Howden Insurance Brokers India Private Limited",
        field_option_value: "howden-insurance"
    },
    {
        field_option_label: "Optima Insurance Brokers Pvt. Ltd",
        field_option_value: "optima-insurance-brokers",
    },
    {
        field_option_label: "Unilight Insurance Brokers",
        field_option_value: "unilight-insurance-brokers",
    }
]
const tableRowsIndividual = [
    { id: 'stakeHolder', numeric: true, disablePadding: false, label: 'Stake Holder' },
    { id: 'amount', numeric: true, disablePadding: false, label: 'Amount' },
    { id: 'mamount', numeric: true, disablePadding: false, label: 'Minimum Amount' },
    { id: 'wamount', numeric: true, disablePadding: false, label: 'warning amount' },
    { id: 'utrNo', numeric: true, disablePadding: false, label: 'UTR No.' },
    { id: 'instrument', numeric: true, disablePadding: false, label: 'Instrument' },
    { id: 'apd', numeric: true, disablePadding: false, label: 'Program Type' },
];

function createDataIndividual(stakeholder, amount, minimum_amount, warning_amount, utr_no, instrument, apdName, toggleDrawer) {
    let stakeholderValue = stakeholder;
    let amountValue = amount;
    let minimumAmountValue = minimum_amount;
    let warningAmountValue = warning_amount;
    let utrNoValue = utr_no;
    let instrumentValue = instrument;
    let apdNameValue = apdName;
    return { stakeholderValue, amountValue, minimumAmountValue, warningAmountValue, utrNoValue, instrumentValue, apdNameValue };
}


const tableRowsGroup = [
];
class ProgramPaymentMethod extends Component {

    state = {
        right: false,
        tabAction: 0,
        isApdChecked: true,
        paymentData: {
            non_apd: {},
            program_key: this.props.programkey,
        },
        typeOfCover: "",
        apdData: {},
        paymentDataNew: {
            non_apd: {},
            program_key: this.props.programkey,
        },
        programDetails: {},
    }

    componentWillMount() {
        let { paymentData } = this.state;
        let { programData } = this.props;

        ProgramApi.getProgramByProgramKey(this.props.programkey).then(data => {
            this.setState({ programDetails: data });
        })

        if (programData.hasOwnProperty("payment_methods") && programData.payment_methods !== null) {
            paymentData = programData.payment_methods;
        }

        this.setState({ paymentData: paymentData });
    }

    componentDidUpdate(prevProps) {
        if (JSON.stringify(prevProps.programData) !== JSON.stringify(this.props.programData)) {
            let { paymentData } = this.state;
            let { programData } = this.props;

            if (programData.hasOwnProperty("payment_methods") && programData.payment_methods !== null) {
                paymentData = programData.payment_methods;
            }
            this.setState({ paymentData: paymentData });
        }
    }

    handleChange = () => (value) => {
        this.setState({ isApdChecked: (value === "apd") });
    }

    handleNextClick() {
        let { paymentData } = this.state;
        if (paymentData.apd && paymentData.apd.length > 0) {
            this.props.handleSaveProgramData();
            this.props.modifyProgramSelection(Constants.PROGRAM.TERMS_CONDITIONS, 6);
        }
    }

    handleBackClick() {
        this.props.modifyProgramSelection(Constants.PROGRAM.COVERAGE_LIMITS, 4);
    }

    handleSavePaymentData() {
        let { paymentData, paymentDataNew, typeOfCover } = this.state;
        let { programData } = this.props;
        if (typeOfCover === "new") {

            let apd = paymentDataNew.apd;

            if (apd) {
                if (paymentData.hasOwnProperty("apd") && paymentData.apd !== undefined) {
                    paymentData.apd = paymentData.apd.concat(apd);
                    programData["payment_methods"] = paymentData;
                }
                else if (!(paymentData.hasOwnProperty("apd"))) {
                    paymentData.apd = paymentDataNew.apd;
                    programData["payment_methods"] = paymentData;
                }

            }
            this.props.modifyProgramData(programData);
            this.toggleDrawer('right', false, null);
            this.setState({ paymentData: paymentData, paymentDataNew: {} },
                () => this.props.handleSaveProgramData()
            );
        }
    }
    deleteCoverage(index) {
        let { paymentData } = this.state;
        let { programData } = this.props;
        if (paymentData.apd.length > 0) {
            paymentData.apd.splice(index, 1);
        }
        programData.payment_methods = paymentData;
        this.props.modifyProgramData(programData);
        this.setState({ paymentData: paymentData },
            () => this.props.handleSaveProgramData()
        );
    }

    handleCancel() {
        this.toggleDrawer('right', false, null);
    }

    handleActionChange(event, newValue) {
        this.setState({ tabAction: newValue });
    }

    toggleDrawer(side, open, typeOfCover, editType) {
        this.setState({ [side]: open, typeOfCover: typeOfCover, programGroupType: (editType === "apd") });
    };

    handlePaymentDataChange(paymentDataPara) {
        let { typeOfCover } = this.state;
        if (typeOfCover === "new") {
            this.setState({ paymentDataNew: paymentDataPara });
        }
    }

    handleDownloadLedgerReportFile() {
        const { programkey } = this.props;
        StorageFileApi.getLedgerReport(programkey).then(response => {
            console.log("response", response);
        })
    }

    render() {
        let { paymentData, tabAction, programDetails, paymentDataNew, typeOfCover } = this.state;
        let tableDataIndividual = [];
        let tableDataGroup = [];
        if (paymentData.hasOwnProperty("apd") && paymentData.apd_payment !== null) {
            paymentData.apd.forEach((payment, index) => {
                sections.map(key => {
                    if (payment.stake_holder === key.field_option_value && programDetails["program_published"] === payment.publish) {
                        tableDataIndividual.push(createDataIndividual(key.field_option_label, payment.amount, payment.minimum_amount, payment.warning_amount, payment.utr_no, payment.instrument, "APD", (event) => { this.toggleDrawer('right', true, "edit", "apd") }));
                        return key.field_option_label;
                    }
                })
            })
        }
        let { programData } = this.props;
        let programType = [
            {
                field_option_label: "Advance Premium Deposit",
                field_option_value: "apd",
            },
            {
                field_option_label: "Non Advance Premium Deposit",
                field_option_value: "non_apd"
            }
        ]

        return (
            <div className="drawer-tabs workflow-tabs-wrap">
                <div className="drawer-tabs-header border-bottom">
                    <div className="workflow-title">
                        <h5>Payment Method</h5>
                    </div>
                    <div className="workflow-tabs-search">
                        <Fab variant="extended" size="large" className="btn btn-large secondary-color workflow-add-btn no-shadow" onClick={this.props.handleDownloadMasterPolicyFile} style={{ minWidth: '180px' }}>
                            <img src={EyeIcon} alt="EyeIcon" className="MuiSvgIcon" />
                            Master Policy
                        </Fab>
                    </div>
                </div>
                <div className="drawer-tabs-header">
                    <div className="workflow-tabs-search">
                        <Fab variant="extended" size="large" className="btn btn-large secondary-color workflow-add-btn no-shadow " onClick={() => this.handleDownloadLedgerReportFile()} style={{ minWidth: '180px' }}>
                            <img src={DownloadIcon} alt="EyeIcon" className="MuiSvgIcon" />
                            Ledger Report
                        </Fab>
                    </div>
                    <div className="workflow-tabs-search">
                        <SearchField placeholder="Search here" />
                        <Fab variant="extended" size="large" className="btn btn-large secondary-color workflow-add-btn no-shadow" onClick={(event) => { this.toggleDrawer('right', true, "new", "apd") }}>
                            <img src={AddIcon} alt="AddIcon" /> &nbsp;
                            Payment
                        </Fab>
                    </div>
                </div>
                <div className="drawer-tabs drawer-tabs-round no-drawer-indicator workflow-tabs-wrap">
                    {tabAction === 0 &&
                        <div className="tab-container program-tab-scroll">
                            <Scrollbars style={{ width: '100%', height: '100%' }}>
                                {tableDataIndividual.length > 0 && (
                                    <div className="tab-container">
                                        <div className="table-wrapper vertical-bottom">
                                            <TableComponent
                                                tableLabel=""
                                                rows={tableRowsIndividual}
                                                isPagginated={true}
                                                isSearchable={false}
                                                data={tableDataIndividual}
                                                searchLabel=""
                                                isCheckBoxEnabled={false}
                                            />
                                        </div>
                                    </div>
                                )}
                                {tableDataIndividual.length <= 0 && (
                                    <section className="blank-page-sec">
                                        <div className="blank-page-wrap">
                                            <div className="blank-page-icon">
                                                <img src={CoverageLimitIllus} alt="CoverageLimitIllus" />
                                            </div>
                                            <div className="blank-page-info">
                                                <h5>You haven't added any Payment Methods yet!</h5>
                                            </div>
                                            <Fab variant="extended" size="large" className="btn btn-large workflow-add-btn no-shadow primary-color" onClick={(event) => { this.toggleDrawer('right', true, "new") }}>
                                                New Payment
                                            </Fab>
                                        </div>
                                    </section>
                                )}
                            </Scrollbars>
                        </div>
                    }

                    {tabAction === 1 &&
                        <div className="tab-container program-tab-scroll">
                            <Scrollbars style={{ width: '100%', height: '100%' }}>
                                {tableDataGroup.length > 0 && (
                                    <div className="tab-container">
                                        <div className="table-wrapper vertical-bottom">
                                            <TableComponent
                                                tableLabel=""
                                                rows={tableRowsGroup}
                                                isPagginated={true}
                                                isSearchable={false}
                                                data={tableDataGroup}
                                                searchLabel=""
                                                isCheckBoxEnabled={false}
                                            />
                                        </div>
                                    </div>
                                )}
                                {tableDataIndividual.length <= 0 && (
                                    <section className="blank-page-sec">
                                        <div className="blank-page-wrap">
                                            <div className="blank-page-icon">
                                                <img src={CoverageLimitIllus} alt="CoverageLimitIllus" />
                                            </div>
                                            <div className="blank-page-info">
                                                <h5>You haven't added any Payment Methods yet!</h5>
                                            </div>
                                            <Fab variant="extended" size="large" className="btn btn-large workflow-add-btn no-shadow primary-color" onClick={event => this.handleOpen("open")}>
                                                New Payment Method
                                            </Fab>
                                        </div>
                                    </section>
                                )}
                            </Scrollbars>
                        </div>
                    }
                </div>
                <div className="card-footer border-top p-l-0 p-r-0">
                    <div className="btn-wrap">
                        <Fab variant="extended" size="large" className="btn btn-large btn-plane primary-color" onClick={this.handleNextClick.bind(this)}>
                            Next
                        </Fab>
                        <Fab variant="extended" size="large" className="btn btn-large btn-plane grey-color" onClick={this.handleBackClick.bind(this)}>
                            Back
                        </Fab>
                    </div>
                </div>
                <Drawer anchor="right" open={this.state.right} onClose={() => { this.handleCancel() }}>
                    <section className="workflow-setup-sec program-setup-sec">
                        <section className="setup-drawer-sec">
                            <div className="card-block">
                                <div className="card-header border-bottom">
                                    <div className="card-title">
                                        <h2>Add Payment</h2>
                                    </div>
                                    <div className="card-left-title" style={{ width: 'auto' }}>
                                        <div className="actions setup-close">
                                            <div className="btn-wrap">
                                                <Fab variant="extended" size="small" className="btn btn-small btn-plane btn-fab white-color no-shadow" onClick={() => { this.handleCancel() }}>
                                                    Close <img alt="edit" src={closeIcon}></img>
                                                </Fab>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div role="presentation" className="drawer-sec">
                                        <div className="drawer-form-sec">
                                            <section className="add-fields-wrap">
                                                <div className="add-field-row">
                                                    <RadioField
                                                        radioGroupLabel="Program Type"
                                                        radioData={programType}
                                                        label="Program Type"
                                                        default_value={this.state.isApdChecked ? "apd" : "non_apd"}
                                                        radioFieldStyle="field-inline"
                                                        radioChangeHandle={this.handleChange().bind(this)}
                                                    />
                                                </div>
                                            </section>
                                            <div className="tab-container tab-container-scroll" style={{ height: 'calc(100vh - 295px)' }}>
                                                <Scrollbars style={{ width: '100%', height: '100%' }}>
                                                    <AddFieldsComponent
                                                        addFieldType={this.state.isApdChecked ? "add-apd-field" : " "}
                                                        paymentData={typeOfCover === "edit" ? paymentData : paymentDataNew}
                                                        handlePaymentDataChange={this.handlePaymentDataChange.bind(this)}
                                                        programData={programData}
                                                        programDetails={programDetails}
                                                    />
                                                </Scrollbars>
                                            </div>
                                            <div className="card-footer border-top">
                                                <div className="btn-wrap">
                                                    <Fab variant="extended" size="large" className="btn btn-large btn-plane primary-color" onClick={(event) => { this.handleSavePaymentData() }}> Save</Fab>
                                                    <Fab variant="extended" size="large" className="btn btn-large btn-plane grey-color" onClick={() => { this.handleCancel() }}>Cancel</Fab>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </section>
                </Drawer>
            </div>
        );
    }
}

export default ProgramPaymentMethod;