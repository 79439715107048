import React, { Component } from 'react';
import { Redirect } from 'react-router-dom'
import InputField from '../../components/fields/inputs';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import IconButton from '@material-ui/core/IconButton';
import LoginIllus from '../../assets/images/illustration/login-iso.svg';
// import Logo from '../../assets/images/asureKit.png';
import CheckBoxField from '../../components/fields/checkbox';
import Fab from '@material-ui/core/Fab'
import CircularProgress from '@material-ui/core/CircularProgress';

//API
import * as LoginApi from '../../api/login-api';
import * as ProgramApi from '../../api/program-api';
import * as UserApi from '../../api/user-api';

/* Date: 11-01-2021
 Name: Milind Sewak
 Desc: Logo Image Configuration*/
import * as Constants from '../../utilities/constants';
// End

class Login extends Component {

    state = {
        redirect: false,
        loginData: {},
        showPassword: false,
        errorMessage: "",
        forgetPassword: false,
        loginMain: true,
        verificationEmail: null,
        redirectDetailsView: false,
        userName: null,
        emailValid: false,
        linkSend: null,
        loaderEmail: false,
    }

    // componentWillMount(){
    //     sessionStorage.removeItem("access_token");
    //     sessionStorage.removeItem("type");
    // }

    checkLoginData = (loginData) => () => {
        LoginApi.getUserLoginToken(loginData).then(data => {
            console.log("data12399", data, "XOXOXO", this.props.location);
            let token = data.access_token;
            this.props.authEvent(token, data.type);

            UserApi.getUserDetailsFromUserName(loginData.username).then(response => {
                if (response.user_type === 2) {
                    this.setState({ redirectDetailsView: true, userName: response.user_name });
                } else {
                    this.setState({ redirect: true });
                }
            })
        }).catch(error => {
            console.log("yerroror", JSON.stringify(error));
            let errorResponse = JSON.parse(error.response);
            this.setState({ errorMessage: errorResponse.message })
        })
    }

    renderRedirect = () => {
        // let { from } = this.props.location.state || { from: { pathname: "/create-app" } };
        // if (this.state.redirect) {
        //     return <Redirect to={from} />
        // }

        let pathUrl = "/create-app";

        if (this.state.redirect) {
            return <Redirect to={{
                pathname: pathUrl,
            }} />
        }

    }

    redirectToDetailsView() {
        let pathUrl = "/policy-list";

        if (this.state.redirectDetailsView) {
            return <Redirect to={{
                pathname: pathUrl,
                state:
                {
                    userName: this.state.userName,
                },
            }} />
        }
    }

    handleLoginChange = (key) => (value) => {
        let { loginData } = this.state;
        loginData[key] = value;
        this.setState({ loginData: loginData });
    }

    handleClickShowPassword() {
        this.setState({ showPassword: !this.state.showPassword });
    }

    forgetPassword() {
        this.setState({ forgetPassword: true, loginMain: false });
    }
    backToSignIn() {
        this.setState({ forgetPassword: false, loginMain: true });
    }
    handleVerificationEmailChange(value, errors) {
        this.setState({ verificationEmail: value, emailValid: errors && errors[0] && errors[0].isValid });

    }

    sendVerificationEmail() {
        let { verificationEmail, emailValid, loaderEmail } = this.state;
        if (emailValid && !loaderEmail) {
            this.setState({ linkSend: null, loaderEmail: true }, () => {

                LoginApi.emailVerification(verificationEmail).then(data => {
                    console.log("data456", data);
                    if (data) {
                        this.setState({ linkSend: "A reset link has been mailed to you." });
                    } else {
                        this.setState({ linkSend: "Email Id does not exist. Please register." });
                    }
                    this.setState({ loaderEmail: false })
                })
                    .catch(error => {

                        this.setState({ linkSend: JSON.parse(error.response).message, loaderEmail: false });

                    })



            })
        }
    }


    render() {
        let { loginData, showPassword, errorMessage, loginMain, forgetPassword, verificationEmail, linkSend, loaderEmail } = this.state;
        console.log("linksend", linkSend);
        let validate = [
            {
                field_option_label: "Keep me logged in",
                field_option_value: "yes"
            }
        ]
        return (
            <section className="login-sec">
                {this.renderRedirect()}
                {this.redirectToDetailsView()}
                <div className="login-wrap">
                    <div className="login-wrap-info">
                        <div className="login-info">
                            <h4>Welcome to the </h4>
                            <h2>Future of Insurance</h2>
                            {/* <h5>To stay connected with us please sign in with your personal info.</h5> */}
                        </div>
                        <div className="login-illus">
                            <img src={LoginIllus} alt="LoginIllus" />
                        </div>
                    </div>
                    <div className="login-wrap-form">
                        <div className="login-form">
                            <div className="logo">
                                <img src={Constants.CONFIG.LOGO} alt="AssureKit" />
                            </div>
                            {
                                loginMain && (
                                    <section className={"login-main "}>
                                        <div className="login-form-field">
                                            <div className="form-row">
                                                <div className="login-header">
                                                    <h5>Sign in by entering information below. </h5>
                                                    <div className="login-extra-info">
                                                        <h5 className="reset-info red-color">{errorMessage}</h5>
                                                        <h5 className="reset-info" style={{ display: 'none' }}>A password reset link has been sent. Please check your inbox.</h5>
                                                    </div>

                                                </div>
                                            </div>
                                            <InputField label="Username" type="text"
                                                placeholder="Enter"
                                                name="login-email"
                                                fieldStyle="field-box bg-plane grey-color"
                                                selected_value={loginData.username}
                                                actionPerformAfterEnterPress={this.checkLoginData(this.state.loginData).bind(this)}
                                                handleOnChangeValue={this.handleLoginChange("username").bind(this)}
                                            />
                                            <InputField label="Password"
                                                placeholder="Enter"
                                                name="login-pwd"
                                                type={showPassword ? 'text' : 'password'}
                                                adornment="end"
                                                fieldStyle="field-box field-adornment bg-plane grey-color"
                                                adornmentContent={
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={this.handleClickShowPassword.bind(this)}
                                                    >
                                                        {showPassword ? <Visibility /> : <VisibilityOff />}
                                                    </IconButton>
                                                }
                                                actionPerformAfterEnterPress={this.checkLoginData(this.state.loginData).bind(this)}
                                                selected_value={loginData.password} handleOnChangeValue={this.handleLoginChange("password").bind(this)}
                                            />
                                            <div className="login-remember">
                                                <CheckBoxField
                                                    checkData={validate}
                                                />
                                                <span className="link" onClick={this.forgetPassword.bind(this)}>Forgot your password</span>
                                            </div>
                                            <div className="btn-wrap form-row">
                                                <Fab variant="extended" size="large" className="btn btn-large btn-plane no-shadow primary-color" onClick={this.checkLoginData(this.state.loginData).bind(this)}>
                                                    Sign In
                                                </Fab>
                                            </div>
                                        </div>
                                    </section>
                                )
                            }

                            {
                                forgetPassword && (
                                    <section className={"forget-main " + forgetPassword}>
                                        <div className="login-form-field">
                                            <div className="form-row">
                                                <div className="login-header">
                                                    <h4>Forget your password</h4>
                                                    <div className="login-extra-info-x">
                                                        <h5>Don’t worry. We’ll email you password reset link with instructions.</h5>
                                                    </div>
                                                </div>
                                            </div>
                                            <InputField label="Email"
                                                placeholder="Enter"
                                                fieldStyle="field-box bg-plane grey-color"
                                                selected_value={verificationEmail}
                                                field_validations={["v_email"]}
                                                handleOnChangeValue={(value, errors) => this.handleVerificationEmailChange(value, errors)}
                                            />
                                            <div className="btn-wrap form-row">
                                                <Fab variant="extended" size="large" className="btn btn-large btn-plane no-shadow primary-color" onClick={() => this.sendVerificationEmail()}>
                                                    {
                                                        this.state.loaderEmail === true ?
                                                            <CircularProgress
                                                                color="secondary"
                                                                className="loader"
                                                            />
                                                            : ""
                                                    }
                                                    Request Reset Link
                                                </Fab>

                                            </div>
                                            <div className="btn-wrap form-row">
                                                <span className="link" onClick={this.backToSignIn.bind(this)}>Back to Sign in</span>
                                            </div>
                                            <div className="form-row">
                                                <div className="login-extra-info-x">
                                                    {/* {
                                                        linkSend === 1 ?
                                                        <h5>A reset link has been mailed to you.</h5>
                                                        :
                                                        (
                                                            linkSend === 0 &&
                                                            <h5>Email Id does not exist. Please register.</h5>
                                                        )
                                                    } */}
                                                    {linkSend}
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                )
                            }
                            <div className="login-footer">
                                <p>By clicking sign in, you agree to our<br /> <a href="//assurekit.com/terms-and-conditions.html" className="link" target="_blank">Terms &amp; Conditions</a> and <a href="//assurekit.com/privacy-policy.html" target="_blank" className="link">Privacy Policy.</a></p>
                                <h5>Copyright &copy; Assurekit, All Rights Reserved</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default (Login);