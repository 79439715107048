import React, { Component } from 'react';
import Fab from '@material-ui/core/Fab';
import DuplicatePinkIcon from '../../assets/images/icon/duplicate-pink.svg';
import DuplicateBlueIcon from '../../assets/images/icon/duplicate-blue.svg';
import DeleteRedIcon from '../../assets/images/icon/delete-red.svg';
import DeleteBlueIcon from '../../assets/images/icon/delete-blue.svg';
import EditBlueIcon from '../../assets/images/icon/signature.svg';
import VerDotsIcon from '../../assets/images/icon/more-vert-blue.svg';
import ShareBlueIcon from '../../assets/images/icon/share-blue.svg';
import DownloadBlueIcon from '../../assets/images/icon/download-blue.svg';
import ReportBlueIcon from '../../assets/images/icon/report-blue.svg';
import UploadIcon from '../../assets/images/icon/upload.svg';
import ArrowBackGrey from '@material-ui/icons/KeyboardArrowLeft';
import ArrowForwardGrey from '@material-ui/icons/KeyboardArrowRight';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Dialog from '@material-ui/core/Dialog';
import CloseIcon from '../../assets/images/icon/close.svg';
import SelectField from '../fields/select';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';


class MultipleTabActions extends Component {
    state = {
        actionLink: null,
        downlaodDia: false,
        deleteDia: false,
    };

    menuOpen = ele => event => {
        this.setState({ [ele]: event.currentTarget });
    };

    menuClose = ele => event => {
        this.setState({ [ele]: null });
    };


    handleCloseDownload() {
        this.setState({ downlaodDia: false });
    }

    handleOpenDownload() {
        this.setState({ downlaodDia: true });
    }
    handleCloseDelete() {
        this.setState({ deleteDia: false });
    }

    handleOpenDelete() {
        this.setState({ deleteDia: true });
    }

    handleChangePage = (key) => () => {

        this.props.paginationChange(key);
    }

    renderIconButtons(iconType) {

        let { handleEditClick } = this.props;
        let { handleDeleteClick } = this.props;
        let { handelDuplicateClick } = this.props;
        let { handleDownloadClick, userType } = this.props;
        const { actionLink } = this.state;
        const LightTooltip = withStyles(theme => ({
            tooltip: {
                backgroundColor: theme.palette.common.white,
                color: 'rgba(0, 0, 0, 0.87)',
                position: 'static',
                left: '50%',
                transform: 'translateX(-50%)',
                boxShadow: theme.shadows[1],
                fontSize: 11,
            },
        }))(Tooltip);

        switch (iconType) {
            case "edit": return (
                <div className="btn-action-wrap">
                    <LightTooltip title="Edit">
                        <Fab variant="extended" size="small" className="btn btn-small btn-plane btn-fab transparent-color no-shadow grey-color" onClick={handleEditClick}>
                            <img src={EditBlueIcon} alt="editIcon" />
                        </Fab>
                    </LightTooltip>
                </div>
            );
            case "delete": return (
                <div className="btn-action-wrap">
                    <LightTooltip title="Delete">
                        <Fab variant="extended" size="small" className="btn btn-small btn-plane btn-fab transparent-color no-shadow grey-color" onClick={handleDeleteClick}>
                            <img src={DeleteRedIcon} alt="DuplicatePinkIcon" />
                        </Fab>
                    </LightTooltip>
                </div>
            );
            case "duplicate": return (
                <div className="btn-action-wrap">
                    <LightTooltip title="Duplicate">
                        <Fab variant="extended" size="small" className="btn btn-small btn-plane btn-fab transparent-color no-shadow grey-color" onClick={handelDuplicateClick}>
                            <img src={DuplicatePinkIcon} alt="DuplicatePinkIcon" />
                        </Fab>
                    </LightTooltip>
                </div>
            );
            case "download": return (
                <div className="btn-action-wrap">
                    <LightTooltip title="Download">
                        <Fab variant="extended" size="small" className="btn btn-small btn-plane btn-fab transparent-color no-shadow grey-color" onClick={handleDownloadClick}>
                            <img src={DownloadBlueIcon} alt="DownloadBlueIcon" />
                        </Fab>
                    </LightTooltip>
                </div>
            );
            case "upload": return (
                <div className="btn-action-wrap">
                    <LightTooltip title="Upload">
                        <Fab variant="extended" size="small" className="btn btn-small btn-plane btn-fab transparent-color no-shadow grey-color" >
                            <img src={UploadIcon} alt="UploadIcon" />
                        </Fab>
                    </LightTooltip>
                </div>
            );
            case "search": return (
                ""
            );
            case "report": return (
                <div className="btn-action-wrap">
                    <LightTooltip title="Report">
                        <Fab variant="extended" size="small" className="btn btn-small btn-plane btn-fab transparent-color no-shadow grey-color">
                            <img src={ReportBlueIcon} alt="ReportBlueIcon" />
                        </Fab>
                    </LightTooltip>
                </div>
            );
            // case "message": return (
            //     <div className="btn-action-wrap">
            //         <LightTooltip  title="Mail">
            //             <Fab variant="extended" size="small" className="btn btn-small btn-plane btn-fab transparent-color no-shadow grey-color">
            //                 <img src={MailBlueIcon} alt="MailBlueIcon" />
            //             </Fab>
            //         </LightTooltip>
            //     </div>
            // );
            case "share": return (
                <div className="btn-action-wrap">
                    <LightTooltip title="Share">
                        <Fab variant="extended" size="small" className="btn btn-small btn-plane btn-fab transparent-color no-shadow grey-color">
                            <img src={ShareBlueIcon} alt="ShareBlueIcon" />
                        </Fab>
                    </LightTooltip>
                </div>
            );
            case "extra": return (
                <div className="btn-action-wrap">
                    <Fab variant="extended" size="small" className="btn btn-small btn-plane btn-fab no-shadow grey-color transparent-color btn-menu"
                        aria-owns={actionLink ? 'profile-menu' : undefined}
                        aria-haspopup="true"
                        onClick={this.menuOpen('actionLink')}>
                        <img src={VerDotsIcon} alt="DuplicatePinkIcon" />
                    </Fab>
                    <Menu
                        id="profile-menu"
                        anchorEl={actionLink}
                        open={Boolean(actionLink)}
                        onClose={this.menuClose('actionLink')}
                    >
                        {/* <MenuItem onClick={this.menuClose('actionLink')}><img src={DisableIcon} alt="" className="icon"></img> Disable</MenuItem> */}
                        <MenuItem onClick={this.menuClose('actionLink')}><img src={ShareBlueIcon} alt="" className="icon"></img> Share</MenuItem>
                    </Menu>
                </div>
            )
            case "pagination": return (
                <div className="btn-action-wrap">
                    {
                        userType !== 2 ?
                            <div>
                                <LightTooltip title="Previous">
                                    <Fab variant="extended" size="small" className="btn btn-small btn-plane btn-fab transparent-color no-shadow grey-color" onClick={this.handleChangePage("previous").bind(this)}>
                                        <ArrowBackGrey />
                                    </Fab>
                                </LightTooltip>
                                <LightTooltip title="Next">
                                    <Fab variant="extended" size="small" className="btn btn-small btn-plane btn-fab transparent-color no-shadow grey-color" onClick={this.handleChangePage("next").bind(this)}>
                                        <ArrowForwardGrey />
                                    </Fab>
                                </LightTooltip>
                            </div>
                            : ""
                    }

                </div>
            );
            default: return "";
        }
    }

    render() {
        let { type, entryCount, iconList } = this.props;
        const { deleteDia, downlaodDia } = this.state;
        let defalutConditions = [
            {
                field_option_label: "Section 1",
                field_option_value: "Section1",
            },
            {
                field_option_label: "Section 2",
                field_option_value: "Section2"
            },
            {
                field_option_label: "Section 3",
                field_option_value: "Section3"
            },
        ]
        return (
            <section className="workflow-tbl-sec">
                <div className="workflow-tbl-wrap">


                    {type === "entries" && (
                        <div className="btn-wrap">
                            <div className="btn-action-wrap workflow-tbl-entries">
                                <h6>Selected ({entryCount}) Entries :</h6>
                            </div> &nbsp;
                            <div className="btn-action-wrap">
                                <Fab variant="extended" size="small" className="btn btn-small btn-plane no-shadow secondary-color" >
                                    <img src={DuplicateBlueIcon} className="icon" alt="DuplicateBlueIcon" />
                                    Duplicate
                                </Fab>
                            </div> &nbsp;
                            <div className="btn-action-wrap">
                                <Fab variant="extended" size="small" className="btn btn-small btn-plane no-shadow secondary-color" onClick={this.handleOpenDelete.bind(this)}>
                                    <img src={DeleteBlueIcon} className="icon" alt="DeleteBlueIcon" />
                                    Delete
                                </Fab>
                            </div> &nbsp;
                            <div className="btn-action-wrap">
                                <Fab variant="extended" size="small" className="btn btn-small btn-plane no-shadow secondary-color" onClick={this.handleOpenDownload.bind(this)}>
                                    <img src={DownloadBlueIcon} className="icon" alt="DownloadBlueIcon" />
                                    Download
                                </Fab>
                            </div> &nbsp;
                            {["search", "extra"].map(icon => {
                                return this.renderIconButtons(icon);
                            })}
                        </div>
                    )}
                    {type === "icons" && (
                        <div className="btn-wrap workflow-action-grp">
                            {iconList.map(icon => {
                                return this.renderIconButtons(icon);
                            })}
                        </div>
                    )}

                </div>
                <Dialog open={deleteDia} onClose={this.handleCloseDelete.bind(this)} aria-labelledby="simple-dialog-title">
                    <section className="workflow-dialog workflow-dialog-small workflow-dialog-pri">
                        <div className="card-block">
                            <div className="card-header border-bottom p-b-25">
                                <div className="sub-title">
                                    <h2 className="bold">Delete</h2>
                                </div>
                                <div className="close-dialog">
                                    <img src={CloseIcon} alt="" onClick={this.handleCloseDelete.bind(this)}></img>
                                </div>
                            </div>
                            <div className="card-body p-t-25 p-b-45">
                                <div className="sub-title">
                                    <h5 className="weight-300">Do you really want to delete the selected 21 entries ?</h5>
                                </div>
                            </div>
                            <div className="card-footer p-0 p-t-25">
                                <div className="btn-wrap">
                                    <Fab variant="extended" size="large" className="btn btn-large btn-plane primary-color">
                                        Delete
                                    </Fab>
                                    <Fab variant="extended" size="large" className="btn btn-large btn-plane grey-color" onClick={this.handleCloseDelete.bind(this)}>
                                        Cancel
                                    </Fab>
                                </div>
                            </div>
                        </div>
                    </section>
                </Dialog>
                <Dialog open={downlaodDia} onClose={this.handleCloseDownload.bind(this)} aria-labelledby="simple-dialog-title">
                    <section className="workflow-dialog workflow-dialog-small workflow-dialog-pri">
                        <div className="card-block">
                            <div className="card-header border-bottom p-b-25">
                                <div className="sub-title">
                                    <h2 className="bold">Download</h2>
                                </div>
                                <div className="close-dialog">
                                    <img src={CloseIcon} alt="" onClick={this.handleCloseDownload.bind(this)}></img>
                                </div>
                            </div>
                            <div className="card-body p-t-30 p-b-30">
                                <div className="workflow-dialog-options p-0">
                                    <div className="worflow-dialog-wrap only-download-wrap">
                                        <SelectField
                                            optionsData={defalutConditions}
                                            label="Select Format"
                                        />
                                    </div>
                                </div>

                            </div>
                            <div className="card-footer p-0">
                                <div className="btn-wrap">
                                    <Fab variant="extended" size="large" className="btn btn-large btn-plane primary-color">
                                        Download
                                    </Fab>
                                </div>
                            </div>
                        </div>
                    </section>
                </Dialog>
            </section>
        )
    }
}
MultipleTabActions.defaultProps = {
    iconList: [],
    userType: 0,
}

export default MultipleTabActions;