import React, { Component } from 'react';
import Fab from '@material-ui/core/Fab';
import EyeIcon from '../../assets/images/icon/eye-blue.svg';
import TextAreaField from '../fields/textarea';
import { Scrollbars } from 'react-custom-scrollbars';
import RichTextKendo from '../kendo/rich-text';

//Constants
import * as Constants from '../../utilities/constants';

class ProgramTermCondition extends Component {

    state = {
        termsConditions: {},
    }

    componentWillMount() {
        let { programData } = this.props;
        let { termsConditions } = this.state;
        if (programData.hasOwnProperty("terms_and_conditions") && programData.terms_and_conditions !== null) {
            termsConditions = programData.terms_and_conditions;
        }
        else {
            termsConditions["program_key"] = this.props.programkey;
        }
        this.setState({ termsConditions: termsConditions })
    }

    handleBackClick() {
        this.props.modifyProgramSelection(Constants.PROGRAM.PAYMENT_METHODS);
    }

    handleTermsAndConditions = (key) => (value) => {
        let { termsConditions } = this.state;
        let { programData } = this.props;
        termsConditions[key] = value;
        programData["terms_and_conditions"] = termsConditions;
        this.setState({ termsConditions: termsConditions });
        this.props.modifyProgramData(programData);

    }

    handleFinishProgram() {
        let { programData } = this.props;
        programData["client_id"] = this.props.clientId;
        programData["is_finish"] = true;
        this.props.modifyProgramData(programData);
        this.props.handleSaveProgramData();
        this.props.modifyProgramSelection(Constants.PROGRAM.TERMS_CONDITIONS, 6, true);
        this.editWorkflow()
    }

    editWorkflow() {
        let clientId = this.props.clientId;
        let programkey = this.props.programkey;
        window.location = "/" + clientId + "/" + programkey + "/section-dashboard";
    }

    render() {
        let { termsConditions } = this.state;
        return (
            <div className="drawer-tabs workflow-tabs-wrap">
                <div className="drawer-tabs-header border-bottom">
                    <div className="workflow-title">
                        <h5>Terms and Condition</h5>
                    </div>
                    <div className="workflow-tabs-search">
                        <Fab variant="extended" size="large" className="btn btn-large secondary-color workflow-add-btn no-shadow" onClick={this.props.handleDownloadMasterPolicyFile} style={{ minWidth: '180px' }}>
                            <img src={EyeIcon} alt="EyeIcon" className="MuiSvgIcon" />
                            Master Policy
                        </Fab>
                    </div>
                </div>
                <div className="tab-container">
                    <Scrollbars style={{ width: '100%', height: '100%' }}>
                        <section className="add-fields-wrap policy-term-wrap">
                            <div className="add-field-row">
                                <div className="added-field-grp">
                                    <div className="added-field">
                                        {/* <TextAreaField label="Condition" type="textarea" name="condition" fieldStyle="field-box bg-plane grey-color" placeholder="Description" rowmax="5" selected_value={termsConditions!== null ? termsConditions.conditions : ''} default_value={termsConditions!== null ? termsConditions.conditions : ''} handleOnChangeValue={this.handleTermsAndConditions("conditions")}/> */}
                                        <RichTextKendo label="Condition" name="condition" fieldStyle="field-box bg-plane grey-color small-icon" rowmax="120" default_value={termsConditions !== null ? termsConditions.conditions : ''} handleOnChangeValue={this.handleTermsAndConditions("conditions")} />

                                    </div>
                                </div>
                            </div>
                            <div className="add-field-row">
                                <div className="added-field-grp">
                                    <div className="added-field">
                                        <RichTextKendo label="Special Condition" name="special-condition" fieldStyle="field-box bg-plane grey-color small-icon" rowmax="120" default_value={termsConditions !== null ? termsConditions.special_conditions : ''} handleOnChangeValue={this.handleTermsAndConditions("special_conditions")} />
                                    </div>
                                </div>
                            </div>
                        </section>
                    </Scrollbars>
                </div>
                <div className="card-footer border-top p-l-0 p-r-0">
                    <div className="btn-wrap">
                        <Fab variant="extended" size="large" className="btn btn-large btn-plane primary-color" onClick={this.handleFinishProgram.bind(this)} >
                            Finish
                        </Fab>
                        <Fab variant="extended" size="large" className="btn btn-large btn-plane grey-color" onClick={this.handleBackClick.bind(this)}>
                            Back
                        </Fab>
                    </div>
                </div>
            </div>
        )
    }
}

export default ProgramTermCondition;