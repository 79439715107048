import React, { Component } from 'react';
import { TimePicker } from '@progress/kendo-react-dateinputs';
import InputLabel from '@material-ui/core/InputLabel';
import Description from '../validators/descriptions';
import MandatoryValidator from '../validators/mandatory-validator';
import { Popup } from "@progress/kendo-react-popup";

//Validation
import * as ErrorValidation from '../validators/error-validator';
//Custom 
import ErrorMessage from '../common/error-message';

class TimePickerKendo extends Component {

    state = {
        value: null,
        errorStates: [],
        selectedDate: "",
    };

    handleTimeChange = event => {
        console.log("Time_Picker_123", event.target.value);
        let timeValue = event.target.value;
        timeValue.setDate(1);
        timeValue.setMonth(1);
        timeValue.setFullYear(1970);
        timeValue.setSeconds(0, 0);

        let errors = [];
        if (timeValue) {
            errors = ErrorValidation.updateErrorMessage(timeValue.toISOString(), this.props.field_validations, this.props.condition_script, this.props.error_message, this.props.allowed_hours);
        }
        this.setState({ value: timeValue });
        this.setState({ errorStates: errors });
        this.props.handleDateChange(timeValue, errors);
    }

    render() {
        const { name, label, instruction, is_mandatory, defaultLabel, disabled, fieldStyle, customStyle, selected_value, default_value } = this.props;
        let { value } = this.state;
        let defaultFieldStyle = "field-box";
        let isDisabled = this.props.is_disable === 'yes' ? true : false;
        let { errorStates } = this.state;
        return (
            <div className={"form-row " + customStyle}>
                <div className={fieldStyle ? fieldStyle : defaultFieldStyle} ref={el => (this._el = el)}>
                    <InputLabel
                        htmlFor={name}
                        className="label">
                        {label ? label : defaultLabel}
                        <MandatoryValidator value={is_mandatory} />
                    </InputLabel>
                    <TimePicker
                        id={name}
                        margin="normal"
                        value={selected_value ? new Date(selected_value) : (default_value ? new Date(default_value) : null)}
                        popup={props => <Popup {...props} appendTo={this._el} />}
                        className="field"
                        onChange={this.handleTimeChange}
                        disabled={isDisabled ? isDisabled : disabled}
                    />
                </div>
                <Description value={instruction} />
                <ErrorMessage errorStates={errorStates} name={name} />
            </div>
        )
    }
}

TimePickerKendo.defaultProps = {
    default_value: null,
}

export default TimePickerKendo