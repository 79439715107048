import * as AjaxHelper from "../utilities/ajax-helper"
import { ApiConstants } from "./common/api-constants"

let apiBaseUrl = () => (ApiConstants.DEV_MODE === 'true' ? ApiConstants.HOST : window.location.origin) + ApiConstants.API_VERSION;

export function getNotifications() {
    //return AjaxHelper.get(apiBaseUrl()+ApiConstants.NOTIFICATION, null, {"Content-Type":"application/json"});
}

export function updateNotificationStatus(notification_id) {
    return AjaxHelper.put(apiBaseUrl() + ApiConstants.NOTIFICATION + "/" + notification_id, null, { "Content-Type": "application/json" });
}

export function updateNotificationStatusforAll(notificationIdList) {
    return AjaxHelper.put(apiBaseUrl() + ApiConstants.NOTIFICATION, notificationIdList, { "Content-Type": "application/json" });
}