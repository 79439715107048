import React, { Component } from 'react';
import { DragSource } from 'react-dnd';

import name from '../../assets/images/icon/name.svg';
import mail from '../../assets/images/icon/mail.svg';
import address from '../../assets/images/icon/address.svg';
import phone from '../../assets/images/icon/phone.svg';
import multiline from '../../assets/images/icon/multi-line.svg';
import number from '../../assets/images/icon/number.svg';
import date from '../../assets/images/icon/date.svg';
import time from '../../assets/images/icon/time.svg';
import datetime from '../../assets/images/icon/date-time.svg';
import dropdown from '../../assets/images/icon/dropdown.svg';
import radio from '../../assets/images/icon/radio.svg';
import multiselect from '../../assets/images/icon/multi-select.svg';
import checkbox from '../../assets/images/icon/checkbox.svg';
import singleText from '../../assets/images/icon/single-line.svg';
import formula from '../../assets/images/icon/fx.svg';
import section from '../../assets/images/icon/section.svg';
import state from '../../assets/images/icon/state.svg';
import substate from '../../assets/images/icon/substate.svg';
import action from '../../assets/images/icon/action.svg';
import policyId from '../../assets/images/icon/policy-blue.svg';
import upload from '../../assets/images/icon/upload.svg';

//Constants
import * as FormConstants from '../../utilities/constants';

const formImage = {
  [FormConstants.FieldIds.F_SINGLE_TEXT.code]: singleText,
  [FormConstants.FieldIds.F_MULTI_TEXT.code]: multiline,
  [FormConstants.FieldIds.F_NAME.code]: name,
  [FormConstants.FieldIds.F_DATE.code]: date,
  [FormConstants.FieldIds.F_TIME.code]: time,
  [FormConstants.FieldIds.F_DATE_TIME.code]: datetime,
  [FormConstants.FieldIds.F_RADIO_GROUP.code]: radio,
  [FormConstants.FieldIds.F_CHECKBOX_GROUP.code]: checkbox,
  [FormConstants.FieldIds.F_SINGLE_DROPDOWN_GROUP.code]: dropdown,
  [FormConstants.FieldIds.F_MULTIPLE_DROPDOWN_GROUP.code]: multiselect,
  [FormConstants.FieldIds.F_PHONE_TEXT.code]: phone,
  [FormConstants.FieldIds.F_EMAIL_TEXT.code]: mail,
  [FormConstants.FieldIds.F_CALCULATION.code]: formula,
  [FormConstants.FieldIds.F_NUMBER_TXT.code]: number,
  [FormConstants.FieldIds.F_ADDRESS.code]: address,
  [FormConstants.FieldIds.F_STATE.code]: state,
  [FormConstants.FieldIds.F_SUB_STATE.code]: substate,
  [FormConstants.FieldIds.F_ACTION_BUTTONS.code]: action,
  [FormConstants.FieldIds.F_SECTION_FIELD_TYPE.code]: section,
  [FormConstants.FieldIds.F_POLICY_ID.code]: policyId,
  [FormConstants.FieldIds.F_UPLOAD.code]: upload,
}

const itemSource = {
  beginDrag(props) {
    return props.item;
  },
  endDrag(props, monitor, component) {
    if (!monitor.didDrop()) {
      return;
    }
    return props.handleDrop(props.item[FormConstants.FieldMetadataValues.M_FIELD_CODE]);
  }
}

function collect(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
    isDragging: monitor.isDragging()
  }
}
class FormToolbox extends Component {

  handleDoubleClick = () => {
    console.log('double click');
    return this.props.handleDrop(this.props.item[FormConstants.FieldMetadataValues.M_FIELD_CODE]);
  }

  handleArrowRef = node => {
    this.setState({
      arrowRef: node,
    });
  };

  styles = theme => ({
    htmlTooltip: {
      fontSize: theme.typography.pxToRem(14)
    }
  })

  render() {
    const { isDragging, connectDragSource, item } = this.props;
    // console.log("item66", item);
    let titleData = (
      <React.Fragment>
        {item[FormConstants.FieldMetadataValues.M_FIELD_NAME]}
      </React.Fragment>
    )

    const opacity = isDragging ? 0.4 : 1;
    return connectDragSource(
      <li className="item" style={{ opacity }}>
        <div className="tool-list" onDoubleClick={this.handleDoubleClick}>
          <img alt="" src={formImage[item[FormConstants.FieldMetadataValues.M_FIELD_CODE]]} />
          <span className="tooltip">{titleData}</span>
        </div>
      </li>
    )
  }
}

export default DragSource('FormToolbox', itemSource, collect)(FormToolbox);