
import deleteIcon from '../../assets/images/icon/delete-grey.svg';
import deleteBlueIcon from '../../assets/images/icon/delete-blue.svg';
import actionIcon from '../../assets/images/icon/action.svg';
import addBlueIcon from '../../assets/images/icon/add-blue.svg';
import buildBlueIcon from '../../assets/images/icon/build-blue.svg';
import claimsBlueIcon from '../../assets/images/icon/claims-blue.svg';
import companyBlueIcon from '../../assets/images/icon/company-blue.svg';
import designBlueIcon from '../../assets/images/icon/design-blue.svg';
import downloadBlueIcon from '../../assets/images/icon/download-blue.svg';
import duplicateBlueIcon from '../../assets/images/icon/duplicate-blue.svg';
import editBlueIcon from '../../assets/images/icon/edit-blue.svg';
import eyeBlueIcon from '../../assets/images/icon/eye-blue.svg';
import filterBlueIcon from '../../assets/images/icon/filter-blue.svg';
import hideBlueIcon from '../../assets/images/icon/hide-blue.svg';
import homeBlueIcon from '../../assets/images/icon/home-blue.svg';
import inputBlueIcon from '../../assets/images/icon/input-blue.svg';
import keyBlueIcon from '../../assets/images/icon/key-blue.svg';
import mailBlueIcon from '../../assets/images/icon/mail-blue.svg';
import mobPhoneBlueIcon from '../../assets/images/icon/mob-phone-blue.svg';
import notificationFillBlueIcon from '../../assets/images/icon/notification-fill-blue.svg';
import ownerBlueIcon from '../../assets/images/icon/owner-blue.svg';
import policyBlueIcon from '../../assets/images/icon/policy-blue.svg';
import profileBlueIcon from '../../assets/images/icon/profile-blue.svg';
import reminderBlueIcon from '../../assets/images/icon/reminder-blue.svg';
import reportBlueIcon from '../../assets/images/icon/report-blue.svg';
import sectionsBlueIcon from '../../assets/images/icon/sections-blue.svg';
import settingBlueIcon from '../../assets/images/icon/setting-blue.svg';
import shareBlueIcon from '../../assets/images/icon/share-blue.svg';
import square2BlueIcon from '../../assets/images/icon/square-2-blue.svg';
import square4BlueIcon from '../../assets/images/icon/square-4-blue.svg';
import stateBlueIcon from '../../assets/images/icon/state-blue.svg';
import taskBlueIcon from '../../assets/images/icon/task-blue.svg';
import tickBlueIcon from '../../assets/images/icon/tick-blue.svg';
import updateBlueIcon from '../../assets/images/icon/update-blue.svg';
import usersBlueIcon from '../../assets/images/icon/users-blue.svg';
import warningBlueIcon from '../../assets/images/icon/warning-blue.svg';
import uploadIcon from '../../assets/images/icon/upload.svg';

const Icons = {
    deleteIcon: deleteIcon,
    deleteBlueIcon: deleteBlueIcon,
    actionIcon: actionIcon,
    addBlueIcon: addBlueIcon,
    buildBlueIcon: buildBlueIcon,
    claimsBlueIcon: claimsBlueIcon,
    companyBlueIcon: companyBlueIcon,
    designBlueIcon: designBlueIcon,
    downloadBlueIcon: downloadBlueIcon,
    duplicateBlueIcon: duplicateBlueIcon,
    editBlueIcon: editBlueIcon,
    eyeBlueIcon: eyeBlueIcon,
    filterBlueIcon: filterBlueIcon,
    hideBlueIcon: hideBlueIcon,
    homeBlueIcon: homeBlueIcon,
    inputBlueIcon: inputBlueIcon,
    keyBlueIcon: keyBlueIcon,
    mailBlueIcon: mailBlueIcon,
    mobPhoneBlueIcon: mobPhoneBlueIcon,
    notificationFillBlueIcon: notificationFillBlueIcon,
    ownerBlueIcon: ownerBlueIcon,
    policyBlueIcon: policyBlueIcon,
    profileBlueIcon: profileBlueIcon,
    reminderBlueIcon: reminderBlueIcon,
    reportBlueIcon: reportBlueIcon,
    sectionsBlueIcon: sectionsBlueIcon,
    settingBlueIcon: settingBlueIcon,
    shareBlueIcon: shareBlueIcon,
    square2BlueIcon: square2BlueIcon,
    square4BlueIcon: square4BlueIcon,
    stateBlueIcon: stateBlueIcon,
    taskBlueIcon: taskBlueIcon,
    tickBlueIcon: tickBlueIcon,
    updateBlueIcon: updateBlueIcon,
    usersBlueIcon: usersBlueIcon,
    warningBlueIcon: warningBlueIcon,
    uploadIcon: uploadIcon,

}

export default Icons;