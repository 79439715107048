import * as AjaxHelper from "../utilities/ajax-helper"
import { ApiConstants } from "./common/api-constants"

let apiBaseUrl = () => (ApiConstants.DEV_MODE === 'true' ? ApiConstants.HOST : window.location.origin) + ApiConstants.API_VERSION;

export function saveFormTemplate(formTemplateData) {
    return AjaxHelper.post(apiBaseUrl() + ApiConstants.FORM_TEMPLATE + "/save", formTemplateData, { "Content-Type": "application/json" });
}

export function getAllFormTemplate() {
    return AjaxHelper.get(apiBaseUrl() + ApiConstants.FORM_TEMPLATE, null, { "Content-Type": "application/json" });
}