
import React, { Component } from 'react';

class DetailsTimeline extends Component {

    render() {
        let { sectionData } = this.props;
        let programStateList = sectionData.program_state_list
        return (
            <section className="details-timeline-sec">
                <div className="details-timeline-wrap">
                    {programStateList.reverse().map(state => (
                        <div className={"details-timeline-row level-1 " + (state.status === true && "done")}>
                            <div className="details-timeline-block">
                                <div className="details-timeline-status"><span></span></div>
                                <div className="details-timeline-info">
                                    <h6 className="font-med">{state.state_name}</h6>
                                    <h6 className="font-small">{state.created_date}</h6>
                                </div>
                            </div>
                            <div className="details-timeline-row level-2">
                                {state.sub_states_list.reverse().map(subState => (
                                    <div className={"details-timeline-block " + (subState.status === true && "done")}>
                                        <div className="details-timeline-status"><span></span></div>
                                        <div className="details-timeline-info">
                                            <h6 className="font-med">{subState.state_name}</h6>
                                            <h6 className="font-small">{subState.created_date}</h6>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))}
                </div>
            </section>
        )
    }
}

export default DetailsTimeline;