import React, { Component } from 'react';
import SelectField from '../fields/select';
import Minimize from '@material-ui/icons/Minimize';
import Fab from '@material-ui/core/Fab';
import AddIcon from '../../assets/images/icon/add-blue.svg';
import InputField from '../fields/inputs';

//Constants
import * as FormConstant from '../../utilities/constants'

class FieldAdditionSubtraction extends Component {

    state = {
        policyData: [],
    }

    componentWillMount() {
        let policyData = this.state.policyData;
        let { selectedData, type } = this.props;

        if (selectedData[type].length > 0) {
            policyData = selectedData[type];
        }
        else {
            let value = "";
            policyData.push(value);
        }

        this.setState({ policyData: policyData });
    }

    handleMaxMinChange = (key, index) => () => {
        let policyData = this.state.policyData;
        let { selectedData, type, defaultSections } = this.props;
        console.log("index9990", index, "policyData", policyData.length, "type ", type, "selectedData", selectedData);

        if (key === "max") {
            if ((policyData.length + 1) <= (defaultSections ? defaultSections.length : 0) || type === "insurance_vendors") {
                let value = "";
                policyData.push(value);
                selectedData[type] = policyData;
                this.props.handleProgramChange(selectedData);
            }
        }
        else if (key === "min") {
            if (policyData.length > 1) {
                policyData.splice(index, 1);
                selectedData[type] = policyData;
                this.props.handleProgramChange(selectedData);
            }
        }
        this.setState({ policyData: policyData });

    }

    handleSelectFieldChange = (index) => (value) => {
        let policyData = this.state.policyData;
        let { selectedData, type } = this.props;

        policyData[index] = value;
        selectedData[type] = policyData;
        this.props.handleProgramChange(selectedData);

        this.setState({ policyData: policyData });
    }

    handleInputFieldChange = (index) => (value) => {
        let policyData = this.state.policyData;
        let { selectedData, type } = this.props;

        policyData[index] = value;
        selectedData[type] = policyData;
        this.props.handleProgramChange(selectedData);

        this.setState({ policyData: policyData });
    }

    updateOptionsDataForSelectField(index) {
        let { selectedData, type, defaultSections } = this.props;
        let defaultOptionsData = [...defaultSections];

        if (selectedData[type].length > 0) {
            for (let i = index; i > 0; i--) {
                defaultOptionsData.forEach((field, ind) => {
                    if (field.field_option_value === selectedData[type][i - 1]) {
                        defaultOptionsData.splice(ind, 1);
                    }
                })
            }
            return defaultOptionsData;
        }
        return defaultSections;
    }

    fieldSelection(fieldType, index) {
        let { selectedData, type } = this.props;

        switch (fieldType) {
            case FormConstant.FieldIds.F_SINGLE_DROPDOWN_GROUP.code:
                return (
                    <SelectField
                        optionsData={this.updateOptionsDataForSelectField(index)}
                        is_search={false}
                        selected_value={(selectedData.hasOwnProperty(type) && selectedData[type] != null) ? selectedData[type][index] : []}
                        default_value={(selectedData.hasOwnProperty(type) && selectedData[type] != null) ? selectedData[type][index] : []}
                        fieldStyle="field-box bg-plane grey-color"
                        radioChangeHandle={this.handleSelectFieldChange(index).bind(this)}
                    />
                );

            case FormConstant.FieldIds.F_SINGLE_TEXT.code:
                return (
                    <InputField
                        type="text"
                        name="form_name"
                        fieldStyle="field-box bg-plane grey-color"
                        placeholder="Enter"
                        selected_value={(selectedData.hasOwnProperty(type) && selectedData[type] != null) ? selectedData[type][index] : ''}
                        default_value={(selectedData.hasOwnProperty(type) && selectedData[type] != null) ? selectedData[type][index] : ''}
                        handleOnChangeValue={this.handleInputFieldChange(index).bind(this)}
                    />
                );

            default: break;
        }
    }

    render() {
        let { fieldType } = this.props;
        let { policyData } = this.state;
        console.log("policyData", policyData);
        return (
            <div>
                {
                    policyData.map((data, index) => {
                        return (
                            <div className="added-field-grp">
                                <div className="added-field">
                                    {this.fieldSelection(fieldType, index)}
                                </div>
                                <Fab variant="extended" size="small" className="btn btn-small btn-plane secondary-color btn-fab no-shadow " onClick={this.handleMaxMinChange("max", index).bind(this)}>
                                    <img src={AddIcon} alt="AddIcon" />
                                </Fab>

                                <Fab variant="extended" size="small" className="btn btn-small btn-plane pink-color btn-fab no-shadow btn-delete" onClick={this.handleMaxMinChange("min", index).bind(this)}>
                                    <Minimize />
                                </Fab>

                            </div>
                        );
                    })
                }
            </div>

        );

    }
}
export default FieldAdditionSubtraction