import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import Description from '../validators/descriptions';
import MandatoryValidator from '../validators/mandatory-validator';

//Validation
import * as ErrorValidation from '../validators/error-validator';

//Custom 
import ErrorMessage from '../common/error-message';

//Constants
import * as Constants from '../../utilities/constants';
import { InputAdornment } from '@material-ui/core';

class PolicyIdField extends Component {

    state = {
        errorStates: [],

    }

    render() {
        const {
            name,
            type,
            label,
            instruction,
            selected_value,
            is_mandatory,
            placeholder,
            defaultLabel,
            disabled,
            fieldStyle,
            customStyle,
            default_value,
            withLabel,
            adornment,
            adornmentContent
        } = this.props;
        let { errorStates } = this.state;
        let defaultPlaceholder = "";
        let defaultFieldStyle = "field-box";
        let isDisabled = (this.props.is_disable === 'yes');
        console.log("PolicyId", this.props);
        return (
            <div className={"form-row " + customStyle}>
                <div className={fieldStyle ? fieldStyle : defaultFieldStyle}>

                    {
                        withLabel ? (
                            <InputLabel
                                shrink
                                htmlFor={name}
                                className="label">
                                {label ? label : defaultLabel}
                                <MandatoryValidator value={is_mandatory} />
                            </InputLabel>
                        ) : ""
                    }
                    <TextField
                        id={name}
                        className="field"
                        type={type}
                        onChange={this.handleOnChangeEvent}
                        value={selected_value ? selected_value : default_value}
                        defaultValue={default_value}
                        placeholder={placeholder ? placeholder : defaultPlaceholder}
                        disabled={isDisabled ? isDisabled : disabled}
                        InputProps={adornment == 'start' ? {
                            startAdornment: (
                                <InputAdornment position="start">
                                    {adornmentContent}
                                </InputAdornment>
                            )
                        } : adornment == 'end' ? {
                            endAdornment: (
                                <InputAdornment position="end">
                                    {adornmentContent}
                                </InputAdornment>
                            )
                        } : ""}
                    >
                    </TextField>
                    <ErrorMessage errorStates={errorStates} name={name} />

                </div>
                <Description value={instruction} />
            </div>
        )
    }
}

PolicyIdField.defaultProps = {
    isPhoneNumber: false,
    isEmail: false,
    withLabel: true,
    default_value: "",
    selected_value: "",
    adornment: "",
}

export default PolicyIdField;