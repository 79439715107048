import React, { Component } from 'react';
import LinkIcon from '../../assets/images/icon/link-blue.svg';
import Fab from '@material-ui/core/Fab';
import closeIcon from '../../assets/images/icon/close.svg';
import userIcon from '../../assets/images/icon/profile-blue.svg';
import customerIcon from '../../assets/images/icon/users-blue.svg';
import developerIcon from '../../assets/images/icon/owner-blue.svg';
import Drawer from '@material-ui/core/Drawer';
import SelectField from '../fields/select';
import CheckBoxField from '../fields/checkbox';
import TextAreaField from '../fields/textarea';
import { Scrollbars } from 'react-custom-scrollbars';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

class ProgramShare extends Component {
    state = {
        right: false,
        actionType: '0',
    }
    toggleDrawer = (side, open) => event => {
        this.setState({ [side]: open });
    };
    handleChange = (key) => (event, newValue) => {
        this.setState({ actionType: newValue });
    };
    render() {
        const { actionType } = this.state;
        let defalutSections = [
            {
                field_option_label: "All users in assurekit.com domain",
                field_option_value: "test1",
            },
            {
                field_option_label: "External Sharing",
                field_option_value: "test2"
            },
        ]
        let notifyByMail = [
            {
                field_option_label: "Notify via email",
                field_option_value: "notify_via_email",
            }
        ]
        return (
            <section className="progarm-share-sec">
                {/* <Fab variant="extended" size="small" className="btn btn-plane btn-small transparent-color no-shadow workflow-setting-btn" onClick={this.toggleDrawer('right', true)}>
                    <img alt="WorkflowIcon" class="icon" src={LinkIcon}/>
                    Share
                </Fab> */}
                <Drawer anchor="right" open={this.state.right} onClose={this.toggleDrawer('right', false)}>
                    <section className="workflow-setup-sec">
                        <section className="setup-drawer-sec">
                            <div className="card-block">
                                <div className="card-header">
                                    <div className="card-subheader">
                                        <div className="card-left-title">
                                            {/* <div className="actions setup-close" style={{marginLeft: '-15px'}}>
                                                <div className="btn-wrap">
                                                    <Fab variant="extended" onClick={this.handleBackClick} size="small" className="btn btn-small btn-plane btn-fab white-color no-shadow">
                                                        <img alt="edit" src={ArrowBackGrey} style={{ margin: 0 }}></img> Go Back
                                                    </Fab>
                                                </div>
                                            </div> */}
                                        </div>
                                        <div className="actions setup-close">
                                            <div className="btn-wrap">
                                                <Fab variant="extended" size="small" className="btn btn-small btn-plane btn-fab white-color no-shadow" onClick={this.toggleDrawer('right', false)}>
                                                    Close <img alt="edit" src={closeIcon}></img>
                                                </Fab>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-header border-bottom" style={{ padding: '8px 0px 25px' }}>
                                    <div className="card-title">
                                        <h2>Share</h2>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div role="presentation" className="drawer-sec">
                                        <div className="drawer-form-sec">
                                            <div className="tab-container tab-container-scroll" style={{ height: 'calc(100vh - 295px)' }}>
                                                <Scrollbars style={{ width: '100%', height: '100%' }}>
                                                    <div className="drawer-tabs">
                                                        <AppBar position="static">
                                                            <Tabs value={actionType} onChange={this.handleChange("actionType").bind(this)}>
                                                                <Tab label="User" icon={<img alt="user" src={userIcon}></img>} />
                                                                <Tab label="Customer" icon={<img alt="customerIcon" src={customerIcon}></img>} />
                                                                <Tab label="Developer" icon={<img alt="developerIcon" src={developerIcon}></img>} />
                                                                {/* <Tab label="Deluge Script" value={Constants.ACTIONS_TYPE.SCRIPT} icon={<img alt="scriptIcon" src={scriptIcon}></img>} /> */}
                                                            </Tabs>
                                                        </AppBar>
                                                        {
                                                            actionType === '0' && (
                                                                <div className="tab-container">
                                                                    <SelectField
                                                                        label="Add Users"
                                                                        optionsData={defalutSections}
                                                                        isMultiple={true}
                                                                        fieldStyle="field-box bg-plane grey-color" />
                                                                    <SelectField
                                                                        label="Choose a Permission"
                                                                        optionsData={defalutSections}
                                                                        instruction="External sharing profile."
                                                                        fieldStyle="field-box bg-plane grey-color" />
                                                                    <CheckBoxField
                                                                        checkData={notifyByMail}
                                                                        customStyle="field-box state-field-check"
                                                                    />
                                                                    <TextAreaField label="Description"
                                                                        name="Description"
                                                                        rowmax="3"
                                                                        fieldStyle="field-box bg-plane grey-color"
                                                                        placeholder="Description"
                                                                    />

                                                                </div>
                                                            )
                                                        }
                                                        {
                                                            actionType === '1' && (
                                                                <div className="tab-container">
                                                                    <SelectField
                                                                        label="Add Customer"
                                                                        optionsData={defalutSections}
                                                                        isMultiple={true}
                                                                        fieldStyle="field-box bg-plane grey-color" />
                                                                    <SelectField
                                                                        label="Choose a Permission"
                                                                        optionsData={defalutSections}
                                                                        instruction="This is the default profile having only add and view permission."
                                                                        fieldStyle="field-box bg-plane grey-color" />

                                                                </div>
                                                            )
                                                        }
                                                        {
                                                            actionType === '2' && (
                                                                <div className="tab-container">
                                                                    <SelectField
                                                                        label="Add Developers"
                                                                        optionsData={defalutSections}
                                                                        isMultiple={true}
                                                                        instruction="Who helps you to modify this application based on your requirements"
                                                                        fieldStyle="field-box bg-plane grey-color" />
                                                                    <CheckBoxField
                                                                        checkData={notifyByMail}
                                                                        customStyle="state-field-check"
                                                                    />
                                                                    <TextAreaField label="Description"
                                                                        name="Description"
                                                                        rowmax="3"
                                                                        fieldStyle="field-box bg-plane grey-color"
                                                                        placeholder="Description"
                                                                    />

                                                                </div>
                                                            )
                                                        }
                                                    </div>
                                                </Scrollbars>
                                            </div>
                                            <div className="card-footer border-top">
                                                <div className="btn-wrap">
                                                    <Fab variant="extended" size="large" className="btn btn-large btn-plane primary-color">Save</Fab>
                                                    <Fab variant="extended" size="large" className="btn btn-large btn-plane grey-color">Cancel</Fab>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </section>
                </Drawer>
            </section>
        )
    }
}

export default ProgramShare;