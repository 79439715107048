import * as AjaxHelper from "../utilities/ajax-helper"
import { ApiConstants } from "./common/api-constants"

let apiBaseUrl = () => (ApiConstants.DEV_MODE === 'true' ? ApiConstants.HOST : window.location.origin) + ApiConstants.API_VERSION;

export function addTeamMember(userDetails) {
    return AjaxHelper.post(apiBaseUrl() + ApiConstants.TEAM_MEMBER, userDetails, { "Content-Type": "application/json" });
}

export function getTeamMembersData(clientId) {
    return AjaxHelper.get(apiBaseUrl() + ApiConstants.TEAM_MEMBER + "/clientId/" + clientId, null, { "Content-Type": "application/json" });
}

export function getTeamMemberDetailsById(teamMemberId) {
    return AjaxHelper.get(apiBaseUrl() + ApiConstants.TEAM_MEMBER + "/teamMemberId/" + teamMemberId, null, { "Content-Type": "application/json" });
}

export function getAllAgentsData(programKey) {
    return AjaxHelper.get(apiBaseUrl() + ApiConstants.TEAM_MEMBER + "/agents/" + programKey, null, { "Content-Type": "application/json" });
}

//Fetching from mongodb AgentsStatusDetails
export function getAllAgentsStatus(programKey) {
    return AjaxHelper.get(apiBaseUrl() + ApiConstants.TEAM_MEMBER + "/agentsStatusDetails/" + programKey, null, { "Content-Type": "application/json" });
}

export function saveAgentsStatus(agentsStatus) {
    return AjaxHelper.post(apiBaseUrl() + ApiConstants.TEAM_MEMBER + "/saveAgentStatusDetails", agentsStatus, { "Content-Type": "application/json" });
}

export function bulkAddTeamMember(teamMemberData) {
    return AjaxHelper.post(apiBaseUrl() + ApiConstants.TEAM_MEMBER + "/bulkUpload", teamMemberData, { "Content-Type": "application/json" });
}

export function updateTeamMember(teamMemberId, teamMemberData) {
    return AjaxHelper.put(apiBaseUrl() + ApiConstants.TEAM_MEMBER + "/" + teamMemberId, teamMemberData, { "Content-Type": "application/json" })
}

