
import React, { Component } from 'react';

import { withStyles } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import InputField from '../fields/inputs';
import IconButton from '@material-ui/core/IconButton';
import SearchField from '../fields/search';
import { Scrollbars } from 'react-custom-scrollbars';
import Tooltip from '@material-ui/core/Tooltip';

import ListSubheader from '@material-ui/core/ListSubheader';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
// import icons from './icons';
// import './styles.css';
import { icons } from '../../utilities/constants';

let { Square2 } = icons['i_square2'].icon;



const CustomListItem = (item, props) => (
  <item.type {...item.props}>
    <span className={`k-icon k-i-${props.dataItem.icon}`} style={{ margin: 'auto' }} />
  </item.type>
)

const CustomValue = (element, props) => (
  <element.type {...element.props}>
    {props && <span className={`k-icon k-i-${props.icon}`} />}
    {props && props.text}
  </element.type>
)


class DropdownPopover extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filter: [],
      anchorEl: this.props.anchorElem ? this.props.anchorElem : false,
      selectedIcon: { text: "Square2", icon: Square2 },
      search: "",
    }
  }

  componentWillReceiveProps(nextProps) {
    console.log("Open --", nextProps.anchorElem, this.state.anchorEl);
    if (nextProps.anchorElem !== this.state.anchorEl) {

      this.setState({ anchorEl: nextProps.anchorElem });
    }
  }

  handleFilterChange = ({ filter }) => {
    this.setState({ filter })
  }
  popoverClick(event) {
    this.setState({ anchorEl: event.currentTarget });
  }
  popoverClose() {
    console.log("cllose - ", !this.state.anchorEl);
    this.props.handleCloseOfFieldDropdown();
    this.setState({ anchorEl: !this.state.anchorEl });
  }

  searchValue(event) {
    // console.log("My Search",value);
    this.setState({ search: event.target.value })
    // console.log(this.state.search,"Hello")
  }

  handleFieldSelectedValue(field) {
    this.props.handleFieldSelected(field);
    this.popoverClose();
    this.setState({ search: "" });
  }

  handleOpenField(event) {
    this.popoverClick(event);
  }

  render() {
    const { name, label, instruction, is_mandatory, defaultLabel, disabled, fieldStyle, customStyle, selected_value, default_value, dataList } = this.props;
    let { anchorEl, newlistIcon, search } = this.state;
    let defaultFieldStyle = "field-box";
    let isDisabled = this.props.is_disable === 'yes' ? true : false;
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popper' : null;
    let filteredValueList = JSON.parse(JSON.stringify(dataList));
    if (search) {
      var myKeys = Object.keys(dataList)

      for (let key in dataList) {
        filteredValueList[key] = dataList[key].filter(data => {
          return data.field_option_label.toLowerCase().includes(search.toLowerCase());
        })
      }
    }

    const LightTooltip = withStyles(theme => ({
      tooltip: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        position: 'static',
        left: '50%',
        transform: 'translateX(-50%)',
        boxShadow: theme.shadows[1],
        fontSize: 11,
      },
    }))(Tooltip);

    let subHeaders = [];
    for (let key in filteredValueList) {
      subHeaders.push(key);
    }
    return (

      open ?
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={this.popoverClose.bind(this)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <div className="select-filer-wrap">
            <div className="search-icons">
              <SearchField placeholder="Search here" handleOnChangeValue={(event) => this.searchValue(event)} />
            </div>
            <div className="select-icons">

              <Scrollbars style={{ width: '100%', height: '100%' }}>
                <List subheader={<li />}>
                  {subHeaders.map((subHeads, indexSubHead) => (
                    <li
                      //key={`section-${subHeads}`} 
                      key={indexSubHead}
                    >
                      <ul>
                        <ListSubheader>{`${subHeads}`}</ListSubheader>
                        {filteredValueList[subHeads].map((field, indexItem) => (
                          <ListItem
                            //key={`item-${subHeads}-${field.field_option_value}`}
                            key={indexItem}
                            onClick={() => this.handleFieldSelectedValue(field)}
                          >
                            <ListItemText primary={`${field.field_option_label}`} />
                          </ListItem>
                        ))}
                      </ul>
                    </li>
                  ))}
                </List>
              </Scrollbars>
            </div>
          </div>
        </Popover> :
        null

    );
  }
}


DropdownPopover.defaultProps = {
  default_value: null,
}

export default DropdownPopover;


